import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper, studentListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import { useHistory } from 'react-router-dom';
import useAuth from 'hooks/useAuth.js';
import AsyncSelect from 'react-select/async';
import { getuserTypeLabel } from 'utils/options';
import { format, parse, parseISO } from 'date-fns';
import DatePicker, { DateObject } from "react-multi-date-picker"


const LibraryVisitor = (props) => {
  const defaultFilters = {
    keyword: '',
    start_date: new DateObject,
    end_date: new DateObject,
    student: null,
    student_id: undefined,
    school: null,
    school_id: undefined,
    kelas: null,
    kelas_id: undefined,
    parallel: null,
    parallel_id: undefined,
  }
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, setPreviousMenuActions, gotoPage, showError, isAllDataAccess } = useUI()
  const { user, detail, students } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const history = useHistory()
  const [filters, setFilters] = useState(defaultFilters)
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [balance, setbalance] = useState(null)
  const [queue, setqueue] = useState(null)
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);


  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'student') {
      filtersTemp['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      filtersTemp['school_id'] = value ? value.value : undefined
      getkelasOptions(value ? value.jenjang_id : undefined)
    }
    else if (fieldName === 'kelas') {
      filtersTemp['kelas_id'] = value ? value.value : undefined
      getparallelOptions(value ? value.value : undefined)
    }
    else if (fieldName === 'parallel') {
      filtersTemp['parallel_id'] = value ? value.value : undefined
    }
    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }


  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };


  const getparallelOptions = async (kelas_id, jurusan_id) => {
    const params = {
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const getData = async (newPage, newRowsPerPage,format) => {
    let params = {
      keyword: filters.keyword,
      student_id: filters.student_id,
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
      school_id: filters.school_id,
      kelas_id: filters.kelas_id,
      parallel_id: filters.parallel_id,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    if (format) {
      if (format === 'excel') {
        printExcel(params)
      }
    } else {
      getDataByParams(params)
    }
  }

  const printExcel = (params) => {
    axios.get(endpoint.library.visitorExcel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "library_visitors.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter,
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        params = {
          ...params,
          employee_id: detail.id,
        }
      }
      else {
        return
      }
    }

    startLoading()
    const response = await axios.get(endpoint.library.visitor, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get visitor')
    }
    stopLoading()
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
      } else {
        showError('delete queue')
      }
    }
  };

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }


  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
    deleteLastQueue()
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds
    };
    const response = await axios.delete(endpoint.balance.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete balance')
      getData();
    } else {
      showError('delete balance')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }


  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getschoolOptions()
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Mulai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.start_date}
                onChange={(e) => filterChange('start_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Sampai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.end_date}
                onChange={(e) => filterChange('end_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>


          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='kelas'
              placeholder='Kelas'
              options={kelasOptions}
              onChange={(e) => filterChange('kelas', e)}
              styles={defaultSelectStyle}
              value={filters.kelas}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='parallel'
              placeholder='Paralel'
              options={parallelOptions}
              value={filters.parallel}
              onChange={(e) => filterChange('parallel', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder="Nama..."
              value={filters.student}
              loadOptions={loadStudentOptions}
              onInputChange={handleInputChangeStudent}
              onChange={(e) => filterChange('student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>


        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={() => getData(undefined, undefined, 'excel')}>
            Excel
          </SmallButton>

          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Tipe</TableCell>
                <TableCell>Sekolah</TableCell>
                <TableCell>Kelas</TableCell>
                <TableCell>Paralel</TableCell>
                <TableCell>Tanggal</TableCell>
                <TableCell>Jam</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>{row.user && row.user.detail && row.user.detail.name}</TableCell>
                  <TableCell>{row.user && getuserTypeLabel(row.user.user_type_value)}</TableCell>
                  <TableCell>{row.school && row.school.name}</TableCell>
                  <TableCell>{row.kelas && row.kelas.name}</TableCell>
                  <TableCell>{row.parallel && row.parallel.name}</TableCell>
                  <TableCell>
                    {
                      format(parse(row.created_at.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
                    }
                  </TableCell>
                  <TableCell>
                    {format(parseISO(row.created_at), 'HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>


  </>;
}


export default LibraryVisitor;

