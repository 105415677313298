import React, { useEffect, useState } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import {
    Grid,
    Box,
    Container,
    Typography,
    Button,
    Hidden,
    Dialog,
    useMediaQuery
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import useAuth from 'hooks/useAuth';
import { color } from '@mui/system';
import EditStringPopUp from 'components/EditStringPopUp';
import useUI from 'hooks/useUI';
import EditIcon from '@mui/icons-material/Edit';
import EditRichTextPopUp from 'components/EditRichTextPopUp';
import { useTheme } from '@mui/styles';


const CallToAction = (props) => {
    const { isPsbOpen, backgroundColor, title = '', content = '', image = '' } = props
    const [openDialog, setopenDialog] = useState(false);
    const [brosurs, setbrosurs] = useState([]);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { user, hasRole } = useAuth()
    const { gotoPage } = useUI()

    const saveTitle = (value) => {
        props.saveSetting('HOME_CALL_ACTION_TITLE', value)
    }

    const saveContent = (value) => {
        props.saveSetting('HOME_CALL_ACTION_CONTENT', value)
    }

    const getBrosurs = async () => {

        const response = await axios.get(endpoint.image.group + '/brosur');
        if (response && response.data) {
            setbrosurs(response.data.map(brosur => ({ ...brosur, path: `${api.url}/${brosur.path}` })))
        }
    };

    const showDialog = () => {
        getBrosurs()
        setopenDialog(true)
    }

    const closeDialog = () => {
        setopenDialog(false)
    }


    const saveWithFile = async (event) => {
        let file = event.target.files[0]
        if (file) {
            const formData = new FormData();
            formData.append('file', file)
            formData.append('code', 'HOME_CALL_ACTION_IMAGE')

            const response = await axios.post(endpoint.setting.codeFile, formData, {});
            if (response && response.data) {
                props.getData()
            }
        }

    };

    return (
        <>
            <Box bgcolor='#ffffff'>
                <Grid container justifyContent='space-around'
                    sx={{
                        pt: 8,
                        px: 4,
                        bgcolor: { backgroundColor },
                        color: '#ffffff',
                        borderBottomLeftRadius: 100,
                        minHeight: 350
                    }}>
                    <Grid item xs={12} md={6} container justifyContent='center' alignContent='center' sx={{ mb: 2 }}>
                        <Grid item xs={12} container justifyContent='center' alignContent='center'>
                            <Typography variant={smallScreen ? 'h4' : 'h3'} component="div" >
                                {title}
                                {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={title} save={saveTitle} />}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} container justifyContent='center' alignContent='center' sx={{ pt: 1 }}>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            {hasRole('SCHOOL_ADMIN') && <EditRichTextPopUp value={content} save={saveContent} />}
                        </Grid>
                        <Grid item container justifyContent='center' alignContent='center' spacing={2} sx={{ pt: 3 }}>
                            {
                                (isPsbOpen === 1) &&
                                <Grid item>
                                    <Button onClick={() => gotoPage('/register')} variant="contained" size="large" color='success' sx={{
                                        borderRadius: 50,
                                        p: 2
                                    }}>
                                        Daftar {process.env.REACT_APP_STUDENT} baru
                                    </Button>
                                </Grid>
                            }


                            <Grid item >
                                <Button onClick={showDialog} variant="contained" size="large" color='error' sx={{
                                    borderRadius: 50,
                                    p: 2
                                }}>
                                    Dapatkan brosur
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Hidden lgDown>
                        <Grid item xs={12} md={6} container justifyContent='center' alignItems='center' sx={{p:8}}>
                            <img
                                src={`${api.url}/${image}?t=${Date.now()}`}
                                style={{
                                    width: '50%'
                                }} />

                            {hasRole('SCHOOL_ADMIN') &&
                                <label htmlFor='HOME_CALL_ACTION_IMAGE'>
                                    <input onChange={saveWithFile} accept="image/*" id='HOME_CALL_ACTION_IMAGE' type="file" style={{ display: 'none' }} />
                                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer' }} />
                                </label>
                            }

                        </Grid>
                    </Hidden>

                </Grid>
            </Box>

            <Dialog
                open={openDialog}
                maxWidth='lg'
                onClose={closeDialog}
                fullWidth
                fullScreen={false}
                scroll="body"
            >
                <Grid container justifyContent='center' spacing={1} sx={{ p: 2 }}>
                    {
                        brosurs.map(brosur => (
                            <Grid item>
                                <Card sx={{ width: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={brosur.path}
                                        alt="green iguana"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {brosur.name}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button href={brosur.path} target="_blank" size="small">Lihat</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Dialog>
        </>
    );
}

export default CallToAction;
