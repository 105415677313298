import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { FormControlLabel, Paper, Switch, Typography } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { getstoreTypeLabel, getstoreTypeObject, storeTypeOptions } from 'utils/options';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';  

const EmployeePerformanceTab = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      store_type_value: undefined,
      balance: 0
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [performances, setperformances] = useState([])


  const getperformances = async () => {
    const params = {}


    const response = await axios.get(endpoint.employee.performance.replace('id', row.id), { params: params });
    if (response) {
      setperformances(response.data)
    }
  };


  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row) {
      getperformances()
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container spacing={1} justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="hari" control={<Radio />} label="Per hari" />
              <FormControlLabel value="jam" control={<Radio />} label="Per jam" /> 
            </RadioGroup>
          </Grid>
          {
            performances.map(performance => (
              <Grid item xs={12} sm={4} container sx={{ mb: 2 }}>
                <Paper elevation={3} sx={{ width: '100%', p: 1 }}>
                  <Grid item container>
                    <Grid item xs={12}>
                      {performance.name}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {new Intl.NumberFormat().format(performance.value)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          }

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>

    </DialogActions>
  </>;
}

export default EmployeePerformanceTab;
