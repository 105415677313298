import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import ScheduleForm from './ScheduleForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import { dayOptions, getdayLabel, getdayObject, getscheduleTypeLabel, getscheduleTypeObject, getuserTypeLabel, scheduleTypeOptions, userTypeOptions } from 'utils/options.js';
import { GridView, TableRows } from '@mui/icons-material';
import useAuth from 'hooks/useAuth.js';


const Schedule = (props) => {
  const defaultFilters = {
    keyword: '',
    day: null,
    day_value: undefined,
    parallel: null,
    parallel_id: undefined,
    school: null,
    school_id: undefined,
    schedule_type: null,
    schedule_type_value: undefined,
    user_type: null,
    user_type_value: undefined,
  }
  const { user, detail } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showSuccess, showError, isAllDataAccess } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState(defaultFilters)
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [isGridView, setisGridView] = useState(true);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [parallelOptions, setparallelOptions] = useState([]);
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelases, setkelases] = useState([]);
  const [daySchedules, setdaySchedules] = useState([]);

  const getAllDaySchedules = async () => {
    const params = {
      school_id: filters.school_id
    }
    startLoading()
    const response = await axios.get(endpoint.schedule.day, { params: params });
    stopLoading()
    if (response && response.data) {
      //let days = response.data.days
      let schedules = response.data.schedules
      let jamPelajarans = response.data.jamPelajarans.map(jam => ({
        ...jam,
        startTime: jam.startTime.substring(0, 5),
        finishTime: jam.finishTime.substring(0, 5)
      }))
      let kelases = response.data.kelases

      let kelasWithDefaultSchedules = kelases.map(kelas => ({
        ...kelas,
        parallels: kelas.parallels.map(parallel => ({
          ...parallel,
          schedules: jamPelajarans.map(jam => ({
            jam_ke: jam.jam_ke,
            subject: { name: '', code: '' },
            employee: { name: '', code: '' },
            isStart: 0,
            isFinish: 0
          }))
        }))
      }))

      setdaySchedules(
        dayOptions.map(day => ({
          day_value: day.value,
          day_name: getdayLabel(day.value),
          jamPelajarans: jamPelajarans,
          kelases: kelasWithDefaultSchedules.map(kelas => ({
            ...kelas,
            parallels: kelas.parallels.map(parallel => ({
              ...parallel,
              schedules: parallel.schedules.map(defaultScd => {
                let realSchedules = schedules.filter(schedule => (defaultScd.jam_ke === schedule.jam_ke && schedule.day_value === day.value && schedule.parallel_id === parallel.id))
                if (realSchedules[0]) {
                  return realSchedules[0]
                } else {
                  return defaultScd
                }
              })
            }))
          }))
        }))
      )
    }
  };




  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'day') {
      filtersTemp['day_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      filtersTemp['school_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'parallel') {
      filtersTemp['parallel_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'schedule_type') {
      if (value) {
        filtersTemp['schedule_type_value'] = value.value
        if (value.value === 2) {
          setisGridView(true)
        } else {
          setisGridView(false)
          filtersTemp['school']=null;
          filtersTemp['school_id']=undefined;
        }
        setDataTable([])
        setdaySchedules([])
      }
    }
    else if (fieldName === 'user_type') {
      filtersTemp['user_type_value'] = value ? value.value : undefined
    }
    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      parallel_id: filters.parallel_id,
      day_value: filters.day_value,
      schedule_type_value: filters.schedule_type_value,
      user_type_value: filters.user_type_value,
      school_id: filters.school_id,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (params = {}) => {
    startLoading()
    const response = await axios.get(endpoint.schedule.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get schedule')
    }
    stopLoading()
  }

  const getschoolOptions = async () => {
    let params = {}
    
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };



  const getkelasOptions = async () => {
    if (filters.school_id) {
      const params = {
        school_id: filters.school_id
      }
      const response = await axios.get(endpoint.kelas.parallelOption, { params: params });
      if (response && response.data) {
        setkelases(generalListOptionMapper(response.data));
      }
    } else {
      showError('Pilih sekolah terlebih dahulu')
    }

  };

  const handleDelete = async (ids) => {
    const params = {
      ids: ids ? ids : selectedIds,
      user_id:user.id
    };
    const response = await axios.delete(endpoint.schedule.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete schedule')
      getData();
    } else {
      showError('delete schedule')
    }
  };

  const scheduleCellClick = async (day, parallel, schedule) => {

    let cell = {
      id: schedule.id,
      schedule_type_value: 2,
      day_value: day.day_value,
      jam_ke: schedule.jam_ke,
      school: filters.school,
      school_id: filters.school ? filters.school.id : undefined,
      kelas_id: parallel.kelas_id,
      parallel: parallel,
      parallel_id: parallel.id,
      subject: schedule.subject ? schedule.subject : null,
      subject_id: schedule.subject && schedule.subject.id ? schedule.subject.id : undefined,
      employee: schedule.employee ? schedule.employee : null,
      employee_id: schedule.employee ? schedule.employee.id : undefined,
      isFinish: schedule.isFinish,
      isStart: schedule.isStart,
      startTime: schedule.startTime,
      finishTime: schedule.finishTime,
      isIgnoringAccuracy : schedule.isIgnoringAccuracy
    }

    setpageAction(schedule.id ? 'EDIT' : 'CREATE')

    setMaxWidth('md');
    setdialogContent(
      <ScheduleForm
        cell={cell}
        getData={getAllDaySchedules}
        pageAction={schedule.id ? 'EDIT' : 'CREATE'}
        closeDialog={closeDialog}
        handleDelete={handleDelete}
      />
    );

    setopenDialog(true);

  };

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <ScheduleForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}

      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Schedule"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }



  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const showGridView = (boolean) => {
    setisGridView(boolean)

    if (boolean === true) {
      if (schoolOptions.length > 0 && filters.school === null) {
        setFilters({
          ...filters,
          school: schoolOptions[0],
          school_id: schoolOptions[0].id
        })
      }
      getAllDaySchedules()
    } else {
      getData()
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getschoolOptions()
    getparallelOptions()
  }, [])

  useEffect(() => {
    if (filters.school_id && isGridView) {
      getAllDaySchedules()
      getkelasOptions()
      setFilters({
        ...filters,
        schedule_type: getscheduleTypeObject(2)
      })
    }
  }, [filters.school_id])

  useEffect(() => {
    if (schoolOptions.length > 0) {
      setFilters({
        ...filters,
        school: schoolOptions[0],
        school_id: schoolOptions[0].id
      })
    }
  }, [schoolOptions])

  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/schedule/jam-pelajaran')} variant='text' color='primary'>
              Jam Pelajaran
            </SmallButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/attendance')} variant='text' color='primary'>
              Kehadiran
            </SmallButton>
          </Grid>

          {
            isGridView &&
            <Grid item>
              <IconButton
                onClick={() => showGridView(false)}
                aria-label='reset'
                size="large">
                <TableRows color='primary' />
              </IconButton>
            </Grid>
          }

          {
            !isGridView &&
            <Grid item>
              <IconButton
                onClick={() => showGridView(true)}
                aria-label='reset'
                size="large">
                <GridView color='primary' />
              </IconButton>
            </Grid>
          }

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="schedule_type"
              placeholder="Pilih tipe jadwal"
              options={scheduleTypeOptions}
              value={filters.schedule_type}
              styles={defaultSelectStyle}
              onChange={(e) => filterChange("schedule_type", e)}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="Type"
              placeholder="Pilih tipe pengguna"
              options={userTypeOptions}
              value={filters.user_type}
              styles={defaultSelectStyle}
              onChange={(e) => filterChange("user_type", e)}
            />
          </Grid>



          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='parallel'
              placeholder='Paralel'
              options={parallelOptions}
              value={filters.parallel}
              onChange={(e) => filterChange('parallel', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="day"
              placeholder="Pilih hari"
              options={dayOptions}
              value={filters.day}
              onChange={(e) => filterChange("day", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >

        {
          !isGridView &&
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                      onChange={toggleSelectAllRow}
                      checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                    />
                  </TableCell> 
                  <TableCell>Tipe</TableCell>
                  <TableCell>Kegiatan</TableCell>
                  <TableCell>Pengguna</TableCell>
                  <TableCell>Hari</TableCell>
                  <TableCell>Jam ke</TableCell>
                  <TableCell>Mulai</TableCell>
                  <TableCell>Selesai</TableCell>
                  <TableCell>Pelajaran</TableCell>
                  <TableCell>Jenjang</TableCell>
                  <TableCell>Kelas</TableCell>
                  <TableCell>Paralel</TableCell>
                  <TableCell>Guru</TableCell>
                  <TableCell>Posisi</TableCell>
                  <TableCell>Sesi mulai</TableCell>
                  <TableCell>Sesi selesai</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTable.map((row) => (
                  <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                    <TableCell>
                      <Checkbox
                        checked={selectedIds.includes(row.id)}
                        color="primary"
                        inputProps={{ 'aria-label': 'select all' }}
                      />
                    </TableCell> 
                    <TableCell>
                      <ClickableText text={getscheduleTypeLabel(row.schedule_type_value)} onClick={() => showDialog('READ', row)} />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{getuserTypeLabel(row.user_type_value)}</TableCell>
                    <TableCell>{getdayLabel(row.day_value)}</TableCell>
                    <TableCell>{row.jam_ke}</TableCell>
                    <TableCell>{row.startTime}</TableCell>
                    <TableCell>{row.finishTime}</TableCell>
                    <TableCell>{row.subject && row.subject.name}</TableCell>
                    <TableCell>{row.jenjang && row.jenjang.code}</TableCell>
                    <TableCell>{row.kelas && row.kelas.name}</TableCell>
                    <TableCell>{row.parallel && row.parallel.name}</TableCell>
                    <TableCell>{row.employee && row.employee.name}</TableCell>
                    <TableCell>{row.position && row.position.name}</TableCell>
                    <TableCell>
                      {
                        row.isStart === 1 ? <CheckIcon /> : null
                      }
                    </TableCell>
                    <TableCell>
                      {
                        row.isFinish === 1 ? <CheckIcon /> : null
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid container justifyContent="flex-end">
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
              />
            </Grid>
          </TableContainer>
        }
        {
          isGridView && kelases.length > 0 &&
          <Grid item container justifyContent='center' alignItems='flex-start'>
            <TableContainer>
              <table style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                <tr>
                  <th style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>Hari</th>
                  <th style={{ width: 30, border: '1px solid #dddddd', borderCollapse: 'collapse' }}>Jam Ke</th>
                  {
                    kelases.map(kelas => (
                      <th style={{ borderRight: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                        <tr style={{ borderBottom: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                          <th colSpan={kelas.parallels.length} >{kelas.name}</th>
                        </tr>
                        <tr>
                          {
                            kelas.parallels.map(parallel => (
                              <th style={{
                                width: 120,
                                border: '1px solid #dddddd',
                                borderCollapse: 'collapse',
                                whiteSpace: 'nowrap'
                              }}>{parallel.name}</th>
                            ))
                          }
                        </tr>
                      </th>
                    ))
                  }
                </tr>

                {
                  daySchedules.map(day => (
                    <tr>
                      <th style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                        {day.day_name}
                      </th>

                      <td>
                        {
                          day.jamPelajarans.map(jamPelajaran => (
                            <tr>
                              <td style={{ height: 22, width: 30, border: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                                {jamPelajaran.jam_ke}
                              </td>
                            </tr>
                          ))
                        }
                      </td>
                      {
                        day.kelases.map(kelas => (
                          <td>
                            {
                              kelas.parallels.map(parallel => (
                                <td>
                                  {
                                    parallel.schedules.map(schedule => (
                                      <tr>
                                        <td onClick={() => scheduleCellClick(day, parallel, schedule)} style={{ textAlign: 'center', height: 22, minWidth: 120, border: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                                          {
                                            schedule && schedule.subject && schedule.employee && schedule.employee.code && schedule.subject.code && 
                                            <>
                                              <span>{schedule.subject.code}</span>
                                              <span style={{ fontSize:'12px', marginLeft: '4px', border: '1px solid blue', borderRadius: '2px', padding: '1px' }}>
                                                {
                                                  schedule.employee.code
                                                }
                                              </span>
                                            </>

                                          }

                                        </td>
                                      </tr>
                                    ))
                                  }</td>
                              ))
                            }
                          </td>
                        ))
                      }

                    </tr>
                  ))
                }
              </table>
            </TableContainer>
          </Grid>
        }


      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default Schedule;

