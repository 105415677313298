import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { FormControlLabel, Switch } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';


const RoleDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      code: null,
      name: undefined, 
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ') 

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue 

    newState[fieldName] = value
    setstate(newState)
  }
 
  const insert = async (payload) => {
    const response = await axios.post(endpoint.role.root, payload)
    if (response && response.data) {
      showSuccess('save role')
      getData()
    } else {
      showError('save role')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.role.root, payload)
    if (response && response.data) {
      showSuccess('save role')
      getData()
    } else {
      showError('save role')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    const response = await axios.get(endpoint.role.detail.replace("id", row.id))
    if (response) {
      const row = response.data
      setstate({
        ...row 
      })
    }
  }
 

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])





  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>
  
           
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>
 

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

           
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default RoleDetail;
