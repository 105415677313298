import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormControlLabel, Switch, Checkbox, TextField } from '@mui/material';
import { format, parse } from 'date-fns'
import { DatePicker, TimePicker, DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useUI from 'hooks/useUI';
import { parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import BooleanChip from 'components/BooleanChip';


const SettingAdminDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const classes = useStyles();
  const reset = () => {
    return {
      id: undefined,
      group: '',
      code: '',
      name: '',
      type: { label: 'integer', value: 'integer' },
      isRestricted: 0,
      integerValue: undefined,
      decimalValue: undefined,
      stringValue: undefined,
      dateValue: undefined,
      dateTimeValue: undefined,
      timeValue: undefined,
      parameter_value: undefined,
      parameter: null,
      booleanValue: 0,
      admin_employee_id: undefined,
      tableSource:undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [parameterOptions, setparameterOptions] = useState([])
  const [typeOptions, settypeOptions] = useState([
    { label: 'boolean', value: 'boolean' },
    { label: 'integer', value: 'integer' },
    { label: 'decimal', value: 'decimal' },
    { label: 'string', value: 'string' },
    { label: 'date', value: 'date' },
    { label: 'dateTime', value: 'dateTime' },
    { label: 'time', value: 'time' },
    { label: 'parameter', value: 'parameter' },
  ])

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'type') {
      newState['integerValue'] = undefined
      newState['decimalValue'] = undefined
      newState['stringValue'] = undefined
      newState['dateValue'] = new Date()
      newState['dateTimeValue'] = new Date()
      newState['timeValue'] = new Date()
      newState['parameter_value'] = undefined
      newState['booleanValue'] = 0

    }
    else if (fieldName === 'booleanValue') {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (["isRestricted", "isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'parameter_group') {
      getparameterOptions(value)
    }
    else if (fieldName === 'parameter') {
      newState['parameter_value'] = value.value
    }


    newState[fieldName] = value
    setstate(newState)
  }

  const getparameterOptions = async (group) => {
    const params = {
      group: group
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setparameterOptions(parameterListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.setting.root, payload)
    if (response && response.data) {
      showSuccess('save setting')
      getData()
    } else {
      showError('save setting')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.setting.root, payload)
    if (response && response.data) {
      showSuccess('save setting')
      getData()
    } else {
      showError('save setting')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      type: state.type.value,
      booleanValue: state.type.value === "boolean" ? Number(state.booleanValue) : undefined,
      integerValue: state.type.value === "integer" ? Number(state.integerValue) : undefined,
      decimalValue: state.type.value === "decimal" ? Number(state.decimalValue) : undefined,
      stringValue: state.type.value === "string" ? state.stringValue : undefined,
      dateValue: state.type.value === "date" ? format(state.dateValue, 'yyyy-MM-dd') : undefined,
      dateTimeValue: state.type.value === "dateTime" ? format(state.dateTimeValue, 'yyyy-MM-dd HH:mm:ss') : undefined,
      timeValue: state.type.value === "time" ? format(state.timeValue, 'HH:mm:ss') : undefined,
      parameter_value: state.type.value === "parameter" ? state.parameter.value : undefined,
      admin_employee_id: undefined
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getParameter = (row) => {
    if (row.parameter_group === 'absen_input_mode') {
      return row.absen_input_mode
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */

    setstate({
      ...row,
      type: { label: row.type, value: row.type },
      parameter: parameterOptionMapper(getParameter(row)),
      timeValue: row.timeValue ? parse(row.timeValue, 'HH:mm:ss', new Date()) : null,
      dateValue: row.dateValue ? parse(row.dateValue, 'yyyy-MM-dd', new Date()) : null,
      dateTimeValue: row.dateTimeValue ? parse(row.dateTimeValue, 'yyyy-MM-dd HH:mm:ss', new Date()) : null
    })

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getparameterOptions(row.parameter_group)
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>

    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Group
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.group} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='group'
                  fullWidth
                  value={state.group}
                  onChange={(e) => handleChange('group', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe nilai
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.type && state.type.value} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="Type"
                  placeholder="Pilih tipe nilai"
                  options={typeOptions}
                  value={state.type}
                  onChange={(e) => handleChange("type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.type.value === "boolean"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nilai boolean
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <BooleanChip value={state.booleanValue} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Switch
                    color='primary'
                    checked={state.booleanValue === 1}
                    onChange={(e) => handleChange("booleanValue", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.type.value === "integer"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nilai integer
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.integerValue} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='integerValue'
                    fullWidth
                    value={state.integerValue}
                    onChange={(e) => handleChange('integerValue', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.type.value === "decimal"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nilai desimal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.decimalValue} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='decimalValue'
                    fullWidth
                    value={state.decimalValue}
                    onChange={(e) => handleChange('decimalValue', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.type.value === "string"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nilai string
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.stringValue} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='stringValue'
                    fullWidth
                    value={state.stringValue}
                    onChange={(e) => handleChange('stringValue', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Conditional condition={state.type.value === "date"}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Nilai date
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.dateValue && format(state.dateValue, 'yyyy-MM-dd')} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.dateValue}
                      onChange={(e) => handleChange("dateValue", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={state.type.value === "time"}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Nilai time
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.timeValue && format(state.timeValue, 'HH:mm:ss')} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <TimePicker
                      autoOk
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.timeValue}
                      onChange={(e) => handleChange("timeValue", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={state.type.value === "dateTime"}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Nilai date time
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.dateTimeValue && format(state.dateTimeValue, 'yyyy-MM-dd HH:mm')} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DateTimePicker
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.dateTimeValue}
                      onChange={(e) => handleChange("dateTimeValue", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={state.type.value === "parameter"}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Parameter group
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.parameter_group} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DebouncedTextField
                      margin="dense"
                      id="parameter_group"
                      fullWidth
                      value={state.parameter_group}
                      onChange={(e) => handleChange("parameter_group", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>

              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Parameter value
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.parameter && state.parameter.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      name="parameter"
                      options={parameterOptions}
                      value={state.parameter}
                      onChange={(e) => handleChange("parameter", e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
                Akses terbatas
              </Grid>
              <Grid item sm={8} xs={2}>
                <Conditional condition={pageAction === 'READ'}>
                  <Checkbox
                    checked={state.isRestricted === 1}
                  />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Checkbox
                    checked={state.isRestricted === 1}
                    onChange={(e) => handleChange("isRestricted", e)}
                  />
                </Conditional>

              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tabel data
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.tableSource} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='stringValue'
                    fullWidth
                    value={state.tableSource}
                    onChange={(e) => handleChange('tableSource', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Conditional condition={pageAction === 'READ'}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  admin
                </Grid>
                <Grid item sm={8} xs={12}>
                  <ReadOnlyTextField value={state.admin_employee_id} />
                </Grid>
              </Grid>
            </Conditional>

          </LocalizationProvider>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default SettingAdminDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
