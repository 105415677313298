import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import NewStudentAdminForm from './NewStudentAdminForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Button, Chip, Collapse, FormControlLabel, useMediaQuery } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import useAuth from 'hooks/useAuth.js';
import { useHistory } from 'react-router-dom';
import { doneStatusOptions, getdoneStatusObject, getsexTypeLabel, getyesNoStatusObject, sexTypeOptions } from 'utils/options.js';
import StatusChip from 'components/StatusChip.js';
import { Close, Summarize } from '@mui/icons-material';
import Confirmation from 'components/Confirmation.js';
import YesNoCancelConfirmation from 'components/YesNoCancelConfirmation.js';
import TwoActionConfirmation from 'components/TwoActionConfirmation.js';
import NewStudentStatistic from './NewStudentStatistic.js';
import InsertChart from '@mui/icons-material/InsertChart';
import { useTheme } from '@mui/styles';



const NewStudentAdmin = (props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, menus = [] } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, setActiveMenuActions, gotoPage, showSuccess, showError } = useUI()
  const history = useHistory()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [summaries, setsummaries] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [openStatistic, setopenStatistic] = useState(false)
  const [selectedStatistic, setselectedStatistic] = useState('')

  const defaultFilters = {
    keyword: '',
    tahun: null,
    tahun_id: undefined,
    psb_flow: null,
    psb_flow_id: undefined,
    sex_type: null,
    sex_type_value: undefined,
    school: null,
    school_id: undefined,
    jurusan: null,
    jurusan_id: undefined,
    continue: null,
    isContinue: undefined,
    accepted: null,
    isAccepted: undefined,
    done: null,
    isPsbDone: undefined,
    isMigrated: undefined,
    parent_status: null,
    parent_status_value: undefined
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [openSummaryDialog, setopenSummaryDialog] = useState(false);
  const [openDecisionDialog, setopenDecisionDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('xl');
  const [isDialogFull, setisDialogFull] = useState(true);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [selectedStudentFlow, setselectedStudentFlow] = useState(null)
  const [psb_flowOptions, setpsb_flowOptions] = useState([]);
  const [schoolOptions, setschoolOptions] = useState([])
  const [jurusanOptions, setjurusanOptions] = useState([])
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [parentStatusOptions, setparentStatusOptions] = useState(
    [
      { label: 'Lengkap', value: 1 },
      { label: 'Yatim', value: 2 },
      { label: 'Piatu', value: 3 },
      { label: 'Yatim piatu', value: 4 }
    ]
  )

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value

    if (fieldName === 'parent_status') {
      filtersTemp['parent_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'isMigrated') {
      filtersTemp[fieldName] = value.target.checked ? 1 : 0
    }
    else if (fieldName === 'psb_flow') {
      filtersTemp['psb_flow_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      filtersTemp['school_id'] = value ? value.value : undefined
      if (value) {
        getpsb_flowOptions(value.value)
      } else {
        filtersTemp['psb_flow'] = null
        filtersTemp['psb_flow_id'] = undefined
      }
    }
    else if (fieldName === 'jurusan') {
      filtersTemp['jurusan_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'tahun') {
      filtersTemp['tahun_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'continue') {
      filtersTemp['isContinue'] = value ? value.value : undefined
    }
    else if (fieldName === 'accepted') {
      filtersTemp['isAccepted'] = value ? value.value : undefined
    }
    else if (fieldName === 'done') {
      filtersTemp['isPsbDone'] = value ? value.value : undefined
    }
    else if (fieldName === 'sex_type') {
      filtersTemp['sex_type_value'] = value ? value.value : undefined
    }


    setFilters(filtersTemp)
  }

  const submitDecision = async (decision) => {
    let payload = {
      id: selectedStudentFlow.id,
      psb_flow_id: selectedStudentFlow.psb_flow_id,
      status_value: decision
    }
    const response = await axios.post(endpoint.psbFlow.adminDecision, payload)
    if (response && response.data) {
      closeDecisionDialog()
      showSuccess('update status')
      getData()
    }
  }

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const getpsb_flowOptions = async (school_id) => {
    const params = {
      school_id: school_id
    }
    const response = await axios.get(endpoint.psbFlow.option, { params: params });
    if (response && response.data) {
      setpsb_flowOptions(generalListOptionMapper(response.data));
    }
  };

  const printExcel = (params) => {
    axios.get(endpoint.newStudent.excel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "new_students.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  const getData = async (newPage, newRowsPerPage, format) => {
    let params = {

      keyword: filters.keyword,
      page: newPage ? newPage : page,
      tahun_id: filters.tahun_id,
      psb_flow_id: filters.psb_flow_id,
      school_id: filters.school_id,
      jurusan_id: filters.jurusan_id,
      isContinue: filters.isContinue,
      isAccepted: filters.isAccepted,
      isPsbDone: filters.isPsbDone,
      isMigrated: filters.isMigrated,
      sex_type_value: filters.sex_type_value,
      parent_status_value: filters.parent_status_value,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    if (format) {
      if (format === 'excel') {
        printExcel(params)
      }
    } else {
      getDataByParams(params)
    }

  }

  const getDataByParams = async (params = {}) => {
    startLoading()
    const response = await axios.get(endpoint.newStudent.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get newStudent')
    }
    stopLoading()
  }

  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDecisionDialog = (newStudentFlow) => {
    setselectedStudentFlow(newStudentFlow)
    setopenDecisionDialog(true)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('xl');
    setdialogContent(
      <NewStudentAdminForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
        importUrl={endpoint.newStudent.import}
        templateUrl={api.url + '/files/NEW_STUDENT_TEMPLATE.xlsx'}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="NewStudentAdmin"
      />
    );

    setopenDialog(true);
  };

  const showMigrationConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <TwoActionConfirmation
        handleClose={closeDialog}
        firstAction={migrateSelected}
        firstLabel={`Migrasi ${process.env.REACT_APP_STUDENT} terpilih`}
        secondAction={migrateAll}
        secondLabel="Migrasi semua"
        message={`Anda akan menyalin data calon ${process.env.REACT_APP_STUDENT} menjadi data ${process.env.REACT_APP_STUDENT}`}
        title='Konfirmasi Migrasi data'
      />
    );

    setopenDialog(true);
  };


  const acceptedConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <YesNoCancelConfirmation
        handleClose={closeDialog}
        yesAction={acceptedPsbFlow}
        noAction={rejectedPsbFlow}
        yesLabel="Diterima"
        noLabel="Ditolak"
        message='Apakah proses pendaftaran sudah selesai dan diterima'
        title='Konfirmasi penerimaan'
      />
    );

    setopenDialog(true);
  };

  const byPassConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <Confirmation
        handleClose={closeDialog}
        action={byPassPsbFlow}
        actionName="Maju ke tahap selanjutnya"
        message={`Apakah ${process.env.REACT_APP_STUDENT} ini langsung maju ke tahap selanjutnya`}
        title='Konfirmasi lanjut oleh petugas'
      />
    );

    setopenDialog(true);
  };

  const stepBackConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <Confirmation
        handleClose={closeDialog}
        action={stepBackPsbFlow}
        actionName="Kembali ke tahap sebelumnya"
        message={`Apakah ${process.env.REACT_APP_STUDENT} ini kembali ke tahap sebelumnya`}
        title='Konfirmasi kembali oleh petugas'
      />
    );

    setopenDialog(true);
  };


  const acceptedPsbFlow = async () => {
    let payload = {
      new_student_id: selectedRow.id
    }
    const response = await axios.post(endpoint.psbFlow.accept, payload)
    if (response && response.data) {
      closeDialog()
      showSuccess('pendaftar diterima')
      getData()
    }
  }

  const rejectedPsbFlow = async () => {
    let payload = {
      new_student_id: selectedRow.id
    }
    const response = await axios.post(endpoint.psbFlow.reject, payload)
    if (response && response.data) {
      closeDialog()
      showSuccess('pendaftar ditolak')
      getData()
    }
  }



  const byPassPsbFlow = async () => {
    let payload = {
      new_student_id: selectedRow.id
    }
    const response = await axios.post(endpoint.psbFlow.bypass, payload)
    if (response && response.data) {
      setselectedIds([])
      setselectedRow(null)
      closeDialog()
      showSuccess('maju ke tahap selanjutnya')
      getData()
    }
  }

  const stepBackPsbFlow = async () => {
    let payload = {
      new_student_id: selectedRow.id
    }
    const response = await axios.post(endpoint.psbFlow.stepBack, payload)
    if (response && response.data) {
      setselectedIds([])
      setselectedRow(null)
      closeDialog()
      showSuccess('kembali ke tahap sebelumnya')
      getData()
    }
  }

  const migrateAll = async () => {
    let payload = {}
    const response = await axios.post(endpoint.psbFlow.migrate, payload)
    if (response && response.data) {
      closeDialog()
      showSuccess('migrasi data')
      getData()
    }
  }

  const migrateSelected = async () => {
    let payload = {
      new_student_ids: selectedIds
    }
    const response = await axios.post(endpoint.psbFlow.migrate, payload)
    if (response && response.data) {
      closeDialog()
      showSuccess('migrasi data')
      getData()
    }
  }

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const closeSummaryDialog = () => {
    setopenSummaryDialog(false)

  }


  const showSummary = async (new_student) => {

    let params = {
      new_student_id: new_student.id,
      school_id: new_student.school_id,
      isForOld: new_student.isContinue === 1 ? 1 : undefined,
      isForNew: new_student.isContinue === 0 ? 1 : undefined
    }
    const response = await axios.get(endpoint.newStudent.overallPsbFlow, { params: params })

    if (response) {
      setsummaries(response.data)
    } else {
      showError('get student psb flow summary')
    }
    setopenSummaryDialog(true)

  }

  const closeDecisionDialog = () => {
    setselectedStudentFlow(null)
    setopenDecisionDialog(false)

  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.newStudent.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete newStudent.type')
      getData();
    } else {
      showError('delete newStudent.type')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const getDataByStatistic = async (statistic) => {
    setopenDialog(false)
    if (selectedStatistic === statistic.code) {
      reset()
    } else {
      setselectedStatistic(statistic.code)
      let params = {
        school_id: statistic.school_id,
        yatim: statistic.yatim,
        isPsbDone: statistic.isPsbDone,
        isMigrated: statistic.isMigrated,
        page: page,
        rowsPerPage: rowsPerPage
      }


      let isPsbDoneIdx = doneStatusOptions.findIndex(type => type.value === statistic.isPsbDone)
      let done = null

      if (isPsbDoneIdx > -1) {
        done = doneStatusOptions[isPsbDoneIdx]
      }

      let schoolIdx = schoolOptions.findIndex(type => type.value === statistic.school_id)
      let school = null

      if (schoolIdx > -1) {
        school = schoolOptions[schoolIdx]
      }

      setFilters(
        {
          ...filters,
          school: school,
          school_id: statistic.school_id,
          done: done,
          isPsbDone: statistic.isPsbDone,
          isMigrated: statistic.isMigrated
        }
      )
      setPage(1)

      getDataByParams(params)

    }

  }

  const showStatistic = () => {
    if (smallScreen) {
      setdialogContent(
        <Box minHeight={300}>
          <NewStudentStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Box>

      )
      setopenDialog(true)
    } else {
      setopenStatistic(!openStatistic)
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      school: defaultFilters.school,
      school_id: defaultFilters.school_id,
      jurusan: defaultFilters.jurusan,
      jurusan_id: defaultFilters.jurusan_id,
      done: null,
      isPsbDone: undefined,
      isMigrated: undefined,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    setselectedStatistic('')
    getDataByParams(params)
  }

  useEffect(() => {
    gettahunPelajaranOptions()
    getschoolOptions()
    getjurusanOptions()
    getpsb_flowOptions()
    getData()

  }, [])



  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
              <Button onClick={() => showDialog('IMPORT')} color='primary'>
                Import
              </Button>
            </Protected>
          </Grid>

          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={showMigrationConfirmation} variant='contained' color='secondary'>
                {`Migrasi data ${process.env.REACT_APP_STUDENT}`}
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <>
              <Grid item>
                <Protected allowedCodes={['CREATE']} >
                  <SmallButton onClick={acceptedConfirmation} variant='contained' color='success'>
                    Penerimaan
                  </SmallButton>
                </Protected>
              </Grid>
              <Grid item>
                <Protected allowedCodes={['CREATE']} >
                  <SmallButton onClick={byPassConfirmation} variant='contained' color='primary'>
                    Lanjut
                  </SmallButton>
                </Protected>
              </Grid>
              <Grid item>
                <Protected allowedCodes={['CREATE']} >
                  <SmallButton onClick={stepBackConfirmation} variant='contained' color='error'>
                    Kembali
                  </SmallButton>
                </Protected>
              </Grid>

              <Grid item>
                <Protected allowedCodes={['EDIT']} >
                  <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                    <ActionDisplay code='EDIT' />
                  </SmallButton>
                </Protected>
              </Grid>
            </>


          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/psb-exam-participant')} variant='text' color='primary'>
              Peserta ujian
            </SmallButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/psb-flow')} variant='text' color='primary'>
              {
                process.env.REACT_APP_SCHOOL_MODE === 'PESANTREN' && 'Tahapan PSB'
              }
              {
                process.env.REACT_APP_SCHOOL_MODE === 'SEKOLAH' && 'Tahapan PPDB'
              }
            </SmallButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={showStatistic}
              aria-label='delete'
              size="large">
              <InsertChart color='primary' />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Collapse in={openStatistic} timeout="auto" unmountOnExit sx={{ width: 'inherit' }}>
        <Grid container sx={{ minHeight: 64, mb: 1 }}>
          <NewStudentStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Grid>
      </Collapse>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          }

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='continue'
              placeholder={`Jenis ${process.env.REACT_APP_STUDENT}`}
              options={[
                { label: `${process.env.REACT_APP_STUDENT} lama`, value: 1 },
                { label: `${process.env.REACT_APP_STUDENT} baru`, value: 0 },
              ]}
              onChange={(e) => filterChange('continue', e)}
              styles={defaultSelectStyle}
              value={filters.continue}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='sex_type'
              placeholder={`Jenis kelamin`}
              options={sexTypeOptions}
              onChange={(e) => filterChange('sex_type', e)}
              styles={defaultSelectStyle}
              value={filters.sex_type}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="tahun"
              placeholder="Pilih Tahun Pelajaran"
              options={tahunPelajaranOptions}
              value={filters.tahun}
              onChange={(e) => filterChange("tahun", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='jurusan'
              placeholder='Jurusan'
              options={jurusanOptions}
              onChange={(e) => filterChange('jurusan', e)}
              styles={defaultSelectStyle}
              value={filters.jurusan}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='psb_flow'
              placeholder='Tahapan'
              options={psb_flowOptions}
              onChange={(e) => filterChange('psb_flow', e)}
              styles={defaultSelectStyle}
              value={filters.psb_flow}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='accepted'
              placeholder='Penerimaan'
              options={[
                { label: 'Diterima', value: 1 },
                { label: 'Ditolak', value: 0 },
              ]}
              onChange={(e) => filterChange('accepted', e)}
              styles={defaultSelectStyle}
              value={filters.accepted}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='done'
              placeholder='Status proses'
              options={[
                { label: 'Selesai', value: 1 },
                { label: 'Belum', value: 0 },
              ]}
              onChange={(e) => filterChange('done', e)}
              styles={defaultSelectStyle}
              value={filters.done}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='parent_status_value'
              placeholder='Orang tua'
              options={parentStatusOptions}
              onChange={(e) => filterChange('parent_status', e)}
              styles={defaultSelectStyle}
              value={filters.parent_status}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <FormControlLabel
              control={
                <Checkbox checked={filters.isMigrated === 1} onChange={(e) => filterChange('isMigrated', e)} />
              }
              label="Migrasi" />
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={() => getData(undefined, undefined, 'excel')}>
            Excel
          </SmallButton>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nomor</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Nama</TableCell>
                <TableCell sx={{ width: 180 }}>Jenis kelamin</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Sekolah</TableCell>
                <TableCell>Jurusan</TableCell>
                <TableCell>Tahapan</TableCell>
                <TableCell>Proses</TableCell>
                <TableCell>Administrasi</TableCell>
                <TableCell>Ringkasan</TableCell>
                <TableCell>Berhasil</TableCell>
                <TableCell>Diterima</TableCell>
                <TableCell>Selesai</TableCell>
                <TableCell>Migrasi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>{row.registration_number}</TableCell>
                  <TableCell>
                    <ClickableText text={row.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>
                    <Chip label={getsexTypeLabel(row.sex_type_value)} color="default" />
                  </TableCell>
                  <TableCell>{row.school && row.school.name}</TableCell>
                  <TableCell>{row.jurusan && row.jurusan.name}</TableCell>
                  <TableCell>{row.psb_flow && row.psb_flow.name}</TableCell>
                  <TableCell>
                    <StatusChip getStatus={getdoneStatusObject} status={row.flow_done_status_value} />
                  </TableCell>
                  <TableCell>
                    {
                      row.psb_flow && row.psb_flow.isAdminDecision === 1 &&
                      <Button variant='outlined' size='small' onClick={() => showDecisionDialog(row)}>Keputusan</Button>
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => showSummary(row)}
                      size="small"
                      aria-label="summary"
                      style={{ margin: 0 }}>
                      <Summarize fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <StatusChip getStatus={getyesNoStatusObject} status={row.psb_flow_status_value} />
                  </TableCell>

                  <TableCell>
                    {
                      row.isAccepted === 1 ? <CheckIcon /> : row.isAccepted === 0 ? <Close /> : null
                    }
                  </TableCell>

                  <TableCell>
                    {
                      row.isPsbDone === 1 ? <CheckIcon /> : null
                    }
                  </TableCell>
                  <TableCell>
                    {
                      row.student_id && <CheckIcon />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Dialog
      open={openDecisionDialog}
      maxWidth='sm'
      onClose={closeDecisionDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <Grid container sx={{ p: 2 }} spacing={2}>
        <Grid item xs={12} container justifyContent='center' sx={{ mb: 4 }}>
          {
            selectedStudentFlow &&
            <span>
              apakah <strong>{selectedStudentFlow.name}</strong> berhasil tahap <strong>{selectedStudentFlow.psb_flow.name}</strong> ?
            </span>
          }

        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => submitDecision(0)} variant='contained' fullWidth color='error' >Tidak</Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => submitDecision(1)} variant='contained' fullWidth color='success'>Ya</Button>
        </Grid>
      </Grid>
    </Dialog>

    <Dialog
      open={openSummaryDialog}
      maxWidth='sm'
      onClose={closeSummaryDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <Grid container justifyContent='center' sx={{ p: 2 }}>
        <Grid item sx={{ mb: 2 }}>

          {
            process.env.REACT_APP_SCHOOL_MODE === 'PESANTREN' && 'Ringkasan proses PSB'
          }
          {
            process.env.REACT_APP_SCHOOL_MODE === 'SEKOLAH' && 'Ringkasan proses PPDB'
          }
        </Grid>
        <Grid item xs={12}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Tahapan</TableCell>
                <TableCell>Selesai</TableCell>
                <TableCell>Berhasil</TableCell>
                <TableCell>Nilai</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                summaries.map(flow => (
                  <TableRow>
                    <TableCell>{flow.name}</TableCell>
                    <TableCell>
                      <StatusChip getStatus={getdoneStatusObject} status={flow.done_value} />
                    </TableCell>
                    <TableCell>
                      {
                        flow.done_value === 1 &&
                        <StatusChip getStatus={getyesNoStatusObject} status={flow.status_value} />
                      }

                    </TableCell>
                    <TableCell>{flow.score}</TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Dialog>

  </>;
}


export default NewStudentAdmin;

