import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormControlLabel, Switch, Checkbox } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { getsubjectTypeLabel, getsubjectTypeObject, subjectTypeOptions } from 'utils/options';


const SubjectDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError, showInfo } = useUI()
  const classes = useStyles();
  const reset = () => {
    return {
      id: undefined,
      order: undefined,
      name: undefined,
      code: undefined,
      nameEnglish: undefined,
      nameArabic: undefined,
      nameMandarin: undefined,
      parent_subject: null,
      parent_subject_id: undefined,
      jenjang: null,
      jenjang_id: undefined,
      kelas: null,
      kelas_id: undefined,
      jurusan: null,
      jurusan_id: undefined,
      subject_type: null,
      subject_type_value: undefined,
      isActive: 1,
      isSelectable: 0
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [parent_subjectOptions, setparent_subjectOptions] = useState([]);
  const [jenjangOptions, setjenjangOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isSelectable", "isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'jenjang') {
      if(value){
        newState['jenjang_id'] = value.value
        getkelasOptions(value.value)
        getjurusanOptions(value.value)
      }else{
        newState['jenjang_id'] = undefined
        newState['kelas'] = null
        newState['kelas_id'] = undefined
        newState['jurusan'] = null
        newState['jurusan_id'] = undefined
      }
      
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value?value.value:undefined
    }
    else if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value?value.value:undefined
    }
    else if (fieldName === 'subject_type') {
      newState['subject_type_value'] = value ? value.value : null
    } 
    else if (fieldName === 'parent_subject') {
      newState['parent_subject_id'] = value ? value.value : null
    }


    newState[fieldName] = value
    setstate(newState)
  }

  const getparent_subjectOptions = async () => {
    const params = {
      not_id: row ? row.id : undefined
    }
    const response = await axios.get(endpoint.subject.option, { params: params });
    if (response && response.data) {
      setparent_subjectOptions(generalListOptionMapper(response.data));
    }
  };

  const getjenjangOptions = async () => {
    const response = await axios.get(endpoint.jenjang.option);
    if (response && response.data) {
      setjenjangOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

 

  const insert = async (payload) => {
    const response = await axios.post(endpoint.subject.root, payload)
    if (response && response.data) {
      showSuccess('save subject')
      getData()
    } else {
      showError('save subject')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.subject.root, payload)
    if (response && response.data) {
      showSuccess('save subject')
      getData()
    } else {
      showError('save subject')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if(!state.name || state.name.trim()===''){
      showInfo('Nama harus diisi')
      return
    }
    if(!state.code || state.code.trim()===''){
      showInfo('Kode harus diisi')
      return
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      parent_subject: generalOptionMapper(row.parent_subject),
      jenjang: generalOptionMapper(row.jenjang),
      kelas: generalOptionMapper(row.kelas),
      jurusan: generalOptionMapper(row.jurusan),
      subject_type: getsubjectTypeObject(row.subject_type_value),
    })
  }

  useEffect(() => {
    if (pageAction !== 'READ') {
      getparent_subjectOptions()
      getjenjangOptions()
      getkelasOptions()
      getjurusanOptions()
    }
  }, [pageAction])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Urutan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.order} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='order'
                  fullWidth
                  type="number"
                  value={state.order}
                  onChange={(e) => handleChange('order', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama English
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nameEnglish} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nameEnglish'
                  fullWidth
                  value={state.nameEnglish}
                  onChange={(e) => handleChange('nameEnglish', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Arab
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nameArabic} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nameArabic'
                  fullWidth
                  value={state.nameArabic}
                  onChange={(e) => handleChange('nameArabic', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Mandarin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nameMandarin} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nameMandarin'
                  fullWidth
                  value={state.nameMandarin}
                  onChange={(e) => handleChange('nameMandarin', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pelajaran induk
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.parent_subject && state.parent_subject.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="parent_subject"
                  placeholder="Pilih pelajaran"
                  options={parent_subjectOptions}
                  value={state.parent_subject}
                  onChange={(e) => handleChange("parent_subject", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenjang
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.jenjang && state.jenjang.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="jenjang"
                  placeholder="Pilih Jenjang"
                  options={jenjangOptions}
                  value={state.jenjang}
                  onChange={(e) => handleChange("jenjang", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kelas
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.kelas && state.kelas.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="kelas"
                  placeholder="Pilih Kelas"
                  options={kelasOptions}
                  value={state.kelas}
                  onChange={(e) => handleChange("kelas", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jurusan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.jurusan && state.jurusan.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="jurusan"
                  placeholder="Pilih jurusan"
                  options={jurusanOptions}
                  value={state.jurusan}
                  onChange={(e) => handleChange("jurusan", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe pelajaran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getsubjectTypeLabel(state.subject_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="subject_type"
                  placeholder="Pilih tipe pelajaran"
                  options={subjectTypeOptions}
                  value={state.subject_type}
                  onChange={(e) => handleChange("subject_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pelajaran pilihan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isSelectable === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isSelectable === 1}
                  onChange={(e) => handleChange("isSelectable", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Aktif
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ActiveChip status={state.isActive} margin='0 0 0 8px' />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Switch
                  color='primary'
                  checked={state.isActive === 1}
                  onChange={(e) => handleChange('isActive', e)}
                />
              </Conditional>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default SubjectDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
