import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import DepartmentDetail from './DepartmentDetail';
import useUI from 'hooks/useUI';
import DepartmentPosition from './DepartmentPosition';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator';

// ----------------------------------------------------------------------

export default function DepartmentForm(props) {
  const {row, pageAction} = props
  const { startLoading, stopLoading, activeMenu = {}, activeForms = [] } = useUI()
  const [currentTab, setCurrentTab] = useState('detail');

  let TABS = [
    {
      value: 'detail',
      component: <DepartmentDetail {...props} />
    }
  ];

  if(row && pageAction!=="CREATE"){
    TABS = [
      ...TABS,
      {
        value: 'posisi',
        component: <DepartmentPosition {...props} />
      }
    ]
  }

  

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container>
      <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} style={{padding:8}}>
      <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
        {activeForms.map(form => (
          <>
            <BreadCrumbSeparator />
            <Typography color='primary' component='h5' variant='h5'>
              {form}
            </Typography>
          </>
        ))}
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
