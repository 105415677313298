import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { Avatar, CircularProgress, DialogTitle, Divider, Icon, List, ListItemAvatar, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { Box } from '@mui/system';
import useAuth from 'hooks/useAuth';


const PaymentMethod = (props) => {
  const { viaBalance = true, onSubmit } = props
  const { user, detail, students } = useAuth()
  const { showError } = useUI()
  
  const [paymentMethod, setpaymentMethod] = useState(1)
  const [paymentChannels, setpaymentChannels] = useState([])
  const [balances, setbalances] = useState([])
  const [selectedBalance, setselectedBalance] = useState(null)
  const [selectedChannel, setselectedChannel] = useState(null)


  const getpaymentChannels = async () => {
    const params = {}
    const response = await axios.get(endpoint.paymentChannel.option, { params: params })

    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
        props.onBack()
      } else {
        return setpaymentChannels(response.data)
      }

    } else {
      showError('get payment channel')
      props.onBack()
    }
  }

  const getbalances = async () => {
    const params = {
      student_ids: students.map(student => (student.id))
    }
    const response = await axios.get(endpoint.balance.option, { params: params })

    if (response) {
      return setbalances(response.data)
    } else {
      return []
    }
  }


  const balanceClick = (method, balance) => {
    setpaymentMethod(method)
    setselectedBalance(balance)
    setselectedChannel(null)
  }

  const channelClick = (method, channel) => {
    setpaymentMethod(method)
    setselectedChannel(channel)
    setselectedBalance(null)
  }

  const submitPayment = () => {
    if (paymentMethod === 1) {
      onSubmit(paymentMethod, selectedBalance)
    } else {
      onSubmit(paymentMethod, selectedChannel)
    }

  }


  useEffect(() => {
    getbalances()
    getpaymentChannels()
  }, [])

  return <>
    <DialogTitle>
      Pilih metode pembayaran
    </DialogTitle>

    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={viaBalance === true}>
            <List sx={{ width: '90%' }}
              subheader={
                <ListSubheader component="div" id="list-subheader">
                  Saldo
                </ListSubheader>
              }
            >
              {
                balances.map(balance => (
                  <ListItemButton selected={selectedBalance && selectedBalance.id === balance.id} onClick={() => balanceClick(1, balance)}>
                    <ListItemAvatar>
                      <Avatar>
                        <Icon>
                          account_balance_wallet
                        </Icon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={new Intl.NumberFormat().format(balance.balance)}
                      secondary={balance.student && balance.student.name}
                      sx={{ pl: 2 }} />
                  </ListItemButton>
                ))
              }
            </List>
            <Divider />
          </Conditional>
          <List
            component="nav"
            aria-labelledby="list-subheader"
            subheader={
              <ListSubheader component="div" id="list-subheader">
                Virtual account
              </ListSubheader>
            }
            sx={{ width: '90%' }}
          >
            {
              paymentChannels.map(channel => (
                <ListItemButton selected={selectedChannel && selectedChannel.id === channel.id} onClick={() => channelClick(2, channel)}>
                  <Avatar alt={channel.name} src={channel.logo} />
                  <ListItemText primary={channel.name} sx={{ pl: 2 }} />
                </ListItemButton>
              ))
            }

          </List>
        </Grid>
      </Grid>
    </DialogContent>

    <DialogActions sx={{ p: 2 }}>
      <Conditional condition={props.isLoading === false}>
        <Button onClick={props.onBack} color="secondary">
          Kembali
        </Button>
        {
          (selectedChannel || selectedBalance) &&
          <Button variant="contained" onClick={submitPayment} color="primary">
            Lanjutkan Pembayaran
          </Button>
        }

      </Conditional>

      <Conditional condition={props.isLoading === true}>
        <CircularProgress />
      </Conditional>
    </DialogActions>
  </>;
}

export default PaymentMethod;

