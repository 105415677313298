import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import ActionDetail from './ActionDetail';
import useUI from 'hooks/useUI';
import ActionApprover from './ActionApprover';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator';

// ----------------------------------------------------------------------

export default function ActionForm(props) {
  const {row, pageAction} = props
  const {activeMenu={}, activeForms=[]} = useUI()
  const [currentTab, setCurrentTab] = useState('detail');

  let TABS = [
    {
      value: 'detail',
      component: <ActionDetail {...props} />
    }
  ];

  if(row && pageAction!=="CREATE"){
    TABS = [
      ...TABS,
      {
        value: 'approver',
        component: <ActionApprover {...props} />
      }
    ]
  }

  

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container>
      <Grid container item alignItems='center' alignContent='center' xs={12} sx={{pt:2}}>
        <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
        {activeForms.map(form=>(
            <>
            <BreadCrumbSeparator/>
            <Typography color='primary' component='h5' variant='h5'>
              {form}
            </Typography>
            </>
          ))}
      </Grid>
      <Box
        sx={{
          paddingBottom: 1
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 1 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
