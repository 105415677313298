import { Grid, Box, Paper, Typography, Dialog } from '@mui/material';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import useUI from 'hooks/useUI';
import React, { useEffect, useState } from 'react'
import BillTransaction from 'views/bill/BillTransaction';
import TransactionIncome from 'views/transaction/TransactionIncome';
import TransactionExpense from 'views/transaction/TransactionExpense';

const FinancialActionShortcut = (props) => {

    const { gotoPage, showError } = useUI()
    const [openBayaranDialog, setopenBayaranDialog] = useState(false);
    const [openIncomeDialog, setopenIncomeDialog] = useState(false);
    const [openExpenseDialog, setopenExpenseDialog] = useState(false);
    const [definedIncome, setdefinedIncome] = useState(null);
    const [definedExpense, setdefinedExpense] = useState(null);
    const [definedIncomeOptions, setdefinedIncomeOptions] = useState([]);
    const [definedExpenseOptions, setdefinedExpenseOptions] = useState([]);


    const closeBayaranDialog = () => {
        setopenBayaranDialog(false)
    }

    const showIncomeDialog = (definedIncome) => {
        setdefinedIncome(definedIncome)
        setopenIncomeDialog(true)
    }

    const showExpenseDialog = (definedExpense) => {
        setdefinedExpense(definedExpense)
        setopenExpenseDialog(true)
    }

    const closeExpenseDialog = () => {
        setopenExpenseDialog(false)
    }
    const closeIncomeDialog = () => {
        setopenIncomeDialog(false)
    }


    const getdefinedIncomeOptions = async () => {
        const params = {}
        const response = await axios.get(endpoint.definedIncome.option, { params: params });
        if (response && response.data) {
            setdefinedIncomeOptions(response.data);
        }
    };

    const getdefinedExpenseOptions = async () => {
        const params = {}
        const response = await axios.get(endpoint.definedExpense.option, { params: params });
        if (response && response.data) {
            setdefinedExpenseOptions(response.data);
        }
    };

    useEffect(() => {
        getdefinedIncomeOptions()
        getdefinedExpenseOptions()
    }, [])

    return (
        <>
            <Grid item xs={12} container justifyContent='center'
                sx={{
                    px: 2,
                }}
            >
                <Grid item container justifyContent='flex-start' spacing={1}
                    sx={{
                        color: '#393a3c',
                        pt: 1,
                        pb: 2,
                        px: 2,
                        mb: 2,
                        borderRadius: 4,
                        background: 'white'
                    }}
                >
                    <Grid item xs={12} container justifyContent='flex-start'>
                        <Typography variant="subtitle2" component="div">
                            Transaksi keuangan
                        </Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={6} onClick={() => setopenBayaranDialog(true)} sx={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                minWidth: 100,
                                height: 48,
                                p: 1,
                                backgroundColor: '#069525',
                                color: 'white',
                            }}
                            elevation={4}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    Bayaran
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item md={3} sm={4} xs={6} onClick={() => setopenBayaranDialog(true)} sx={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                minWidth: 100,
                                height: 48,
                                p: 1,
                                backgroundColor: '#069525',
                                color: 'white',
                            }}
                            elevation={4}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    Donasi
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    {
                        definedIncomeOptions.map(definedIncome => (
                            <Grid item md={3} sm={4} xs={6} onClick={() => showIncomeDialog(definedIncome)} sx={{ cursor: 'pointer' }}>
                                <Paper
                                    sx={{
                                        minWidth: 100,
                                        height: 48,
                                        p: 1,
                                        backgroundColor: '#069525',
                                        color: 'white',
                                    }}
                                    elevation={4}>
                                    <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                        <Typography variant="h5" component="div">
                                            {definedIncome.name}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))
                    }

                    <Grid item md={3} sm={4} xs={6} onClick={() => setopenIncomeDialog(true)} sx={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                minWidth: 100,
                                height: 48,
                                p: 1,
                                backgroundColor: '#069525',
                                color: 'white',
                            }}
                            elevation={4}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    Penerimaan lain
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    

                    <Grid item md={3} sm={4} xs={6} onClick={() => setopenBayaranDialog(true)} sx={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                minWidth: 100,
                                height: 48,
                                p: 1,
                                backgroundColor: '#e21867',
                                color: 'white',
                            }}
                            elevation={4}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    Tarik tunai
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item md={3} sm={4} xs={6} onClick={() => setopenBayaranDialog(true)} sx={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                minWidth: 100,
                                height: 48,
                                p: 1,
                                backgroundColor: '#e21867',
                                color: 'white',
                            }}
                            elevation={4}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    Pencairan toko
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    {
                        definedExpenseOptions.map(definedExpense => (
                            <Grid item md={3} sm={4} xs={6} onClick={() => showExpenseDialog(definedExpense)} sx={{ cursor: 'pointer' }}>
                                <Paper
                                    sx={{
                                        minWidth: 100,
                                        height: 48,
                                        p: 1,
                                        backgroundColor: '#e21867',
                                        color: 'white',
                                    }}
                                    elevation={4}>
                                    <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                        <Typography variant="h5" component="div">
                                            {definedExpense.name}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))
                    }

                    <Grid item md={3} sm={4} xs={6} onClick={() => setopenExpenseDialog(true)} sx={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                minWidth: 100,
                                height: 48,
                                p: 1,
                                backgroundColor: '#e21867',
                                color: 'white',
                            }}
                            elevation={4}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    Pengeluaran lain
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    

       



                </Grid>

            </Grid>

            <Dialog
                open={openBayaranDialog}
                maxWidth='lg'
                onClose={closeBayaranDialog}
                fullWidth
                fullScreen={false}
                scroll="body"
            >
                <BillTransaction
                    pageAction='PAYMENT'
                    closeDialog={closeBayaranDialog}
                />
            </Dialog>

            <Dialog
                open={openIncomeDialog}
                maxWidth='lg'
                onClose={closeIncomeDialog}
                fullWidth
                fullScreen={false}
                scroll="body"
            >
                <TransactionIncome
                    pageAction='INCOME'
                    closeDialog={closeIncomeDialog}
                    definedIncome={definedIncome}
                />
            </Dialog>

            <Dialog
                open={openExpenseDialog}
                maxWidth='lg'
                onClose={closeExpenseDialog}
                fullWidth
                fullScreen={false}
                scroll="body"
            >
                <TransactionExpense
                    pageAction='EXPENSE'
                    closeDialog={closeExpenseDialog}
                    definedExpense={definedExpense}
                />
            </Dialog>
        </>

    );

}

export default FinancialActionShortcut;
