import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, jamListOptionMapper, parameterListOptionMapper, parameterOptionMapper, studentListOptionMapper } from 'utils/mappers';
import BooleanChip from 'components/BooleanChip';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format, parse } from 'date-fns'
import AsyncSelect from 'react-select/async';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import StatusChip from 'components/StatusChip';
import useAuth from 'hooks/useAuth';
import { getapprovalStatusObject } from 'utils/options';
import { Calendar } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"


const PermissionDetail = (props) => {
  const { row = {}, getData } = props
  const { user, detail, students } = useAuth()
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      user_type: null,
      user_type_value: undefined,
      employee: null,
      employee_id: undefined,
      student: null,
      student_id: undefined,
      permission_type: null,
      permission_type_id: undefined,
      jenjang: null,
      jenjang_id: undefined,
      school: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      parallel: null,
      parallel_id: undefined,
      jam_pelajarans: [],
      dates: [],
      days: 0,
      reason: undefined,
      isFullDay: 1,
      isHadir: 0,
      attachment: undefined,
      employee_input: null,
      employee_input_id: undefined,
      approval_statu: null,
      approval_status_value: 0,
      approval: null
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [permission_typeOptions, setpermission_typeOptions] = useState([])
  const [jamOptions, setjamOptions] = useState([])


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue
    if (fieldName === 'permission_type') {
      newState['permission_type_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'student') {
      newState['student_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (["isFullDay"].includes(fieldName)) {
      value = eventValue.target.checked ? 0 : 1;
    }
    else if (fieldName === 'dates') {
      value = eventValue.map(dateObj => (dateObj.toDate()))
      newState['days'] = value.length
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getpermission_typeOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.permissionType.option, { params: params });
    if (response && response.data) {
      setpermission_typeOptions(generalListOptionMapper(response.data));
    }
  };

  const getjamOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.jamPelajaran.option, { params: params });
    if (response && response.data) {
      setjamOptions(jamListOptionMapper(response.data));
    }
  };


  const insert = async (payload) => {
    const response = await axios.post(endpoint.permission.root, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('save permission')
        getData()
        props.closeDialog()
      }
    } else {
      showError('save permission')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.permission.root, payload)
    if (response && response.data) {
      showSuccess('save permission')
      getData()
      props.closeDialog()
    } else {
      showError('save permission')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      user_id: user.id,
      employee_id: user.user_type_value === 1 ? detail.id : undefined,
      student_id: user.user_type_value === 2 ? detail.id : undefined,
      student_id: user.user_type_value === 3 ? state.student_id : undefined,
      user_type_value: user.user_type_value === 3 ? 2 : user.user_type_value,
      dates: state.dates.map(date => (format(date, 'yyyy-MM-dd'))),
      jam_ids: state.jam_pelajarans.map(jam => (jam.id)),
      jam_kes: state.jam_pelajarans.map(jam => (jam.jam_ke))
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {

    let params = {}

    const response = await axios.get(endpoint.permission.detail.replace("id", row.id), params)
    if (response) {
      let row = response.data
      setstate({
        ...row,
        permission_type: parameterOptionMapper(row.permission_type),
        dates: row.dates.map(date => (parse(date.permissionDate, 'yyyy-MM-dd', new Date()))),
        jam_pelajarans: row.jam_pelajarans.map(jam => ({ value: jam.id, label: `${jam.jam_ke}(${jam.school.code})` }))
      })
    }

  }


  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getpermission_typeOptions()
      getjamOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])


  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          
          <Conditional condition={pageAction === "READ"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nama
              </Grid>
              <Grid item sm={10} xs={12}> 
                {
                  state.student && <ReadOnlyTextField value={state.student.name} />
                }
                {
                  state.employee && <ReadOnlyTextField value={state.employee.name} />
                }
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe Izin
            </Grid>
            <Grid item sm={10} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.permission_type && state.permission_type.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="Type"
                  placeholder="Pilih tipe"
                  options={permission_typeOptions}
                  value={state.permission_type}
                  onChange={(e) => handleChange("permission_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
              Keterangan
            </Grid>
            <Grid item sm={10} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.reason} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='reason'
                  fullWidth
                  value={state.reason}
                  onChange={(e) => handleChange('reason', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container spacing={1} justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pilih Tanggal
            </Grid>
            <Grid item sm={10} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Calendar
                  multiple
                  value={state.dates}
                  plugins={[
                    <DatePanel />
                  ]}
                />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Calendar
                  multiple
                  value={state.dates}
                  onChange={(e) => handleChange('dates', e)}
                  plugins={[
                    <DatePanel />
                  ]}
                />
              </Conditional>
            </Grid>

          </Grid>


          <Conditional condition={state.dates.length === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
                Hanya jam tertentu
              </Grid>
              <Grid item sm={8} xs={2}>
                <Conditional condition={pageAction === "READ"}>
                  <Checkbox checked={state.isFullDay === 0} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Checkbox
                    checked={state.isFullDay === 0}
                    onChange={(e) => handleChange("isFullDay", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.isFullDay === 0}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jam pelajaran
              </Grid>
              <Grid item sm={10} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <Select
                    isMulti
                    name="jam_pelajarans"
                    placeholder="Pilih jam pelajaran"
                    options={permission_typeOptions}
                    value={state.jam_pelajarans}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    isMulti
                    name="jam_pelajarans"
                    placeholder="Pilih jam pelajaran"
                    options={jamOptions}
                    value={state.jam_pelajarans}
                    onChange={(e) => handleChange("jam_pelajarans", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={pageAction !== "CREATE"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={2} xs={4} container justifyContent='flex-start' alignContent='center'>
                Status
              </Grid>
              <Grid item sm={10} xs={8}>
                <StatusChip getStatus={getapprovalStatusObject} status={state.approval_status_value} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === "READ"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
                Diputuskan oleh
              </Grid>
              <Grid item sm={10} xs={12}>
                <ReadOnlyTextField value={state.approval && state.approval.approval_by && state.approval.approval_by.name} />
              </Grid>
            </Grid>

            <Conditional condition={state.approval_status_value === 2}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={2} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Alasan
                </Grid>
                <Grid item sm={10} xs={12}>
                  <ReadOnlyTextField value={state.approval && state.approval.reason} />
                </Grid>
              </Grid>
            </Conditional>

          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </LocalizationProvider>;
}

export default PermissionDetail;

