import React, { useState, useEffect } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import { Switch, Dialog, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, Box } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getbillPriceTypeObject, getlifeStatusLabel, getlifeStatusObject, getreligionLabel, getreligionObject, getsexTypeLabel, getsexTypeObject, getstayWithLabel, getstayWithObject, getstudentStatusLabel, getstudentStatusObject, getvehicleTypeLabel, lifeStatusOptions, religionOptions, sexTypeOptions, stayWithOptions, studentStatusOptions, vehicleTypeOptions } from 'utils/options';
import { cardOptionMapper, generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import SmallButton from 'components/SmallButton';
import ParentForm from 'views/parent/ParentForm';
import StatusChip from 'components/StatusChip';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';


const StudentDetail = (props) => {
  const { row, getData } = props
  const { detail } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, activeForms, showSuccess, showError, setActiveForms, isAllDataAccess } = useUI()
  const initialState = {
    id: undefined,
    user: null,
    user_id: undefined,
    virtual_account: undefined,
    father_parent: null,
    father_parent_id: undefined,
    mother_parent: null,
    mother_parent_id: undefined,
    father_employee: null,
    father_employee_id: undefined,
    mother_employee: null,
    mother_employee_id: undefined,
    wali_parent: null,
    wali_parent_id: undefined,
    wali_employee: null,
    wali_employee_id: undefined,
    jenjang: null,
    jenjang_id: undefined,
    school: null,
    school_id: undefined,
    kelas: null,
    kelas_id: undefined,
    parallel: null,
    parallel_id: undefined,
    masuk_tahun: null,
    masuk_tahun_id: undefined,
    masuk_jenjang: null,
    masuk_jenjang_id: undefined,
    masuk_kelas: null,
    masuk_kelas_id: undefined,
    province: null,
    province_id: undefined,
    regency: null,
    regency_id: undefined,
    district: null,
    district_id: undefined,
    village: null,
    village_id: undefined,
    rw: undefined,
    rt: undefined,
    postalCode: undefined,
    vehicle_type: null,
    vehicle_type_value: undefined,
    FatherAlive: { value: 1, label: 'Hidup' },
    isFatherAlive: 1,
    MotherAlive: { value: 1, label: 'Hidup' },
    isMotherAlive: 1,
    isPoor: 0,
    npsn: undefined,
    nis: undefined,
    nisn: undefined,
    nkk: undefined,
    nik: undefined,
    akta: undefined,
    mobilePhone: undefined,
    email: undefined,
    skhun: undefined,
    pesertaUN: undefined,
    ijazah: undefined,
    isKps: 0,
    kps: undefined,
    isKip: 0,
    kip: undefined,
    kipName: undefined,
    kks: undefined,
    pip_bank_account_number: undefined,
    pip_bank_account_name: undefined,
    height: 0,
    weight: 0,
    head: 0,
    homeDistance: 0,
    travelTime: 0,
    name: undefined,
    sex_type: null,
    sex_type_value: 1,
    address: undefined,
    photo: undefined,
    birthPlace: undefined,
    birthDate: new Date(),
    birthOrder: 1,
    religion: null,
    religion_value: 1,
    nationality: null,
    nationality_value: 1,
    isAdopted: 0,
    stay_with: null,
    stay_with_value: 1,
    siblings: 0,
    hoby: undefined,
    dream: undefined,
    medicalHistory: undefined,
    achievementHistory: undefined,
    isActive: 1,
    student_status: null,
    student_status_value: 1,
    lulus_tahun: null,
    lulus_tahun_id: undefined,
    tahunLulus: undefined,
    smart_card: null,
    smart_card_value: undefined,
    card_number: undefined,
    bill_price_type_value: 1,
    scholarship: undefined
  }

  const [state, setstate] = useState(initialState)
  const [pageAction, setpageAction] = useState('READ')
  const [smart_cardOptions, setsmart_cardOptions] = useState([]);
  const [jenjangOptions, setjenjangOptions] = useState([]);
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [provinceOptions, setprovinceOptions] = useState([]);
  const [regencyOptions, setregencyOptions] = useState([]);
  const [districtOptions, setdistrictOptions] = useState([]);
  const [villageOptions, setvillageOptions] = useState([]);
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [nationalityOptions, setnationalityOptions] = useState([]);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [nameFatherParentKeyWord, setnameFatherParentKeyWord] = useState('');
  const [nameMotherParentKeyWord, setnameMotherParentKeyWord] = useState('');
  const [nameWaliParentKeyWord, setnameWaliParentKeyWord] = useState('');


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (['isRestricted', 'isActive', 'isPoor', 'isAdopted'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    if (fieldName === 'school') {
      newState['school_id'] = value.value
      newState['jenjang_id'] = value.jenjang_id
      getkelasOptions(value.jenjang_id)
      getjurusanOptions(value.jenjang_id)
    }
    else if (fieldName === 'father_parent') {
      newState['father_parent_id'] = value ? value.value : value
    }
    else if (fieldName === 'mother_parent') {
      newState['mother_parent_id'] = value ? value.value : value
    }
    else if (fieldName === 'wali_parent') {
      newState['wali_parent_id'] = value ? value.value : value
    }
    else if (fieldName === 'smart_card') {
      newState['smart_card_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'province') {
      newState['province_id'] = value ? value.value : undefined
      getRegencyOptions(value.value)
      newState['regency'] = null
      newState['regency_id'] = undefined
      newState['district'] = null
      newState['district_id'] = undefined
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'regency') {
      newState['regency_id'] = value ? value.value : undefined
      getDistrictOptions(value.value)
      newState['district'] = null
      newState['district_id'] = undefined
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'district') {
      newState['district_id'] = value ? value.value : undefined
      getVillageOptions(value.value)
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'village') {
      newState['village_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
      getparallelOptions(value ? value.value : undefined, undefined)
    }
    else if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value ? value.value : undefined
      getparallelOptions(undefined, value ? value.value : undefined)
    }
    else if (fieldName === 'masuk_jenjang') {
      newState['masuk_jenjang_id'] = value ? value.value : undefined
      getkelasOptions(value ? value.value : undefined)
    }
    else if (fieldName === 'FatherAlive') {
      newState['isFatherAlive'] = value ? value.value : undefined
    }
    else if (fieldName === 'MotherAlive') {
      newState['isMotherAlive'] = value ? value.value : undefined
    }
    else if (fieldName === 'lulus_tahun') {
      newState['lulus_tahun_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'masuk_kelas') {
      newState['masuk_kelas_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'masuk_tahun') {
      newState['masuk_tahun_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'nationality') {
      newState['nationality_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'parallel') {
      newState['parallel_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'religion') {
      newState['religion_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'stay_with') {
      newState['stay_with_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'student_status') {
      if(value){
        newState['student_status_value'] = value ? value.value : undefined
        if(value.value === 1){
          newState['isActive'] = 1;
          newState['lulus_tahun'] = null;
          newState['lulus_tahun_id'] = undefined;
          newState['tahunLulus'] = undefined;
        }
        else{
          newState['isActive'] = 0;
        }
      }
      
    }
    else if (fieldName === 'bill_price_type_value') {
      value = parseInt(eventValue.target.value)
    }

    if (fieldName === 'siblings') {
      newState[fieldName] = parseInt(value) || '0'
    } else {
      newState[fieldName] = value
    }

    setstate(newState)
  }

  const getParentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.parent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadParentOptions = async (inputValue, callback) => {
    callback(await getParentOptions(inputValue))
  };

  const handleInputChangeFatherParent = (newValue) => {
    setnameFatherParentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeMotherParent = (newValue) => {
    setnameMotherParentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeWaliParent = (newValue) => {
    setnameWaliParentKeyWord(newValue)
    return newValue;
  };

  const getsmart_cardOptions = async () => {
    const params = {
      card_status_value: 1
    }
    const response = await axios.get(endpoint.smartCard.option, { params: params });
    if (response && response.data) {
      setsmart_cardOptions(response.data.map(row => ({ ...row, label: row.number, value: row.number })));
    }
  };

  const getjenjangOptions = async () => {
    const response = await axios.get(endpoint.jenjang.option);
    if (response && response.data) {
      setjenjangOptions(generalListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async (kelas_id, jurusan_id) => {
    const params = {
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const getprovinceOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.province, { params: params });
    if (response && response.data) {
      setprovinceOptions(generalListOptionMapper(response.data));
    }
  };

  const getRegencyOptions = async (province_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.provinceRegency.replace('id', province_id), { params: params });
    if (response && response.data) {
      setregencyOptions(generalListOptionMapper(response.data));
    }
  };

  const getDistrictOptions = async (regency_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.regencyDistrict.replace('id', regency_id), { params: params });
    if (response && response.data) {
      setdistrictOptions(generalListOptionMapper(response.data));
    }
  };

  const getVillageOptions = async (district_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.districtVillage.replace('id', district_id), { params: params });
    if (response && response.data) {
      setvillageOptions(generalListOptionMapper(response.data));
    }
  };

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const getnationalityOptions = async () => {
    const params = {
      group: 'nationality'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setnationalityOptions(parameterListOptionMapper(response.data));
    }
  };

  const syncParent = async () => {
    const response = await axios.post(endpoint.student.syncParent, { student_id: state.id });
    if (response && response.data) {
      getRowDetail()
      showSuccess('sync parent')
    }
  };

  const showParentDetail = (parent) => {
    let params = {
      parent: parent
    }
    showDialog('READ', params)
  }

  const createFather = () => {
    let params = {
      student_id: row ? row.id : undefined,
      sex_type_value: 1,
      parent_type_value: 1
    }
    showDialog('CREATE', params)
  }

  const createMother = () => {
    let params = {
      student_id: row ? row.id : undefined,
      sex_type_value: 1,
      parent_type_value: 1
    }
    showDialog('CREATE', params)
  }

  const createWali = () => {
    let params = {
      student_id: row ? row.id : undefined,
      parent_type_value: 2
    }
    showDialog('CREATE', params)
  }


  const showDialog = async (actionCode, params) => {
    setMaxWidth('md');
    let parent = undefined
    if(params.parent){
      const response = await axios.get(endpoint.parent.detail.replace('id', params.parent.id), {})
      if(response){
        parent = response.data
      }
    }
    setdialogContent(
      <ParentForm
        row = {parent}
        params={params}
        getData={getRowDetail}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );
    setActiveForms([...activeForms, 'Parent'])
    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    setActiveForms(activeForms.filter(form => form !== 'Parent'))
  }

  const selectImage = async (event) => {
    let file = event.target.files[0]
    setstate({
      ...state,
      photoFile: file,
      photo: URL.createObjectURL(file)
    })

    if (pageAction === 'EDIT') {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('id', row.id)
      const response = await axios.post(endpoint.student.photo, formData, {});
      if (response && response.data) {
        showSuccess('save photo')
      } else {
        showError('save photo')
      }
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.student.root, payload)
    if (response && response.data) {
      showSuccess('save student')
      getData()
    } else {
      showError('save student')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.student.root, payload)
    if (response && response.data) {
      showSuccess('save student')
      getRowDetail()
      getData()
    } else {
      showError('save student')
    }
  }

  const submit = async () => {

    if (!state.name) {
      showError('Mohon isi nama')
      return
    }

    if (!state.school_id) {
      showError('Mohon pilih sekolah')
      return
    }

    if (!state.kelas_id) {
      showError('Mohon pilih kelas')
      return
    }

    if (!state.masuk_tahun_id) {
      showError('Mohon pilih tahun masuk')
      return
    }

    if (!state.masuk_jenjang_id) {
      showError('Mohon pilih jenjang masuk')
      return
    }

    if (!state.masuk_kelas_id) {
      showError('Mohon pilih kelas masuk')
      return
    }

    if (!state.nis) {
      showError('Mohon isi nis')
      return
    }

    if (!state.sex_type_value) {
      showError('Mohon pilih jenis kelamin')
      return
    }

    if (!state.birthPlace) {
      showError('Mohon isi kota kelahiran')
      return
    }

    if (!state.birthDate) {
      showError('Mohon isi tanggal lahir')
      return
    }

    if (!state.address) {
      showError('Mohon isi alamat')
      return
    }

    if (!state.student_status_value) {
      showError('Mohon pilih status')
      return
    }

    const params = {
      ...state,
      birthDate: format(state.birthDate, 'yyyy-MM-dd')
    }

    if (pageAction === 'CREATE') {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {

    if (row) {
      let params = {}
      const response = await axios.get(endpoint.student.detail.replace('id', row.id), params)
      if (response && response.data) {
        let student = response.data
        setstate({
          ...student,
          smart_card: student.card_number ? { label: student.card_number, value: student.card_number } : null,
          father_parent: generalOptionMapper(student.father_parent),
          mother_parent: generalOptionMapper(student.mother_parent),
          wali_parent: generalOptionMapper(student.wali_parent),
          school: generalOptionMapper(student.school),
          kelas: generalOptionMapper(student.kelas),
          jurusan: generalOptionMapper(student.jurusan),
          parallel: generalOptionMapper(student.parallel),
          province: generalOptionMapper(student.province),
          regency: generalOptionMapper(student.regency),
          district: generalOptionMapper(student.district),
          village: generalOptionMapper(student.village),
          masuk_tahun: generalOptionMapper(student.masuk_tahun),
          masuk_jenjang: generalOptionMapper(student.masuk_jenjang),
          masuk_kelas: generalOptionMapper(student.masuk_kelas),
          nationality: parameterOptionMapper(student.nationality),
          FatherAlive: getlifeStatusObject(student.isFatherAlive),
          MotherAlive: getlifeStatusObject(student.isMotherAlive),
          sex_type: getsexTypeObject(student.sex_type_value),
          religion: getreligionObject(student.religion_value),
          stay_with: getstayWithObject(student.stay_with_value),
          student_status: getstudentStatusObject(student.student_status_value),
          lulus_tahun: generalOptionMapper(student.lulus_tahun),
          birthDate: parse(student.birthDate, 'yyyy-MM-dd', new Date()),
          photo: `${api.url}/${row.photo}`
        })

        setpageAction(props.pageAction) //agar ketika handle change, state bukan yang initial

      }
    }

  }

  useEffect(() => {
    if (props.pageAction === 'CREATE') {
      setpageAction('CREATE')
    }
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getnationalityOptions()
      gettahunPelajaranOptions()
      getjenjangOptions()
      getschoolOptions()
      getsmart_cardOptions()
      getprovinceOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(initialState)
    }

  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='flex-end'>
            <Conditional condition={pageAction === 'READ'}>
              <Protected allowedCodes={['EDIT']}>
                <Button onClick={() => setpageAction('EDIT')} color='primary'>
                  Edit
                </Button>
              </Protected>
            </Conditional>
            <Button onClick={props.closeDialog} color='secondary'>
              Kembali
            </Button>
            <Conditional condition={pageAction !== 'READ'}>
              <Button variant='contained' onClick={submit} color='primary'>
                Simpan
              </Button>
            </Conditional>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Foto
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box sx={{ width: 100, position: 'relative' }}>
                <img
                  src={state.photo}
                  style={{ width: 100, height: 100, borderRadius: 16 }} />
                <Conditional condition={pageAction === 'EDIT'}>
                  <label htmlFor='propic'>
                    <input onChange={selectImage} accept="image/*" id='propic' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                  </label>
                </Conditional>

              </Box>

            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Username
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.user && state.user.username} />
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Smart card
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.card_number} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='smart_card'
                  placeholder='Pilih smart card'
                  options={smart_cardOptions}
                  value={state.smart_card}
                  onChange={(e) => handleChange('smart_card', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ayah
            </Grid>
            <Grid item sm={8} xs={12} container>
              <Grid item xs={8}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.father_parent && state.father_parent.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih ayah..."
                    value={state.father_parent}
                    loadOptions={loadParentOptions}
                    onInputChange={handleInputChangeFatherParent}
                    onChange={(e) => handleChange('father_parent', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
              <Grid item xs={4} container alignContent='center'>
                {
                  state.father_parent && <SmallButton onClick={() => showParentDetail(state.father_parent)}>detail</SmallButton>
                }
                <Conditional condition={pageAction === 'EDIT'}>
                  <SmallButton onClick={createFather} color='success'>baru</SmallButton>
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ibu
            </Grid>

            <Grid item sm={8} xs={12} container>
              <Grid item xs={8}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.mother_parent && state.mother_parent.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih ibu..."
                    value={state.mother_parent}
                    loadOptions={loadParentOptions}
                    onInputChange={handleInputChangeMotherParent}
                    onChange={(e) => handleChange('mother_parent', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
              <Grid item xs={4} container alignContent='center'>
                {
                  state.mother_parent && <SmallButton onClick={() => showParentDetail(state.mother_parent)}>detail</SmallButton>
                }
                <Conditional condition={pageAction === 'EDIT'}>
                  <SmallButton onClick={createMother} color='success'>baru</SmallButton>
                </Conditional>
              </Grid>
            </Grid>

          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Wali
            </Grid>

            <Grid item sm={8} xs={12} container>
              <Grid item xs={8}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.wali_parent && state.wali_parent.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih wali..."
                    value={state.wali_parent}
                    loadOptions={loadParentOptions}
                    onInputChange={handleInputChangeWaliParent}
                    onChange={(e) => handleChange('wali_parent', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
              <Grid item xs={4} container alignContent='center'>
                {
                  state.wali_parent && <SmallButton onClick={() => showParentDetail(state.wali_parent)}>detail</SmallButton>
                }

                <Conditional condition={pageAction === 'EDIT'}>
                  <SmallButton onClick={createWali} color='success'>baru</SmallButton>
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                NIK anak
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.nik} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='nik'
                    fullWidth
                    value={state.nik}
                    onChange={(e) => handleChange('nik', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.school && state.school.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='school'
                  placeholder='Pilih sekolah'
                  options={schoolOptions}
                  value={state.school}
                  onChange={(e) => handleChange('school', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kelas
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.kelas && state.kelas.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='kelas'
                  placeholder='Pilih Kelas'
                  options={kelasOptions}
                  value={state.kelas}
                  onChange={(e) => handleChange('kelas', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jurusan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.jurusan && state.jurusan.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='jurusan'
                  placeholder='Pilih jurusan'
                  options={jurusanOptions}
                  value={state.jurusan}
                  onChange={(e) => handleChange('jurusan', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Parallel
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.parallel && state.parallel.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='parallel'
                  placeholder='Pilih Parallel'
                  options={parallelOptions}
                  value={state.parallel}
                  onChange={(e) => handleChange('parallel', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun Masuk
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.masuk_tahun && state.masuk_tahun.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='masuk_tahun'
                  placeholder='Pilih tahun masuk'
                  options={tahunPelajaranOptions}
                  value={state.masuk_tahun}
                  onChange={(e) => handleChange('masuk_tahun', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenjang Masuk
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.masuk_jenjang && state.masuk_jenjang.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='masuk_jenjang'
                  placeholder='Pilih jenjang masuk'
                  options={jenjangOptions}
                  value={state.masuk_jenjang}
                  onChange={(e) => handleChange('masuk_jenjang', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kelas Masuk
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.masuk_kelas && state.masuk_kelas.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='masuk_kelas'
                  placeholder='Pilih kelas masuk'
                  options={kelasOptions}
                  value={state.masuk_kelas}
                  onChange={(e) => handleChange('masuk_kelas', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                NPSN sekolah asal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.npsn} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='npsn'
                    fullWidth
                    value={state.npsn}
                    onChange={(e) => handleChange('npsn', e)}
                  />
                </Conditional>

              </Grid>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              NIS
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nis} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nis'
                  fullWidth
                  value={state.nis}
                  onChange={(e) => handleChange('nis', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              NISN
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nisn} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nisn'
                  fullWidth
                  value={state.nisn}
                  onChange={(e) => handleChange('nisn', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor KK
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nkk} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nkk'
                  fullWidth
                  value={state.nkk}
                  onChange={(e) => handleChange('nkk', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Virtual account
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.virtual_account} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='virtual_account'
                  fullWidth
                  value={state.virtual_account}
                  onChange={(e) => handleChange('virtual_account', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor akta lahir
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.akta} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='akta'
                    fullWidth
                    value={state.akta}
                    onChange={(e) => handleChange('akta', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor handphone
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.mobilePhone} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='mobilePhone'
                    fullWidth
                    value={state.mobilePhone}
                    onChange={(e) => handleChange('mobilePhone', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Email
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.email} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='email'
                    fullWidth
                    value={state.email}
                    onChange={(e) => handleChange('email', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor SKHUN
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.skhun} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='skhun'
                    fullWidth
                    value={state.skhun}
                    onChange={(e) => handleChange('skhun', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor peserta Ujian Nasional
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.pesertaUN} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='pesertaUN'
                    fullWidth
                    value={state.pesertaUN}
                    onChange={(e) => handleChange('pesertaUN', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor ijazah terakhir
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.ijazah} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='ijazah'
                    fullWidth
                    value={state.ijazah}
                    onChange={(e) => handleChange('ijazah', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tinggi badan (cm)
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.height} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='height'
                    fullWidth
                    value={state.height}
                    onChange={(e) => handleChange('height', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Berat badan (kg)
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.weight} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='weight'
                    fullWidth
                    value={state.weight}
                    onChange={(e) => handleChange('weight', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Lingkar kepala (cm)
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.head} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='head'
                    fullWidth
                    value={state.head}
                    onChange={(e) => handleChange('head', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tinggal bersama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getstayWithLabel(state.stay_with_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='stay_with'
                  placeholder='Pilih keluarga'
                  options={stayWithOptions}
                  value={state.stay_with}
                  onChange={(e) => handleChange('stay_with', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Anak ke
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthOrder} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='birthOrder'
                  fullWidth
                  type="number"
                  value={state.birthOrder}
                  onChange={(e) => handleChange('birthOrder', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah saudara kandung
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.siblings} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='siblings'
                  fullWidth
                  type="number"
                  value={state.siblings}
                  onChange={(e) => handleChange('siblings', e)}
                />
              </Conditional>
            </Grid>
          </Grid>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota kelahiran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthPlace} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='birthPlace'
                  fullWidth
                  value={state.birthPlace}
                  onChange={(e) => handleChange('birthPlace', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={format(state.birthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.birthDate}
                      onChange={(e) => handleChange('birthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Agama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getreligionLabel(state.religion_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='religion'
                  placeholder='Pilih agama'
                  options={religionOptions}
                  value={state.religion}
                  onChange={(e) => handleChange('religion', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Warga negara
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nationality && state.nationality.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='nationality'
                  placeholder='Pilih Negara'
                  options={nationalityOptions}
                  value={state.nationality}
                  onChange={(e) => handleChange('nationality', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Anak adopsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <Checkbox
                  checked={state.isAdopted === 1}
                />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Checkbox
                  checked={state.isAdopted === 1}
                  onChange={(e) => handleChange("isAdopted", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isFatherAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='FatherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.FatherAlive}
                  onChange={(e) => handleChange('FatherAlive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isMotherAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='MotherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.MotherAlive}
                  onChange={(e) => handleChange('MotherAlive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kurang mampu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <Checkbox
                  checked={state.isPoor === 1}
                />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Checkbox
                  checked={state.isPoor === 1}
                  onChange={(e) => handleChange("isPoor", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsexTypeLabel(state.sex_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.sex_type}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Provinsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.province && state.province.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='province'
                  placeholder='Pilih provinsi'
                  options={provinceOptions}
                  value={state.province}
                  onChange={(e) => handleChange('province', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota / Kabupaten
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.regency && state.regency.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='regency'
                  placeholder='Pilih kota kabupaten'
                  options={regencyOptions}
                  value={state.regency}
                  onChange={(e) => handleChange('regency', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kecamatan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.district && state.district.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='district'
                  placeholder='Pilih kecamatan'
                  options={districtOptions}
                  value={state.district}
                  onChange={(e) => handleChange('district', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Desa / kelurahan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.village && state.village.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='village'
                  placeholder='Pilih desa kelurahan'
                  options={villageOptions}
                  value={state.village}
                  onChange={(e) => handleChange('village', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                RW
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.rw} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='rw'
                    fullWidth
                    value={state.rw}
                    onChange={(e) => handleChange('rw', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                RT
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.rt} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='rt'
                    fullWidth
                    value={state.rt}
                    onChange={(e) => handleChange('rt', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kode pos
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.postalCode} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='postalCode'
                    fullWidth
                    value={state.postalCode}
                    onChange={(e) => handleChange('postalCode', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.address} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='address'
                  fullWidth
                  value={state.address}
                  onChange={(e) => handleChange('address', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jarak sekolah dari rumah (km)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.homeDistance} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='homeDistance'
                  fullWidth
                  type="number"
                  value={state.homeDistance}
                  onChange={(e) => handleChange('homeDistance', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Transportasi ke sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getvehicleTypeLabel(state.vehicle_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='vehicle_type'
                  placeholder='Pilih moda transportasi'
                  options={vehicleTypeOptions}
                  value={state.vehicle_type}
                  onChange={(e) => handleChange('vehicle_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Lama perjalanan ke sekolah (menit)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.travelTime} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='travelTime'
                  fullWidth
                  type="number"
                  value={state.travelTime}
                  onChange={(e) => handleChange('travelTime', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Hobi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.hoby} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='hoby'
                  fullWidth
                  value={state.hoby}
                  onChange={(e) => handleChange('hoby', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Cita - cita
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.dream} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='dream'
                  fullWidth
                  value={state.dream}
                  onChange={(e) => handleChange('dream', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Riwayat penyakit
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.medicalHistory} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='medicalHistory'
                  fullWidth
                  value={state.medicalHistory}
                  onChange={(e) => handleChange('medicalHistory', e)}
                />
              </Conditional>
            </Grid>
          </Grid>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Prestasi sebelumnya
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.achievementHistory} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='achievementHistory'
                  fullWidth
                  value={state.achievementHistory}
                  onChange={(e) => handleChange('achievementHistory', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              {`Status ${process.env.REACT_APP_STUDENT}`}
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getstudentStatusLabel(state.student_status_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='student_status'
                  placeholder='Pilih status'
                  options={studentStatusOptions}
                  value={state.student_status}
                  onChange={(e) => handleChange('student_status', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun Lulus
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.lulus_tahun && state.lulus_tahun.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='lulus_tahun'
                  placeholder='Pilih tahun'
                  options={tahunPelajaranOptions}
                  value={state.lulus_tahun}
                  onChange={(e) => handleChange('lulus_tahun', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Lulus tahun
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.tahunLulus} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='tahunLulus'
                  fullWidth
                  type="number"
                  value={state.tahunLulus}
                  onChange={(e) => handleChange('tahunLulus', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Aktif
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ActiveChip status={state.isActive} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Switch
                  color='primary'
                  checked={state.isActive === 1}
                  onChange={(e) => handleChange('isActive', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Harga tagihan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <StatusChip getStatus={getbillPriceTypeObject} status={state.bill_price_type_value} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="bill_price_type"
                    defaultValue='1'
                    name="bill_price_type-group"
                    value={String(state.bill_price_type_value)}
                    onChange={(e) => handleChange('bill_price_type_value', e)}
                  >
                    <FormControlLabel value='1' control={<Radio />} label="Normal" />
                    <FormControlLabel value='2' control={<Radio />} label="Khusus" />
                  </RadioGroup>
                </FormControl>
              </Conditional>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === 'READ'}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction('EDIT')} color='primary'>
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color='secondary'>
        Kembali
      </Button>
      <Conditional condition={pageAction !== 'READ'}>
        <Button variant='contained' onClick={submit} color='primary'>
          Simpan
        </Button>
      </Conditional>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}

export default StudentDetail;
