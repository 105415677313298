import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js'; 
 
import { format, parse } from 'date-fns';
import { gettransactionModeLabel, gettransactionTypeLabel, gettransactionTypeObject } from 'utils/options.js';



const StyledPaper = () => {
  return (<Paper elevation={12} />)
}

const IncomeStatement = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);

  const defaultFilters = {
    keyword: ''
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [data, setdata] = useState([]);
  const [dataPendapatan, setdataPendapatan] = useState([]);
  const [dataBiaya, setdataBiaya] = useState([]);

  const [totalPendapatan, settotalPendapatan] = useState(0);
  const [totalBiaya, settotalBiaya] = useState(0);


  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = () => {
    getDataAllPendapatan()
    getDataAllBiaya()
  }


  const getDataAllPendapatan = async () => {
    let params = {
      keyword: filters.keyword,
    }
    startLoading()
    const response = await axios.get(endpoint.report.allPendapatan, { params: params })
    if (response) {
      setdataPendapatan(response.data)
      settotalPendapatan(response.data.map(pendapatan => (parseInt(pendapatan.credit))).reduce((partialSum, a) => partialSum + a, 0))
    } else {
      showError('get pendapatan')
    }
    stopLoading()
  }

  const getDataAllBiaya = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.allBiaya, { params: params })
    if (response) {
      setdataBiaya(response.data)
      settotalBiaya(response.data.map(biaya => (parseInt(biaya.debit))).reduce((partialSum, a) => partialSum + a, 0))
    } else {
      showError('get biaya')
    }
  }



  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const showDialog = (actionCode, rowParam) => {
    setMaxWidth('md');
    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
  }




  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
    }
    setFilters(defaultFilters)
  }

  useEffect(() => {
    getData()
  }, [])



  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >

          <Grid item xs={6} lg={3}>

          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container alignItems='flex-start' sx={{ minHeight: 400 }} >
      <Paper elevation={8} sx={{ borderRadius: 4, width:'100%', p:1 }} >
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">

              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Pendapatan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataPendapatan.map(pendapatan => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" sx={{ pl: 2 }}>
                          {pendapatan.coa_name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="body2" align='right'>
                          {new Intl.NumberFormat().format(pendapatan.credit)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Total Pendapatan</TableCell>
                  <TableCell>
                    <Typography variant="body2" align='right'>
                      {
                        new Intl.NumberFormat().format(totalPendapatan)
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Biaya</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataBiaya.map(biaya => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" sx={{ pl: 2 }}>
                          {biaya.coa_name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="body2" align='right'>
                          {new Intl.NumberFormat().format(biaya.debit)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Total Biaya</TableCell>
                  <TableCell>
                    <Typography variant="body2" align='right'>
                      {
                        new Intl.NumberFormat().format(totalBiaya)
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Laba / rugi bersih</TableCell>
                  <TableCell>
                    <Typography variant="body2" align='right'>
                      {
                        (totalPendapatan-totalBiaya) < 0 ? '(' + new Intl.NumberFormat().format(Math.abs(totalPendapatan-totalBiaya)) + ')' : new Intl.NumberFormat().format(totalPendapatan-totalBiaya)
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

            </Table>
          </TableContainer>
        </Paper>


      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default IncomeStatement;

