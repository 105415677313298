import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Icon,
  Stack
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { SocialIcon } from 'react-social-icons';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';

const Footer = (props) => {
  const { history, address, phone, schools = [], backgroundColor } = props
  const { isAuthenticated, logout } = useAuth()
  const [links, setlinks] = useState([])


  const getlinks = async () => {

    const response = await axios.get(endpoint.link.group + '/contact');
    if (response && response.data) {
      setlinks(response.data)
    }
  };

  useEffect(() => {
    getlinks()
  }, [])

  return (
    <Grid container justifyContent='center' alignContent='flex-start' direction='row' sx={{
      py: 8,
      px: 4,
      bgcolor: { backgroundColor },
      color: '#ffffff'
    }}>
      <Grid item xs={12} md={6} container justifyContent='center' sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>

          <Typography variant="h6" component="div" >
            Pendidikan
          </Typography>
          {
            schools.map(school => (
              <Typography variant="body1" component="div" color='primary' sx={{ textDecoration: 'none', color: '#a2b4ff' }}>
                {school.name}
              </Typography>
            ))
          }

        </Grid>
        <Grid item xs={12} md={4} >
          <Typography variant="h6" component="div" >
            Tentang kami
          </Typography>
          <Typography variant="body1" component="div" color='primary' >
            <a href='#vision' style={{ textDecoration: 'none', color: '#a2b4ff' }}>Visi misi</a>
          </Typography>
          <Typography variant="body1" component="div" color='primary' sx={{ textDecoration: 'none', color: '#a2b4ff' }}>
            <a href='#featured' style={{ textDecoration: 'none', color: '#a2b4ff' }}>Program unggulan</a>
          </Typography>

        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" component="div" >
            Galeri
          </Typography>
          <Typography variant="body1" component="div" color='primary'>
            <a href='#activities' style={{ textDecoration: 'none', color: '#a2b4ff' }}>
              {
                `Kegiatan ${process.env.REACT_APP_STUDENT}`
              }
            </a>
          </Typography>
          <Typography variant="body1" component="div" color='primary'>
            <a href='#facilities' style={{ textDecoration: 'none', color: '#a2b4ff' }}>Fasilitas</a>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent='center' >
        <Grid item xs={12} container justifyContent='center'>
          <Typography variant="h6" component="div" >

          </Typography>
        </Grid>
        <Grid item xs={12} spacing={1} container justifyContent='center' alignItems='space-around'>
          {
            links.map(link => (
              <Grid item container justifyContent='center' sx={{ width: 'auto' }}>
                <Grid item container justifyContent='center'>
                  <SocialIcon url={link.url} />
                </Grid>
                <Grid item container justifyContent='center'>
                  <Typography align='center' variant='caption'>{link.name}</Typography>
                </Grid>
              </Grid>
            ))
          }
          <Grid item xs={12}>
            <Stack justifyContent='center'>
              <Typography variant="body1" component="div" align='center'>
                {address}
              </Typography>
              <Typography variant="body1" component="div" align='center'>
                {phone}
                <span id='phone'></span>
              </Typography>
            </Stack>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
