import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { TextField } from '@mui/material';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { generalListOptionMapper } from 'utils/mappers';
import Select from 'react-select' 
import { defaultSelectStyle } from 'utils/theme.js'


const DonationInputAmount = (props) => {
  const { row = {} } = props
  const { showInfo } = useUI()
  const [campaignOptions, setcampaignOptions] = useState([]);

  const [state, setstate] = useState(
    {
      amount: 0, 
      campaign: null,
      donation_campaign_id: undefined, 
    }
  ) 
 
  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'amount') {
      value = eventValue.target.value
    } 
    else if (fieldName === 'campaign') {
      newState['donation_campaign_id'] = value ? value.value : undefined
    }

    newState[fieldName] = value

    setstate(newState)
  }

  const getcampaignOptions = async () => {
    const response = await axios.get(endpoint.donationCampaign.option);
    if (response && response.data) {
      setcampaignOptions(response.data.map(campaign=>({...campaign, label:campaign.title, value:campaign.id})));
    }
  };

  const submit = async () => {
    if (parseInt(state.amount) < 1000) {
      showInfo('Minimal Rp. 1.000')
      return
    }

    props.onSubmit(state.campaign, parseInt(state.amount))
    props.onClose()
  }

  useEffect(() => {
    getcampaignOptions()
  }, [])

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Program Donasi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name="campaign"
                placeholder="Pilih program donasi"
                options={campaignOptions}
                value={state.campaign}
                onChange={(e) => handleChange("campaign", e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>


          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                fullWidth
                value={parseInt(state.amount)}
                onChange={(e) => handleChange("amount", e)}
                name="amount"
                id="amount"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Infak dari saldo
      </Button>
    </DialogActions>
  </>;
}

export default DonationInputAmount;

