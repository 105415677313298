import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, studentListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import useUI from 'hooks/useUI.js';
import SmallButton from 'components/SmallButton.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import useAuth from 'hooks/useAuth';
import { Button, Chip, Divider, IconButton } from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import SelectEmployeeForm from 'views/employee/SelectEmployeeForm';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import AsyncSelect from 'react-select/async';
import useSetting from 'hooks/useSetting';
import { studentGroupOptions } from 'utils/options';

const StudentGroup = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const { tahunPelajaran } = useSetting()
  const isInitialMount = useRef(true);
  const { user, detail } = useAuth()
  const defaultFilters = {
    keyword: ''
  }
  const [isChangePassword, setisChangePassword] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [tahun, settahun] = useState(null);
  const [group, setgroup] = useState(studentGroupOptions[0]);
  const [selectedEmployee, setselectedEmployee] = useState(null);
  const [selectedStudent, setselectedStudent] = useState(null);
  const [employeeOptions, setemployeeOptions] = useState([]);
  const [groupedStudents, setgroupedStudents] = useState([]);
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const getEmployeeOptions = async () => {
    const response = await axios.get(endpoint.employee.option);
    if (response && response.data) {
      setemployeeOptions(generalListOptionMapper(response.data));
    }
  };

  const getgroupedStudents = async (employee, selectedGroup, selectedTahun) => {
    let params = {
      employee_id: employee.id,
      group: selectedGroup.value,
      tahun_id: selectedTahun.id
    }
    startLoading() 
    const response = await axios.get(endpoint.student.group, { params: params });
    stopLoading()
    if (response && response.data) {
      setgroupedStudents(response.data);
    }
  };

  const deleteStudentFromGroup = async (student) => {
    let params = {
      employee_id: selectedEmployee.id,
      student_id: student.id,
      tahun_id: tahun.id,
      group: group.value
    }

    const response = await axios.delete(endpoint.student.group, { data: params });
    if (response && response.data) {
      showSuccess('delete student')
      getgroupedStudents(selectedEmployee, group, tahun)
    }
  };

  const groupChange = (e) => {
    setgroup(e)
    if (selectedEmployee) {
      getgroupedStudents(selectedEmployee, e, tahun)
    }
  }

  const tahunChange = (e) => {
    settahun(e)
    if (selectedEmployee) {
      getgroupedStudents(selectedEmployee, group, e)
    }
  }

  const selectEmployee = (employee) => {
    setselectedEmployee(employee)
    getgroupedStudents(employee, group, tahun)
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })
    
    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => { 
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const selectStudentChange = (e) => {
    setselectedStudent(e)
  };

  const addStudentToGroup = async () => {
    if (selectedStudent) {
      const params = {
        employee_id: selectedEmployee.id,
        student_id: selectedStudent.id,
        tahun_id: tahun.id,
        group: group.value
      }
      const response = await axios.post(endpoint.student.group, params)
      if (response && response.data) {
        showSuccess('add student')
        getgroupedStudents(selectedEmployee, group, tahun)
        setselectedStudent(null)
      } else {
        showError('add student')
      }
    }


  }

  const reset = () => {
  }

  useEffect(() => {
    gettahunPelajaranOptions()
    getEmployeeOptions()
  }, [])

  useEffect(() => {
    if (tahunPelajaran) {
      settahun(generalOptionMapper(tahunPelajaran))
    }
  }, [tahunPelajaran])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{ minHeight: 400 }} >
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item sm={4} xs={6}>
            <Select
              name="group"
              placeholder="Pilih grup"
              options={studentGroupOptions}
              value={group}
              onChange={groupChange}
              styles={defaultSelectStyle}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <Select
              name="tahun"
              placeholder="Tahun Pelajaran"
              options={tahunPelajaranOptions}
              value={tahun}
              onChange={tahunChange}
              styles={defaultSelectStyle}
            />
          </Grid>

        </Grid>

        <Grid item xs={12} container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
          sx={{ p: 0, mt: 1, border: 'solid #c5c5cc 1px', borderRadius: 1 }}>
          <Grid item xs={6} container justifyContent='flex-start' sx={{ borderRight: 'solid #c5c5cc 1px' }}>
          
            <List component="nav" aria-label="employee" sx={{ width: '100%', height: 500, overflow: 'scroll'  }}>
              {
                employeeOptions.map(employee => (

                  <ListItemButton
                    selected={selectedEmployee && selectedEmployee.id === employee.id}
                    onClick={() => selectEmployee(employee)}
                  >
                    <ListItemText primary={employee.name} />
                  </ListItemButton>

                ))
              }
            </List>
          </Grid>
          <Grid item xs={6} container justifyContent='flex-start' sx={{ p: 1 }}>
            {
              selectedEmployee &&
              <>
                <Grid item sm={10} xs={12} container justifyContent='flex-start'>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                    value={selectedStudent}
                    loadOptions={loadStudentOptions}
                    onInputChange={handleInputChangeStudent}
                    onChange={(e) => selectStudentChange(e)}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <Grid item sm={2} xs={12} container justifyContent='center'>
                  <IconButton onClick={addStudentToGroup} edge="end" aria-label="delete">
                    <Add />
                  </IconButton>

                </Grid>
                <List component="nav" aria-label="student" sx={{ width: '100%' }}>
                  {
                    groupedStudents.map(std => (

                      <ListItem
                        secondaryAction={
                          <IconButton onClick={() => deleteStudentFromGroup(std)} edge="end" aria-label="delete">
                            <Close />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={std.name}
                        />
                      </ListItem>

                    ))
                  }
                </List>
              </>
            }

          </Grid>

        </Grid>


      </Grid>

    </Grid>


  </>;
}


export default StudentGroup;

