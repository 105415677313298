import React from 'react'
import { makeStyles } from '@mui/styles';

import Chip from '@mui/material/Chip';

const BooleanChip = ({value, margin, trueLabel='true', trueColor='#16cd90', falseLabel='false', falseColor='grey'}) => {

    const label = value===1 || value===true ?trueLabel:falseLabel
    const color = value===1 || value===true ?trueColor:falseColor
    const classes = useStyles({color, margin})
    return (
        <Chip size="small" label={label} className={classes.chip} />
    );
}

const useStyles = makeStyles(theme => ({
    chip:{
      backgroundColor:({color})=>{return color;},
      color:'white',
      margin:({margin})=>{return margin;}
    }

  }));

export default BooleanChip;
