import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import { Card, CardHeader, Checkbox, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material';
import useUI from 'hooks/useUI';


const RolePriviledge = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const [menuOptions, setmenuOptions] = useState([]);
  const [actionOptions, setactionOptions] = useState([]);
  const [state, setstate] = useState({
    menus: [],
    actions: []
  });
  const [selectedMenu, setselectedMenu] = useState(null);
  const [selectedAction, setselectedAction] = useState(null);


  const [pageAction, setpageAction] = useState('')

  const getmenuOptions = async () => {
    const response = await axios.get(endpoint.menu.option);
    if (response && response.data) {
      setmenuOptions(response.data);
    }
  };

  const getactionOptions = async (menu) => {
    const params = {
      menu_id: menu ? menu.id : undefined
    }

    const response = await axios.get(endpoint.action.option, { params: params });
    if (response && response.data) {
      setactionOptions(response.data);
    }
  };

  const selectMenu = (menu) => {
    setselectedMenu(menu)
    getactionOptions(menu)
  };

  const selectAction = (action) => {
    setselectedAction(action)
  };

  const toggleMenu = (menu, event) => {
    
    let newMenus = state.menus
    let newActions = state.actions
    if (event.target.checked) {
      let parentMenu = menuOptions.filter(item => item.id === menu.parent_id)
      if(parentMenu[0]){
        newMenus = [...newMenus, menu, parentMenu[0]]
      }else{
        newMenus = [...newMenus, menu]
      }
      
      newActions = [...newActions, ...actionOptions]
    } else {
      newMenus = newMenus.filter(item => item.id != menu.id)
      newActions = newActions.filter(item => item.menu_id != menu.id)
    }
    setstate({ ...state, menus: newMenus, actions: newActions })
  };

  const toggleAction = (action, event) => {
    let newActions = state.actions
    if (event.target.checked) {
      if (state.menus.filter(menu => menu.id === action.menu_id)[0]) {
        newActions = [...newActions, action]
      }
    } else {
      newActions = newActions.filter(item => item.id != action.id)
    }
    setstate({ ...state, actions: newActions })
  };

  const isIncluded = (list, item, property) => {
    if (list.filter(obj => obj[property] === item[property])[0]) {
      return true
    }
    return false
  }

  const submit = async () => {
    const params = {
      role_id: row.id,
      menu_ids: state.menus.map(menu => menu.id),
      action_ids: state.actions.map(action => action.id)
    }

    const response = await axios.put(endpoint.role.priviledge, params)
    if (response && response.data) {
      showSuccess('save role')
      getData()
    } else {
      showError('save role')
    }
  }

  const getRolePriviledge = async () => {
    const params = {
      role_id: row.id
    }
    const response = await axios.get(endpoint.role.priviledge, { params: params })
    if (response) {
      setstate(response.data)
    }
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row) {
      getmenuOptions()
      getRolePriviledge()
    }

  }, [row])



  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        padding: '16px 0'
      }}>

      <Grid item xs={6} sm={6} md={4} lg={4} container justifyContent="center" style={{ padding: 8, width: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardHeader title="Menu" />
          <List sx={{
            height: 350,
            overflow: 'scroll'
          }}
          >
            {menuOptions.map((menu) => {
              const labelId = `checkbox-list-label-${menu.id}`;

              return (
                <ListItem
                  key={menu.id}
                  role={undefined}
                  dense
                  button
                  onClick={() => selectMenu(menu)}
                  selected={selectedMenu && menu.id === selectedMenu.id}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isIncluded(state.menus, menu, 'id')}
                      onChange={(e) => toggleMenu(menu, e)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={menu.id} primary={`${menu.name} (${menu.code})`} />
                </ListItem>
              );
            })}
          </List>
        </Card>
      </Grid>

      <Grid item xs={6} sm={6} md={4} lg={4} container justifyContent="center" style={{ padding: 8, width: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardHeader title="Action" />
          <List
            sx={{
              height: 350,
              overflow: 'scroll'
            }}
          >
            {actionOptions.map((action) => {
              const labelId = `checkbox-list-label-${action.id}`;

              return (
                <ListItem key={action.id} role={undefined} dense button onClick={() => selectAction(action)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isIncluded(state.actions, action, 'id')}
                      onChange={(e) => toggleAction(action, e)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={action.id} primary={action.name} />
                </ListItem>
              );
            })}
          </List>
        </Card>
      </Grid>
    </Grid>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default RolePriviledge;

