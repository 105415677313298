import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js'; 
 
import { format, parse } from 'date-fns';
import { gettransactionModeLabel, gettransactionTypeLabel, gettransactionTypeObject } from 'utils/options.js';



const StyledPaper = () => {
  return (<Paper elevation={12} />)
}

const BalanceSheet = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);

  const defaultFilters = {
    keyword: ''
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [data, setdata] = useState([]);
  const [hartaLancar, sethartaLancar] = useState([]);
  const [hartaTidakLancar, sethartaTidakLancar] = useState([]);
  const [kewajibanUtang, setkewajibanUtang] = useState([]);
  const [kewajibanModal, setkewajibanModal] = useState([]);

  const [totalHartaLancar, settotalHartaLancar] = useState(0);
  const [totalHartaTidakLancar, settotalHartaTidakLancar] = useState(0);
  const [totalUtang, settotalUtang] = useState(0);
  const [totalModal, settotalModal] = useState(0);
  const [totalBiaya, settotalBiaya] = useState(0);
  const [totalPendapatan, settotalPendapatan] = useState(0);
  const [laba, setlaba] = useState(0);


  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = () => {
    getHartaLancar()
    getHartaTidakLancar()
    getUtang()
    getModal()
    getLaba()
  }


  const getHartaLancar = async () => {
    let params = {
      keyword: filters.keyword,
    }
    startLoading()
    const response = await axios.get(endpoint.report.hartaLancar, { params: params })
    if (response) {
      sethartaLancar(response.data)
      settotalHartaLancar(response.data.map(harta => (parseInt(harta.debit) - parseInt(harta.credit))).reduce((partialSum, a) => partialSum + a, 0))
    } else {
      showError('get harta')
    }
    stopLoading()
  }

  const getTotalBiaya = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.totalBiaya, { params: params })
    if (response) {
      settotalBiaya(parseInt(response.data))
    } else {
      showError('get total biaya')
    }
  }

  const getTotalPendapatan = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.totalPendapatan, { params: params })
    if (response) {
      settotalPendapatan(parseInt(response.data))
    } else {
      showError('get total biaya')
    }
  }

  const getLaba = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.laba, { params: params })
    if (response) {
      setlaba(parseInt(response.data))
    } else {
      showError('get total biaya')
    }
  }

  const getHartaTidakLancar = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.hartaTidakLancar, { params: params })
    if (response) {
      sethartaTidakLancar(response.data)
      settotalHartaTidakLancar(response.data.map(harta => (harta.debit)).reduce((partialSum, a) => partialSum + a, 0))
    } else {
      showError('get harta')
    }
  }

  const getUtang = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.utang, { params: params })
    if (response) {
      setkewajibanUtang(response.data)
      settotalUtang(response.data.map(utang => (parseInt(utang.credit) - parseInt(utang.debit))).reduce((partialSum, a) => partialSum + a, 0))
    } else {
      showError('get utang')
    }
  }

  const getModal = async () => {
    let params = {
      keyword: filters.keyword,
    }
    const response = await axios.get(endpoint.report.modal, { params: params })
    if (response) {
      setkewajibanModal(response.data)
      settotalModal(response.data.map(modal => (parseInt(modal.credit) - parseInt(modal.debit))).reduce((partialSum, a) => partialSum + a, 0))
    } else {
      showError('get modal')
    }
  }





  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const showDialog = (actionCode, rowParam) => {
    setMaxWidth('md');
    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
  }




  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
    }
    setFilters(defaultFilters)
  }

  useEffect(() => {
    getData()
  }, [])



  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >

          <Grid item xs={6} lg={3}>

          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container alignItems='flex-start' sx={{ minHeight: 400 }} >
        <Grid container justifyContent='center' item xs={12} md={6} sx={{p:1}}>
          <Paper elevation={8} sx={{ borderRadius: 4, width: '100%', p: 1 }} >
            <Typography variant="h5" >
              Harta
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 1 }}>
              <Table size="small" aria-label="a dense table">

                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Aset</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    hartaLancar.map(harta => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" sx={{ pl: 2 }}>
                            {harta.coa_name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="body2" align='right'>
                            {new Intl.NumberFormat().format(harta.debit - harta.credit)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>Total Aset</TableCell>
                    <TableCell>
                      <Typography variant="body2" align='right'>
                        {
                          new Intl.NumberFormat().format(totalHartaLancar)
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid container justifyContent='center' item xs={12} md={6} sx={{p:1}} >

          <Paper elevation={8} sx={{ borderRadius: 4, width: '100%', p: 1 }} >
            <Typography variant="h5" >
              Kewajiban
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 1 }}>
              <Table size="small" aria-label="a dense table">

                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Liabilitas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    kewajibanUtang.map(utang => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" sx={{ pl: 2 }}>
                            {utang.coa_name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="body2" align='right'>
                            {new Intl.NumberFormat().format(utang.credit - utang.debit)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>Total Liabilitas</TableCell>
                    <TableCell>
                      <Typography variant="body2" align='right'>
                        {
                          new Intl.NumberFormat().format(totalUtang)
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Ekuitas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    kewajibanModal.map(modal => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" sx={{ pl: 2 }}>
                            {modal.coa_name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="body2" align='right'>
                            {new Intl.NumberFormat().format(modal.credit - modal.debit)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        Laba
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="body2" align='right'>
                        {new Intl.NumberFormat().format(laba)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>Total Ekuitas</TableCell>
                    <TableCell>
                      <Typography variant="body2" align='right'>
                        {
                          new Intl.NumberFormat().format(totalModal + laba)
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableHead>
                  <TableRow>
                    <TableCell>Total kewajiban</TableCell>
                    <TableCell>
                      <Typography variant="body2" align='right'>
                        {
                          new Intl.NumberFormat().format(totalUtang + totalModal + laba)
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

              </Table>
            </TableContainer>
          </Paper>

        </Grid>


      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default BalanceSheet;

