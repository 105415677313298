import { Grid, Box, Paper, Typography, Icon } from '@mui/material';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import useUI from 'hooks/useUI';
import React, { useEffect, useState } from 'react'
import useAuth from 'hooks/useAuth';
import { Star } from '@mui/icons-material';

const FavoriteMenu = (props) => {
    const { user } = useAuth()
    const { startLoading, stopLoading, gotoPage, showError, setFavoriteMenus, favoriteMenus = [] } = useUI()

    const getFavoriteMenus = async () => {
        startLoading()
        let params = {}
        const response = await axios.get(endpoint.menu.favorite + '/' + user.id, { params: params })

        if (response && response.data) {
            setFavoriteMenus(response.data)
        } else {
            showError('get favorite menu')
        }
        stopLoading()
    }

    useEffect(() => {
        if (user) {
            getFavoriteMenus()
        }
    }, [user])


    return (
        <Grid item xs={12} container justifyContent='center' alignItems='center'
            sx={{
                px: 2,
            }}
        >
            <Grid item container justifyContent='center' spacing={1}
                sx={{
                    color: '#393a3c',
                    pt: 1,
                    pb: 2,
                    px: 2,
                    mb: 2,
                    borderRadius: 4,
                    background: 'white'
                }}
            >
                {
                    favoriteMenus.length === 0 &&
                    <Grid container justifyContent='center' alignItems='center' alignContent='center'>
                        <Typography variant='body2' align='center'>
                            belum ada menu favorit
                        </Typography>
                        <Star fontSize='small' sx={{ml:1}}/>
                    </Grid> 
                }

                {
                    favoriteMenus.map(menu => (
                        <Grid key={menu.id} item xs={4} sm={3} md={2} container justifyContent='center' onClick={() => gotoPage(menu.webPath)} sx={{ cursor: 'pointer' }}>
                            <Grid item xs={12} container justifyContent='center'>
                                <Icon color='secondary'>
                                    {menu.icon}
                                </Icon>
                            </Grid>
                            <Grid item xs={12} container justifyContent='center'>
                                <Typography variant='body2' align='center'>
                                    {menu.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))
                }

            </Grid>

        </Grid>
    );

}

export default FavoriteMenu;
