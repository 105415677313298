import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon'
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { blue } from '@mui/material/colors';

const MenuItem = (props) => {
  const {expandedMenuId, menu={}, menuClick, activeMenu={}} = props
    if(!menu.webPath)
    {
      return(
        < >
        <ListItem
          button
          onClick={()=>menuClick(menu)}
          key={menu.id}
          selected={
            (expandedMenuId && menu.id===activeMenu.parent_id)
            }
            sx={{
              '&:hover': {
                bgcolor: blue[50],
                borderRadius:4,
                '& .MuiListItemIcon-root': {
                  color: 'primary.main',
                }
              }
            }}
          >
          <ListItemIcon> <Icon color='secondary'>{menu.icon}</Icon> </ListItemIcon>
          <ListItemText primary={menu.name} />
          {
            (expandedMenuId===menu.id ? <ExpandLess /> : <ExpandMore />)
          }
        </ListItem>
        <Collapse in={expandedMenuId===menu.id} timeout="auto" unmountOnExit>
          <List
          sx={{
            pl:2
          }} 
          >
          {
            menu.children.map(child=>
              (          
                <ListItem
                  button
                  onClick={()=>menuClick(child)}
                  key={child.id}
                  selected={activeMenu.code===child.code}
                  sx={{
                    '&:hover': {
                      bgcolor: blue[50],
                      borderRadius:4,
                      '& .MuiListItemIcon-root': {
                        color: 'primary.main',
                      }
                    }
                  }}
                  >
                  <ListItemIcon> <Icon color='secondary'>{child.icon}</Icon> </ListItemIcon>
                  <ListItemText 
                  primary={child.name} 
                  />
                </ListItem>          
              )
            )
          }
          </List>
        </Collapse>
        
        </>
      )
    }else{
      return(
      <ListItem
        button
        onClick={()=>menuClick(menu)}
        key={menu.id}
        selected={activeMenu.code===menu.code}
        sx={{
          '&:hover': {
            bgcolor: blue[50],
            borderRadius:4,
            '& .MuiListItemIcon-root': {
              color: 'primary.main',
            }
          }
        }}
        >
        <ListItemIcon> <Icon color='secondary'>{menu.icon}</Icon> </ListItemIcon>
        <ListItemText 
        primary={menu.name} 
        />
      </ListItem>
    )
    }
    
}

export default MenuItem;

