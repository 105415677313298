import React,{useState, useEffect, useRef} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilterList from 'components/FilterList';
import DeleteConfirmation   from 'components/DeleteConfirmation.js';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import {generalListOptionMapper, parameterListOptionMapper} from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import ActionForm from 'views/action/ActionForm';
import { DialogActions } from '@mui/material';
import SmallButton from 'components/SmallButton';
import CheckIcon from '@mui/icons-material/Check';


const MenuAction =(props)=> {
  const {row={}} = props
  const { activeMenu={}, activeForms, showSuccess, showError, setActiveForms } = useUI()
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch();
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({keyword:''})
  const [filtersAvailable, setfiltersAvailable] = useState([
    {name:'status', display:'status', type:'select'},
    {name:'jenjang', display:'jenjang', type:'select'}
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{label:'aktif', value:1}, {label:'nonaktif', value:0}])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter =(field)=>{
    let filtersTemp = {...filters}
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f=>f.name!==field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy,field])
  }


  const getActionsOfMenu =async()=>{
    let params = {
      menu_id:row.id
    }
    
    const response = await axios.get(endpoint.menu.action, {params:params})    
    
    if(response && response.data){
      const modifiedData = response.data.map(row=>({
        ...row
      }))
      setDataTable(modifiedData)
    }
  }


  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let rowAction=undefined
    if(rowParam){
      rowAction = rowParam
    }else{
      rowAction = selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <ActionForm
        row={rowAction}
        menu={row}
        getActionsOfMenu={getActionsOfMenu}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );
    setActiveForms([...activeForms, 'Action'])
    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Action"
      />
    );

    setopenDialog(true);
  };


  const closeDialog =()=>{
    setopenDialog(false)
    if(pageAction==='READ'){
      setselectedRow(null)
      setselectedIds([])
    }
    setActiveForms(activeForms.filter(form=>form!=='Action'))
  }  


  const handleDelete = async () => {
    const params = {
      action_ids: selectedIds
    };
    const response = await axios.delete(endpoint.menu.action, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete action')
      getActionsOfMenu();
    }else{
      showError('delete action')
    }
  };

  const toggleSelectRow =(row)=>{
    if(selectedIds.includes(row.id)){
      const ids = selectedIds.filter(item=>item!==row.id)
      setselectedIds(ids)
      
      if(ids.length===1){
        const existingRow = dataTable.filter(data=>(data.id===ids[0]))
        setselectedRow(existingRow[0])
      }
      else{
        setselectedRow(null)
      }      
      
    }else{      
      setselectedIds([...selectedIds,row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow=()=>{
    if(selectedIds.length === dataTable.length){
      setselectedIds([])
    }else{
      setselectedIds(dataTable.map(row=>row.id))
    }
  }
  
  useEffect(() => {
    getActionsOfMenu()
  }, [])

    return <>
      <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{p:1}}>
          <Grid container justifyContent='flex-end' sx={{my:1}}>
              
            {
              selectedIds.length===1 &&
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={()=>showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT'/>
                </SmallButton>
              </Protected>
                
            }
            {
              selectedIds.length>0 &&
                <Protected allowedCodes={['DELETE']} >
                  <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary'>
                    <ActionDisplay code='DELETE'/>
                  </SmallButton>
                </Protected>
            }
              <Protected allowedCodes={['CREATE']} >
                <SmallButton onClick={()=>showDialog('CREATE')} variant='contained' color='primary'>
                  <ActionDisplay code='CREATE'/>
                </SmallButton>
              </Protected>
          </Grid>
          
          <Grid container sx={{minHeight:400}}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        inputProps={{ 'aria-label': 'select all' }}
                        onChange={toggleSelectAllRow}
                        checked={selectedIds.length === dataTable.length && dataTable.length>0}
                      />
                    </TableCell>
                    <TableCell>Nama</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Menu</TableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((row) => (
                    <TableRow key={row.id} onClick={()=>toggleSelectRow(row)}>
                      <TableCell>
                      <Checkbox
                          checked={selectedIds.includes(row.id)}                            
                          color="primary"
                          inputProps={{ 'aria-label': 'select all' }}
                        />
                      </TableCell>
                      <TableCell onClick={()=>showDialog('READ',row)} 
                        sx={{
                          cursor:'pointer',
                          fontWeight:'bold',
                          color:'primary.main'
                        }}
                      >{row.name}</TableCell>                
                      <TableCell>{row.code}</TableCell> 
                      <TableCell>{row.menu&&row.menu.name}</TableCell>  
                      </TableRow>
                  ))}
                </TableBody>
              </Table>                
            </TableContainer>
          </Grid>

      </Grid>

      <DialogActions>
        <Button onClick={props.closeDialog} color="secondary">
          Kembali
        </Button>
      </DialogActions>

      <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
      >
        {dialogContent}
      </Dialog>

      <Popover
        id='popUpFilter'
        open={Boolean(anchorFilter)}
        anchorEl={anchorFilter}
        onClose={popupFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={
          {marginTop:8}
        }
      >
        <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose}/>
      </Popover>


      </>;
  }
  

export default MenuAction;


