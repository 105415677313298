import React, { useState, useEffect } from 'react'; 
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js'; 
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js'; 
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js'; 
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { kiOptions } from 'utils/options';


const KdDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI() 
  const reset = () => {
    return {
      id: undefined,
      kiObject: null,
      ki: undefined,
      description: undefined,
      code: undefined,
      jenjang: null,
      jenjang_id: undefined,
      kelas: null,
      kelas_id: undefined,
      jurusan: null,
      jurusan_id: undefined,
      subject: null,
      subject_id: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [jenjangOptions, setjenjangOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [subjectOptions, setsubjectOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'jenjang') {
      newState['jenjang_id'] = value.value
      getkelasOptions(value.value)
      getjurusanOptions(value.value)
      getsubjectOptions(value.value)
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value.value
      getsubjectOptions(value.jenjang_id, value.value)
    }
    else if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value.value
      getsubjectOptions(value.jenjang_id, value.kelas_id, value.value)
    }
    else if (fieldName === 'kiObject') {
      newState['ki'] = value.value
    }
    else if (fieldName === 'subject') {
      newState['subject_id'] = value ? value.value : undefined
    }


    newState[fieldName] = value
    setstate(newState)
  }

  const getsubjectOptions = async (jenjang_id, kelas_id, jurusan_id) => {
    const params = {
      jenjang_id: jenjang_id,
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    const response = await axios.get(endpoint.subject.option, { params: params });
    if (response && response.data) {
      setsubjectOptions(generalListOptionMapper(response.data));
    }
  };

  const getjenjangOptions = async () => {
    const response = await axios.get(endpoint.jenjang.option);
    if (response && response.data) {
      setjenjangOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };



  const insert = async (payload) => {
    const response = await axios.post(endpoint.kd.root, payload)
    if (response && response.data) {
      showSuccess('save kd')
      getData()
    } else {
      showError('save kd')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.kd.root, payload)
    if (response && response.data) {
      showSuccess('save kd')
      getData()
    } else {
      showError('save kd')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      jenjang: generalOptionMapper(row.jenjang),
      kelas: generalOptionMapper(row.kelas),
      jurusan: generalOptionMapper(row.jurusan),
      subject: generalOptionMapper(row.subject),
    })
  }

  useEffect(() => {
    if (pageAction !== 'READ') {
      getjenjangOptions()
    }
  }, [pageAction])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenjang
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.jenjang && state.jenjang.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="jenjang"
                  placeholder="Pilih Jenjang"
                  options={jenjangOptions}
                  value={state.jenjang}
                  onChange={(e) => handleChange("jenjang", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kelas
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.kelas && state.kelas.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="kelas"
                  placeholder="Pilih Kelas"
                  options={kelasOptions}
                  value={state.kelas}
                  onChange={(e) => handleChange("kelas", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jurusan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.jurusan && state.jurusan.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="jurusan"
                  placeholder="Pilih jurusan"
                  options={jurusanOptions}
                  value={state.jurusan}
                  onChange={(e) => handleChange("jurusan", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Mata pelajaran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.subject && state.subject.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='subject'
                  placeholder='Pilih pelajaran'
                  options={subjectOptions}
                  value={state.subject}
                  onChange={(e) => handleChange('subject', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              KI
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.ki} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name="ki"
                  placeholder="Pilih KI"
                  options={kiOptions}
                  value={state.kiObject}
                  onChange={(e) => handleChange("kiObject", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Deskripsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>



        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default KdDetail;
 
