import React, { useState, useEffect, useRef } from 'react'; 
import Grid from '@mui/material/Grid'; 
import Typography from '@mui/material/Typography'; 
import Button from '@mui/material/Button'; 
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const MoneyPad = (props) => { 
  const [total, settotal] = useState(0)
  const [queue, setqueue] = useState(null) 
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const clickMoney = (value) => {
    props.clickMoney(value)
  }


  const pay = () => {
    props.pay()
  };


  return (
    <Grid container sx={{ minHeight: 400 }} >
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(100000)} variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#e21e46',
          '&:hover': {
            backgroundColor: '#ae1635',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            100.000
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(50000)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#4e71a5',
          '&:hover': {
            backgroundColor: '#3c577f',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            50.000
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(20000)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#a0cdaf',
          '&:hover': {
            backgroundColor: '#69b080',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            20.000
          </Typography>
        </Button>
      </Grid>

      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(10000)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#751d57',
          '&:hover': {
            backgroundColor: '#5a1643',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            10.000
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(5000)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#633421',
          '&:hover': {
            backgroundColor: '#4c2819',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            5.000
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(2000)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#c0bcc4',
          '&:hover': {
            backgroundColor: '#948d9a',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            2.000
          </Typography>
        </Button>
      </Grid>

      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(1000)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#a8a859',
          '&:hover': {
            backgroundColor: '#828244',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            1.000
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(500)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#8ec0a3',
          '&:hover': {
            backgroundColor: '#5ca47b',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            500
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(200)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#63676f',
          '&:hover': {
            backgroundColor: '#4c4f55',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            200
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(100)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#63676f',
          '&:hover': {
            backgroundColor: '#4c4f55',
            color: '#fff'
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            100
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={() => clickMoney(50)} color='success' variant="contained" size='large' sx={{
          width: '95%',
          height: '95%',
          backgroundColor: '#63676f',
          '&:hover': {
            backgroundColor: '#4c4f55',
            color: '#fff',
          }
        }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            50
          </Typography>
        </Button>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems='center'  >
        <Button onClick={pay} color='success' variant="outlined" size='large' sx={{ width: '95%', height: '95%' }}>
          <Typography variant={smallScreen?"h5":"h4"} align="center">
            Bayar
          </Typography>
        </Button>
      </Grid>

    </Grid>
  )
}


export default MoneyPad;

