import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getkitabTypeLabel, getkitabTypeObject, getsuratObject, kitabTypeOptions, suratOptions } from 'utils/options';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalOptionMapper } from 'utils/mappers';
import UserCard from 'components/UserCard';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format, parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';


const BookBorrowDetail = (props) => {
  const { row = {}, getData, getDataByParams } = props
  const reset = () => {
    return {
      id: undefined,
      user: null,
      user_type_value: undefined,
      user_detail_id: undefined,
      book: null,
      borrowDate: null,
      returnDeadLine: null,
      returnDate: null,
      borrow_status: null,
      borrow_status_value: undefined,
      tahun: null,
      tahun_id: undefined,

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')


  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      borrowDate: parse(row.borrowDate, 'yyyy-MM-dd', new Date()),
      returnDeadLine: parse(row.returnDeadLine, 'yyyy-MM-dd', new Date()),
      returnDate: row.returnDate ? parse(row.returnDate, 'yyyy-MM-dd', new Date()) : null
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row) {
      getRowDetail()
    }
  }, [row])
 


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12}container justifyContent='flex-start' alignContent='center'>
              Peminjam
            </Grid>
            <Grid item sm={8} xs={12}>
              <ReadOnlyTextField value={state.user && state.user.detail && state.user.detail.name} />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12}container justifyContent='flex-start' alignContent='center'>
              Buku
            </Grid>
            <Grid item sm={8} xs={12}>
              <ReadOnlyTextField value={state.book && state.book.name} />
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal pinjam
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.borrowDate && format(state.borrowDate, 'dd  MMMM yyyy')} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal kembali
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.returnDate && format(state.returnDate, 'dd  MMMM yyyy')} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Batas waktu
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.returnDeadLine &&format(state.returnDeadLine, 'dd  MMMM yyyy')} />
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tahun Pelajaran
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.tahun && state.tahun.name} />
              </Grid>
            </Grid>
          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>
  </>;
}

export default BookBorrowDetail;

