import { Add, MoreVert, Verified } from '@mui/icons-material';
import { Alert, TextField, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import Conditional from 'components/Conditional';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import React, { useEffect, useState } from 'react'
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import { defaultSelectStyle } from 'utils/theme.js'
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import Confirmation from 'components/Confirmation';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NewStudentPsbFlow = (props) => {

  const { user, detail, students } = useAuth()
  const [psbFlows, setpsbFlows] = useState([])
  const [studentPsbFlowIds, setstudentPsbFlowIds] = useState([])
  const [studentPsbFlows, setstudentPsbFlows] = useState([])
  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const [existingStudent, setexistingStudent] = useState(null)
  const [studentOverallPsbFlows, setstudentOverallPsbFlows] = useState([])
  const [currentPsbFlow, setcurrentPsbFlow] = useState(null)
  const [lastPsbFlow, setlastPsbFlow] = useState(null)
  const [isPsbDone, setisPsbDone] = useState(0)
  const { gotoPage, showError } = useUI()
  const [openDialog, setopenDialog] = useState(false);
  const [openCancelDialog, setopenCancelDialog] = useState(false);
  const [name, setname] = useState("");
  const [schoolOptions, setschoolOptions] = useState([]);
  const [school, setschool] = useState(null);
  const [isParentFilled, setisParentFilled] = useState(false);
  const [studentType, setstudentType] = useState('1');

  const query = useQuery();

  const schoolChange = event => {
    setschool(event)
  };

  const existingStudentChange = event => {
    setexistingStudent(event)
  };

  const studentTypeChange = event => {
    setstudentType(event.target.value)
  };

  const nameChange = e => {
    setname(e.target.value);
  };



  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getNewParentData = async () => {
    let params = {}
    const response = await axios.get(endpoint.newParent.detail.replace("id", detail.id), { params: params })

    if (response) {
      let dataParent = response.data
      if (dataParent.isFatherAlive === 0 && dataParent.isMotherAlive === 0 && dataParent.isHasWali === 0) {
        setisParentFilled(false)
      } else {
        setisParentFilled(true)
      }
    }
  }

  const getStudentPsbFlows = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id
    }
    const response = await axios.get(endpoint.newStudent.psbFlow, { params: params })

    if (response && response.data) {
      setstudentPsbFlows(response.data)
      setstudentPsbFlowIds(response.data.map(flow => flow.id))
      if (response.data[0]) {
        setlastPsbFlow(response.data[response.data.length - 1])
      }
    } else {
      showError('get student psb flow')
    }
  }

  const showDialog = () => {
    setopenDialog(true);
  };

  const getStudentOverallPsbFlows = async (new_student) => {
    let params = {
      new_student_id: new_student.id,
      school_id: new_student.school_id,
      isForOld: new_student.isContinue === 1 ? 1 : undefined,
      isForNew: new_student.isContinue === 0 ? 1 : undefined
    }
    const response = await axios.get(endpoint.newStudent.overallPsbFlow, { params: params })

    if (response) {
      setstudentOverallPsbFlows(response.data)
    } else {
      showError('get student overall psb flow')
    }
  }

  const getnewStudentOptions = async () => {
    let params = {
      user_id: user.id
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      setnewStudentOptions(response.data)
      let new_student_id = query.get('new_student_id')

      if (response.data[0]) {
        if (new_student_id) {
          setselectedNewStudent(response.data.filter(newStudent => (newStudent.id === parseInt(new_student_id)))[0])
        } else {
          setselectedNewStudent(response.data[0])
        }
      }

    } else {
      showError('get new student')
    }
  }

  const addNewStudent = async () => {

    if (school === null) {
      showError('mohon pilih sekolah')
      return
    }

    if (studentType === '1') {
      if (name.trim().length < 1) {
        showError(`mohon isi nama calon ${process.env.REACT_APP_STUDENT}`)
        return
      }

    }

    let payload = {
      name: name,
      isContinue: studentType === '1' ? 0 : 1,
      existing_student_id: studentType === '2' ? existingStudent.id : undefined,
      school_id: school.id,
      jenjang_id: school.jenjang_id,
      new_parent_id: user.user_type_value === 6 ? detail.id : undefined,
      parent_id: user.user_type_value === 3 ? detail.id : undefined,
      user_id: user.id,
    }

    const response = await axios.post(endpoint.psbFlow.newStudent, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        getnewStudentOptions()
        setopenDialog(false)
      }
    } else {
      showError('tambah baru')
    }
  }

  const getcurrentPsbFlow = async (new_student_id) => {

    const response = await axios.get(endpoint.newStudent.currentPsbFlow.replace('new_student_id', new_student_id))

    if (response) {
      setcurrentPsbFlow(response.data)
    } else {
      showError('get current psb flow')
    }
  }

  const getIsPsbDone = async (new_student_id) => {

    const response = await axios.get(endpoint.newStudent.psbDone.replace('new_student_id', new_student_id))

    if (response) {
      setisPsbDone(response.data)
    } else {
      showError('get current psb flow')
    }
  }

  const getPsbFlowOptions = async (new_student) => {
    let params = {
      new_student_id: new_student.id,
      school_id: new_student.school_id,
      isForOld: new_student.isContinue === 1 ? 1 : undefined,
      isForNew: new_student.isContinue === 0 ? 1 : undefined
    }

    const response = await axios.get(endpoint.psbFlow.option, { params: params })

    if (response) {
      setpsbFlows(response.data)
    } else {
      showError('get psb flow')
    }
  }

  const getBackgroundColor = (flow) => {
    if (flow.done_value === 1) {
      if (flow.status_value === 1) {
        return '#5ac58c'
      } else {
        return '#e91e63'
      }
    } else {
      return '#d4cfcf'
    }
  }

  const newStudentClick = (newStudent) => {
    setselectedNewStudent(newStudent)
  }

  const flowClick = (flow) => {
    gotoPage(flow.path, 'id=' + flow.id + '&code=' + flow.code + '&new_student_id=' + selectedNewStudent.id)
  }

  const getStatus = (studentPsbFlows, flow) => {
    const studentFlow = studentPsbFlows.filter(studentFlow => (studentFlow.id === flow.id))[0]
    if (studentFlow) {
      return studentFlow.pivot.status_value
    } else {
      return 0
    }
  }

  const handleCancel = async () => {
    const payload = {
      ids: [selectedNewStudent.id]
    };
    const response = await axios.post(endpoint.newStudent.cancel, payload);
    if (response) {
      getnewStudentOptions()
    } 
  };

  useEffect(() => {
    if (user) {
      if (user.user_type_value === 6) {
        getNewParentData()
      }
      else if (user.user_type_value === 3) {
        setisParentFilled(true)

      }

      getnewStudentOptions()
      getschoolOptions()

    }

  }, [detail])

  useEffect(() => {
    if (selectedNewStudent) {
      getPsbFlowOptions(selectedNewStudent)
      getIsPsbDone(selectedNewStudent.id)
      getStudentOverallPsbFlows(selectedNewStudent)
      getStudentPsbFlows(selectedNewStudent.id)
      getcurrentPsbFlow(selectedNewStudent.id)
    }
  }, [selectedNewStudent])

  return (
    <>
      <Stack
        direction='column'
        sx={{
          bgcolor: 'white',
          width: '100%',
          p: 2,
          borderRadius: 4
        }}

      >
        {
          currentPsbFlow && lastPsbFlow && currentPsbFlow.id !== lastPsbFlow.id &&
          <Alert severity="info"
            sx={{
              borderRadius: 4
            }}>
            <AlertTitle>{selectedNewStudent && selectedNewStudent.name}</AlertTitle>
            Proses selanjutnya adalah <strong>{currentPsbFlow.name}</strong>
          </Alert>
        }

        {
          currentPsbFlow && lastPsbFlow && currentPsbFlow.id === lastPsbFlow.id &&
          <Alert severity="info"
            sx={{
              borderRadius: 4
            }}>
            <AlertTitle>{selectedNewStudent && selectedNewStudent.name}</AlertTitle>
            sudah selesai <strong>{currentPsbFlow.name}</strong>
          </Alert>
        }

        {
          isParentFilled &&
          <>
            <VerticalPillSelection
              selected={selectedNewStudent}
              options={newStudentOptions}
              onSelection={newStudentClick}
              withAddition={true}
              onAdditionClick={showDialog}
            />

            {
              selectedNewStudent &&
              <>
                <Typography variant="h6" component="h6" align='left' sx={{ my: 1 }}>
                  Tahapan seleksi :
                </Typography>

                {
                  studentOverallPsbFlows.map((flow, index) => (
                    <>
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Grid
                          item
                          xs={12} sm={6}
                          container
                          justifyContent='center'
                          alignItems='center'
                          onClick={() => flowClick(flow)}
                          sx={{
                            cursor: 'pointer',
                            borderRadius: 4,
                            bgcolor: getBackgroundColor(flow),
                            height: 100,
                            p: 2,
                          }}>
                          <Grid item xs={2} >
                            <Box bgcolor='white' width={30} height={30} display='flex' justifyContent='center' alignItems='center' borderRadius={50}>
                              <Typography variant="h6" component="h6" align="center" sx={{ width: 200 }}>
                                {flow.order}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={8} container justifyContent='center' alignItems='center'>
                            <Typography variant="h6" component="h6" align="center"
                              sx={{
                                width: 200,
                                color: flow.done_value === 1 ? 'white' : '#463e3e'
                              }}
                            >
                              {flow.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} container justifyContent='flex-end' alignItems='center' >
                            {
                              flow.done_value === 1 && flow.status_value === 1 &&
                              <Box bgcolor='white' width={36} height={36} display='flex' justifyContent='center' alignItems='center' borderRadius={50}>
                                <Verified color='success' fontSize='large' />
                              </Box>
                            }
                          </Grid>
                        </Grid>

                      </Grid>
                      <Conditional condition={psbFlows.length - 1 > index}>
                        <Grid container justifyContent='center' alignItems='center' >
                          <MoreVert sx={{ color: '#d4cfcf', my: 1 }} />
                        </Grid>
                      </Conditional>
                    </>
                  ))
                }

                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  sx={{ mt: 4 }}
                >
                  <Grid
                    item
                    xs={12} sm={6}
                    container
                    justifyContent='center'
                    alignItems='center'
                    onClick={() => setopenCancelDialog(true)}
                    sx={{
                      cursor: 'pointer',
                      borderRadius: 4,
                      bgcolor: 'maroon',
                      height: 100,
                      p: 2,
                    }}>
                    <Grid item xs={2} >

                    </Grid>
                    <Grid item xs={8} container justifyContent='center' alignItems='center'>
                      <Typography variant="h6" component="h6" align="center"
                        sx={{
                          width: 200,
                          color: 'white'
                        }}
                      >
                        Batalkan pendaftaran
                      </Typography>
                    </Grid>
                    <Grid item xs={2} container justifyContent='flex-end' alignItems='center' >

                    </Grid>
                  </Grid>


                </Grid>

              </>
            }

            {
              !selectedNewStudent &&
              <Grid item xs={12} container sx={{ mt: 2 }}>
                <Grid item xs={12} container justifyContent='center'>
                  {`Pendaftaran ${process.env.REACT_APP_STUDENT} baru`}
                </Grid>
                <Grid item xs={12} container justifyContent='center' sx={{ mt: 2 }} onClick={showDialog}>
                  <img src={`${api.url}/assets/undraw_duplicate_re_d39g.svg`} style={{ width: 200, height: 200 }} />
                </Grid>
                <Grid item xs={12} container justifyContent='center' alignContent='center' alignItems='center'>
                  <Typography variant="h6" component="h6" align="center" >
                    {`Anda dapat mendaftarkan ${process.env.REACT_APP_STUDENT} baru dengan menekan tombol`}
                  </Typography>
                  <Add />
                </Grid>
              </Grid>
            }

          </>

        }


        {
          !isParentFilled &&
          <Grid item xs={12} container sx={{ mt: 2 }}>
            <Grid item xs={12} container justifyContent='center'>
              Selamat datang
            </Grid>
            <Grid item xs={12} container justifyContent='center' sx={{ mt: 2 }} onClick={showDialog}>
              <img src={`${api.url}/assets/undraw_add_information_j2wg.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item xs={12} container justifyContent='center' alignContent='center' alignItems='center'>
              <Typography variant="h6" component="h6" align="center" >
                Mohon isi data orang tua terlebih dahulu,
              </Typography>
              <Button onClick={() => gotoPage('/dashboard/psb-new-parent')}> klik disini</Button>
            </Grid>
          </Grid>
        }
      </Stack>

      <Dialog
        open={openDialog}
        maxWidth='sm'
        onClose={() => setopenDialog(false)}
        fullWidth
        fullScreen={false}
        scroll="body"
      >
        <DialogTitle id="form-dialog-title">{`Pendaftaran ${process.env.REACT_APP_STUDENT} baru`}</DialogTitle>
        <DialogContent>
          <Grid container sx={{ height: 300 }} alignContent='flex-start'>

            <Conditional condition={user && user.user_type_value !== 6}>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={studentType}
                  onChange={studentTypeChange}
                >
                  <FormControlLabel value="1" control={<Radio />} label={`${process.env.REACT_APP_STUDENT} baru`} />
                  <FormControlLabel value="2" control={<Radio />} label={`${process.env.REACT_APP_STUDENT} lama`} />

                </RadioGroup>
              </Grid>
            </Conditional>

            <Conditional condition={studentType === '1'}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label={`Nama calon ${process.env.REACT_APP_STUDENT}`}
                  name="name"
                  autoComplete="name"
                  value={name}
                  onChange={nameChange}
                />
              </Grid>
            </Conditional>

            <Conditional condition={studentType === '2'}>
              <Grid item xs={12}>
                <Select
                  name='existing'
                  placeholder={`Pilih ${process.env.REACT_APP_STUDENT}`}
                  options={studentListOptionMapper(students)}
                  value={existingStudent}
                  onChange={existingStudentChange}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Conditional>


            <Grid item xs={12} sx={{ mt: 2 }}>
              <Select
                name='school'
                placeholder='Pilih sekolah'
                options={schoolOptions}
                value={school}
                onChange={schoolChange}
                styles={defaultSelectStyle}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={addNewStudent} color="secondary">
            Simpan
          </Button>
          <Button variant="contained" onClick={() => setopenDialog(false)} color="primary">
            Batal
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCancelDialog}
        maxWidth='sm'
        onClose={() => setopenCancelDialog(false)}
        fullWidth
        fullScreen={false}
        scroll="body"
      >
        <Confirmation
          handleClose={() => setopenCancelDialog(false)}
          action={handleCancel}
          actionName="Hapus data"
          message={`Apakah anda yakin membatalkan pendaftaran, data ${process.env.REACT_APP_STUDENT} baru akan dihapus`}
          title='Konfirmasi pembatalan'
        />
      </Dialog>
    </>
  );

}

export default NewStudentPsbFlow;
