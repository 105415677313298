import { useState, useEffect } from 'react';
import { axiosInstance as axios } from 'utils/axios';
import { Button, Grid } from '@mui/material';
import useUI from 'hooks/useUI';


// ----------------------------------------------------------------------

export default function GeneralImport(props) {
  const { startLoading, stopLoading, showSuccess, showError } = useUI()
  const ImportExcel = async (event) => {
    startLoading()
    let file = event.target.files[0]
    if (file) {
        const formData = new FormData();
        formData.append('file', file) 
        const response = await axios.post(props.importUrl, formData, {});
        if (response) {
          if (response.data.error) {
            showError(response.data.error)
          } else {
            showSuccess('import')
          }
          
        }else{
          showError('import')
        }
    } 
    stopLoading()
};

  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center' spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <Button href={props.templateUrl} color='primary'>
          Unduh template
        </Button>
      </Grid>
      <Grid item >
        <label htmlFor='excel'>
          <input onChange={ImportExcel} id='excel' type="file" style={{ display: 'none' }} />
          <Button variant="contained" component="span">
            Pilih file excel
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}
