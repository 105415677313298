// material
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function ClickableText({ text, onClick, sx, ...other }) {

  return (
    <Typography
      variant="body"
      noWrap      
      onClick={onClick}
      color='primary'
      sx={{ cursor: 'pointer'}}
    >
      {text}
    </Typography>
  );
}
