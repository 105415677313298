import React, { useEffect, useState } from 'react';  
import Grid from '@mui/material/Grid';  
import {
    IconButton,
    Popover,
    TextField
} from '@mui/material'; 
import { Check, Close } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';


const EditStringPopUp = (props) => {
    const [anchor, setanchor] = useState(null);
    const [state, setstate] = useState(null);

    const showForm = (event) => {
        setanchor(event.currentTarget);
    };

    const formClose = () => {
        setanchor(null);
    };

    const saveClick = () => {
        props.save(state)
        setanchor(null);
    };

    useEffect(() => {
        setstate(props.value)
    }, [props.value])

    return <>

        <IconButton
            color="secondary"
            onClick={showForm}
            size='small'
            aria-label='edit'
            style={{ margin: 0 }}>
            <EditIcon fontSize='inherit' />
        </IconButton>
        <Popover
            id="popUpProfile"
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={formClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={
                { 
                    top: 2
                }
            }
        >
            <Grid container justifyContent='space-between' alignItems='center' sx={{ width: 400, p: 2 }} >
                <Grid item sm={8} xs={12}>
                    <TextField fullWidth variant='outlined' value={state} onChange={(e) => setstate(e.target.value)} />
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={formClose}
                        size='small'
                        aria-label='edit'
                        style={{ margin: 0 }}>
                        <Close fontSize='inherit' />
                    </IconButton>
                    <IconButton
                        onClick={saveClick}
                        size='small'
                        aria-label='edit'
                        style={{ margin: 0 }}>
                        <Check fontSize='inherit' />
                    </IconButton>
                </Grid>
            </Grid>
        </Popover>
    </>;

}

export default EditStringPopUp;
 