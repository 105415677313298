import React from 'react'
import { Button } from '@mui/material';

const SmallButton = (props) => {
     
  return <Button 
          size='small'
          {...props} 
          sx={{
            height:30,
            px:0.5,
            fontSize:'0.7em',
            mx:0.5
          }}
        />  
    
}

export default SmallButton;
