import { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { Button, Grid } from '@mui/material';
import useUI from 'hooks/useUI';


// ----------------------------------------------------------------------

export default function ProductRestock(props) {
  const { startLoading, stopLoading, showSuccess, showError } = useUI()
  const ImportExcel = async (event) => {
    startLoading()
    let file = event.target.files[0]
    if (file) {
        const formData = new FormData();
        formData.append('file', file) 
        const response = await axios.post(endpoint.product.batchRestock, formData, {});
        if (response) {
          if (response.data.error) {
            showError(response.data.error)
          } else {
            showSuccess('restock')
          }
          
        }else{
          showError('restock')
        }
    } 
    stopLoading()
};

const downloadTemplate =async () => {
  axios.get(endpoint.product.template, { params: {}, responseType: 'blob' })
    .then(response => {
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "product_restock.xlsx");
      document.body.appendChild(link);
      link.click();
      
    })
}

  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center' spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <Button onClick={downloadTemplate} color='primary'>
          Unduh template
        </Button>
      </Grid>
      <Grid item >
        <label htmlFor='excel'>
          <input onChange={ImportExcel} id='excel' type="file" style={{ display: 'none' }} />
          <Button variant="contained" component="span">
            Pilih file excel
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}
