import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Typography,
    Button,
    Link,
    useMediaQuery
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import EditStringPopUp from 'components/EditStringPopUp';
import useUI from 'hooks/useUI';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/styles';


const MobileApp = (props) => {
    const { backgroundColor, title = '', content = '', image = '' } = props
    const { user, hasRole } = useAuth()
    const { gotoPage } = useUI()
    const [androidLink, setandroidLink] = useState('#')
    const [iosLink, setiosLink] = useState('#')
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const saveTitle = (value) => {
        props.saveSetting('HOME_MOBILE_APP_TITLE', value)
    }

    const saveContent = (value) => {
        props.saveSetting('HOME_MOBILE_APP_CONTENT', value)
    }

    const saveWithFile = async (event) => {
        let file = event.target.files[0]
        if (file) {
            const formData = new FormData();
            formData.append('file', file)
            formData.append('code', 'HOME_MOBILE_APP_IMAGE')

            const response = await axios.post(endpoint.setting.codeFile, formData, {});
            if (response && response.data) {
                props.getData()
            }
        }

    };

    const getAndroidLink = async () => {

        const response = await axios.get(endpoint.link.code + '/ANDROID');
        if (response && response.data) {
            setandroidLink(response.data.url)
        }
    };

    const getIosLink = async () => {

        const response = await axios.get(endpoint.link.code + '/IOS');
        if (response && response.data) {
            setiosLink(response.data.url)
        }
    };

    useEffect(() => {
        getAndroidLink()
        getIosLink()
      }, []) 
    


    return (
        <Box bgcolor={backgroundColor}>
            <Grid container justifyContent='space-between' sx={{
                pb: 8,
                px: 4,
                color: { backgroundColor },
                bgcolor: '#ffffff',
                borderTopRightRadius: 100,
                borderBottomRightRadius: 100,
                minHeight: 350
            }}>
                <Grid item xs={12} md={5} container justifyContent='center' alignContent='center'>
                    <Grid item xs={12} container justifyContent='center' alignContent='center'
                        sx={{
                            p: 4,
                        }}
                    >
                        <img
                            src={`${api.url}/${image}?t=${Date.now()}`}
                            style={{
                                width: '50%'
                            }} />

                        {hasRole('SCHOOL_ADMIN') &&
                            <label htmlFor='HOME_MOBILE_APP_IMAGE'>
                                <input onChange={saveWithFile} accept="image/*" id='HOME_MOBILE_APP_IMAGE' type="file" style={{ display: 'none' }} />
                                <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer' }} />
                            </label>
                        }
                    </Grid>

                </Grid>
                <Grid item xs={12} md={5} container justifyContent='center' alignContent='center'
                    sx={{
                        px: 1,
                    }}
                >
                    <Typography variant={smallScreen ? 'h4':'h3'} component="div" >
                        {title}
                        {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={title} save={saveTitle} />}
                    </Typography>
                    <Typography variant="body1" component="div" >
                        {content}
                        {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={content} save={saveContent} />}
                    </Typography>
                    <Grid item container justifyContent='center' alignContent='center' spacing={2} sx={{ pt: 3 }}>
                        <Grid item>
                            <Button variant="contained" size="large" color='success' sx={{
                                borderRadius: 50,
                                p: 2
                            }}>
                                <Link underline='none' target='_blank' color="inherit" href={androidLink}>
                                    Download Android
                                </Link>
                            </Button>
                        </Grid>

                        <Grid item >
                            <Button variant="contained" size="large" color='info' sx={{
                                borderRadius: 50,
                                p: 2
                            }}>
                                <Link underline='none' target='_blank' color="inherit" href={iosLink}>
                                    Download IOS
                                </Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
}

export default MobileApp;
