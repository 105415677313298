import React from 'react'

import Chip from '@mui/material/Chip';

const StatusChip = ({getStatus, status, margin}) => {

  if(getStatus(status)){
    return (
      <Chip 
      size="small" 
      label={getStatus(status).label} 
      color={getStatus(status).backgroundColor}
      sx={{
        color:getStatus(status).textColor,
        margin:margin
      }}
      />
  );
  }else{
    return null
  }
    
}

export default StatusChip;
