import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js'; 
 
import MoneyCard from 'components/MoneyCard';
import Button from '@mui/material/Button';
import Conditional from 'components/Conditional';
import Confirmation from 'components/Confirmation';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { EchoInstance } from 'App';
import { TRANSACTION_MODE_BALANCE, TRANSACTION_TYPE_JAJAN } from 'utils/options';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const SimpleCashier = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, setPreviousMenuActions, gotoPage, showError, showInfo } = useUI()
  const { user, detail } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const history = useHistory()
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultFilters = {
    keyword: ''
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [total, settotal] = useState(0)
  const [queue, setqueue] = useState(null)

  const clickMoney = (value) => {
    if (queue) {
      if ((total + value) <= queue.balance) {
        settotal(total + value)
      } else {
        showInfo('Saldo tidak mencukupi')
      }
    }

  }


  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      startLoading()
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params }) 
      if (response && response.data) {
        setqueue(response.data)
      }else{
        setqueue(null)
      }
      stopLoading()
    }
    
  }


  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent();

    setopenDialog(true);
  };

  const showPayConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <Confirmation
        handleClose={closeDialog}
        action={pay}
        actionName="Bayar"
        message='Anda akan melakukan pembayaran'
        title='Pembayaran'
      />
    );
    if (total > 0 && queue !== null) {
      setopenDialog(true);
    }
  };
  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const deleteLastQueue = async () => {
    setqueue(null)
    const params = {
      ids: [queue.id]
    };
    const response = await axios.delete(endpoint.smartReader.queue, { data: params });
    if (response) {
      getLastQueue()
    } else {
      showError('delete queue')
    }
  };

  const gotoTransactionPage = () => {
    const route = '/dashboard/transaction'
    setPreviousMenuActions(activeMenu)
    gotoPage(route)
    history.push(route)
  }

  const pay = async () => {
    const params = {
      queue_id: queue.id,
      user_id: queue.user_id,
      smart_reader_number: detail.smart_reader_number,
      store_id: detail.store_id,
      total: total,
      admin_employee_id:detail.id,
      transaction_mode_value: TRANSACTION_MODE_BALANCE,
      transaction_type_value: TRANSACTION_TYPE_JAJAN
    }
    const response = await axios.post(endpoint.store.paySimpleCashless, params)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else { 
        reset()
      }

    } else {
      showError('membayar cashless')
    }
  }

  const reset = () => {
    settotal(0)
    setqueue(null)
    getLastQueue()
  }

  useEffect(() => { 

    getLastQueue()

    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME+'card')
    .listen('NewCardQueue', (e) => {      
        getLastQueue()  
    });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME +'card');
    }
  }, [])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={gotoTransactionPage} variant='text' color='primary'>
              Transaksi
            </SmallButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems='flex-start' sx={{ mb: 1 }}>
        <Grid container justifyContent="flex-start" alignItems='stretch' >
          <Grid container item xs={12} md={6} alignItems='center' sx={{ mb: 1 }}>
            <Grid container justifyContent="center">
              {
                queue && <MoneyCard user={queue} onClose={deleteLastQueue} />
              }
              <Conditional condition={queue === null}>
                <IconButton
                  onClick={getLastQueue}
                  aria-label='reset'
                  size="large">
                  <RefreshIcon color='primary' />
                </IconButton>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6} justifyContent="center" alignItems='center' >
            <Grid container item xs={6} md={12} justifyContent="center">
              Total belanja :
            </Grid>
            <Grid container item xs={6} md={12} justifyContent="center">
              <Typography variant={smallScreen?"h5":"h4"} align="center">
                {new Intl.NumberFormat().format(total)}
              </Typography>
            </Grid>

          </Grid>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(100000)} variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#e21e46',
            '&:hover': {
              backgroundColor: '#ae1635',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              100.000
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(50000)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#4e71a5',
            '&:hover': {
              backgroundColor: '#3c577f',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              50.000
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(20000)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#a0cdaf',
            '&:hover': {
              backgroundColor: '#69b080',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              20.000
            </Typography>
          </Button>
        </Grid>

        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(10000)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#751d57',
            '&:hover': {
              backgroundColor: '#5a1643',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              10.000
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(5000)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#633421',
            '&:hover': {
              backgroundColor: '#4c2819',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              5.000
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(2000)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#c0bcc4',
            '&:hover': {
              backgroundColor: '#948d9a',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              2.000
            </Typography>
          </Button>
        </Grid>

        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(1000)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#a8a859',
            '&:hover': {
              backgroundColor: '#828244',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              1.000
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(500)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#8ec0a3',
            '&:hover': {
              backgroundColor: '#5ca47b',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              500
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(200)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#63676f',
            '&:hover': {
              backgroundColor: '#4c4f55',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              200
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(100)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#63676f',
            '&:hover': {
              backgroundColor: '#4c4f55',
              color: '#fff'
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              100
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={() => clickMoney(50)} color='success' variant="contained" size='large' sx={{
            width: '95%',
            height: '95%',
            backgroundColor: '#63676f',
            '&:hover': {
              backgroundColor: '#4c4f55',
              color: '#fff',
            }
          }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              50
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center" alignItems='center'  >
          <Button onClick={showPayConfirmation} color='success' variant="outlined" size='large' sx={{ width: '95%', height: '95%' }}>
            <Typography variant={smallScreen?"h5":"h4"} align="center">
              Bayar
            </Typography>
          </Button>
        </Grid>

      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default SimpleCashier;

