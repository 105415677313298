import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import { Checkbox, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import { format, parse } from 'date-fns';
import { examTypeOptions, gettransactionModeLabel, gettransactionTypeLabel, gettransactionTypeObject, numberOptions, scoreTypeOptions, scoringMethodOptions, semesterOptions } from 'utils/options.js';
import DebouncedTextField from 'components/DebouncedTextField';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useSetting from 'hooks/useSetting';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import useAuth from 'hooks/useAuth';


const ScoreParallel = (props) => {
  const { user, detail, students } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, showInfo, isAllDataAccess } = useUI()
  const { tahunPelajaran } = useSetting()
  const isInitialMount = useRef(true);

  const defaultFilters = {
    exam_type: null,
    exam_type_value: undefined,
    scoring_method: null,
    scoring_method_code: undefined,
    kd: null,
    kd_id: undefined,
    step: null,
    step_value: undefined,
    subject: null,
    subject_id: undefined,
    school_id: undefined,
    kelas_id: undefined,
    parallel: null,
    parallel_id: undefined,
    semester: null,
    semester_value: undefined,
    tahun: null,
    tahun_id: undefined,
    isRapor: 0

  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [subjectOptions, setsubjectOptions] = useState([]);
  const [kdOptions, setkdOptions] = useState([]);
  const [selectedSteps, setselectedSteps] = useState([]);
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [scoringSteps, setscoringSteps] = useState([]);
  const [studentScores, setstudentScores] = useState([]);
  const [isAddingScore, setisAddingScore] = useState(false);
  const [isEditingScore, setisEditingScore] = useState(false);
  const [newStep, setnewStep] = useState(0);
  const [examDate, setexamDate] = useState(new Date());


  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value

    if (fieldName === 'tahun') {
      filtersTemp['tahun_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'scoring_method') {
      filtersTemp['scoring_method_code'] = value ? value.value : undefined
    }
    else if (fieldName === 'semester') {
      filtersTemp['semester_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'exam_type') {
      filtersTemp['exam_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'parallel') {
      filtersTemp['parallel_id'] = value ? value.value : undefined
      filtersTemp['kelas_id'] = value ? value.kelas_id : undefined
      filtersTemp['school_id'] = value ? value.school_id : undefined
    }
    else if (fieldName === 'kd') {
      filtersTemp['kd_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'step') {
      filtersTemp['step_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'subject') {
      filtersTemp['subject_id'] = value ? value.value : undefined
      getkdOptions(value.value)
    }

    setFilters(filtersTemp)
  }


  const toggleSelectStep = (step) => {
    if (selectedSteps.includes(step)) {
      const steps = selectedSteps.filter(item => item !== step)
      setselectedSteps(steps)

    } else {
      setselectedSteps([...selectedSteps, step])
    }
  }

  const examDateChange = (e) => {
    setexamDate(e)
  }

  const scoreChange = (studentIndex, scoreIndex, value) => {
    let newStudentScores = [...studentScores]
    let oldScore = newStudentScores[studentIndex].scores[scoreIndex]
    newStudentScores[studentIndex].scores[scoreIndex] = { ...oldScore, score: Number(value) }
    setstudentScores(newStudentScores)
  }


  const getparallelOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const getsubjectOptions = async (jenjang_id) => {
    const params = {}
    const response = await axios.get(endpoint.subject.option, { params: params });
    if (response && response.data) {
      setsubjectOptions(generalListOptionMapper(response.data));
    }
  };

  const getkdOptions = async (subject_id) => {
    const params = {
      subject_id: subject_id,
    }
    const response = await axios.get(endpoint.kd.option, { params: params });
    if (response && response.data) {
      setkdOptions(response.data.map(kd => ({ ...kd, label: kd.code, value: kd.id })));
    }
  };

  const getStudentScores = async () => {
    startLoading()
    const params = {
      parallel_id: filters.parallel_id,
      subject_id: filters.subject_id,
      tahun_id: filters.tahun_id,
      semester_value: filters.semester_value,
      exam_type_value: filters.exam_type_value,
      score_type_value: 1
    }

    const response = await axios.get(endpoint.score.kelas, { params: params });

    if (response && response.data) {
      setstudentScores(response.data)
      if (response.data[0]) {
        let steps = response.data[0].scores.map(score => (score.step_value))
        setscoringSteps(steps)
        setnewStep(steps.length)
      }
    }
    stopLoading()
  }

  const editScore = () => {
    if (!filters.parallel_id) {
      showInfo('Pilih kelas')
      return
    }

    if (!filters.subject_id) {
      showInfo('Pilih pelajaran')
      return
    }

    if (!filters.exam_type_value) {
      showInfo('Pilih tipe ujian')
      return
    }

    if (!filters.tahun_id) {
      showInfo('Pilih tahun pelajaran')
      return
    }

    if (!filters.semester_value) {
      showInfo('Pilih semester')
      return
    }
    setisEditingScore(true)

  }

  const addScore = () => {

    if (!filters.parallel_id) {
      showInfo('Pilih kelas')
      return
    }

    if (!filters.subject_id) {
      showInfo('Pilih pelajaran')
      return
    }

    if (!filters.exam_type_value) {
      showInfo('Pilih tipe ujian')
      return
    }

    if (!filters.tahun_id) {
      showInfo('Pilih tahun pelajaran')
      return
    }

    if (!filters.semester_value) {
      showInfo('Pilih semester')
      return
    }


    setnewStep(newStep + 1)
    setisAddingScore(true)
    setscoringSteps(
      [...scoringSteps, newStep + 1]
    )
    setstudentScores(
      studentScores.map(student => (
        {
          ...student,
          scores: [
            ...student.scores,
            { score: 0, step_value: newStep + 1 }
          ]
        }
      ))
    )
    filterChange('step', { label: newStep + 1, value: newStep + 1 })
  }

  const confirmDeleteScoreByStep = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={deleteScoreByStep}
        selectedIds={selectedSteps}
        title="Kelompok nilai"
      />
    );

    setopenDialog(true);
  }


  const deleteScoreByStep = async () => {
    const params = {
      step_values: selectedSteps,
      subject_id: filters.subject_id,
      parallel_id: filters.parallel_id,
      tahun_id: filters.tahun_id,
      semester_value: filters.semester_value,
      exam_type_value: filters.exam_type_value,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.score.step, { data: params });
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setselectedSteps([])
        showSuccess('delete score')
        getStudentScores();
      }

    } else {
      showError('delete score')
    }
  };

  const cancelModifyScore = () => {
    if (isAddingScore) {
      setisAddingScore(false)
      setscoringSteps(scoringSteps.filter(scoringStep => scoringStep !== newStep))
      setnewStep(newStep - 1)
      setstudentScores(
        studentScores.map(student => (
          {
            ...student,
            scores: student.scores.filter(score => score.step_value !== newStep)
          }
        ))
      )
    }

    else if (isEditingScore) {
      setisEditingScore(false)
    }

  }

  const submitScore = async () => {

    if (!filters.tahun_id) {
      showInfo('Pilih tahun pelajaran')
      return
    }

    if (!filters.semester_value) {
      showInfo('Pilih semester')
      return
    }

    if (!filters.exam_type_value) {
      showInfo('Pilih tipe ujian')
      return
    }


    if (!filters.parallel_id) {
      showInfo('Pilih kelas')
      return
    }


    if (!filters.subject_id) {
      showInfo('Pilih pelajaran')
      return
    }

    if (isAddingScore) {
      if (!filters.kd_id) {
        showInfo('Pilih KD')
        return
      }

      if (!filters.step_value) {
        showInfo('Pilih penilaian keberapa')
        return
      }

      if (!filters.scoring_method_code) {
        showInfo('Pilih metode penilaian')
        return
      }
      submitAdd()
    }

    else if (isEditingScore) {
      submitEdit()
    }

  }

  const submitAdd = async () => {

    let newScores = studentScores.map(student => (
      {
        student_id: student.id,
        ...student.scores.filter(score => score.step_value === newStep)[0]
      }

    ))

    let payload = {
      parallel_id: filters.parallel_id,
      kelas_id: filters.kelas_id,
      school_id: filters.school_id,
      subject_id: filters.subject_id,
      kd_id: filters.kd_id,
      step_value: filters.step_value,
      tahun_id: filters.tahun_id,
      semester_value: filters.semester_value,
      exam_type_value: filters.exam_type_value,
      score_type_value: 1,
      scoring_method_code: filters.scoring_method_code,
      isRapor: filters.isRapor,
      scores: newScores,
      examDate: format(examDate, 'yyyy-MM-dd')
    }
    startLoading()
    const response = await axios.post(endpoint.score.kelas, payload)
    stopLoading()
    if (response) {
      showSuccess('simpan nilai kelas')
      setisAddingScore(false)
      getStudentScores()

    }
  }

  const submitEdit = async () => {

    let payload = {
      scores: studentScores.map(student => (
        student.scores.map(item => ({ id: item.id, score: item.score }))
      )).flat()
    }
    startLoading()
    const response = await axios.put(endpoint.score.kelas, payload)
    stopLoading()
    if (response) {
      showSuccess('simpan nilai kelas')
      setisEditingScore(false)
      getStudentScores()

    }
  }


  const showDialog = (actionCode, rowParam) => {
    setMaxWidth('md');
    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
  }


  const printExcel = () => {
    const params = {
      parallel_id: filters.parallel_id,
      subject_id: filters.subject_id,
      tahun_id: filters.tahun_id,
      semester_value: filters.semester_value,
      exam_type_value: filters.exam_type_value,
      score_type_value: 1
    }

    axios.get(endpoint.score.kelasExcel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "nilai_kelas.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  useEffect(() => {
    getparallelOptions()
    getsubjectOptions()
    gettahunPelajaranOptions()
  }, [])




  useEffect(() => {
    if (filters.tahun_id && filters.semester_value && filters.exam_type_value && filters.parallel_id && filters.subject_id) {
      getStudentScores()
    }

  }, [filters.tahun_id, filters.semester_value, filters.exam_type_value, filters.parallel_id, filters.subject_id])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
      }
    },
    700,
    [filters.keyword]
  );

  useEffect(() => {
    if (tahunPelajaran) {
      filterChange('tahun', generalOptionMapper(tahunPelajaran))
    }
  }, [tahunPelajaran])



  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          {
            (!isAddingScore && !isEditingScore) &&
            <>
              <Grid item>
                <SmallButton onClick={addScore} variant='contained' color='primary'>
                  <ActionDisplay code='CREATE' />
                </SmallButton>
              </Grid>
              <Grid item>
                <SmallButton onClick={editScore} variant='contained' color='secondary'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Grid>
              {
                selectedSteps.length > 0 &&
                <Grid item>
                  <SmallButton onClick={confirmDeleteScoreByStep} variant='contained' color='error'>
                    <ActionDisplay code='DELETE' />
                  </SmallButton>
                </Grid>
              }

            </>
          }

          {
            (isAddingScore || isEditingScore) && <>
              <Grid item>
                <SmallButton onClick={submitScore} variant='contained' color='success'>
                  Simpan
                </SmallButton>
              </Grid>

              <Grid item>
                <SmallButton onClick={cancelModifyScore} variant='contained' color='error'>
                  Batal
                </SmallButton>
              </Grid>
            </>
          }


        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >


          <Grid item xs={6} md={3}>
            <Select
              name='parallel'
              placeholder='Kelas Paralel'
              options={parallelOptions}
              value={filters.parallel}
              onChange={(e) => filterChange('parallel', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              name="subject"
              placeholder="Pilih pelajaran"
              options={subjectOptions}
              value={filters.subject}
              onChange={(e) => filterChange("subject", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='exam_type'
              placeholder='Pilih tipe ujian'
              options={examTypeOptions}
              onChange={(e) => filterChange('exam_type', e)}
              styles={defaultSelectStyle}
              value={filters.exam_type}
            />
          </Grid>


          <Grid item xs={6} md={3}>
            <Select
              name="tahun"
              placeholder="Pilih Tahun Pelajaran"
              options={tahunPelajaranOptions}
              value={filters.tahun}
              onChange={(e) => filterChange("tahun", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='semester'
              placeholder='Pilih semester'
              options={semesterOptions}
              onChange={(e) => filterChange('semester', e)}
              styles={defaultSelectStyle}
              value={filters.semester}
            />
          </Grid>



          {
            isAddingScore &&
            <>
              <Grid item xs={6} md={3}>
                <Select
                  isClearable={true}
                  name="KD"
                  placeholder="Pilih KD"
                  options={kdOptions}
                  value={filters.kd}
                  onChange={(e) => filterChange("kd", e)}
                  styles={defaultSelectStyle}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <Select
                  isClearable={true}
                  name="step"
                  placeholder="Penilaian ke"
                  options={numberOptions}
                  value={filters.step}
                  onChange={(e) => filterChange("step", e)}
                  styles={defaultSelectStyle}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <Select
                  isClearable={true}
                  name='scoring_method'
                  placeholder='Metode penilaian'
                  options={scoringMethodOptions}
                  onChange={(e) => filterChange('scoring_method', e)}
                  styles={defaultSelectStyle}
                  value={filters.scoring_method}
                />
              </Grid>

              <Grid item xs={6} md={3} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} fullWidth size="small" />}
                    value={examDate}
                    onChange={examDateChange}
                  />
                </LocalizationProvider>
              </Grid>

            </>
          }

        </Grid>


        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={printExcel}>
            Excel
          </SmallButton>
          <IconButton
            onClick={getStudentScores}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container alignItems='flex-start' sx={{ minHeight: 400 }} >

        <TableContainer component={Paper} >
          <Table size="small" aria-label="a dense table" sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell>Nama</TableCell>
                {
                  scoringSteps.map(step => (
                    <TableCell>
                      {!isAddingScore && !isEditingScore &&
                        <Checkbox
                          checked={selectedSteps.includes(step)}
                          color="primary"
                          onChange={() => toggleSelectStep(step)}
                        />
                      }

                      Nilai {step}
                    </TableCell>
                  ))
                }
                <TableCell>Rata - rata</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                studentScores.map((student, studentIndex) => (
                  <TableRow key={student.student_id}>
                    <TableCell> {student.name} </TableCell>
                    {
                      student.scores.map((score, scoreIndex) => (
                        <TableCell sx={{
                          background: selectedSteps.includes(score.step_value) ? 'grey' : 'white'
                        }}>
                          {
                            (isAddingScore || isEditingScore) &&
                            <DebouncedTextField
                              margin="dense"
                              id={`score_${student.id}_${score.id}`}
                              type="number"
                              fullWidth
                              value={'' + score.score}
                              onChange={(e) => scoreChange(studentIndex, scoreIndex, e)}
                            />
                          }

                          {
                            !isAddingScore && !isEditingScore &&
                            <ReadOnlyTextField value={score.score} />
                          }
                        </TableCell>
                      ))
                    }
                    <TableCell>{
                      student.scores.length > 0 && Math.round((student.scores.reduce((partialSum, score) => partialSum + score.score, 0)) / student.scores.length)
                    }</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default ScoreParallel;

