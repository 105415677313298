import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilterList from 'components/FilterList';
import FilterComponent from 'components/FilterComponent.js';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Popover from '@mui/material/Popover';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import SelectEmployeeSmartReaderForm from 'views/employee/SelectEmployeeSmartReaderForm';
import { DialogActions } from '@mui/material';
import ClickableText from 'components/ClickableText';


const StoreEmployee = (props) => {
  const { row = {} } = props
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({ keyword: '' })
  const [filtersAvailable, setfiltersAvailable] = useState([
    { name: 'status', display: 'status', type: 'select' },
    { name: 'jenjang', display: 'jenjang', type: 'select' }
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)



  const getFilterOptions = (field) => {
    if (field.name === 'status') {
      return statusOptions
    }
  }

  const popupFilterShow = (event) => {
    if (filtersAvailable.length > 0) {
      setanchorFilter(event.currentTarget);
    }
  };

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter = (field) => {
    let filtersTemp = { ...filters }
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f => f.name !== field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy, field])
  }

  const removeFilter = (field) => {
    let filtersTemp = { ...filters }
    delete filtersTemp[field.name]
    setFilters(filtersTemp)
    let newFilterBy = filterBy.filter(f => f.name !== field.name)
    setfilterBy(newFilterBy)
    setfiltersAvailable([...filtersAvailable, field])
  }

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  }


  const getEmployeesOfStore = async (newPage, newRowsPerPage) => {
    let params = {
      store_id: row.id,
      keyword: filters.keyword === '' ? undefined : filters.keyword,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }

    const response = await axios.get(endpoint.store.employee, { params: params })

    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }
  }

  const getDataByFilter = () => {
    getEmployeesOfStore()
  }


  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getEmployeesOfStore(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getEmployeesOfStore(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = selectedRow
    }

    setMaxWidth('sm');
    setdialogContent(
      <SelectEmployeeSmartReaderForm
        onSubmit={addEmployee}
        closeDialog={closeDialog}
      />
    );



    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDeleteEmployee}
        selectedIds={selectedIds}
        title="Employee"
      />
    );

    setopenDialog(true);
  };


  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }


  const handleDeleteEmployee = async () => {
    const params = {
      employee_ids: selectedIds
    };
    const response = await axios.delete(endpoint.store.employee, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete employee')
      getEmployeesOfStore();
    } else {
      showError('delete employee')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }


  const addEmployee = async (data) => {
    const params = {
      store_id: row.id,
      employee_id: data.employee.id,
      smart_reader_number: data.smart_reader ? data.smart_reader.number : null
    }
    const response = await axios.put(endpoint.store.employee, params)
    if (response && response.data) {
      showSuccess('save employee')
      getEmployeesOfStore()
    } else {
      showError('save employee')
    }

  }


  useEffect(() => {
    getEmployeesOfStore()
  }, [])

  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getEmployeesOfStore()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' className={classes.root}>
      <Grid container justifyContent="flex-end" alignItems="center" className={classes.toolbarContainer}>
         
        {
          selectedIds.length > 0 &&
          <Protected allowedCodes={['DELETE']} >
            <Button onClick={showDeleteConfirmation} variant='contained' color='secondary' size='small' className={classes.smallButton}>
              <ActionDisplay code='DELETE' />
            </Button>
          </Protected>
        }


        <Protected allowedCodes={['ADD_EMPLOYEE']} >
          <Button onClick={() => showDialog('ADD_EMPLOYEE')} variant='contained' color='primary' size='small' className={classes.smallButton}>
            <ActionDisplay code='ADD_EMPLOYEE' />
          </Button>
        </Protected>
      </Grid>
      <Grid container className={classes.tableContainer}>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell >Nama</TableCell>
                <TableCell >Smart reader</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    <ClickableText text={row.name} />
                  </TableCell>
                  <TableCell >{row.smart_reader && `(${row.smart_reader.number}) ${row.smart_reader.name}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end" className={classes.paginationContainer}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>



    </Grid>

    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Popover
      id='popUpFilter'
      open={Boolean(anchorFilter)}
      anchorEl={anchorFilter}
      onClose={popupFilterClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={classes.popUpFilter}
      style={
        { marginTop: 8 }
      }
    >
      <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose} />
    </Popover>


  </>;
}


export default StoreEmployee;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  toolbarContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  filterContainer: {
    marginBottom: theme.spacing(1)
  },
  tableContainer: {
    minHeight: 400,
  },
  clickable: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  formContainer: {
    minHeight: '80vh',
    marginTop: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  formPaper: {
    width: 'inherit',
    padding: theme.spacing(2)
  },
  paginatonContainer: {
    height: 40,
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  marginB1: {
    marginBottom: theme.spacing(1)
  },
  reactSelect: {
    width: '100%'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  closeButton: {
    top: -20,
    marginLeft: 'auto',
    marginRight: 0
  },
  tableContainer: {
    minHeight: 400,
  },
  clickable: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },

  paginatonContainer: {
    height: 40,
  }

}));

const selectCustomZindex = {
  control: (base, state) => ({
    ...base,
    minWidth: '150px',
    margin: '0 4px'
  }),
  container: (base, state) => {
    return {
      ...base,
      flex: 1,
      zIndex: state.isFocused ? '1100' : '1' //Only when current state focused
    };
  }
};