import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Alert, AlertTitle, Button, Checkbox, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import DebouncedTextField from 'components/DebouncedTextField';
import SmallButton from 'components/SmallButton';
import Conditional from 'components/Conditional';
import { getjobTypeObject, getreligionObject, getsexTypeObject, getstayWithObject, getwaliTypeObject, jobTypeOptions, religionOptions, sexTypeOptions, stayWithOptions, waliTypeOptions } from 'utils/options';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const PsbProfile = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, gotoPage } = useUI()
  const { user, detail } = useAuth()
  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const query = useQuery();

  const initialState = {
    id: undefined,
    registration_number:undefined,
    user: null,
    name: "",
    fatherName: "",
    motherName: "",
    waliName: "",
    jenjang: null,
    jenjang_id: undefined,
    school: null,
    school_id: undefined,
    kelas: null,
    kelas_id: undefined,
    jurusan: null,
    jurusan_id: undefined,
    wali_type: null,
    wali_type_value: undefined,
    fatherKtp: "",
    motherKtp: "",
    waliKtp: "",
    fatherPhone: "",
    motherPhone: "",
    waliPhone: "",
    fatherEmail: "",
    motherEmail: "",
    waliEmail: "",
    oldSchoolName: "",
    isFatherAlive: 0,
    isMotherAlive: 0,
    isHasWali: 0,
    father_job_type: null,
    father_job_type_value: undefined,
    mother_job_type: null,
    mother_job_type_value: undefined,
    wali_job_type: null,
    wali_job_type_value: undefined,
    nisn: "",
    nkk: "",
    sex_type: null,
    sex_type_value: undefined,
    wali_sex_type: null,
    wali_sex_type_value: undefined,
    province: null,
    province_id: undefined,
    regency: null,
    regency_id: undefined,
    district: null,
    district_id: undefined,
    village: null,
    village_id: undefined,
    address: "",
    photo: undefined,
    birthPlace: "",
    birthDate: new Date(),
    birthOrder: 1,
    religion: null,
    religion_value: undefined,
    nationality: null,
    nationality_value: undefined,
    isAdopted: 0,
    stay_with_value: undefined,
    siblings: 0,
    hoby: undefined,
    dream: undefined,
    medicalHistory: undefined,
    achievementHistory: undefined,
  }

  const [state, setstate] = useState(initialState)
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [nationalityOptions, setnationalityOptions] = useState([]);
  const [provinceOptions, setprovinceOptions] = useState([]);
  const [regencyOptions, setregencyOptions] = useState([]);
  const [districtOptions, setdistrictOptions] = useState([]);
  const [villageOptions, setvillageOptions] = useState([]);
  const [studentPsbFlows, setstudentPsbFlows] = useState([])
  const [currentPsbFlow, setcurrentPsbFlow] = useState(null)
  const [lastPsbFlow, setlastPsbFlow] = useState(null)
  const [isCurrentFlow, setisCurrentFlow] = useState(false)

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'school') {
      newState['school_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
    }
    if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'province') {
      newState['province_id'] = value ? value.value : undefined
      getRegencyOptions(value.value)
      newState['regency'] = null
      newState['regency_id'] = undefined
      newState['district'] = null
      newState['district_id'] = undefined
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'regency') {
      newState['regency_id'] = value ? value.value : undefined
      getDistrictOptions(value.value)
      newState['district'] = null
      newState['district_id'] = undefined
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'district') {
      newState['district_id'] = value ? value.value : undefined
      getVillageOptions(value.value)
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'village') {
      newState['village_id'] = value ? value.value : undefined
    }
    else if (['isAdopted'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'isFatherAlive') {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 0) {
        newState['fatherPhone'] = undefined
        newState['fatherKtp'] = undefined
        newState['fatherEmail'] = undefined
      }
    }
    else if (fieldName === 'isMotherAlive') {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 0) {
        newState['motherPhone'] = undefined
        newState['motherKtp'] = undefined
        newState['motherEmail'] = undefined
      }
    }
    else if (fieldName === 'isHasWali') {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 0) {
        newState['wali_type'] = null
        newState['wali_type_value'] = undefined
        newState['wali_sex_type'] = null
        newState['wali_sex_type_value'] = undefined
        newState['waliName'] = undefined
        newState['waliPhone'] = undefined
        newState['waliKtp'] = undefined
        newState['waliEmail'] = undefined
      }
    }
    else if (fieldName === 'wali_type') {
      newState['wali_type_value'] = value.value
    }
    else if (fieldName === 'father_job_type') {
      newState['father_job_type_value'] = value.value
    }
    else if (fieldName === 'mother_job_type') {
      newState['mother_job_type_value'] = value.value
    }
    else if (fieldName === 'wali_job_type') {
      newState['wali_job_type_value'] = value.value
    }
    else if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value.value
    }
    else if (fieldName === 'wali_sex_type') {
      newState['wali_sex_type_value'] = value.value
    }
    else if (fieldName === 'stay_with') {
      newState['stay_with_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'religion') {
      newState['religion_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'nationality') {
      newState['nationality_value'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getnewStudentOptions = async () => {
    let params = {
      user_id: user.id
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      setnewStudentOptions(response.data)
      let new_student_id = query.get('new_student_id')

      if (response.data[0]) {
        if (new_student_id) {
          setselectedNewStudent(response.data.filter(newStudent => (newStudent.id === parseInt(new_student_id)))[0])
        } else {
          setselectedNewStudent(response.data[0])
        }
      }

    } else {
      showError('get new student')
    }
  }

  const newStudentClick = (newStudent) => {
    setselectedNewStudent(newStudent)
  }

  const isThisCurrentFlow = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id,
      flow_id: query.get('id')
    }
    const response = await axios.get(endpoint.psbFlow.isCurrent, { params: params })

    if (response) {
      if (response.data === 1) {
        setisCurrentFlow(true)
      } else {
        setisCurrentFlow(false)
      }
    } else {
      showError('get student psb flow')
    }
  }

  const getStudentPsbFlows = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id
    }
    const response = await axios.get(endpoint.newStudent.psbFlow, { params: params })

    if (response && response.data) {
      setstudentPsbFlows(response.data.map(flow => flow.id))
      if (response.data[0]) {
        setlastPsbFlow(response.data[response.data.length - 1])
      }
    } else {
      showError('get student psb flow')
    }
  }

  const getcurrentPsbFlow = async (new_student_id) => {

    const response = await axios.get(endpoint.newStudent.currentPsbFlow.replace('new_student_id', new_student_id))

    if (response) {
      setcurrentPsbFlow(response.data)
    } else {
      showError('get current psb flow')
    }
  }


  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getnationalityOptions = async () => {
    const params = {
      group: 'nationality'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setnationalityOptions(parameterListOptionMapper(response.data));
    }
  };

  const getprovinceOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.province, { params: params });
    if (response && response.data) {
      setprovinceOptions(generalListOptionMapper(response.data));
    }
  };

  const getRegencyOptions = async (province_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.provinceRegency.replace('id', province_id), { params: params });
    if (response && response.data) {
      setregencyOptions(generalListOptionMapper(response.data));
    }
  };

  const getDistrictOptions = async (regency_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.regencyDistrict.replace('id', regency_id), { params: params });
    if (response && response.data) {
      setdistrictOptions(generalListOptionMapper(response.data));
    }
  };

  const getVillageOptions = async (district_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.districtVillage.replace('id', district_id), { params: params });
    if (response && response.data) {
      setvillageOptions(generalListOptionMapper(response.data));
    }
  };

  const submit = async () => {

    if (state.name.trim().length === 0) {
      showError(`Mohon isi nama calon ${process.env.REACT_APP_STUDENT}`)
      return
    }

    if (!state.sex_type_value) {
      showError('Mohon pilih jenis kelamin')
      return
    }

    if (!state.stay_with_value) {
      showError('Mohon pilih tinggal bersama siapa')
      return
    }

    if (state.address.trim().length === 0) {
      showError('Mohon isi alamat')
      return
    }

    if (!state.birthOrder || parseInt(state.birthOrder) < 1) {
      showError('Mohon isi anak keberapa')
      return
    }


    if (state.birthPlace.trim().length === 0) {
      showError('Mohon isi kota kelahiran')
      return
    }

    if (!state.birthDate) {
      showError('Mohon pilih tanggal lahir')
      return
    }

    if (!state.religion_value) {
      showError('Mohon pilih agama')
      return
    }

    if (!state.nationality_value) {
      showError('Mohon pilih kewarganegaraan')
      return
    }

    if (!state.kelas_id) {
      showError('Mohon pilih kelas')
      return
    }


    if (state.nkk.trim().length === 0) {
      showError('Mohon isi Nomor Kartu Keluarga')
      return
    }



    let payload = {
      ...state,
      siblings: state.siblings ? parseInt(state.siblings) : 0,
      id: selectedNewStudent.id,
      birthDate: state.birthDate ? format(state.birthDate, 'yyyy-MM-dd') : undefined
    }
    const response = await axios.put(endpoint.psbFlow.profile, payload)
    if (response && response.data) {
      showSuccess('simpan biodata')
      gotoPage('/dashboard' + '?new_student_id=' + selectedNewStudent.id)
    }
  }

  const getNewStudentDetail = async (new_student_id) => {
    let params = {}
    const response = await axios.get(endpoint.newStudent.detail.replace('id', new_student_id), params)
    if (response && response.data) {
      let student = response.data
      setstate({
        ...student,
        wali_type: getwaliTypeObject(student.wali_type_value),
        kelas: generalOptionMapper(student.kelas),
        jurusan: generalOptionMapper(student.jurusan),
        sex_type: getsexTypeObject(student.sex_type_value),
        stay_with: getstayWithObject(student.stay_with_value),
        birthDate: student.birthDate ? parse(student.birthDate, 'yyyy-MM-dd', new Date()) : null,
        religion: getreligionObject(student.religion_value),
        nationality: parameterOptionMapper(student.nationality),
        province: generalOptionMapper(student.province),
        regency: generalOptionMapper(student.regency),
        district: generalOptionMapper(student.district),
        village: generalOptionMapper(student.village),

      })
      let school = student.school
      if (school) {
        getkelasOptions(school.jenjang_id)
      }
    }
  }

  useEffect(() => {
    if (detail) {
      getnewStudentOptions()
      getprovinceOptions()
      getjurusanOptions()
    }
  }, [detail])


  useEffect(() => {
    if (selectedNewStudent) {
      isThisCurrentFlow(selectedNewStudent.id)
      getnationalityOptions()
      getNewStudentDetail(selectedNewStudent.id)
      getStudentPsbFlows(selectedNewStudent.id)
      getcurrentPsbFlow(selectedNewStudent.id)
    }
  }, [selectedNewStudent])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={10} sm={10} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={2} sm={2} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
      </Grid>

      <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400 }}>
        <Grid item xs={12} >
          {
            currentPsbFlow && lastPsbFlow && currentPsbFlow.id !== lastPsbFlow.id &&
            <Alert severity="info"
              sx={{
                borderRadius: 4
              }}>
              <AlertTitle>Informasi</AlertTitle>
              Proses selanjutnya adalah <strong>{currentPsbFlow.name}</strong>
            </Alert>
          }

          {
            currentPsbFlow && lastPsbFlow && currentPsbFlow.id === lastPsbFlow.id &&
            <Alert severity="info"
              sx={{
                borderRadius: 4
              }}>
              <AlertTitle>Informasi</AlertTitle>
              anda sudah selesai <strong>{currentPsbFlow.name}</strong>
            </Alert>
          }


        </Grid>

        <VerticalPillSelection
          selected={selectedNewStudent}
          options={newStudentOptions}
          onSelection={newStudentClick}
        />

        {
          !isCurrentFlow &&
          <Grid container direction='column' spacing={2} sx={{ mt: 2 }}>
            <Grid item container justifyContent='center'>
              Mohon selesaikan proses sebelumnya
            </Grid>
            <Grid item container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_process_re_gws7.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item container justifyContent='center'>
              Anda dapat melihat tahapannya di dashboard
            </Grid>
          </Grid>
        }

        {
          isCurrentFlow &&
          <Grid item xs={12} md={6} container  >
            <Grid container sx={{ mt: 2 }}>
              <Grid container justifyContent='center' alignItems='center' >
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Nomor pendaftaran
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='registration_number'
                    fullWidth
                    value={state.registration_number}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  {`Nama calon ${process.env.REACT_APP_STUDENT}`}
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='name'
                    fullWidth
                    value={state.name}
                    onChange={(e) => handleChange('name', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Jenis kelamin
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.sex_type}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  NIK anak
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='nik'
                    fullWidth
                    value={state.nik}
                    onChange={(e) => handleChange('nik', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Nomor akta lahir
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='akta'
                    fullWidth
                    value={state.akta}
                    onChange={(e) => handleChange('akta', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Nomor kartu keluarga
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='nkk'
                    fullWidth
                    value={state.nkk}
                    onChange={(e) => handleChange('nkk', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Nomor induk siswa nasional
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='nisn'
                    fullWidth
                    value={state.nisn}
                    onChange={(e) => handleChange('nisn', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Nomor handphone anak
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='mobilePhone'
                    fullWidth
                    value={state.mobilePhone}
                    onChange={(e) => handleChange('mobilePhone', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Email anak
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='email'
                    fullWidth
                    value={state.email}
                    onChange={(e) => handleChange('email', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Tinggal bersama
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='stay_with'
                  placeholder='Pilih keluarga'
                  options={stayWithOptions}
                  value={state.stay_with}
                  onChange={(e) => handleChange('stay_with', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Provinsi
              </Grid>
              <Grid item sm={8} xs={12}>
                <Select
                  name='province'
                  placeholder='Pilih provinsi'
                  options={provinceOptions}
                  value={state.province}
                  onChange={(e) => handleChange('province', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kota / Kabupaten
              </Grid>
              <Grid item sm={8} xs={12}>
                <Select
                  name='regency'
                  placeholder='Pilih kota kabupaten'
                  options={regencyOptions}
                  value={state.regency}
                  onChange={(e) => handleChange('regency', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kecamatan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Select
                  name='district'
                  placeholder='Pilih kecamatan'
                  options={districtOptions}
                  value={state.district}
                  onChange={(e) => handleChange('district', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Desa / kelurahan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Select
                  name='village'
                  placeholder='Pilih desa kelurahan'
                  options={villageOptions}
                  value={state.village}
                  onChange={(e) => handleChange('village', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Alamat
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='address'
                  fullWidth
                  value={state.address}
                  onChange={(e) => handleChange('address', e)}
                  multiline={true}
                  rows={3}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Anak ke
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='birthOrder'
                  fullWidth
                  type="number"
                  value={state.birthOrder && state.birthOrder.toString()}
                  onChange={(e) => handleChange('birthOrder', e)}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='birthPlace'
                  fullWidth
                  value={state.birthPlace}
                  onChange={(e) => handleChange('birthPlace', e)}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Tanggal lahir
              </Grid>
              <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.birthDate}
                    onChange={(e) => handleChange('birthDate', e)}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Agama
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='religion'
                  placeholder='Pilih agama'
                  options={religionOptions}
                  value={state.religion}
                  onChange={(e) => handleChange('religion', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Warga negara
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='nationality'
                  placeholder='Pilih Negara'
                  options={nationalityOptions}
                  value={state.nationality}
                  onChange={(e) => handleChange('nationality', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>


            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                sekolah
              </Grid>
              <Grid item xs={8}>
                <ReadOnlyTextField value={state.school && state.school.name} />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Kelas
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='kelas'
                  placeholder='Pilih Kelas'
                  options={kelasOptions}
                  value={state.kelas}
                  onChange={(e) => handleChange('kelas', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Jurusan
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='kelas'
                  placeholder='Pilih Jurusan'
                  options={jurusanOptions}
                  value={state.jurusan}
                  onChange={(e) => handleChange('jurusan', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Sekolah asal
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='oldSchoolName'
                    fullWidth
                    value={state.oldSchoolName}
                    onChange={(e) => handleChange('oldSchoolName', e)}
                  />
                </Grid>
              </Grid>
            </Grid>


            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Anak adopsi
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={state.isAdopted === 1}
                  onChange={(e) => handleChange("isAdopted", e)}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Jumlah saudara kandung
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='siblings'
                  fullWidth
                  type="number"
                  value={state.siblings && state.siblings.toString()}
                  onChange={(e) => handleChange('siblings', e)}
                />
              </Grid>
            </Grid>


            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Hobi
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='hoby'
                    fullWidth
                    value={state.hoby}
                    onChange={(e) => handleChange('hoby', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Cita - cita
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='dream'
                    fullWidth
                    value={state.dream}
                    onChange={(e) => handleChange('dream', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Riwayat penyakit
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='medicalHistory'
                    fullWidth
                    value={state.medicalHistory}
                    onChange={(e) => handleChange('medicalHistory', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container >
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                  Prestasi sebelumnya
                </Grid>
                <Grid item xs={8}>
                  <DebouncedTextField
                    margin='dense'
                    id='achievementHistory'
                    fullWidth
                    value={state.achievementHistory}
                    onChange={(e) => handleChange('achievementHistory', e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent='center'>
              <Button fullWidth onClick={submit} variant='contained' color='success'>
                Simpan
              </Button>
            </Grid>
          </Grid>
        }

      </Grid>

    </Grid>



  </>;
}


export default PsbProfile;

