import React,{useState, useEffect, useRef} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Conditional from 'components/Conditional';
import { Check } from '@mui/icons-material'; 
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import { Switch } from '@mui/material';


const SettingBoolean =(props)=> {
  const{setting={}, saveSetting} = props
  const [isEditing, setisEditing] = useState(false)
  const [state, setstate] = useState({})
    
  const save=()=>{
    setisEditing(false)
    saveSetting(state)    
  }

  const cancel=()=>{
    setisEditing(false)
    setstate(setting)
  }

  const handleChange=(e)=>{
    setstate({
      ...state,
      booleanValue:e.target.checked ? 1 : 0
    })
  }
  
  useEffect(() => {
    setstate(setting)
  }, [setting])  

    return (
      <Paper sx={{
        p:1, 
        width:'90%', 
        }}>
          <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>
              {state.name}
          </Typography>
          <Conditional condition={isEditing===false}>
              <IconButton
              onClick={()=>setisEditing(true)}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
              <EditIcon fontSize='inherit' />
              </IconButton>
          </Conditional>
          
          <Conditional condition={isEditing===true}>
              <Grid item>
              <IconButton
              onClick={cancel}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
                  <Close fontSize='inherit' />
              </IconButton>
              <IconButton
              onClick={save}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
                  <Check fontSize='inherit' />
              </IconButton>
              </Grid>
          </Conditional>
          </Grid> 
          <Conditional condition={isEditing===false}>
            <Grid container>
              <Switch
                color='primary'
                checked={state.booleanValue===1}
              />
            </Grid> 
          </Conditional>
          <Conditional condition={isEditing===true}>
            <Switch
                color='primary'
                checked={state.booleanValue===1}
                onChange={handleChange}
              />
          </Conditional>                
    </Paper>
    );
  }
  

export default SettingBoolean;