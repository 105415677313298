import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import ProductForm from './ProductForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Button, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';
import DatePicker, { DateObject } from "react-multi-date-picker"
import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import { useHistory } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { filter } from 'draft-js/lib/DefaultDraftBlockRenderMap';


const ProductReport = (props) => {
  const { user, menus = [] } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, setPreviousMenuActions, gotoPage, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const history = useHistory()

  const defaultFilters = {
    keyword: '',
    store: null,
    store_id: undefined,
    product: null,
    product_id: undefined,
    start_date: new DateObject,
    end_date: new DateObject,
    sorting_type: {value: 1, label: 'Produk terlaris'},
    sorting_type_value: 1
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [storeOptions, setstoreOptions] = useState([])
  const [productOptions, setproductOptions] = useState([])
  const [totalSales, settotalSales] = useState(0)
  const [totalProfit, settotalProfit] = useState(0)
  const sortingTypeOptions = [
    { value: 1, label: 'Produk terlaris' },
    { value: 2, label: 'Profit terbesar' },
    { value: 3, label: 'Penjualan terkecil' },
  ]
  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value

    if (fieldName === 'sorting_type') {
      filtersTemp['sorting_type_value'] = value ? value.value : undefined
    }

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      store_id: filters.store ? filters.store.value : undefined,
      product_id: filters.product ? filters.product.value : undefined,
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
      sorting_type_value:filters.sorting_type_value,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
    gettotalSales(params)
    gettotalProfit(params)
  }

  const getDataByParams = async (params = {}) => {
    startLoading()
    const response = await axios.get(endpoint.product.report, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get product report')
    }
    stopLoading()
  }

  const gettotalSales = async (parameter) => {
    let params = {
      ...parameter
    }

    const response = await axios.get(endpoint.product.sales, { params: params })
    if (response) {
      settotalSales(response.data)
    } else {
      showError('get total sales')
      settotalSales(0)
    }
  }

  const gettotalProfit = async (parameter) => {
    let params = {
      ...parameter
    }

    const response = await axios.get(endpoint.product.profit, { params: params })
    if (response) {
      settotalProfit(response.data)
    } else {
      showError('get total sales')
      settotalProfit(0)
    }
  }

  const getstoreOptions = async () => {
    const response = await axios.get(endpoint.store.option);
    if (response && response.data) {
      setstoreOptions(generalListOptionMapper(response.data));
    }
  };

  const getproductOptions = async () => {
    const params = {
      group: 'products'
    }
    const response = await axios.get(endpoint.product.option, { params: params });
    if (response && response.data) {
      setproductOptions(generalListOptionMapper(response.data));
    }
  };

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }


  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getData()
    getstoreOptions()
    getproductOptions()
  }, [])



  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/cashier-minimarket')} variant='text' color='primary'>
              Kasir
            </SmallButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/product')} variant='text' color='primary'>
              Produk
            </SmallButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/store')} variant='text' color='primary'>
              Toko
            </SmallButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent='flex-end'>
        <Grid item >
          <Paper
            sx={{
              minWidth: 100,
              height: 'auto',
              p: 1,
              backgroundColor: 'white'
            }}
            elevation={4}>
            <Typography variant="body2" component="div">
              Total Omzet
            </Typography>
            <Typography variant="h6" component="div">
              {new Intl.NumberFormat().format(totalSales)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item >
          <Paper
            sx={{
              minWidth: 100,
              height: 'auto',
              p: 1,
              backgroundColor: 'white'
            }}
            elevation={4}>
            <Typography variant="body2" component="div">
              Total Profit
            </Typography>
            <Typography variant="h6" component="div">
              {new Intl.NumberFormat().format(totalProfit)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ my: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='store'
              placeholder='Usaha...'
              options={storeOptions}
              onChange={(e) => filterChange('store', e)}
              styles={defaultSelectStyle}
              value={filters.store}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='product'
              placeholder='Produk...'
              options={productOptions}
              onChange={(e) => filterChange('product', e)}
              styles={defaultSelectStyle}
              value={filters.product}
            />
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Mulai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.start_date}
                onChange={(e) => filterChange('start_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Sampai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.end_date}
                onChange={(e) => filterChange('end_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              name='sorting_type'
              placeholder='Urutan...'
              options={sortingTypeOptions}
              onChange={(e) => filterChange('sorting_type', e)}
              styles={defaultSelectStyle}
              value={filters.sorting_type}
            />
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Produk</TableCell>
                <TableCell>Modal</TableCell>
                <TableCell>Harga</TableCell>
                <TableCell>Terjual</TableCell>
                <TableCell>Omzet</TableCell>
                <TableCell>Profit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>{row.product && row.product.name}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.buyPrice)}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.sellPrice)}</TableCell>
                  <TableCell>{row.items}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.sales)}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.sales - row.capital)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default ProductReport;

