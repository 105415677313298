import { Grid, Box, Paper, Typography } from '@mui/material';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import useUI from 'hooks/useUI';
import React, { useEffect, useState } from 'react'

const EmployeeStatistic = (props) => {

    const { gotoPage, showError } = useUI()
 
    const [employeeStatistics, setemployeeStatistics] = useState([
        { name: 'Total', code: 'TOTAL', value: 0, isActive: null, parent_id: null },
        { name: 'Active', code: 'ACTIVE', value: 0, isActive: 1, parent_id: null },
        { name: 'Not Active', code: 'INACTIVE', value: 0, isActive: 0, parent_id: null }
    ])


    const getemployeeStatistic = async () => {
        let params = {}
        const response = await axios.get(endpoint.employee.statistic, { params: params })

        if (response && response.data) {
            let newStatistics = employeeStatistics.map(statistic => {
                let idx = response.data.findIndex(data => data.code === statistic.code)
                if (idx > -1) {
                    return { ...statistic, value: response.data[idx].value }
                }
                return statistic
            })
            setemployeeStatistics(newStatistics)
        } else {
            showError('get employee')
        }
    }

    const gotoEmployeePage = () => {
        let route = '/dashboard/employee'
        gotoPage(route)
    }

    useEffect(() => {
        getemployeeStatistic()
    }, [])

    return (
        <Grid container item xs={12}
                    sx={{
                        px: 2
                    }}
                >
                    <Grid container justifyContent='flex-start' spacing={1}
                        sx={{
                            color: '#393a3c',
                            pt: 1,
                            pb: 2,
                            px: 2,
                            mb: 2,
                            borderRadius: 4,
                            background: 'white'
                        }}
                    >
                        <Grid item xs={12} container justifyContent='flex-start'>
                            <Typography variant="subtitle2" component="div">
                                Employee
                            </Typography>
                        </Grid>
                        {
                            employeeStatistics.map(statistic => (
                                <Grid item onClick={gotoEmployeePage} sx={{ cursor: 'pointer' }}>
                                    <Paper
                                        sx={{
                                            minWidth: 100,
                                            height: 48,
                                            p: 1,
                                            backgroundColor:'#1e65e9',
                                            color:'white'
                                        }}
                                        elevation={4}>
                                        <Typography variant="body2" gutterBottom component="div">
                                            {statistic.name}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom component="div">
                                            {statistic.value}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))
                        }

                    </Grid>

                </Grid>
    );

}

export default EmployeeStatistic;
