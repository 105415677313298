import React from 'react';
import {
    Grid,
    Box,
    Typography,
    useMediaQuery
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import EditStringPopUp from 'components/EditStringPopUp';
import EditRichTextPopUp from 'components/EditRichTextPopUp';
import { useTheme } from '@mui/styles';


const Featured = (props) => { 
    const { backgroundColor, title = '', content = '', image = '' } = props
    const { user, hasRole } = useAuth()
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const saveTitle = (value) => {
        props.saveSetting('HOME_FEATURED_TITLE', value)
    }

    const saveContent = (value) => {
        props.saveSetting('HOME_FEATURED_CONTENT', value)
    }

    return (
        <Box bgcolor='#ffffff'>
            <Grid container justifyContent='space-between' sx={{
                py: 8,
                px: 4,
                bgcolor: {backgroundColor},
                color: '#ffffff',
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100
            }}>
                <Grid item xs={12} md={5} container justifyContent='center' alignContent='center'
                    sx={{
                        px: 1,
                    }}
                >
                    <Typography variant={smallScreen?'h4':'h3'} component="div" >
                        {title}
                        {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={title} save={saveTitle} />}
                        <span id='featured'></span>
                    </Typography>

                </Grid>
                <Grid item xs={12} md={5} container justifyContent='center' alignContent='center'
                    sx={{
                        px: 1,
                    }}
                >
                    <Typography variant="body1" component="div" >
                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                        {hasRole('SCHOOL_ADMIN') && <EditRichTextPopUp value={content} save={saveContent} />} 
                    </Typography>
                </Grid>
            </Grid>
        </Box>

    );
}

export default Featured;
