import React from 'react';
import {
    Grid,
    Box,
    Typography,
    useMediaQuery
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import EditStringPopUp from 'components/EditStringPopUp';
import EditRichTextPopUp from 'components/EditRichTextPopUp';
import { useTheme } from '@mui/styles';


const Vision = (props) => { 
    const { backgroundColor, visionTitle = '', visionContent = '', visionImage = '', missionTitle = '', missionContent = '', missionImage = '' } = props
    const { user, hasRole } = useAuth()
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const savevisionTitle = (value) => {
        props.saveSetting('HOME_VISION_TITLE', value)
    }

    const savevisionContent = (value) => {
        props.saveSetting('HOME_VISION_CONTENT', value)
    }

    const savemissionTitle = (value) => {
        props.saveSetting('HOME_MISSION_TITLE', value)
    }

    const savemissionContent = (value) => {
        props.saveSetting('HOME_MISSION_CONTENT', value)
    }

    return (
        <Box bgcolor='#ffffff'>
            <Grid container justifyContent='space-between' sx={{
                py: 8,
                px: 4,
                bgcolor: {backgroundColor},
                color: '#ffffff',
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100
            }}>
                 <span id='vision'></span>
                <Grid item xs={12} md={5} container justifyContent='center' alignContent='flex-start'
                    sx={{
                        px: 1,
                    }}
                >
                    <Typography variant={smallScreen?'h4':'h3'} component="div" >
                        {visionTitle}
                        {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={visionTitle} save={savevisionTitle} />}
                       
                    </Typography>
                    <Typography variant="body1" component="div" >
                        <div dangerouslySetInnerHTML={{__html: visionContent}}></div>
                        {hasRole('SCHOOL_ADMIN') && <EditRichTextPopUp value={visionContent} save={savevisionContent} />} 
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5} container justifyContent='center' alignContent='flex-start'
                    sx={{
                        px: 1,
                    }}
                >
                    <Typography variant={smallScreen?'h4':'h3'} component="div" >
                        {missionTitle}
                        {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={missionTitle} save={savemissionTitle} />}
                    </Typography>
                    <Typography variant="body1" component="div" >
                        <div dangerouslySetInnerHTML={{__html: missionContent}}></div>
                        {hasRole('SCHOOL_ADMIN') && <EditRichTextPopUp value={missionContent} save={savemissionContent} />} 
                    </Typography>
                </Grid>
            </Grid>
        </Box>

    );
}

export default Vision;
