import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Alert, AlertTitle, Button, Checkbox, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import DebouncedTextField from 'components/DebouncedTextField';
import SmallButton from 'components/SmallButton';
import Conditional from 'components/Conditional';
import { educationTypeOptions, geteducationTypeObject, getjobTypeObject, getlifeStatusObject, getreligionObject, getsalaryTypeObject, getsexTypeObject, getstayWithObject, getwaliTypeObject, jobTypeOptions, lifeStatusOptions, religionOptions, salaryTypeOptions, sexTypeOptions, stayWithOptions, waliTypeOptions } from 'utils/options';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import { useLocation } from 'react-router-dom';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const PsbNewParent = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, gotoPage } = useUI()
  const { detail } = useAuth()
  const query = useQuery();

  const initialState = {
    id: undefined,
    fatherName: "",
    motherName: "",
    waliName: "",
    wali_type: null,
    wali_type_value: undefined,
    fatherKtp: "",
    motherKtp: "",
    waliKtp: "",
    fatherPhone: "",
    motherPhone: "",
    waliPhone: "",
    fatherEmail: "",
    motherEmail: "",
    waliEmail: "",
    FatherAlive: {value:1, label:'Hidup'},
    isFatherAlive: 1,
    MotherAlive: {value:1, label:'Hidup'},
    isMotherAlive: 1,
    isHasWali: 0,
    father_job_type: null,
    father_job_type_value: undefined,
    mother_job_type: null,
    mother_job_type_value: undefined,
    wali_job_type: null,
    wali_job_type_value: undefined,
    wali_sex_type: null,
    wali_sex_type_value: undefined,
    fatherJobCorporateName: undefined,
    fatherJobPositionName: undefined,
    fatherJobWorkAddress: undefined,
    motherJobCorporateName: undefined,
    motherJobPositionName: undefined,
    motherJobWorkAddress: undefined,
    waliJobCorporateName: undefined,
    waliJobPositionName: undefined,
    waliJobWorkAddress: undefined,

  }

  const [state, setstate] = useState(initialState)

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'FatherAlive') {
      newState['isFatherAlive'] = value ? value.value : undefined
    }
    else if (fieldName === 'MotherAlive') {
      newState['isMotherAlive'] = value ? value.value : undefined
    }
    else if (fieldName === 'isHasWali') {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'wali_type') {
      newState['wali_type_value'] = value.value
    }
    else if (fieldName === 'father_job_type') {
      newState['father_job_type_value'] = value.value
    }
    else if (fieldName === 'mother_job_type') {
      newState['mother_job_type_value'] = value.value
    }
    else if (fieldName === 'wali_job_type') {
      newState['wali_job_type_value'] = value.value
    }
    else if (fieldName === 'wali_sex_type') {
      newState['wali_sex_type_value'] = value.value
    }
    else if (fieldName === 'father_education_type') {
      newState['father_education_type_value'] = value.value
    }
    else if (fieldName === 'mother_education_type') {
      newState['mother_education_type_value'] = value.value
    }
    else if (fieldName === 'wali_education_type') {
      newState['wali_education_type_value'] = value.value
    }
    else if (fieldName === 'father_salary_type') {
      newState['father_salary_type_value'] = value.value
    }
    else if (fieldName === 'mother_salary_type') {
      newState['mother_salary_type_value'] = value.value
    }
    else if (fieldName === 'wali_salary_type') {
      newState['wali_salary_type_value'] = value.value
    }


    newState[fieldName] = value
    setstate(newState)
  }


  const submit = async () => {


    if (state.fatherName.trim().length === 0) {
      showError('Mohon isi nama ayah')
      return
    }


    if (state.fatherPhone.trim().length === 0) {
      showError('Mohon isi handphone ayah')
      return
    }

    if (!state.father_job_type) {
      showError('Mohon isi pekerjaan ayah')
      return
    }

    if (state.motherName.trim().length === 0) {
      showError('Mohon isi nama ibu')
      return
    }

    if (state.motherPhone.trim().length === 0) {
      showError('Mohon isi handphone ibu')
      return
    }

    if (!state.mother_job_type) {
      showError('Mohon isi pekerjaan ibu')
      return
    }

    if (state.isHasWali === 1) {

      if (!state.wali_sex_type_value) {
        showError('Mohon pilih jenis kelamin wali')
        return
      }

      if (!state.wali_type_value) {
        showError('Mohon pilih tipe wali')
        return
      }

      if (!state.waliName || state.waliName.trim().length === 0) {
        showError('Mohon isi nama wali')
        return
      }

      if (!state.waliPhone || state.waliPhone.trim().length === 0) {
        showError('Mohon isi handphone wali')
        return
      }

      if (!state.wali_job_type) {
        showError('Mohon isi pekerjaan wali')
        return
      }
    }

    if (state.isMotherAlive === 0 && state.isFatherAlive === 0) {
      if (state.isHasWali === 0) {
        showError('Mohon isi data wali')
        return
      }
    }


    let payload = {
      ...state,
      id: detail.id,
      fatherBirthDate: state.fatherBirthDate ? format(state.fatherBirthDate, 'yyyy-MM-dd') : undefined,
      motherBirthDate: state.motherBirthDate ? format(state.motherBirthDate, 'yyyy-MM-dd') : undefined,
      waliBirthDate: state.waliBirthDate ? format(state.waliBirthDate, 'yyyy-MM-dd') : undefined,

    }
    const response = await axios.put(endpoint.psbFlow.newParent, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('simpan biodata')
        gotoPage('/dashboard')
      }

    }
  }

  const getNewParentDetail = async (new_parent_id) => {
    let params = {}
    const response = await axios.get(endpoint.newParent.detail.replace('id', new_parent_id), params)
    if (response && response.data) {
      let row = response.data
      setstate({
        ...row,
        wali_type: getwaliTypeObject(row.wali_type_value),
        father_job_type: getjobTypeObject(row.father_job_type_value),
        father_education_type: geteducationTypeObject(row.father_education_type_value),
        father_education_type_value: row.father_education_type_value,
        father_salary_type: getsalaryTypeObject(row.father_salary_type_value),
        father_salary_type_value: row.father_salary_type_value,
        fatherBirthDate: row.fatherBirthDate ? parse(row.fatherBirthDate, 'yyyy-MM-dd', new Date()) : null,
        mother_job_type: getjobTypeObject(row.mother_job_type_value),
        mother_education_type: geteducationTypeObject(row.mother_education_type_value),
        mother_education_type_value: row.mother_education_type_value,
        mother_salary_type: getsalaryTypeObject(row.mother_salary_type_value),
        mother_salary_type_value: row.mother_salary_type_value,
        motherBirthDate: row.motherBirthDate ? parse(row.motherBirthDate, 'yyyy-MM-dd', new Date()) : null,
        wali_job_type: getjobTypeObject(row.wali_job_type_value),
        wali_type: getwaliTypeObject(row.wali_type_value),
        wali_sex_type: getsexTypeObject(row.wali_sex_type_value),
        wali_education_type: geteducationTypeObject(row.wali_education_type_value),
        wali_education_type_value: row.wali_education_type_value,
        wali_salary_type: getsalaryTypeObject(row.wali_salary_type_value),
        wali_salary_type_value: row.wali_salary_type_value,
        waliBirthDate: row.waliBirthDate ? parse(row.waliBirthDate, 'yyyy-MM-dd', new Date()) : null,
        FatherAlive: getlifeStatusObject(row.isFatherAlive),
        MotherAlive: getlifeStatusObject(row.isMotherAlive)
          
      })
    }
  }


  useEffect(() => {
    if (detail) {
      getNewParentDetail(detail.id)
    }
  }, [detail])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={10} sm={10} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={2} sm={2} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <SmallButton onClick={submit} variant='contained' color='success'>
            Simpan
          </SmallButton>
        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
      </Grid>

      <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400 }}>

        <Grid item xs={12} md={6} container  >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Status ayah
            </Grid>
            <Grid item xs={8}>
            <Select
                  name='FatherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.FatherAlive}
                  onChange={(e) => handleChange('FatherAlive', e)}
                  styles={defaultSelectStyle}
                />
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Nama ayah
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherName'
                  fullWidth
                  value={state.fatherName}
                  onChange={(e) => handleChange('fatherName', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Telepon ayah
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherPhone'
                  fullWidth
                  value={state.fatherPhone}
                  onChange={(e) => handleChange('fatherPhone', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Ktp ayah
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherKtp'
                  fullWidth
                  value={state.fatherKtp}
                  onChange={(e) => handleChange('fatherKtp', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                e-mail ayah
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherEmail'
                  fullWidth
                  value={state.fatherEmail}
                  onChange={(e) => handleChange('fatherEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container alignItems='flex-start'>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.fatherBirthDate}
                    onChange={(e) => handleChange('fatherBirthDate', e)}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran ayah
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherBirthPlace'
                  fullWidth
                  value={state.fatherBirthPlace}
                  onChange={(e) => handleChange('fatherBirthPlace', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir ayah
            </Grid>
            <Grid item xs={8}>
              <Select
                name='father_education_type'
                placeholder='Pilih pendidikan'
                options={educationTypeOptions}
                value={state.father_education_type}
                onChange={(e) => handleChange('father_education_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Ayah
            </Grid>
            <Grid item xs={8}>
              <Select
                name='father_job_type'
                placeholder='Pilih jenis pekerjaan'
                options={jobTypeOptions}
                value={state.father_job_type}
                onChange={(e) => handleChange('father_job_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="fatherJobCorporateName"
                fullWidth
                value={state.fatherJobCorporateName}
                onChange={(e) => handleChange("fatherJobCorporateName", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="fatherJobPositionName"
                fullWidth
                value={state.fatherJobPositionName}
                onChange={(e) => handleChange("fatherJobPositionName", e)}
              />
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="fatherJobWorkAddress"
                fullWidth
                value={state.fatherJobWorkAddress}
                onChange={(e) => handleChange("fatherJobWorkAddress", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan ayah
            </Grid>
            <Grid item xs={8}>
              <Select
                name='father_salary_type'
                placeholder='Pilih penghasilan'
                options={salaryTypeOptions}
                value={state.father_salary_type}
                onChange={(e) => handleChange('father_salary_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Status ibu
            </Grid>
            <Grid item xs={8}>
            <Select
                  name='MotherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.MotherAlive}
                  onChange={(e) => handleChange('MotherAlive', e)}
                  styles={defaultSelectStyle}
                />
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Nama ibu
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='motherName'
                  fullWidth
                  value={state.motherName}
                  onChange={(e) => handleChange('motherName', e)}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Telepon ibu
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='motherPhone'
                  fullWidth
                  value={state.motherPhone}
                  onChange={(e) => handleChange('motherPhone', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Ktp ibu
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='motherKtp'
                  fullWidth
                  value={state.motherKtp}
                  onChange={(e) => handleChange('motherKtp', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                e-mail ibu
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='motherEmail'
                  fullWidth
                  value={state.motherEmail}
                  onChange={(e) => handleChange('motherEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container alignItems='flex-start'>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.motherBirthDate}
                    onChange={(e) => handleChange('motherBirthDate', e)}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran ibu
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='motherBirthPlace'
                  fullWidth
                  value={state.motherBirthPlace}
                  onChange={(e) => handleChange('motherBirthPlace', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir ibu
            </Grid>
            <Grid item xs={8}>
              <Select
                name='mother_education_type'
                placeholder='Pilih pendidikan'
                options={educationTypeOptions}
                value={state.mother_education_type}
                onChange={(e) => handleChange('mother_education_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Ibu
            </Grid>
            <Grid item xs={8}>
              <Select
                name='mother_job_type'
                placeholder='Pilih jenis pekerjaan'
                options={jobTypeOptions}
                value={state.mother_job_type}
                onChange={(e) => handleChange('mother_job_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="motherJobCorporateName"
                fullWidth
                value={state.motherJobCorporateName}
                onChange={(e) => handleChange("motherJobCorporateName", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="motherJobPositionName"
                fullWidth
                value={state.motherJobPositionName}
                onChange={(e) => handleChange("motherJobPositionName", e)}
              />
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="motherJobWorkAddress"
                fullWidth
                value={state.motherJobWorkAddress}
                onChange={(e) => handleChange("motherJobWorkAddress", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan ibu
            </Grid>
            <Grid item xs={8}>
              <Select
                name='mother_salary_type'
                placeholder='Pilih penghasilan'
                options={salaryTypeOptions}
                value={state.mother_salary_type}
                onChange={(e) => handleChange('mother_salary_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Ada wali
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={state.isHasWali === 1}
                onChange={(e) => handleChange("isHasWali", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Tipe wali
            </Grid>
            <Grid item xs={8}>
              <Select
                name='wali_type'
                placeholder='Pilih tipe wali'
                options={waliTypeOptions}
                value={state.wali_type}
                onChange={(e) => handleChange('wali_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item xs={8}>
              <Select
                name='wali_sex_type'
                placeholder='Pilih jenis kelamin'
                options={sexTypeOptions}
                value={state.wali_sex_type}
                onChange={(e) => handleChange('wali_sex_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Nama wali
            </Grid>
            <Grid item xs={8}>
              <DebouncedTextField
                margin='dense'
                id='waliName'
                fullWidth
                value={state.waliName}
                onChange={(e) => handleChange('waliName', e)}
              />
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Telepon wali
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='waliPhone'
                  fullWidth
                  value={state.waliPhone}
                  onChange={(e) => handleChange('waliPhone', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Ktp wali
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='waliKtp'
                  fullWidth
                  value={state.waliKtp}
                  onChange={(e) => handleChange('waliKtp', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                e-mail wali
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='waliEmail'
                  fullWidth
                  value={state.waliEmail}
                  onChange={(e) => handleChange('waliEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container alignItems='flex-start'>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.waliBirthDate}
                    onChange={(e) => handleChange('waliBirthDate', e)}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran wali
              </Grid>
              <Grid item xs={8}>
                <DebouncedTextField
                  margin='dense'
                  id='waliBirthPlace'
                  fullWidth
                  value={state.waliBirthPlace}
                  onChange={(e) => handleChange('waliBirthPlace', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir wali
            </Grid>
            <Grid item xs={8}>
              <Select
                name='wali_education_type'
                placeholder='Pilih pendidikan'
                options={educationTypeOptions}
                value={state.wali_education_type}
                onChange={(e) => handleChange('wali_education_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Wali
            </Grid>
            <Grid item xs={8}>
              <Select
                name='wali_job_type'
                placeholder='Pilih jenis pekerjaan'
                options={jobTypeOptions}
                value={state.wali_job_type}
                onChange={(e) => handleChange('wali_job_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="waliJobCorporateName"
                fullWidth
                value={state.waliJobCorporateName}
                onChange={(e) => handleChange("waliJobCorporateName", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="waliJobPositionName"
                fullWidth
                value={state.waliJobPositionName}
                onChange={(e) => handleChange("waliJobPositionName", e)}
              />
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="waliJobWorkAddress"
                fullWidth
                value={state.waliJobWorkAddress}
                onChange={(e) => handleChange("waliJobWorkAddress", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan wali
            </Grid>
            <Grid item xs={8}>
              <Select
                name='wali_salary_type'
                placeholder='Pilih penghasilan'
                options={salaryTypeOptions}
                value={state.wali_salary_type}
                onChange={(e) => handleChange('wali_salary_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>



        </Grid>
        <Grid container justifyContent='flex-end'>
          <SmallButton onClick={submit} variant='contained' color='success'>
            Simpan
          </SmallButton>
        </Grid>

      </Grid>


    </Grid>


  </>;
}


export default PsbNewParent;

