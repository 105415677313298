import { Grid, Box, Paper, Typography } from '@mui/material';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import React, { useEffect, useState } from 'react'
import Protected from 'components/Protected';
import StudentStatistic from './StudentStatistic';
import ParentStatistic from './ParentStatistic';
import EmployeeStatistic from './EmployeeStatistic';
import VisitorStatistic from './VisitorStatistic';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import NewStudentPsbFlow from './NewStudentPsbFlow';
import BillStatistic from './BillStatistic';
import FavoriteMenu from './FavoriteMenu';
import FinancialActionShortcut from './FinancialActionShortcut';

const DashboardContent = ({ code }) => {
    const { menus = [] } = useAuth()
    const { setActiveMenuActions } = useUI()
    const [isPsbOpen, setisPsbOpen] = useState(0);

    const getPsbOpenStatus = async () => {
        const params = {}
        const response = await axios.get(endpoint.psb.open, { params: params });
        if (response && response.data) {
            setisPsbOpen(response.data)
        }
    };

    useEffect(() => {
        let dashboardMenu = menus.filter(menu => menu.code === 'DASHBOARD')[0]
        if (dashboardMenu) {
            setActiveMenuActions(dashboardMenu)
        }
    }, [menus]);

    useEffect(() => {
        getPsbOpenStatus()
    }, []);

    return (
        <Grid container justifyContent='center' alignContent='flex-start' alignItems='flex-start' sx={{
            p: 1,
        }}>

            <FavoriteMenu />

            <Protected allowedCodes={['FINANCIAL_ACTION_SHORTCUT']} >
                <FinancialActionShortcut />
            </Protected>

            <Protected allowedCodes={['STUDENT_STATISTIC']} >
                <StudentStatistic />
            </Protected>

            <Protected allowedCodes={['EMPLOYEE_STATISTIC']} >
                <EmployeeStatistic />
            </Protected>

            <Protected allowedCodes={['PARENT_STATISTIC']} >
                <ParentStatistic />
            </Protected>

            <Protected allowedCodes={['VISITOR_STATISTIC']} >
                <VisitorStatistic />
            </Protected>

            {
                (isPsbOpen === 1) &&
                <Protected allowedCodes={['PSB_FLOW']} >
                    <NewStudentPsbFlow />
                </Protected>
            }


            <Protected allowedCodes={['BILL_STATISTIC']} >
                <BillStatistic />
            </Protected>

        </Grid>
    )
}

export default DashboardContent;