import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import useUI from 'hooks/useUI';
import Conditional from 'components/Conditional';
import { Alert, FormControl, FormLabel, List, ListItem, ListItemButton, ListItemText, Radio, RadioGroup } from '@mui/material';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper} from 'utils/mappers';
import { defaultSelectStyle } from 'utils/theme.js'


const Register = (props) => {
  const { user } = props
  const { gotoPage, showError } = useUI()
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [error, seterror] = useState(null);
  const [success, setsuccess] = useState(false);
  const [password, setpassword] = useState("");
  
  const nameChange = e => {
    setname(e.target.value);
  };
    const usernameChange = e => {
    setusername(e.target.value);
  };

  const passwordChange = e => {
    setpassword(e.target.value);
  };


  const submit = async () => {
    if(name.trim() === ''){
      return
    }

    if(username.trim() === ''){
      return
    }

    if(password.trim() === ''){
      return
    }

    let payload = {
      name:name,
      username: username,
      password: password
    }

    const response = await axios.post(endpoint.newParent.register, payload)
    if (response && response.data) {
      if (response.data.error) {
        seterror(response.data.error + ', ' + response.data.message)
      } else {
        setsuccess(true)
      }
    } else {
      seterror('mohon maaf, terjadi kesalahan di server')
    }
  }
 

  return (
    <>
      <Conditional condition={success === false} >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Mendaftar
            </Typography>


            <Grid container spacing={2}>

              <Grid container sx={{ my: 2 }}>
                <Conditional condition={error !== null}>
                  <Grid item xs={12}>
                    <Alert severity="error" onClose={() => seterror(null)} >{error}</Alert>
                  </Grid>
                </Conditional>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Nama orang tua / wali"
                  name="name"
                  value={name}
                  onChange={nameChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Email atau No. WA orang tua"
                  name="username"
                  value={username}
                  onChange={usernameChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={passwordChange}
                />
              </Grid>

            </Grid>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={submit}
            >
              Kirim
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Sudah punya akun ? Langsung Login
                </Link>
              </Grid>
            </Grid>

          </Box>
        </Container>
      </Conditional>

      <Conditional condition={success === true} >
        <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400, mt: 4, px: 2 }}>
          <Grid item xs={12} container justifyContent='center'>
            <Typography color='primary' component='h6' variant='h6'>
              Selamat, anda berhasil mendaftar !!!
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <img src={`${api.url}/assets/undraw_hello_re_3evm.svg`} style={{ width: 200, height: 200 }} />
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Typography color='primary' component='h6' variant='h6'>
              Silahkan cek email atau aplikasi whatsapp anda dan klik link aktivasi yang kami kirimkan
            </Typography>
          </Grid> 
          <Grid item xs={12} md={6} container justifyContent='center' sx={{mt:2}}>
            <Button variant='outlined' color='primary' fullWidth onClick={()=>gotoPage('/login')}>
              Login
            </Button>
          </Grid>
        </Grid> 
      </Conditional>
    </>
  )
}

export default Register; 
