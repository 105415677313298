import React, { useState, useEffect, createContext } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import MoneyCard from 'components/MoneyCard';
import { Card, CardContent, Dialog, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format, parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import Confirmation from 'components/Confirmation';
import { EchoInstance } from 'App';


const BalanceWithdrawal = (props) => {

  const { row = {}, getData, getDataByParams } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()
  const reset = () => {
    return {

    }

  }
  const [pageAction, setpageAction] = useState('READ')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [queue, setqueue] = useState(null)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [amount, setamount] = useState(0)
  const [reason, setreason] = useState('')
  const [student, sestudent] = useState(null)
  const [coa_hartaOptions, setcoa_hartaOptions] = useState([]);
  const [coa_harta, setcoa_harta] = useState(null);


  const closeDialog = () => {
    setopenDialog(false)
  }

  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        setqueue(response.data)
        props.setqueue(response.data)
      } else {
        setqueue(null)
      }
    }

  }

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
        getLastQueue()
      } else {
        showError('delete queue')
      }
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }



  const submitConfirmation = () => {
    if (!queue) {
      showInfo('Kartu belum terbaca')
      return;
    }
    if (!coa_harta) {
      showInfo('Mohon pilih sumber dana')
      return;
    }

    if (!amount || Number(amount) < 1) {
      showInfo('Nominal tidak boleh kosong')
      return;
    }
    setMaxWidth("sm");
    setdialogContent(
      <Confirmation
        handleClose={closeDialog}
        action={submit}
        actionName="Cairkan"
        message='Pastikan sudah dana tersedia'
        title='Konfirmasi pencairan dana'
      />
    );
    setopenDialog(true);
  };

  const getcoa_hartaOptions = async () => {
    const params = {
      group: 'HARTA',
      employee_id: detail.id
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_hartaOptions(generalListOptionMapper(response.data));
    }
  };

  const submit = async () => {
    const params = {
      queue_id: queue.id,
      user_id: queue.user_id,
      amount: amount,
      reason: reason,
      coa_harta_id: coa_harta.id,
      admin_employee_id: detail.id,
      balance_type_value: 1
    }
    const response = await axios.post(endpoint.balance.withdrawal, params)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('Tarik tunai')
        deleteLastQueue()
        getData()
        props.closeDialog()
      }

    } else {
      showError('Tarik tunai')
    }
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    getcoa_hartaOptions()
  }, [])

  useEffect(() => {
    getLastQueue()
    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME + 'card')
      .listen('NewCardQueue', (e) => {
        getLastQueue()
      });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME + 'card');
      deleteLastQueue()
    }
  }, [])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            pb: 2,
            width: 'inherit',
          }}
        >

          <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
            {
              queue && <MoneyCard user={queue} onClose={deleteLastQueue} />
            }
            <Conditional condition={queue === null}>
              <IconButton
                onClick={getLastQueue}
                aria-label='reset'
                size="large">
                <RefreshIcon color='primary' />
              </IconButton>
            </Conditional>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container alignContent='center' alignItems='center' >
              <Select
                name='coa_harta'
                placeholder='Pilih Sumber dana'
                options={coa_hartaOptions}
                value={coa_harta}
                onChange={setcoa_harta}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container alignContent='center' alignItems='center' >
              <DebouncedTextField
                margin='dense'
                id='amount'
                fullWidth
                label='Nominal'
                type="number"
                value={amount}
                onChange={(e) => setamount(e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container alignContent='center' alignItems='center' >
              <DebouncedTextField
                multiline
                rows={2}
                id='reason'
                fullWidth
                label='Keperluan'
                value={reason}
                onChange={(e) => setreason(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        batal
      </Button>
      <Button variant="contained" onClick={submitConfirmation} color="primary">
        Lakukan pembayaran
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  </>;
}

export default BalanceWithdrawal;

