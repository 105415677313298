import React,{useState} from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const TwoActionConfirmation =(props)=> {
  const {handleClose, firstAction, secondAction, firstLabel, secondLabel, message, title} = props

  const onFirstAction = ()=>{
    firstAction()
    handleClose()
  }

  const onSecondAction = ()=>{
    secondAction()
    handleClose()
  }

  return (
  <>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  variant="contained" onClick={onFirstAction} color="primary">
          {firstLabel}
        </Button>
        <Button  variant="contained" onClick={onSecondAction} color="secondary">
          {secondLabel}
        </Button>
        <Button onClick={handleClose}>
          Batal
        </Button>
      </DialogActions>
  </>

    );
}

export default TwoActionConfirmation;
