import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { Checkbox } from '@mui/material';
import { defaultSelectStyle } from 'utils/theme.js'
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper, } from 'utils/mappers';
import useSetting from 'hooks/useSetting';
import useAuth from 'hooks/useAuth';
import { sexTypeOptions } from 'utils/options';


const BillTypeDetail = (props) => {
  const { row = {}, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError, isAllDataAccess } = useUI()
  const { tahunPelajaran } = useSetting()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      normalPrice: undefined,
      tahun: generalOptionMapper(tahunPelajaran),
      tahun_id: tahunPelajaran.id,
      kelas: null,
      kelas_id: undefined,
      school: null,
      school_id: undefined,
      sex_type: null,
      sex_type_value: undefined,
      isCicil: 0,
      coa_pdpt: null,
      coa_pdpt_id: undefined,
      coa_piutang: null,
      coa_piutang_id: undefined,
      coa_diskon: null,
      coa_diskon_id: undefined,

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [schoolOptions, setschoolOptions] = useState([]);
  const [bankAccountOptions, setbankAccountOptions] = useState([]);
  const [coa_pdptOptions, setcoa_pdptOptions] = useState([]);
  const [coa_piutangOptions, setcoa_piutangOptions] = useState([]);
  const [coa_diskonOptions, setcoa_diskonOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (['isCicil'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'tahun') {
      newState['tahun_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      newState['school_id'] = value.value
      if (value) {
        getkelasOptions(value.jenjang_id)
      }
    }
    else if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'coa_pdpt') {
      newState['coa_pdpt_id'] = value.value
    }
    else if (fieldName === 'coa_piutang') {
      newState['coa_piutang_id'] = value.value
    }
    else if (fieldName === 'coa_diskon') {
      newState['coa_diskon_id'] = value.value
    }
    else if (fieldName === 'bank_account') {
      newState['bank_account_id'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }


  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getbankAccountOptions = async () => {
    let params = {
      isForPaymentGateway: 1
    }

    const response = await axios.get(endpoint.bankAccount.option, { params: params });
    if (response && response.data) {

      setbankAccountOptions(response.data.map(bankAccount => ({
        ...bankAccount,
        label: `${bankAccount.bank} ${bankAccount.number}`,
        value: bankAccount.id
      })));
    }
  };

  const getcoa_piutangOptions = async () => {
    const params = {
      group: 'PIUTANG'
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_piutangOptions(generalListOptionMapper(response.data));
    }
  };

  const getcoa_pdptOptions = async () => {
    const params = {
      group: 'PENDAPATAN'
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_pdptOptions(generalListOptionMapper(
        response.data.filter(item => (item.code.substring(0, 2) !== '46'))
      ))
      setcoa_diskonOptions(generalListOptionMapper(
        response.data.filter(item => (item.code.substring(0, 2) === '46'))
      ))
    }
  };



  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.billType.root, payload)
    if (response && response.data) {
      showSuccess('save bill.type')
      getData()
    } else {
      showError('save bill.type')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.billType.root, payload)
    if (response && response.data) {
      showSuccess('save bill.type')
      getData()
    } else {
      showError('save bill.type')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      tahun: generalOptionMapper(row.tahun),
      school: generalOptionMapper(row.school),
      kelas: generalOptionMapper(row.kelas),
      coa_pdpt: generalOptionMapper(row.coa_pdpt),
      coa_piutang: generalOptionMapper(row.coa_piutang),
      coa_diskon: generalOptionMapper(row.coa_diskon),
      bank_account: row.bank_account ? {
        ...row.bank_account,
        label: `${row.bank_account.bank} ${row.bank_account.number}`,
        value: row.bank_account.id
      } : null
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getschoolOptions()
      gettahunPelajaranOptions()
      getcoa_pdptOptions()
      getcoa_piutangOptions()
      getbankAccountOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun Pelajaran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.tahun && state.tahun.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="tahun"
                  placeholder="Pilih Tahun Pelajaran"
                  options={tahunPelajaranOptions}
                  value={state.tahun}
                  onChange={(e) => handleChange("tahun", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.school && state.school.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='school'
                  placeholder='Pilih sekolah'
                  options={schoolOptions}
                  value={state.school}
                  onChange={(e) => handleChange('school', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kelas
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.kelas && state.kelas.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="kelas"
                  placeholder="Pilih Kelas"
                  options={kelasOptions}
                  value={state.kelas}
                  onChange={(e) => handleChange("kelas", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name='sex_type'
                  placeholder={`Jenis kelamin`}
                  options={sexTypeOptions}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                  value={state.sex_type}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Tagihan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nominal normal
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.normalPrice} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='normalPrice'
                  fullWidth
                  value={state.normalPrice}
                  onChange={(e) => handleChange('normalPrice', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Bisa dicicil
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === "READ"}>
                <Switch
                  color='primary'
                  checked={state.isCicil === 1}
                />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Switch
                  color='primary'
                  checked={state.isCicil === 1}
                  onChange={(e) => handleChange("isCicil", e)}
                />
              </Conditional>
            </Grid>
          </Grid>
          <Conditional condition={state.isCicil === 1}>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jumlah cicilan minimal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.minimumCicil} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='minimumCicil'
                    fullWidth
                    value={state.minimumCicil}
                    onChange={(e) => handleChange('minimumCicil', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Bank pembayaran online
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.bank_account && `${state.bank_account.bank} ${state.bank_account.number}`} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name='bank_account'
                  placeholder={`Rekening bank`}
                  options={bankAccountOptions}
                  onChange={(e) => handleChange('bank_account', e)}
                  styles={defaultSelectStyle}
                  value={state.bank_account}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rek. Pendapatan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.coa_pdpt && state.coa_pdpt.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='coa_pdpt'
                  placeholder='Pilih Rek. Pendapatan'
                  options={coa_pdptOptions}
                  value={state.coa_pdpt}
                  onChange={(e) => handleChange('coa_pdpt', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rek. Piutang
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.coa_piutang && state.coa_piutang.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='coa_piutang'
                  placeholder='Pilih Rek. Piutang'
                  options={coa_piutangOptions}
                  value={state.coa_piutang}
                  onChange={(e) => handleChange('coa_piutang', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rek. Diskon
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.coa_diskon && state.coa_diskon.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='coa_diskon'
                  placeholder='Pilih Rek. Diskon'
                  options={coa_diskonOptions}
                  value={state.coa_diskon}
                  onChange={(e) => handleChange('coa_diskon', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default BillTypeDetail;

