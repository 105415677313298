import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import useUI from 'hooks/useUI.js';
import SmallButton from 'components/SmallButton.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import useAuth from 'hooks/useAuth';
import { Button, Chip, Divider, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward, East, Edit, SelectAll } from '@mui/icons-material';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import SelectEmployeeForm from 'views/employee/SelectEmployeeForm';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';


const StudentClass = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, isAllDataAccess } = useUI()
  const isInitialMount = useRef(true);
  const { detail } = useAuth()
  const defaultFilters = {
    keyword: ''
  }  
  const [isChangePassword, setisChangePassword] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedSubject, setselectedSubject] = useState(null);
  const [userDetail, setuserDetail] = useState(null);
  const [schoolOptions, setschoolOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]); 
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusan, setjurusan] = useState(null);
  const [kelas, setkelas] = useState(null);
  const [school, setschool] = useState(null); 
  const [parallel, setparallel] = useState(null);
  const [nullParallelStudents, setnullParallelStudents] = useState([]);
  const [movedStudents, setmovedStudents] = useState([]);
  const [selectedStudents, setselectedStudents] = useState([]);
  const [selectedStudentIds, setselectedStudentIds] = useState([]);  
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [tahun, settahun] = useState(null);


  const getnullParallelStudents = async (kelas_id) => {
    const params = {
      kelas_id: kelas_id
    }

    const response = await axios.get(endpoint.student.nullParallel, { params: params });

    if (response && response.data) {
      setnullParallelStudents(response.data)
    }
  }

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };


  const getMovedStudents = async (parallel_id) => {
    const params = {
      parallel_id: parallel_id
    }

    const response = await axios.get(endpoint.student.moved, { params: params });

    if (response && response.data) {
      setmovedStudents(response.data)
    }
  }

  const getschoolOptions = async () => {
    let params = {}
    
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, {params:params});
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async (kelas_id) => {
    const params = {
      kelas_id: kelas_id
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };
 
  const submitStudentClass = async () => {
     
    if(!tahun){
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      jenjang_id: school.jenjang_id,
      school_id: school.id,
      kelas_id: kelas.id,
      parallel_id: parallel.id,
      tahun_id:tahun.id,
      student_ids: movedStudents.map(std=>(std.id))
    }

    const response = await axios.post(endpoint.student.class, payload)

    if (response && response.data) {
      showSuccess('simpan') 
    } else {
      showError('simpan')
    }
  }
 

  const selectAll = () => {
    setselectedStudents([...nullParallelStudents])
    setselectedStudentIds(nullParallelStudents.map(student => (student.id)))
  }

  const moveStudents = () => {
    setmovedStudents(
      [
        ...movedStudents,
        ...selectedStudents
      ]
    )
    setnullParallelStudents(
      nullParallelStudents.filter(std=>(!selectedStudentIds.includes(std.id)))
    )
    setselectedStudents([])
    setselectedStudentIds([])
  }
  

  const SchoolChange = (e) => {
    setschool(e)
    getkelasOptions(e.jenjang_id)
  }
  const KelasChange = (e) => {
    setkelas(e)
    getparallelOptions(e.value)
    getnullParallelStudents(e.value)
  }
  const parallelChange = (e) => {
    setparallel(e)
    getMovedStudents(e.value)
  } 


  const closeAddTeacherDialog = (e) => {
    setopenDialog(false)
    setselectedSubject(null)
  }

  const selectStudent = (student) => {
    if (!selectedStudentIds.includes(student.id)) {
      setselectedStudentIds([...selectedStudentIds, student.id])
      setselectedStudents([...selectedStudents, student])
    } else {
      setselectedStudentIds(selectedStudentIds.filter(std => (std !== student.id)))
      setselectedStudents(selectedStudents.filter(std => (std.id !== student.id)))
    }
  }

  const tahunChange = (e) => {
    settahun(e)
  }
 
  const reset = () => {
    if (parallel) {
      getnullParallelStudents(parallel.id)
    } 

  }
 
  useEffect(() => { 
    gettahunPelajaranOptions()
    getschoolOptions() 
  }, [])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

          {
            (movedStudents.length > 0) &&
            <Grid item>
              <SmallButton onClick={submitStudentClass} variant='contained' color='success'>
                Simpan
              </SmallButton>
            </Grid>
          }

        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{ minHeight: 400 }} >
        <Grid item xs={12} container justifyContent='center'>
          <Grid item sm={4} xs={12}>
            <Select
              name="tahun"
              placeholder="Tahun Pelajaran"
              options={tahunPelajaranOptions}
              value={tahun}
              onChange={tahunChange}
              styles={defaultSelectStyle}
            />
          </Grid>

        </Grid>

        <Grid item xs={12} lg={6} container sx={{ mt: 1 }}>
          <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
            <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
              {`${process.env.REACT_APP_STUDENT} belum dapat kelas`}
              <Grid item xs={12} sx={{ my: 1 }}>
                {
                  nullParallelStudents.length > 0 &&
                  <Button onClick={selectAll} fullWidth variant="contained" startIcon={<SelectAll />}>
                    Pilih semua
                  </Button>
                }
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="school"
                  placeholder="Pilih sekolah"
                  options={schoolOptions}
                  value={school}
                  onChange={SchoolChange}
                  styles={defaultSelectStyle}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name='kelas'
                  placeholder='Pilih kelas'
                  options={kelasOptions}
                  value={kelas}
                  onChange={KelasChange}
                  styles={defaultSelectStyle}
                />
              </Grid>
              
              <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {nullParallelStudents.map((student) => {
                  return (
                    <>
                      <ListItem
                        key={student.id}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={(e) => selectStudent(student)}
                            checked={selectedStudentIds.indexOf(student.id) !== -1}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText primary={student.name} />
                        </ListItemButton>
                      </ListItem>
                      <Divider light />
                    </>

                  );
                })}
              </List>
            </Grid>
          </Paper>

        </Grid>

        <Grid item xs={12} lg={6} container justifyContent='center' alignItems='flex-start' alignContent='flex-start' sx={{ mt: 1 }}>
          <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
            <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
              {`${process.env.REACT_APP_STUDENT} sudah dapat kelas`}
              <Grid item xs={12}>
                <Select
                  name='parallel'
                  placeholder='Pilih paralel'
                  options={parallelOptions}
                  value={parallel}
                  onChange={parallelChange}
                  styles={defaultSelectStyle}
                />
              </Grid>

              <Grid item xs={12} sx={{ my: 1 }}>
                {
                  selectedStudents.length > 0 && parallel &&
                  <Button onClick={moveStudents} fullWidth color='success' variant="contained" startIcon={<ArrowUpward />}>
                    {`Masukkan ${process.env.REACT_APP_STUDENT} terpilih`}
                  </Button>
                }
              </Grid>
               
              <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {movedStudents.map((student) => {
                  return (
                    <>
                      <ListItem
                        key={student.id}
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText primary={student.name} />
                        </ListItemButton>
                      </ListItem>

                      <Divider light />
                    </>
                  );
                })}
              </List>
            </Grid>
          </Paper>

        </Grid>
        
      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeAddTeacherDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    > 
    </Dialog>

  </>;
}


export default StudentClass;

