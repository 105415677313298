import React from 'react'
import Chip from '@mui/material/Chip';

const RoleChip = ({role={}}) => {
    return (
        <Chip size="small" label={role.name} 
        style={{
          backgroundColor:'#16cd90',
          color:'white'}} 
        />
    );
}


export default RoleChip;
