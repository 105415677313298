import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getsmart_reader_modeLabel, getsmart_reader_modeObject, smart_reader_modeOptions } from 'utils/options';
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';


const SmartReaderDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      smart_reader_mode:null,
      smart_reader_mode_value:undefined,
      building:null,
      building_id:undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ') 
  const [buildingOptions, setbuildingOptions] = useState([]);
  const [roomOptions, setroomOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'smart_reader_mode') {
      newState['smart_reader_mode_value'] = value ? value.value : undefined
    } 
    else if (fieldName === 'building') {

      newState['building_id'] = value ? value.value : undefined
      getroomOptions(value ? value.value : null)
    }
    else if (fieldName === 'room') {
      newState['room_id'] = value ? value.value : undefined
    } 

    newState[fieldName] = value
    setstate(newState)
  }

  const getbuildingOptions = async () => {
    const response = await axios.get(endpoint.building.option);
    if (response && response.data) {
      setbuildingOptions(generalListOptionMapper(response.data));
    }
  };

  const getroomOptions = async (building_id) => {
    const params = {
      building_id: building_id
    }
    const response = await axios.get(endpoint.room.option, {params:params});
    if (response && response.data) {
      setroomOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.smartReader.root, payload)

    if (response && response.data) {
      showSuccess('save smartReader')
      getData()
    } else {
      showError('save smartReader')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.smartReader.root, payload)
    if (response && response.data) {
      showSuccess('save smartReader')
      getData()
    } else {
      showError('save smartReader')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      smart_reader_mode: getsmart_reader_modeObject(row.smart_reader_mode_value),
      building: generalOptionMapper(row.building),
      room: generalOptionMapper(row.room),
    })
  }
 

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getbuildingOptions()
      getroomOptions() 
    }
  }, [pageAction])

  useEffect(() => {
    
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>



          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid> 

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.number} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='number'
                  fullWidth
                  value={state.number}
                  onChange={(e) => handleChange('number', e)}
                />
              </Conditional>
            </Grid>
          </Grid>  

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
            Key
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.key} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='key'
                  fullWidth
                  value={state.key}
                  onChange={(e) => handleChange('key', e)}
                />
              </Conditional>
            </Grid>
          </Grid>  

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Mode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}> 
                <ReadOnlyTextField value={getsmart_reader_modeLabel(state.smart_reader_mode_value)} /> 
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="Mode"
                  placeholder="Pilih mode"
                  options={smart_reader_modeOptions}
                  value={state.smart_reader_mode}
                  onChange={(e) => handleChange("smart_reader_mode", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Gedung
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}> 
                <ReadOnlyTextField value={state.building && state.building.name} /> 
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="building"
                  placeholder="Pilih Gedung"
                  options={buildingOptions}
                  value={state.building}
                  onChange={(e) => handleChange("building", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ruangan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}> 
                <ReadOnlyTextField value={state.room && state.room.name} /> 
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  isClearable={true}
                  name="room"
                  placeholder="Pilih Ruangan"
                  options={roomOptions}
                  value={state.room}
                  onChange={(e) => handleChange("room", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>
  
         
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default SmartReaderDetail;

