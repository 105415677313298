import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { Checkbox, Chip, Switch } from '@mui/material';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import { getrecurringTypeLabel, getrecurringTypeObject, getscopeTypeLabel, getscopeTypeObject, getsexTypeLabel, getsexTypeObject, recurringTypeOptions, scopeTypeOptions, sexTypeOptions } from 'utils/options';
import useAuth from 'hooks/useAuth';


const AutodebetTypeDetail = (props) => {
  const { row = {}, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError, isAllDataAccess } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      amount: undefined,
      recurringDay: 1,
      isActive: 0,
      recurring_type: { value: 1, label: 'Satu kali' },
      recurring_type_value: 1,
      scope_type: { value: 1, label: 'Semua' },
      scope_type_value: 1,
      sex_type: null,
      sex_type_value: undefined,
      school: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      parallel: null,
      parallel_id: undefined,

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'recurring_type') {
      newState['recurring_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'scope_type') {
      newState['scope_type_value'] = value ? value.value : undefined
      newState['school'] = null
      newState['school_id'] = undefined
      newState['kelas'] = null
      newState['kelas_id'] = undefined
      newState['parallel'] = null
      newState['parallel_id'] = undefined

      if (value && value.value === 2) {
        getschoolOptions()
      }
      if (value && value.value === 3) {
        getkelasOptions()
      }
      if (value && value.value === 4) {
        getparallelOptions()
      }
    }
    else if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      newState['school_id'] = value.value
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'parallel') {
      newState['parallel_id'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.autodebet.type, payload)
    if (response && response.data) {
      showSuccess('save autodebet')
      getData()
    } else {
      showError('save autodebet')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.autodebet.type, payload)
    if (response && response.data) {
      showSuccess('save autodebet')
      getData()
    } else {
      showError('save autodebet')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      recurring_type: getrecurringTypeObject(row.recurring_type_value),
      scope_type: getscopeTypeObject(row.scope_type_value),
      sex_type: getsexTypeObject(row.sex_type_value),
      school: generalOptionMapper(row.school),
      kelas: generalOptionMapper(row.kelas),
      parallel: generalOptionMapper(row.parallel),

    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])


  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nominal
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.amount)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='amount'
                  fullWidth
                  type="number"
                  value={state.amount}
                  onChange={(e) => handleChange('amount', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Cakupan
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getscopeTypeLabel(state.scope_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='scope_type'
                  placeholder='Pilih cakupan'
                  options={scopeTypeOptions}
                  value={state.scope_type}
                  onChange={(e) => handleChange('scope_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.scope_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Sekolah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.school && state.school.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='school'
                    placeholder='Pilih sekolah'
                    options={schoolOptions}
                    value={state.school}
                    onChange={(e) => handleChange('school', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.scope_type_value === 3}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kelas
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.kelas && state.kelas.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='kelas'
                    placeholder='Pilih Kelas'
                    options={kelasOptions}
                    value={state.kelas}
                    onChange={(e) => handleChange('kelas', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.scope_type_value === 4}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Parallel
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.parallel && state.parallel.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='parallel'
                    placeholder='Pilih Parallel'
                    options={parallelOptions}
                    value={state.parallel}
                    onChange={(e) => handleChange('parallel', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsexTypeLabel(state.sex_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.sex_type}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pengulangan
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getrecurringTypeLabel(state.recurring_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='recurringType'
                  placeholder='Pilih pengulangan'
                  options={recurringTypeOptions}
                  value={state.recurring_type}
                  onChange={(e) => handleChange('recurring_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.recurring_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal pendebetan terjadwal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.recurringDay} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='recurringDay'
                    fullWidth
                    type="number"

                    value={state.recurringDay}
                    onChange={(e) => handleChange('recurringDay', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Status
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction !== "EDIT"}>
                <ActiveChip status={state.isActive} margin="0 0 0 8px" />
              </Conditional>
              <Conditional condition={pageAction === "EDIT"}>
                <Switch
                  color='primary'
                  checked={state.isActive === 1}
                  onChange={(e) => handleChange("isActive", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default AutodebetTypeDetail;

