import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { FormControlLabel, Switch, Checkbox, TextField, Box } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { employeeTypeOptions, getemployeeTypeLabel, getemployeeTypeObject, getjobTypeLabel, getjobTypeObject, getparentTypeLabel, getparentTypeObject, getreligionLabel, getreligionObject, getsexTypeLabel, getsexTypeObject, getwaliTypeLabel, getwaliTypeObject, jobTypeOptions, parentTypeOptions, religionOptions, sexTypeOptions, waliTypeOptions } from 'utils/options';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import EditIcon from '@mui/icons-material/Edit';


const EmployeeDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError, showInfo } = useUI()
  const reset = () => {
    return {
      id: undefined,
      user_id: undefined,
      store: null,
      store_id: undefined,
      name: undefined,
      code: undefined,
      mobilePhone: undefined,
      sex_type_value: undefined,
      nik: undefined,
      employee_type_value: undefined,
      isActive: 1,
      birthDate: new Date(),
      joinDate: new Date(),
      tetapDate: null,
      resignDate: null,
      smart_card: null,
      smart_card_value: undefined,
      smart_reader: null,
      smart_reader_number: undefined,
      card_number: undefined,
      cutiBalance: 0,
      photo: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [smart_cardOptions, setsmart_cardOptions] = useState([]);
  const [smart_readerOptions, setsmart_readerOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value.value
    }
    else if (fieldName === 'employee_type') {
      newState['employee_type_value'] = value.value
    }
    else if (fieldName === 'smart_card') {
      newState['smart_card_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'smart_reader') {
      newState['smart_reader_number'] = value ? value.number : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const selectImage = async (event) => {
    let file = event.target.files[0]
    setstate({
      ...state,
      photoFile: file,
      photo: URL.createObjectURL(file)
    })

    if (pageAction === 'EDIT') {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('id', row.id)
      const response = await axios.post(endpoint.employee.photo, formData, {});
      if (response && response.data) {
        showSuccess('save photo')
      } else {
        showError('save photo')
      }
    }
  };


  const getsmart_cardOptions = async () => {
    const params = {
      card_status_value: 1
    }
    const response = await axios.get(endpoint.smartCard.option, { params: params });
    if (response && response.data) {
      setsmart_cardOptions(response.data.map(row => ({ ...row, label: row.number, value: row.number })));
    }
  };

  const getsmart_readerOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.smartReader.option, { params: params });
    if (response && response.data) {
      setsmart_readerOptions(generalListOptionMapper(response.data));
    }
  };


  const insert = async (payload) => {
    const response = await axios.post(endpoint.employee.root, payload)
    if (response && response.data) {
      showSuccess('save employee')
      getData()
    } else {
      showError('save employee')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.employee.root, payload)
    if (response && response.data) {
      showSuccess('save employee')
      getData()
    } else {
      showError('save employee')
    }
  }

  const submit = async () => {
    
    if(!state.name || state.name===''){
      showInfo('mohon ini nama');
      return
    }

    if(!state.mobilePhone || state.mobilePhone===''){
      showInfo('mohon ini nomer HP');
      return
    }

    if(!state.sex_type_value){
      showInfo('mohon pilih jenis kelamin');
      return
    }

    if(!state.nik || state.nik===''){
      showInfo('mohon isi nomor induk');
      return
    }

    if(!state.employee_type_value){
      showInfo('mohon pilih tipe pegawai');
      return
    }

    const params = {
      ...state,
      birthDate: format(state.birthDate, 'yyyy-MM-dd'),
      joinDate: format(state.joinDate, 'yyyy-MM-dd'),
      resignDate: state.resignDate ? format(state.resignDate, 'yyyy-MM-dd'):undefined,
      tetapDate: state.tetapDate ? format(state.tetapDate, 'yyyy-MM-dd'):undefined
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      sex_type: getsexTypeObject(props.row.sex_type_value),
      religion: getreligionObject(props.row.religion_value),
      employee_type: getemployeeTypeObject(props.row.employee_type_value),
      birthDate: parse(props.row.birthDate, 'yyyy-MM-dd', new Date()),
      joinDate: parse(props.row.joinDate, 'yyyy-MM-dd', new Date()),
      tetapDate: props.row.tetapDate ? parse(props.row.tetapDate, 'yyyy-MM-dd', new Date()) : null,
      resignDate: props.row.resignDate ? parse(props.row.resignDate, 'yyyy-MM-dd', new Date()) : null,
      smart_card: row.card_number ? { label: row.card_number, value: row.card_number } : null,
      smart_reader: generalOptionMapper(row.smart_reader),
      photo: `${api.url}/${row.photo}`

    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getsmart_cardOptions()
      getsmart_readerOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Foto
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box sx={{ width: 100, position: 'relative' }}>
                <img
                  src={state.photo}
                  style={{ width: 100, height: 100, borderRadius: 16 }} />

                <Conditional condition={pageAction === 'EDIT'}>
                  <label htmlFor='propic'>
                    <input onChange={selectImage} accept="image/*" id='propic' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                  </label>
                </Conditional>

              </Box>
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Username
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.user && state.user.username} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Smart card
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.card_number} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='smart_card'
                  placeholder='Pilih smart card'
                  options={smart_cardOptions}
                  value={state.smart_card}
                  onChange={(e) => handleChange('smart_card', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Smart reader
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.smart_reader && `(${state.smart_reader.number}) ${state.smart_reader.name}`} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='smart_reader'
                  placeholder='Pilih smart reader'
                  options={smart_readerOptions}
                  value={state.smart_reader}
                  onChange={(e) => handleChange('smart_reader', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Handphone
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.mobilePhone} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="mobilePhone"
                  fullWidth
                  value={state.mobilePhone}
                  onChange={(e) => handleChange("mobilePhone", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsexTypeLabel(state.sex_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.sex_type}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor Induk
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nik} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="nik"
                  fullWidth
                  value={state.nik}
                  onChange={(e) => handleChange("nik", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe pegawai
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getemployeeTypeLabel(state.employee_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='employee_type'
                  placeholder='Pilih tipe pegawai'
                  options={employeeTypeOptions}
                  value={state.employee_type}
                  onChange={(e) => handleChange('employee_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={format(state.birthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.birthDate}
                      onChange={(e) => handleChange('birthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal bergabung
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={format(state.joinDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.joinDate}
                      onChange={(e) => handleChange('joinDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal pegawai tetap
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.tetapDate ? format(state.tetapDate, 'dd  MMMM yyyy'):null} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.tetapDate}
                      onChange={(e) => handleChange('tetapDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal keluar
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.resignDate ? format(state.resignDate, 'dd  MMMM yyyy'):null} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.resignDate}
                      onChange={(e) => handleChange('resignDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Saldo cuti
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.cutiBalance} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ' && state.store !== null}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nama Toko
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.store && state.store.name} />
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={pageAction !== "CREATE"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Status
              </Grid>
              <Grid item xs={8}>
                <Conditional condition={pageAction === "READ"}>
                  <ActiveChip status={state.isActive} margin="0 0 0 8px" />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Switch
                    color='primary'
                    checked={state.isActive === 1}
                    onChange={(e) => handleChange("isActive", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>



        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default EmployeeDetail;

