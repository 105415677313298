import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
    IconButton,
    Popover,
    TextField
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState } from 'draft-js';



const EditRichTextPopUp = (props) => {
    const [anchor, setanchor] = useState(null);
    const [editorState, seteditorState] = useState(
        EditorState.createEmpty()
    );

    const onEditorStateChange = (editorState) => {
        seteditorState(editorState);
    };

    const showForm = (event) => {
        setanchor(event.currentTarget);
    };

    const formClose = () => {
        setanchor(null);
    };

    const saveClick = () => {
        props.save(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setanchor(null);
    };


    const htmlToContent = (html) =>{ 
        const { contentBlocks, entityMap } = htmlToDraft(html);
        return ContentState.createFromBlockArray(contentBlocks, entityMap);
    } 

    useEffect(() => {
        if(props.value){
            seteditorState(EditorState.createWithContent(htmlToContent(props.value))) 
        }
    }, [props.value])
 
    return <>

        <IconButton
            color="secondary"
            onClick={showForm}
            size='small'
            aria-label='edit'
            style={{ margin: 0 }}>
            <EditIcon fontSize='inherit' />
        </IconButton>
        <Popover
            id="popUpProfile"
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={formClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={
                {
                    top: 2
                }
            }
        >
            <Grid container justifyContent='space-between' alignItems='center' sx={{ width: '100%', p: 2 }} >
                <Grid item sm={8} xs={12}>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={formClose}
                        size='small'
                        aria-label='edit'
                        style={{ margin: 0 }}>
                        <Close fontSize='inherit' />
                    </IconButton>
                    <IconButton
                        onClick={saveClick}
                        size='small'
                        aria-label='edit'
                        style={{ margin: 0 }}>
                        <Check fontSize='inherit' />
                    </IconButton>
                </Grid>
            </Grid>
        </Popover>
    </>;

}

export default EditRichTextPopUp;
