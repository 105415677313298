import React, { useState, useEffect } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import {
    Grid,
    Box,
    Typography,
    Stack
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { generalListOptionMapper } from 'utils/mappers';


const Schools = (props) => { 
    const { backgroundColor, history, schools=[] } = props
    const { isAuthenticated, logout } = useAuth()
     
    return (
        <Box bgcolor={backgroundColor}>
            <Grid container justifyContent='center' sx={{
                py: 8,
                color: {backgroundColor},
                bgcolor:'#ffffff',
                borderTopRightRadius:100,
                borderBottomRightRadius:100
            }}>
                {
                    schools.map(school => (
                        <Grid item xs={12} md={3} container justifyContent='center' alignContent='center' sx={{p:2}}>
                            <Stack justifyContent="center" alignItems="center">
                                <img src={school.logo} style={{ width: '50%' }} />
                                <Typography variant="h6" component="div" >
                                    {school.name}
                                </Typography>
                            </Stack>
                        </Grid>
                    ))
                }

            </Grid>
        </Box>


    );
}

export default Schools;
