import React from 'react'
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';


const ActionDisplay = ({code}) => {
    const {user,  actions=[]} = useAuth()
    const { startLoading, stopLoading, activeMenu = {}} = useUI()
    const action =  actions.filter(a=>(a.code===code && a.menu_id===activeMenu.id))[0];
    if(action){
      return action.name
    }else{
      return null
    }      
    
}

export default ActionDisplay;
