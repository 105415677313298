import React,{useState, useEffect} from 'react'; 
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';


const SelectRoleForm =(props)=> {
  const {closeDialog, onSubmit} = props 
  const reset=()=>{    
    return{
      role:null
    }    
  }
  const [state, setstate] = useState(reset())
  const [roleOptions, setroleOptions] = useState([]);

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    newState[fieldName] = value
    setstate(newState)
  }

  const getroleOptions = async () => {
    const response = await axios.get(endpoint.role.option);
    if (response && response.data) {
      setroleOptions(generalListOptionMapper(response.data));
    }
  };  
  
  const submit = async()=>{
    onSubmit(state.role)
    setstate(reset())
  }  
  
  useEffect(() => {
    getroleOptions()
  }, [])

  return <>      
      <DialogContent sx={{height:300}}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >  
          <Grid container justifyContent='center' alignItems='flex-start' style={{margin:'16px 0'}}>                
          <Select
              name="Menu"
              placeholder = "Pilih Akses..."
              options={roleOptions}
              value={state.role}
              onChange={(e)=>handleChange("role",e)}
              styles={defaultSelectStyle}
            />
          </Grid>
            
          </Grid>          
        </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
</>;
}

export default SelectRoleForm;
 
