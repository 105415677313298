import React, { useState, useEffect, createContext } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import MoneyCard from 'components/MoneyCard';
import { Card, CardContent, Dialog, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format, parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import Confirmation from 'components/Confirmation';
import StatusChip from 'components/StatusChip';
import { EchoInstance } from 'App';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const InstallmentContext = createContext();

const UnpaidBuyCreate = (props) => {

  const { row = {}, getData, getDataByParams } = props
  const { user={}, detail } = useAuth()
  const { showSuccess, showError, showInfo, isAllDataAccess } = useUI()
  const reset = () => {
    return {

    }

  }
  const [pageAction, setpageAction] = useState('READ')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [queue, setqueue] = useState(null)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [amount, setamount] = useState(0)
  const [student, sestudent] = useState(null)
  const [employee, setemployee] = useState(null)
  const [store, setstore] = useState(null)
  const [storeOptions, setstoreOptions] = useState([]);


  const closeDialog = () => {
    setopenDialog(false)
  }

  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        setqueue(response.data)
        props.setqueue(response.data)
      } else {
        setqueue(null)
      }
    }

  }

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
        getLastQueue()
      } else {
        showError('delete queue')
      }
    }
  };

  const getstoreOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.store.option, { params: params });
    if (response && response.data) {
      setstoreOptions(generalListOptionMapper(response.data));
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }


  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const handleStudentChange = (value) => {
    sestudent(value)
    if (value) {
      insertStudentToQueue(value)
      getLastQueue()
    }
  };

  const insertStudentToQueue = async (student) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        student_id: student.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'KASIR',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.studentQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    } else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleEmployeeInputChange = (newValue) => {
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleEmployeeChange = (value) => {
    setemployee(value)
    if (value) {
      insertEmployeeToQueue(value)
      getLastQueue()
    }
  };

  const storeChange = (e) => {
    setstore(e)
  };

  const insertEmployeeToQueue = async (employee) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        employee_id: employee.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'KASIR',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.employeeQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    }
    else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const submitConfirmation = () => {

    if (student && employee) {
      showInfo('Hanya bisa isi satu pengguna');
      return;
    } else {
      if (queue || student || employee) {

        setMaxWidth("sm");
        setdialogContent(
          <Confirmation
            handleClose={closeDialog}
            action={submit}
            actionName="Simpan"
            message='Apakah nominal sudah sesuai'
            title='Konfirmasi simpan'
          />
        );
        setopenDialog(true);
      } else {
        showInfo('Belum pilih pengguna')
      }

    }
  };


  const submit = async () => {
    let user_id = undefined
    let queue_id = undefined
    let store_id = undefined

    if (queue) {
      user_id = queue.user_id
      queue_id = queue.id
    }
    else if (student) {
      user_id = student.user_id
    }
    else if (employee) {
      user_id = employee.user_id
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        store_id = detail.store_id
      }  
    }else{
      store_id = store ? store.id: undefined
    }

    if(!store_id){
      showError('tidak ada toko terpilih')
    }

    const params = {
      queue_id: queue_id,
      user_id: user_id,
      amount: amount,
      store_id: store_id,
      admin_employee_id: detail.id,
    }

    const response = await axios.post(endpoint.store.unpaidBuy, params)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('Menabung')
        deleteLastQueue()
        getData()
        props.closeDialog()
      }
    } else {
      showError('Menabung')
    }

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    getstoreOptions()

    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME + 'card')
      .listen('NewCardQueue', (e) => {
        getLastQueue()
      });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME + 'card');
    }
  }, [])





  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            pb: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12}>
              <Conditional condition={pageAction !== 'READ'}>
                <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                  {
                    queue && <MoneyCard user={queue} onClose={deleteLastQueue} />
                  }
                  <Conditional condition={queue === null}>
                    <IconButton
                      onClick={getLastQueue}
                      aria-label='reset'
                      size="large">
                      <RefreshIcon color='primary' />
                    </IconButton>
                  </Conditional>
                </Grid>
                {
                  isAllDataAccess() &&
                  <Conditional condition={queue === null}>
                    <Grid container item xs={12} justifyContent="center" alignItems='center' sx={{ mb: 1 }}>
                      <AsyncSelect
                        isClearable={true}
                        cacheOptions
                        placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                        value={student}
                        loadOptions={loadOptions}
                        onInputChange={handleInputChange}
                        onChange={handleStudentChange}
                        styles={defaultSelectStyle}
                      />
                    </Grid>

                    <Grid container item xs={12} justifyContent="center" alignItems='center' >
                      <AsyncSelect
                        isClearable={true}
                        cacheOptions
                        placeholder="Nama pegawai..."
                        value={employee}
                        loadOptions={loadEmployeeOptions}
                        onInputChange={handleEmployeeInputChange}
                        onChange={handleEmployeeChange}
                        styles={defaultSelectStyle}
                      />
                    </Grid>
                  </Conditional>
                }

              </Conditional>
            </Grid>
          </Grid>

          {
            isAllDataAccess() &&
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid container item xs={12} justifyContent="center" alignItems='center' >
                <Select
                  name='store'
                  placeholder='Pilih toko'
                  options={storeOptions}
                  value={store}
                  onChange={storeChange}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>
          }

 
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container alignContent='center' alignItems='center' >
              <DebouncedTextField
                margin='dense'
                id='amount'
                fullWidth
                label='Nominal'
                type="number"
                value={amount}
                onChange={(e) => setamount(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        batal
      </Button>
      <Button variant="contained" onClick={submitConfirmation} color="primary">
        Simpan
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  </>;
}

export default UnpaidBuyCreate;

