import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format, parse } from 'date-fns'



const TahunPelajaranDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const classes = useStyles();
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      startDate: new Date(),
      endDate: new Date(),
      isActive: 1
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const insert = async (payload) => {
    const response = await axios.post(endpoint.tahunPelajaran.root, payload)
    if (response && response.data) {
      showSuccess('save tahunPelajaran')
      getData()
    } else {
      showError('save tahunPelajaran')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.tahunPelajaran.root, payload)
    if (response && response.data) {
      showSuccess('save tahunPelajaran')
      getData()
    } else {
      showError('save tahunPelajaran')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      startDate: format(state.startDate, 'yyyy-MM-dd'),
      endDate: format(state.endDate, 'yyyy-MM-dd'),
      
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row
    })

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])


  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
           <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container justifyContent='center' alignItems='center'>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal mulai
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={format(state.startDate, 'dd  MMMM yyyy')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.startDate}
                    onChange={(e) => handleChange('startDate', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid item xs={12} container justifyContent='center' alignItems='center'>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal selesai
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={format(state.endDate, 'dd  MMMM yyyy')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.endDate}
                    onChange={(e) => handleChange('endDate', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Active
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ActiveChip status={state.isActive} margin="0 0 0 8px" />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Switch
                  color='primary'
                  checked={state.isActive === 1}
                  onChange={(e) => handleChange("isActive", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </LocalizationProvider>;
}

export default TahunPelajaranDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
