import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { getpayStatusLabel, getpayStatusObject, getmonthLabel, getmonthObject, monthOptions, sexTypeOptions } from 'utils/options';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import AsyncSelect from 'react-select/async';
import useSetting from 'hooks/useSetting';
import useAuth from 'hooks/useAuth';
import ActionDisplay from 'components/ActionDisplay';
import StatusChip from 'components/StatusChip';
import { FormControl, FormControlLabel, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import { format, parse } from 'date-fns';


const BillDetail = (props) => {
  const { row = {}, getData } = props
  const { startLoading, stopLoading, showSuccess, showError, showInfo, isAllDataAccess } = useUI()
  const { tahunPelajaran } = useSetting()
  const { detail } = useAuth()
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const reset = () => {
    return {
      id: undefined,
      tahun: null,
      tahun_id: undefined,
      school: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      parallel: null,
      parallel_id: undefined,
      sex_type: null,
      sex_type_value: undefined,
      new_student: null,
      new_student_id: undefined,
      student: null,
      student_id: undefined,
      bill_type: null,
      bill_type_id: undefined,
      billNumber: undefined,
      amount: 0,
      paidAmount: 0,
      discount: undefined,
      pay_status: null,
      pay_status_value: undefined,
      billed_status: null,
      billed_status_value: 0,
      month: getmonthObject(month + 1),
      month_value: month + 1,
      year: { label: year, value: year },
      year_value: year,
      admin: null,
      admin_employee_id: undefined,
      transactions: [],
      receiver: "4",
      periode: '1',

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [bill_typeOptions, setbill_typeOptions] = useState([])
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [yearOptions, setyearOptions] = useState([])
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["billed_status_value"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'bill_type') {
      newState['bill_type_id'] = eventValue ? eventValue.value : eventValue
      newState['amount'] = eventValue.normalPrice
    }
    else if (fieldName === 'month') {
      newState['month_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'year') {
      newState['year_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'tahun') {
      let tahun_id = value ? value.value : undefined
      newState['tahun_id'] = tahun_id
      newState['bill_type'] = null
      newState['bill_type_id'] = undefined
      getbill_typeOptions(state.school_id, state.kelas_id, tahun_id, state.sex_type_value)
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.value : undefined
      newState['school_id'] = value ? value.school_id : undefined
      newState['bill_type'] = null
      newState['bill_type_id'] = undefined
    }
    else if (fieldName === 'school') {
      newState['school_id'] = value.value
      newState['bill_type'] = null
      newState['bill_type_id'] = undefined
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
      newState['bill_type'] = null
      newState['bill_type_id'] = undefined
    }
    else if (fieldName === 'parallel') {
      newState['parallel_id'] = value ? value.value : undefined
      newState['bill_type'] = null
      newState['bill_type_id'] = undefined
    }

    if (fieldName === 'receiver') {
      value = eventValue.target.value

      newState['receiver'] = value
      newState['school'] = null
      newState['school_id'] = undefined
      newState['kelas'] = null
      newState['kelas_id'] = undefined
      newState['parallel'] = null
      newState['parallel_id'] = undefined
      newState['student'] = null
      newState['student_id'] = undefined
      newState['bill_type'] = null
      newState['bill_type_id'] = undefined
      newState['amount'] = 0
      newState['sex_type'] = null
      newState['sex_type_value'] = undefined

      if (value === '1') {
        if (schoolOptions.length === 0) {
          getschoolOptions()
        }
      }
      else if (value === '2') {
        if (kelasOptions.length === 0) {
          getkelasOptions()
        }
      }
      else if (value === '3') {
        if (parallelOptions.length === 0) {
          getparallelOptions()
        }
      }

    }
    else if (fieldName === 'periode') {
      value = eventValue.target.value
      newState['periode'] = value
    }
    else if (fieldName === 'sex_type') {
      let sex_type_value = value ? value.value : undefined
      newState['sex_type'] = value
      newState['sex_type_value'] = sex_type_value
    }
    else {
      newState[fieldName] = value
    }

    setstate(newState)
  }


  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
      let dates = new Set()
      response.data.forEach(item => {
        let startDate = parse(item.startDate, 'yyyy-MM-dd', new Date())
        let endDate = parse(item.endDate, 'yyyy-MM-dd', new Date())
        dates.add(Number(format(startDate, 'yyyy')))
        dates.add(Number(format(endDate, 'yyyy')))
      });
      setyearOptions(Array.from(dates).map(item => ({ label: item, value: item })));
    }
  };

  const getbill_typeOptions = async (school_id, kelas_id, tahun_id, sex_type_value) => {
    const params = {
      school_id: school_id,
      kelas_id: kelas_id,
      tahun_id: tahun_id,
      sex_type_value: sex_type_value
    }
    const response = await axios.get(endpoint.billType.option, { params: params });
    if (response && response.data) {
      setbill_typeOptions(generalListOptionMapper(response.data));
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const getBillPriceForStudent = async (student_id, bill_type_id) => {
    if (student_id && bill_type_id) {
      const params = {
        student_id: student_id,
        bill_type_id: bill_type_id
      }
      const response = await axios.get(endpoint.student.billType, { params: params });
      if (response && response.data) {
        setstate({ ...state, amount: response.data.price })
      }
    }
  };


  const getYearOptions = async (keyword) => {

    setyearOptions(
      [
        { label: year - 2, value: year - 2 },
        { label: year - 1, value: year - 1 },
        { label: year, value: year },
        { label: year + 1, value: year + 1 },
        { label: year + 2, value: year + 2 },
      ]
    )
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const exportPdf = () => {
    let params = {
      type: 'pdf'
    }
    axios.get(endpoint.bill.export.replace('id', row.id), { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "bill.pdf");
        document.body.appendChild(link);
        link.click();
      })
  }


  const insert = async (payload) => {
    if (detail) {
      const params = {
        ...payload,
        admin_employee_id: detail.id,
        periode: parseInt(state.periode)
      }
      startLoading()
      const response = await axios.post(endpoint.bill.root, params)
      stopLoading()
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        } else {
          showSuccess('simpan tagihan')
          getData()
        }
      } else {
        showError('simpan tagihan')
      }

    }


  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.bill.root, payload)
    if (response && response.data) {
      showSuccess('save bill')
      getData()
    } else {
      showError('save bill')
    }
  }

  const submit = async () => {

    if (state.amount <= 0) {
      showInfo('Tagihan tidak boleh 0')
      return
    }

    if (state.receiver === '1' && !state.school) {
      showInfo('Mohon pilih sekolah')
      return
    }

    if (state.receiver === '2' && !state.kelas) {
      showInfo('Mohon pilih kelas')
      return
    }

    if (state.receiver === '3' && !state.parallel) {
      showInfo('Mohon pilih paralel')
      return
    }

    if (state.receiver === '4' && !state.student) {
      showInfo(`Mohon pilih ${process.env.REACT_APP_STUDENT}`)
      return
    }

    if (!state.bill_type) {
      showInfo('Mohon pilih jenis tagihan')
      return
    }



    const params = {
      ...state,
      periode: parseInt(state.periode),
      receiver: parseInt(state.receiver)
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}

    const response = await axios.get(endpoint.bill.detail.replace("id", row.id), params)
    if (response) {
      let row = response.data
      setstate({
        ...row,
        tahun: generalOptionMapper(row.tahun),
        bill_type: generalOptionMapper(row.bill_type),
        receiver: '4'
      })
    }

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      //getYearOptions()
      gettahunPelajaranOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  useEffect(() => {
    if (tahunPelajaran && pageAction !== 'READ') {
      handleChange('tahun', generalOptionMapper(tahunPelajaran))
    }
  }, [tahunPelajaran, pageAction])

  useEffect(() => {
    if (state.school) {
      getbill_typeOptions(state.school.id, undefined, state.tahun_id, state.sex_type_value)
    }
    else if (state.kelas) {
      getbill_typeOptions(state.kelas.school_id, state.kelas_id, state.tahun_id, state.sex_type_value)
    }
    else if (state.parallel) {
      getbill_typeOptions(state.parallel.school_id, undefined, state.tahun_id, state.sex_type_value)
    }
    else if (state.student) {
      getbill_typeOptions(state.student.school_id, undefined, state.tahun_id, state.sex_type_value)
    }
  }, [state.student, state.school_id, state.kelas_id, state.parallel_id, state.sex_type_value])

  useEffect(() => {
    if (state.student) {
      if (state.student.bill_price_type_value === 2) {
        getBillPriceForStudent(state.student_id, state.bill_type_id)
      }
    }
    if (!state.bill_type_id) {
      setstate({ ...state, amount: 0 })
    }

  }, [state.bill_type_id])

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Conditional condition={pageAction === 'CREATE'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kepada
              </Grid>
              <Grid item sm={8} xs={12}>
                <FormControl>

                  <RadioGroup
                    row
                    aria-labelledby="penerima"
                    name="penerima"
                    defaultValue="4"
                    value={state.receiver}
                    onChange={(e) => handleChange('receiver', e)}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Sekolah" />
                    <FormControlLabel value="2" control={<Radio />} label="Kelas" />
                    <FormControlLabel value="3" control={<Radio />} label="Paralel" />
                    <FormControlLabel value="4" control={<Radio />} label={process.env.REACT_APP_STUDENT} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.receiver === '1' || pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Sekolah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.school && state.school.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='school'
                    placeholder='Pilih sekolah'
                    options={schoolOptions}
                    value={state.school}
                    onChange={(e) => handleChange('school', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.receiver === '2' || pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kelas
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.kelas && state.kelas.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='kelas'
                    placeholder='Pilih Kelas'
                    options={kelasOptions}
                    value={state.kelas}
                    onChange={(e) => handleChange('kelas', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.receiver === '3' || pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Parallel
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.parallel && state.parallel.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='parallel'
                    placeholder='Pilih Parallel'
                    options={parallelOptions}
                    value={state.parallel}
                    onChange={(e) => handleChange('parallel', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.receiver === '4' && state.new_student === null}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                {process.env.REACT_APP_STUDENT}
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={state.student && state.student.name} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <AsyncSelect
                    cacheOptions
                    placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                    value={state.student}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={(e) => handleChange('student', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.receiver !== '4'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jenis kelamin
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    isClearable={true}
                    name='sex_type'
                    placeholder={`Jenis kelamin`}
                    options={sexTypeOptions}
                    onChange={(e) => handleChange('sex_type', e)}
                    styles={defaultSelectStyle}
                    value={state.sex_type}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.new_student !== null}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Calon {process.env.REACT_APP_STUDENT}
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.new_student && state.new_student.name} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun Pelajaran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.tahun && state.tahun.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="tahun"
                  placeholder="Pilih Tahun Pelajaran"
                  options={tahunPelajaranOptions}
                  value={state.tahun}
                  onChange={(e) => handleChange("tahun", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.bill_type && state.bill_type.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='bill_type'
                  placeholder='Pilih jenis tagihan'
                  options={bill_typeOptions}
                  value={state.bill_type}
                  onChange={(e) => handleChange('bill_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor tagihan
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.billNumber} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              <Typography variant="body1" align="center" color='primary'>
                Harga tagihan
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.amount)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='amount'
                  fullWidth
                  value={new Intl.NumberFormat().format(state.amount)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                <Typography variant="body1" align="center" color='secondary'>
                  Tagihan dibayar
                </Typography>
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.paidAmount)} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                <Typography variant="body1" align="center" color='error'>
                  Sisa Tagihan
                </Typography>
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.amount - state.paidAmount)} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Potongan
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.discount} />
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={pageAction === "CREATE"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jumlah bulan
              </Grid>
              <Grid item sm={8} xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="periode"
                    name="periode"
                    defaultValue="1"
                    value={state.periode}
                    onChange={(e) => handleChange('periode', e)}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="1 Bulan" />
                    <FormControlLabel value="3" control={<Radio />} label="3 Bulan" />
                    <FormControlLabel value="6" control={<Radio />} label="6 Bulan" />
                    <FormControlLabel value="12" control={<Radio />} label="12 Bulan" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={state.periode === '1' || pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Bulan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={getmonthLabel(state.month_value)} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name='month'
                    placeholder='Pilih bulan'
                    options={monthOptions}
                    value={state.month}
                    onChange={(e) => handleChange('month', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tahun
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.year_value} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name='year'
                    placeholder='Pilih bulan'
                    options={yearOptions}
                    value={state.year}
                    onChange={(e) => handleChange('year', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={state.periode !== '1'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Mulai bulan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={getmonthLabel(state.month_value)} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name='month'
                    placeholder='Pilih bulan'
                    options={monthOptions}
                    value={state.month}
                    onChange={(e) => handleChange('month', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Mulai tahun
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.year_value} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name='year'
                    placeholder='Pilih tahun'
                    options={yearOptions}
                    value={state.year}
                    onChange={(e) => handleChange('year', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === "CREATE"}>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
                Langsung ditagihkan
              </Grid>
              <Grid item sm={8} xs={2}>
                <Switch
                  color='primary'
                  checked={state.billed_status_value === 1}
                  onChange={(e) => handleChange("billed_status_value", e)}
                />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === "READ"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Status
              </Grid>
              <Grid item sm={8} xs={12}>
                <StatusChip getStatus={getpayStatusObject} status={state.pay_status_value} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.transactions.length > 0}>
            <Grid container justifyContent='center' alignItems='center'
              sx={{ my: 1 }}
            >
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Riwayat Pembayaran
              </Grid>
              <Grid item sm={8} xs={12}
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: 1,
                  backgroundColor: 'white',
                  padding: 2
                }}>
                <Grid item xs={12} container justifyContent="space-between" alignItems='center' sx={{ p: 1, borderBottom: '1px solid #c4c4c4' }}>
                  <Grid item xs={4} container justifyContent="flex-start" alignContent='center'>
                    <Typography variant="h6" align="center">
                      Transaksi
                    </Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-start" alignContent='center'>
                    <Typography variant="h6" align="center">
                      Tanggal
                    </Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end" alignContent='center'>
                    <Typography variant="h6" align="center">
                      Pembayaran
                    </Typography>
                  </Grid>
                </Grid>
                {
                  state.transactions.map((transaction, index) => (
                    <Grid item xs={12} container justifyContent="space-between" alignItems='center' sx={{ p: 1 }}>
                      <Grid item xs={4} container justifyContent="flex-start" alignContent='center'>
                        {transaction.number}
                      </Grid>
                      <Grid item xs={4} container justifyContent="flex-start" alignContent='center'>
                        {format(parse(transaction.transactionDate, 'yyyy-MM-dd', new Date()), 'dd  MMMM yyyy')}
                      </Grid>
                      <Grid item xs={4} container justifyContent="flex-end" alignContent='center'>
                        {new Intl.NumberFormat().format(transaction.pivot.pay)}
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Conditional>



        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Button onClick={exportPdf} color="success">
          PDF
        </Button>
        <Conditional condition={state.pay_status_value === 0}>
          <Protected allowedCodes={['EDIT']}>
            <Button onClick={() => setpageAction("EDIT")} color="primary">
              Edit
            </Button>
          </Protected>
        </Conditional>

        <Protected allowedCodes={['PAY']}>
          <Conditional condition={state.pay_status_value !== 2}>
            <Button variant='contained' onClick={props.payClick} color="success">
              <ActionDisplay code='PAY' />
            </Button>
          </Conditional>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default BillDetail;

