import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import { gettransactionModeLabel, gettransactionModeObject, gettransactionTypeLabel, transactionModeOptions, transactionTypeOptions } from 'utils/options';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { format, parse, parseISO } from 'date-fns';


const TransactionDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      number : undefined,
      transaction_type_value: undefined,
      transaction_type: null,
      transaction_mode_value: undefined,
      transaction_mode: null,
      cart: [],
      bills: []
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'transaction_type') {
      newState['transaction_type_value'] = value.value
    } else if (fieldName === 'isActive') {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }



  const insert = async (payload) => {
    const response = await axios.post(endpoint.transaction.root, payload)

    if (response && response.data) {
      showSuccess('save transaction')
      getData()
    } else {
      showError('save transaction')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.transaction.root, payload)
    if (response && response.data) {
      showSuccess('save transaction')
      getData()
    } else {
      showError('save transaction')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}

    const response = await axios.get(endpoint.transaction.detail.replace("id", row.id), params)
    if (response) {
      const row = response.data
      setstate({
        ...row,
        transactionDate: parse(row.transactionDate, 'yyyy-MM-dd', new Date()),
      }

      )
    }

  }

  const exportPdf = async() => {
    let params = {
      type: 'pdf'
    }

    axios.get(endpoint.transaction.export.replace('id', row.id), { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transaction.pdf");
        document.body.appendChild(link);
        link.click();
      })
  }


  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Conditional condition={pageAction === 'READ'}>
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor transaksi
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.number} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal transaksi
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={
                  `${state.transactionDate && format(state.transactionDate, 'dd  MMMM yyyy')} ${state.created_at && format(parseISO(state.created_at), 'HH:mm')}` 
                }
                  
                   />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis transaksi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction !== "CREATE"}>
                <ReadOnlyTextField value={gettransactionTypeLabel(state.transaction_type_value)} />
              </Conditional>
              <Conditional condition={pageAction === "CREATE"}>
                <Select
                  name="Type"
                  placeholder="Pilih tipe"
                  options={transactionTypeOptions}
                  value={state.transaction_type}
                  onChange={(e) => handleChange("transaction_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Mode transaksi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction !== "CREATE"}>
                <ReadOnlyTextField value={gettransactionModeLabel(state.transaction_mode_value)} />
              </Conditional>
              <Conditional condition={pageAction === "CREATE"}>
                <Select
                  name="Type"
                  placeholder="Pilih mode"
                  options={transactionModeOptions}
                  value={state.transaction_mode}
                  onChange={(e) => handleChange("transaction_mode", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Total transaksi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={`Rp. ${new Intl.NumberFormat().format(state.amount)}`} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <DebouncedTextField
                  margin='dense'
                  id='amount'
                  fullWidth
                  value={state.amount}
                  onChange={(e) => handleChange('amount', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Keterangan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                  multiline={true}
                  rows={3}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.transaction_type_value === 1}>
            <Grid container justifyContent='center' alignItems='center'
              sx={{ my: 1 }}
            >
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Keranjang
              </Grid>
              <Grid item sm={8} xs={12}
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: 1,
                  backgroundColor: 'white',
                  padding: 1
                }}>

                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produk</TableCell>
                        <TableCell>Jumlah</TableCell>
                        <TableCell>Subtotal</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.cart.map((cartItem) => (
                        <TableRow key={cartItem.id}>
                          <TableCell>{cartItem.product.name}</TableCell>
                          <TableCell>{cartItem.count}</TableCell>
                          <TableCell>{new Intl.NumberFormat().format(cartItem.sellPrice * cartItem.count)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.transaction_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center'
              sx={{ my: 1 }}
            >
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tagihan
              </Grid>
              <Grid item sm={8} xs={12}
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: 1,
                  backgroundColor: 'white',
                  padding: 1
                }}>

                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Jenis</TableCell>
                        <TableCell>Nama</TableCell>
                        <TableCell>Pembayaran</TableCell>
                        <TableCell>Besaran</TableCell>
                        <TableCell>Terbayar</TableCell>
                        <TableCell>Sisa</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.bills.map((bill) => (
                        <TableRow key={bill.id}>
                          <TableCell>{bill.bill_type && bill.bill_type.name}</TableCell>
                          <TableCell>{bill.student && bill.student.name}</TableCell>
                          <TableCell>{new Intl.NumberFormat().format(bill.pivot.pay)}</TableCell>
                          <TableCell>{new Intl.NumberFormat().format(bill.amount)}</TableCell>
                          <TableCell> {new Intl.NumberFormat().format(bill.pivot.paid)}</TableCell>
                          <TableCell>{new Intl.NumberFormat().format(bill.pivot.outstanding)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button onClick={exportPdf} color="success">
        PDF
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default TransactionDetail;

