import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';

const StudentPerformance = (props) => {

    const [selectedGroup, setselectedGroup] = useState('ATTENDANCE')
    const [performances, setperformances] = useState([])

    const getperformances = async () => {
        const params = {
            group:selectedGroup
        }


        const response = await axios.get(endpoint.student.performance, { params: params });
        if (response) {
            setperformances(response.data)
        }
    };

    const handleChangeTab = (event, newValue) => {
        setselectedGroup(newValue);
    };

    useEffect(() => {
        getperformances()
    }, [])

    return <>
        <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
            sx={{
                p: 2,
                bgcolor: 'white',
                borderRadius: 4
            }}
        >
            <Grid container alignItems='center' alignContent='center'
                sx={{ my: 1 }}
            >
                <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
                    sx={{ pl: 1 }}
                >
                    <MenuBreadCrumb />
                </Grid>
                <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

                </Grid>
            </Grid>


            <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start' sx={{ minHeight: 400 }} >
                <Tabs
                    value={selectedGroup}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={handleChangeTab}
                >
                    <Tab
                        disableRipple
                        label='Kehadiran'
                        value='ATTENDANCE'
                    />
                    <Tab
                        disableRipple
                        label='Keaktifan'
                        value='ACTIVE'
                    />
                </Tabs>

                <Box sx={{ mb: 5 }} />

                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {
                        performances.map(performance => (
                            <Grid item xs={12} sm={4} container sx={{ mb: 2 }}>
                                <Paper elevation={3} sx={{ width: '100%', p: 1 }}>
                                    <Grid item container>
                                        <Grid item xs={12}>
                                            {performance.name}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {performance.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>

            </Grid>
        </Grid>


    </>;
}


export default StudentPerformance;

