import React,{useState, useEffect} from 'react'; 
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper } from 'utils/mappers';
import AsyncSelect from 'react-select/async';
import { defaultSelectStyle } from 'utils/theme';


const SelectUserForm =(props)=> {
  const {closeDialog, onSubmit} = props 
  const reset=()=>{    
    return{
      selectedNewUser:null
    }    
  }
  const [state, setstate] = useState(reset())
  const [nameKeyWord, setnameKeyWord] = useState('');

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    
    newState[fieldName] = value
    setstate(newState)
  }

  const getUserOptions = async(keyword)=>{
    const params={
      keyword:keyword
    }
    const response = await axios.get(endpoint.user.option, {params:params})    
    
    if(response){
      return generalListOptionMapper(response.data)
    }else{
      return []
    }
  }

  const loadOptions = async(inputValue,callback) => {
    callback(await getUserOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };
  
  const submit = async()=>{
    onSubmit(state.selectedNewUser)
    setstate(reset())
  }  
  

  return <>      
      <DialogContent sx={{height:300}}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >  
          <Grid container justifyContent='center' alignItems='flex-start' style={{margin:'16px 0'}}>                
            <AsyncSelect
              cacheOptions
              placeholder = "Pilih User..."
              value={state.selectedNewUser}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={(e)=>handleChange('selectedNewUser',e)}
              styles={defaultSelectStyle}
            />
          </Grid>
            
          </Grid>          
        </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
</>;
}

export default SelectUserForm;
 
