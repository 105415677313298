import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Alert, AlertTitle, Button, Input } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import { parameterListOptionMapper } from 'utils/mappers';
import { Verified } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PsbFile = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showError } = useUI()
  const { user, detail } = useAuth()
  const [documentTypeOptions, setdocumentTypeOptions] = useState([])
  const [newStudentFiles, setnewStudentFiles] = useState([])
  const [selectedFile, setselectedFile] = useState(null)
  const [studentPsbFlows, setstudentPsbFlows] = useState([])
  const [lastPsbFlow, setlastPsbFlow] = useState(null)
  const [currentPsbFlow, setcurrentPsbFlow] = useState(null)
  const timestamp = Date.now();
  const [isCurrentFlow, setisCurrentFlow] = useState(false)
  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const query = useQuery();

  const getnewStudentOptions = async () => {
    let params = {
      user_id: user.id
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      setnewStudentOptions(response.data)
      let new_student_id = query.get('new_student_id')

      if (response.data[0]) {
        if (new_student_id) {
          setselectedNewStudent(response.data.filter(newStudent => (newStudent.id === parseInt(new_student_id)))[0])
        } else {
          setselectedNewStudent(response.data[0])
        }
      }

    } else {
      showError('get new student')
    }
  }

  const newStudentClick = (newStudent) => {
    setselectedNewStudent(newStudent)
  }

  const fileChange = async (document_type_value, event) => {
    const formData = new FormData();

    let file = event.target.files[0]
    formData.append('file', file)

    formData.append('id', selectedNewStudent.id)
    formData.append('document_type_value', document_type_value)

    const response = await axios.post(endpoint.psbFlow.file, formData, {});
    if (response && response.data) {
      getNewStudentFiles(selectedNewStudent.id)
      if (response.data.next) {
        gotoPage('/dashboard' + '?new_student_id=' + selectedNewStudent.id)
      }
    }
  };

  const isThisCurrentFlow = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id,
      flow_id: query.get('id')
    }
    const response = await axios.get(endpoint.psbFlow.isCurrent, { params: params })

    if (response) {
      if (response.data === 1) {
        setisCurrentFlow(true)
      } else {
        setisCurrentFlow(false)
      }
    } else {
      showError('get student psb flow')
    }
  }

  const getStudentPsbFlows = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id
    }
    const response = await axios.get(endpoint.newStudent.psbFlow, { params: params })

    if (response && response.data) {
      setstudentPsbFlows(response.data.map(flow => flow.id))
      if (response.data[0]) {
        setlastPsbFlow(response.data[response.data.length - 1])
      }
    } else {
      showError('get student psb flow')
    }
  }

  const getcurrentPsbFlow = async (new_student_id) => {

    const response = await axios.get(endpoint.newStudent.currentPsbFlow.replace('new_student_id', new_student_id))

    if (response) {
      setcurrentPsbFlow(response.data)
    } else {
      showError('get current psb flow')
    }
  }

  const getdocumentTypeOptions = async () => {
    const params = {
      group: 'psb_document_type'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setdocumentTypeOptions(parameterListOptionMapper(response.data));
    }
  };

  const getNewStudentFiles = async (new_student_id) => {
    let params = {}
    const response = await axios.get(endpoint.newStudent.file.replace('id', new_student_id), params)
    if (response) {
      setnewStudentFiles(response.data)
    }
  }


  useEffect(() => {
    if (detail) {
      getnewStudentOptions()
    }
  }, [detail])

  useEffect(() => {
    if (selectedNewStudent) {
      isThisCurrentFlow(selectedNewStudent.id)
      getdocumentTypeOptions()
      getNewStudentFiles(selectedNewStudent.id)
      getStudentPsbFlows(selectedNewStudent.id)
      getcurrentPsbFlow(selectedNewStudent.id)
    }
  }, [selectedNewStudent])


  useEffect(() => {
    let documentTypeValues = documentTypeOptions.map(item => (item.value))
    let notExistFiles = newStudentFiles.filter(item => (!documentTypeValues.includes(item.document_type_value)))

    let newDocumentTypeOptions = documentTypeOptions.map(
      documentType => {
        let matchStudentFile = undefined
        newStudentFiles.forEach(studentFile => {
          if (studentFile.document_type_value === documentType.value) {
            matchStudentFile = studentFile
          }
        });
        return {
          ...documentType,
          path: matchStudentFile ? matchStudentFile.path : undefined
        }
      }
    )

    newDocumentTypeOptions = [...newDocumentTypeOptions, ...notExistFiles.map(item=>({...item, ...item.document_type}))]



    setdocumentTypeOptions(newDocumentTypeOptions)
  }, [newStudentFiles])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>


        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>


      </Grid>

      <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400 }} >
        <Grid xs={12}>
          {
            currentPsbFlow && lastPsbFlow && currentPsbFlow.id !== lastPsbFlow.id &&
            <Alert severity="info"
              sx={{
                borderRadius: 4
              }}>
              <AlertTitle>Informasi</AlertTitle>
              Proses selanjutnya adalah <strong>{currentPsbFlow.name}</strong>
            </Alert>
          }

          {
            currentPsbFlow && lastPsbFlow && currentPsbFlow.id === lastPsbFlow.id &&
            <Alert severity="info"
              sx={{
                borderRadius: 4
              }}>
              <AlertTitle>Informasi</AlertTitle>
              anda sudah selesai <strong>{currentPsbFlow.name}</strong>
            </Alert>
          }

        </Grid>

        <VerticalPillSelection
          selected={selectedNewStudent}
          options={newStudentOptions}
          onSelection={newStudentClick}
        />

        {
          !isCurrentFlow &&
          <Grid container direction='column' spacing={2} sx={{ mt: 2 }}>
            <Grid item container justifyContent='center'>
              Mohon selesaikan proses sebelumnya
            </Grid>
            <Grid item container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_process_re_gws7.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item container justifyContent='center'>
              Anda dapat melihat tahapannya di dashboard
            </Grid>
          </Grid>
        }

        {
          isCurrentFlow &&
          <Grid item xs={12} md={6} container sx={{ mt: 2 }}>
            {
              documentTypeOptions.map(documentType => (
                <Grid item xs={12} container sx={{
                  bgcolor: '#dff6ff',
                  borderRadius: 4,
                  my: 2,
                  p: 2
                }}>
                  <Grid item xs={12} >
                    <Grid item xs={12} >
                      <Typography component='h6' variant='h6'>
                        {documentType.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent='center' alignItems='center'
                      sx={{
                        my: 1,
                      }}
                    >
                      {
                        documentType.path &&
                        <img style={{ width: 200, height: 200 }} src={`${api.url}/${documentType.path}?t=${timestamp}`} />
                      }
                    </Grid>
                    <Grid item xs={12} >
                      {
                        <Typography variant='body1' color='error'>
                          {documentType.comment}
                        </Typography>
                      }

                    </Grid>

                    
                      <Grid container>
                        <Grid item xs={6} >
                          <label htmlFor={`${documentType.value}-upload`}>
                            <input onChange={(e) => fileChange(documentType.value, e)} accept="image/*" id={`${documentType.value}-upload`} type="file" style={{ display: 'none' }} />
                            <Button variant="contained" component="span">
                              Upload file
                            </Button>
                          </label>
                        </Grid>

                        <Grid item xs={6} container justifyContent='flex-end' alignItems='center'>
                          {
                            documentType.path &&
                            <Box bgcolor='white' width={36} height={36} display='flex' justifyContent='center' alignItems='center' borderRadius={50}>
                              <Verified color='success' fontSize='large' />
                            </Box>
                          }

                        </Grid>
                      </Grid>

                  </Grid>

                </Grid>
              ))
            }
          </Grid>
        }

      </Grid>
    </Grid>

  </>;
}


export default PsbFile;

