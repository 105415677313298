import React, { useEffect, useState } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import Conditional from 'components/Conditional';
import { defaultSelectStyle } from 'utils/theme.js'
import Select from 'react-select'
import { generalListOptionMapper } from 'utils/mappers';
import useAuth from 'hooks/useAuth';

const BillCashConfirmation = (props) => {
  const { detail } = useAuth()
  const { handleClose, action, transactionMode, balance, transactionModeChange, bills, manualCoaBank, setmanualCoaBank, manualCoaKas, setmanualCoaKas } = props
  const [coaBankOptions, setcoaBankOptions] = useState([]);
  const [coaKasOptions, setcoaKasOptions] = useState([]);
  const onAction = () => {
    action()
  }

  const getcoaBankOptions = async () => {
    const params = {
      group: 'HARTA',
      code_prefix: '12'
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoaBankOptions(generalListOptionMapper(response.data));
    }
  };

  const getcoaKasOptions = async () => {
    const params = {
      group: 'HARTA',
      code_prefix: '11',
      employee_id: detail.id
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoaKasOptions(generalListOptionMapper(response.data));
    }
  };

  useEffect(() => {
    getcoaBankOptions()
    getcoaKasOptions()
  }, [])

  return (
    <>
      <DialogTitle id="form-dialog-title">Konfirmasi pembayaran</DialogTitle>
      <DialogContent sx={{ height: 300 }}>
        <FormControl>

          <RadioGroup
            row
            aria-labelledby="transactionMode"
            name="transactionMode"
            defaultValue="2"
            value={transactionMode}
            onChange={(e) => transactionModeChange(e)}
          >
            <FormControlLabel value="2" control={<Radio />} label="Terima uang tunai" />
            <FormControlLabel value="4" control={<Radio />} label="Manual bank transfer" />
            <FormControlLabel value="1" control={<Radio />} label="Saldo" />
          </RadioGroup>
        </FormControl>
        <Conditional condition={transactionMode === '2'}>
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rekening Kas
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='coa_kas'
                placeholder='Pilih  Rek. Kas'
                options={coaKasOptions}
                value={manualCoaKas}
                onChange={setmanualCoaKas}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>
        </Conditional>

        <Conditional condition={transactionMode === '4'}>
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rekening Bank
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='coa_bank'
                placeholder='Pilih  Rek. Bank'
                options={coaBankOptions}
                value={manualCoaBank}
                onChange={setmanualCoaBank}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>
        </Conditional>

        <Conditional condition={transactionMode === '1'}>
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Saldo {bills[0] && bills[0].student && bills[0].student.name}
            </Grid>
            
            <Grid item sm={8} xs={12}>
              <strong>Rp. {balance && new Intl.NumberFormat().format(balance.balance)}</strong>
            </Grid>
           </Grid>
        </Conditional>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAction} color="secondary">
          Terima pembayaran
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          Batal
        </Button>
      </DialogActions>
    </>

  );
}

export default BillCashConfirmation;
