import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { Card, CardHeader, Checkbox, FormControlLabel, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NumberFormatCustom from 'components/NumberFormatCustom';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { defaultSelectStyle } from 'utils/theme.js'
import { generalListOptionMapper, studentListOptionMapper } from 'utils/mappers';
import Confirmation from 'components/Confirmation';
import useAuth from 'hooks/useAuth';
import { dayOptions } from 'utils/options';
import { Box } from '@mui/system';


const DuplicateForm = (props) => {
  const { row = {} } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()
  const [kelasOptions, setkelasOptions] = useState([]);
  const [kelases, setkelases] = useState([]);
  const [days, setdays] = useState([]);

  const submit = async () => {
    let payload = {
      jam_pelajaran_id : row.id,
      kelas_ids:kelases.map(kelas=>(kelas.id)),
      day_values:days.map(day=>(day.value))
    }

    const response = await axios.post(endpoint.jamPelajaran.duplicate, payload)

    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('duplikat jam pelajaran')
      }
      
      props.onClose()
      props.getData()
      props.clearSelectedRow()
    } else {
      showError('duplikat jam pelajaran')
    }
  }

  const getkelasOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };


  const toggleKelas = (newKelas) => {
    let newKelases = kelases
    if (kelases.filter(kelas => kelas.id === newKelas.id)[0]) {
      newKelases = newKelases.filter(item => item.id != newKelas.id)
    }else{
      newKelases = [...newKelases, newKelas]
    }
    setkelases(newKelases)
  };


  const toggleDay = (newDay) => {
    let newDays = days
    if (days.filter(day => day.value === newDay.value)[0]) {
      newDays = newDays.filter(item => item.value != newDay.value)
    }else{
      newDays = [...newDays, newDay]
    }
    setdays(newDays)
  };

  const selectAllKelases = () => {
    setkelases([...kelasOptions])
  };

  const isIncluded = (list, item, property) => {
    if (list.filter(obj => obj[property] === item[property])[0]) {
      return true
    }
    return false
  }

  useEffect(() => {
    getkelasOptions()
  }, [])

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid item xs={6} sm={6} md={4} lg={4} container justifyContent="center" style={{ padding: 8, width: '100%' }}>
            <Card style={{ width: '100%' }}>
              <CardHeader title="Kelas" />
              <Button onClick={selectAllKelases}> Pilih semua </Button>
              <List sx={{
                height: 350,
                overflow: 'scroll'
              }}
              >
                {
                  kelasOptions.map((kelas) => {
                    const labelId = `checkbox-list-label-${kelas.id}`;

                    return (
                      <ListItem
                        key={kelas.id}
                        role={undefined}
                        dense
                        button
                        onClick={(e) => toggleKelas(kelas)}

                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isIncluded(kelases, kelas, 'id')}
                            onChange={(e) => toggleKelas(kelas)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={kelas.id} primary={kelas.name} />
                      </ListItem>
                    );
                  })}
              </List>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={4} container justifyContent="center" style={{ padding: 8, width: '100%' }}>
            <Card style={{ width: '100%' }}>
              <CardHeader title="Hari"  />
              <Box height={36}/>
              <List
                sx={{
                  height: 350,
                  overflow: 'scroll'
                }}
              >
                {
                  dayOptions.map((day) => {
                    const labelId = `checkbox-list-label-${day.value}`;

                    return (
                      <ListItem
                        key={day.value}
                        role={undefined}
                        dense
                        button
                        onClick={(e) => toggleDay(day)}

                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isIncluded(days, day, 'value')}
                            onChange={(e) => toggleDay(day)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={day.value} primary={day.label} />
                      </ListItem>
                    );
                  })}
              </List>
            </Card>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>

  </>;
}

export default DuplicateForm;

