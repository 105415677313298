import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';


const ActionDetail = (props) => {
  const { row = {}, getData, getActionsOfMenu, menu } = props
  const { showSuccess, showError } = useUI()
  const classes = useStyles();

  const reset = () => {
    return {
      id: undefined,
      menu_id: menu ? menu.id : null,
      menu: menu ? generalOptionMapper(menu) : null,
      name: undefined,  
      code: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ') 
  const [menuOptions, setmenuOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'menu') {
      newState['menu_id'] = value.value
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getmenuOptions = async () => {
    const response = await axios.get(endpoint.menu.option);
    if (response && response.data) {
      setmenuOptions(generalListOptionMapper(response.data));
    }
  };

 

  const insert = async (payload) => {
    const response = await axios.post(endpoint.action.root, payload)
    if (response && response.data) {
      showSuccess('save action')
      if (getActionsOfMenu) {
        getActionsOfMenu()
      } else {
        getData()
      }
    } else {
      showError('save action')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.action.root, payload)
    if (response && response.data) {
      showSuccess('save action')
      if (getActionsOfMenu) {
        getActionsOfMenu()
      } else {
        getData()
      }
    } else {
      showError('save action')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      menu: menu ? generalOptionMapper(menu) : generalOptionMapper(row.menu), 
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    getmenuOptions() 
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
    
  }, [row])



  return <>
    <DialogContent className={classes.dialogContent}>
      <Grid container spacing={2} justifyContent='space-between' className={classes.root}>
        <Grid container justifyContent='center' alignItems='flex-start' className={classes.content}>
          
        <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' style={{ margin: '8px 0' }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Menu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.menu && state.menu.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="Menu"
                  placeholder="Pilih Menu"
                  options={menuOptions}
                  value={state.menu}
                  onChange={(e) => handleChange("menu", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>
  
           
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default ActionDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {

  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
