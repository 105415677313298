import { useDispatch, useSelector } from 'react-redux';
import { Icon } from "@iconify/react";
import close from "@iconify/icons-ant-design/close-circle-filled";
// redux
import { startLoading, stopLoading, setActiveMenu, setPreviousMenu, setMenuActions, setActiveForms, showSuccessSnackbar, showErrorSnackbar, showInfoSnackbar, hideSnackbar } from 'redux/slices/ui';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import useAuth from './useAuth';
import { useHistory } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function useSetting() {
  // JWT Auth
  const dispatch = useDispatch();  
  const { tahunPelajaran} = useSelector(
    (state) => state.setting
  );
  

  const settahunPelajaran = (tahunPelajaran) =>{
    dispatch(settahunPelajaran(tahunPelajaran))
  }

  return {
    tahunPelajaran,
    settahunPelajaran:(tahunPelajaran)=>dispatch(settahunPelajaran(tahunPelajaran))
  };
}
