
export const userOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row, label:row.username, value:row.id}))
  }
  else{
    return null
  }  
  
  }

export const generalListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.name, value:row.id}))
  }
  else{
    return null 
  }  
  
}

export const jamListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:`${row.name}(${row.school.code})`, value:row.id}))
  }
  else{
    return null 
  }
}  

export const menuListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:`${row.name}(${row.code})`, value:row.id}))
  }
  else{
    return null 
  }  
  
}

export const groupListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.group, value:row.group}))
  }
  else{
    return null 
  }  
  
}

export const scheduleListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>{
      let subjectName = row.subject ? `${row.subject.name}` : ''
      let parallelName = row.parallel ? `${row.parallel.name}` : ''
      return {...row,label:`(${row.jam_ke}) - ${subjectName} - ${parallelName}`, value:row.id}
    })
  }
  else{
    return null 
  }  
  
}

export const studentListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>{
      let parallelName = row.parallel ? `(${row.parallel.name})` : ''
      return {...row,label:`${row.name} ${parallelName}`, value:row.id}
    })
  }
  else{
    return null 
  }  
  
}

export const generalOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.name, value:row.id}
  }
  else{
    return null
  }  
}
 
export const jamOptionMapper=(row)=>{
  if(row){
    return {...row,label:`${row.name} (${row.school.code})`, value:row.id}
  }
  else{
    return null
  }  
}

export const cardOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.number, value:row.number}
  }
  else{
    return null
  }  
}

export const parameterOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.name, value:row.value}
  }
  else{
    return null
  }
  
}

export const parameterListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.name, value:Number(row.value)}))
  }
  else{
    return null
  }
  
}

export const billTypeOptionMapper=(row)=>{
  if(row){
    return {...row,label:`${row.name} ${row.tahun && row.tahun.name}`, value:row.id}
  }
  else{
    return null
  }  
}

export const billTypeListOptionMapper=(rows)=>{
  if(rows){
    
    return rows.map(row=>({...row,label:`${row.name} ${row.tahun&&row.tahun.name}`, value:row.id}))
  }
  else{
    return null
  }
  
}