import React,{useState, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import DebouncedTextField from 'components/DebouncedTextField';


const ActionApproverForm =(props)=> {
  const {closeDialog, onSubmit} = props
  const classes = useStyles();
  const reset=()=>{    
    return{
      role:null,
      level:1
    }    
  }
  const [state, setstate] = useState(reset())
  const [roleOptions, setroleOptions] = useState([]);
  
  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    newState[fieldName] = value
    setstate(newState)
  }

  const getroleOptions = async () => {
    const response = await axios.get(endpoint.role.option);
    if (response && response.data) {
      setroleOptions(generalListOptionMapper(response.data));
    }
  };

  
  const submit = async()=>{
    if(state.role){
      onSubmit(state)
    }    
    setstate(reset())
  }  

  useEffect(() => {
    getroleOptions()
  }, [])
  

  return <>      
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} justifyContent='space-between' className={classes.root}>
          <Grid container justifyContent='center' alignItems='flex-start' className={classes.content}>                            

          <Grid container justifyContent='center' alignItems='flex-start' style={{margin:'16px 0'}}>                
            <Select
              name="Menu"
              placeholder = "Pilih Akses..."
              options={roleOptions}
              value={state.role}
              onChange={(e)=>handleChange("role",e)}
              styles={defaultSelectStyle}
            />
          </Grid>
          <Grid container justifyContent='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="Level"
                type="number"
                label="Level"
                fullWidth
                value={state.level}
                onChange={(e)=>handleChange("level",e)}
              />
            </Grid>
            
          </Grid>          
        </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
</>;
}

export default ActionApproverForm;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:'16px 0',
    minHeight:200
  },
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
