import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import { Button, Icon, IconButton, ListItem, ListItemIcon, ListItemText, Tab, Tabs } from '@mui/material';
import SmallButton from 'components/SmallButton';
import { blue } from '@mui/material/colors';
import { Edit } from '@mui/icons-material';

const Documentation = (props) => {


    return <>
        <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
            sx={{
                p: 2,
                bgcolor: 'white',
                borderRadius: 4
            }}
        >
            <Grid container alignItems='center' alignContent='center'
                sx={{ my: 1 }}
            >
                <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
                    sx={{ pl: 1 }}
                >
                    <MenuBreadCrumb />
                </Grid>
                <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

                </Grid>
            </Grid>


            <Grid container sx={{ minHeight: 400 }} >
                <h1>Panduan penggunaan aplikasi ZIAD</h1>
                <ul>
                    <li>
                        <h2>Melengkapi data</h2>
                    </li>
                    <p>Melengkapi data diperlukan sebelum aplikasi dapat digunakan, berikut ini adalah urutan data - data yang harus dilengkapi sesuai kebutuhan sekolah</p>
                    <ol>
                        <li>
                            <h3>Jenjang</h3>
                        </li>
                        <li>
                            <h3>Sekolah</h3>
                        </li>
                        <li>
                            <h3>Kelas</h3>
                        </li>
                        <li>
                            <h3>Jurusan</h3>
                        </li>
                        <li>
                            <h3>Parallel</h3>
                        </li>
                        <li>
                            <h3>Jam Pelajaran</h3>
                        </li>
                        <li>
                            <h3>Mata Pelajaran</h3>
                        </li>
                        <li>
                            <h3>Tahun Pelajaran</h3>
                        </li>
                        <li>
                            <h3>Bidang</h3>
                        </li>
                        <li>
                            <h3>Level posisi</h3>
                            <p>Dalam menu parameter terdapat pengaturan level posisi yang disesuaikan dengan struktur organisasi sekolah atau yayasan.</p>
                        </li>
                        <li>
                            <h3>Posisi</h3>
                            <p>Tambahkan posisi atau jabatan yang berlaku di yayasan atau sekolah, lalu tentukan akses menunya. Akses menu juga dapat dirubah atau ditambahkan pada menu <strong>Akses</strong></p>
                        </li>
                        <li>
                            <h3>Jenis Izin</h3>
                        </li>
                        <li>
                            <h3>Pegawai</h3>
                        </li>
                        <li>
                            <h3>{process.env.REACT_APP_STUDENT}</h3>
                        </li>
                        <li>
                            <h3>Jadwal</h3>
                        </li>
                        <li>
                            <h3>Rekening Bank</h3>
                        </li>
                        <li>
                            <h3>Rekening Perkiraan</h3>
                        </li>
                        <li>
                            <h3>Jenis Tagihan</h3>
                            <p>Jenis tagihan adalah semua jenis pembayaran yang wajib dibayar oleh orang tua {process.env.REACT_APP_STUDENT}.
                                Pastikan sudah melengkapi kode akun atau rekening perkiraan untuk keperluan laporan keuangan. Jenis tagihan berlaku untuk satu tahun pelajaran. Jika ada {process.env.REACT_APP_STUDENT} yang diberikan harga khusus, maka harga tersebut harus di atur di menu {process.env.REACT_APP_STUDENT}.</p>

                        </li>
                        <li>
                            <h3>Kompetensi Dasar</h3>
                        </li>
                        <li>
                            <h3>Gedung</h3>
                        </li>
                        <li>
                            <h3>Ruangan</h3>
                        </li>
                        <li>
                            <h3>Ekstrakurikuler</h3>
                        </li>
                        <li>
                            <h3>Inventaris</h3>
                        </li>
                        <li>
                            <h3>Data Kurikulum</h3>
                        </li>
                        <li>
                            <h3>
                                {
                                    process.env.REACT_APP_SCHOOL_MODE === 'PESANTREN' && 'Tahapan PSB'
                                }
                                {
                                    process.env.REACT_APP_SCHOOL_MODE === 'SEKOLAH' && 'Tahapan PPDB'
                                }
                            </h3>

                        </li>

                    </ol>

                    <li>
                        <h2>Cara penggunaan modul</h2>
                    </li>
                    <p></p>
                    <ol>
                        <li>
                            <h3>Pengaturan Umum</h3>
                            <ul>
                                <li><h4>Mengatur tampilan Home atau Landing Page </h4>
                                    <p>pengaturan Home bisa dilakukan di menu <strong>Pengaturan Umum</strong> di tab
                                        <Tabs value='HOME' style={{ display: 'inline-flex' }}> <Tab value='HOME' label='HOME' /> </Tabs> atau langsung di halaman <strong>Home</strong>
                                    </p>
                                    <ul>
                                        <li>Merubah warna background
                                            <p>Warna background dapat diubah dengan mengubah kode warna di bagian <strong>Background color</strong>. Klik icon  <IconButton
                                                size='small'
                                                aria-label='edit'
                                                style={{ margin: 0 }}>
                                                <Edit fontSize='inherit' />
                                            </IconButton>
                                                Untuk mengubah nilainya

                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Import data pegawai</h3>
                            <p>Data pegawai dapat dibuat satu persatu dengan menekan tombol
                                <SmallButton variant='contained' color='primary'>
                                    Baru
                                </SmallButton>
                                atau mengimport data excel dengan cara </p>
                            <ul>
                                <li>Klik tombol
                                    <SmallButton variant='text' color='primary'>
                                        Import
                                    </SmallButton>
                                </li>
                                <li>Setelah muncul popup, unduh template file excel dengan cara klik tombol
                                    <SmallButton variant='text' color='primary'>
                                        Unduh template
                                    </SmallButton>
                                </li>
                                <li>Isi data pegawai sesuai dengan template agar dapat diproses oleh sistem</li>
                                <li>Setelah diisi, unggah template file excel dengan cara klik tombol
                                    <SmallButton variant='text' color='primary'>
                                        Pilih File excel
                                    </SmallButton>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h3>Mengatur akses pegawai</h3>
                            <p>Akses pegawai diatur berdasarkan posisi, posisi dapat diatur dengan cara :</p>
                            <ul>
                                <li>buka menu
                                    <div style={{ width: 200 }}>
                                        <ListItem
                                            button
                                            sx={{
                                                bgcolor: blue[50],
                                                borderRadius: 4,
                                                '& .MuiListItemIcon-root': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                        >
                                            <ListItemIcon> <Icon color='secondary'>portrait</Icon> </ListItemIcon>
                                            <ListItemText
                                                primary='Pegawai'
                                            />
                                        </ListItem>
                                    </div>
                                </li>
                                <li>Klik pada nama pegawai</li>
                                <li>Isi data {process.env.REACT_APP_STUDENT} sesuai dengan template agar dapat diproses oleh sistem</li>
                                <li>Setelah muncul popup detail, pilih tab <Tabs value='POSISI' style={{ display: 'inline-flex' }}> <Tab value='POSISI' label='POSISI' /> </Tabs></li>
                                <li >
                                    <div style={{ marginTop: 8 }}>
                                        tambahkan posisi dengan cara klik tombol
                                        <SmallButton variant='contained' color='primary'>
                                            Tambah posisi
                                        </SmallButton>
                                    </div>
                                </li>
                                <li>jika ingin menghapus akses, dapat menghapus posisi dengan cara pilih posisi, lalu klik tombol
                                    <SmallButton variant='contained' color='error'>
                                        hapus
                                    </SmallButton>
                                </li>

                            </ul>
                        </li>

                        <li>
                            <h3>Import data {process.env.REACT_APP_STUDENT}</h3>
                            <p>Data {process.env.REACT_APP_STUDENT} dapat dibuat satu persatu dengan menekan tombol
                                <SmallButton variant='contained' color='primary'>
                                    Baru
                                </SmallButton>
                                atau mengimport data excel dengan cara </p>
                            <ul>
                                <li>Klik tombol
                                    <SmallButton variant='text' color='primary'>
                                        Import
                                    </SmallButton>
                                </li>
                                <li>Setelah muncul popup, unduh template file excel dengan cara klik tombol
                                    <SmallButton variant='text' color='primary'>
                                        Unduh template
                                    </SmallButton>
                                </li>
                                <li>Isi data {process.env.REACT_APP_STUDENT} sesuai dengan template agar dapat diproses oleh sistem</li>
                                <li>Setelah diisi, unggah template file excel dengan cara klik tombol
                                    <SmallButton variant='text' color='primary'>
                                        Pilih File excel
                                    </SmallButton>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h3>Mengatur Smart Reader</h3>
                            <p>Smart Reader adalah alat multifungsi, dapat ditambahkan, dihapus atau diganti fungsinya.</p>
                            <ul>
                                <li><h4>Menambahkan Smart Reader</h4>
                                    <p>Jika alat sudah tersedia, harus didaftarkan dengan klik tombol
                                        <SmallButton variant='contained' color='primary'>
                                            Baru
                                        </SmallButton>, nomor mesin harus diisi sesuai dengan nomor yang tertanam di mesin.
                                    </p>
                                </li>
                                <li><h4>Merubah mode / mengganti fungsi</h4>
                                    <p>Mode dari smart reader dapat dirubah sesuai fungsi yang dibutuhkan. </p>
                                    <ul>
                                        <li>buka menu
                                            <div style={{ width: 200 }}>
                                                <ListItem
                                                    button
                                                    sx={{
                                                        bgcolor: blue[50],
                                                        borderRadius: 4,
                                                        '& .MuiListItemIcon-root': {
                                                            color: 'primary.main',
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon> <Icon color='secondary'>sensors</Icon> </ListItemIcon>
                                                    <ListItemText
                                                        primary='Smart Reader'
                                                    />
                                                </ListItem>
                                            </div>
                                        </li>
                                        <li>klik nama Smart Reader</li>
                                        <li>klik tombol
                                            <SmallButton variant='contained' color='primary'>
                                                Ubah
                                            </SmallButton>
                                        </li>
                                    </ul></li>
                            </ul>
                        </li>

                        <li>
                            <h3>Mendaftarkan Smart Card</h3>
                            <p>Smart Card adalah kartu yang dapat digunakan untuk tanda pengenal, transaksi cashless, absen, pinjam buku, dll. Berikut ini adalah cara untuk mendaftarkan kartu baru : </p>
                            <ul>
                                <li>pilih Smart Reader dengan mode REGISTER</li>
                                <li>Tempelkan (tap) Smart Card ke mesin Smart Reader sampai ada suara "beep beep"</li>
                                <li>Lihat kartu baru di menu </li>
                                <div style={{ width: 200 }}>
                                    <ListItem
                                        button
                                        sx={{
                                            bgcolor: blue[50],
                                            borderRadius: 4,
                                            '& .MuiListItemIcon-root': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <ListItemIcon> <Icon color='secondary'>credit_card</Icon> </ListItemIcon>
                                        <ListItemText
                                            primary='Smart Card'
                                        />
                                    </ListItem>
                                </div>
                            </ul>
                        </li>

                        <li>
                            <h3>Mengatur Smart Card Untuk {process.env.REACT_APP_STUDENT}</h3>
                            <p>Setelah data {process.env.REACT_APP_STUDENT} disimpan dalam sistem, selanjutnya adalah memilih kartu Smart Card. berikut ini langkahnya</p>
                            <ul>
                                <li>pilih atau atur Smart Reader dengan mode REGISTER</li>
                                <li>Buka menu {process.env.REACT_APP_STUDENT}</li>
                                <div style={{ width: 200 }}>
                                    <ListItem
                                        button
                                        sx={{
                                            bgcolor: blue[50],
                                            borderRadius: 4,
                                            '& .MuiListItemIcon-root': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <ListItemIcon> <Icon color='secondary'>badge</Icon> </ListItemIcon>
                                        <ListItemText
                                            primary='{ process.env.REACT_APP_STUDENT}'
                                        />
                                    </ListItem>
                                </div>
                                <li>Siapkan kartu dan cari Ketik nama</li>
                                <li>Tempelkan (tap) Smart Card ke mesin Smart Reader sampai muncul nomer kartu (ingat / catat)</li>
                                <li>Pilih {process.env.REACT_APP_STUDENT} sesuai kartu yang dipilih, tekan tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Ubah
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Pilih smart card sesuai nomor kartu yang baru, lalu klik tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Simpan
                                    </SmallButton>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h3>Mengatur Smart Card Untuk Pegawai</h3>
                            <p>Setelah data pegawai disimpan dalam sistem, selanjutnya adalah memilih kartu Smart Card. berikut ini langkahnya</p>
                            <ul>
                                <li>pilih atau atur Smart Reader dengan mode REGISTER</li>
                                <li>Buka menu pegawai</li>
                                <div style={{ width: 200 }}>
                                    <ListItem
                                        button
                                        sx={{
                                            bgcolor: blue[50],
                                            borderRadius: 4,
                                            '& .MuiListItemIcon-root': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <ListItemIcon> <Icon color='secondary'>portrait</Icon> </ListItemIcon>
                                        <ListItemText
                                            primary='Pegawai'
                                        />
                                    </ListItem>
                                </div>
                                <li>Siapkan kartu dan cari nama pegawai</li>
                                <li>Tempelkan (tap) Smart Card ke mesin Smart Reader sampai muncul nomer kartu (ingat / catat)</li>
                                <li>Pilih pegawai sesuai kartu yang dipilih, tekan tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Ubah
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Pilih smart card sesuai nomor kartu yang baru, lalu klik tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Simpan
                                    </SmallButton>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h3>Mengisi saldo manual oleh admin</h3>
                            <p>Saldo kartu dapat ditambah (topup) sendiri oleh orang tua lewat aplikasi atau manual oleh admin (orang tua datang untuk setor tunai). Berikut ini langkah untuk melakukan penambahan saldo oleh admin : </p>
                            <ul>
                                <li>Buka menu tabungan</li>
                                <div style={{ width: 200 }}>
                                    <ListItem
                                        button
                                        sx={{
                                            bgcolor: blue[50],
                                            borderRadius: 4,
                                            '& .MuiListItemIcon-root': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <ListItemIcon> <Icon color='secondary'>account_balance_wallet</Icon> </ListItemIcon>
                                        <ListItemText
                                            primary='Tabungan'
                                        />
                                    </ListItem>
                                </div>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='contained' color='success'>
                                        Isi saldo
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Setelah muncul popup, Ketik nama atau tempelkan kartu jika ada</li>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Terima pembayaran
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='text' color='secondary'>
                                        Simpan
                                    </SmallButton>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h3>Tarik tunai saldo {process.env.REACT_APP_STUDENT}</h3>
                            <p>Saldo kartu dapat ditarik tunai di admin ketika diperlukan, berikut ini langkahnya :</p>
                            <ul>
                                <li>Buka menu tabungan</li>
                                <div style={{ width: 200 }}>
                                    <ListItem
                                        button
                                        sx={{
                                            bgcolor: blue[50],
                                            borderRadius: 4,
                                            '& .MuiListItemIcon-root': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <ListItemIcon> <Icon color='secondary'>account_balance_wallet</Icon> </ListItemIcon>
                                        <ListItemText
                                            primary='Tabungan'
                                        />
                                    </ListItem>
                                </div>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='contained' color='error'>
                                        Tarik tunai
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Setelah muncul popup, tempelkan (tap) kartu {process.env.REACT_APP_STUDENT}, akan muncul nama dan saldo</li>
                                <li style={{ marginTop: 8 }}>ketik jumlah uang yang ingin ditarik</li>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Lakukan pembayaran
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='text' color='secondary'>
                                        Cairkan
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Berikan uang kepada {process.env.REACT_APP_STUDENT} sesuai jumlah yang diinput</li>
                            </ul>
                        </li>

                        <li>
                            <h3>Membuat Tagihan {process.env.REACT_APP_STUDENT}</h3>
                            <p>Tagihan adalah semua jenis pembayaran yang harus dibayarkan oleh orang tua kepada pihak sekolah. <strong>Jenis tagihan</strong> harus dibuat terlebih dahulu di menu Jenis Tagihan. berikut ini langkah pembuatan tagihan baru</p>
                            <ul>
                                <li>Buka menu tagihan</li>
                                <div style={{ width: 200 }}>
                                    <ListItem
                                        button
                                        sx={{
                                            bgcolor: blue[50],
                                            borderRadius: 4,
                                            '& .MuiListItemIcon-root': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <ListItemIcon> <Icon color='secondary'>shopping_basket</Icon> </ListItemIcon>
                                        <ListItemText
                                            primary='Tagihan'
                                        />
                                    </ListItem>
                                </div>
                                <li style={{ marginTop: 8 }}>Klik tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Baru
                                    </SmallButton>
                                </li>
                                <li style={{ marginTop: 8 }}>Tagihan dapat dibuat untuk satu sekolah / kelas / parallel / per-{process.env.REACT_APP_STUDENT}</li>
                                <li >Dapat dibuat tiap bulan atau langsung setahun</li>
                                <li >Jika langsung ditagihkan, maka akan langsung muncul di aplikasi orang tua</li>
                                <li >Sistem akan menagih tiap bulan pada tanggal yang ditentukan di menu pengaturan <strong>Umum</strong></li>
                                <li >Klik tombol
                                    <SmallButton variant='contained' color='primary'>
                                        Simpan
                                    </SmallButton>
                                </li>
                            </ul>
                        </li>

                    </ol>
                </ul>

            </Grid>
        </Grid>


    </>;
}


export default Documentation;

