import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import useAuth from 'hooks/useAuth';


const ApprovalDetail = (props) => {
  const { row = {}, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError, gotoPage, setDetailId } = useUI()
  const reset = () => {
    return {
      id: undefined,
      description: undefined,
      reason: undefined,
      path: undefined,
      isApprover : 0
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [openReason, setopenReason] = useState(false)


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const approve = async () => {
    if (detail) {
      let payload = {
        id: row.id,
        approval_by_id: detail.id,
        approval_status_value: 1
      }

      const response = await axios.post(endpoint.approval.root, payload)
      if (response && response.data) {
        showSuccess('save approval')
        getData()
        props.closeDialog()
      } else {
        showError('save approval')
      }
    } else {
      showError('anda tidak berwenang')
    }

  }

  const reject = async () => {

    if (detail) {
      let payload = {
        id: row.id,
        approval_by_id: detail.id,
        approval_status_value: 2,
        reason: state.reason
      }

      if (!state.reason) {
        setopenReason(true)
      } else {
        const response = await axios.post(endpoint.approval.root, payload)
        if (response && response.data) {
          showSuccess('save approval')
          getData()
          props.closeDialog()
        } else {
          showError('save approval')
        }
      }
    } else {
      showError('anda tidak berwenang')
    }
 
  }

  const gotoDetail = () => {
    setDetailId(state.data_id)
    gotoPage(state.path)

  }


  const getRowDetail = async () => {
    let params = {
      employee_id:detail.id
    }
    
    const response = await axios.get(endpoint.approval.detail.replace("id",row.id), {params:params})
    if(response){      
      setstate(response.data)
    }
    
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row]) 



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Permohonan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={openReason === true || state.approval_status_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Alasan Penolakan
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='reason'
                  fullWidth
                  value={state.reason}
                  onChange={(e) => handleChange('reason', e)}
                />
              </Grid>
            </Grid>
          </Conditional>




        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={gotoDetail} color="primary">
        Lihat data
      </Button>
      <Conditional condition={state.approval_status_value === 0 && state.isApprover === 1}>
        <Button variant="contained" onClick={reject} color="error">
          Tolak
        </Button>
        <Conditional condition={openReason === false}>
          <Button variant="contained" onClick={approve} color="success">
            Setujui
          </Button>
        </Conditional>

      </Conditional>

    </DialogActions>
  </>;
}

export default ApprovalDetail;

