import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper, studentListOptionMapper } from 'utils/mappers';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import Conditional from 'components/Conditional';
import { parentTypeOptions } from 'utils/options';


const SelectStudentForParent = (props) => {
  const { closeDialog, onSubmit } = props
  const classes = useStyles();
  const reset = () => {
    return {
      selectedNewStudent: null,
      parent_type: null,
      parent_type_value: undefined
    }
  }
  const [state, setstate] = useState(reset())
  const [nameKeyWord, setnameKeyWord] = useState('');

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'parent_type') {
      newState['parent_type_value'] = value.value
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };

  const submit = async () => {
    onSubmit({
      student: state.selectedNewStudent,
      parent_type_value : state.parent_type_value
    })
    setstate(reset())
  }


  return <>
    <DialogContent sx={{height:300}}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              { process.env.REACT_APP_STUDENT}
            </Grid>
            <Grid item xs={8}>
              <AsyncSelect
                cacheOptions
                placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                value={state.selectedNewStudent}
                loadOptions={loadOptions}
                onInputChange={handleInputChange}
                onChange={(e) => handleChange('selectedNewStudent', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Tipe orang tua
            </Grid>
            <Grid item xs={8}>
              <Select
                name='parent_type'
                placeholder='Pilih tipe orangtua'
                options={parentTypeOptions}
                value={state.parent_type}
                onChange={(e) => handleChange('parent_type', e)}
                styles={defaultSelectStyle}
              />

            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
  </>;
}

export default SelectStudentForParent;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0',
    minHeight: 200
  },
  dialogContent: {
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
