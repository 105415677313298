import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import StudentForm from './StudentForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Button, Chip, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Collapse from '@mui/material/Collapse';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StudentStatistic from './StudentStatistic.js';
import { getsexTypeLabel, getstudentStatusLabel, sexTypeOptions, studentStatusOptions } from 'utils/options.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import useAuth from 'hooks/useAuth.js';


const Student = (props) => {
  const { user, detail, students } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showSuccess, showError, isAllDataAccess } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [openStatistic, setopenStatistic] = useState(false)
  const [selectedStatistic, setselectedStatistic] = useState('')

  const defaultFilters = {
    keyword: '',
    isActive: null,
    sex_type: null,
    sex_type_value: undefined,
    school: null,
    school_id: undefined,
    kelas: null,
    kelas_id: undefined,
    jurusan: null,
    jurusan_id: undefined,
    parallel: null,
    parallel_id: undefined,
    student_status: null,
    student_status_value: undefined,
    card_status: null,
    card_status_value: undefined,
    parent_status: null,
    parent_status_value: undefined

  }
  const [filters, setFilters] = useState(defaultFilters)

  const [isActiveOptions, setisActiveOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [parentStatusOptions, setparentStatusOptions] = useState(
    [
      { label: 'Lengkap', value: 1 },
      { label: 'Yatim', value: 2 },
      { label: 'Piatu', value: 3 },
      { label: 'Yatim piatu', value: 4 }
    ]
  )
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }

    if (fieldName === 'parent_status') {
      filtersTemp['parent_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      filtersTemp['school_id'] = value ? value.value : undefined
      getkelasOptions(value ? value.jenjang_id : undefined)
      getjurusanOptions(value ? value.jenjang_id : undefined)
    }
    else if (fieldName === 'kelas') {
      filtersTemp['kelas_id'] = value ? value.value : undefined
      getparallelOptions(value ? value.value : undefined)
    }
    else if (fieldName === 'jurusan') {
      filtersTemp['jurusan_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'parallel') {
      filtersTemp['parallel_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'student_status') {
      filtersTemp['student_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'card_status') {
      filtersTemp['card_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'sex_type') {
      filtersTemp['sex_type_value'] = value ? value.value : undefined
    }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }

    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async (kelas_id, jurusan_id) => {
    const params = {
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };


  const getData = async (newPage, newRowsPerPage, format) => {
    let params = {
      keyword: filters.keyword,
      school_id: filters.school_id,
      kelas_id: filters.kelas_id,
      jurusan_id: filters.jurusan_id,
      parallel_id: filters.parallel_id,
      student_status_value: filters.student_status_value,
      card_status_value: filters.card_status_value,
      sex_type_value: filters.sex_type_value,
      isActive: filters.isActive ? filters.isActive.value : undefined,
      parent_status_value: filters.parent_status_value,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        params = {
          ...params,
          employee_id: detail.id,
        }
      }
      else if (user.user_type_value === 2 && detail) {
        params = {
          ...params,
          student_id: detail.id,
        }
      }
      else if (user.user_type_value === 3 && students.length > 0) {
        params = {
          ...params,
          student_ids: students.map(student => (student.id))
        }
      }
      else {
        return
      }
    }

    if (format) {
      if (format === 'excel') {
        printExcel(params)
      }
    } else {
      getDataByParams(params)
    }

  }

  const printExcel = (params) => {
    axios.get(endpoint.student.excel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "students.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter
    }

    startLoading()
    const response = await axios.get(endpoint.student.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get student')
    }
    stopLoading()
  }



  const getDataByStatistic = async (statistic) => {
    setopenDialog(false)
    if (selectedStatistic === statistic.code) {
      reset()
    } else {
      setselectedStatistic(statistic.code)
      let params = {
        isActive: statistic.isActive,
        school_id: statistic.school_id,
        yatim: statistic.yatim,
        page: page,
        rowsPerPage: rowsPerPage
      }

      let isActiveIdx = isActiveOptions.findIndex(status => status.value === statistic.isActive)
      let isActive = null

      if (isActiveIdx > -1) {
        isActive = isActiveOptions[isActiveIdx]
      }

      let schoolIdx = schoolOptions.findIndex(type => type.value === statistic.school_id)
      let school = null

      if (schoolIdx > -1) {
        school = schoolOptions[schoolIdx]
      }

      setFilters(
        {
          ...filters,
          isActive: isActive,
          school: school,
          school_id: statistic.school_id,
        }
      )
      setPage(1)

      getDataByParams(params)

    }

  }

  const showStatistic = () => {
    if (smallScreen) {
      setdialogContent(
        <Box minHeight={300}>
          <StudentStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Box>

      )
      setopenDialog(true)
    } else {
      setopenStatistic(!openStatistic)
    }
  }

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <StudentForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
        importUrl={endpoint.student.import}
        templateUrl={api.url + '/files/STUDENT_TEMPLATE.xlsx'}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Student"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.student.root, { data: params });
    if (response) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setselectedIds([])
        setselectedRow(null)
        showSuccess('delete student')
        getData();
      }

    } else {
      showError('delete student')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      isActive: defaultFilters.isActive ? defaultFilters.isActive.value : undefined,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setselectedStatistic('')
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getschoolOptions()
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
              <Button onClick={() => showDialog('IMPORT')} color='primary'>
                Import
              </Button>

            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }
          <Grid item>
            <IconButton
              onClick={showStatistic}
              aria-label='delete'
              size="large">
              <InsertChartIcon color='primary' />
            </IconButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/parent')} color='primary'>
              Orang tua
            </SmallButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Collapse in={openStatistic} timeout="auto" unmountOnExit sx={{ width: 'inherit' }}>
        <Grid container sx={{ minHeight: 64, mb: 1 }}>
          <StudentStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Grid>
      </Collapse>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='sex_type'
              placeholder={`Jenis kelamin`}
              options={sexTypeOptions}
              onChange={(e) => filterChange('sex_type', e)}
              styles={defaultSelectStyle}
              value={filters.sex_type}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='kelas'
              placeholder='Kelas'
              options={kelasOptions}
              onChange={(e) => filterChange('kelas', e)}
              styles={defaultSelectStyle}
              value={filters.kelas}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='jurusan'
              placeholder='Jurusan'
              options={jurusanOptions}
              onChange={(e) => filterChange('jurusan', e)}
              styles={defaultSelectStyle}
              value={filters.jurusan}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='parallel'
              placeholder='Paralel'
              options={parallelOptions}
              value={filters.parallel}
              onChange={(e) => filterChange('parallel', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='student_status'
              placeholder='Status'
              options={studentStatusOptions}
              onChange={(e) => filterChange('student_status', e)}
              styles={defaultSelectStyle}
              value={filters.student_status}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='isActive'
              placeholder='Is active'
              options={isActiveOptions}
              onChange={(e) => filterChange('isActive', e)}
              styles={defaultSelectStyle}
              value={filters.isActive}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='card_status_value'
              placeholder='Smart card'
              options={
                [
                  { label: 'terhubung', value: 1 },
                  { label: 'tidak terhubung', value: 0 }
                ]
              }
              onChange={(e) => filterChange('card_status', e)}
              styles={defaultSelectStyle}
              value={filters.card_status}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='parent_status_value'
              placeholder='Orang tua'
              options={parentStatusOptions}
              onChange={(e) => filterChange('parent_status', e)}
              styles={defaultSelectStyle}
              value={filters.parent_status}
            />
          </Grid>

        </Grid>
        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={() => getData(undefined, undefined, 'excel')}>
            Excel
          </SmallButton>
          <IconButton
            onClick={() => getData()}
            aria-label='delete'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>User</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Nama</TableCell>
                <TableCell sx={{ width: 180 }}>Jenis kelamin</TableCell>
                <TableCell sx={{ minWidth: 200 }}>School</TableCell>
                <TableCell>Kelas</TableCell>
                <TableCell sx={{ minWidth: 100 }}>Paralel</TableCell>
                <TableCell sx={{ minWidth: 100 }}>NIS</TableCell>
                <TableCell sx={{ minWidth: 100 }}>Tahun Masuk</TableCell>
                <TableCell sx={{ minWidth: 100 }}>Status</TableCell>
                <TableCell sx={{ minWidth: 100 }}>Tahun Lulus</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>{row.user && row.user.username}</TableCell>
                  <TableCell>
                    <ClickableText text={row.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>
                    <Chip label={getsexTypeLabel(row.sex_type_value)} color="default" />
                  </TableCell>
                  <TableCell>{row.school && row.school.name}</TableCell>
                  <TableCell>{row.kelas && row.kelas.name}</TableCell>
                  <TableCell>{row.parallel && row.parallel.name}</TableCell>
                  <TableCell>{row.nis}</TableCell>
                  <TableCell>{row.masuk_tahun && row.masuk_tahun.name}</TableCell>
                  <TableCell>{getstudentStatusLabel(row.student_status_value)}</TableCell>
                  <TableCell>{row.tahunLulus}</TableCell>
                  <TableCell><ActiveChip status={row.isActive} /></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default Student;

