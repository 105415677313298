import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { dayOptions, getdayLabel, getdayObject, getscheduleTypeLabel, getscheduleTypeObject, getuserTypeLabel, getuserTypeObject, scheduleTypeOptions, userTypeOptions } from 'utils/options';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import { TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format, parse } from 'date-fns'
import { Box, Checkbox, Switch, TextField } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { FilterSharp } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';


const ScheduleDetail = (props) => {
  const { row = {}, cell, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo, isAllDataAccess } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      schedule_type: null,
      schedule_type_value: undefined,
      user_type: null,
      user_type_value: undefined,
      employee: null,
      employee_id: undefined,
      position: null,
      position_id: undefined,
      jenjang: null,
      jenjang_id: undefined,
      school: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      jurusan: null,
      jurusan_id: undefined,
      parallel: null,
      parallel_id: undefined,
      subject: null,
      subject_id: undefined,
      day: null,
      day_value: undefined,
      jam_ke: undefined,
      isStart: 0,
      isFinish: 0,
      startTime: undefined,
      finishTime: undefined,
      isIgnoringAccuracy : 0
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [positionOptions, setpositionOptions] = useState([]);
  const [subjectOptions, setsubjectOptions] = useState([]);
  const [employeeOptions, setemployeeOptions] = useState([]);
  const [nameKeyWord, setnameKeyWord] = useState('');

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isStart", "isFinish", 'isIgnoringAccuracy'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    } 
    else if (fieldName === 'school') {
      let school_id = value ? value.value : undefined
      let jenjang_id = value ? value.jenjang_id : undefined
      newState['school_id'] = school_id
      newState['jenjang_id'] = jenjang_id
      getkelasOptions(jenjang_id)
      getjurusanOptions(jenjang_id)
      getsubjectOptions(jenjang_id, undefined)

    }
    else if (fieldName === 'kelas') {
      if (value) {
        newState['kelas_id'] = value.value
        getparallelOptions(value.value, undefined)
        getsubjectOptions(value.jenjang_id, value.value)
      } else {
        newState['kelas_id'] = undefined
        getparallelOptions(undefined, undefined)
        getsubjectOptions(undefined, undefined)
      }

    }
    else if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value ? value.value : undefined
      getparallelOptions(undefined, value ? value.value : undefined)
    }
    else if (fieldName === 'parallel') {
      newState['parallel_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'schedule_type') {
      let schedule_type_value = value ? value.value : undefined
      newState['schedule_type_value'] = schedule_type_value
      if (schedule_type_value === 1) {
        newState['isStart'] = 1;
        newState['isFinish'] = 1;
      }
      else if (schedule_type_value === 2) {
        newState['isStart'] = 0;
        newState['isFinish'] = 0;
      }
      else if (schedule_type_value === 3) {
        newState['isStart'] = 1;
        newState['isFinish'] = 1;
      }
    }
    else if (fieldName === 'user_type') {
      newState['user_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'position') {
      newState['position_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'subject') {
      newState['subject_id'] = value ? value.value : undefined
      if (value) {
        getEmployeeOptions(value.value)
      }
    }
    else if (fieldName === 'startTimeObject') {
      newState['startTime'] = value ? format(value, 'HH:mm:00') : undefined
    }
    else if (fieldName === 'finishTimeObject') {
      newState['finishTime'] = value ? format(value, 'HH:mm:00') : undefined
    }
    else if (fieldName === 'day') {
      newState['day_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'employee') {
      newState['employee_id'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getsubjectOptions = async (jenjang_id, kelas_id) => {
    const params = {
      jenjang_id: jenjang_id,
      kelas_id: kelas_id
    }
    const response = await axios.get(endpoint.subject.option, { params: params });
    if (response && response.data) {
      setsubjectOptions(generalListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    let params = {}
    
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async (kelas_id, jurusan_id) => {
    let params = {
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const getpositionOptions = async () => {
    const response = await axios.get(endpoint.position.option);
    if (response && response.data) {
      setpositionOptions(generalListOptionMapper(response.data));
    }
  };

  const getEmployeeOptions = async (subject_id) => {
    const params = {
      subject_id: subject_id
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response && response.data) {
      setemployeeOptions(generalListOptionMapper(response.data))
    }
  }



  const insert = async (payload) => {
    const response = await axios.post(endpoint.schedule.root, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('save schedule')
        getData()
        props.closeDialog()
      }
    } else {
      showError('save schedule')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.schedule.root, payload)
    if (response && response.data) {
      showSuccess('save schedule')
      getData()
      props.closeDialog()
    } else {
      showError('save schedule')
    }
  }

  const handleDelete = async () => {
    props.handleDelete([cell.id])
    getData()
    props.closeDialog()
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if(!state.schedule_type_value){
      showInfo('Tipe jadwal belum dipilih')
      return;
    }
    
    if(state.schedule_type_value===1){
      if(!state.user_type_value){
        showInfo('Tipe pengguna belum dipilih')
        return;
      }
    }
    else if(state.schedule_type_value===2){
      
      if(!state.parallel_id){
        showInfo('Paralel belum dipilih')
        return;
      }
      if(!state.subject_id){
        showInfo('Mata pelajaran belum dipilih')
        return;
      }
      if(!state.employee_id){
        showInfo('Guru belum dipilih')
        return;
      }
      if(!state.day_value){
        showInfo('Hari belum dipilih')
        return;
      }
      
      
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}

    setstate({
      ...row,
      jenjang: generalOptionMapper(row.jenjang),
      school: generalOptionMapper(row.school),
      kelas: generalOptionMapper(row.kelas),
      jurusan: generalOptionMapper(row.jurusan),
      parallel: generalOptionMapper(row.parallel),
      subject: generalOptionMapper(row.subject),
      schedule_type: getscheduleTypeObject(row.schedule_type_value),
      user_type: getuserTypeObject(row.user_type_value),
      employee: generalOptionMapper(row.employee),
      position: generalOptionMapper(row.position),
      day: getdayObject(row.day_value),
      startTimeObject: parse(row.startTime, 'HH:mm:ss', new Date()),
      finishTimeObject: parse(row.finishTime, 'HH:mm:ss', new Date())
    })
  }

  const getCellDetail = async () => {

    setstate({
      ...cell,
      jenjang: generalOptionMapper(cell.jenjang),
      school: generalOptionMapper(cell.school),
      kelas: generalOptionMapper(cell.kelas),
      jurusan: generalOptionMapper(cell.jurusan),
      parallel: generalOptionMapper(cell.parallel),
      subject: generalOptionMapper(cell.subject),
      schedule_type: getscheduleTypeObject(cell.schedule_type_value),
      user_type: getuserTypeObject(cell.user_type_value),
      employee: generalOptionMapper(cell.employee),
      position: generalOptionMapper(cell.position),
      day: getdayObject(cell.day_value),
      startTimeObject: parse(cell.startTime, 'HH:mm:ss', new Date()),
      finishTimeObject: parse(cell.finishTime, 'HH:mm:ss', new Date())
    })

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (!cell) {
      if (row && pageAction !== 'CREATE') {
        getRowDetail()
      }
      else {
        setstate(reset())
      }
    }

  }, [row])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getschoolOptions()
      getparallelOptions()
      getsubjectOptions()
      getpositionOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (cell) {
      getCellDetail()
    }
  }, [cell])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Conditional condition={pageAction === 'READ'}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  ID
                </Grid>
                <Grid item sm={8} xs={12}>
                  <ReadOnlyTextField value={state.id} />
                </Grid>
              </Grid>
            </Conditional>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tipe jadwal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getscheduleTypeLabel(state.schedule_type_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="schedule_type"
                    placeholder="Pilih tipe jadwal"
                    options={scheduleTypeOptions}
                    value={state.schedule_type}
                    onChange={(e) => handleChange("schedule_type", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Conditional condition={state.schedule_type_value === 1}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Tipe user
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={getuserTypeLabel(state.user_type_value)} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <Select
                      isClearable={true}
                      name="Type"
                      placeholder="Pilih type"
                      options={userTypeOptions}
                      value={state.user_type}
                      onChange={(e) => handleChange("user_type", e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>

              <Conditional condition={state.user_type_value === 1}>
                <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                  <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                    Posisi
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Conditional condition={pageAction === 'READ'}>
                      <ReadOnlyTextField value={state.position && state.position.name} />
                    </Conditional>
                    <Conditional condition={pageAction !== 'READ'}>
                      <Select
                        isClearable={true}
                        name='position'
                        placeholder='Pilih posisi'
                        options={positionOptions}
                        value={state.position}
                        onChange={(e) => handleChange('position', e)}
                        styles={defaultSelectStyle}
                      />
                    </Conditional>
                  </Grid>
                </Grid>

              </Conditional>

              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Sekolah
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.school && state.school.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='school'
                      placeholder='Pilih sekolah'
                      options={schoolOptions}
                      value={state.school}
                      onChange={(e) => handleChange('school', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>

              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Kelas
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={state.kelas && state.kelas.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <Select
                      isClearable={true}
                      name="kelas"
                      placeholder="Pilih Kelas"
                      options={kelasOptions}
                      value={state.kelas}
                      onChange={(e) => handleChange("kelas", e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>

            </Conditional>


            <Conditional condition={state.schedule_type_value === 2}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Jam ke
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.jam_ke} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DebouncedTextField
                      margin='dense'
                      id='jam_ke'
                      fullWidth
                      type="number"
                      value={state.jam_ke}
                      onChange={(e) => handleChange('jam_ke', e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>

              <Conditional condition={pageAction === 'READ'}>
                <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                  <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                    Sekolah
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Conditional condition={pageAction === 'READ'}>
                      <ReadOnlyTextField value={state.school && state.school.name} />
                    </Conditional>
                    <Conditional condition={pageAction !== 'READ'}>
                      <Select
                        isClearable={true}
                        name='school'
                        placeholder='Pilih sekolah'
                        options={schoolOptions}
                        value={state.school}
                        onChange={(e) => handleChange('school', e)}
                        styles={defaultSelectStyle}
                      />
                    </Conditional>
                  </Grid>
                </Grid>

                <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                  <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                    Kelas
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Conditional condition={pageAction === "READ"}>
                      <ReadOnlyTextField value={state.kelas && state.kelas.name} />
                    </Conditional>
                    <Conditional condition={pageAction !== "READ"}>
                      <Select
                        isClearable={true}
                        name="kelas"
                        placeholder="Pilih Kelas"
                        options={kelasOptions}
                        value={state.kelas}
                        onChange={(e) => handleChange("kelas", e)}
                        styles={defaultSelectStyle}
                      />
                    </Conditional>
                  </Grid>
                </Grid>

                <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                  <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                    Jurusan
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Conditional condition={pageAction === 'READ'}>
                      <ReadOnlyTextField value={state.jurusan && state.jurusan.name} />
                    </Conditional>
                    <Conditional condition={pageAction !== 'READ'}>
                      <Select
                        isClearable={true}
                        name='jurusan'
                        placeholder='Pilih jurusan'
                        options={jurusanOptions}
                        value={state.jurusan}
                        onChange={(e) => handleChange('jurusan', e)}
                        styles={defaultSelectStyle}
                      />
                    </Conditional>
                  </Grid>
                </Grid>
              </Conditional>


              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Parallel
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.parallel && state.parallel.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='parallel'
                      placeholder='Pilih Parallel'
                      options={parallelOptions}
                      value={state.parallel}
                      onChange={(e) => handleChange('parallel', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>

              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Mata pelajaran
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.subject && state.subject.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='subject'
                      placeholder='Pilih pelajaran'
                      options={subjectOptions}
                      value={state.subject}
                      onChange={(e) => handleChange('subject', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>


              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Guru
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={state.instructor && state.instructor.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <Select
                      isClearable={true}
                      placeholder="Pilih guru..."
                      name='employee'
                      value={state.employee}
                      options={employeeOptions}
                      onChange={(e) => handleChange('employee', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>

            </Conditional>

            <Conditional condition={state.schedule_type_value === 3}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Tipe user
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={getuserTypeLabel(state.user_type_value)} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <Select
                      isClearable={true}
                      name="Type"
                      placeholder="Pilih type"
                      options={userTypeOptions}
                      value={state.user_type}
                      onChange={(e) => handleChange("user_type", e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Nama jadwal / kegiatan
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DebouncedTextField
                      margin='dense'
                      id='name'
                      fullWidth
                      value={state.name}
                      onChange={(e) => handleChange('name', e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Hari
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getdayLabel(state.day_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    isClearable={true}
                    name="day"
                    placeholder="Pilih hari"
                    options={dayOptions}
                    value={state.day}
                    onChange={(e) => handleChange("day", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Conditional condition={state.schedule_type_value === 1 || state.schedule_type_value === 3}>
              <Grid container spacing={2} justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={6} container justifyContent='flex-start' alignContent='center'>
                  Jam Mulai
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Conditional condition={pageAction === 'READ'}>
                    <Box width={100}>
                      <ReadOnlyTextField value={state.startTime} />
                    </Box>
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>

                    <Grid container alignItems='flex-start'>
                      <TimePicker
                        autoOk
                        ampm={false}
                        variant="inline"
                        inputVariant="outlined"
                        renderInput={(props) => <TextField {...props} />}
                        value={state.startTimeObject}
                        onChange={(e) => handleChange('startTimeObject', e)}
                      />
                    </Grid>
                  </Conditional>
                </Grid>

                <Grid item sm={4} xs={6} container justifyContent='flex-start' alignContent='center'>
                  Jam Selesai
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Conditional condition={pageAction === 'READ'}>
                    <Box width={100}>
                      <ReadOnlyTextField value={state.finishTime} />
                    </Box>
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>

                    <Grid container alignItems='flex-start'>
                      <TimePicker
                        autoOk
                        ampm={false}
                        variant="inline"
                        inputVariant="outlined"
                        renderInput={(props) => <TextField {...props} />}
                        value={state.finishTimeObject}
                        onChange={(e) => handleChange('finishTimeObject', e)}
                      />
                    </Grid>
                  </Conditional>
                </Grid>

              </Grid>
            </Conditional>

            <Conditional condition={state.schedule_type_value === 2}>
              <Conditional condition={pageAction !== 'CREATE'}>
                <Grid container spacing={2} justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                  <Grid item sm={4} xs={6} container justifyContent='flex-start' alignContent='center'>
                    Jam Mulai
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <Box width={100}>
                      <ReadOnlyTextField value={state.startTime} />
                    </Box>
                  </Grid>

                  <Grid item sm={4} xs={6} container justifyContent='flex-start' alignContent='center'>
                    Jam Selesai
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <Box width={100}>
                      <ReadOnlyTextField value={state.finishTime} />
                    </Box>
                  </Grid>

                </Grid>
              </Conditional>
            </Conditional>




            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
                Sesi mulai
              </Grid>
              <Grid item sm={8} xs={2}>
                <Conditional condition={pageAction === 'READ'}>
                  <Checkbox
                    checked={state.isStart === 1}
                  />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Checkbox
                    checked={state.isStart === 1}
                    onChange={(e) => handleChange("isStart", e)}
                  />
                </Conditional>
              </Grid>

              <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
                Sesi selesai
              </Grid>
              <Grid item sm={8} xs={2}>
                <Conditional condition={pageAction === 'READ'}>
                  <Checkbox
                    checked={state.isFinish === 1}
                  />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Checkbox
                    checked={state.isFinish === 1}
                    onChange={(e) => handleChange("isFinish", e)}
                  />
                </Conditional>
              </Grid>

            </Grid>


          </LocalizationProvider>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Abaikan ketepatan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                <Switch
                    color='primary'
                    checked={state.isIgnoringAccuracy === 1}
                  />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Switch
                    color='primary'
                    checked={state.isIgnoringAccuracy === 1}
                    onChange={(e) => handleChange("isIgnoringAccuracy", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== 'CREATE' && cell !== undefined}>
        <Button onClick={handleDelete} color="error">
          Hapus
        </Button>
      </Conditional>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default ScheduleDetail;

