import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use'; 
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilterList from 'components/FilterList';
import FilterComponent from 'components/FilterComponent.js';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Popover from '@mui/material/Popover';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import UserForm from 'views/user/UserForm';
import SelectUserForm from 'views/user/SelectUserForm';
import { DialogActions } from '@mui/material';
import ClickableText from 'components/ClickableText';
import SmallButton from 'components/SmallButton';


const RoleUser = (props) => {
  const { row = {} } = props
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch(); 
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({ keyword: '' })
  const [filtersAvailable, setfiltersAvailable] = useState([
    { name: 'status', display: 'status', type: 'select' },
    { name: 'jenjang', display: 'jenjang', type: 'select' }
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)



  const getFilterOptions = (field) => {
    if (field.name === 'status') {
      return statusOptions
    }
  }

  const popupFilterShow = (event) => {
    if (filtersAvailable.length > 0) {
      setanchorFilter(event.currentTarget);
    }
  };

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter = (field) => {
    let filtersTemp = { ...filters }
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f => f.name !== field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy, field])
  }

  const removeFilter = (field) => {
    let filtersTemp = { ...filters }
    delete filtersTemp[field.name]
    setFilters(filtersTemp)
    let newFilterBy = filterBy.filter(f => f.name !== field.name)
    setfilterBy(newFilterBy)
    setfiltersAvailable([...filtersAvailable, field])
  }

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  }


  const getUsersOfRole = async (newPage, newRowsPerPage) => {
    let params = {
      role_id: row.id,
      keyword: filters.keyword === '' ? undefined : filters.keyword,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }

    const response = await axios.get(endpoint.role.user, { params: params })

    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }
  }

  const getDataByFilter = () => {
    getUsersOfRole()
  }


  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getUsersOfRole(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getUsersOfRole(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = selectedRow
    }

    if (actionCode === 'ADD_USER') {
      setMaxWidth('sm');
      setdialogContent(
        <SelectUserForm
          onSubmit={addUser}
          closeDialog={closeDialog}
        />
      );
    } else {
      setMaxWidth('md');
      setdialogContent(
        <UserForm
          row={row}
          getData={getUsersOfRole}
          pageAction={actionCode}
          closeDialog={closeDialog}
        />
      );
    }


    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDeleteUser}
        selectedIds={selectedIds}
        title="User"
      />
    );

    setopenDialog(true);
  };


  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }


  const handleDeleteUser = async () => {
    const params = {
      role_id: row.id,
      user_ids: selectedIds
    };
    const response = await axios.delete(endpoint.role.user, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete user')
      getUsersOfRole();
    } else {
      showError('delete user')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }


  const addUser = async (user) => {
    const params = {
      role_id: row.id,
      user_id: user.id
    }
    const response = await axios.put(endpoint.role.user, params)
    if (response && response.data) {
      showSuccess('save user')
      getUsersOfRole()
    } else {
      showError('save user')
    }

  }


  useEffect(() => {
    getUsersOfRole()
  }, [])

  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getUsersOfRole()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' 
    sx={{
      p:1
    }}>
      <Grid container justifyContent="flex-end" alignItems="center" 
      sx={
        {
          mt:1,
          mb:1
        }
      }>
        {
          selectedIds.length === 1 &&
          <Protected allowedCodes={['EDIT']} > 
            <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
              <ActionDisplay code='EDIT' />
            </SmallButton> 
          </Protected>

        }
        {
          selectedIds.length > 0 &&
          <Protected allowedCodes={['DELETE']} > 
            <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary'>
              <ActionDisplay code='DELETE' />
            </SmallButton>
          </Protected>
        }


        <Protected allowedCodes={['ADD_USER']} > 
          <SmallButton onClick={() => showDialog('ADD_USER')} variant='contained' color='primary'>
            <ActionDisplay code='ADD_USER' />
          </SmallButton>
        </Protected>
        <Protected allowedCodes={['CREATE_USER']} > 
          <SmallButton onClick={() => showDialog('CREATE_USER')} variant='contained' color='primary'>
            <ActionDisplay code='CREATE_USER' />
          </SmallButton>
        </Protected>
      </Grid>
      <Grid container sx={{minHeight: 400}}>
        <TableContainer component={Paper}>
          <Table  size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell >Nama</TableCell>
                <TableCell >Username</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    <ClickableText text={row.detail && row.detail.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell >{row.username}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end"  >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>



    </Grid>

    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Popover
      id='popUpFilter'
      open={Boolean(anchorFilter)}
      anchorEl={anchorFilter}
      onClose={popupFilterClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} 
      sx={
        { marginTop: 8 }
      }
    >
      <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose} />
    </Popover>


  </>;
}


export default RoleUser;
 