import React,{useEffect, useState} from 'react' 
import Chip from '@mui/material/Chip';

const QueueChip = ({status, margin}) => {

  const [label, setLabel] = React.useState('Menunggu');
  const [backgroundColor, setbackgroundColor] = React.useState('default');
  const [textColor, settextColor] = React.useState('black');

  useEffect(() => {
    if(status===0){
      setLabel('Menunggu')
      setbackgroundColor('default')
      settextColor('black')
    }
    else if(status===1){
      setLabel('Diproses')
      setbackgroundColor('info')
      settextColor('white')
    }
    else if(status==2){
      setLabel('Selesai')
      setbackgroundColor('success')
      settextColor('white')
    }
  }, [status])
  
    return (
        <Chip 
        size="small" 
        label={ label } 
        color={backgroundColor}
        sx={{
          color:textColor,
          margin:margin
        }}
        />
    );
}

export default QueueChip;
