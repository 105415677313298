import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import SettingProvider from 'utils/SettingProvider';
import LoadingScreen from 'components/Loading';
import Home from 'views/home/Home';
import Login from 'views/login/Login';
import Register from 'views/register/Register';
import Dashboard from 'views/dashboard/Dashboard';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Echo from "laravel-echo"
import Pusher from 'pusher-js';
import Activation from 'views/activation/Activation';
import { api } from 'utils/axios';
import Reset from 'views/login/Reset';
 

export const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: '1111111111',
  wsHost: api.wshost,
  wsPort: process.env.REACT_APP_BACKEND_MODE === 'PROD' ? 80:6001,
  wssPort: process.env.REACT_APP_BACKEND_MODE === 'PROD' ? 443:6001,
  forceTLS: process.env.REACT_APP_BACKEND_MODE === 'PROD',
  disableStats: true,
  scheme: process.env.REACT_APP_BACKEND_MODE === 'PROD' ? 'https':'http'
});



const history = createBrowserHistory();
const theme = createTheme();
export default function App() {
  return (

    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <Router history={history}>
            <SettingProvider>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/home" component={Home} />
                  <Route path="/login" component={Login} />
                  <Route path="/reset" component={Reset} />
                  <Route path="/register" component={Register} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/activation" component={Activation} />
                </Switch>
            </SettingProvider>
          </Router>
        </PersistGate>
      </ReduxProvider>
    </ThemeProvider>

  );
}
