import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Alert, AlertTitle, Button, Input } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import { parameterListOptionMapper } from 'utils/mappers';
import { Verified } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PsbFlowInstruction = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showError } = useUI()
  const { user, detail } = useAuth()
  const [psbFlow, setpsbFlow] = useState(null)
  const query = useQuery();
  const [studentPsbFlows, setstudentPsbFlows] = useState([])
  const [selectedPsbFlow, setselectedPsbFlow] = useState(null)
  const [lastPsbFlow, setlastPsbFlow] = useState(null)
  const [isDone, setisDone] = useState(false)
  const [status, setstatus] = useState(0)


  const getPsbFlowByCode = async () => {
    let params = {}
    const response = await axios.get(endpoint.psbFlow.id.replace('id', query.get('id')), { params: params })

    if (response) {
      setpsbFlow(response.data)
    } else {
      showError('get psb flow')
    }
  }

  const getStudentPsbFlows = async () => {
    let params = {
      new_student_id: query.get('new_student_id')
    }
    const response = await axios.get(endpoint.newStudent.psbFlow, { params: params })

    if (response && response.data) {
      if (response.data[0]) {
        setlastPsbFlow(response.data[response.data.length - 1])
      }
      setstudentPsbFlows(response.data.map(flow => flow.id))
      const selectedFlow = response.data.filter(flow => flow.code === query.get('code'))[0]
      if (selectedFlow) {
        setisDone(true)
        setselectedPsbFlow(selectedFlow)
        if (selectedFlow.pivot) {
          setstatus(selectedFlow.pivot.status_value)
        } 
      }else{
        setisDone(false)
        setstatus(false)
      }
    } else {
      showError('get student psb flow')
    }
  }

  useEffect(() => {
    getPsbFlowByCode()
    getStudentPsbFlows()
  }, [])

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>


        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>


      </Grid>

      <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400 }} >

        {
          psbFlow &&
          <Alert severity="info"
            sx={{
              borderRadius: 4,
              width: '100%'
            }}>
            <AlertTitle>{psbFlow.name}</AlertTitle>
            {psbFlow.instruction}
          </Alert>
        }

        {
          isDone && status === 1 &&
          <Grid item xs={12} container sx={{ mt: 2 }}>
            <Grid item xs={12} container justifyContent='center' sx={{ mb: 2 }}>
              <Box display='flex' justifyContent='center' alignItems='center' sx={{ p: 2, height: 50, borderRadius: 4, border: 'solid 1px #6a63ff' }}>
                <Typography color='primary' component='h6' variant='h6'>
                  Selamat kamu berhasil {selectedPsbFlow && selectedPsbFlow.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_welcoming_re_x0qo.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
          </Grid>
        }

        {
          isDone && status === 0 &&
          <Grid item xs={12} container sx={{ mt: 2 }}>
            <Grid item xs={12} container justifyContent='center' sx={{ mb: 2 }}>
              <Box display='flex' justifyContent='center' alignItems='center' sx={{ p: 2, height: 50, borderRadius: 4, border: 'solid 1px #6a63ff' }}>
                <Typography color='primary' component='h6' variant='h6'>
                  Tetap semangat, kamu belum berhasil {selectedPsbFlow && selectedPsbFlow.name} kali ini
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_stepping_up_g6oo.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
          </Grid>
        }


      </Grid>
    </Grid>

  </>;
}


export default PsbFlowInstruction;

