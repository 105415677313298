import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';
import DebouncedTextField from 'components/DebouncedTextField';
import useSetting from 'hooks/useSetting';
import { discountTypeOptions, specialPriceOptions } from 'utils/options';
import Conditional from 'components/Conditional';


const SelectBillTypeForm = (props) => {
  const { closeDialog, onSubmit, student } = props
  const { tahunPelajaran } = useSetting()
  const classes = useStyles();
  const reset = () => {
    return {
      bill_type: null,
      bill_type_id: undefined,
      special_type: null,
      special_type_value: undefined,
      discount_type: null,
      discount_type_value: undefined,
      amount: undefined,
      tahun: null,
      tahun_id: undefined
    }
  }
  const [state, setstate] = useState(reset())
  const [bill_typeOptions, setbill_typeOptions] = useState([]);
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue
    if (fieldName === 'tahun') {
      newState['tahun_id'] = value.value
      getbill_typeOptions(value.value)
    }
    else if (fieldName === 'bill_type') {
      newState['bill_type_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'special_type') {
      newState['special_type_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'discount_type') {
      newState['discount_type_value'] = eventValue ? eventValue.value : eventValue
    }
    newState[fieldName] = value
    setstate(newState)
  }

  const getbill_typeOptions = async (tahun_id) => {
    let params = {
      school_id: student ? student.school_id : undefined,
      tahun_id: tahun_id
    }
    const response = await axios.get(endpoint.billType.option, { params: params });
    if (response && response.data) {
      setbill_typeOptions(generalListOptionMapper(response.data));
    }
  };

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const submit = async () => {
    onSubmit(state)
    setstate(reset())
  }

  useEffect(() => {
    gettahunPelajaranOptions()
  }, [])


  useEffect(() => {
    if (tahunPelajaran) {
      handleChange('tahun', generalOptionMapper(tahunPelajaran))
    }
  }, [tahunPelajaran])


  return <>
    <DialogContent sx={{ height: 300 }}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Tahun Pelajaran
            </Grid>
            <Grid item xs={8}>
              <Select
                name="tahun"
                placeholder="Pilih Tahun Pelajaran"
                options={tahunPelajaranOptions}
                value={state.tahun}
                onChange={(e) => handleChange("tahun", e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Jenis Tagihan
            </Grid>
            <Grid item xs={8}>
              <Select
                name='bill_type'
                placeholder='Pilih jenis tagihan'
                options={bill_typeOptions}
                value={state.bill_type}
                onChange={(e) => handleChange('bill_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Jenis potongan
            </Grid>
            <Grid item xs={8}>
              <Select
                name='special_type'
                placeholder='Pilih jenis potongan'
                options={specialPriceOptions}
                value={state.special_type}
                onChange={(e) => handleChange('special_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Conditional condition={state.special_type_value === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Tipe diskon
              </Grid>
              <Grid item xs={8}>
                <Select
                  name='discount_type'
                  placeholder='Pilih jenis diskon'
                  options={discountTypeOptions}
                  value={state.discount_type}
                  onChange={(e) => handleChange('discount_type', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Nominal
            </Grid>
            <Grid item xs={8}>
              <DebouncedTextField
                margin='dense'
                id='amount'
                fullWidth
                type="number"
                value={state.amount}
                onChange={(e) => handleChange('amount', e)}
              />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
  </>;
}

export default SelectBillTypeForm;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0',
    minHeight: 200
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
