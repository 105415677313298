import { useDispatch, useSelector } from 'react-redux';
// redux
import { login, register, logout } from 'redux/slices/auth';

// ----------------------------------------------------------------------

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, roles, menus, actions, detail, isAuthenticated, students } = useSelector(
    (state) => state.auth
  );

  
  const hasRole = (roleCode) => { 
    const filtered = roles.filter(role=>(role.code===roleCode))
    return filtered.length > 0
  }

  return {
    method: 'jwt',
    user,   
    menus,
    actions,
    detail,
    students,
    roles, 
    isAuthenticated,
    login: ({ username, password }) =>
      dispatch(
        login({
          username,
          password
        })
      ),

    register: ({ username, password, firstName, lastName }) =>
      dispatch(
        register({
          username,
          password,
          firstName,
          lastName
        })
      ),

    logout: () => dispatch(logout()),

    resetPassword: () => {},

    updateProfile: () => {},

    hasRole
  };
}
