import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Cancel from '@mui/icons-material/Cancel';
import Send from '@mui/icons-material/Send';
import FilterList from 'components/FilterList';
import FilterComponent from 'components/FilterComponent.js';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Popover from '@mui/material/Popover';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional';
import { Check, Edit } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import SettingInteger from './SettingInteger';
import SettingString from './SettingString';
import SettingDecimal from './SettingDecimal';
import SettingDate from './SettingDate';
import SettingDateTime from './SettingDateTime';
import SettingTime from './SettingTime';
import useAuth from 'hooks/useAuth';
import SettingBoolean from './SettingBoolean';
import SettingParameter from './SettingParameter';
import Link from 'components/Link';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator';
import { Box, Tab, Tabs } from '@mui/material';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

const SettingGeneral = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const { user = {} } = useAuth()
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch();
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({ keyword: '' })
  const [filtersAvailable, setfiltersAvailable] = useState([
    { name: 'status', display: 'status', type: 'select' },
    { name: 'jenjang', display: 'jenjang', type: 'select' }
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [selectedGroup, setselectedGroup] = useState('ATTENDANCE')


  const saveSetting = async (setting) => {

    const response = await axios.put(endpoint.setting.root, setting)
    if (response && response.data) {
      showSuccess('save setting')
      getData()
    } else {
      showError('save action')
    }
  }


  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter = (field) => {
    let filtersTemp = { ...filters }
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f => f.name !== field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy, field])
  }

  const removeFilter = (field) => {
    let filtersTemp = { ...filters }
    delete filtersTemp[field.name]
    setFilters(filtersTemp)
    let newFilterBy = filterBy.filter(f => f.name !== field.name)
    setfilterBy(newFilterBy)
    setfiltersAvailable([...filtersAvailable, field])
  }

  const handleChangeTab = (event, newValue) => {
    setselectedGroup(newValue);
  };


  const getData = async () => {
    let params = {
      group : selectedGroup
    }

    const response = await axios.get(endpoint.setting.general, { params: params })

    if (response && response.data) {
      const modifiedData = response.data.map(row => ({
        ...row,
      }))
      setDataTable(modifiedData)
    }
  }

  const getDataByFilter = () => {
    getData()
  }

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = selectedRow
    }
    setMaxWidth('md');
    setdialogContent(null);

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth('sm');
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title='SettingGeneral'
      />
    );

    setopenDialog(true);
  };


  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }


  const handleDelete = async () => {
    const params = {
      ids: selectedIds
    };
    const response = await axios.delete(endpoint.parameter.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete parameter')
      getData();
    } else {
      showError('delete parameter')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  useEffect(() => {
    getData()
  }, [selectedGroup])

  return <>
    <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start' 
     sx={{
      p: 2,
      bgcolor: 'white',
      borderRadius: 4
    }}
    >
      <Grid container 
      sx={{ my: 1 }} 
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
      </Grid>

      <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: 4,
          minHeight:400
        }}
      >
        <Tabs
          value={selectedGroup}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          <Tab
            disableRipple
            label='Absensi'
            value='ATTENDANCE'
          />
          <Tab
            disableRipple
            label='Home'
            value='HOME'
          />
          {
            process.env.REACT_APP_SCHOOL_MODE === 'PESANTREN' && 'Ringkasan proses PSB'
          }
          {
            process.env.REACT_APP_SCHOOL_MODE === 'SEKOLAH' && 'Ringkasan proses PPDB'
          }
          <Tab
            disableRipple
            label={process.env.REACT_APP_SCHOOL_MODE === 'PESANTREN'?'PSB':'PPDB'}
            value='PPDB'
          />
          <Tab
            disableRipple
            label='KEUANGAN'
            value='KEUANGAN'
          />
          <Tab
            disableRipple
            label='Lainnya'
            value='GENERAL'
          />
        </Tabs>

        <Box sx={{ mb: 5 }} />

        <Grid container sx={{ mt: 2 }}>
          {
            dataTable.map(setting => (
              <Grid container justifyContent="center" item xs={12} sm={6} md={4} style={{ marginBottom: 8, minHeight: 100 }}>
                <Conditional condition={setting.type == 'boolean'}>
                  <SettingBoolean setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'integer'}>
                  <SettingInteger setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'string'}>
                  <SettingString setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'decimal'}>
                  <SettingDecimal setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'date'}>
                  <SettingDate setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'dateTime'}>
                  <SettingDateTime setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'time'}>
                  <SettingTime setting={setting} saveSetting={saveSetting} />
                </Conditional>
                <Conditional condition={setting.type == 'parameter'}>
                  <SettingParameter setting={setting} saveSetting={saveSetting} />
                </Conditional>
              </Grid>
            ))
          }
        </Grid>

      </Grid>
    </Grid>




    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    


  </>;
}


export default SettingGeneral;

