import { Grid, Box, Paper, Typography } from '@mui/material';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import useUI from 'hooks/useUI';
import React, { useEffect, useState } from 'react'

const VisitorStatistic = (props) => {

    const { gotoPage, showError } = useUI()


    const [visitorStatistics, setvisitorStatistics] = useState([
        { name: 'Total', code: 'TOTAL', value: 0, isActive: null, parent_id: null },
    ])


    const getvisitorStatistic = async () => {
        let params = {}
        const response = await axios.get(endpoint.visitor.statistic, { params: params })

        if (response && response.data) {
            let newStatistics = visitorStatistics.map(statistic => {
                let idx = response.data.findIndex(data => data.code === statistic.code)
                if (idx > -1) {
                    return { ...statistic, value: response.data[idx].value }
                }
                return statistic
            })
            setvisitorStatistics(newStatistics)
        } else {
            showError('get visitor')
        }
    }

    const gotoVisitorPage = () => {
        let route = '/dashboard/visitor'
        gotoPage(route)
    }

    useEffect(() => {
        getvisitorStatistic()
    }, [])

    return (
        <Grid container item xs={12}  
            sx={{
                px: 2
            }}
        >
            <Grid container justifyContent='flex-start' spacing={1}
                sx={{
                    color: '#393a3c',
                    pt: 1,
                    pb: 2,
                    px: 2,
                    mb: 2,
                    borderRadius: 4,
                    background: 'white'
                }}
            >
                <Grid item xs={12} container justifyContent='flex-start'>
                    <Typography variant="subtitle2" component="div">
                        Visitor
                    </Typography>
                </Grid>
                {
                    visitorStatistics.map(statistic => (
                        <Grid item onClick={gotoVisitorPage} sx={{ cursor: 'pointer' }}>
                            <Paper
                                sx={{
                                    minWidth: 100,
                                    height: 48,
                                    p: 1,
                                    backgroundColor:'#1e65e9',
                                    color:'white'
                                }}
                                elevation={4}>
                                <Typography variant="body2" gutterBottom component="div">
                                    {statistic.name}
                                </Typography>
                                <Typography variant="h6" gutterBottom component="div">
                                    {statistic.value}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))
                }

            </Grid>

        </Grid>
    );

}

export default VisitorStatistic;
