import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { generalListOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { Box, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from 'hooks/useAuth';


const ProductDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError, isAllDataAccess } = useUI()
  const { user, detail } = useAuth()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      barcode: undefined,
      store: null,
      store_id: undefined,
      category: null,
      category_id: undefined,
      description: undefined,
      image: undefined,
      isCountStock: 0,
      stock: 0,
      buyPrice: 0,
      sellPrice: 0,
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [storeOptions, setstoreOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([])

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isCountStock"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    } if (fieldName === 'store') {
      newState['store_id'] = eventValue ? eventValue.value : eventValue
    } if (fieldName === 'category') {
      newState['category_id'] = eventValue ? eventValue.value : eventValue
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getcategoryOptions = async () => {
    const params = {
      group: 'products'
    }
    const response = await axios.get(endpoint.category.option, { params: params });
    if (response && response.data) {
      setcategoryOptions(generalListOptionMapper(response.data));
    }
  };

  const getstoreOptions = async () => {
    let params = {}

    if (!isAllDataAccess() && detail) {
      params = {
        store_id: detail.store_id,
      }
    }

    const response = await axios.get(endpoint.store.option, { params: params });
    if (response && response.data) {
      setstoreOptions(generalListOptionMapper(response.data));
    }
  };

  const selectImage = async (event) => {
    let file = event.target.files[0]
    setstate({
      ...state,
      imageFile: file,
      image: URL.createObjectURL(file)
    })

    if (pageAction === 'EDIT') {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('id', row.id)
      const response = await axios.post(endpoint.product.image, formData, {});
      if (response && response.data) {
        showSuccess('save photo')
      } else {
        showError('save photo')
      }
    }

  };


  const insert = async (payload) => {
    const response = await axios.post(endpoint.product.root, payload)
    if (response && response.data) {
      showSuccess('save product')
      getData()
    } else {
      showError('save product')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.product.root, payload)
    if (response && response.data) {
      showSuccess('save product')
      getData()
    } else {
      showError('save product')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      user_id:user.id
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row, 
      image: `${api.url}/${row.image}`,
      store: row.store ? {
        ...row.store,
        value : row.store.id, 
        label : row.store.name
      } : null
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getcategoryOptions()
      getstoreOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Gambar
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box sx={{ width: 100, position: 'relative' }}>
                <img
                  src={state.image}
                  style={{ width: 100, height: 100, borderRadius: 16 }} />
                <Conditional condition={pageAction === 'EDIT'}>
                  <label htmlFor='propic'>
                    <input onChange={selectImage} accept="image/*" id='propic' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                  </label>
                </Conditional>

              </Box>
            </Grid>
          </Grid>


          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Usaha
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.store && state.store.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='store'
                  placeholder='Pilih unit usaha'
                  options={storeOptions}
                  value={state.store}
                  onChange={(e) => handleChange('store', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kategori
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.category && state.category.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='category'
                  placeholder='Pilih kategori'
                  options={categoryOptions}
                  value={state.category}
                  onChange={(e) => handleChange('category', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Barcode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.barcode} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='barcode'
                  fullWidth
                  value={state.barcode}
                  onChange={(e) => handleChange('barcode', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Deskripsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Hitung stok
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isCountStock === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isCountStock === 1}
                  onChange={(e) => handleChange("isCountStock", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Stok tersedia
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.stock} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='stock'
                  fullWidth
                  type="number"
                  value={state.stock}
                  onChange={(e) => handleChange('stock', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Harga beli
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.buyPrice} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='buyPrice'
                  fullWidth
                  type="number"
                  value={state.buyPrice}
                  onChange={(e) => handleChange('buyPrice', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Harga jual
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.sellPrice} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='sellPrice'
                  fullWidth
                  type="number"
                  value={state.sellPrice}
                  onChange={(e) => handleChange('sellPrice', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default ProductDetail;

