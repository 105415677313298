import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Send from '@mui/icons-material/Send';
import { gettransactionTypeLabel } from 'utils/options';
import { format, parse, parseISO } from 'date-fns';
import SmallButton from 'components/SmallButton';

const BalanceSummary = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return []

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const defaultFilters = {
    start_date: new DateObject,
    end_date: new DateObject,

  }
  const [filters, setFilters] = useState(defaultFilters)

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getMutations = async () => {
    let params = {
      balance_id: row.id,
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
    }

    const response = await axios.get(endpoint.balance.mutation, { params: params })
    if (response) {
      setstate(response.data)
    }
  }

  const exportSummaryExcel = () => {
    let params = {
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
    }
    axios.get(endpoint.balance.excel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "balance_mutation.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    getMutations()
  }, [])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container>
          <Grid item xs={6} md={4} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Mulai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.start_date}
                onChange={(e) => filterChange('start_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={4} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Sampai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.end_date}
                onChange={(e) => filterChange('end_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={6} md={4} item container justifyContent='flex-end' alignItems='flex-end'>
            <SmallButton variant='text' color='secondary' onClick={exportSummaryExcel}>
              Excel
            </SmallButton>
          </Grid>
        </Grid>

        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            width: 'inherit',
            minHeight: 400,
            mt: 1
          }}
        ></Grid>
        
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>
  </>;
}

export default BalanceSummary;

