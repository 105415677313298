import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import EmployeeDetail from './EmployeeDetail';
import useUI from 'hooks/useUI';
import EmployeePosition from './EmployeePosition';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator';
import EmployeeRole from './EmployeeRole';
import EmployeePerformanceTab from './EmployeePerformanceTab';
import GeneralImport from 'components/GeneralImport';

// ----------------------------------------------------------------------

export default function EmployeeForm(props) {
  const {row, pageAction} = props
  const { startLoading, stopLoading, activeMenu = {}, activeForms = [] } = useUI()
  const [currentTab, setCurrentTab] = useState('detail');

  const [tabs, settabs] = useState([{
    value: 'detail',
    component: <EmployeeDetail {...props} />
  }]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if(pageAction==='IMPORT'){
      settabs([
        {
          value: 'import',
          component: <GeneralImport {...props} />
        },
      ])
      setCurrentTab('import')
    }
    else if (["READ", 'EDIT'].includes(pageAction)) {
      settabs([
        {
          value: 'detail',
          component: <EmployeeDetail {...props} />
        },
        {
          value: 'posisi',
          component: <EmployeePosition {...props} />
        },
        {
          value: 'akses',
          component: <EmployeeRole {...props} />
        },
        {
          value: 'performa',
          component: <EmployeePerformanceTab {...props} />
        }
      ])
    }

  }, [pageAction])
 

  return (
    <Container>
      <Grid container item alignItems='center' alignContent='center' xs={12} sm={12} md={6} lg={6} style={{padding:8}}>
      <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
        {activeForms.map(form => (
          <>
            <BreadCrumbSeparator />
            <Typography color='primary' component='h5' variant='h5'>
              {form}
            </Typography>
          </>
        ))}
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {tabs.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
