import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { caseLevelOptions, caseStatusOptions, caseTypeOptions, consultationTypeOptions, getbkServiceLabel, getcaseLevelLabel, getcaseLevelObject, getcaseStatusObject, getcaseTypeLabel, getcaseTypeObject, getconsultationTypeLabel, getserviceTypeLabel, getuserTypeLabel, getuserTypeObject, serviceTypeOptions } from 'utils/options';
import StatusChip from 'components/StatusChip';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format, parse } from 'date-fns'
import { IconButton, Switch, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { EchoInstance } from 'App';
import UserCard from 'components/UserCard';
import RefreshIcon from '@mui/icons-material/Refresh';


const SchoolCaseDetail = (props) => {
  const { row = {}, getData, getDataByParams } = props
  const { showSuccess, showError } = useUI()
  const { detail } = useAuth()
  const [queue, setqueue] = useState(null)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  
  const reset = () => {
    return {
      id: undefined,
      student: null,
      student_id: undefined,
      employee: null,
      employee_id: undefined,
      user_type: null,
      user_type_value: undefined,
      service_type: null,
      service_type_value: undefined,
      consultation_type: null,
      consultation_type_value: undefined,
      case_type: null,
      case_type_value: undefined,
      case_level: null,
      case_level_value: undefined,
      case_status: null,
      case_status_value: undefined,
      point: 0,
      problemDescription: undefined,
      solutionDescription: undefined,
      happenedDate: new Date(),
      solvedDate: new Date(),
      admin_employee: null,
      admin_employee_id: undefined,
      isPrivate: 0,
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [studentKeyWord, setstudentKeyWord] = useState('');
  const [employeeKeyWord, setemployeeKeyWord] = useState('');
  const [userTypeOptions, setuserTypeOptions] = useState([
    { label: 'Pegawai', value: 1 },
    { label: process.env.REACT_APP_STUDENT, value: 2 }
  ]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (['isPrivate'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'employee') {
      newState['employee_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'user_type') {
      newState['user_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'service_type') {
      newState['service_type_value'] = value ? value.value : undefined
      newState['case_type'] = null
      newState['case_type_value'] = undefined
      newState['consultation_type'] = null
      newState['consultation_type_value'] = undefined
      newState['case_level'] = null
      newState['case_level_value'] = undefined
      newState['case_status'] = null
      newState['case_status_value'] = undefined
    }
    else if (fieldName === 'consultation_type') {
      newState['consultation_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'case_type') {
      newState['case_type_value'] = value ? value.value : undefined

    }
    else if (fieldName === 'case_level') {
      newState['case_level_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'case_status') {
      newState['case_status_value'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        let newState = {...state}
        if(response.data.user_type_value===1){
          newState = {...state, employee : response.data.detail, employee_id:response.data.user_detail_id}
        }
        else if(response.data.user_type_value===2){
          newState = {...state, student : response.data.detail, student_id:response.data.user_detail_id}
        }
        setqueue(response.data)
        setstate({ ...newState, user_id: response.data.user_id, user_type_value:response.data.user_type_value })
      } else {
        setqueue(null)
      }
    }

  }

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
        getLastQueue()
      } else {
        showError('delete queue')
      }
    }
    setstate(reset())
  };
  const getHistory = async () => {
    getDataByParams(
      {
        student_id: queue.detail_id
      }
    )
    props.closeDialog()
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };

  const handleStudentChange = (value) => {
    handleChange("student", value)
    if (value) {
      insertStudentToQueue(value)
      getLastQueue()
    }
  };

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleEmployeeInputChange = (newValue) => { 
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleEmployeeChange = (value) => {
    handleChange("employee", value)
    if (value) {
      insertEmployeeToQueue(value)
      getLastQueue()
    }
  };

  const insertStudentToQueue = async (student) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        student_id: student.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'UKS',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.studentQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }

    } else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const insertEmployeeToQueue = async (employee) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        employee_id: employee.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'UKS',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.employeeQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    }

  }
  const insert = async (payload) => {
    const response = await axios.post(endpoint.schoolCase.root, payload)
    if (response && response.data) {
      showSuccess('save schoolCase')
      getData()
      deleteLastQueue()
    } else {
      showError('save schoolCase')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.schoolCase.root, payload)
    if (response && response.data) {
      showSuccess('save schoolCase')
      getData()
      deleteLastQueue()
    } else {
      showError('save schoolCase')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      happenedDate: format(state.happenedDate, 'yyyy-MM-dd'),
      solvedDate: format(state.solvedDate, 'yyyy-MM-dd'),
      admin_employee_id: detail ? detail.id : undefined,
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      user_type: getuserTypeObject(row.user_type_value),
      case_type: getcaseTypeObject(row.case_type_value),
      case_level: getcaseLevelObject(row.case_level_value),
      case_status: getcaseStatusObject(row.case_status_value),
    })
  }

  useEffect(() => {
    if (pageAction !== 'READ') {
    }
  }, [pageAction])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  useEffect(() => {
    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME + 'card')
      .listen('NewCardQueue', (e) => {
        getLastQueue()
      });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME +'card');
    }
  }, [])

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DialogContent sx={{ minHeight: 300 }}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.detail && state.detail.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                  {
                    queue && <UserCard user={queue} onClose={deleteLastQueue} onClick={getHistory} />
                  }
                  <Conditional condition={queue === null}>
                    <IconButton
                      onClick={getLastQueue}
                      aria-label='reset'
                      size="large">
                      <RefreshIcon color='primary' />
                    </IconButton>
                  </Conditional>
                </Grid>

                <Conditional condition={queue === null}>
                  <Grid container item xs={12} justifyContent="center" alignItems='center' sx={{mb:1}}>
                    <AsyncSelect
                      cacheOptions
                      placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                      value={state.student}
                      loadOptions={loadOptions}
                      onInputChange={handleInputChange}
                      onChange={handleStudentChange}
                      styles={defaultSelectStyle}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="center" alignItems='center' >
                    <AsyncSelect
                      cacheOptions
                      placeholder="Nama pegawai..."
                      value={state.employee}
                      loadOptions={loadEmployeeOptions}
                      onInputChange={handleEmployeeInputChange}
                      onChange={handleEmployeeChange}
                      styles={defaultSelectStyle}
                    />
                  </Grid>
                </Conditional>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe pengguna
            </Grid>
            <Grid item sm={8} xs={12}>
              <ReadOnlyTextField value={getuserTypeLabel(state.user_type_value)} />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Layanan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getserviceTypeLabel(state.service_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="service_type"
                  placeholder="Pilih layanan"
                  options={serviceTypeOptions}
                  value={state.service_type}
                  onChange={(e) => handleChange("service_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.service_type_value === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jenis konsultasi
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getconsultationTypeLabel(state.consultation_type_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="consultation_type"
                    placeholder="Pilih jenis konsultasi"
                    options={consultationTypeOptions}
                    value={state.consultation_type}
                    onChange={(e) => handleChange("consultation_type", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal konsultasi
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={format(state.happenedDate, 'dd  MMMM yyyy')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.happenedDate}
                    onChange={(e) => handleChange('happenedDate', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

          </Conditional>

          <Conditional condition={state.service_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jenis kasus
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getcaseTypeLabel(state.case_type_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="Type"
                    placeholder="Pilih type"
                    options={caseTypeOptions}
                    value={state.case_type}
                    onChange={(e) => handleChange("case_type", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Level kasus
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getcaseLevelLabel(state.case_level_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="Type"
                    placeholder="Pilih type"
                    options={caseLevelOptions}
                    value={state.case_level}
                    onChange={(e) => handleChange("case_level", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Status kasus
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <StatusChip getStatus={getcaseStatusObject} status={state.case_status_value} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="Type"
                    placeholder="Pilih type"
                    options={caseStatusOptions}
                    value={state.case_status}
                    onChange={(e) => handleChange("case_status", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal kejadian
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={format(state.happenedDate, 'dd  MMMM yyyy')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.happenedDate}
                    onChange={(e) => handleChange('happenedDate', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tanggal selesai
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={format(state.solvedDate, 'dd  MMMM yyyy')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.solvedDate}
                    onChange={(e) => handleChange('solvedDate', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

            
          </Conditional>




          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Deskripsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.problemDescription} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  multiline
                  margin='dense'
                  id='problemDescription'
                  fullWidth
                  value={state.problemDescription}
                  onChange={(e) => handleChange('problemDescription', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Penyelesaian
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.solutionDescription} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  multiline
                  margin='dense'
                  id='solutionDescription'
                  fullWidth
                  value={state.solutionDescription}
                  onChange={(e) => handleChange('solutionDescription', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Rahasia
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === "READ"}>
                <Switch
                  color='primary'
                  checked={state.isPrivate === 1}
                />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Switch
                  color='primary'
                  checked={state.isPrivate === 1}
                  onChange={(e) => handleChange("isPrivate", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </LocalizationProvider>;
}

export default SchoolCaseDetail;