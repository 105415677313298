import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { FormControlLabel, Switch } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { getstoreTypeLabel, getstoreTypeObject, storeTypeOptions } from 'utils/options';


const StoreDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      store_type_value: undefined,
      balance: 0,
      smart_reader: null,
      smart_reader_number: undefined,
      fixed_amount:0

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')  
  const [smart_readerOptions, setsmart_readerOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'store_type') {
      newState['store_type_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'smart_reader') {
      newState['smart_reader_number'] = value ? value.number : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getsmart_readerOptions = async () => {
    const params = { }
    const response = await axios.get(endpoint.smartReader.option, { params: params });
    if (response && response.data) {
      setsmart_readerOptions(generalListOptionMapper(response.data));
    }
  };
  

  const insert = async (payload) => {
    const response = await axios.post(endpoint.store.root, payload)
    if (response && response.data) {
      showSuccess('save store')
      getData()
    } else {
      showError('save store')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.store.root, payload)
    if (response && response.data) {
      showSuccess('save store')
      getData()
    } else {
      showError('save store')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    setstate({
      ...row,
      store_type: getstoreTypeObject(row.store_type_value), 
      smart_reader: generalOptionMapper(row.smart_reader),
    })

  }

  useEffect(() => {
    if (pageAction !== 'READ') {
      getsmart_readerOptions()
    }
  }, [pageAction])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])





  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe kasir
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getstoreTypeLabel(state.store_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="Type"
                  placeholder="Pilih tipe"
                  options={storeTypeOptions}
                  value={state.store_type}
                  onChange={(e) => handleChange("store_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama usaha
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="code"
                fullWidth
                value={state.code}
                onChange={(e) => handleChange("code", e)}
              />
            </Grid>
          </Grid>


          <Conditional condition={state.store_type_value === 3}>
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Smart reader
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.smart_reader && `(${state.smart_reader.number}) ${state.smart_reader.name}`} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='smart_reader'
                  placeholder='Pilih smart reader'
                  options={smart_readerOptions}
                  value={state.smart_reader}
                  onChange={(e) => handleChange('smart_reader', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Harga (auto debet)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.fixed_amount)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='fixed_amount'
                  fullWidth
                  value={state.fixed_amount}
                  onChange={(e) => handleChange('fixed_amount', e)}
                />
              </Conditional>
            </Grid>
          </Grid>
          </Conditional>
          

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Saldo
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.balance} />
              </Grid>
            </Grid>
          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default StoreDetail;
