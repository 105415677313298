import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';

const BreadCrumbSeparator = (props) => {
  
  
  return(
    <Box mx={1} display='inline' height={20}>
      <ArrowForwardIosIcon sx={{color:grey[500]}} fontSize='small'/>
    </Box>
  )
    
}

export default BreadCrumbSeparator;
