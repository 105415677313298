import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper, scheduleListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import useUI from 'hooks/useUI.js';
import SmallButton from 'components/SmallButton.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import useAuth from 'hooks/useAuth';
import { Button, Chip, Divider, IconButton, Stack, TextField } from '@mui/material';
import { ArrowDownward, ArrowUpward, East, Edit, SelectAll } from '@mui/icons-material';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import SelectEmployeeForm from 'views/employee/SelectEmployeeForm';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import StatusBadge from 'components/StatusBadge';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import Confirmation from 'components/Confirmation';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';


const AttendanceByTeacher = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  const { user, detail } = useAuth()
  const defaultFilters = {
    keyword: ''
  }
  const [isChangePassword, setisChangePassword] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedStudent, setselectedStudent] = useState(null);
  const [userDetail, setuserDetail] = useState(null);
  const [scheduleOptions, setscheduleOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusan, setjurusan] = useState(null);
  const [kelas, setkelas] = useState(null);
  const [school, setschool] = useState(null);
  const [students, setstudents] = useState([]);
  const [movedStudents, setmovedStudents] = useState([]);
  const [selectedStudents, setselectedStudents] = useState([]);
  const [selectedStudentIds, setselectedStudentIds] = useState([]);
  const [date, setdate] = useState(new Date());
  const [undefinedCount, setundefinedCount] = useState(0);
  const [hadirCount, sethadirCount] = useState(0);
  const [izinCount, setizinCount] = useState(0);
  const [alpaCount, setalpaCount] = useState(0);
  const [schedule, setschedule] = useState(null);


  const getStudentsAttendance = async (schedule) => {
    const params = {
      schedule_id: schedule.id,
      parallel_id: schedule.parallel_id,
      attendance_date: format(date, 'yyyy-MM-dd')
    }

    const response = await axios.get(endpoint.student.attendance, { params: params });

    if (response && response.data) {
      const studentAttendances = response.data
      setstudents(studentAttendances)
      setundefinedCount(studentAttendances.filter(data => data.attendance_type_value === null).length)
      sethadirCount(studentAttendances.filter(data => data.attendance_type_value === 1).length)
      setizinCount(studentAttendances.filter(data => data.attendance_type_value === 2).length)
      setalpaCount(studentAttendances.filter(data => data.attendance_type_value === 0).length)
    }
  }

  const getscheduleOptions = async (day_value, employee_id) => {
    const params = {
      day_value: day_value,
      employee_id: employee_id,
      schedule_type_value: 2
    }

    const response = await axios.get(endpoint.schedule.option, { params: params });

    if (response && response.data) {
      setscheduleOptions(scheduleListOptionMapper(response.data))
    }
  }


  const submitAllPresent = async () => {

    let payload = {
      day_value: date.getDay(),
      employee_id: detail.id,
      subject_id: schedule.subject_id,
      parallel_id: schedule.parallel_id,
      attendance_type_value: 1,
      attendance_date: format(date, 'yyyy-MM-dd'),
      student_ids: students.filter(student => student.attendance_type_value === null).map(student => student.id)
    }

    const response = await axios.post(endpoint.student.allPresent, payload)

    if (response && response.data) {
      getStudentsAttendance(schedule)
    } else {
      showError('simpan absensi')
    }

  }


  const dateChange = (e) => {
    setdate(e)
    getscheduleOptions(e.getDay(), detail.id)
    setschedule(null)
    setstudents([])
  }

  const scheduleChange = (e) => {
    setschedule(e)
    getStudentsAttendance(e)
  }

  const studentAttendance = async (student_id, status) => {

    let payload = {
      day_value: date.getDay(),
      employee_id: detail.id,
      subject_id: schedule.subject_id,
      parallel_id: schedule.parallel_id,
      attendance_type_value: status,
      attendance_date: format(date, 'yyyy-MM-dd'),
      student_id: student_id
    }

    const response = await axios.post(endpoint.student.attendance, payload)

    if (response && response.data) {
      getStudentsAttendance(schedule)
    } else {
      showError('simpan absensi')
    }

    setopenDialog(false)
  }

  const showPresenceDialog = (student) => {
    setMaxWidth("sm");
    setdialogContent(
      <Grid container justifyContent='space-around' sx={{ p: 2 }}>
        <Grid item>
          <Button onClick={(e) => studentAttendance(student.id, 1)} variant='contained' color='success'>Hadir</Button>
        </Grid>
        <Grid item>
          <Button onClick={(e) => studentAttendance(student.id, 2)} variant='contained' color='warning'>Izin</Button>
        </Grid>
        <Grid item>
          <Button onClick={(e) => studentAttendance(student.id, 0)} variant='contained' color='error'>Alpa</Button>
        </Grid>
      </Grid>
    );

    setopenDialog(true);
  }

  const closePresenceDialog = (e) => {
    setopenDialog(false)
  }

  const allPresentConfirmation = (e) => {
    setMaxWidth("sm");
    setdialogContent(
      <Confirmation
        handleClose={() => setopenDialog(false)}
        action={submitAllPresent}
        actionName="Simpan"
        message='Apakah kehadiran sudah sesuai'
        title='Konfirmasi kehadiran'
      />
    );

    setopenDialog(true);
  }


  useEffect(() => {
    if (detail) {
      getscheduleOptions(date.getDay(), detail.id)
    }
  }, [detail])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>



        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" spacing={1} >
          <Chip label={`Total ${students.length} `} color="default" />
          <Chip label={`Hadir ${hadirCount} `} color="success" />
          <Chip label={`Izin ${izinCount} `} color="warning" />
          <Chip label={`Alpa ${alpaCount} `} color="error" />
        </Stack>
      </Grid>

      <Grid container spacing={1} justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{ mt: 1, minHeight: 400 }} >
        <Grid item xs={12} container spacing={1} justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item sm={4} xs={12} sx={{ mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                renderInput={(props) => <TextField {...props} fullWidth size="small" />}
                value={date}
                onChange={dateChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={4} xs={12} sx={{ mb: 1 }}>
            <Select
              name="schedule"
              placeholder="Pilih jadwal"
              options={scheduleOptions}
              value={schedule}
              onChange={scheduleChange}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item sm={4} xs={12} container>
            {
              students.length > 0 && undefinedCount > 0 && students.length === undefinedCount &&
              <Button onClick={allPresentConfirmation} color='success' variant='outlined' fullWidth >Semuanya hadir</Button>
            }
            {
              students.length > 0 && undefinedCount > 0 && students.length !== undefinedCount &&
              <Button onClick={allPresentConfirmation} color='success' variant='outlined' fullWidth >Sisanya hadir</Button>
            }
          </Grid>

        </Grid>

        <Grid item xs={12} lg={6} container justifyContent='center' alignItems='flex-start' alignContent='flex-start' sx={{ mt: 1 }}>
          <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
            <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
              {
                `${process.env.REACT_APP_STUDENT} di kelas `
              }
              {
                schedule && schedule.parallel && schedule.parallel.name
              }
              
              <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {students.map((student) => {
                  return (
                    <>
                      <ListItem
                        key={student.id}
                        disablePadding
                        secondaryAction={
                          <StatusBadge status={student.attendance_type_value} />
                        }
                        onClick={() => showPresenceDialog(student)}
                      >
                        <ListItemButton>
                          <ListItemText primary={student.name} />
                        </ListItemButton>
                      </ListItem>

                      <Divider light />
                    </>
                  );
                })}
              </List>
            </Grid>
          </Paper>

        </Grid>

      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closePresenceDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default AttendanceByTeacher;

