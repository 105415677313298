import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { DialogActions, CardActionArea, CardActions } from '@mui/material';
import { Box } from '@mui/system';
import { parameterListOptionMapper } from 'utils/mappers';


const StudentFile = (props) => {
  const { row = {} } = props
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [studentFiles, setstudentFiles] = useState([])
  const [selectedFile, setselectedFile] = useState(null)
  const [documentTypeOptions, setdocumentTypeOptions] = useState([])



  const closeDialog = () => {
    setopenDialog(false)
    setselectedFile(null)
  }

  const getStudentFiles = async () => {
    let params = {}
    const response = await axios.get(endpoint.student.file.replace('id', row.id), params)
    if (response) {
      setstudentFiles(response.data)
    }
  }

  const fileChange = async (currentFile, event) => {
    let file = event.target.files[0]
    const formData = new FormData();
    formData.append('file', file)
    formData.append('id', currentFile.id)
    formData.append('document_type_value', currentFile.document_type_value)

    const response = await axios.post(endpoint.student.file.replace('/id',''), formData, {});
    if (response && response.data) {
      getStudentFiles()
    }
  };


  const showFile = (file) => {
    setselectedFile(file)

  }

  useEffect(() => {
    if (row) {
      getStudentFiles()
    }
  }, [row])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' >
      <Grid container justifyContent="center" alignItems="flex-start"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: 1,
          padding: 2,
          width: 'inherit',
        }}
      >

        {
          studentFiles.map(file => (
            <Grid item xs={12} md={4} sx={{ p: 2 }} container justifyContent="center" alignItems="flex-start">
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => showFile(file)}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${api.url}/${file.path}`}
                    alt="file psb"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {file.document_type && file.document_type.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Protected allowedCodes={['EDIT']} >
                  <CardActions>
                    <label htmlFor={`${file.id}-upload`}>
                      <input onChange={(e) => fileChange(file, e)} accept="image/*" id={`${file.id}-upload`} type="file" style={{ display: 'none' }} />
                      <Button component="span" color='error'>
                        Ubah
                      </Button>
                    </label>
                  </CardActions>
                </Protected>

              </Card>
            </Grid>
          ))

        }

      </Grid>

    </Grid>

    <DialogActions>
      <Grid container>
        <Grid item xs={6} container>

        </Grid>
        <Grid item xs={6} container justifyContent='flex-end'>
          <Button onClick={props.closeDialog} color="secondary">
            Kembali
          </Button>
        </Grid>
      </Grid>





    </DialogActions>

    <Dialog
      open={selectedFile !== null}
      maxWidth='xl'
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <Grid container justifyContent='center'>
        {
          selectedFile &&
          <img src={`${api.url}/${selectedFile.path}`} />
        }
      </Grid>

    </Dialog>

  </>;
}


export default StudentFile;
