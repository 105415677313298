import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Select from 'react-select'
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';


const FilterComponent =(props)=> {
  const {options, removeFilter, filterChange, field} = props
  const classes = useStyles();

  if(field.type==="select"){
    return (
      <Grid item container justifyContent="center" alignItems="center" wrap="nowrap" className={classes.filterWrapper}>
        <Grid item>
        <Select
          isClearable={true}
          name={field.name}
          placeholder = {field.display}
          options={options}
          onChange={(e)=>filterChange(field.name,e)}
          styles={selectCustomZindex}
        />
        </Grid>
        <Grid item>
         <IconButton
          onClick={()=>removeFilter(field)}
          size="small"
          aria-label="close"
          style={{margin:0}}>
            <Cancel fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    );
  }else{
    return null
  }

}

export default FilterComponent;


const useStyles = makeStyles((theme) => ({
  filterWrapper:{
    minWidth:150,
    width:'fit-content'
  },
}));


const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: "150px",
        margin: "0 4px"
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
        };
    }
};
