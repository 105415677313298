import React from 'react';
import { Grid, Button, Typography, CardContent, CardActions, Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const MoneyCard = (props) => {
  const { user={}, onClick, onClose } = props

  const handleClose = ()=>{
    onClose()
  }

  return (
    <Card variant="outlined" onClick={onClick} style={{ cursor: 'pointer' }}>
      <CardContent>
        <Grid container sx={{minWidth:150}}>
          <Grid item xs={12} container justifyContent="space-between" alignItems='center'>
            <Grid item>
              <Typography variant="h6" component="h6" align="center">
                {user.name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
                aria-label='close'
                size="small">
                <CloseIcon color='primary' />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={4} container justifyContent="center" alignItems='center'>
            <Typography variant="body1" align="center">
              saldo :
            </Typography>
          </Grid>
          <Grid item xs={8} container justifyContent="center" alignItems='center'>
            <Typography variant="h6" component="h6" align="center">
              {new Intl.NumberFormat().format(user.balance)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MoneyCard;
