import React,{useState,useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import useUI from 'hooks/useUI';
import useAuth from 'hooks/useAuth';
import {useHistory} from 'react-router-dom'
import MenuItem from 'components/MenuItem';
import { blue  } from '@mui/material/colors';


const Sidebar =(props)=> {  
  const { justify, onClose, expandedMenuId, setexpandedMenuId} = props
  const history = useHistory()
  const {menus=[],actions=[] } = useAuth()
  const {isLoading, activeMenu={}, setActiveMenuActions } = useUI()
  const classes = useStyles();

  const navigateToMenu = (menu) =>{    
    if(menu.parent_id!==expandedMenuId){
      setexpandedMenuId(null) 
    }
    setActiveMenuActions(menu)
    history.push(menu.webPath)
    onClose()
  }

  const toggleParentMenu = (menu) =>{
    if(expandedMenuId){
      if(expandedMenuId===menu.id){
        setexpandedMenuId(null)  
      }else{
        setexpandedMenuId(menu.id)  
      }
    }else{
      setexpandedMenuId(menu.id)
    }
    
  }

  const menuClick=(menu)=>{
    if(!menu.webPath){
      toggleParentMenu(menu)
    }else{
      navigateToMenu(menu)
    }
  }

  const renderMenu=(rootMenus)=>{    
    return(
      rootMenus.map(menu=>(
        <MenuItem expandedMenuId={expandedMenuId} menu={menu} activeMenu={activeMenu} menuClick={menuClick}/>
      ))
    )
  }
 
  return (
    <Grid sx={{width:300}} container justifyContent={justify} alignItems='flex-start' alignContent="flex-start" className={classes.sideBarListContainer}>
      <Hidden lgUp>
        <Grid container justifyContent="space-between" alignContent="center" alignItems="center" style={{padding:"0 8px 0 8px"}}>          
          <Typography variant="h6" color="primary">Smart Education System</Typography>
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            size="large">
            <CloseIcon color="primary"/>
          </IconButton>
        </Grid>
      </Hidden>
      <List 
      component="div"
      disablePadding 
      sx={{
        width:"100%",
        '&& .Mui-selected, && .Mui-selected:hover': {
          bgcolor: blue[50],
          borderRadius:4,
          '&, & .MuiListItemIcon-root': {
            color: 'primary.main',
          }
        },
        '&& .MuiListItemButton-root:hover': {
          bgcolor: blue[50],
          borderRadius:4,
          '&, & .MuiListItemIcon-root': {
            color: 'primary.main',
          }
        },
      }}
      >
        {
          renderMenu(menus.filter(menu=>menu.parent_id===null))
        }
      </List>
    </Grid>
  );
}

export default Sidebar;





export const useStyles = makeStyles((theme) => ({
  sideBar:{
    height:'100vh',
    borderRight:'1px #e1e2e4 solid'
  },
  list:{
    width:"100%"
  }

}));
