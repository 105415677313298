import React from 'react'
import MaterialLink from '@mui/material/Link';
import { useHistory } from 'react-router-dom';

const Link = (props) => {
  
  const history = useHistory()
  function dashboardClick(event) {
    event.preventDefault();
    history.push(props.href)
  }
  return(
    <MaterialLink {...props} onClick={dashboardClick}/>
  )
    
}

export default Link;
