import React from 'react'

import Chip from '@mui/material/Chip';

const ActiveChip = ({status, margin}) => {

    const label = status===1 || status===true ?'Active':'Inactive'
    const backgroundColor = status===1 || status===true ?'success':'default'
    const textColor = status===1 || status===true ?'white':'black'
    
    return (
        <Chip 
        size="small" 
        label={label} 
        color={backgroundColor}
        sx={{
          color:textColor,
          margin:margin
        }}
        />
    );
}

export default ActiveChip;
