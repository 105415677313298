import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getmonthLabel, getmonthObject, getsuratLabel, getsuratObject, gettahfidzScoreTypeLabel, gettahfidzScoreTypeObject, monthOptions, suratOptions, tahfidzScoreTypeOptions } from 'utils/options';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import { Checkbox, IconButton } from '@mui/material';
import useSetting from 'hooks/useSetting';


const MonthlyTahfidzDetail = (props) => {
  const { row = {}, getData } = props
  const { tahunPelajaran } = useSetting()
  const { user, detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const reset = () => {
    return {
      id: undefined,
      student: null,
      student_id: undefined,
      month: getmonthObject(month + 1),
      month_value: month + 1,
      year: { label: year, value: year },
      year_value: year,
      teacher_employee: detail ? generalOptionMapper(detail) : null,
      teacher_employee_id: detail ? detail.id : undefined,
      pageTotal: undefined,
      pageStart: undefined,
      pageEnd: undefined,
      start_surat: null,
      start_surat_id: undefined,
      end_surat: null,
      end_surat_id: undefined,
      murojaahJuzTotal: undefined,
      memorizedJuzTotal: undefined,
      isExam: 0,
      alpa: 0,
      score: null,
      score_value: undefined,
      examJuzStart: undefined,
      examJuzEnd: undefined,
      reward_type: null,
      reward_type_id: undefined

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [yearOptions, setyearOptions] = useState([])
  const [studentOptions, setstudentOptions] = useState([])
  const [rewardTypeOptions, setrewardTypeOptions] = useState([])

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isExam"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 0) {
        newState['score'] = null;
        newState['score_value'] = undefined;
        newState['reward_type'] = null;
        newState['reward_type_id'] = undefined;
      }
    }
    else if (fieldName === 'surat') {
      newState['surat_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'teacher_employee') {
      newState['teacher_employee_id'] = value ? value.id : undefined
      if (value) {
        getgroupedStudents(value)
      }
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.id : undefined
    }
    else if (fieldName === 'month') {
      newState['month_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'year') {
      newState['year_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'start_surat') {
      newState['start_surat_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'end_surat') {
      newState['end_surat_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'reward_type') {
      newState['reward_type_id'] = value ? value.id : undefined
    }
    else if (fieldName === 'score') {
      newState['score_value'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }


  const getgroupedStudents = async (employee) => {
    let params = {
      employee_id: employee.id,
      group: 'TAHFIDZ',
      tahun_id: tahunPelajaran.id
    }
    const response = await axios.get(endpoint.student.group, { params: params });
    if (response && response.data) {
      setstudentOptions(studentListOptionMapper(response.data));
    }
  };

  const getrewardTypeOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.reward.typeOption, { params: params });
    if (response && response.data) {
      setrewardTypeOptions(generalListOptionMapper(response.data));
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getYearOptions = (keyword) => {

    setyearOptions(
      [
        { label: year - 2, value: year - 2 },
        { label: year - 1, value: year - 1 },
        { label: year, value: year },
        { label: year + 1, value: year + 1 },
        { label: year + 2, value: year + 2 },
      ]
    )
  }


  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };


  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const handleInputChangeEmployee = (newValue) => { 
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };



  const insert = async (payload) => {
    const response = await axios.post(endpoint.tahfidz.monthly, payload)
    if (response && response.data) {
      showSuccess('save tahfidz')
      getData()
    } else {
      showError('save tahfidz')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.tahfidz.monthly, payload)
    if (response && response.data) {
      showSuccess('save tahfidz')
      getData()
      props.closeDialog()
    } else {
      showError('save tahfidz')
    }
  }

  const submit = async () => {

    if (!state.teacher_employee_id) {
      showInfo('Belum pilih ustadz/ah')
      return
    }
    if (!state.student_id) {
      showInfo(`Belum pilih ${process.env.REACT_APP_STUDENT}`)
      return
    }
    if (!state.pageTotal) {
      showInfo('Belum isi total halaman ziyadah')
      return
    }
    if (!state.murojaahJuzTotal) {
      showInfo('Belum isi total juz murojaah')
      return
    }
    if (!state.memorizedJuzTotal) {
      showInfo('Belum isi total juz dihafal')
      return
    }

    const params = {
      ...state,
      alpa: state.alpa ? state.alpa : 0
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)

    }

  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      start_surat: getsuratObject(row.start_surat_id),
      end_surat: getsuratObject(row.end_surat_id),
      student: generalOptionMapper(row.student),
      teacher_employee: generalOptionMapper(row.teacher_employee),
      month: getmonthObject(row.month_value),
      reward_type: generalOptionMapper(row.reward_type),
      score: gettahfidzScoreTypeObject(row.score_value),
      tahun: generalOptionMapper(row.tahun),
      year: { label: row.year_value, value: row.year_value }
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getYearOptions()
      getrewardTypeOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  useEffect(() => {
    if (detail && user.user_type_value === 1) {
      getgroupedStudents(detail)
    }
  }, [detail])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ustadz / Ustadzah
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.teacher_employee && state.teacher_employee.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <AsyncSelect
                  isClearable={true}
                  cacheOptions
                  placeholder="Pilih Ustadz/ah..."
                  value={state.teacher_employee}
                  loadOptions={loadEmployeeOptions}
                  onInputChange={handleInputChangeEmployee}
                  onChange={(e) => handleChange('teacher_employee', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              { process.env.REACT_APP_STUDENT}
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.student && state.student.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name="student"
                  placeholder={`Pilih ${process.env.REACT_APP_STUDENT}`}
                  options={studentOptions}
                  value={state.student}
                  onChange={(e) => handleChange("student", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Bulan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getmonthLabel(state.month_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='month'
                  placeholder='Pilih bulan'
                  options={monthOptions}
                  value={state.month}
                  onChange={(e) => handleChange('month', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.year_value} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='year'
                  placeholder='Pilih tahun'
                  options={yearOptions}
                  value={state.year}
                  onChange={(e) => handleChange('year', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ziyadah (Total halaman)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.pageTotal} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='pageTotal'
                  fullWidth
                  value={state.pageTotal}
                  type='number'
                  onChange={(e) => handleChange('pageTotal', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ziyadah (halaman mulai)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.pageStart} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='pageStart'
                  fullWidth
                  value={state.pageStart}
                  type='number'
                  onChange={(e) => handleChange('pageStart', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ziyadah (halaman akhir)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.pageEnd} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='pageEnd'
                  fullWidth
                  value={state.pageEnd}
                  type='number'
                  onChange={(e) => handleChange('pageEnd', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ziyadah (surat mulai)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsuratLabel(state.start_surat_id)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name="surat"
                  placeholder="Pilih surat"
                  options={suratOptions}
                  value={state.start_surat}
                  onChange={(e) => handleChange("start_surat", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ziyadah (surat akhir)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsuratLabel(state.end_surat_id)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name="surat"
                  placeholder="Pilih surat"
                  options={suratOptions}
                  value={state.end_surat}
                  onChange={(e) => handleChange("end_surat", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Murojaah (total juz)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.murojaahJuzTotal} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='murojaahJuzTotal'
                  fullWidth
                  value={state.murojaahJuzTotal}
                  type='number'
                  onChange={(e) => handleChange('murojaahJuzTotal', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah total juz dihafal
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.memorizedJuzTotal} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='memorizedJuzTotal'
                  fullWidth
                  value={state.memorizedJuzTotal}
                  type='number'
                  onChange={(e) => handleChange('memorizedJuzTotal', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah alpa
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.alpa} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='alpa'
                  fullWidth
                  value={state.alpa}
                  type='number'
                  onChange={(e) => handleChange('alpa', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ujian
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isExam === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isExam === 1}
                  onChange={(e) => handleChange("isExam", e)}
                />
              </Conditional>
            </Grid>
          </Grid>



          <Conditional condition={state.isExam === 1}>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Juz ujian
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={5}>
                      <ReadOnlyTextField value={state.examJuzStart} />
                    </Grid>
                    <Grid item xs={1} container justifyContent='center' alignContent='center'>
                      -
                    </Grid>
                    <Grid item xs={5}>
                      <ReadOnlyTextField value={state.examJuzEnd} />
                    </Grid>
                  </Grid>
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>

                  <Grid container justifyContent='space-between'>
                    <Grid item xs={5}>
                      <DebouncedTextField
                        margin='dense'
                        id='examJuzStart'
                        fullWidth
                        value={state.examJuzStart}
                        type='number'
                        onChange={(e) => handleChange('examJuzStart', e)}
                      />
                    </Grid>
                    <Grid item xs={1} container justifyContent='center' alignContent='center'>
                      -
                    </Grid>
                    <Grid item xs={5}>
                      <DebouncedTextField
                        margin='dense'
                        id='examJuzEnd'
                        fullWidth
                        value={state.examJuzEnd}
                        type='number'
                        onChange={(e) => handleChange('examJuzEnd', e)}
                      />
                    </Grid>
                  </Grid>
                </Conditional>
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nilai
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={gettahfidzScoreTypeLabel(state.score_value)} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name="surat"
                    placeholder="Pilih nilai"
                    options={tahfidzScoreTypeOptions}
                    value={state.score}
                    onChange={(e) => handleChange("score", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Penghargaan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.reward_type && state.reward_type.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name='reward'
                    placeholder='Pilih penghargaan'
                    options={rewardTypeOptions}
                    value={state.reward_type}
                    onChange={(e) => handleChange('reward_type', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>



        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default MonthlyTahfidzDetail;

