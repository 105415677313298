import React from 'react';
import { Grid, Typography} from '@mui/material';
import { Add } from '@mui/icons-material';


const VerticalPillSelection = (props) => {
    const { options, selected, onSelection, withAddition=false, onAdditionClick } = props
    return (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
      >
        {
          options.map(item => (
            <Grid
              item
              xs={5} md={3}
              container
              justifyContent='center'
              alignItems='center'
              onClick={() => onSelection(item)}
              sx={{
                cursor: 'pointer',
                borderRadius: 4,
                bgcolor: selected && selected.id === item.id ? 'success.main' : '#d4cfcf',
                p: 1,
                mt: 2,
                mx: 1
              }}>
              <Typography variant="subtitle1" align="center"
                sx={{
                  width: 200,
                  color: selected && selected.id === item.id ? 'white' : '#463e3e'
                }}
              >
                {
                  item.name
                }
              </Typography>
            </Grid>
          ))
        }

        {
          withAddition &&
          <Grid
            item
            xs={4} sm={2}
            container
            justifyContent='center'
            alignItems='center'
            onClick={onAdditionClick}
            sx={{
              cursor: 'pointer',
              borderRadius: 4,
              bgcolor: '#d4cfcf',
              p: 1,
              mt: 2,
              mx: 1
            }}>
            <Add fontSize='medium' />
          </Grid>
        }
  
      </Grid>
    )
  }

  export default VerticalPillSelection;