import React,{useState, useEffect, useRef} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilterList from 'components/FilterList';
import DeleteConfirmation   from 'components/DeleteConfirmation.js';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import {generalListOptionMapper, parameterListOptionMapper} from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import useUI from 'hooks/useUI.js';
import { DialogActions } from '@mui/material';


const NewStudentAdminExam =(props)=> {
  const {row={}} = props
  const { activeMenu={}, activeForms, showSuccess, showError, setActiveForms } = useUI()
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch();
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({keyword:''})
  const [filtersAvailable, setfiltersAvailable] = useState([])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{label:'aktif', value:1}, {label:'nonaktif', value:0}])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter =(field)=>{
    let filtersTemp = {...filters}
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f=>f.name!==field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy,field])
  }


  const getExams =async()=>{
    let params = {}
    
    const response = await axios.get(endpoint.newStudent.exam.replace('id',row.id), {params:params})    
    
    if(response && response.data){
      const modifiedData = response.data.map(row=>({
        ...row
      }))
      setDataTable(modifiedData)
    }
  }


  const closeDialog =()=>{
    setopenDialog(false)
    if(pageAction==='READ'){
      setselectedRow(null)
      setselectedIds([])
    }
    setActiveForms(activeForms.filter(form=>form!=='Action'))
  }  



  const toggleSelectRow =(row)=>{
    if(selectedIds.includes(row.id)){
      const ids = selectedIds.filter(item=>item!==row.id)
      setselectedIds(ids)
      
      if(ids.length===1){
        const existingRow = dataTable.filter(data=>(data.id===ids[0]))
        setselectedRow(existingRow[0])
      }
      else{
        setselectedRow(null)
      }      
      
    }else{      
      setselectedIds([...selectedIds,row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow=()=>{
    if(selectedIds.length === dataTable.length){
      setselectedIds([])
    }else{
      setselectedIds(dataTable.map(row=>row.id))
    }
  }
  
  useEffect(() => {
    getExams()
  }, [])

    return <>
      <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{p:1}}>
          <Grid container justifyContent='flex-end' sx={{my:1}}>
          </Grid>
          
          <Grid container sx={{minHeight:400}}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        inputProps={{ 'aria-label': 'select all' }}
                        onChange={toggleSelectAllRow}
                        checked={selectedIds.length === dataTable.length && dataTable.length>0}
                      />
                    </TableCell>
                    <TableCell>Ujian</TableCell>
                    <TableCell>Nilai</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((row) => (
                    <TableRow key={row.id} onClick={()=>toggleSelectRow(row)}>
                      <TableCell>
                      <Checkbox
                          checked={selectedIds.includes(row.id)}                            
                          color="primary"
                          inputProps={{ 'aria-label': 'select all' }}
                        />
                      </TableCell>               
                      <TableCell>{row.exam_type && row.exam_type.name}</TableCell> 
                      <TableCell>{row.score}</TableCell>  
                      </TableRow>
                  ))}
                </TableBody>
              </Table>                
            </TableContainer>
          </Grid>

      </Grid>

      <DialogActions>
        <Button onClick={props.closeDialog} color="secondary">
          Kembali
        </Button>
      </DialogActions>

      <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
      >
        {dialogContent}
      </Dialog>

      <Popover
        id='popUpFilter'
        open={Boolean(anchorFilter)}
        anchorEl={anchorFilter}
        onClose={popupFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={
          {marginTop:8}
        }
      >
        <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose}/>
      </Popover>


      </>;
  }
  

export default NewStudentAdminExam;


