import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getreaderLevelLabel, getreaderLevelObject, getuserTypeLabel, getuserTypeObject, readerLevelOptions, userTypeOptions } from 'utils/options';
import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';


const NewsDetail = (props) => {
  const { row = {}, getData } = props
  const { user, detail } = useAuth()
  const { showSuccess, showError, isAllDataAccess } = useUI()
  const reset = () => {
    return {
      user_type: null,
      user_type_value: undefined,
      reader_level: null,
      reader_level_value: undefined,
      tahun: null,
      tahun_id: undefined,
      position: null,
      position_id: undefined,
      jenjang: null,
      jenjang_id: undefined,
      school: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      parallel: null,
      parallel_id: undefined,
      jurusan: null,
      jurusan_id: undefined,
      student: null,
      student_id: undefined,
      new_student: null,
      new_student_id: undefined,
      employee: null,
      employee_id: undefined,
      position: null,
      position_id: undefined,
      title: '',
      content: '',
      admin_employee: null,
      admin_employee_id: undefined,
      news_status_value: 1,//published, 0 draft
      isForAll: 0,
      isSendToWA:0
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [positionOptions, setpositionOptions] = useState([]);
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameNewStudentKeyWord, setnameNewStudentKeyWord] = useState('');


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isForAll"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 1) {
        newState['reader_level'] = null;
        newState['reader_level_value'] = undefined;
        newState['position'] = null;
        newState['position_id'] = undefined;
        newState['school'] = null;
        newState['school_id'] = undefined;
        newState['kelas'] = null;
        newState['kelas_id'] = undefined;
        newState['parallel'] = null;
        newState['parallel_id'] = undefined;
        newState['jurusan'] = null;
        newState['jurusan_id'] = undefined;

      }
    }
    else if (fieldName === 'isSendToWA') {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'reader_level') {
      newState['reader_level_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      if (value) {
        newState['school_id'] = value.value
        newState['jenjang_id'] = value.jenjang_id
        getkelasOptions(value.jenjang_id)
        getjurusanOptions(value.jenjang_id)
      } else {
        newState['school_id'] = undefined
        newState['jenjang'] = null
        newState['jenjang_id'] = undefined
        setkelasOptions([])
        setjurusanOptions([])
      }

    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
      getparallelOptions(value ? value.value : undefined, undefined)
    }
    else if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value ? value.value : undefined
      getparallelOptions(undefined, value ? value.value : undefined)
    }
    else if (fieldName === 'position') {
      newState['position_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'user_type') {
      newState['user_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'employee') {
      newState['employee_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'new_student') {
      newState['new_student_id'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getNewStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeNewStudent = (newValue) => {
    setnameNewStudentKeyWord(newValue)
    return newValue;
  };


  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const loadNewStudentOptions = async (inputValue, callback) => {
    callback(await getNewStudentOptions(inputValue))
  };

  const handleInputChangeEmployee = (newValue) => {
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const getpositionOptions = async () => {
    const response = await axios.get(endpoint.position.option);
    if (response && response.data) {
      setpositionOptions(generalListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async (kelas_id, jurusan_id) => {
    const params = {
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.news.root, payload)
    if (response && response.data) {
      showSuccess('save news')
      getData()
    } else {
      showError('save news')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.news.root, payload)
    if (response && response.data) {
      showSuccess('save news')
      getData()
    } else {
      showError('save news')
    }
  }

  const saveAsDraft = () => {
    const params = {
      ...state,
      admin_employee_id: detail ? detail.id : undefined,
      news_status_value: 0
    }
    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }



  const submit = async () => {
    const params = {
      ...state,
      admin_employee_id: detail ? detail.id : undefined,
      news_status_value: 1
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      position: generalOptionMapper(row.position),
      user_type: getuserTypeObject(row.user_type_value),
      reader_level: getreaderLevelObject(row.reader_level_value)
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getschoolOptions()
      getpositionOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Untuk semua
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isForAll === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isForAll === 1}
                  onChange={(e) => handleChange("isForAll", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Conditional condition={state.isForAll !== 1}>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tipe pengguna
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getuserTypeLabel(state.user_type_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="User Type"
                    placeholder="Pilih tipe pengguna"
                    options={userTypeOptions}
                    value={state.user_type}
                    onChange={(e) => handleChange("user_type", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jangkauan
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={getreaderLevelLabel(state.reader_level_value)} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Select
                    name="Reader Level"
                    placeholder="Pilih level pembaca"
                    options={readerLevelOptions}
                    value={state.reader_level}
                    onChange={(e) => handleChange("reader_level", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Conditional condition={state.reader_level_value === 6}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Posisi
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.position && state.position.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='position'
                      placeholder='Pilih posisi'
                      options={positionOptions}
                      value={state.position}
                      onChange={(e) => handleChange('position', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={[2, 3, 4, 5].includes(state.reader_level_value)}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Sekolah
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.school && state.school.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='school'
                      placeholder='Pilih sekolah'
                      options={schoolOptions}
                      value={state.school}
                      onChange={(e) => handleChange('school', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>


            <Conditional condition={[3, 4, 5].includes(state.reader_level_value)}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Kelas
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={state.kelas && state.kelas.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <Select
                      isClearable={true}
                      name="kelas"
                      placeholder="Pilih Kelas"
                      options={kelasOptions}
                      value={state.kelas}
                      onChange={(e) => handleChange("kelas", e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>


            <Conditional condition={state.reader_level_value === 5}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Jurusan
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.jurusan && state.jurusan.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='jurusan'
                      placeholder='Pilih jurusan'
                      options={jurusanOptions}
                      value={state.jurusan}
                      onChange={(e) => handleChange('jurusan', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>


            <Conditional condition={state.reader_level_value === 4}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Parallel
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.parallel && state.parallel.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Select
                      isClearable={true}
                      name='parallel'
                      placeholder='Pilih Parallel'
                      options={parallelOptions}
                      value={state.parallel}
                      onChange={(e) => handleChange('parallel', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={state.user_type_value === 1 && state.reader_level_value === 7}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Pegawai
                </Grid>
                <Grid item sm={8} xs={12}>

                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={state.employee && state.employee.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <AsyncSelect
                      isClearable={true}
                      cacheOptions
                      placeholder="Pilih Pegawai..."
                      value={state.employee}
                      loadOptions={loadEmployeeOptions}
                      onInputChange={handleInputChangeEmployee}
                      onChange={(e) => handleChange('employee', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>
                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={(state.user_type_value === 2 || state.user_type_value === 3) && state.reader_level_value === 7}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  {process.env.REACT_APP_STUDENT}
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={state.student && state.student.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <AsyncSelect
                      isClearable={true}
                      cacheOptions
                      placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                      value={state.student}
                      loadOptions={loadStudentOptions}
                      onInputChange={handleInputChangeStudent}
                      onChange={(e) => handleChange('student', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>

                </Grid>
              </Grid>
            </Conditional>

            <Conditional condition={(state.user_type_value === 6 || state.user_type_value === 7) && state.reader_level_value === 7}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  {`Calon ${process.env.REACT_APP_STUDENT}`}
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === "READ"}>
                    <ReadOnlyTextField value={state.new_student && state.new_student.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== "READ"}>
                    <AsyncSelect
                      isClearable={true}
                      cacheOptions
                      placeholder={`Pilih calon ${process.env.REACT_APP_STUDENT}...`}
                      value={state.new_student}
                      loadOptions={loadNewStudentOptions}
                      onInputChange={handleInputChangeNewStudent}
                      onChange={(e) => handleChange('new_student', e)}
                      styles={defaultSelectStyle}
                    />
                  </Conditional>

                </Grid>
              </Grid>
            </Conditional>

          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Judul
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.title} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='title'
                  fullWidth
                  value={state.title}
                  onChange={(e) => handleChange('title', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Isi berita
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.content} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='content'
                  fullWidth
                  value={state.content}
                  onChange={(e) => handleChange('content', e)}
                  multiline={true}
                  rows={3}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>

            </Grid>
            <Grid item sm={8} xs={12}>
             
              <Conditional condition={pageAction !== 'READ'}>
                <FormControlLabel
                  value="isSendToWA"
                  control={
                    <Switch color="primary"
                      checked={state.isSendToWA === 1}
                      onChange={(e) => handleChange("isSendToWA", e)}
                    />
                  }
                  label="WhatsApp"
                  labelPlacement="start"
                />
              </Conditional>
            </Grid>
          </Grid>



        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="text" onClick={saveAsDraft} color="primary">
          Simpan sebagai draft
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Sebarkan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default NewsDetail;

