import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Alert, AlertTitle, Button, Checkbox, Dialog, FormControl, FormControlLabel, FormLabel, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import DebouncedTextField from 'components/DebouncedTextField';
import SmallButton from 'components/SmallButton';
import Conditional from 'components/Conditional';
import { educationTypeOptions, geteducationTypeLabel, geteducationTypeObject, getjobTypeLabel, getjobTypeObject, getlifeStatusLabel, getlifeStatusObject, getreligionLabel, getreligionObject, getsalaryTypeLabel, getsalaryTypeObject, getsexTypeLabel, getsexTypeObject, getstayWithLabel, getstayWithObject, getvehicleTypeLabel, getvehicleTypeObject, getwaliTypeLabel, getwaliTypeObject, jobTypeOptions, lifeStatusOptions, religionOptions, salaryTypeOptions, sexTypeOptions, stayWithOptions, vehicleTypeOptions, waliTypeOptions } from 'utils/options';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper, studentListOptionMapper } from 'utils/mappers';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import AsyncSelect from 'react-select/async';
import SelectBillTypeForm from 'views/bill/SelectBillTypeForm';
import { Close, Delete } from '@mui/icons-material';


const NewStudentAdminDetail = (props) => {
  const { row = {}, getData } = props
  const { user, detail } = useAuth()
  const { showSuccess, showError } = useUI()

  const reset = () => {
    return {
      id: undefined,
      isContinue: 0,
      existing_student: null,
      existing_student_id: undefined,
      existing_parent: null,
      existing_parent_id: undefined,
      registration_number: undefined,
      user: null,
      name: undefined,
      fatherName: undefined,
      motherName: undefined,
      jenjang: null,
      jenjang_id: undefined,
      school: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      jurusan: null,
      jurusan_id: undefined,
      province: null,
      province_id: undefined,
      regency: null,
      regency_id: undefined,
      district: null,
      district_id: undefined,
      village: null,
      village_id: undefined,
      rw: undefined,
      rt: undefined,
      postalCode: undefined,
      vehicle_type: null,
      vehicle_type_value: undefined,
      wali_type: null,
      wali_type_value: undefined,
      wali_sex_type: null,
      wali_sex_type_value: undefined,
      fatherKtp: undefined,
      motherKtp: undefined,
      waliKtp: undefined,
      fatherPhone: undefined,
      motherPhone: undefined,
      waliPhone: undefined,
      fatherEmail: undefined,
      motherEmail: undefined,
      waliEmail: undefined,
      oldSchoolName: undefined,
      npsn: undefined,
      nis: undefined,
      nisn: undefined,
      nkk: undefined,
      nik: undefined,
      akta: undefined,
      mobilePhone: undefined,
      email: undefined,
      skhun: undefined,
      pesertaUN: undefined,
      ijazah: undefined,
      isKps: 0,
      kps: undefined,
      isKip: 0,
      kip: undefined,
      kipName: undefined,
      kks: undefined,
      pip_bank_account_number: undefined,
      pip_bank_account_name: undefined,
      height: 0,
      weight: 0,
      head: 0,
      homeDistance: 0,
      travelTime: 0,
      sex_type: null,
      sex_type_value: undefined,
      address: undefined,
      photo: undefined,
      birthPlace: undefined,
      birthDate: new Date(),
      birthOrder: 1,
      religion: null,
      religion_value: undefined,
      nationality: null,
      nationality_value: undefined,
      isAdopted: 0,
      stay_with: null,
      stay_with_value: undefined,
      siblings: 0,
      hoby: undefined,
      dream: undefined,
      medicalHistory: undefined,
      achievementHistory: undefined,
      fatherEmail: undefined,
      fatherJobCorporateName: undefined,
      fatherJobPositionName: undefined,
      fatherJobWorkAddress: undefined,
      fatherKtp: undefined,
      fatherName: undefined,
      fatherPhone: undefined,
      father_job_type: null,
      father_job_type_value: undefined,
      father_education_type: null,
      father_education_type_value: undefined,
      father_salary_type: null,
      father_salary_type_value: undefined,
      fatherBirthDate: new Date(),
      fatherBirthPlace: undefined,
      FatherAlive: {value:1, label:'Hidup'},
      isFatherAlive: 1,
      MotherAlive: {value:1, label:'Hidup'},
      isMotherAlive: 1,
      isHasWali: 0,
      motherEmail: undefined,
      motherJobCorporateName: undefined,
      motherJobPositionName: undefined,
      motherJobWorkAddress: undefined,
      motherKtp: undefined,
      motherName: undefined,
      motherPhone: undefined,
      mother_job_type: null,
      mother_job_type_value: undefined,
      mother_education_type: null,
      mother_education_type_value: undefined,
      mother_salary_type: null,
      mother_salary_type_value: undefined,
      motherBirthDate: new Date(),
      motherBirthPlace: undefined,
      waliEmail: undefined,
      waliJobCorporateName: undefined,
      waliJobPositionName: undefined,
      waliJobWorkAddress: undefined,
      waliKtp: undefined,
      waliName: undefined,
      waliPhone: undefined,
      wali_job_type: null,
      wali_job_type_value: undefined,
      wali_sex_type_value: undefined,
      wali_type_value: undefined,
      wali_education_type: null,
      wali_education_type_value: undefined,
      wali_salary_type: null,
      wali_salary_type_value: undefined,
      waliBirthDate: new Date(),
      waliBirthPlace: undefined,
      isPoor: 0,
      scholarship: undefined
    }

  }
  const [studentType, setstudentType] = useState('1');
  const [parentType, setparentType] = useState('1');
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [openDiscountDialog, setopenDiscountDialog] = useState(false)
  const [openStudentDialog, setopenStudentDialog] = useState(false)
  const [openParentDialog, setopenParentDialog] = useState(false)
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [nationalityOptions, setnationalityOptions] = useState([]);
  const [provinceOptions, setprovinceOptions] = useState([]);
  const [regencyOptions, setregencyOptions] = useState([]);
  const [districtOptions, setdistrictOptions] = useState([]);
  const [villageOptions, setvillageOptions] = useState([]);
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameParentKeyWord, setnameParentKeyWord] = useState('');
  const [billTypes, setbillTypes] = useState([]);

  const studentTypeChange = event => {
    setstudentType(event.target.value)
  };

  const parentTypeChange = event => {
    setparentType(event.target.value)
  };

  const addBillType = value => {
    setbillTypes([...billTypes, value])
    setopenDiscountDialog(false)
  };

  const deleteBillType = idx => {
    setbillTypes(
      billTypes.filter((item, index) => index !== idx)
    )
    setopenDiscountDialog(false)
  };


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (['isPoor', 'isKps', 'isKip', 'isAdopted', 'isHasWali'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'FatherAlive') {
      newState['isFatherAlive'] = value ? value.value : undefined
    }
    else if (fieldName === 'MotherAlive') {
      newState['isMotherAlive'] = value ? value.value : undefined
    }
    else if (fieldName === 'province') {
      newState['province_id'] = value ? value.value : undefined
      getRegencyOptions(value.value)
      newState['regency'] = null
      newState['regency_id'] = undefined
      newState['district'] = null
      newState['district_id'] = undefined
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'regency') {
      newState['regency_id'] = value ? value.value : undefined
      getDistrictOptions(value.value)
      newState['district'] = null
      newState['district_id'] = undefined
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'district') {
      newState['district_id'] = value ? value.value : undefined
      getVillageOptions(value.value)
      newState['village'] = null
      newState['village_id'] = undefined
    }
    else if (fieldName === 'village') {
      newState['village_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'stay_with') {
      newState['stay_with_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'religion') {
      newState['religion_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'nationality') {
      newState['nationality_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      newState['school_id'] = value.value
      newState['jenjang_id'] = value.jenjang_id
      getkelasOptions(value.jenjang_id)
    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'jurusan') {
      newState['jurusan_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'existing_student') {
      newState['existing_student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'existing_parent') {
      newState['existing_parent_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'father_job_type') {
      newState['father_job_type_value'] = value.value
    }
    else if (fieldName === 'mother_job_type') {
      newState['mother_job_type_value'] = value.value
    }
    else if (fieldName === 'wali_job_type') {
      newState['wali_job_type_value'] = value.value
    }
    else if (fieldName === 'father_education_type') {
      newState['father_education_type_value'] = value.value
    }
    else if (fieldName === 'mother_education_type') {
      newState['mother_education_type_value'] = value.value
    }
    else if (fieldName === 'wali_education_type') {
      newState['wali_education_type_value'] = value.value
    }
    else if (fieldName === 'father_salary_type') {
      newState['father_salary_type_value'] = value.value
    }
    else if (fieldName === 'mother_salary_type') {
      newState['mother_salary_type_value'] = value.value
    }
    else if (fieldName === 'wali_salary_type') {
      newState['wali_salary_type_value'] = value.value
    }
    else if (fieldName === 'wali_type') {
      newState['wali_type_value'] = value.value
    }
    else if (fieldName === 'wali_sex_type') {
      newState['wali_sex_type_value'] = value.value
    }

    if (fieldName === 'siblings') {
      newState[fieldName] = parseInt(value) || '0'
    } else {
      newState[fieldName] = value
    }


    newState[fieldName] = value
    setstate(newState)
  }

  const closeStudentDialog = () => {
    setopenStudentDialog(false)
  }

  const closeParentDialog = () => {
    setopenParentDialog(false)
  }

  const closeDiscountDialog = () => {
    setopenDiscountDialog(false)
  }

  const saveStudentType = () => {
    setopenStudentDialog(false)
    if (studentType === '1') {
      setopenParentDialog(true)
    }
    else {
      if (state.existing_student) {
        getStudentDetail(state.existing_student_id)
      }
    }

  }

  const saveExistingParent = () => {
    setopenParentDialog(false)
    if (state.existing_parent) {
      getParentDetail(state.existing_parent_id)
    }
  }



  const getnationalityOptions = async () => {
    const params = {
      group: 'nationality'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setnationalityOptions(parameterListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getprovinceOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.province, { params: params });
    if (response && response.data) {
      setprovinceOptions(generalListOptionMapper(response.data));
    }
  };

  const getRegencyOptions = async (province_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.provinceRegency.replace('id', province_id), { params: params });
    if (response && response.data) {
      setregencyOptions(generalListOptionMapper(response.data));
    }
  };

  const getDistrictOptions = async (regency_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.regencyDistrict.replace('id', regency_id), { params: params });
    if (response && response.data) {
      setdistrictOptions(generalListOptionMapper(response.data));
    }
  };

  const getVillageOptions = async (district_id) => {
    const params = {}
    const response = await axios.get(endpoint.wilayah.districtVillage.replace('id', district_id), { params: params });
    if (response && response.data) {
      setvillageOptions(generalListOptionMapper(response.data));
    }
  };

  const getParentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.parent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const handleInputChangeParent = (newValue) => {
    setnameParentKeyWord(newValue)
    return newValue;
  };

  const loadParentOptions = async (inputValue, callback) => {
    callback(await getParentOptions(inputValue))
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };


  const insert = async (payload) => {
    const response = await axios.post(endpoint.newStudent.root, payload)
    if (response && response.data) {
      showSuccess('save newStudent')
      getData()
      props.closeDialog()
    } else {
      showError('save newStudent')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.newStudent.root, payload)
    if (response && response.data) {
      showSuccess('save newStudent')
      getData()
      props.closeDialog()
    } else {
      showError('save newStudent')
    }
  }

  const submit = async () => {

    if (!state.school_id) {
      showError('Mohon pilih sekolah')
      return
    }

    if (!state.kelas_id) {
      showError('Mohon pilih kelas')
      return
    }

    if (state.isMotherAlive === 0 && state.isFatherAlive === 0 && state.isHasWali === 0) {
      showError('Mohon isi data orang tua')
      return
    }

    if (!state.fatherName || state.fatherName.trim().length === 0) {
      showError('Mohon isi nama ayah')
      return
    }


    if (!state.motherName || state.motherName.trim().length === 0) {
      showError('Mohon isi nama ibu')
      return
    }


    if (state.isHasWali === 1) {

      if (!state.wali_sex_type_value) {
        showError('Mohon pilih jenis kelamin wali')
        return
      }

      if (!state.wali_type_value) {
        showError('Mohon pilih tipe wali')
        return
      }

      if (!state.waliName || state.waliName.trim().length === 0) {
        showError('Mohon isi nama wali')
        return
      }

      if (!state.waliPhone || state.waliPhone.trim().length === 0) {
        showError('Mohon isi handphone wali')
        return
      }

      if (!state.wali_job_type) {
        showError('Mohon isi pekerjaan wali')
        return
      }
    }


    const params = {
      ...state,
      siblings: state.siblings ? parseInt(state.siblings) : 0,
      birthDate: state.birthDate ? format(state.birthDate, 'yyyy-MM-dd') : undefined,
      fatherBirthDate: state.fatherBirthDate ? format(state.fatherBirthDate, 'yyyy-MM-dd') : undefined,
      motherBirthDate: state.motherBirthDate ? format(state.motherBirthDate, 'yyyy-MM-dd') : undefined,
      waliBirthDate: state.waliBirthDate ? format(state.waliBirthDate, 'yyyy-MM-dd') : undefined,
      admin_employee_id: detail.id,
      billTypes: billTypes.length > 0 ? billTypes : undefined
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    const response = await axios.get(endpoint.newStudent.detail.replace('id', row.id), params)
    if (response && response.data) {
      let student = response.data
      let newState = {
        ...student,
        isHasWali: student.wali_type_value > 0 ? 1 : 0,
        wali_type: getwaliTypeObject(student.wali_type_value),
        school: generalOptionMapper(student.school),
        kelas: generalOptionMapper(student.kelas),
        jurusan: generalOptionMapper(student.jurusan),
        sex_type: getsexTypeObject(student.sex_type_value),
        stay_with: getstayWithObject(student.stay_with_value),
        birthDate: student.birthDate ? parse(student.birthDate, 'yyyy-MM-dd', new Date()) : null,
        religion: getreligionObject(student.religion_value),
        nationality: parameterOptionMapper(student.nationality),
        province: generalOptionMapper(student.province),
        regency: generalOptionMapper(student.regency),
        district: generalOptionMapper(student.district),
        village: generalOptionMapper(student.village),
        vehicle_type: getvehicleTypeObject(student.vehicle_type_value),
      }

      if (student.new_parent) {
        newState = {
          ...newState,
          fatherEmail: student.new_parent.fatherEmail,
          fatherJobCorporateName: student.new_parent.fatherJobCorporateName,
          fatherJobPositionName: student.new_parent.fatherJobPositionName,
          fatherJobWorkAddress: student.new_parent.fatherJobWorkAddress,
          fatherKtp: student.new_parent.fatherKtp,
          fatherName: student.new_parent.fatherName,
          fatherPhone: student.new_parent.fatherPhone,
          father_job_type: getjobTypeObject(student.new_parent.father_job_type_value),
          father_job_type_value: student.new_parent.father_job_type_value,
          father_education_type: geteducationTypeObject(student.new_parent.father_education_type_value),
          father_education_type_value: student.new_parent.father_education_type_value,
          father_salary_type: getsalaryTypeObject(student.new_parent.father_salary_type_value),
          father_salary_type_value: student.new_parent.father_salary_type_value,
          fatherBirthDate: student.new_parent.fatherBirthDate ? parse(student.new_parent.fatherBirthDate, 'yyyy-MM-dd', new Date()) : null,
          fatherBirthPlace: student.new_parent.fatherBirthPlace,
          isFatherAlive: student.new_parent.isFatherAlive,
          isHasWali: student.new_parent.isHasWali,
          isMotherAlive: student.new_parent.isMotherAlive,
          motherEmail: student.new_parent.motherEmail,
          motherJobCorporateName: student.new_parent.motherJobCorporateName,
          motherJobPositionName: student.new_parent.motherJobPositionName,
          motherJobWorkAddress: student.new_parent.motherJobWorkAddress,
          motherKtp: student.new_parent.motherKtp,
          motherName: student.new_parent.motherName,
          motherPhone: student.new_parent.motherPhone,
          mother_job_type: getjobTypeObject(student.new_parent.mother_job_type_value),
          mother_job_type_value: student.new_parent.mother_job_type_value,
          mother_education_type: geteducationTypeObject(student.new_parent.mother_education_type_value),
          mother_education_type_value: student.new_parent.mother_education_type_value,
          mother_salary_type: getsalaryTypeObject(student.new_parent.mother_salary_type_value),
          mother_salary_type_value: student.new_parent.mother_salary_type_value,
          motherBirthDate: student.new_parent.motherBirthDate ? parse(student.new_parent.motherBirthDate, 'yyyy-MM-dd', new Date()) : null,
          motherBirthPlace: student.new_parent.motherBirthPlace,
          waliEmail: student.new_parent.waliEmail,
          waliJobCorporateName: student.new_parent.waliJobCorporateName,
          waliJobPositionName: student.new_parent.waliJobPositionName,
          waliJobWorkAddress: student.new_parent.waliJobWorkAddress,
          waliKtp: student.new_parent.waliKtp,
          waliName: student.new_parent.waliName,
          waliPhone: student.new_parent.waliPhone,
          wali_job_type: getjobTypeObject(student.new_parent.wali_job_type_value),
          wali_job_type_value: student.new_parent.wali_job_type_value,
          wali_sex_type: getsexTypeObject(student.new_parent.wali_sex_type_value),
          wali_sex_type_value: student.new_parent.wali_sex_type_value,
          wali_type: getwaliTypeObject(student.new_parent.wali_type_value),
          wali_type_value: student.new_parent.wali_type_value,
          wali_education_type: geteducationTypeObject(student.new_parent.wali_education_type_value),
          wali_education_type_value: student.new_parent.wali_education_type_value,
          wali_salary_type: getsalaryTypeObject(student.new_parent.wali_salary_type_value),
          wali_salary_type_value: student.new_parent.wali_salary_type_value,
          waliBirthDate: student.new_parent.waliBirthDate ? parse(student.new_parent.waliBirthDate, 'yyyy-MM-dd', new Date()) : null,
          waliBirthPlace: student.new_parent.waliBirthPlace,
          FatherAlive: getlifeStatusObject(student.new_parent.isFatherAlive),
          MotherAlive: getlifeStatusObject(student.new_parent.isMotherAlive),
          
        }

      }

      if (student.father_parent) {
        newState = {
          ...newState,
          fatherEmail: student.father_parent.email,
          fatherJobCorporateName: student.father_parent.jobCorporateName,
          fatherJobPositionName: student.father_parent.jobPositionName,
          fatherJobWorkAddress: student.father_parent.jobWorkAddress,
          fatherKtp: student.father_parent.ktp,
          fatherName: student.father_parent.name,
          fatherPhone: student.father_parent.mobilePhone,
          father_job_type: getjobTypeObject(student.father_parent.job_type_value),
          father_job_type_value: student.father_parent.job_type_value,
          isFatherAlive: student.father_parent.isAlive,
          father_education_type: geteducationTypeObject(student.father_parent.education_type_value),
          father_education_type_value: student.father_parent.education_type_value,
          father_salary_type: getsalaryTypeObject(student.father_parent.salary_type_value),
          father_salary_type_value: student.father_parent.salary_type_value,
          fatherBirthDate: student.father_parent.birthDate ? parse(student.father_parent.birthDate, 'yyyy-MM-dd', new Date()) : null,
          fatherBirthPlace: student.father_parent.birthPlace,
          FatherAlive: getlifeStatusObject(student.father_parent.isFatherAlive),
        }

      }

      if (student.mother_parent) {
        newState = {
          ...newState,
          MotherAlive: getlifeStatusObject(student.mother_parent.isMotherAlive),
          isMotherAlive: student.mother_parent.isAlive,
          motherEmail: student.mother_parent.email,
          motherJobCorporateName: student.mother_parent.jobCorporateName,
          motherJobPositionName: student.mother_parent.jobPositionName,
          motherJobWorkAddress: student.mother_parent.jobWorkAddress,
          motherKtp: student.mother_parent.ktp,
          motherName: student.mother_parent.name,
          motherPhone: student.mother_parent.mobilePhone,
          mother_job_type: getjobTypeObject(student.mother_parent.job_type_value),
          mother_job_type_value: student.mother_parent.job_type_value,
          mother_education_type: geteducationTypeObject(student.mother_parent.education_type_value),
          mother_education_type_value: student.mother_parent.education_type_value,
          mother_salary_type: getsalaryTypeObject(student.mother_parent.salary_type_value),
          mother_salary_type_value: student.mother_parent.salary_type_value,
          motherBirthDate: student.mother_parent.birthDate ? parse(student.mother_parent.birthDate, 'yyyy-MM-dd', new Date()) : null,
          motherBirthPlace: student.mother_parent.birthPlace,
        }

      }

      if (student.wali_parent) {
        newState = {
          ...newState,
          isHasWali: 1,
          waliEmail: student.wali_parent.email,
          waliJobCorporateName: student.wali_parent.jobCorporateName,
          waliJobPositionName: student.wali_parent.jobPositionName,
          waliJobWorkAddress: student.wali_parent.jobWorkAddress,
          waliKtp: student.wali_parent.ktp,
          waliName: student.wali_parent.name,
          waliPhone: student.wali_parent.mobilePhone,
          wali_job_type: getjobTypeObject(student.wali_parent.job_type_value),
          wali_job_type_value: student.wali_parent.job_type_value,
          wali_sex_type_value: student.wali_parent.sex_type_value,
          wali_type_value: student.wali_parent.wali_type_value,
          wali_education_type: geteducationTypeObject(student.wali_parent.education_type_value),
          wali_education_type_value: student.wali_parent.education_type_value,
          wali_salary_type: getsalaryTypeObject(student.wali_parent.salary_type_value),
          wali_salary_type_value: student.wali_parent.salary_type_value,
          waliBirthDate: student.wali_parent.birthDate ? parse(student.wali_parent.birthDate, 'yyyy-MM-dd', new Date()) : null,
          waliBirthPlace: student.wali_parent.birthPlace,
        }

      }

      setstate(newState)

      getkelasOptions(student.jenjang_id)

    }
  }

  const getStudentDetail = async (student_id) => {

    let params = {}
    const response = await axios.get(endpoint.student.detail.replace('id', student_id), params)
    if (response && response.data) {
      let student = response.data
      let newState = {
        ...student,
        isContinue: 1,
        oldNis: student.nis,
        oldSchoolName: student.school.name,
        school: null,
        school_id: undefined,
        jenjang: null,
        jenjang_id: undefined,
        province: generalOptionMapper(student.province),
        regency: generalOptionMapper(student.regency),
        district: generalOptionMapper(student.district),
        village: generalOptionMapper(student.village),
        nationality: parameterOptionMapper(student.nationality),
        sex_type: getsexTypeObject(student.sex_type_value),
        religion: getreligionObject(student.religion_value),
        stay_with: getstayWithObject(student.stay_with_value),
        birthDate: parse(student.birthDate, 'yyyy-MM-dd', new Date()),
        photo: `${api.url}/${student.photo}`
      }


      if (student.father_parent) {
        newState = {
          ...newState,
          fatherEmail: student.father_parent.email,
          fatherJobCorporateName: student.father_parent.jobCorporateName,
          fatherJobPositionName: student.father_parent.jobPositionName,
          fatherJobWorkAddress: student.father_parent.jobWorkAddress,
          fatherKtp: student.father_parent.ktp,
          fatherName: student.father_parent.name,
          fatherPhone: student.father_parent.mobilePhone,
          father_job_type: getjobTypeObject(student.father_parent.job_type_value),
          father_job_type_value: student.father_parent.job_type_value,
          isFatherAlive: 1,
          father_education_type: geteducationTypeObject(student.father_parent.education_type_value),
          father_education_type_value: student.father_parent.education_type_value,
          father_salary_type: getsalaryTypeObject(student.father_parent.salary_type_value),
          father_salary_type_value: student.father_parent.salary_type_value,
          fatherBirthDate: student.father_parent.birthDate ? parse(student.father_parent.birthDate, 'yyyy-MM-dd', new Date()) : null,
          fatherBirthPlace: student.father_parent.fatherBirthPlace,
        }

      }

      if (student.mother_parent) {
        newState = {
          ...newState,
          isMotherAlive: 1,
          motherEmail: student.mother_parent.email,
          motherJobCorporateName: student.mother_parent.jobCorporateName,
          motherJobPositionName: student.mother_parent.jobPositionName,
          motherJobWorkAddress: student.mother_parent.jobWorkAddress,
          motherKtp: student.mother_parent.ktp,
          motherName: student.mother_parent.name,
          motherPhone: student.mother_parent.mobilePhone,
          mother_job_type: getjobTypeObject(student.mother_parent.job_type_value),
          mother_job_type_value: student.mother_parent.job_type_value,
          mother_education_type: geteducationTypeObject(student.mother_parent.education_type_value),
          mother_education_type_value: student.mother_parent.education_type_value,
          mother_salary_type: getsalaryTypeObject(student.mother_parent.salary_type_value),
          mother_salary_type_value: student.mother_parent.salary_type_value,
          motherBirthDate: student.mother_parent.birthDate ? parse(student.mother_parent.birthDate, 'yyyy-MM-dd', new Date()) : null,
          motherBirthPlace: student.mother_parent.motherBirthPlace,
        }

      }

      if (student.wali_parent) {
        newState = {
          ...newState,
          isHasWali: 1,
          waliEmail: student.wali_parent.email,
          waliJobCorporateName: student.wali_parent.jobCorporateName,
          waliJobPositionName: student.wali_parent.jobPositionName,
          waliJobWorkAddress: student.wali_parent.jobWorkAddress,
          waliKtp: student.wali_parent.ktp,
          waliName: student.wali_parent.name,
          waliPhone: student.wali_parent.mobilePhone,
          wali_sex_type_value: student.wali_parent.sex_type_value,
          wali_type_value: student.wali_parent.wali_type_value,
          wali_job_type: getjobTypeObject(student.wali_parent.job_type_value),
          wali_job_type_value: student.wali_parent.job_type_value,
          wali_education_type: geteducationTypeObject(student.wali_parent.education_type_value),
          wali_education_type_value: student.wali_parent.education_type_value,
          wali_salary_type: getsalaryTypeObject(student.wali_parent.salary_type_value),
          wali_salary_type_value: student.wali_parent.salary_type_value,
          waliBirthDate: student.wali_parent.birthDate ? parse(student.wali_parent.birthDate, 'yyyy-MM-dd', new Date()) : null,
          waliBirthPlace: student.wali_parent.waliBirthPlace,
        }

      }

      setstate(newState)

    }

  }

  const getParentDetail = async (parent_id) => {

    let params = {}
    const response = await axios.get(endpoint.parent.detail.replace('id', parent_id), params)
    if (response && response.data) {
      let parent = response.data

      if (parent) {
        if (parent.parent_type_value === 1 && parent.sex_type_value === 1) {
          setstate({
            ...state,
            father_parent_id: parent_id,
            fatherEmail: parent.email,
            fatherJobCorporateName: parent.jobCorporateName,
            fatherJobPositionName: parent.jobPositionName,
            fatherJobWorkAddress: parent.jobWorkAddress,
            fatherKtp: parent.ktp,
            fatherName: parent.name,
            fatherBirthPlace: parent.birthPlace,
            fatherPhone: parent.mobilePhone,
            father_job_type_value: parent.job_type_value,
            isFatherAlive: 1,

          })
        }
        else if (parent.parent_type_value === 1 && parent.sex_type_value === 2) {
          setstate({
            ...state,
            mother_parent_id: parent_id,
            motherEmail: parent.email,
            motherJobCorporateName: parent.jobCorporateName,
            motherJobPositionName: parent.jobPositionName,
            motherJobWorkAddress: parent.jobWorkAddress,
            motherKtp: parent.ktp,
            motherName: parent.name,
            motherBirthPlace: parent.birthPlace,
            motherPhone: parent.mobilePhone,
            mother_job_type_value: parent.job_type_value,
            isMotherAlive: 1,

          })
        } else {
          setstate({
            ...state,
            wali_parent_id: parent_id,
            waliEmail: parent.email,
            waliJobCorporateName: parent.jobCorporateName,
            waliJobPositionName: parent.jobPositionName,
            waliJobWorkAddress: parent.jobWorkAddress,
            waliKtp: parent.ktp,
            waliName: parent.name,
            waliBirthPlace: parent.birthPlace,
            waliPhone: parent.mobilePhone,
            wali_job_type_value: parent.job_type_value,
            isHasWali: 1,

          })
        }
      }


    }

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getprovinceOptions()
      getnationalityOptions()
      getschoolOptions()
      getjurusanOptions()
      if (pageAction === 'CREATE') {
        setopenStudentDialog(true)
      }
    }

  }, [pageAction])


  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])




  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid container justifyContent='flex-end'>
            <Conditional condition={pageAction === "READ"}>
              <Protected allowedCodes={['EDIT']}>
                <Button onClick={() => setpageAction("EDIT")} color="primary">
                  Edit
                </Button>
              </Protected>
            </Conditional>
            <Button onClick={props.closeDialog} color="secondary">
              Kembali
            </Button>
            <Conditional condition={pageAction !== "READ"}>
              <Button variant="contained" onClick={submit} color="primary">
                Simpan
              </Button>
            </Conditional>
          </Grid>
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Pendaftar online
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.new_parent && state.new_parent.user && state.new_parent.user.username} />
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={pageAction === 'CREATE'}>
            <Grid container sx={{ mt: 2 }}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Harga khusus
                </Grid>
                <Grid item sm={8} xs={12} container spacing={1}>

                  <Grid item sm={8} xs={12}>
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="a dense table">
                        <TableBody>
                          {billTypes.map((row, index) => (
                            <TableRow key={row.bill_type_id}>

                              <TableCell>
                                <IconButton
                                  color="secondary"
                                  onClick={() => deleteBillType(index)}
                                  size='small'
                                  aria-label='delete'
                                  style={{ margin: 0 }}>
                                  <Close fontSize='inherit' />
                                </IconButton>
                              </TableCell>

                              <TableCell>
                                {row.bill_type.name}
                              </TableCell>

                              <TableCell>
                                {row.special_type && row.special_type.label}
                              </TableCell>

                              <TableCell>
                                {row.discount_type && row.discount_type.label}
                              </TableCell>

                              <TableCell>
                                {row.amount}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Button variant="contained" onClick={() => setopenDiscountDialog(true)} color="warning">
                      Tambah harga khusus
                    </Button>
                  </Grid>
                </Grid>


              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={studentType === '2'}>
            <Grid container sx={{ mt: 2 }}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  NIS lama
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.oldNis} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DebouncedTextField
                      margin='dense'
                      id='oldNis'
                      fullWidth
                      value={state.oldNis}
                      onChange={(e) => handleChange('oldNis', e)}
                    />
                  </Conditional>

                </Grid>
              </Grid>
            </Grid>
          </Conditional>


          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor pendaftaran
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.registration_number} />
              </Grid>
            </Grid>
          </Conditional>



          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                {`Nama calon ${process.env.REACT_APP_STUDENT}`}
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='name'
                    fullWidth
                    value={state.name}
                    onChange={(e) => handleChange('name', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsexTypeLabel(state.sex_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.sex_type}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                NIK anak
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.nik} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='nik'
                    fullWidth
                    value={state.nik}
                    onChange={(e) => handleChange('nik', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor akta lahir
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.akta} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='akta'
                    fullWidth
                    value={state.akta}
                    onChange={(e) => handleChange('akta', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor handphone
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.mobilePhone} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='mobilePhone'
                    fullWidth
                    value={state.mobilePhone}
                    onChange={(e) => handleChange('mobilePhone', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Email
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.email} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='email'
                    fullWidth
                    value={state.email}
                    onChange={(e) => handleChange('email', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor SKHUN
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.skhun} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='skhun'
                    fullWidth
                    value={state.skhun}
                    onChange={(e) => handleChange('skhun', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor peserta Ujian Nasional
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.pesertaUN} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='pesertaUN'
                    fullWidth
                    value={state.pesertaUN}
                    onChange={(e) => handleChange('pesertaUN', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor ijazah terakhir
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.ijazah} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='ijazah'
                    fullWidth
                    value={state.ijazah}
                    onChange={(e) => handleChange('ijazah', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tinggi badan (cm)
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.height} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='height'
                    fullWidth
                    value={state.height}
                    onChange={(e) => handleChange('height', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Berat badan (kg)
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.weight} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='weight'
                    fullWidth
                    value={state.weight}
                    onChange={(e) => handleChange('weight', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Lingkar kepala (cm)
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.head} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='head'
                    fullWidth
                    value={state.head}
                    onChange={(e) => handleChange('head', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tinggal bersama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getstayWithLabel(state.stay_with_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='stay_with'
                  placeholder='Pilih keluarga'
                  options={stayWithOptions}
                  value={state.stay_with}
                  onChange={(e) => handleChange('stay_with', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Anak adopsi
            </Grid>
            <Grid item sm={8} xs={2}  >
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isAdopted === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isAdopted === 1}
                  onChange={(e) => handleChange("isAdopted", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah saudara kandung
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.siblings && state.siblings.toString()} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='siblings'
                  fullWidth
                  type="number"
                  value={state.siblings && state.siblings.toString()}
                  onChange={(e) => handleChange('siblings', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Provinsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.province && state.province.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='province'
                  placeholder='Pilih provinsi'
                  options={provinceOptions}
                  value={state.province}
                  onChange={(e) => handleChange('province', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota / Kabupaten
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.regency && state.regency.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='regency'
                  placeholder='Pilih kota kabupaten'
                  options={regencyOptions}
                  value={state.regency}
                  onChange={(e) => handleChange('regency', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kecamatan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.district && state.district.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='district'
                  placeholder='Pilih kecamatan'
                  options={districtOptions}
                  value={state.district}
                  onChange={(e) => handleChange('district', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Desa / kelurahan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.village && state.village.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='village'
                  placeholder='Pilih desa kelurahan'
                  options={villageOptions}
                  value={state.village}
                  onChange={(e) => handleChange('village', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                RW
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.rw} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='rw'
                    fullWidth
                    value={state.rw}
                    onChange={(e) => handleChange('rw', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                RT
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.rt} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='rt'
                    fullWidth
                    value={state.rt}
                    onChange={(e) => handleChange('rt', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kode pos
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.postalCode} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='postalCode'
                    fullWidth
                    value={state.postalCode}
                    onChange={(e) => handleChange('postalCode', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.address} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='address'
                  fullWidth
                  value={state.address}
                  onChange={(e) => handleChange('address', e)}
                  multiline={true}
                  rows={3}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jarak sekolah dari rumah (km)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.homeDistance} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='homeDistance'
                  fullWidth
                  type="number"
                  value={state.homeDistance}
                  onChange={(e) => handleChange('homeDistance', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Transportasi ke sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getvehicleTypeLabel(state.vehicle_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='vehicle_type'
                  placeholder='Pilih moda transportasi'
                  options={vehicleTypeOptions}
                  value={state.vehicle_type}
                  onChange={(e) => handleChange('vehicle_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Lama perjalanan ke sekolah (menit)
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.travelTime} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='travelTime'
                  fullWidth
                  type="number"
                  value={state.travelTime}
                  onChange={(e) => handleChange('travelTime', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Anak ke
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthOrder} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='birthOrder'
                  fullWidth
                  type="number"
                  value={state.birthOrder}
                  onChange={(e) => handleChange('birthOrder', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota kelahiran
            </Grid>
            <Grid item sm={8} xs={12}>

              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthPlace} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='birthPlace'
                  fullWidth
                  value={state.birthPlace}
                  onChange={(e) => handleChange('birthPlace', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthDate && format(state.birthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.birthDate}
                      onChange={(e) => handleChange('birthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Agama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getreligionLabel(state.religion_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='religion'
                  placeholder='Pilih agama'
                  options={religionOptions}
                  value={state.religion}
                  onChange={(e) => handleChange('religion', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Warga negara
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nationality && state.nationality.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='nationality'
                  placeholder='Pilih Negara'
                  options={nationalityOptions}
                  value={state.nationality}
                  onChange={(e) => handleChange('nationality', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.school && state.school.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='school'
                  placeholder='Pilih sekolah'
                  options={schoolOptions}
                  value={state.school}
                  onChange={(e) => handleChange('school', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kelas
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.kelas && state.kelas.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='kelas'
                  placeholder='Pilih Kelas'
                  options={kelasOptions}
                  value={state.kelas}
                  onChange={(e) => handleChange('kelas', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jurusan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.jurusan && state.jurusan.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='jurusan'
                  placeholder='Pilih jurusan'
                  options={jurusanOptions}
                  value={state.jurusan}
                  onChange={(e) => handleChange('jurusan', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Sekolah asal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.oldSchoolName} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='oldSchoolName'
                    fullWidth
                    value={state.oldSchoolName}
                    onChange={(e) => handleChange('oldSchoolName', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                NPSN sekolah asal
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.npsn} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='npsn'
                    fullWidth
                    value={state.npsn}
                    onChange={(e) => handleChange('npsn', e)}
                  />
                </Conditional>

              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor induk siswa nasional
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.nisn} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='nisn'
                    fullWidth
                    value={state.nisn}
                    onChange={(e) => handleChange('nisn', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nomor kartu keluarga
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.nkk} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='nkk'
                    fullWidth
                    value={state.nkk}
                    onChange={(e) => handleChange('nkk', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Hobi
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.hoby} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='hoby'
                    fullWidth
                    value={state.hoby}
                    onChange={(e) => handleChange('hoby', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Cita - cita
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.dream} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='dream'
                    fullWidth
                    value={state.dream}
                    onChange={(e) => handleChange('dream', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Riwayat penyakit
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.medicalHistory} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='medicalHistory'
                    fullWidth
                    value={state.medicalHistory}
                    onChange={(e) => handleChange('medicalHistory', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Prestasi sebelumnya
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.achievementHistory} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='achievementHistory'
                    fullWidth
                    value={state.achievementHistory}
                    onChange={(e) => handleChange('achievementHistory', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kurang mampu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <Checkbox
                  checked={state.isPoor === 1}
                />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Checkbox
                  checked={state.isPoor === 1}
                  onChange={(e) => handleChange("isPoor", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          {
            state.isPoor === 1 &&
            <>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Beasiswa
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.scholarship} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <DebouncedTextField
                      margin="dense"
                      id="scholarship"
                      fullWidth
                      value={state.scholarship}
                      onChange={(e) => handleChange("scholarship", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Peserta KPS
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <Checkbox
                      checked={state.isKps === 1}
                    />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Checkbox
                      checked={state.isKps === 1}
                      onChange={(e) => handleChange("isKps", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
              {
                state.isKps === 1 &&
                <>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                      <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                        Nomor peserta KPS
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Conditional condition={pageAction === 'READ'}>
                          <ReadOnlyTextField value={state.kps} />
                        </Conditional>
                        <Conditional condition={pageAction !== 'READ'}>
                          <DebouncedTextField
                            margin='dense'
                            id='kps'
                            fullWidth
                            value={state.kps}
                            onChange={(e) => handleChange('kps', e)}
                          />
                        </Conditional>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                      <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                        Nomor Kartu Keluarga Sejahtera
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Conditional condition={pageAction === 'READ'}>
                          <ReadOnlyTextField value={state.kipName} />
                        </Conditional>
                        <Conditional condition={pageAction !== 'READ'}>
                          <DebouncedTextField
                            margin='dense'
                            id='kipName'
                            fullWidth
                            value={state.kipName}
                            onChange={(e) => handleChange('kipName', e)}
                          />
                        </Conditional>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }

              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Menerima Kartu Indonesia Pintar
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <Checkbox
                      checked={state.isKip === 1}
                    />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Checkbox
                      checked={state.isKip === 1}
                      onChange={(e) => handleChange("isKip", e)}
                    />
                  </Conditional>
                </Grid>
              </Grid>
              {
                state.isKip === 1 &&
                <>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                      <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                        Nomor Kartu Indonesia Pintar
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Conditional condition={pageAction === 'READ'}>
                          <ReadOnlyTextField value={state.kip} />
                        </Conditional>
                        <Conditional condition={pageAction !== 'READ'}>
                          <DebouncedTextField
                            margin='dense'
                            id='kip'
                            fullWidth
                            value={state.kip}
                            onChange={(e) => handleChange('kip', e)}
                          />
                        </Conditional>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 2 }}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                      <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                        Nama di Kartu Indonesia Pintar
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Conditional condition={pageAction === 'READ'}>
                          <ReadOnlyTextField value={state.kipName} />
                        </Conditional>
                        <Conditional condition={pageAction !== 'READ'}>
                          <DebouncedTextField
                            margin='dense'
                            id='kipName'
                            fullWidth
                            value={state.kipName}
                            onChange={(e) => handleChange('kipName', e)}
                          />
                        </Conditional>
                      </Grid>
                    </Grid>
                  </Grid>



                  <Grid container sx={{ mt: 2 }}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                      <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                        Nomor rekening PIP
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Conditional condition={pageAction === 'READ'}>
                          <ReadOnlyTextField value={state.pip_bank_account_number} />
                        </Conditional>
                        <Conditional condition={pageAction !== 'READ'}>
                          <DebouncedTextField
                            margin='dense'
                            id='pip_bank_account_number'
                            fullWidth
                            value={state.pip_bank_account_number}
                            onChange={(e) => handleChange('pip_bank_account_number', e)}
                          />
                        </Conditional>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 2 }}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                      <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                        Nama pemilik rekening PIP
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Conditional condition={pageAction === 'READ'}>
                          <ReadOnlyTextField value={state.pip_bank_account_name} />
                        </Conditional>
                        <Conditional condition={pageAction !== 'READ'}>
                          <DebouncedTextField
                            margin='dense'
                            id='pip_bank_account_name'
                            fullWidth
                            value={state.pip_bank_account_name}
                            onChange={(e) => handleChange('pip_bank_account_name', e)}
                          />
                        </Conditional>
                      </Grid>
                    </Grid>
                  </Grid>

                </>
              }

            </>

          }

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isFatherAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='FatherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.FatherAlive}
                  onChange={(e) => handleChange('FatherAlive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nama ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.fatherName} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='fatherName'
                    fullWidth
                    value={state.fatherName}
                    onChange={(e) => handleChange('fatherName', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Telepon ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.fatherPhone} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='fatherPhone'
                    fullWidth
                    value={state.fatherPhone}
                    onChange={(e) => handleChange('fatherPhone', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Ktp ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.fatherKtp} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='fatherKtp'
                    fullWidth
                    value={state.fatherKtp}
                    onChange={(e) => handleChange('fatherKtp', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                e-mail ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.fatherEmail} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='fatherEmail'
                    fullWidth
                    value={state.fatherEmail}
                    onChange={(e) => handleChange('fatherEmail', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherBirthDate && format(state.fatherBirthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.fatherBirthDate}
                      onChange={(e) => handleChange('fatherBirthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.fatherBirthPlace} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='fatherBirthPlace'
                    fullWidth
                    value={state.fatherBirthPlace}
                    onChange={(e) => handleChange('fatherBirthPlace', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.father_education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='father_education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.father_education_type}
                  onChange={(e) => handleChange('father_education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getjobTypeLabel(state.father_job_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='father_job_type'
                  placeholder='Pilih jenis pekerjaan'
                  options={jobTypeOptions}
                  value={state.father_job_type}
                  onChange={(e) => handleChange('father_job_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherJobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherJobCorporateName"
                  fullWidth
                  value={state.fatherJobCorporateName}
                  onChange={(e) => handleChange("fatherJobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherJobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherJobPositionName"
                  fullWidth
                  value={state.fatherJobPositionName}
                  onChange={(e) => handleChange("fatherJobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherJobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherJobWorkAddress"
                  fullWidth
                  value={state.fatherJobWorkAddress}
                  onChange={(e) => handleChange("fatherJobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.father_salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='father_salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.father_salary_type}
                  onChange={(e) => handleChange('father_salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isMotherAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='MotherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.MotherAlive}
                  onChange={(e) => handleChange('MotherAlive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nama ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.motherName} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='motherName'
                    fullWidth
                    value={state.motherName}
                    onChange={(e) => handleChange('motherName', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Telepon ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.motherPhone} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='motherPhone'
                    fullWidth
                    value={state.motherPhone}
                    onChange={(e) => handleChange('motherPhone', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Ktp ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.motherKtp} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='motherKtp'
                    fullWidth
                    value={state.motherKtp}
                    onChange={(e) => handleChange('motherKtp', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                e-mail ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.motherEmail} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='motherEmail'
                    fullWidth
                    value={state.motherEmail}
                    onChange={(e) => handleChange('motherEmail', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherBirthDate && format(state.motherBirthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.motherBirthDate}
                      onChange={(e) => handleChange('motherBirthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.motherBirthPlace} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='motherBirthPlace'
                    fullWidth
                    value={state.motherBirthPlace}
                    onChange={(e) => handleChange('motherBirthPlace', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.mother_education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='mother_education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.mother_education_type}
                  onChange={(e) => handleChange('mother_education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getjobTypeLabel(state.mother_job_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='father_job_type'
                  placeholder='Pilih jenis pekerjaan'
                  options={jobTypeOptions}
                  value={state.mother_job_type}
                  onChange={(e) => handleChange('mother_job_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherJobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherJobCorporateName"
                  fullWidth
                  value={state.motherJobCorporateName}
                  onChange={(e) => handleChange("motherJobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherJobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherJobPositionName"
                  fullWidth
                  value={state.motherJobPositionName}
                  onChange={(e) => handleChange("motherJobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherJobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherJobWorkAddress"
                  fullWidth
                  value={state.motherJobWorkAddress}
                  onChange={(e) => handleChange("motherJobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.mother_salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='mother_salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.mother_salary_type}
                  onChange={(e) => handleChange('mother_salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Ada wali
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isHasWali === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isHasWali === 1}
                  onChange={(e) => handleChange("isHasWali", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getwaliTypeLabel(state.wali_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_type'
                  placeholder='Pilih tipe wali'
                  options={waliTypeOptions}
                  value={state.wali_type}
                  onChange={(e) => handleChange('wali_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsexTypeLabel(state.wali_sex_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.wali_sex_type}
                  onChange={(e) => handleChange('wali_sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='waliName'
                  fullWidth
                  value={state.waliName}
                  onChange={(e) => handleChange('waliName', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Telepon wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.waliPhone} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='waliPhone'
                    fullWidth
                    value={state.waliPhone}
                    onChange={(e) => handleChange('waliPhone', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Ktp wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.waliKtp} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='waliKtp'
                    fullWidth
                    value={state.waliKtp}
                    onChange={(e) => handleChange('waliKtp', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                e-mail wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.waliEmail} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='waliEmail'
                    fullWidth
                    value={state.waliEmail}
                    onChange={(e) => handleChange('waliEmail', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliBirthDate && format(state.waliBirthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.waliBirthDate}
                      onChange={(e) => handleChange('waliBirthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kota kelahiran wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.waliBirthPlace} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='waliBirthPlace'
                    fullWidth
                    value={state.waliBirthPlace}
                    onChange={(e) => handleChange('waliBirthPlace', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.wali_education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.wali_education_type}
                  onChange={(e) => handleChange('wali_education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getjobTypeLabel(state.wali_job_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_job_type'
                  placeholder='Pilih jenis pekerjaan'
                  options={jobTypeOptions}
                  value={state.wali_job_type}
                  onChange={(e) => handleChange('wali_job_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliJobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliJobCorporateName"
                  fullWidth
                  value={state.waliJobCorporateName}
                  onChange={(e) => handleChange("waliJobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliJobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliJobPositionName"
                  fullWidth
                  value={state.waliJobPositionName}
                  onChange={(e) => handleChange("waliJobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliJobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliJobWorkAddress"
                  fullWidth
                  value={state.waliJobWorkAddress}
                  onChange={(e) => handleChange("waliJobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.wali_salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.wali_salary_type}
                  onChange={(e) => handleChange('wali_salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>

    <Dialog
      open={openStudentDialog}
      maxWidth='sm'
      onClose={closeStudentDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <Grid container sx={{ p: 2 }}>
          <Grid container>
            <RadioGroup
              row
              aria-labelledby="studentType"
              defaultValue='1'
              name="studentType"
              value={studentType}
              onChange={studentTypeChange}
            >

              <FormControlLabel value='1' control={<Radio />} label={`${process.env.REACT_APP_STUDENT} baru`} />
              <FormControlLabel value='2' control={<Radio />} label={`${process.env.REACT_APP_STUDENT} lama`} />
            </RadioGroup>
          </Grid>
          <Conditional condition={studentType === '2'}>
            <Grid container alignContent='flex-start' sx={{ height: 200 }}>
              <AsyncSelect
                isClearable={true}
                cacheOptions
                placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                value={state.existing_student}
                loadOptions={loadStudentOptions}
                onInputChange={handleInputChangeStudent}
                onChange={(e) => handleChange('existing_student', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Conditional>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={saveStudentType} color="primary">
          Simpan
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openParentDialog}
      maxWidth='sm'
      onClose={closeParentDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <Grid container sx={{ p: 2 }}>
          <Grid container>
            <RadioGroup
              row
              aria-labelledby="parentType"
              defaultValue='1'
              name="parentType"
              value={parentType}
              onChange={parentTypeChange}
            >

              <FormControlLabel value='1' control={<Radio />} label="Orang tua baru" />
              <FormControlLabel value='2' control={<Radio />} label="Orang tua lama" />
            </RadioGroup>
          </Grid>
          <Conditional condition={parentType === '2'}>
            <Grid container alignContent='flex-start' sx={{ height: 200 }}>
              <AsyncSelect
                isClearable={true}
                cacheOptions
                placeholder="Nama orang tua..."
                value={state.existing_parent}
                loadOptions={loadParentOptions}
                onInputChange={handleInputChangeParent}
                onChange={(e) => handleChange('existing_parent', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Conditional>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={saveExistingParent} color="primary">
          Simpan
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openDiscountDialog}
      maxWidth='sm'
      onClose={closeDiscountDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <SelectBillTypeForm
        student={null}
        onSubmit={addBillType}
        closeDialog={closeDiscountDialog}
      />
    </Dialog>

  </>;
}

export default NewStudentAdminDetail;

