import { useDispatch, useSelector } from 'react-redux';
import { Icon } from "@iconify/react";
import close from "@iconify/icons-ant-design/close-circle-filled";
// redux
import { startLoading, stopLoading, setFavoriteMenus, setActiveMenu, setPreviousMenu, setMenuActions, setActiveForms, showSuccessSnackbar, showErrorSnackbar, showInfoSnackbar, hideSnackbar, setDetailId, addFavoriteMenu, deleteFavoriteMenu } from 'redux/slices/ui';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import useAuth from './useAuth';
import { useHistory } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function useUI() {
  // JWT Auth
  const dispatch = useDispatch();
  const history = useHistory()
  const { menus = [], actions = [], user } = useAuth()
  const { favoriteMenus, isLoading, activeMenu, previousMenu, menuActions, activeForms, isOpenSnackbar, snackbarMessage, snackbarVariant, detailId } = useSelector(
    (state) => state.ui
  );
 

  const setActiveMenuActions = (menu) => {
    dispatch(setActiveMenu(menu))
    dispatch(setMenuActions(actions.filter(action => action.menu_id === menu.id)))
  }


  const setPreviousMenuActions = (menu) => {
    dispatch(setPreviousMenu(menu))
  }

  const gotoPage = (route,query) => { 
    const filteredMenus = menus.filter(menu => menu.webPath === route)
    if (filteredMenus[0]) {
      setActiveMenuActions(filteredMenus[0])
    }
    if(query){
      history.push(route+'?'+query)
    }else{
      history.push(route)
    }
    
  }

  const isFavoriteMenu = () => { 
    return favoriteMenus.find(favorites => favorites.id === activeMenu.id) !== undefined
  }

  const toggleFavoriteMenu = () => { 
    if(isFavoriteMenu()){ 
      dispatch(deleteFavoriteMenu({menu_id:activeMenu.id, user_id:user.id}))
    }else{
      dispatch(addFavoriteMenu({menu_id:activeMenu.id, user_id:user.id}) )
    }
  }
 
  const isAllDataAccess = () => {   
    let granted = false
    if (menuActions.length > 0) {
      menuActions.map(action => (action.code)).forEach(cur => {
        if (['READ_ALL'].includes(cur)) {
          granted = true;
        }
      });
    }
    else {
      const route = window.location.pathname;
      const filteredMenus = menus.filter(menu => menu.webPath === route)
      const menu = filteredMenus[0]
      if (menu) {
        const readAllAction = actions.filter(action => (action.menu_id === menu.id && action.code === 'READ_ALL'))
        if (readAllAction[0]) {
          granted = true;
        }
      }
    }

    return granted
  }

  return {

    isLoading,
    activeMenu,
    previousMenu,
    favoriteMenus,
    activeForms,
    menuActions,
    isOpenSnackbar,
    snackbarMessage,
    snackbarVariant,
    detailId,
    setDetailId: (id) => dispatch(setDetailId(id)),
    setActiveMenuActions: (menu) => setActiveMenuActions(menu),
    isAllDataAccess,
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    setActiveMenu: (menu) => dispatch(setActiveMenu(menu)),
    setMenuActions: (menuActions) => dispatch(setMenuActions(menuActions)),
    setActiveForms: (form) => dispatch(setActiveForms(form)),
    gotoPage: (route,query) => gotoPage(route,query),
    setPreviousMenuActions: (menu) => setPreviousMenuActions(menu),
    showSuccess: (message) => dispatch(showSuccessSnackbar(message)),
    showError: (message) => dispatch(showErrorSnackbar(message)),
    showInfo: (message) => dispatch(showInfoSnackbar(message)),
    hideSnackbar: () => dispatch(hideSnackbar()),
    toggleFavoriteMenu,
    setFavoriteMenus:(menus)=>dispatch(setFavoriteMenus(menus)),
    isFavoriteMenu
  };
}
