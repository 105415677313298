import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import PsbFlowForm from './PsbFlowForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormGroup, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js'; 
 
import useAuth from 'hooks/useAuth.js';
import { useHistory } from 'react-router-dom';


const PsbFlow = (props) => {
  const { user, menus = [] } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, setActiveMenuActions, gotoPage, showSuccess, showError } = useUI()
  const history = useHistory()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const defaultFilters = {
    keyword: '',
    school:null,
    school_id:undefined,
    isForOld:1,
    isForNew:1
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [schoolOptions, setschoolOptions] = useState([]);

  const filterChange = (fieldName, eventvalue) => {
    let filtersTemp = { ...filters }
    let value = eventvalue
    
    if (fieldName === 'school') {
      filtersTemp['school_id'] = eventvalue ? eventvalue.value : undefined
    }
    else if (fieldName === 'isForOld') {
      value = eventvalue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'isForNew') {
      value = eventvalue.target.checked ? 1 : 0;
    }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      school_id:filters.school_id,
      isForNew:filters.isForNew,
      isForOld:filters.isForOld,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (params = {}) => {
    startLoading()
    const response = await axios.get(endpoint.psbFlow.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get psbFlow')
    }
    stopLoading()
  }


  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <PsbFlowForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="PsbFlow"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id:user.id
    };
    const response = await axios.delete(endpoint.psbFlow.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete psbFlow.type')
      getData();
    } else {
      showError('delete psbFlow.type')
    }
  };
  
  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getschoolOptions()
    getData()
  }, [])



  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/new-student-adm')} variant='text' color='primary'>
              {`Calon ${process.env.REACT_APP_STUDENT}`}
            </SmallButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }
          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item md={6} xs={6}>
              <FormGroup row>
                  <FormControlLabel control={<Checkbox checked={filters.isForOld === 1} onChange={(e) => filterChange("isForOld", e)}/>} label={`${process.env.REACT_APP_STUDENT} lama`} />
                  <FormControlLabel control={<Checkbox checked={filters.isForNew === 1} />} onChange={(e) => filterChange("isForNew", e)} label={`${process.env.REACT_APP_STUDENT} baru`} />
                </FormGroup>
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell> 
                <TableCell>Sekolah</TableCell>
                <TableCell>Nomor</TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Kode</TableCell>
                <TableCell>Instruksi</TableCell> 
                <TableCell>Path</TableCell>
                <TableCell>Lama</TableCell>
                <TableCell>Baru</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell> 
                  <TableCell>{row.school && row.school.name}</TableCell>
                  <TableCell>{row.order}</TableCell>
                  <TableCell>
                    <ClickableText text={row.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.instruction}</TableCell>
                  <TableCell>{row.path}</TableCell>
                  <TableCell>
                    {
                      row.isForOld === 1 ? <CheckIcon /> : null
                    }
                  </TableCell>
                  <TableCell>
                    {
                      row.isForNew === 1 ? <CheckIcon /> : null
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default PsbFlow;

