import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Button } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PsbResult = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showError } = useUI()
  const { user, detail } = useAuth()
  const [isPsbDone, setisPsbDone] = useState(0)

  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const query = useQuery();

  const getnewStudentOptions = async () => {
    let params = {
      user_id: user.id
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      setnewStudentOptions(response.data)
      let new_student_id = query.get('new_student_id')


      if (response.data[0]) {
        if (new_student_id) {
          setselectedNewStudent(response.data.filter(newStudent => (newStudent.id === parseInt(new_student_id)))[0])
        } else {
          setselectedNewStudent(response.data[0])
        }

      }

    } else {
      showError('get new student')
    }
  }

  const newStudentClick = (newStudent) => {
    setselectedNewStudent(newStudent)
  }


  const getIsPsbDone = async (new_student_id) => {

    const response = await axios.get(endpoint.newStudent.psbDone.replace('new_student_id', new_student_id))

    if (response) {
      setisPsbDone(response.data)
    } else {
      showError('get current psb flow')
    }
  }

  useEffect(() => {
    if (detail) {
      getnewStudentOptions()
    }
  }, [detail])

  useEffect(() => {
    if (selectedNewStudent) {
      getIsPsbDone(selectedNewStudent.id)
    } 
  }, [selectedNewStudent])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
      </Grid>

      <VerticalPillSelection
        selected={selectedNewStudent}
        options={newStudentOptions}
        onSelection={newStudentClick}
      />

      <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400, mt: 2 }} >
        <Grid item xs={12} >
        </Grid>


        {
          selectedNewStudent && selectedNewStudent.isAccepted === 1 &&
          <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_hello_re_3evm.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item container justifyContent='center'>
              <Typography color='primary' component='h4' variant='h4' textAlign='center'>
                Selamat kamu berhasil diterima !!!
              </Typography>
            </Grid>
          </Grid>
        }

        {
          selectedNewStudent && selectedNewStudent.isAccepted === 0 &&
          <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_select_option_re_u4qn.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item container justifyContent='center'>
              <Typography color='primary' component='h5' variant='h5' textAlign='center'>
                Mohon maaf kamu belum diterima, tetap semangat !
              </Typography>
            </Grid>
          </Grid>
        }

        {
          selectedNewStudent && selectedNewStudent.isAccepted === null &&
          <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_select_option_re_u4qn.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item container justifyContent='center'>
              <Typography color='primary' component='h4' variant='h4' textAlign='center'>
                Masih menunggu hasil seleksi
              </Typography>
            </Grid>
          </Grid>
        }

        {
          isPsbDone === 0 &&
          <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent='center'>
              Mohon selesaikan proses seleksi lainnya
            </Grid>
           <Grid item container justifyContent='center'>
              Anda dapat melihat tahapannya di dashboard
            </Grid>
          </Grid>
        }

        {
          isPsbDone === 1 &&
          <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent='center'>
              <Typography color='primary' component='h6' variant='h6'>
                Proses seleksi telah selesai
              </Typography>
            </Grid>

          </Grid>
        }

      </Grid>
    </Grid>

  </>;
}


export default PsbResult;

