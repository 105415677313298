import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import AutodebetDetail from './AutodebetDetail';
import useUI from 'hooks/useUI';

// ----------------------------------------------------------------------

export default function AutodebetForm(props) {
  const { row, pageAction } = props
  const { startLoading, stopLoading, activeMenu = {} } = useUI()
  const [currentTab, setCurrentTab] = useState('detail');

  let TABS = [
    {
      value: 'detail',
      component: <AutodebetDetail {...props} />
    }
  ];



  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container>
      <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} style={{ padding: 8 }}>
        <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
