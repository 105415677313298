import React from 'react'
import useAuth from 'hooks/useAuth';
import { Box } from '@mui/material';

const ReadOnlyTextField = ({value}) => {
    return(
      <Box sx={{
        border:'1px solid #c4c4c4',
        borderRadius:1,
        backgroundColor:'white',
        padding:2,
        height:'auto'
      }}>
        {value}
      </Box>
    )    
    
}

export default ReadOnlyTextField;
