import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios'; 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'; 
import IconButton from '@mui/material/IconButton'; 
import RefreshIcon from '@mui/icons-material/Refresh'; 
import Dialog from '@mui/material/Dialog'; 
import useUI from 'hooks/useUI.js'; 
import {  Hidden } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js'; 
import MoneyCard from 'components/MoneyCard';
import Button from '@mui/material/Button';
import Conditional from 'components/Conditional';
import Confirmation from 'components/Confirmation';
import useAuth from 'hooks/useAuth';
import {  Refresh, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import MoneyPad from './MoneyPad';
import { useHistory } from 'react-router-dom';
import ShoppingCart from './ShoppingCart';
import {EchoInstance} from 'App.js'
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';


const MinimarketCashier = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, setPreviousMenuActions, gotoPage, showError, showInfo } = useUI()
  const { user, detail } = useAuth()
  const history = useHistory()

  const defaultFilters = {
    keyword: ''
  }

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [openMoneyPadDialog, setopenMoneyPadDialog] = useState(false);
  const [openCartDialog, setopenCartDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [total, settotal] = useState(0)
  const [queue, setqueue] = useState(null)
  const [cart, setcart] = useState([])
  const [products, setproducts] = useState([])
  const [barcode, setbarcode] = useState('');
  const [keyword, setkeyword] = useState('');
  const [payAmount, setpayAmount] = useState(0);


  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      startLoading()
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        setqueue(response.data)
      } else {
        setqueue(null)
      }
      stopLoading()
    }

  }

  const inputBarcode = (event) => {
    setbarcode(event.target.value)
  };

  const inputKeyword = (event) => {
    let value = event.target.value
    setkeyword(event.target.value)
    getProducts(value)

  };

  const getProducts = async (keyword) => {
    if (detail && detail.store_id) {
      let params = {
        store_id: detail.store_id,
        page: 1,
        rowsPerPage: 20,
        keyword: keyword
      }
      startLoading()
  
  
      const response = await axios.get(endpoint.product.root, { params: params })
      if (response && response.data && response.data.data) {
        const modifiedData = response.data.data.map(row => ({
          ...row
        }))
        setproducts(modifiedData)
      } else {
        showError('get product')
      }
      stopLoading()
    }

  }


  const showPayConfirmation = () => {
    if (queue) {
      if (total > 0 && queue.balance - total >= 0) {
        setMaxWidth("sm");
        setdialogContent(
          <Confirmation
            handleClose={closeDialog}
            action={payCashless}
            actionName="Bayar pakai kartu"
            message='Transaksi pembayaran menggunakan kartu'
            title='Konfirmasi pembayaran'
          />
        );
        setopenDialog(true);
      } else {
        showInfo('Saldo tidak cukup')
      }

    } else {
      if (total > 0) {
        setopenMoneyPadDialog(true);
      }
    }


  };
  const closeDialog = () => {
    setopenDialog(false)
  }

  const closeMoneyPadDialog = () => {
    setopenMoneyPadDialog(false)
  }

  const closeCartDialog = () => {
    setopenCartDialog(false)
  }

  const deleteLastQueue = async () => {
    const params = {
      ids: [queue.id]
    };
    const response = await axios.delete(endpoint.smartReader.queue, { data: params });
    if (response) {
      getLastQueue()
    } else {
      showError('delete queue')
    }
  };

  const addToCartByBarcode = async (barcode) => {
    if(detail && detail.store_id){
      let params = {
        barcode: barcode,
        store_id: detail.store_id
      }
      startLoading()
      const response = await axios.get(endpoint.product.cartByBarcode, { params: params })
      if (response && response.data && response.data.data) {
        addToCart(response.data.data)
      } else {
        showError(response.data.error)
      }
      stopLoading()
    }else{
      showError('Hanya bisa digunakan oleh pegawai toko')
    }
    
  }

  const addToCartByClick = async (product) => {
    let params = {
      id: product.id,
    }
    startLoading()
    const response = await axios.get(endpoint.product.cartById, { params: params })
    if (response && response.data && response.data.data) {
      addToCart(response.data.data)
    } else {
      showError(response.data.error)
    }
    stopLoading()
  }

  const addToCart = (product) => {
    let newCart = [...cart]

    let exist = false

    cart.forEach((existing, index) => {
      let newProduct = existing

      if (newProduct.id === product.id) {
        exist = true
        newProduct.count += 1
      }

      newCart[index] = newProduct
    });

    if (!exist) {
      newCart.push({ ...product, count: 1 })
    }


    setcart(newCart)
    setbarcode('')
  }

  const deleteFromCart = (index) => {
    let newCart = [...cart]
    newCart.splice(index, 1)
    setcart(newCart)
  }

  const addPayAmount = (amount) => {
    setpayAmount(payAmount + amount)
  }

  const setFocus = (input) => {
    if(input && keyword===''){
      input.focus()
    }
    
  }

  const payCash = async () => {
    if (payAmount - total >= 0) {
      const params = {
        store_id: detail.store_id,
        total: total,
        cart: cart,
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.store.payMinimarketCash, params)
      reset()
      setopenMoneyPadDialog(false)
      showSuccess('Transaksi')
    } else {
      showInfo('Pembayaran masih kurang')
    }

  }


  const payCashless = async () => {
    
    if (queue.balance - total >= 0) {
      const params = {
        queue_id: queue.id,
        user_id: queue.user_id,
        smart_reader_number: detail.smart_reader_number,
        store_id: detail.store_id,
        total: total,
        admin_employee_id: detail.id,
        cart: cart
      }
      const response = await axios.post(endpoint.store.payMinimarketCashless, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        } else { 
          reset()
        }
  
      } else {
        showError('membayar cashless')
      }
    } else {
      showInfo('Saldo tidak cukup ')
    }

  }



  const gotoTransactionPage = () => {
    const route = '/dashboard/transaction'
    setPreviousMenuActions(activeMenu)
    gotoPage(route)
    history.push(route)
  }

  const reset = () => {
    settotal(0)
    setqueue(null)
    getLastQueue()
    setkeyword('')
    setbarcode('')
    setcart([])
    getProducts()
    setpayAmount(0)
  }

  useEffect(() => {
    if(barcode && barcode!=='' && barcode.length >=3 ){
      addToCartByBarcode(barcode)
    }
    
  }, [barcode])

  useEffect(() => {
    settotal(cart.reduce((partialSum, product) => partialSum + (product.count * product.sellPrice), 0))
  }, [cart])

  useEffect(() => {
    getLastQueue()
    getProducts()  

    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME+'card')
    .listen('NewCardQueue', (e) => {      
        getLastQueue()  
    });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME +'card');
    }
  }, [])

  
  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/product-report')} variant='text' color='primary'>
              Penjualan
            </SmallButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/product')} variant='text' color='primary'>
              Produk
            </SmallButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={gotoTransactionPage} variant='text' color='primary'>
              Transaksi
            </SmallButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems='flex-start' sx={{ mb: 1 }}>
        <Grid container justifyContent="flex-start" alignItems='stretch' >

          <Grid container item xs={12} md={3} alignItems='center' sx={{ mb: 1 }}>
            <Grid container justifyContent="center" alignItems='center'>
              {
                queue && <MoneyCard user={queue} onClose={deleteLastQueue} />
              }
              <Conditional condition={queue === null}>
                <IconButton
                  onClick={getLastQueue}
                  aria-label='reset'
                  size="large">
                  <RefreshIcon color='primary' />
                </IconButton>
                <Typography variant='caption' >
                  Tidak ada antrian kartu
                </Typography>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={5} justifyContent="center" alignItems='center' sx={{ mb: 1 }}>
            <Hidden mdDown>
              <Grid item xs={10} >
                <TextField inputRef={input => setFocus(input) } fullWidth variant='outlined' value={barcode} onChange={inputBarcode} placeholder='Barcode' inputProps={{ 'autocomplete': 'off' }} />
              </Grid>
            </Hidden>

            <Grid item xs={10} >
              <TextField fullWidth variant='outlined' value={keyword} onChange={inputKeyword} placeholder='Nama produk' inputProps={{ 'autocomplete': 'off' }} />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={4} justifyContent="center" alignItems='center' >
            <Grid container item xs={6} md={12} justifyContent="center">
              Total belanja :
            </Grid>
            <Grid container item xs={6} md={12} justifyContent="center">
              <Typography variant="h4" component="h4" align="center">
                {new Intl.NumberFormat().format(total)}
              </Typography>
              <Hidden mdUp>
                <IconButton
                  onClick={() => setopenCartDialog(true)}
                  aria-label='cart'
                  size="large">
                  <ShoppingCartIcon color='secondary' />
                </IconButton>
              </Hidden>

            </Grid>
            <Grid container item xs={12} md={12} justifyContent="center" alignItems='center' >
              <Button onClick={showPayConfirmation} fullWidth variant='contained' color='success' size='large'>Bayar</Button>
            </Grid>
          </Grid>

        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <Grid item xs={12} md={8} container justifyContent="center" alignItems='center'  >
          {
            products.map(product => (
              <Grid item xs={4} onClick={() => addToCartByClick(product)} container justifyContent="center" alignItems='center' sx={{ p: 1, cursor: 'pointer' }}>

                <Grid item xs={12} container alignItems='center'
                  sx={{
                    border: 'solid 2px #c5c5cc',
                    borderRadius: 4, 
                    width: '100%'
                  }}>
                  <Grid item xs={12} container justifyContent="center">
                    <img src={`${api.url}/${product.image}`} style={{width:'50%', height:'50%'}} />
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Typography variant='body1' textAlign='center'>{product.name}</Typography>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Typography variant='body1'>{new Intl.NumberFormat().format(product.sellPrice)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>

        <Hidden mdDown>
          <Grid item xs={4} container sx={{
            minHeight: 300,
            border: '5px solid #c5c5cc',
            borderRadius: 4
          }}>
            <ShoppingCart cart={cart} deleteFromCart={deleteFromCart} />
          </Grid>
        </Hidden>


      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Dialog
      open={openCartDialog}
      maxWidth={maxWidth}
      onClose={closeCartDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <ShoppingCart cart={cart} deleteFromCart={deleteFromCart} />
    </Dialog>

    <Dialog
      open={openMoneyPadDialog}
      maxWidth={maxWidth}
      onClose={closeMoneyPadDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"

    >
      <Grid container sx={{ p: 2 }}>
        <Grid item xs={12}>
          <MoneyPad clickMoney={addPayAmount} pay={payCash} />
        </Grid>
        <Grid item xs={12} container justifyContent='space-between' alignItems='center' sx={{ p: 2 }}>
          <Typography variant='h5' component='h5'>
            {`Bayar : ${new Intl.NumberFormat().format(payAmount)} `}
          </Typography>

          <IconButton
            onClick={() => setpayAmount(0)}
            size='medium'
            aria-label='edit'
            style={{ margin: 0 }}>
            <Refresh fontSize='inherit' />
          </IconButton>

          <Typography variant='h5' component='h5'>
            {`Kembali : ${new Intl.NumberFormat().format((payAmount - total) >= 0 ? payAmount - total : 0)} `}
          </Typography>

        </Grid>
      </Grid>
    </Dialog>

  </>;
}



export default MinimarketCashier;

