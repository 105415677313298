import { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { Container, Tab, Box, Tabs, Grid, Typography, Paper } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper } from 'utils/mappers';


// ----------------------------------------------------------------------

export default function NewStudentStatistic(props) {
  const { showError } = useUI()

  const {getDataByStatistic, selectedStatistic} = props
  const [schoolOptions, setschoolOptions] = useState([]);
  
  const [statistics, setstatistics] = useState([
    { name: 'Total', code: 'TOTAL', value: 0,  school_id:undefined, yatim:undefined, isPsbDone: undefined, isMigrated: undefined },
    { name: 'Selesai', code: 'DONE', value: 0,  school_id:undefined, yatim:undefined, isPsbDone: 1, isMigrated: undefined   },
    { name: 'Migrasi', code: 'MIGRATED', value: 0,  school_id:undefined, yatim:undefined, isPsbDone: 1, isMigrated: 1  },
    { name: 'Yatim', code: 'YATIM', value: 0,  school_id:undefined, yatim:1, isPsbDone: undefined, isMigrated: undefined  },
  ])

  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getStatistic = async (statistics) => {
    let params = {}
    const response = await axios.get(endpoint.newStudent.statistic, { params: params })

    if (response && response.data) {
      let newStatistics = statistics.map(statistic => {
        let idx = response.data.findIndex(data => data.code === statistic.code)
        if (idx > -1) {
          return { ...statistic, value: response.data[idx].value }
        }
        return statistic
      })
      setstatistics(newStatistics)
    } else {
      showError('get student')
    }
  }

  useEffect(() => {
    if(schoolOptions){
      let schoolStatistics = schoolOptions.map(school=>({ name: school.code, code: school.code, value: 0, isActive: null, school_id:school.id }))
      
      getStatistic([...statistics,...schoolStatistics])
    }
  }, [schoolOptions])

  useEffect(() => {
    getschoolOptions()
  }, [])

  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center' spacing={1} sx={{ p: 1 }}>
          {
            statistics.map(statistic => (
              <Grid item onClick={() => getDataByStatistic(statistic)} sx={{ cursor: 'pointer' }}>
                <Paper
                  sx={{
                    minWidth: 100,
                    height: 48,
                    p: 1,
                    backgroundColor: selectedStatistic === statistic.code ? 'primary.main' : 'white'
                  }}
                  elevation={4}>
                  <Typography color={selectedStatistic === statistic.code ? 'white' : 'black'} variant="body2" gutterBottom component="div">
                    {statistic.name}
                  </Typography>
                  <Typography color={selectedStatistic === statistic.code ? 'white' : 'black'} variant="h6" gutterBottom component="div">
                    {statistic.value}
                  </Typography>
                </Paper>
              </Grid>
            ))
          }

        </Grid>
  );
}
