import React from 'react';
import {
    Grid,
    Box
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import useUI from 'hooks/useUI';


const Header = (props) => {
    const { isPsbOpen, backgroundColor } = props
    const history = useHistory()
    const { gotoPage } = useUI()
    const { isAuthenticated, logout } = useAuth()

    return (
        <Grid container alignItems='flex-start'
            sx={{
                p: 2,
                bgcolor: { backgroundColor },
                color: '#ffffff'
            }}>
            <Grid container justifyContent='flex-end' >
                <Box fontSize={14} style={{ cursor: 'pointer', margin: '16px 8px' }}>
                    <a href='#facilities' style={{ textDecoration: 'none', color: '#ffffff' }}>Fasilitas</a>
                </Box>
                <Box fontSize={14} style={{ cursor: 'pointer', margin: '16px 8px' }}>
                    <a href='#phone' style={{ textDecoration: 'none', color: '#ffffff' }}>Kontak</a>
                </Box>
                {
                    (isPsbOpen === 1) &&
                    <Box onClick={() => gotoPage('/register')} fontSize={14} style={{ cursor: 'pointer', margin: '16px 8px' }}>
                        {
                            process.env.REACT_APP_SCHOOL_MODE === 'PESANTREN' && 'PSB'
                        }
                        {
                            process.env.REACT_APP_SCHOOL_MODE === 'SEKOLAH' && 'PPDB'
                        }
                    </Box>
                }

                {
                    !isAuthenticated &&
                    <Box onClick={() => history.push('/login')} fontSize={14} style={{ cursor: 'pointer', margin: '16px 8px' }}    >
                        Login
                    </Box>
                }

                {
                    isAuthenticated &&
                    <Box onClick={() => history.push('/dashboard')} fontSize={14} style={{ cursor: 'pointer', margin: '16px 8px' }}    >
                        Dashboard
                    </Box>
                }

                {
                    isAuthenticated &&
                    <Box onClick={logout} fontSize={14} style={{ cursor: 'pointer', margin: '16px 8px' }}    >
                        Logout
                    </Box>
                }
            </Grid>
        </Grid>
    );
}

export default Header;
