import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, studentListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import MonthlyTahfidzForm from './MonthlyTahfidzForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import { getkitabTypeLabel, getmonthLabel, getmonthObject, getscoreTypeLabel, getsuratLabel, gettahfidzScoreTypeLabel, monthOptions, tahfidzScoreTypeOptions } from 'utils/options.js';
import AsyncSelect from 'react-select/async';
import useAuth from 'hooks/useAuth.js';
import useSetting from 'hooks/useSetting.js';


const MonthlyTahfidz = (props) => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const defaultFilters = {
    keyword: '',
    student: null,
    student_id: undefined,
    employee: null,
    employee_id: undefined,
    month: getmonthObject(month + 1),
    month_value: month + 1,
    year: { label: year, value: year },
    year_value: year,
    score: null,
    score_value: undefined,
    exam: null,
    isExam: undefined
  }
  const { user, detail } = useAuth()
  const { tahunPelajaran } = useSetting()
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState(defaultFilters)
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [yearOptions, setyearOptions] = useState([])
  const [isExamOptions, setisExamOptions] = useState([{ label: 'Ujian', value: 1 }, { label: 'Tidak Ujian', value: 0 }])

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'student') {
      filtersTemp['student_id'] = value ? value.value : undefined
    }
    if (fieldName === 'employee') {
      filtersTemp['employee_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'month') {
      filtersTemp['month_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'year') {
      filtersTemp['year_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'year') {
      filtersTemp['year_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'score') {
      filtersTemp['score_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'exam') {
      filtersTemp['isExam'] = value ? value.value : undefined
    }
    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      student_id: filters.student_id,
      teacher_employee_id: filters.employee_id,
      year_value: filters.year_value,
      month_value: filters.month_value,
      score_value: filters.score_value,
      isExam: filters.isExam,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (params = {}) => {
    startLoading()
    const response = await axios.get(endpoint.tahfidz.monthly, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get tahfidz')
    }
    stopLoading()
  }

  const getYearOptions = async (keyword) => {

    setyearOptions(
      [
        { label: year - 2, value: year - 2 },
        { label: year - 1, value: year - 1 },
        { label: year, value: year },
        { label: year + 1, value: year + 1 },
        { label: year + 2, value: year + 2 },
      ]
    )
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => { 
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleEmployeeInputChange = (newValue) => { 
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };


  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <MonthlyTahfidzForm
        row={row}
        getData={getData}
        getDataByParams={getDataByParams}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="MonthlyTahfidz"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds
    };
    const response = await axios.delete(endpoint.tahfidz.monthly, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete tahfidz')
      getData();
    } else {
      showError('delete tahfidz')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getData()
    getYearOptions()
  }, [])

  useEffect(() => {
    if (user && detail && user.user_type_value === 1) {
      setFilters({
        ...filters,
        employee: generalOptionMapper(detail),
        employee_id: detail.id
      })
    }
  }, [detail])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }
          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder="Nama pegawai..."
              value={filters.employee}
              loadOptions={loadEmployeeOptions}
              onInputChange={handleEmployeeInputChange}
              onChange={(e) => filterChange('employee', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
              value={filters.student}
              loadOptions={loadStudentOptions}
              onInputChange={handleInputChangeStudent}
              onChange={(e) => filterChange('student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="month"
              placeholder="Pilih Bulan"
              options={monthOptions}
              value={filters.month}
              onChange={(e) => filterChange("month", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='year'
              placeholder='Pilih Tahun'
              options={yearOptions}
              value={filters.year}
              onChange={(e) => filterChange('year', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="exam"
              placeholder="Ikut ujian"
              options={isExamOptions}
              value={filters.exam}
              onChange={(e) => filterChange("exam", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="score"
              placeholder="Pilih Nilai"
              options={tahfidzScoreTypeOptions}
              value={filters.score}
              onChange={(e) => filterChange("score", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>  
                <TableCell>Halaqoh</TableCell>
                <TableCell>{process.env.REACT_APP_STUDENT}</TableCell>
                <TableCell>Bulan</TableCell>
                <TableCell>Tahun</TableCell>
                <TableCell>Total halaman</TableCell>
                <TableCell>Halaman</TableCell>
                <TableCell>Mulai surat</TableCell>
                <TableCell>Sampai surat</TableCell>
                <TableCell>Total murojaah</TableCell>
                <TableCell>Total hafalan</TableCell>
                <TableCell>Ujian</TableCell>
                <TableCell>Nilai</TableCell>
                <TableCell>Juz Ujian</TableCell>
                <TableCell>alpa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell> 
                  <TableCell>{row.teacher_employee && row.teacher_employee.name}</TableCell>
                  <TableCell>
                    <ClickableText text={row.student && row.student.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>{getmonthLabel(row.month_value)}</TableCell>
                  <TableCell>{row.year_value}</TableCell>
                  <TableCell>{row.pageTotal}</TableCell>
                  <TableCell>{`${row.pageStart}-${row.pageEnd}`}</TableCell>
                  <TableCell>{row.start_surat && row.start_surat.name}</TableCell>
                  <TableCell>{row.end_surat && row.end_surat.name}</TableCell>
                  <TableCell>{row.murojaahJuzTotal}</TableCell>
                  <TableCell>{row.memorizedJuzTotal}</TableCell>
                  <TableCell>
                    {
                      row.isExam === 1 ? <CheckIcon /> : null
                    }
                  </TableCell>
                  <TableCell>{gettahfidzScoreTypeLabel(row.score_value)}</TableCell>
                  <TableCell>{
                    row.examJuzStart &&
                      (row.examJuzStart !== row.examJuzEnd)
                      ? `${row.examJuzStart} - ${row.examJuzEnd}`
                      : row.examJuzStart
                  }</TableCell>
                  <TableCell>{row.alpa}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default MonthlyTahfidz;

