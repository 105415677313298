import React,{useState, useEffect} from 'react'; 
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper } from 'utils/mappers';
import AsyncSelect from 'react-select/async';
import { defaultSelectStyle } from 'utils/theme'; 
import Select from 'react-select'


const SelectEmployeeSmartReaderForm =(props)=> {
  const {closeDialog, onSubmit} = props 
  const reset=()=>{    
    return{
      employee:null,
      smart_reader:null,
      smart_reader_number:undefined
    }    
  }
  const [state, setstate] = useState(reset())
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [smartReaderOptions, setsmartReaderOptions] = useState([])

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if (fieldName === 'smart_reader') {
      newState['smart_reader_number'] = value?value.number:undefined
    } 
    newState[fieldName] = value
    setstate(newState)
  }

  const getsmartReaderOptions = async () => {
    const response = await axios.get(endpoint.smartReader.option);
    if (response && response.data) {
      setsmartReaderOptions(generalListOptionMapper(response.data));
    }
  };

  const getEmployeeOptions = async(keyword)=>{
    const params={
      name:keyword
    }
    const response = await axios.get(endpoint.employee.option, {params:params})    
    
    if(response){
      return generalListOptionMapper(response.data)
    }else{
      return []
    }
  }

  const loadOptions = async(inputValue,callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };
  
  const submit = async()=>{ 
    
    onSubmit(state)
    setstate(reset())
  }  

  useEffect(() => {
    getsmartReaderOptions()
  }, [])
  

  return <>      
      <DialogContent sx={{height:300}}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >  
          <Grid container justifyContent='center' alignItems='flex-start' style={{margin:'16px 0'}}>                
            <AsyncSelect
              cacheOptions
              placeholder = "Pilih pegawai..."
              value={state.employee}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={(e)=>handleChange('employee',e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              isClearable={true}
              name='smart_reader'
              placeholder='Pilih Smart reader...'
              options={smartReaderOptions}
              onChange={(e) => handleChange('smart_reader', e)}
              styles={defaultSelectStyle}
              value={state.smart_reader}
            />
          </Grid>
            
          </Grid>          
        </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
</>;
}

export default SelectEmployeeSmartReaderForm;
 
