import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use'; 
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'; 
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Popover from '@mui/material/Popover';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import StudentForm from 'views/student/StudentForm'; 
import { DialogActions } from '@mui/material';
import SelectStudentForParent from 'views/student/SelectStudentForParent';


const ParentStudent = (props) => {
  const { row = {} } = props
  const { startLoading, stopLoading, activeMenu = {}, activeForms, showSuccess, showError, setActiveForms } = useUI()
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch();
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({ keyword: '' })
  const [filtersAvailable, setfiltersAvailable] = useState([
    { name: 'status', display: 'status', type: 'select' },
    { name: 'jenjang', display: 'jenjang', type: 'select' }
  ])
  const [filterBy, setfilterBy] = useState([])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)


  const getStudentsOfParent = async (newPage, newRowsPerPage) => {
    let params = {
      parent_id: row.id,
      keyword: filters.keyword === '' ? undefined : filters.keyword,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }

    const response = await axios.get(endpoint.parent.student, { params: params })

    if (response && response.data) {
      const modifiedData = response.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
    }
  }


  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getStudentsOfParent(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getStudentsOfParent(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = selectedRow
    }

    if (actionCode === 'ADD_STUDENT') {
      setMaxWidth('sm');
      setdialogContent(
        <SelectStudentForParent
          onSubmit={addStudent}
          closeDialog={closeDialog}
        />
      );
    } else {
      setMaxWidth('md');
      setdialogContent(
        <StudentForm
          row={row}
          getData={getStudentsOfParent}
          pageAction={actionCode}
          closeDialog={closeDialog}
        />
      );
    }

    setActiveForms([...activeForms, process.env.REACT_APP_STUDENT])

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDeleteStudent}
        selectedIds={selectedIds}
        title="Student"
      />
    );

    setopenDialog(true);
  };


  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
    setActiveForms(activeForms.filter(form => form !== process.env.REACT_APP_STUDENT))
  }


  const handleDeleteStudent = async () => {
    const params = {
      parent_id: row.id,
      student_ids: selectedIds
    };
    const response = await axios.delete(endpoint.parent.student, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete student')
      getStudentsOfParent();
    } else {
      showError('delete student')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }


  const addStudent = async (newStudent) => {
    const params = {
      parent_id: row.id,
      student_id: newStudent.student.id,
      parent_type_value: newStudent.parent_type_value,
      sex_type_value: row.sex_type_value
    }
    const response = await axios.put(endpoint.parent.student, params)
    if (response && response.data) {
      showSuccess('save student')
      getStudentsOfParent()
    } else {
      showError('save student')
    }

  }


  useEffect(() => {
    getStudentsOfParent()
  }, [])

  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getStudentsOfParent()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{ p: 1 }}>
      <Grid container justifyContent="flex-end" alignItems="center" sx={{ mt: 1, mb: 1 }}>
        
        {
          selectedIds.length > 0 &&
          <Protected allowedCodes={['DELETE']} >
            <Button onClick={showDeleteConfirmation} variant='contained' color='secondary' size='small' sx={{ mx: 1 }} >
              <ActionDisplay code='DELETE' />
            </Button>
          </Protected>
        }

        <Protected allowedCodes={['ADD_STUDENT']} >
          <Button onClick={() => showDialog('ADD_STUDENT')} variant='contained' color='primary' size='small'  sx={{ mx: 1 }}>
            <ActionDisplay code='ADD_STUDENT' />
          </Button>
        </Protected>
        <Protected allowedCodes={['CREATE_STUDENT']} >
          <Button onClick={() => showDialog('CREATE_STUDENT')} variant='contained' color='primary' size='small' sx={{ mx: 1 }} >
            <ActionDisplay code='CREATE_STUDENT' />
          </Button>
        </Protected>
      </Grid>
      <Grid container sx={{ minHeight: 400 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Sekolah</TableCell>
                <TableCell>Kelas</TableCell>
                <TableCell>Parallel</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell onClick={() => showDialog('READ', row)}
                    sx={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      color: 'primary.main'
                    }}
                  >{row.name}</TableCell>
                  <TableCell>{row.school && row.school.name}</TableCell>
                  <TableCell>{row.kelas && row.kelas.name}</TableCell>
                  <TableCell>{row.parallel && row.parallel.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>



    </Grid>

    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>



  </>;
}


export default ParentStudent;

