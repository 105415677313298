import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format, parse } from 'date-fns'
import { Box, Checkbox, TextField } from '@mui/material';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import { defaultSelectStyle } from 'utils/theme.js'
import { dayOptions, getdayLabel, getdayObject } from 'utils/options';
import useAuth from 'hooks/useAuth';


const JamPelajaranDetail = (props) => {
  const { detail } = useAuth()
  const { row = {}, getData } = props
  const { showSuccess, showError, isAllDataAccess } = useUI()
  const reset = () => {
    return {
      id: undefined,
      school_: null,
      school_id: undefined,
      kelas: null,
      kelas_id: undefined,
      day: null,
      day_value: undefined,
      jam_ke: undefined,
      startTime: undefined,
      finishTime: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'school') {
      newState['school_id'] = value ? value.value : eventValue
      getkelasOptions(value ? value.jenjang_id : undefined)

    }
    else if (fieldName === 'kelas') {
      newState['kelas_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'day') {
      newState['day_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'startTimeObject') {
      newState['startTime'] = value ? format(value, 'HH:mm:00') : undefined
    }
    else if (fieldName === 'finishTimeObject') {
      newState['finishTime'] = value ? format(value, 'HH:mm:00') : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getschoolOptions = async () => {
    let params = {}
    
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.jamPelajaran.root, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('save JamPelajaran')
        getData()
      }
    } else {
      showError('save JamPelajaran')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.jamPelajaran.root, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('save JamPelajaran')
        getData()
      }

    } else {
      showError('save JamPelajaran')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}

    setstate({
      ...row,
      school: generalOptionMapper(row.school),
      kelas: generalOptionMapper(row.kelas),
      day: getdayObject(row.day_value),
      startTimeObject: parse(row.startTime, 'HH:mm:ss', new Date()),
      finishTimeObject: parse(row.finishTime, 'HH:mm:ss', new Date())
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getschoolOptions()
      getkelasOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Conditional condition={pageAction === 'READ'}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  ID
                </Grid>
                <Grid item sm={8} xs={12}>
                  <ReadOnlyTextField value={state.id} />
                </Grid>
              </Grid>
            </Conditional>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Sekolah
              </Grid>
              <Grid sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.school && state.school.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true}
                    name='school'
                    placeholder='Pilih sekolah'
                    options={schoolOptions}
                    value={state.school}
                    onChange={(e) => handleChange('school', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Kelas
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.kelas && state.kelas.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='kelas'
                    placeholder='Pilih Kelas'
                    options={kelasOptions}
                    value={state.kelas}
                    onChange={(e) => handleChange('kelas', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Hari
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={getdayLabel(state.day_value)} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name="day"
                    placeholder="Pilih hari"
                    options={dayOptions}
                    value={state.day}
                    onChange={(e) => handleChange("day", e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>


            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jam ke
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.jam_ke} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <DebouncedTextField
                    margin='dense'
                    id='jam_ke'
                    fullWidth
                    type="number"
                    value={state.jam_ke}
                    onChange={(e) => handleChange('jam_ke', e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jam Mulai
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <Box width={100}>
                    <ReadOnlyTextField value={state.startTime} />
                  </Box>
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>

                  <Grid container alignItems='flex-start'>
                    <TimePicker
                      autoOk
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.startTimeObject}
                      onChange={(e) => handleChange('startTimeObject', e)}
                    />
                  </Grid>
                </Conditional>
              </Grid>

              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jam Selesai
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <Box width={100}>
                    <ReadOnlyTextField value={state.finishTime} />
                  </Box>
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>

                  <Grid container alignItems='flex-start'>
                    <TimePicker
                      autoOk
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.finishTimeObject}
                      onChange={(e) => handleChange('finishTimeObject', e)}
                    />
                  </Grid>
                </Conditional>
              </Grid>

            </Grid>

          </LocalizationProvider>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default JamPelajaranDetail;

