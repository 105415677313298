import { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { Container, Tab, Box, Tabs, Grid, Typography, Paper } from '@mui/material';
import useUI from 'hooks/useUI';


// ----------------------------------------------------------------------

export default function AttendanceStatistic(props) {
  const { showError } = useUI()

  const {getDataByStatistic, selectedStatistic} = props
  
  const [statistics, setstatistics] = useState([
    { name: 'Pegawai Hadir', caption: 'hari ini', code: 'EMPLOYEE_DAILY_PRESENT', value: 0, user_type_value:1, attendance_type_value: 1},
    { name: 'Pegawai Izin', caption: 'hari ini',code: 'EMPLOYEE_DAILY_PERMIT', value: 0, user_type_value:1, attendance_type_value: 2},
    { name: 'Pegawai Alpa', caption: 'hari ini',code: 'EMPLOYEE_DAILY_ALPA', value: 0, user_type_value:1, attendance_type_value: 0},
    { name: 'Pegawai Hadir', caption: 'bulan ini', code: 'EMPLOYEE_MONTHLY_PRESENT', value: 0, user_type_value:1, attendance_type_value: 1},
    { name: 'Pegawai Izin', caption: 'bulan ini',code: 'EMPLOYEE_MONTHLY_PERMIT', value: 0, user_type_value:1, attendance_type_value: 2},
    { name: 'Pegawai Alpa', caption: 'bulan ini',code: 'EMPLOYEE_MONTHLY_ALPA', value: 0, user_type_value:1, attendance_type_value: 0},
    
  ])

  const getStatistic = async () => {
    let params = {}
    const response = await axios.get(endpoint.attendance.statistic, { params: params })

    if (response && response.data) {
      let newStatistics = statistics.map(statistic => {
        let idx = response.data.findIndex(data => data.code === statistic.code)
        if (idx > -1) {
          return { ...statistic, value: response.data[idx].value }
        }
        return statistic
      })
      setstatistics(newStatistics)
    } else {
      showError('get attendance')
    }
  }

  useEffect(() => {
    getStatistic()
  }, [])

  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center' spacing={1} sx={{ p: 1 }}>
          {
            statistics.map(statistic => (
              <Grid item onClick={() => getDataByStatistic(statistic)} sx={{ cursor: 'pointer' }}>
                <Paper
                  sx={{
                    minWidth: 100,
                    height: 'auto',
                    p: 1,
                    backgroundColor: selectedStatistic === statistic.code ? 'primary.main' : 'white'
                  }}
                  elevation={4}>
                  <Typography color={selectedStatistic === statistic.code ? 'white' : 'black'} variant="body2" component="div">
                    {statistic.name}
                  </Typography>
                  <Typography color={selectedStatistic === statistic.code ? 'white' : 'black'} variant="caption" component="div">
                    {statistic.caption}
                  </Typography>
                  <Typography color={selectedStatistic === statistic.code ? 'white' : 'black'} variant="h6" component="div">
                    {statistic.value}
                  </Typography>
                </Paper>
              </Grid>
            ))
          }

        </Grid>
  );
}
