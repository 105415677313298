import React, { useState, useEffect, createContext } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import MoneyCard from 'components/MoneyCard';
import { Card, CardContent, Dialog, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format, parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import Confirmation from 'components/Confirmation';


const CampaignWithdrawal = (props) => {

  const { row = {}, getData, getDataByParams } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()
  const reset = () => {
    return {

    }

  }
  const [pageAction, setpageAction] = useState('READ')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [amount, setamount] = useState(0)
  const [manualCoaKas, setmanualCoaKas] = useState(null);
  const [coaKasOptions, setcoaKasOptions] = useState([]);

  const closeDialog = () => {
    setopenDialog(false)
  }

  const getcoaKasOptions = async () => {
    const params = {
      group: 'HARTA',
      code_prefix: '11',
      employee_id: detail.id
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoaKasOptions(generalListOptionMapper(response.data));
    }
  };


  const submitConfirmation = () => {
    if (manualCoaKas && amount > 0) {
      setMaxWidth("sm");
      setdialogContent(
        <Confirmation
          handleClose={closeDialog}
          action={submit}
          actionName="Cairkan"
          message='Apakah rekening kas dan nominal sudah sesuai'
          title='Konfirmasi pencairan'
        />
      );
      setopenDialog(true);
    } else {
      showInfo('Mohon lengkapi data terlebih dahulu')
    }


  };


  const submit = async () => {
    const params = {
      donation_campaign_id: row.id,
      amount: amount,
      coa_kas_id: manualCoaKas ? manualCoaKas.id : undefined,
      admin_employee_id : detail.id
    }
    const response = await axios.post(endpoint.donationCampaign.withdrawal, params)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {

        showSuccess('Pencairan')
        getData()
        props.closeDialog()

      }
    } else {
      showError('Pencairan')
    }


  }

  useEffect(() => {
    getcoaKasOptions()
  }, [])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            pb: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12}>
              <Select
                name='coa_kas'
                placeholder='Pilih  Rek. Kas'
                options={coaKasOptions}
                value={manualCoaKas}
                onChange={setmanualCoaKas}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container alignContent='center' alignItems='center' >
              <DebouncedTextField
                margin='dense'
                id='amount'
                fullWidth
                label='Nominal'
                type="number"
                value={amount}
                onChange={(e) => setamount(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        batal
      </Button>
      <Button variant="contained" onClick={submitConfirmation} color="primary">
        Cairkan
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  </>;
}

export default CampaignWithdrawal;

