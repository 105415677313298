import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import useAuth from 'hooks/useAuth';
import {
    Icon,
    IconButton,
    ListItemIcon,
    Popover
  } from '@mui/material';
import Search from '@mui/icons-material/Search';
import RoleChip from './RoleChip';
import DebouncedTextField from './DebouncedTextField';
import {useHistory} from 'react-router-dom'
import useUI from 'hooks/useUI';


const SearchPopUp = (props) => {
    const {menus=[] } = useAuth()
    const {isLoading, activeMenu={}, setActiveMenuActions } = useUI()
    const history = useHistory()
    const classes = useStyles()
    const [anchorProfile, setAnchorProfile] = React.useState(null);
    const [filteredMenus, setfilteredMenus] = React.useState([]);
    
    const handleChange=(value)=>{
        if(value.trim() !== ''){
            let filtered = menus.filter(menu=>(menu.webPath && menu.name.toLowerCase().includes(value.toLowerCase())))
            setfilteredMenus(
                filtered
            )
        }else{
            setfilteredMenus([])
        }
    }
    
    const close = () => {
        setAnchorProfile(null);
    };

    const navigateToMenu = (menu) =>{    
        setActiveMenuActions(menu)
        history.push(menu.webPath)
    }

    const menuClick=(menu)=>{
        if(menu.webPath){
            navigateToMenu(menu)
            close()
        }
    }


    return <>        
    <IconButton
      onClick={(e)=>setAnchorProfile(e.currentTarget)}
      aria-label='search'
      size='small'
      style={{margin:0}}>
        <Search />
      </IconButton>
    <Popover
    id='popUpProfile'
    open={Boolean(anchorProfile)}
    anchorEl={anchorProfile}
    onClose={close}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    className={classes.popUpProfile}
    style={
      {marginTop:8}
    }
    >
        <Grid container className={classes.profileContainer} >
            <Grid container justifyContent='center' alignContent='center' style={{marginBottom:8}}>
                <DebouncedTextField
                margin='dense'
                id='id'
                label='Search menu...'
                fullWidth
                onChange={handleChange}
                inputRef={input => input && input.focus()}
                />
            </Grid>
            

            <Grid container justifyContent='center' spacing={1} style={{marginBottom:8}}>
                <List component="div" disablePadding className={classes.list}>
                {
                filteredMenus.map(menu=>(
                    <ListItem
                        button
                        onClick={()=>menuClick(menu)}
                        key={menu.id}
                        selected={activeMenu.code===menu.code}>
                        <ListItemIcon> <Icon>{menu.icon}</Icon> </ListItemIcon>
                        <ListItemText 
                        primary={menu.name} 
                        />
                    </ListItem>
                ))
                }
                </List>                
            </Grid>
        </Grid>
    </Popover>
  </>;
    
}

export default SearchPopUp;

export const useStyles = makeStyles((theme) => ({
    profileContainer:{
        width:300,
        padding:theme.spacing(1)
    },
    role:{
        padding:4,
        border:'1px solid',
        borderColor:theme.borderColor,
        margin:'0 2px 0 2px',
        borderRadius:4
    },
    popUpProfile:{
        top:theme.spacing(2),
    },
    list:{
        width:'100%'
    }
}))