import React,{useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';


const SmallConfirmation =(props)=> {
  const {handleClose, anchor, execute, actionName} = props
  const classes = useStyles()

  const yesClick = ()=>{
    execute()
    handleClose()
  }
  const noClick = ()=>{
    handleClose()
  }

  return (
    <Popover
      id="popup"
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      style={
        {margin:8}
      }
    >
      <Grid container style={{padding:8}}>
          <Grid container>
            <Typography variant="body2">
              are you sure want to {actionName} ?
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Button onClick={yesClick} color="secondary">Yes</Button>
            <Button onClick={noClick} size="small" variant="contained" color="primary" className={classes.smallButton}>Cancel</Button>
          </Grid>
      </Grid>
    </Popover>
  );
}

export default SmallConfirmation;

const useStyles = makeStyles((theme) => ({
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em'
  }
}));
