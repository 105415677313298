import React from 'react';
import { api } from 'utils/axios';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import useAuth from 'hooks/useAuth';
import {
    Popover
  } from '@mui/material';
import Person from '@mui/icons-material/Person';
import RoleChip from './RoleChip';
import useUI from 'hooks/useUI';


const ProfilePopUp = (props) => {
    const {detail={},user={}, roles=[], logout } = useAuth()
    const { gotoPage } = useUI()
    const classes = useStyles()
    const timestamp = Date.now();
    const [anchorProfile, setAnchorProfile] = React.useState(null);
    
    const profileClick = (event) => {
        setAnchorProfile(event.currentTarget);
    };

    const profileClose = () => {
        setAnchorProfile(null);
    };

    const handleLogout = () => {
        logout()
        gotoPage('/home')
    };

    return <>
    <Avatar src={`${api.url}/${detail && detail.photo}`} onClick={profileClick} className={classes.avatarProfile}>
          <Person />
    </Avatar>
    <Popover
    id="popUpProfile"
    open={Boolean(anchorProfile)}
    anchorEl={anchorProfile}
    onClose={profileClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    className={classes.popUpProfile}
    style={
      {marginTop:8}
    }
    >
        <Grid container className={classes.profileContainer} >
            <Grid container justifyContent="center" alignContent="center" style={{marginBottom:8}}>
                <Typography
                    component="span"
                    variant="h5"
                    color="textPrimary"
                >
                    {user.name}
                </Typography>
            </Grid>
            <Grid container justifyContent="center" alignContent="center" style={{marginBottom:8}}>
                <Typography
                    component="span"
                    variant="subtitle"
                    color="textPrimary"
                >
                    {user.username}
                </Typography>
            </Grid>

            <Grid container justifyContent="center" spacing={1} style={{marginBottom:8}}>
            {
            roles.map(role=>(
                <Grid key={role.id} item>
                    <RoleChip role={role}/>
                </Grid>
            ))
            }
            </Grid>

            <Grid container justifyContent="center">
            <Button onClick={handleLogout} color="secondary">logout</Button>
            </Grid>
        </Grid>
    </Popover>
  </>;
    
}

export default ProfilePopUp;

export const useStyles = makeStyles((theme) => ({
    profileContainer:{
        minWidth:200,
        maxWidth:500,
        padding:theme.spacing(1)
    },
    role:{
        padding:4,
        border:'1px solid',
        borderColor:theme.borderColor,
        margin:'0 2px 0 2px',
        borderRadius:4
    },
    popUpProfile:{
        top:theme.spacing(2),
    },
}))