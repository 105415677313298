import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import AutodebetForm from './AutodebetForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';

import ClickableText from 'components/ClickableText.js';
import useAuth from 'hooks/useAuth.js';
import { autodebetStatusOptions, getautodebetStatusObject } from 'utils/options.js';
import StatusChip from 'components/StatusChip.js';




const AutodebetDetail = (props) => {
  const {autodebet} = props
  const { user, detail, roles } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, showInfo, gotoPage, isAllDataAccess } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const defaultFilters = {
    keyword: '',
    autodebet_status: null,
    autodebet_status_value: undefined
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value
    if (fieldName === 'autodebet_status') {
      filtersTemp['autodebet_status_value'] = value ? value.value : undefined
    }
    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      autodebet_id:autodebet.id,
      keyword: filters.keyword,
      page: newPage ? newPage : page,
      autodebet_status_value: filters.autodebet_status_value,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter,

    }

    if (!isAllDataAccess() && roles) {
      params = {
        ...params,
        role_ids: roles.map(role => (role.id))
      }
    }

    startLoading()
    const response = await axios.get(endpoint.autodebet.student, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get autodebet')
    }
    stopLoading()
  }


  const studentAutodebet = async (autodebet_student_id) => {
    
    const payload = {
      autodebet_student_id : autodebet_student_id
    }

    startLoading()
    const response = await axios.post(endpoint.autodebet.student, payload )
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('autodebet')
        getData()
      }
      
    } else {
      showError('autodebet')
    }
    stopLoading()
  }

  const cancelAutodebet = async (autodebet_student_id) => {
    
    const payload = {
      autodebet_student_id : autodebet_student_id
    }

    startLoading()
    const response = await axios.post(endpoint.autodebet.cancelStudent, payload )
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('cancel')
        getData()
      }
      
    } else {
      showError('cancel')
    }
    stopLoading()
  }

  
  

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    if(autodebet){
      getData()
    }
  }, [autodebet])

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={6} container >
          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='autodebet_status'
              placeholder='status'
              options={autodebetStatusOptions}
              onChange={(e) => filterChange('autodebet_status', e)}
              styles={defaultSelectStyle}
              value={filters.autodebet_status}
            />
          </Grid>
        </Grid>

        <Grid xs={6} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>{process.env.REACT_APP_STUDENT}</TableCell>
                <TableCell>Nominal</TableCell>
                <TableCell>Saldo</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>{row.student && row.student.name}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.amount)}</TableCell>
                  <TableCell>{row.balance && new Intl.NumberFormat().format(row.balance.balance)}</TableCell>
                  <TableCell><StatusChip getStatus={getautodebetStatusObject} status={row.autodebet_status_value} /></TableCell>
                  <TableCell>
                    {
                      row.autodebet_status_value === 0 &&
                      <SmallButton onClick={()=>studentAutodebet(row.id)} variant='contained' color='success'>
                        autodebet sekarang
                      </SmallButton>
                    }
                    {
                      row.autodebet_status_value === 1 &&
                      <SmallButton onClick={()=>cancelAutodebet(row.id)} variant='contained' color='error'>
                        Batalkan autodebet
                      </SmallButton>
                    }
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

  </>;
}


export default AutodebetDetail;

