import React, { useState, useEffect, createContext } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getpayStatusLabel, getpayStatusObject, getkitabTypeLabel, getkitabTypeObject, getmonthLabel, getsuratObject, kitabTypeOptions, suratOptions } from 'utils/options';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import UserCard from 'components/UserCard';
import { Card, CardContent, Dialog, FormControlLabel, IconButton, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format, parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import SmallConfirmation from 'components/SmallConfirmation';
import Confirmation from 'components/Confirmation';
import BillInstallmentForm from './BillInstallmentForm';
import BillInstallmentConfirmation from './BillInstallmentConfirmation';
import StatusChip from 'components/StatusChip';
import BillCashConfirmation from './BillCashConfirmation';

export const InstallmentContext = createContext();

const BillTransaction = (props) => {

  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()
  const reset = () => {
    return {
      id: undefined,
      user: null,
      user_type_value: undefined,
      user_detail_id: undefined,
      tahun: null,
      tahun_id: undefined,
      billed_status_value: 1,
      student: null,
      student_id: undefined,
      newStudent: null,
      new_student_id: undefined

    }

  }
  const [studentType, setstudentType] = useState('2');
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [openDialog, setopenDialog] = useState(false);
  const [openInstallmentDialog, setopenInstallmentDialog] = useState(false);
  const [openInstallmentConfirmDialog, setopenInstallmentConfirmDialog] = useState(false);
  const [openManualConfirmDialog, setopenManualConfirmDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [queue, setqueue] = useState(null)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [installmentAmount, setinstallmentAmount] = useState(0);
  const [isCicilAvailable, setisCicilAvailable] = useState(true);
  const [bills, setbills] = useState([]);
  const [selectedBills, setselectedBills] = useState([]);
  const [transactionMode, settransactionMode] = useState('2');
  const [manualCoaBank, setmanualCoaBank] = useState(null);
  const [manualCoaKas, setmanualCoaKas] = useState(null);
  const [balance, setbalance] = useState(null);


  const studentTypeChange = event => {
    setstudentType(event.target.value)
  };

  const transactionModeChange = event => {
    settransactionMode(event.target.value)
    if (event.target.value === '2') {
      setmanualCoaBank(null)
    }
    else if (event.target.value === '4') {
      setmanualCoaKas(null)
    }
    else if (event.target.value === '1') {
      setmanualCoaBank(null)
      setmanualCoaKas(null)
      let student_id = bills[0].student_id
      if (student_id)
        getStudentBalance(student_id)

    }
  };


  const getStudentBalance = async (student_id) => {
    const params = {}
    const response = await axios.get(endpoint.balance.student + '/' + student_id, { params: params })

    if (response) {
      setbalance(response.data)
    }
  }

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'user') {
      newState['user_id'] = value ? value.user_id : undefined
      newState['user_detail_id'] = value ? value.user_detail_id : undefined
      newState['user_type_value'] = value ? value.user_type_value : undefined
    }
    else if (fieldName === 'book') {
      newState['book_id'] = value ? value.id : undefined
    }
    else if (fieldName === 'book') {
      newState['book_id'] = value ? value.id : undefined
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.id : undefined
    }
    else if (fieldName === 'newStudent') {
      newState['new_student_id'] = value ? value.id : undefined
    }
    else if (fieldName === 'parent') {
      newState['parent_id'] = value ? value.id : undefined
    }
    else if (["billed_status_value"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const closeDialog = () => {
    setopenDialog(false)
  }

  const closeInstallmentDialog = () => {
    setopenInstallmentDialog(false)
  }

  const closeInstallmentConfirmDialog = () => {
    setopenInstallmentConfirmDialog(false)
  }

  const closeManualConfirmDialog = () => {
    setopenManualConfirmDialog(false)
  }

  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        setqueue(response.data)
        handleChange('student', response.data.detail)
        getStudentUnpaidBills(response.data.user_detail_id)
      } else {
        setqueue(null)
      }
    }

  }

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
        getLastQueue()
      } else {
        showError('delete queue')
      }
    }
    setstate(reset())
    setbills([])
    setselectedBills([])
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getStudentUnpaidBills = async (student_id) => {
    const params = {
      student_id: student_id,
      billed_status_value: state.billed_status_value
    }
    const response = await axios.get(endpoint.bill.unpaid, { params: params })

    if (response && response.data) {
      return setbills(response.data)
    } else {
      return []
    }
  }


  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const handleStudentChange = (value) => {
    handleChange("student", value)
    if (value) {
      //insertStudentToQueue(value)
      //getLastQueue()
      getStudentUnpaidBills(value.id)
    }
  };

  const getnewStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getnewStudentUnpaidBills = async (new_student_id) => {
    const params = {
      new_student_id: new_student_id,
      billed_status_value: state.billed_status_value
    }
    const response = await axios.get(endpoint.bill.unpaid, { params: params })

    if (response && response.data) {
      return setbills(response.data)
    } else {
      return []
    }
  }


  const loadnewStudentOptions = async (inputValue, callback) => {
    callback(await getnewStudentOptions(inputValue))
  };

  const handlenewStudentInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const handlenewStudentChange = (value) => {
    handleChange("newStudent", value)
    if (value) {
      getnewStudentUnpaidBills(value.id)
    }
  };

  const getParentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.parent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getParentUnpaidBills = async (parent_id) => {
    const params = {
      billed_status_value: state.billed_status_value
    }
    const response = await axios.get(endpoint.bill.allUnpaidParent + '/' + parent_id, { params: params })

    if (response && response.data) {
      return setbills(response.data)
    } else {
      return []
    }
  }


  const loadParentOptions = async (inputValue, callback) => {
    callback(await getParentOptions(inputValue))
  };

  const handleParentInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const handleParentChange = (value) => {
    handleChange("parent", value)
    if (value) {
      getParentUnpaidBills(value.id)
    }
  };

  const insertStudentToQueue = async (student) => {

    if (detail && detail.smart_reader_number) {
      const params = {
        student_id: student.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'TATA USAHA',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.studentQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    } else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const selectBill = (bill) => {
    let exist = selectedBills.filter(b => b.id === bill.id)

    if (exist.length > 0) {
      setselectedBills(
        selectedBills.filter(b => b.id !== bill.id)
      )
    } else {
      setselectedBills(
        [
          ...selectedBills,
          bill
        ]
      )
    }

  };


  const selectAllBills = () => {

    setselectedBills(bills)

  };

  const unselectAllBills = () => {

    setselectedBills([])

  };

  const payCash = async () => {
    if (transactionMode === '2' && !manualCoaKas) {
      showInfo('Mohon pilih kas')
      return
    }
    if (transactionMode === '4' && !manualCoaBank) {
      showInfo('Mohon pilih bank')
      return
    }
    if (detail) {
      let payload = {
        bill_ids: selectedBills.map(bill => bill.id),
        admin_employee_id: detail.id,
        transaction_mode_value: Number(transactionMode),
        coa_bank_id: manualCoaBank ? manualCoaBank.id : undefined,
        coa_kas_id: manualCoaKas ? manualCoaKas.id : undefined,
        balance_id: balance ? balance.id : undefined
      }

      const response = await axios.post(endpoint.bill.payOfflineCash, payload)
      if (response && response.data) {
        if (response && response.data.error) {
          showError(response.data.error)
        } else {
          showSuccess('simpan pembayaran')
          deleteLastQueue()
          if (props.getData) {
            props.getData()
          }

          props.closeDialog()
          closeManualConfirmDialog()
        }

      } else {
        showError('save pembayaran')
      }
    }
  }

  const payInstallment = async (installmentAmount) => {
    if (transactionMode === '2' && !manualCoaKas) {
      showInfo('Mohon pilih kas')
      return
    }
    if (transactionMode === '4' && !manualCoaBank) {
      showInfo('Mohon pilih bank')
      return
    }
    if (detail) {
      let payload = {
        bill_id: selectedBills[0].id,
        installment: installmentAmount,
        admin_employee_id: detail.id,
        transaction_mode_value: Number(transactionMode),
        coa_bank_id: manualCoaBank ? manualCoaBank.id : undefined,
        coa_kas_id: manualCoaKas ? manualCoaKas.id : undefined,
        balance_id: balance ? balance.id : undefined
      }
      const response = await axios.post(endpoint.bill.payOfflineInstallment, payload)
      if (response && response.data) {
        if (response && response.data.error) {
          showError(response.data.error)
        } else {
          showSuccess('simpan pembayaran')
          deleteLastQueue()
          if (props.getData) {
            props.getData()
          }
          props.closeDialog()
          closeInstallmentConfirmDialog()
        }
      } else {
        showError('save peminjaman')
      }
    }

  }

  const installmentAmountChange = (e) => {
    setinstallmentAmount(parseInt(e.target.value))
  }

  const inputInstallement = () => {
    setMaxWidth("sm");
    setopenInstallmentDialog(true);
  }

  const showInstallmentConfirmation = () => {

    if (installmentAmount < (selectedBills[0].amount - selectedBills[0].paidAmount)) {
      if (selectedBills[0].bill_type.minimumCicil && selectedBills[0].bill_type.minimumCicil > 0) {
        if (installmentAmount >= selectedBills[0].bill_type.minimumCicil) {
          setMaxWidth("sm");
          setopenInstallmentConfirmDialog(true);
        }

      } else {
        setMaxWidth("sm");
        setopenInstallmentConfirmDialog(true);
      }

    }

  }

  const showManualConfirmation = () => {
    setMaxWidth("sm");
    setopenManualConfirmDialog(true);
  }


  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])


  useEffect(() => {
    getLastQueue()
  }, [])

  useEffect(() => {
    if (state.student_id) {
      getStudentUnpaidBills(state.student_id)
    }
    else if (state.parent_id) {
      getParentUnpaidBills(state.parent_id)
    }
    else if (state.new_student_id) {
      getnewStudentUnpaidBills(state.new_student_id)
    }
    else if (queue) {
      getStudentUnpaidBills(queue.user_detail_id)
    }
  }, [state.billed_status_value])

  useEffect(() => {
    let tunaiBills = selectedBills.filter(bill => (bill.bill_type.isCicil === 0))
    setisCicilAvailable(tunaiBills.length < 1)

  }, [selectedBills])


  return (
    <>
      <DialogContent>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid container justifyContent='center' alignItems='flex-start'
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: 1,
              pb: 2,
              width: 'inherit',
            }}
          >

            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid container>
                <RadioGroup
                  row
                  aria-labelledby="studentType"
                  defaultValue='1'
                  name="studentType"
                  value={studentType}
                  onChange={studentTypeChange}
                >

                  <FormControlLabel value='2' control={<Radio />} label={process.env.REACT_APP_STUDENT} />
                  <FormControlLabel value='1' control={<Radio />} label={`Calon ${process.env.REACT_APP_STUDENT}`} />
                  <FormControlLabel value='3' control={<Radio />} label={`Orang tua ${process.env.REACT_APP_STUDENT}`} />
                </RadioGroup>
              </Grid>

              <Conditional condition={studentType === '1'}>
                <Grid item xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.newStudent && state.newStudent.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                      {
                        queue && <UserCard user={queue} onClose={deleteLastQueue} />
                      }
                    </Grid>

                    <Conditional condition={queue === null}>
                      <Grid container item xs={12} justifyContent="center" alignItems='center' >
                        <AsyncSelect
                          cacheOptions
                          placeholder={`Nama calon ${process.env.REACT_APP_STUDENT}...`}
                          value={state.newStudent}
                          loadOptions={loadnewStudentOptions}
                          onInputChange={handlenewStudentInputChange}
                          onChange={handlenewStudentChange}
                          styles={defaultSelectStyle}
                        />
                      </Grid>
                    </Conditional>
                  </Conditional>
                </Grid>
              </Conditional>

              <Conditional condition={studentType === '2'}>
                <Grid item xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.student && state.student.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                      {
                        queue && <UserCard user={queue} onClose={deleteLastQueue} />
                      }
                    </Grid>

                    <Conditional condition={queue === null}>
                      <Grid container item xs={12} justifyContent="center" alignItems='center' >
                        <AsyncSelect
                          cacheOptions
                          placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                          value={state.student}
                          loadOptions={loadOptions}
                          onInputChange={handleInputChange}
                          onChange={handleStudentChange}
                          styles={defaultSelectStyle}
                        />
                      </Grid>
                    </Conditional>
                  </Conditional>
                </Grid>
              </Conditional>

              <Conditional condition={studentType === '3'}>
                <Grid item xs={12}>
                  <Conditional condition={pageAction === 'READ'}>
                    <ReadOnlyTextField value={state.parent && state.parent.name} />
                  </Conditional>
                  <Conditional condition={pageAction !== 'READ'}>
                    <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                      {
                        queue && <UserCard user={queue} onClose={deleteLastQueue} />
                      }
                    </Grid>

                    <Conditional condition={queue === null}>
                      <Grid container item xs={12} justifyContent="center" alignItems='center' >
                        <AsyncSelect
                          cacheOptions
                          placeholder={`Nama orang tua`}
                          value={state.parent}
                          loadOptions={loadParentOptions}
                          onInputChange={handleParentInputChange}
                          onChange={handleParentChange}
                          styles={defaultSelectStyle}
                        />
                      </Grid>
                    </Conditional>
                  </Conditional>
                </Grid>
              </Conditional>

            </Grid>

            <Conditional condition={bills.length === 0}>
              <Grid container justifyContent='center'>
                <Typography variant="h5" component="div" color="text.secondary">
                  Tidak ada tagihan
                </Typography>
              </Grid>
            </Conditional>

            <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
              <Grid item xs={12} md={9} container alignItems='center'>
                hanya yang sudah ditagihkan
                <Switch
                  color='primary'
                  checked={state.billed_status_value === 1}
                  onChange={(e) => handleChange("billed_status_value", e)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                Daftar tagihan belum lunas
              </Grid>

            </Grid>

            <Conditional condition={bills.length > 0}>
              {
                bills.map(bill =>
                  <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
                    <Card onClick={() => selectBill(bill)} sx={{
                      minWidth: 275,
                      width: '100%',
                      cursor: 'pointer',
                      background: selectedBills.map(b => b.id).includes(bill.id) ? 'linear-gradient(90deg, #89f7fe 0%, #66a6ff 100%)' : 'white',
                    }}>
                      <CardContent>
                        <Grid container>

                          <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item container >
                              <Grid item xs={6}  >
                                {
                                  (bill.student && bill.student.name) ||
                                  (bill.new_student && bill.new_student.name)
                                }
                              </Grid>
                              <Grid item xs={6} container justifyContent='flex-end'>
                                <Typography variant="h6" component="div">
                                  {
                                    bill.bill_type && bill.bill_type.name
                                  }
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={10}>
                              <Typography sx={{ fontSize: 14 }}>
                                {bill.month_value && getmonthLabel(bill.month_value)}
                                {' '}
                                {bill.year_value}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} container justifyContent='flex-end'>
                              <StatusChip getStatus={getpayStatusObject} status={bill.pay_status_value} />
                            </Grid>

                          </Grid>

                          <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={4}>
                              Tagihan
                            </Grid>

                            <Grid item xs={8} container justifyContent='flex-end'>
                              <Typography variant="h5" component="div" color="text.secondary">
                                Rp. {new Intl.NumberFormat().format(bill.amount)}
                              </Typography>
                            </Grid>

                          </Grid>


                          <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={4}>
                              Terbayar
                            </Grid>
                            <Grid item >
                              <Typography variant="h5" component="div" color="text.secondary">
                                Rp. {new Intl.NumberFormat().format(bill.paidAmount)}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                            <Grid item >
                              Kurang
                            </Grid>
                            <Grid item >
                              <Typography variant="h5" component="div">
                                Rp. {new Intl.NumberFormat().format(bill.amount - bill.paidAmount)}
                              </Typography>
                            </Grid>
                          </Grid>


                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              }

              <Grid container justifyContent='space-between' alignItems='center' sx={{ my: 0.5 }}>
                <Grid item>
                  Total tagihan
                </Grid>
                {
                  selectedBills.length !== bills.length &&
                  <Grid item>
                    <Button onClick={selectAllBills} color="secondary">
                      pilih semua
                    </Button>
                  </Grid>
                }
                {
                  selectedBills.length === bills.length &&
                  selectedBills.length !== 0 &&
                  <Grid item>
                    <Button onClick={unselectAllBills} color="secondary">
                      tidak pilih semua
                    </Button>
                  </Grid>

                }
              </Grid>
              <Typography variant="h3" component="h3">
                Rp.
                {
                  new Intl.NumberFormat().format(
                    selectedBills.map(b => (b.amount - b.paidAmount)).reduce((partialSum, a) => partialSum + a, 0)
                  )
                }
              </Typography>
            </Conditional>


          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Conditional condition={pageAction === "READ"}>
          <Protected allowedCodes={['EDIT']}>
            <Button onClick={() => setpageAction("EDIT")} color="primary">
              Edit
            </Button>
          </Protected>
        </Conditional>
        <Button onClick={props.closeDialog} color="secondary">
          batal
        </Button>
        <Conditional condition={pageAction !== "READ" && selectedBills.length === 1 && isCicilAvailable}>
          <Button variant="contained" onClick={inputInstallement} color="primary">
            Bayar cicil
          </Button>
        </Conditional>
        <Conditional condition={pageAction !== "READ" && selectedBills.length > 0}>
          <Button variant="contained" onClick={showManualConfirmation} color="primary">
            Bayar tunai
          </Button>
        </Conditional>
      </DialogActions>

      <Dialog
        open={openDialog}
        maxWidth={maxWidth}
        onClose={closeDialog}
        fullWidth
        fullScreen={isDialogFull}
        scroll="body"
      >
        {dialogContent}
      </Dialog>

      <Dialog
        open={openInstallmentDialog}
        maxWidth={maxWidth}
        onClose={closeInstallmentDialog}
        fullWidth
        fullScreen={isDialogFull}
        scroll="body"
      >
        <BillInstallmentForm
          handleClose={closeInstallmentDialog}
          action={showInstallmentConfirmation}
          handleChange={installmentAmountChange}
          installmentAmount={installmentAmount}
          actionName="Terima cicilan"
          title='Input pembayaran'
          bill={selectedBills[0]}
        />
      </Dialog>

      <Dialog
        open={openInstallmentConfirmDialog}
        maxWidth={maxWidth}
        onClose={closeInstallmentConfirmDialog}
        fullWidth
        fullScreen={isDialogFull}
        scroll="body"
      >
        <BillInstallmentConfirmation
          installmentAmount={installmentAmount}
          handleClose={closeInstallmentConfirmDialog}
          action={payInstallment}
          transactionMode={transactionMode}
          transactionModeChange={transactionModeChange}
          manualCoaBank={manualCoaBank}
          setmanualCoaBank={setmanualCoaBank}
          manualCoaKas={manualCoaKas}
          setmanualCoaKas={setmanualCoaKas}
          balance={balance}
          bills={bills}
        />
      </Dialog>

      <Dialog
        open={openManualConfirmDialog}
        maxWidth={maxWidth}
        onClose={closeManualConfirmDialog}
        fullWidth
        fullScreen={isDialogFull}
        scroll="body"
      >
        <BillCashConfirmation
          handleClose={closeManualConfirmDialog}
          action={payCash}
          transactionMode={transactionMode}
          transactionModeChange={transactionModeChange}
          manualCoaBank={manualCoaBank}
          setmanualCoaBank={setmanualCoaBank}
          manualCoaKas={manualCoaKas}
          setmanualCoaKas={setmanualCoaKas}
          balance={balance}
          bills={bills}
        />
      </Dialog>



    </>
  );
}

export default BillTransaction;

