import React,{useState} from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const Confirmation =(props)=> {
  const {handleClose, action, actionName, message, title} = props

  const onAction = ()=>{
    action()
    handleClose()
  }

  return (
  <>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={onAction} color="secondary">
          {actionName}
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          Batal
        </Button>
      </DialogActions>
  </>

    );
}

export default Confirmation;
