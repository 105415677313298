import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Conditional from 'components/Conditional';
import { Check } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import { defaultSelectStyle } from 'utils/theme.js'

const SettingInteger = (props) => {
  const { setting = {}, saveSetting } = props
  const [isEditing, setisEditing] = useState(false)
  const [state, setstate] = useState({})
  const [options, setoptions] = useState([])

  const save = () => {
    setisEditing(false)
    saveSetting(state)
  }

  const cancel = () => {
    setisEditing(false)
    setstate(setting)
  }

  const handleChange = (e) => {
    if (isEditing) {
      setstate({
        ...state,
        integerValue: e.target.value
      })
    }
  }

  const selectionChange = (e) => {
    if (isEditing) {
      setstate({
        ...state,
        valueObject: e,
        integerValue: e.value
      })
    }
  }

  const getData = async (tableSource) => {
    let params = {}

    const response = await axios.get(endpoint.data.option.replace('table', tableSource), { params: params })

    if (response && response.data) {
      setoptions(generalListOptionMapper(response.data))
    }
  }

  const getDataDetail = async (newState, tableSource, id) => {
    let params = {}

    const response = await axios.get(endpoint.data.detail.replace('table', tableSource).replace('id', id), { params: params })

    if (response && response.data) {
      setstate({
        ...newState,
        valueObject: generalOptionMapper(response.data)
      })
    }
  }

  useEffect(() => {

    if (setting) {
      let newState = setting

      setstate(
        {
          ...newState,
        }
      )
      if (setting.tableSource) {
        getDataDetail(newState, setting.tableSource, setting.integerValue)
        getData(setting.tableSource)
      } 
    }
  }, [setting])


  return (
    <Paper
      sx={{
        p: 1,
        width: '90%',
      }}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>
          {state.name}
        </Typography>
        <Conditional condition={isEditing === false}>
          <IconButton
            onClick={() => setisEditing(true)}
            size='small'
            aria-label='edit'
            style={{ margin: 0 }}>
            <EditIcon fontSize='inherit' />
          </IconButton>
        </Conditional>

        <Conditional condition={isEditing === true}>
          <Grid item>
            <IconButton
              onClick={cancel}
              size='small'
              aria-label='edit'
              style={{ margin: 0 }}>
              <Close fontSize='inherit' />
            </IconButton>
            <IconButton
              onClick={save}
              size='small'
              aria-label='edit'
              style={{ margin: 0 }}>
              <Check fontSize='inherit' />
            </IconButton>
          </Grid>
        </Conditional>
      </Grid>

      <Conditional condition={state.tableSource === null}>
        <Conditional condition={isEditing === false}>
          <Grid container>
            {state.integerValue}
          </Grid>
        </Conditional>
        <Conditional condition={isEditing === true}>
          <TextField
            value={state.integerValue}
            onChange={handleChange}
            margin='dense'
            variant='outlined'
            fullWidth
            placeholder='integer value'
          />
        </Conditional>
      </Conditional>

      <Conditional condition={state.tableSource !== null}>
        <Grid container alignItems='flex-start'>
          <Select
            name="datasource"
            placeholder="Pilih data"
            options={options}
            value={state.valueObject}
            onChange={selectionChange}
            styles={defaultSelectStyle}
          />
        </Grid>
      </Conditional>

    </Paper>
  );
}


export default SettingInteger;