import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import useAuth from 'hooks/useAuth';
import {
    Badge,
    Button,
    Grid,
    Icon,
    IconButton,
    ListItemButton,
    ListItemIcon,
    Popover
} from '@mui/material';
import { Notifications, NotificationsOutlined } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import useUI from 'hooks/useUI';
import { EchoInstance } from 'App';



const NotificationPopUp = (props) => {
    const { gotoPage, showError } = useUI()
    const { user = {} } = useAuth()
    const [anchorNotification, setAnchorNotification] = useState(null);
    const [notifications, setnotifications] = useState([]);


    const iconClick = (event) => {
        if(notifications.length===0){
            getNotifications()
        }
        if (notifications.length > 0) {
            setAnchorNotification(event.currentTarget);
        }
        
    };

    const notificationClose = () => {
        setAnchorNotification(null);
    };

    const notificationClick = (notification) => {
        notificationClose()
        gotoPage(notification.path)
        deleteNotification(notification)
        getNotifications()
    };

    const dismiss = async (notification, e) => {
        e.stopPropagation();
        deleteNotification(notification)
        getNotifications()
    };

    const deleteNotification = async (notification) => {
        const params = {
            notification_id: notification.id,
            user_id: user.id
        };
        const response = await axios.delete(endpoint.notification.root, { data: params });
    };


    const getNotifications = async () => {
        const response = await axios.get(endpoint.notification.detail.replace("id", user.id))
        if (response) {
            setnotifications(response.data)
        } else {
            showError('get notification')
        }
    }
 

    useEffect(() => {
        if(notifications.length===0){
            notificationClose() 
        }
    }, [notifications])

    useEffect(() => { 
        getNotifications()

        EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME+'notification')
        .listen('NewNotification', (e) => {      
            getNotifications()
        });
    
        return function cleanup() {
          EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME +'notification');
        }
      }, [])

    return <>

        <IconButton
            color="primary"
            onClick={iconClick}
            size='medium'
            aria-label='edit'
            style={{ margin: 0 }}
        >

            <Badge badgeContent={notifications.length} color="secondary">
                <Notifications fontSize='inherit' />
            </Badge>
        </IconButton>
        <Popover
            id="popUpNotification"
            open={Boolean(anchorNotification)}
            anchorEl={anchorNotification}
            onClose={notificationClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{ top: 2 }}
        >
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {
                    notifications.map((notification, index) => (
                        <>
                            <ListItem alignItems="flex-start">
                                <ListItemButton onClick={() => notificationClick(notification)}>
                                    <ListItemIcon>
                                        <Icon>{notification.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={notification.title}
                                        secondary={notification.info}
                                    />
                                    <Button color='primary' onClick={(e) => dismiss(notification, e)}>oke</Button>
                                </ListItemButton>
                            </ListItem>
                            {index < (notifications.length - 1) && <Divider variant="inset" component="li" />}
                        </>
                    ))
                }
            </List>

        </Popover>
    </>;

}

export default NotificationPopUp;

