import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import StudentPermissionForm from './StudentPermissionForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import { approvalStatusOptions, getapprovalStatusLabel, getapprovalStatusObject, getpermissionTypeLabel } from 'utils/options.js';
import StatusChip from 'components/StatusChip.js';
import useAuth from 'hooks/useAuth.js';
import DatePicker, { DateObject } from "react-multi-date-picker"

const StudentPermission = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, isAllDataAccess, gotoPage, detailId, setDetailId } = useUI()
  const { user, detail, students } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const defaultFilters = {
    keyword: '',
    date: null,
    approval_status: null,
    approval_status_value: undefined
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('lg');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [permission_typeOptions, setpermission_typeOptions] = useState([])

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }

    if (fieldName === 'permission_type') {
      filtersTemp['permission_type_id'] = value ? value.value : value
    }
    else if (fieldName === 'approval_status') {
      filtersTemp['approval_status_value'] = value ? value.value : undefined
    }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      permission_type_id: filters.permission_type_id,
      approval_status_value: filters.approval_status_value,
      date: filters.date ? filters.date.format('YYYY-MM-DD') : undefined,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter,
      id: detailId
    }

    if (!isAllDataAccess() && detail) {
      params = {
        ...params,
        employee_id: user.user_type_value === 1 ? detail.id : undefined,
        student_id: user.user_type_value === 2 ? detail.id : undefined,
        student_ids: user.user_type_value === 3 ? students.map(student => (student.id)) : undefined
      }
    }

    startLoading()
    const response = await axios.get(endpoint.permission.student, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get permission')
    }
    stopLoading()
  }

  const getpermission_typeOptions = async () => {
    const params = {
      user_type_value: 2
    }
    const response = await axios.get(endpoint.permissionType.option, { params: params });
    if (response && response.data) {
      setpermission_typeOptions(generalListOptionMapper(response.data));
    }
  };


  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('lg');
    setdialogContent(
      <StudentPermissionForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("lg");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="StudentPermission"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.permission.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete permission')
      getData();
    } else {
      showError('delete permission')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setDetailId(undefined)
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getpermission_typeOptions()
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }

          <Grid item>
            <Protected allowedCodes={['PERMISSION_TYPE']} >
              <SmallButton onClick={() => gotoPage('/dashboard/permission-type')} variant='text' color='primary' >
                <ActionDisplay code='PERMISSION_TYPE' />
              </SmallButton>
            </Protected>
          </Grid>


          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          }


          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="Type"
              placeholder="Pilih tipe izin"
              options={permission_typeOptions}
              value={filters.permission_type}
              onChange={(e) => filterChange("permission_type", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Pada tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.date}
                onChange={(e) => filterChange('date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              name="Type"
              placeholder="Pilih status"
              options={approvalStatusOptions}
              value={filters.approval_status}
              onChange={(e) => filterChange("approval_status", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Paralel</TableCell>
                <TableCell>Tipe Izin</TableCell>
                <TableCell>Jumlah Hari</TableCell>
                <TableCell>Mulai Tanggal</TableCell>
                <TableCell>Sampai Tanggal</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    <ClickableText text={
                      row.student && row.student.name
                    } onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>{row.student && row.student.parallel && row.student.parallel.name}</TableCell>
                  <TableCell>{row.permission_type && row.permission_type.name}</TableCell>
                  <TableCell>{row.days}</TableCell>
                  <TableCell>{row.dates[0] && row.dates[0].permissionDate}</TableCell>
                  <TableCell>{row.dates[row.dates.length - 1] && row.dates[row.dates.length - 1].permissionDate}</TableCell>
                  <TableCell>
                    <StatusChip getStatus={getapprovalStatusObject} status={row.approval_status_value} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default StudentPermission;

