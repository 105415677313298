import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import { getattendanceTypeLabel, getattendanceTypeObject, attendanceTypeOptions } from 'utils/options';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { addDays, format, parse, subDays } from 'date-fns';
import { IconButton, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';


const AttendanceDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError, startLoading, stopLoading } = useUI()
  const [pageAction, setpageAction] = useState('READ')
  const [date, setdate] = useState(null)
  const [hourlyAttendanceDetail, sethourlyAttendanceDetail] = useState([])


  const prev = () => {
    setdate(subDays(date, 1))
  }

  const next = () => {
    setdate(addDays(date, 1))
  }

  const getRowDetail = async () => {
    let params = {
      employee_id: row.employee_id,
      date: format(date, 'yyyy-MM-dd')
    }
    startLoading()
    const response = await axios.get(endpoint.attendance.hourlyDetail, { params: params })
    if (response) {
      sethourlyAttendanceDetail(response.data)
    }
    stopLoading()

  }


  useEffect(() => {
    if (row) {
      setdate(parse(row.date, 'yyyy-MM-dd', new Date()))
    }
  }, [row])

  useEffect(() => {
    if (date) {
      getRowDetail()
    }
  }, [date])




  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            width: 'inherit',
          }}
        >
          <Grid container justifyContent='center' alignItems='center' sx={{ mb: 2 }}>
            <Grid item>
              <IconButton
                onClick={prev}
                aria-label='statistic'
                size="large">
                <ArrowBack color='primary' />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="h6" align="center">
                {
                  date && format(date, 'dd MMMM yyyy')
                }
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={next}
                aria-label='statistic'
                size="large">
                <ArrowForward color='primary' />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
            Jam mengajar
          </Grid>
          <Grid container justifyContent='center'>
            <table>
              {
                hourlyAttendanceDetail.map(attendance => (
                  <tr>
                    {
                      attendance.attendance_type_value === 0 &&
                      <td style={{ textAlign: 'center', color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#d6005f', width: 50 }}>{attendance.jam_ke}</td>
                    }
                    {
                      attendance.attendance_type_value === 1 &&
                      <td style={{ textAlign: 'center', color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#0f6812', width: 50 }} >{attendance.jam_ke}</td>
                    }
                    {
                      attendance.attendance_type_value === 2 &&
                      <td style={{ textAlign: 'center', color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#ffa246', width: 50 }} >{attendance.jam_ke}</td>
                    }
                    {
                      attendance.attendance_type_value === -1 &&
                      <td style={{ textAlign: 'center', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, width: 50, height: 18 }} >{attendance.jam_ke}</td>
                    }
                  </tr>
                ))
              }

            </table>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>

    </DialogActions>
  </>;
}

export default AttendanceDetail;

