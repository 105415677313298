import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import ProgressTahfidzDetail from './ProgressTahfidzDetail';
import useUI from 'hooks/useUI';

// ----------------------------------------------------------------------

export default function ProgressTahfidzForm(props) { 
  const { startLoading, stopLoading, activeMenu = {} } = useUI() 
  
  return (
    <Container>
      <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} style={{ padding: 8 }}>
        <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <ProgressTahfidzDetail {...props} />
      </Box>
    </Container>
  );
}
