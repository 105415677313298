import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import MuiSelect from '@mui/material/Select';
import { billTypeListOptionMapper, generalListOptionMapper, studentListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import StatusChip from 'components/StatusChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import TransactionForm from './TransactionForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Button, Divider, FormControl, FormControlLabel, InputLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Collapse from '@mui/material/Collapse';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TransactionStatistic from './TransactionStatistic.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import { gettransactionModeLabel, gettransactionTypeLabel, getuserTypeLabel, getwithdrawalStatusObject, transactionModeOptions, transactionTypeOptions, userTypeOptions } from 'utils/options.js';
import ReadOnlyTextField from 'components/ReadOnlyTextField.js';
import { useHistory } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Conditional from 'components/Conditional.js';
import DebouncedTextField from 'components/DebouncedTextField.js';
import useAuth from 'hooks/useAuth.js';
import Confirmation from 'components/Confirmation.js';
import DonationAdmin from './DonationAdmin.js';
import DatePicker, { DateObject } from "react-multi-date-picker"
import { format, parse, parseISO } from 'date-fns';
import AsyncSelect from 'react-select/async';


const Transaction = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, previousMenu = {}, setActiveMenuActions, showSuccess, showError, showInfo, isAllDataAccess } = useUI()
  const { user, detail, students = [] } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [openStatistic, setopenStatistic] = useState(false)
  const [selectedStatistic, setselectedStatistic] = useState('')
  const [totalAmount, settotalAmount] = useState(0)
  const history = useHistory()

  const defaultFilters = {
    keyword: '',
    student: null,
    student_id: undefined,
    new_student: null,
    new_student_id: undefined,
    start_date: new DateObject,
    end_date: new DateObject,
    transaction_type_value: undefined,
    transaction_type: null,
    transaction_mode_value: undefined,
    transaction_mode: null,
    isActive: null,
    store: null,
    store_id: undefined,
    donation_campaign: null,
    donation_campaign_id: undefined,
    bill_type: null,
    bill_type_id: undefined,
    school: null,
    school_id: undefined,
    user_type: null,
    user_type_value: undefined,

  }
  const [filters, setFilters] = useState(defaultFilters)

  const [isActiveOptions, setisActiveOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [openBalanceDialog, setopenBalanceDialog] = useState(false);
  const [openDonationDialog, setopenDonationDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [withdrawalType, setwithdrawalType] = useState(1)
  const [withdrawalAmount, setwithdrawalAmount] = useState(0)
  const [store, setstore] = useState(null)
  const [storeOptions, setstoreOptions] = useState([])
  const [donation_campaignOptions, setdonation_campaignOptions] = useState([])
  const [withdrawals, setwithdrawals] = useState([])
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameNewStudentKeyWord, setnameNewStudentKeyWord] = useState('');
  const [bill_typeOptions, setbill_typeOptions] = useState([])
  const [schoolOptions, setschoolOptions] = useState([]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'transaction_type') {
      filtersTemp['transaction_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      filtersTemp['school_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'transaction_mode') {
      filtersTemp['transaction_mode_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'student') {
      filtersTemp['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'new_student') {
      filtersTemp['new_student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'store') {
      filtersTemp['store_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'donation_campaign') {
      filtersTemp['donation_campaign_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'bill_type') {
      filtersTemp['bill_type_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'user_type') {
      filtersTemp['user_type_value'] = value ? value.value : undefined
    }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const withdrawalTypeChange = (e) => {
    setwithdrawalType(e.target.value)
  }


  const getData = async (newPage, newRowsPerPage, format) => {
    let params = {
      keyword: filters.keyword,
      student_id: filters.student_id,
      new_student_id: filters.new_student_id,
      user_type_value: filters.user_type_value,
      school_id: filters.school_id,
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
      transaction_type_value: filters.transaction_type_value,
      transaction_mode_value: filters.transaction_mode_value,
      isActive: filters.isActive ? filters.isActive.value : undefined,
      store_id: filters.store_id,
      donation_campaign_id: filters.donation_campaign_id,
      bill_type_id: filters.bill_type_id,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    if (format) {
      if (format === 'excel') {
        printExcel(params)
      }
    } else {
      getDataByParams(params)
      getTotalAmount(params)
    }

  }

  const printExcel = (params) => {
    axios.get(endpoint.transaction.excel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transactions.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        if (detail.store_id) {
          params = {
            ...params,
            store_id: detail.store_id
          }
        } else {
          params = {
            ...params,
            employee_id: detail.id
          }
        }
      }
      else if (user.user_type_value === 2 && detail) {
        params = {
          ...params,
          student_id: detail.id,
        }
      }
      else if (user.user_type_value === 3 && students.length > 0) {
        params = {
          ...params,
          user_id: user.id,
          student_ids: students.map(student => (student.id))
        }
      }
      else {
        return
      }
    }


    startLoading()
    const response = await axios.get(endpoint.transaction.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get transaction')
    }
    stopLoading()
  }

  const getStore = async (params = {}) => {
    const response = await axios.get(endpoint.store.detail.replace('id', detail.store_id), { params: params })
    if (response) {
      setstore(response.data)
    } else {
      showError('get transaction')
    }
  }

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }

    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getbill_typeOptions = async () => {
    const params = {
    }
    const response = await axios.get(endpoint.billType.option, { params: params });
    if (response && response.data) {
      setbill_typeOptions(billTypeListOptionMapper(response.data));
    }
  };


  const getTotalAmount = async (parameter) => {
    let params = {
      ...parameter
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        if (detail.store_id) {
          params = {
            ...params,
            store_id: detail.store_id
          }
        } else {
          params = {
            ...params,
            employee_id: detail.id
          }
        }
      }
      else if (user.user_type_value === 2 && detail) {
        params = {
          ...params,
          student_id: detail.id,
        }
      }
      else if (user.user_type_value === 3 && students.length > 0) {
        params = {
          ...params,
          user_id: user.id,
          student_ids: students.map(student => (student.id))
        }
      }
      else {
        return
      }
    }
    const response = await axios.get(endpoint.transaction.amount, { params: params })
    if (response) {
      settotalAmount(response.data)
    } else {
      showError('get total amount')
      settotalAmount(0)
    }
  }

  const getDataByStatistic = async (statistic) => {
    setopenDialog(false)
    if (selectedStatistic === statistic.code) {
      reset()
    } else {
      setselectedStatistic(statistic.code)
      let params = {
        transaction_type_value: statistic.transaction_type_value,
        isActive: statistic.isActive,
        page: page,
        rowsPerPage: rowsPerPage
      }

      let isActiveIdx = isActiveOptions.findIndex(status => status.value === statistic.isActive)
      let isActive = null

      if (isActiveIdx > -1) {
        isActive = isActiveOptions[isActiveIdx]
      }

      let transaction_typeIdx = transactionModeOptions.findIndex(type => type.value === statistic.transaction_type_value)
      let transaction_type = null

      if (transaction_typeIdx > -1) {
        transaction_type = transactionModeOptions[transaction_typeIdx]
      }

      setFilters(
        {
          ...filters,
          transaction_type_value: statistic.transaction_type_value,
          isActive: isActive,
          transaction_type: transaction_type
        }
      )
      setPage(1)

      getDataByParams(params)

    }

  }

  const showStatistic = () => {
    if (smallScreen) {
      setdialogContent(
        <Box minHeight={300}>
          <TransactionStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Box>

      )
      setopenDialog(true)
    } else {
      setopenStatistic(!openStatistic)
    }
  }

  const withdrawalRequestConfirmation = () => {
    if (withdrawalType === 1) {
      setMaxWidth("sm");
      setdialogContent(
        <Confirmation
          handleClose={closeDialog}
          action={withdrawalRequest}
          actionName="Cairkan"
          message='Apakah anda ingin melakukan pencairan semua saldo '
          title='Konfirmasi Pencairan'
        />
      );
      setopenDialog(true);
    } else {
      withdrawalRequest()
    }

  };

  const withdrawalRequest = async () => {
    let amount = 0;
    if (withdrawalType === 1) {
      amount = store.balance
    } else {
      amount = withdrawalAmount
    }

    if (amount < 1000) {
      showInfo('Minimal Rp. 1000')
      return
    }

    if (amount > store.balance) {
      showInfo('Saldo tidak mencukupi')
      return
    }

    let payload = {
      amount: amount,
      store_id: store.id,
      store_employee_id: detail.id
    }

    const response = await axios.post(endpoint.store.withdrawalRequest, payload)
    if (response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('Permintaan pencairan saldo')
      }

    } else {
      showError('Permintaan pencairan saldo')
    }
  }

  const getstoreOptions = async () => {
    const response = await axios.get(endpoint.store.option);
    if (response && response.data) {
      setstoreOptions(generalListOptionMapper(response.data));
    }
  };

  const getdonation_campaignOptions = async () => {
    const response = await axios.get(endpoint.donationCampaign.option);
    if (response && response.data) {
      setdonation_campaignOptions(response.data.map(campaign => ({ ...campaign, label: campaign.title, value: campaign.id })));
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getNewStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const loadNewStudentOptions = async (inputValue, callback) => {
    callback(await getNewStudentOptions(inputValue))
  };

  const handleInputChangeNewStudent = (newValue) => {
    setnameNewStudentKeyWord(newValue)
    return newValue;
  };

  const gotoPreviousPage = () => {
    if (previousMenu && previousMenu.id) {
      setActiveMenuActions(previousMenu)
      history.push(previousMenu.webPath)
    }
  }

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <TransactionForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );

    setopenDialog(true);
  };

  const showBalance = () => {
    //getStore()
    //setopenBalanceDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Transaction"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const closeBalanceDialog = () => {
    setopenBalanceDialog(false)
  }

  const closeDonationDialog = () => {
    setopenDonationDialog(false)
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.transaction.root, { data: params });
    if (response) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setselectedIds([])
        setselectedRow(null)
        getData();
        showSuccess('hapus transaction')
      }
      
    }else{
      showError('hapus transaction')
    }

  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      transaction_type_value: defaultFilters.transaction_type_value,
      keyword: defaultFilters.keyword,
      isActive: defaultFilters.isActive ? defaultFilters.isActive.value : undefined,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setselectedStatistic('')
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getData()
    getstoreOptions()
    getdonation_campaignOptions()
    getbill_typeOptions()
    getschoolOptions()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <Protected allowedCodes={['DONATION']} >
              <SmallButton onClick={() => setopenDonationDialog(true)} variant='contained' color='primary'>
                <ActionDisplay code='DONATION' />
              </SmallButton>
            </Protected>
          </Grid>

          <Grid item>
            <Protected allowedCodes={['INCOME']} >
              <SmallButton onClick={() => showDialog('INCOME')} variant='contained' color='success'>
                <ActionDisplay code='INCOME' />
              </SmallButton>
            </Protected>
          </Grid>
          <Grid item>
            <Protected allowedCodes={['EXPENSE']} >
              <SmallButton onClick={() => showDialog('EXPENSE')} variant='contained' color='error'>
                <ActionDisplay code='EXPENSE' />
              </SmallButton>
            </Protected>
          </Grid>
          <Grid item>
            <Protected allowedCodes={['TRANSFER']} >
              <SmallButton onClick={() => showDialog('TRANSFER')} variant='contained' color='warning'>
                <ActionDisplay code='TRANSFER' />
              </SmallButton>
            </Protected>
          </Grid>
          <Grid item>
            <Protected allowedCodes={['EQUITY']} >
              <SmallButton onClick={() => showDialog('EQUITY')} variant='contained' color='secondary'>
                <ActionDisplay code='EQUITY' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='error' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }
          {
            isAllDataAccess() &&
            <Grid item>
              <IconButton
                onClick={showStatistic}
                aria-label='delete'
                size="large">
                <InsertChartIcon color='primary' />
              </IconButton>
            </Grid>
          }


          <Grid item>
            {
              previousMenu && <SmallButton onClick={gotoPreviousPage} variant='text' color='primary'>
                {previousMenu.name}
              </SmallButton>
            }
          </Grid>

          {
            /**
             <Grid item>
                      <Protected allowedCodes={['STORE_BALANCE']} >
                        <SmallButton onClick={showBalance} variant='contained' color='primary'>
                          <ActionDisplay code='STORE_BALANCE' />
                        </SmallButton>
                      </Protected>
                    </Grid>
             */
          }


          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>


      <Collapse in={openStatistic} timeout="auto" unmountOnExit sx={{ width: 'inherit' }}>
        <Grid container sx={{ minHeight: 64, mb: 1 }}>
          <TransactionStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Grid>
      </Collapse>

      <Grid container justifyContent='flex-end'>
        <Grid item >
          <Paper
            sx={{
              minWidth: 100,
              height: 'auto',
              p: 1,
              backgroundColor: 'white'
            }}
            elevation={4}>
            <Typography variant="body2" component="div">
              Jumlah total
            </Typography>
            <Typography variant="h6" component="div">
              {new Intl.NumberFormat().format(totalAmount)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>


      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
              value={filters.student}
              loadOptions={loadStudentOptions}
              onInputChange={handleInputChangeStudent}
              onChange={(e) => filterChange('student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder={`Nama calon ${process.env.REACT_APP_STUDENT}...`}
              value={filters.new_student}
              loadOptions={loadNewStudentOptions}
              onInputChange={handleInputChangeNewStudent}
              onChange={(e) => filterChange('new_student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='user_type'
              placeholder='tipe pengguna'
              options={userTypeOptions}
              onChange={(e) => filterChange('user_type', e)}
              styles={defaultSelectStyle}
              value={filters.user_type}
            />
          </Grid>

          <Grid item>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Mulai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.start_date}
                onChange={(e) => filterChange('start_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Sampai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.end_date}
                onChange={(e) => filterChange('end_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='transaction_type'
              placeholder='jenis transaksi'
              options={transactionTypeOptions}
              onChange={(e) => filterChange('transaction_type', e)}
              styles={defaultSelectStyle}
              value={filters.transaction_type}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='bill_type'
              placeholder='Pilih jenis tagihan'
              options={bill_typeOptions}
              value={filters.bill_type}
              onChange={(e) => filterChange('bill_type', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='transaction_mode'
              placeholder='mode transaksi'
              options={transactionModeOptions}
              onChange={(e) => filterChange('transaction_mode', e)}
              styles={defaultSelectStyle}
              value={filters.transaction_mode}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='store'
              placeholder='Usaha...'
              options={storeOptions}
              onChange={(e) => filterChange('store', e)}
              styles={defaultSelectStyle}
              value={filters.store}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='donation_campaign'
              placeholder='Donasi...'
              options={donation_campaignOptions}
              onChange={(e) => filterChange('donation_campaign', e)}
              styles={defaultSelectStyle}
              value={filters.donation_campaign}
            />
          </Grid>

        </Grid>
        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={() => getData(undefined, undefined, 'excel')}>
            Excel
          </SmallButton>
          <IconButton
            onClick={() => getData()}
            aria-label='delete'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell >Nomor</TableCell>
                <TableCell >Tanggal</TableCell>
                <TableCell >Jam</TableCell>
                <TableCell >Jenis Transaksi</TableCell>
                <TableCell >Nominal</TableCell>
                <TableCell >Keterangan</TableCell>
                <TableCell >Nama</TableCell>
                <TableCell >Sekolah</TableCell>
                <TableCell >Admin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    #{row.number}
                  </TableCell>
                  <TableCell >
                    {format(parse(row.transactionDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')}
                  </TableCell>
                  <TableCell >
                    {format(parseISO(row.created_at), 'HH:mm')}
                  </TableCell>
                  <TableCell >
                    <ClickableText text={gettransactionTypeLabel(row.transaction_type_value)} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell >{new Intl.NumberFormat().format(row.amount)}</TableCell>
                  <TableCell >
                    {row.description}
                  </TableCell>
                  <TableCell>
                    {
                      (row.new_student && row.new_student.name)
                      ||
                      (row.student && row.student.name)
                      ||
                      (row.parent && row.parent.name)
                      ||
                      (row.employee && row.employee.name)
                      ||
                      (row.visitor && row.visitor.name)
                    }
                  </TableCell>
                  <TableCell>
                    {row.school && row.school.code}
                  </TableCell>
                  <TableCell>
                    {row.admin && row.admin.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Dialog
      open={openBalanceDialog}
      maxWidth={'md'}
      onClose={closeBalanceDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <Grid
        container
        alignItems='center'
        sx={{
          p: 2
        }}>

        <Grid container alignItems='center' sx={{ mt: 2 }}>
          <Grid container justifyContent='flex-start' alignContent='center' alignItems='center' item xs={6}>
            Saldo Toko
          </Grid>
          <Grid container justifyContent='flex-end' alignContent='center' alignItems='center' item xs={6} >
            <Typography variant="h4" component="h4" align="center">
              {store && new Intl.NumberFormat().format(store.balance)}
            </Typography>
          </Grid>
          <Grid container justifyContent='flex-start' alignContent='center' alignItems='center' item xs={6} >
            <FormControl fullWidth>
              <InputLabel id="withdrawalType-label">Jumlah</InputLabel>
              <MuiSelect
                labelId="withdrawalType-label"
                id="withdrawalType"
                value={withdrawalType}
                label="Jumlah"
                onChange={withdrawalTypeChange}
              >
                <MenuItem value={1}>Semua</MenuItem>
                <MenuItem value={2}>Sebagian</MenuItem>
              </MuiSelect>
            </FormControl>
          </Grid>
          <Grid container justifyContent='flex-end' alignContent='center' alignItems='center' item xs={6} >
            <Button onClick={withdrawalRequestConfirmation} variant='contained' color='success'> Cairkan </Button>
          </Grid>
        </Grid>

        <Conditional condition={withdrawalType === 2}>
          <Grid container alignItems='center' >
            <Grid container alignContent='center' alignItems='center' item xs={12} >
              <DebouncedTextField
                margin='dense'
                id='amount'
                fullWidth
                label='Jumlah pencairan'
                type="number"
                value={withdrawalAmount}
                onChange={(e) => setwithdrawalAmount(e)}
              />
            </Grid>
          </Grid>
        </Conditional>


      </Grid>

    </Dialog>

    <Dialog
      open={openDonationDialog}
      maxWidth={'lg'}
      onClose={closeDonationDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <DonationAdmin onClose={closeDonationDialog} />
    </Dialog>

  </>;
}


export default Transaction;

