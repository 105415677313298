import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import ParallelForm from './ParallelForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Button, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Collapse from '@mui/material/Collapse';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js'; 
import useAuth from 'hooks/useAuth.js';
 



const Parallel = (props) => {
  const { user, detail } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const defaultFilters = {
    keyword: '',
    jenjang: null,
    jenjang_id: undefined,
    school: null,
    school_id: undefined,
    kelas: null,
    kelas_id: undefined
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [jenjangOptions, setjenjangOptions] = useState([])
  const [schoolOptions, setschoolOptions] = useState([])
  const [kelasOptions, setkelasOptions] = useState([])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }

    if (value) {
      if (fieldName === 'school') {
        filtersTemp['school_id'] = value.value
        filtersTemp['jenjang_id'] = value.jenjang_id
        getkelasOptions(value.jenjang_id)
      } else
        if (fieldName === 'kelas') {
          filtersTemp['kelas_id'] = value.value
        }
    }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      jenjang_id: filters.jenjang ? filters.jenjang.value : undefined,
      school_id: filters.school ? filters.school.value : undefined,
      kelas_id: filters.kelas ? filters.kelas.value : undefined,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (params = {}) => {
    startLoading()
    const response = await axios.get(endpoint.parallel.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get parallel')
    }
    stopLoading()
  }

  const getjenjangOptions = async () => {
    const response = await axios.get(endpoint.jenjang.option);
    if (response && response.data) {
      setjenjangOptions(generalListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };



  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <ParallelForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
        importUrl = {endpoint.parallel.import}
        templateUrl = {api.url + '/files/PARALLEL_TEMPLATE.xlsx'}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Parallel"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id:user.id
    };
    const response = await axios.delete(endpoint.parallel.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete parallel')
      getData();
    } else {
      showError('delete parallel')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      jenjang_id: defaultFilters.jenjang_id,
      school_id: defaultFilters.school_id,
      kelas_id: defaultFilters.kelas_id,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }


  useEffect(() => {
    getschoolOptions()
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
              <Button onClick={() => showDialog('IMPORT')} color='primary'>
                Import
              </Button>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }
          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>


      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item>
            <Select
              isClearable={true}
              name='kelas'
              placeholder='Kelas'
              options={kelasOptions}
              onChange={(e) => filterChange('kelas', e)}
              styles={defaultSelectStyle}
              value={filters.kelas}
            />
          </Grid>


        </Grid>
        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>


      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Kelas</TableCell>
                <TableCell>Jurusan</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Jenjang</TableCell>
                <TableCell>Wali kelas</TableCell>
                <TableCell>Smart Reader</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    <ClickableText text={row.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.kelas && row.kelas.name}</TableCell>
                  <TableCell>{row.jurusan && row.jurusan.name}</TableCell>
                  <TableCell>{row.school && row.school.name}</TableCell>
                  <TableCell>{row.jenjang && row.jenjang.name}</TableCell>
                  <TableCell>{row.wali_kelas && row.wali_kelas.name}</TableCell>
                  <TableCell>{row.smart_reader && `(${row.smart_reader.number}) ${row.smart_reader.name}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default Parallel;

