import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper, studentListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import BalanceForm from './BalanceForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import { balanceTypeOptions, getbalanceTypeLabel } from 'utils/options.js';
import { useHistory } from 'react-router-dom';
import useAuth from 'hooks/useAuth.js';
import TopupInputAmount from './TopupInputAmount.js';
import PaymentMethod from 'views/payment/PaymentMethod.js';
import PaymentCharge from 'views/payment/PaymentCharge.js';
import DonationInputAmount from './DonationInputAmount.js';
import AsyncSelect from 'react-select/async';
import InputLimitAmount from './InputLimitAmount.js';
import BalanceSummary from './BalanceSummary.js';



const Balance = (props) => {
  const defaultFilters = {
    keyword: '',
    student: null,
    student_id: undefined,
    employee: null,
    employee_id: undefined
  }
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, setPreviousMenuActions, gotoPage, showError, isAllDataAccess } = useUI()
  const { user, detail, students } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [topUpAmount, settopUpAmount] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const history = useHistory()
  const [filters, setFilters] = useState(defaultFilters)
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [openAmountDialog, setopenAmountDialog] = useState(false);
  const [openLimitAmountDialog, setopenLimitAmountDialog] = useState(false);
  const [openChannelDialog, setopenChannelDialog] = useState(false);
  const [openVADialog, setopenVADialog] = useState(false);
  const [openDonationDialog, setopenDonationDialog] = useState(false);
  const [openSummaryDialog, setopenSummaryDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [balance, setbalance] = useState(null)
  const [queue, setqueue] = useState(null)
  const [paymentRequest, setpaymentRequest] = useState(null)
  const [defaultDailyLimit, setdefaultDailyLimit] = useState(0)

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'student') {
      filtersTemp['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'employee') {
      filtersTemp['employee_id'] = value ? value.value : undefined
    }
    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }



  const getdefaultDailyLimit = async () => {

    const response = await axios.get(endpoint.setting.code + '/DAILY_PAY_LIMIT');
    if (response && response.data) {
      setdefaultDailyLimit(response.data.integerValue)
    }
  };

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      student_id: filters.student_id,
      employee_id: filters.employee_id,
      balance_type_value: filters.balance_type ? filters.balance_type.value : undefined,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter,
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        params = {
          ...params,
          employee_id: detail.id,
        }
      }
      else if (user.user_type_value === 2 && detail) {
        params = {
          ...params,
          student_id: detail.id,
        }
      }
      else if (user.user_type_value === 3 && students.length > 0) {
        params = {
          ...params,
          student_ids: students.map(student => (student.id))
        }
      }
      else {
        return
      }
    }

    startLoading()
    const response = await axios.get(endpoint.balance.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get balance')
    }
    stopLoading()
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };


  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleEmployeeInputChange = (newValue) => {
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
      } else {
        showError('delete queue')
      }
    }
  };

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showSummary = () => {
    setopenSummaryDialog(true)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <BalanceForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
        setqueue={setqueue}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Balance"
      />
    );

    setopenDialog(true);
  };

  const showInputAmount = (balance) => {
    setbalance(balance)
    setMaxWidth("sm");
    setopenAmountDialog(true);
  };

  const showInputLimitAmount = (balance) => {
    setbalance(balance)
    setMaxWidth("sm");
    setopenLimitAmountDialog(true);
  };

  const showDonationDialog = (balance) => {
    setbalance(balance)
    setMaxWidth("sm");
    setopenDonationDialog(true);
  };

  const backToTopupAmount = () => {
    setopenAmountDialog(true);
    setopenChannelDialog(false)
  };

  const showPaymentChannel = () => {
    setMaxWidth("sm");
    setopenChannelDialog(true);
  };

  const showVaAvailable = () => {
    setMaxWidth("sm");
    setopenVADialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
    deleteLastQueue()
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.balance.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete balance')
      getData();
    } else {
      showError('delete balance')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const gotoTransactionPage = () => {
    const route = '/dashboard/transaction'
    setPreviousMenuActions(activeMenu)
    gotoPage(route)
    history.push(route)
  }

  const submitDonation = async (campaign, amount) => {
    let payload = {
      payment_method_value: 1,
      balance_id: balance.id,
      donation_campaign_id: campaign.id,
      amount: amount,
      parent_id: user.user_type_value === 3 ? user.id : undefined,
    }

    const response = await axios.post(endpoint.donation.user, payload)

    if (response && response.data) {
      showSuccess('donasi dari saldo')
      getData()
    } else {
      showError('donasi dari saldo')
    }
  }

  const submitLimitAmount = async (amount) => {
    let payload = {
      id: balance.id,
      amount: amount
    }

    const response = await axios.put(endpoint.balance.limit, payload)

    if (response && response.data) {
      showSuccess('update limit')
      getData()
    } else {
      showError('update limit')
    }
  }

  const submitPaymentRequest = async (method, channel) => {
    setisLoading(true)
    let payload = {
      payment_channel_id: channel.id,
      balance_id: balance.id,
      user_id: user.id,
      amount: topUpAmount
    }


    const response = await axios.post(endpoint.balance.topUpPaymentRequest, payload)

    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setpaymentRequest(response.data)
        setopenChannelDialog(false)
        showVaAvailable()
      }

    } else {
      showError('request virtual account')
    }

    setisLoading(false)
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getdefaultDailyLimit()
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['WITHDRAWAL']} >
              <SmallButton onClick={() => showDialog('WITHDRAWAL')} variant='contained' color='error'>
                <ActionDisplay code='WITHDRAWAL' />
              </SmallButton>
            </Protected>
          </Grid>
          <Grid item>
            <Protected allowedCodes={['TOPUP']} >
              <SmallButton onClick={() => showDialog('TOPUP')} variant='contained' color='success'>
                <ActionDisplay code='TOPUP' />
              </SmallButton>
            </Protected>
          </Grid>


          <Grid item>
            <SmallButton onClick={gotoTransactionPage} variant='text' color='primary'>
              Transaksi
            </SmallButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
              value={filters.student}
              loadOptions={loadStudentOptions}
              onInputChange={handleInputChangeStudent}
              onChange={(e) => filterChange('student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder="Nama pegawai..."
              value={filters.employee}
              loadOptions={loadEmployeeOptions}
              onInputChange={handleEmployeeInputChange}
              onChange={(e) => filterChange('employee', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='balance_type'
              placeholder='tipe tabungan'
              options={balanceTypeOptions}
              onChange={(e) => filterChange('balance_type', e)}
              styles={defaultSelectStyle}
              value={filters.balance_type}
            />
          </Grid>


        </Grid>



        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton onClick={showSummary} variant='text' color='primary'>
            Ringkasan
          </SmallButton>

          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Tipe</TableCell>
                <TableCell>Saldo</TableCell>
                <TableCell>Sisa harian</TableCell>
                <TableCell>Batas harian</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    {row.student && row.student.name}
                    {row.employee && row.employee.name}
                  </TableCell>
                  <TableCell>
                    <ClickableText text={getbalanceTypeLabel(row.balance_type_value)} onClick={() => showDialog('MUTATION', row)} />
                  </TableCell>
                  <TableCell> {new Intl.NumberFormat().format(row.balance)} </TableCell>
                  <TableCell> {(row.balance < row.sisa) ? new Intl.NumberFormat().format(row.balance) : new Intl.NumberFormat().format(row.sisa)} </TableCell>
                  <TableCell> {row.dailyLimit ? new Intl.NumberFormat().format(row.dailyLimit) : new Intl.NumberFormat().format(defaultDailyLimit)} </TableCell>
                  <TableCell>
                    {
                      user && user.user_type_value === 3 &&
                      <>
                        <SmallButton onClick={() => showDonationDialog(row)} variant='contained' color='primary'>
                          <ActionDisplay code='DONATION' />
                        </SmallButton>
                        <SmallButton onClick={() => showInputAmount(row)} variant='contained' color='success'>
                          Isi saldo
                        </SmallButton>
                      </>

                    }
                    {
                      <Protected allowedCodes={['EDIT']} >
                        <SmallButton onClick={() => showInputLimitAmount(row)} variant='contained'>
                          maksimal jajan
                        </SmallButton>
                      </Protected>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Dialog
      open={openAmountDialog}
      maxWidth={maxWidth}
      onClose={() => setopenAmountDialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <TopupInputAmount
        onClose={() => setopenAmountDialog(false)}
        showPaymentChannel={showPaymentChannel}
        settopUpAmount={settopUpAmount}
      />
    </Dialog>

    <Dialog
      open={openChannelDialog}
      maxWidth={maxWidth}
      onClose={() => setopenChannelDialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <PaymentMethod
        viaBalance={false}
        onSubmit={submitPaymentRequest}
        onBack={backToTopupAmount}
        isLoading={isLoading}
      />
    </Dialog>


    <Dialog
      open={openVADialog}
      maxWidth={maxWidth}
      onClose={() => setopenVADialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <PaymentCharge onClose={() => setopenVADialog(false)} paymentRequest={paymentRequest} />
    </Dialog>

    <Dialog
      open={openDonationDialog}
      maxWidth={maxWidth}
      onClose={() => setopenDonationDialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <DonationInputAmount
        onClose={() => setopenDonationDialog(false)}
        onSubmit={submitDonation}
      />
    </Dialog>

    <Dialog
      open={openLimitAmountDialog}
      maxWidth={maxWidth}
      onClose={() => setopenLimitAmountDialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <InputLimitAmount
        onClose={() => setopenLimitAmountDialog(false)}
        onSubmit={submitLimitAmount}
      />
    </Dialog>

    <Dialog
      open={openSummaryDialog}
      maxWidth='sm'
      onClose={() => setopenSummaryDialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <BalanceSummary
        onClose={() => setopenSummaryDialog(false)}
      />
    </Dialog>

  </>;
}


export default Balance;

