import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import useUI from 'hooks/useUI.js';
import SmallButton from 'components/SmallButton.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import useAuth from 'hooks/useAuth';
import { Button, Chip, Divider, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward, East, Edit, SelectAll } from '@mui/icons-material';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import SelectEmployeeForm from 'views/employee/SelectEmployeeForm';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import Conditional from 'components/Conditional';
import { format, parse } from 'date-fns';

const StudentMutation = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, isAllDataAccess } = useUI()
  const isInitialMount = useRef(true);
  const { detail } = useAuth()
  const defaultFilters = {
    keyword: ''
  }
  const [filters, setFilters] = useState(defaultFilters)
  const timestamp = Date.now();
  const [newPassword, setnewPassword] = useState('')
  const [isChangePassword, setisChangePassword] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [userDetail, setuserDetail] = useState(null);
  const [nextschoolOptions, setnextschoolOptions] = useState([]);
  const [currentschoolOptions, setcurrentschoolOptions] = useState([]);
  const [currentparallelOptions, setcurrentparallelOptions] = useState([]);
  const [currentkelasOptions, setcurrentkelasOptions] = useState([]);
  const [nextkelasOptions, setnextkelasOptions] = useState([]);
  const [tahunLulusOptions, settahunLulusOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [jenjang, setjenjang] = useState(null);
  const [jurusan, setjurusan] = useState(null);
  const [kelas, setkelas] = useState(null);
  const [currentSchool, setcurrentSchool] = useState(null);
  const [currentKelas, setcurrentKelas] = useState(null);
  const [currentParallel, setcurrentParallel] = useState(null);
  const [nextSchool, setnextSchool] = useState(null);
  const [nextKelas, setnextKelas] = useState(null);
  const [currentStudents, setcurrentStudents] = useState([]);
  const [selectedCurrentStudents, setselectedCurrentStudents] = useState([]);
  const [selectedCurrentStudentIds, setselectedCurrentStudentIds] = useState([]);
  const [selectedMovedStudents, setselectedMovedStudents] = useState([]);
  const [selectedMovedStudentIds, setselectedMovedStudentIds] = useState([]);
  const [selectedStayedStudents, setselectedStayedStudents] = useState([]);
  const [selectedStayedStudentIds, setselectedStayedStudentIds] = useState([]);
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [nextTahun, setnextTahun] = useState(null);
  const [currentTahunPelajaran, setcurrentTahunPelajaran] = useState(null);
  const [tahunLulus, settahunLulus] = useState(null);
  const [mutationType, setmutationType] = useState(null);
  const mutationTypeOptions = [
    { value: 1, label: 'Kenaikan kelas' },
    { value: 2, label: 'Kelulusan' },
    { value: 3, label: 'Cuti' },
    { value: 4, label: 'Putus sekolah' },
    { value: 5, label: 'Pindah sekolah' },
    { value: 6, label: 'Dikeluarkan' },
    { value: 7, label: 'Meninggal dunia' },
    { value: 8, label: 'Aktif kembali' },
  ];


  const getStudents = async (parallel_id) => {
    let mutationTypeValue = 1
    if(mutationType){
      mutationTypeValue = mutationType.value===8 ? 0 : 1
    }
    const params = {
      parallel_id: parallel_id,
      isActive: mutationTypeValue,
      not_mutation_tahun_id : nextTahun ? nextTahun.id : undefined
    }

    const response = await axios.get(endpoint.student.option, { params: params });

    if (response && response.data) {
      setcurrentStudents(response.data)
    }
  }

  const getcurrentschoolOptions = async () => {
    let params = {}
    
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }

    const response = await axios.get(endpoint.school.option, {params:params});
    if (response && response.data) {
      setcurrentschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getcurrentkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setcurrentkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getcurrentparallelOptions = async (kelas_id, jurusan_id) => {
    const params = {
      kelas_id: kelas_id
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setcurrentparallelOptions(generalListOptionMapper(response.data));
    }
  };


  const getnextschoolOptions = async () => {
    const response = await axios.get(endpoint.school.option);
    if (response && response.data) {
      setnextschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getnextkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setnextkelasOptions(generalListOptionMapper(response.data));
    }
  };


  const submitStudentMutation = async () => {

    if (mutationType.value === 1) {
      submitKenaikanKelas()
    }
    else if (mutationType.value === 2) {
      submitKelulusan()
    }
    else if (mutationType.value === 3) {
      submitCuti()
    }
    else if (mutationType.value === 4) {
      submitPutus()
    }
    else if (mutationType.value === 5) {
      submitPindah()
    }
    else if (mutationType.value === 6) {
      submitDropout()
    }
    else if (mutationType.value === 7) {
      submitMeninggal()
    }
    else if (mutationType.value === 8) {
      submitActivation()
    }

  }

  const submitKenaikanKelas = async () => {

    if (!nextTahun) {
      showError('pilih tahun pelajaran berikutnya')
      return
    }

    if (!nextSchool) {
      showError('pilih sekolah berikutnya')
      return
    }

    if (!nextKelas) {
      showError('pilih kelas berikutnya')
      return
    }

    let payload = {
      mutation_type_value: 1,
      current_jenjang_id: currentSchool.jenjang_id,
      current_school_id: currentSchool.id,
      current_kelas_id: currentKelas.id,
      next_tahun_id: nextTahun.id,
      next_jenjang_id: nextSchool.jenjang_id,
      next_school_id: nextSchool.id,
      next_kelas_id: nextKelas.id,
      moved_student_ids: selectedMovedStudentIds,
      stayed_student_ids: selectedStayedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitKelulusan = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    if (!tahunLulus) {
      showError('pilih tahun kelulusan')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      tahunLulus: parseInt(tahunLulus.value),
      mutation_type_value: 2,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitPindah = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      mutation_type_value: 5,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitDropout = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      mutation_type_value: 6,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitPutus = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      mutation_type_value: 4,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitCuti = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      mutation_type_value: 3,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitMeninggal = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      mutation_type_value: 7,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const submitActivation = async () => {

    if (!currentTahunPelajaran) {
      showError('pilih tahun pelajaran')
      return
    }

    let payload = {
      tahun_id: currentTahunPelajaran.id,
      mutation_type_value: 8,
      moved_student_ids: selectedMovedStudentIds
    }

    const response = await axios.post(endpoint.student.mutation, payload)

    if (response && response.data) {
      showSuccess(`Mutasi ${process.env.REACT_APP_STUDENT}`)
      reset()
    } else {
      showError(`Mutasi ${process.env.REACT_APP_STUDENT}`)
    }
  }

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const selectAll = () => {
    setselectedCurrentStudents([...currentStudents])
    setselectedCurrentStudentIds(currentStudents.map(student => (student.id)))
  }

  const moveStudents = () => {
    setselectedMovedStudents([...selectedCurrentStudents])
    setselectedMovedStudentIds([...selectedCurrentStudentIds])

    setcurrentStudents(currentStudents.filter(student => (!selectedCurrentStudentIds.includes(student.id))))

    setselectedCurrentStudents([])
    setselectedCurrentStudentIds([])
  }

  const studentStay = () => {
    setselectedStayedStudents([...selectedCurrentStudents])
    setselectedStayedStudentIds([...selectedCurrentStudentIds])

    setcurrentStudents(currentStudents.filter(student => (!selectedCurrentStudentIds.includes(student.id))))

    setselectedCurrentStudents([])
    setselectedCurrentStudentIds([])
  }

  const mutationTypeChange = (e) => {
    setmutationType(e)
    settahunLulus(null)
    setnextTahun(null)
    setcurrentTahunPelajaran(null)
  }

  const currentSchoolChange = (e) => {
    setcurrentSchool(e)
    getcurrentkelasOptions(e.jenjang_id)
  }

  const currentKelasChange = (e) => {
    setcurrentKelas(e)
    getcurrentparallelOptions(e.value)
  }

  const currentParallelChange = (e) => {
    setcurrentParallel(e)
    getStudents(e.value)
  }

  const nextTahunChange = (e) => {
    setnextTahun(e)
  }

  const currentTahunPelajaranChange = (e) => {
    setcurrentTahunPelajaran(e)
    settahunLulus(null)
    settahunLulusOptions([
      {
        'value': format(parse(e.startDate, 'yyyy-MM-dd', new Date()), 'yyyy'),
        'label': format(parse(e.startDate, 'yyyy-MM-dd', new Date()), 'yyyy')
      },
      {
        'value': format(parse(e.endDate, 'yyyy-MM-dd', new Date()), 'yyyy'),
        'label': format(parse(e.endDate, 'yyyy-MM-dd', new Date()), 'yyyy')
      }
    ])
  }

  const tahunLulusChange = (e) => {
    settahunLulus(e)
  }

  const nextSchoolChange = (e) => {
    setnextSchool(e)
    getnextkelasOptions(e.jenjang_id)
  }

  const nextKelasChange = (e) => {
    setnextKelas(e)
  }


  const selectCurrentStudent = (student) => {
    if (!selectedCurrentStudentIds.includes(student.id)) {
      setselectedCurrentStudentIds([...selectedCurrentStudentIds, student.id])
      setselectedCurrentStudents([...selectedCurrentStudents, student])
    } else {
      setselectedCurrentStudentIds(selectedCurrentStudentIds.filter(std => (std !== student.id)))
      setselectedCurrentStudents(selectedCurrentStudents.filter(std => (std.id !== student.id)))
    }
  }



  const reset = () => {
    if (currentParallel) {
      getStudents(currentParallel.id)
    }

    setselectedCurrentStudents([])
    setselectedCurrentStudentIds([])
    setselectedMovedStudents([])
    setselectedMovedStudentIds([])
    setselectedStayedStudents([])
    setselectedStayedStudentIds([])

  }


  useEffect(() => {
    gettahunPelajaranOptions()
    getcurrentschoolOptions()
    getnextschoolOptions()
  }, [])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

          {
            (selectedMovedStudentIds.length > 0 || selectedStayedStudentIds.length > 0) &&
            <Grid item>
              <SmallButton onClick={submitStudentMutation} variant='contained' color='success'>
                Simpan
              </SmallButton>
            </Grid>
          }

        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{ minHeight: 400 }} >
        <Grid item xs={12} container justifyContent='center'>
          <Grid item sm={4} xs={12}>
            <Select
              name="mutation_type"
              placeholder="Jenis mutasi"
              options={mutationTypeOptions}
              value={mutationType}
              onChange={mutationTypeChange}
              styles={defaultSelectStyle}
            />
          </Grid>
        </Grid>

        <Conditional condition={mutationType && mutationType.value === 1}>
          <Grid item xs={12} container justifyContent='flex-start'>
            <Grid item sm={4} xs={12}>
              <Select
                name="tahun"
                placeholder="Tahun Pelajaran berikutnya"
                options={tahunPelajaranOptions}
                value={nextTahun}
                onChange={nextTahunChange}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4} container sx={{ mt: 1 }}>
            <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
              <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
                {`${process.env.REACT_APP_STUDENT} `} aktif saat ini
                <Grid item xs={12} sx={{ my: 1 }}>
                  {
                    currentStudents.length > 0 &&
                    <Button onClick={selectAll} fullWidth variant="contained" startIcon={<SelectAll />}>
                      Pilih semua
                    </Button>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name="school"
                    placeholder="Pilih sekolah saat ini"
                    options={currentschoolOptions}
                    value={currentSchool}
                    onChange={currentSchoolChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name='kelas'
                    placeholder='Pilih kelas saat ini'
                    options={currentkelasOptions}
                    value={currentKelas}
                    onChange={currentKelasChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name='parallel'
                    placeholder='Pilih paralel saat ini'
                    options={currentparallelOptions}
                    value={currentParallel}
                    onChange={currentParallelChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {currentStudents.map((student) => {
                    return (
                      <>
                        <ListItem
                          key={student.id}
                          secondaryAction={
                            <Checkbox
                              edge="end"
                              onChange={(e) => selectCurrentStudent(student)}
                              checked={selectedCurrentStudentIds.indexOf(student.id) !== -1}
                            />
                          }
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemText primary={student.name} />
                          </ListItemButton>
                        </ListItem>
                        <Divider light />
                      </>

                    );
                  })}
                </List>
              </Grid>
            </Paper>

          </Grid>

          <Grid item xs={12} lg={4} container justifyContent='center' alignItems='flex-start' alignContent='flex-start' sx={{ mt: 1 }}>
            <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
              <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
                {`${selectedMovedStudentIds.length} ${process.env.REACT_APP_STUDENT} naik kelas`}
                <Grid item xs={12} sx={{ my: 1 }}>
                  {
                    selectedCurrentStudents.length > 0 &&
                    <Button onClick={moveStudents} fullWidth color='success' variant="contained" startIcon={<ArrowUpward />}>
                      {`${process.env.REACT_APP_STUDENT} `} terpilih Naik kelas
                    </Button>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name="school"
                    placeholder="Pilih sekolah berikutnya"
                    options={nextschoolOptions}
                    value={nextSchool}
                    onChange={nextSchoolChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name='kelas'
                    placeholder='Pilih kelas berikutnya'
                    options={nextkelasOptions}
                    value={nextKelas}
                    onChange={nextKelasChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>

                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {selectedMovedStudents.map((student) => {
                    return (
                      <>
                        <ListItem
                          key={student.id}
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemText primary={student.name} />
                          </ListItemButton>
                        </ListItem>

                        <Divider light />
                      </>
                    );
                  })}
                </List>
              </Grid>
            </Paper>

          </Grid>

          <Grid item xs={12} lg={4} container justifyContent='center' alignItems='flex-start' alignContent='flex-start' sx={{ mt: 1 }}>
            <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
              <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>

                {`${selectedStayedStudentIds.length} ${process.env.REACT_APP_STUDENT} tinggal kelas`}
                <Grid item xs={12} sx={{ my: 1 }}>
                  {
                    selectedCurrentStudents.length > 0 &&
                    <Button onClick={studentStay} fullWidth color='error' variant="contained" startIcon={<ArrowDownward />}>
                      {`${process.env.REACT_APP_STUDENT} `} terpilih Tinggal kelas
                    </Button>
                  }
                  <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {selectedStayedStudents.map((student) => {
                      return (
                        <>
                          <ListItem
                            key={student.id}
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText primary={student.name} />
                            </ListItemButton>
                          </ListItem>
                          <Divider light />
                        </>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Conditional>

        <Conditional condition={mutationType && mutationType.value !== 1}>
          <Grid item xs={12} container justifyContent='flex-start'>
            <Grid item sm={4} xs={12}>
              <Select
                name="tahun"
                placeholder="Tahun Pelajaran"
                options={tahunPelajaranOptions}
                value={currentTahunPelajaran}
                onChange={currentTahunPelajaranChange}
                styles={defaultSelectStyle}
              />
            </Grid>
            <Conditional condition={mutationType && mutationType.value === 2} >
              <Grid item sm={4} xs={12}>
                <Select
                  name="tahun"
                  placeholder="Tahun lulus"
                  options={tahunLulusOptions}
                  value={tahunLulus}
                  onChange={tahunLulusChange}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Conditional>
          </Grid>
          <Grid item xs={12} lg={4} container sx={{ mt: 1 }}>
            <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
              <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
                {`${process.env.REACT_APP_STUDENT} `} saat ini
                <Grid item xs={12} sx={{ my: 1 }}>
                  {
                    currentStudents.length > 0 &&
                    <Button onClick={selectAll} fullWidth variant="contained" startIcon={<SelectAll />}>
                      Pilih semua
                    </Button>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name="school"
                    placeholder="Pilih sekolah saat ini"
                    options={currentschoolOptions}
                    value={currentSchool}
                    onChange={currentSchoolChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name='kelas'
                    placeholder='Pilih kelas saat ini'
                    options={currentkelasOptions}
                    value={currentKelas}
                    onChange={currentKelasChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name='parallel'
                    placeholder='Pilih paralel saat ini'
                    options={currentparallelOptions}
                    value={currentParallel}
                    onChange={currentParallelChange}
                    styles={defaultSelectStyle}
                  />
                </Grid>
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {currentStudents.map((student) => {
                    return (
                      <>
                        <ListItem
                          key={student.id}
                          secondaryAction={
                            <Checkbox
                              edge="end"
                              onChange={(e) => selectCurrentStudent(student)}
                              checked={selectedCurrentStudentIds.indexOf(student.id) !== -1}
                            />
                          }
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemText primary={student.name} />
                          </ListItemButton>
                        </ListItem>
                        <Divider light />
                      </>

                    );
                  })}
                </List>
              </Grid>
            </Paper>

          </Grid>

          <Grid item xs={12} lg={4} container justifyContent='center' alignItems='flex-start' alignContent='flex-start' sx={{ mt: 1 }}>
            <Paper elevation={8} sx={{ minHeight: 300, borderRadius: 4, width: '100%', p: 1 }} >
              <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'>
                {selectedMovedStudentIds.length + ' '}
                <Conditional condition={mutationType && mutationType.value === 2}>
                  {`${process.env.REACT_APP_STUDENT} `} lulus
                </Conditional>
                <Conditional condition={mutationType && mutationType.value === 3}>
                  {`${process.env.REACT_APP_STUDENT} `} cuti
                </Conditional>
                <Conditional condition={mutationType && mutationType.value === 4}>
                  {`${process.env.REACT_APP_STUDENT} `} putus sekolah
                </Conditional>
                <Conditional condition={mutationType && mutationType.value === 5}>
                  {`${process.env.REACT_APP_STUDENT} `} pindah sekolah
                </Conditional>
                <Conditional condition={mutationType && mutationType.value === 6}>
                  {`${process.env.REACT_APP_STUDENT} `} dikeluarkan
                </Conditional>
                <Conditional condition={mutationType && mutationType.value === 7}>
                  {`${process.env.REACT_APP_STUDENT} `} meninggal dunia
                </Conditional>
                <Conditional condition={mutationType && mutationType.value === 8}>
                  {`${process.env.REACT_APP_STUDENT} `} aktif kembali
                </Conditional>
                <Grid item xs={12} sx={{ my: 1 }}>
                  {
                    selectedCurrentStudents.length > 0 &&
                    <Button onClick={moveStudents} fullWidth color='success' variant="contained" startIcon={<ArrowUpward />}>
                      <Conditional condition={mutationType && mutationType.value === 2}>
                        {`Luluskan ${process.env.REACT_APP_STUDENT} terpilih`}
                      </Conditional>
                      <Conditional condition={mutationType && mutationType.value === 3}>
                        {`${process.env.REACT_APP_STUDENT} `} terpilih cuti
                      </Conditional>
                      <Conditional condition={mutationType && mutationType.value === 4}>
                        {`${process.env.REACT_APP_STUDENT} `} terpilih putus sekolah
                      </Conditional>
                      <Conditional condition={mutationType && mutationType.value === 5}>
                        {`${process.env.REACT_APP_STUDENT} `} terpilih pindah sekolah
                      </Conditional>
                      <Conditional condition={mutationType && mutationType.value === 6}>
                        {`${process.env.REACT_APP_STUDENT} `} terpilih dikeluarkan
                      </Conditional>
                      <Conditional condition={mutationType && mutationType.value === 7}>
                        {`${process.env.REACT_APP_STUDENT} `} terpilih meninggal dunia
                      </Conditional>
                      <Conditional condition={mutationType && mutationType.value === 8}>
                        {`${process.env.REACT_APP_STUDENT} `} terpilih aktif kembali
                      </Conditional>
                    </Button>
                  }
                </Grid>

                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {selectedMovedStudents.map((student) => {
                    return (
                      <>
                        <ListItem
                          key={student.id}
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemText primary={student.name} />
                          </ListItemButton>
                        </ListItem>

                        <Divider light />
                      </>
                    );
                  })}
                </List>
              </Grid>
            </Paper>

          </Grid>
        </Conditional>



      </Grid>

    </Grid>


  </>;
}


export default StudentMutation;

