import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import TransactionDetail from './TransactionDetail';
import TransactionIncome from './TransactionIncome';
import TransactionExpense from './TransactionExpense';
import useUI from 'hooks/useUI';
import TransactionTransfer from './TransactionTransfer';
import TransactionEquity from './TransactionEquity';

// ----------------------------------------------------------------------

export default function TransactionForm(props) {
  const { row, pageAction } = props
  const { startLoading, stopLoading, activeMenu = {} } = useUI()
  const [currentTab, setCurrentTab] = useState('detail');

  let TABS = [
    {
      value: 'detail',
      component: <TransactionDetail {...props} />
    }
  ];

  if (pageAction === 'INCOME') { 
    TABS = [
      {
        value: 'Penerimaan',
        component: <TransactionIncome {...props} />
      }
    ];
  }
  else if (pageAction === 'EXPENSE') { 
    TABS = [
      {
        value: 'Pengeluaran',
        component: <TransactionExpense {...props} />
      }
    ];
  }
  else if (pageAction === 'TRANSFER') { 
    TABS = [
      {
        value: 'Transfer dana',
        component: <TransactionTransfer {...props} />
      }
    ];
  }
  else if (pageAction === 'EQUITY') { 
    TABS = [
      {
        value: 'Modal kas',
        component: <TransactionEquity {...props} />
      }
    ];
  }
  
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if(pageAction==='INCOME'){
      setCurrentTab('Penerimaan')
    }
    else if(pageAction==='EXPENSE'){
      setCurrentTab('Pengeluaran')
    }
    else if(pageAction==='TRANSFER'){
      setCurrentTab('Transfer dana')
    }
    else if(pageAction==='EQUITY'){
      setCurrentTab('Modal kas')
    }
    

  }, [pageAction])

  return (
    <Container>
      <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} style={{ padding: 8 }}>
        <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
