import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { achievementParticipantOptions, achievementTypeOptions, getachievementTypeLabel, getachievementTypeObject, getparticipantTypeLabel, getparticipantTypeObject, participantTypeOptions, userTypeOptions } from 'utils/options';
import { Checkbox } from '@mui/material';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import useSetting from 'hooks/useSetting';


const AchievementDetail = (props) => {
  const { row = {}, getData } = props
  const { user, detail } = useAuth()
  const { tahunPelajaran } = useSetting()
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      participant_type: null,
      participant_type_value: undefined,
      achievement_type: null,
      achievement_type_value: undefined,
      tahun: null,
      tahun_id: undefined,
      student: null,
      student_id: undefined,
      students: [],
      student_ids: [],
      title: undefined,
      description: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [rewardTypeOptions, setrewardTypeOptions] = useState([])


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'participant_type') {
      newState['participant_type_value'] = value ? value.value : undefined
      if (value.value === 2) {
        newState['student'] = null
        newState['student_id'] = undefined
      }
    }
    else if (fieldName === 'achievement_type') {
      newState['achievement_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'tahun') {
      newState['tahun_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'students') {
      newState['student_ids'] = value.map(student => (student.id))
    }
    else if (fieldName === 'reward_type') {
      newState['reward_type_id'] = value ? value.id : undefined
    }


    newState[fieldName] = value
    setstate(newState)
  }

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };


  const getrewardTypeOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.reward.typeOption, { params: params });
    if (response && response.data) {
      setrewardTypeOptions(generalListOptionMapper(response.data));
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.achievement.root, payload)
    if (response && response.data) {
      showSuccess('save achievement')
      getData()
    } else {
      showError('save achievement')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.achievement.root, payload)
    if (response && response.data) {
      showSuccess('save achievement')
      getData()
    } else {
      showError('save achievement')
    }
  }


  const submit = async () => {
    const params = {
      ...state,
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      student: generalOptionMapper(row.student),
      students: studentListOptionMapper(row.students),
      tahun: generalOptionMapper(row.tahun),
      achievement_type: getachievementTypeObject(row.achievement_type_value),
      participant_type: getparticipantTypeObject(row.participant_type_value),
      reward_type: generalOptionMapper(row.reward_type),
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      gettahunPelajaranOptions()
      getrewardTypeOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  useEffect(() => {
    if (tahunPelajaran && pageAction !== 'READ') {
      handleChange('tahun', generalOptionMapper(tahunPelajaran))
    }
  }, [tahunPelajaran, pageAction])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama prestasi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.title} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='title'
                  fullWidth
                  value={state.title}
                  onChange={(e) => handleChange('title', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Keterangan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun Pelajaran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.tahun && state.tahun.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="tahun"
                  placeholder="Pilih Tahun Pelajaran"
                  options={tahunPelajaranOptions}
                  value={state.tahun}
                  onChange={(e) => handleChange("tahun", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe prestasi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getachievementTypeLabel(state.achievement_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="achievement achievement "
                  placeholder="Pilih tipe prestasi"
                  options={achievementTypeOptions}
                  value={state.achievement_type}
                  onChange={(e) => handleChange("achievement_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe peserta
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getparticipantTypeLabel(state.participant_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="achievement participant "
                  placeholder="Pilih tipe peserta"
                  options={participantTypeOptions}
                  value={state.participant_type}
                  onChange={(e) => handleChange("participant_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Conditional condition={state.participant_type_value === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              {
                process.env.REACT_APP_STUDENT
              }
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={state.student && state.student.name} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <AsyncSelect
                    cacheOptions
                    placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                    value={state.student}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={(e) => handleChange('student', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.participant_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              {
                process.env.REACT_APP_STUDENT
              }
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                    value={state.students}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                    value={state.students}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={(e) => handleChange('students', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Penghargaan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.reward_type && state.reward_type.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='reward'
                  placeholder='Pilih penghargaan'
                  options={rewardTypeOptions}
                  value={state.reward_type}
                  onChange={(e) => handleChange('reward_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default AchievementDetail;

