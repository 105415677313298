import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Alert, AlertTitle, Button, Input } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import { parameterListOptionMapper } from 'utils/mappers';
import { Verified } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PsbExam = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showError } = useUI()
  const { user, detail } = useAuth()
  const [documentTypeOptions, setdocumentTypeOptions] = useState([])
  const [newStudentFiles, setnewStudentFiles] = useState([])
  const [selectedFile, setselectedFile] = useState(null)
  const [studentPsbFlows, setstudentPsbFlows] = useState([])
  const [currentPsbFlow, setcurrentPsbFlow] = useState(null)
  const [selectedPsbFlow, setselectedPsbFlow] = useState(null)
  const [lastPsbFlow, setlastPsbFlow] = useState(null)
  const [isDone, setisDone] = useState(false)
  const [status, setstatus] = useState(0)
  const timestamp = Date.now();
  const [isCurrentFlow, setisCurrentFlow] = useState(false)
  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const [newStudentPhoto, setnewStudentPhoto] = useState('assets/undraw_welcoming_re_x0qo.svg')
  const query = useQuery();

  const getnewStudentOptions = async () => {
    let params = {
      user_id: user.id
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      setnewStudentOptions(response.data)
      let new_student_id = query.get('new_student_id')


      if (response.data[0]) {
        if (new_student_id) {
          setselectedNewStudent(response.data.filter(newStudent => (newStudent.id === parseInt(new_student_id)))[0])
        } else {
          setselectedNewStudent(response.data[0])
        }

      }

    } else {
      showError('get new student')
    }
  }

  const newStudentClick = (newStudent) => {
    setselectedNewStudent(newStudent)
  }

  const isThisCurrentFlow = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id,
      flow_id: query.get('id')
    }
    const response = await axios.get(endpoint.psbFlow.isCurrent, { params: params })

    if (response) {
      if (response.data === 1) {
        setisCurrentFlow(true)
      } else {
        setisCurrentFlow(false)
      }
    } else {
      showError('get student psb flow')
    }
  }

  const getStudentPsbFlows = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id
    }
    const response = await axios.get(endpoint.newStudent.psbFlow, { params: params })

    if (response && response.data) {
      if (response.data[0]) {
        setlastPsbFlow(response.data[response.data.length - 1])
      }
      setstudentPsbFlows(response.data.map(flow => flow.id))
      const selectedFlow = response.data.filter(flow => flow.code === query.get('code'))[0]
      if (selectedFlow) {
        setisDone(true)
        setselectedPsbFlow(selectedFlow)
        if (selectedFlow.pivot) {
          setstatus(selectedFlow.pivot.status_value)
        }
      } else {
        setisDone(false)
        setstatus(false)
      }
    } else {
      showError('get student psb flow')
    }
  }

  const getcurrentPsbFlow = async (new_student_id) => {

    const response = await axios.get(endpoint.newStudent.currentPsbFlow.replace('new_student_id', new_student_id))

    if (response) {
      setcurrentPsbFlow(response.data)
    } else {
      showError('get current psb flow')
    }
  }

  useEffect(() => {
    if (!query.get('code')) {
      gotoPage('/dashboard')
    }
  }, [])

  useEffect(() => {
    if (detail) {
      getnewStudentOptions()
    }
  }, [detail])

  useEffect(() => {
    if (selectedNewStudent) {
      isThisCurrentFlow(selectedNewStudent.id)
      getStudentPsbFlows(selectedNewStudent.id)
      getcurrentPsbFlow(selectedNewStudent.id)
      if (selectedNewStudent.photo) {
        setnewStudentPhoto(selectedNewStudent.photo)
      }
    } 
  }, [selectedNewStudent])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>


        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>


      </Grid>

      <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400 }} >
        <Grid xs={12}>
          {
            currentPsbFlow && lastPsbFlow && currentPsbFlow.id !== lastPsbFlow.id &&
            <Alert severity="info"
              sx={{
                borderRadius: 4
              }}>
              <AlertTitle>Informasi</AlertTitle>
              Proses selanjutnya adalah <strong>{currentPsbFlow.name}</strong>
            </Alert>
          }

          {
            currentPsbFlow && lastPsbFlow && currentPsbFlow.id === lastPsbFlow.id &&
            <Alert severity="info"
              sx={{
                borderRadius: 4
              }}>
              <AlertTitle>Informasi</AlertTitle>
              anda sudah selesai <strong>{currentPsbFlow.name}</strong>
            </Alert>
          }


        </Grid>

        <VerticalPillSelection
          selected={selectedNewStudent}
          options={newStudentOptions}
          onSelection={newStudentClick}
        />

        {
          !isCurrentFlow &&
          <Grid container direction='column' spacing={2} sx={{ mt: 2 }}>
            <Grid item container justifyContent='center'>
              Mohon selesaikan proses sebelumnya
            </Grid>
            <Grid item container justifyContent='center'>
              <img src={`${api.url}/assets/undraw_process_re_gws7.svg`} style={{ width: 200, height: 200 }} />
            </Grid>
            <Grid item container justifyContent='center'>
              Anda dapat melihat tahapannya di dashboard
            </Grid>
          </Grid>
        }

        {
          isCurrentFlow &&
          <Grid item xs={12} md={8} container sx={{ mt: 2 }}>
            {
              !isDone &&
              <Grid item xs={12} container justifyContent='center'>
                <Grid item xs={12} container justifyContent='center'>
                  <img src={`${api.url}/${newStudentPhoto}`} style={{ width: 200, height: 200 }} />
                </Grid>
                <Grid item xs={12} md={8} container sx={{ my: 2, p: 2, borderRadius: 4, border: 'solid 1px #6a63ff' }}>
                  <Grid container justifyContent='center'>
                    <Typography color='primary' variant='caption' component='h6'>
                      Nomor Peserta
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <Typography color='primary' component='h5' variant='h5'>
                      {selectedNewStudent && selectedNewStudent.examParticipantNumber}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <Typography color='primary' component='h6' variant='h6'>
                      {selectedNewStudent && selectedNewStudent.school && selectedNewStudent.school.name}
                    </Typography>
                  </Grid>
                </Grid>
                
                
              </Grid>

            }

            {
              isDone && status === 1 &&
              <Grid item xs={12} container justifyContent='center'>
                <Grid item xs={12} container justifyContent='center'>
                  <img src={`${api.url}/${newStudentPhoto}`} style={{ width: 200, height: 200 }} />
                </Grid>
                
                <Grid item xs={12} md={8} container sx={{ my: 2, p: 2, borderRadius: 4, border: 'solid 1px #6a63ff' }}>
                  <Grid container justifyContent='center'>
                    <Typography color='primary' variant='caption' component='h6'>
                      Nomor Peserta
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <Typography color='primary' component='h5' variant='h5'>
                      {selectedNewStudent && selectedNewStudent.examParticipantNumber}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <Typography color='primary' component='h6' variant='h6'>
                      {selectedNewStudent && selectedNewStudent.school && selectedNewStudent.school.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                  <Typography color='primary' component='h6' variant='h6'>
                    Selamat kamu berhasil {selectedPsbFlow && selectedPsbFlow.name}
                  </Typography>
                </Grid>
              </Grid>

            }


            {
              isDone && status === 0 &&
              <Grid item xs={12} container >
                <Grid item xs={12} container justifyContent='center' sx={{ mb: 2 }}>
                  <Box display='flex' justifyContent='center' alignItems='center' sx={{ p: 2, height: 50, borderRadius: 4, border: 'solid 1px #6a63ff' }}>
                    <Typography color='primary' component='h6' variant='h6'>
                      Tetap semangat, kamu belum berhasil {selectedPsbFlow && selectedPsbFlow.name} kali ini
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} container justifyContent='center'>
                  <img src={`${api.url}/assets/undraw_stepping_up_g6oo.svg`} style={{ width: 200, height: 200 }} />
                </Grid>
              </Grid>
            }
          </Grid>

        }


      </Grid>
    </Grid>

  </>;
}


export default PsbExam;

