import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'


const PsbFlowDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      code: undefined,
      instruction: undefined,
      order: undefined,
      path: undefined,
      isExam: 0,
      isBillable: 0,
      isAdminDecision: 0,
      school: null,
      school_id: undefined,
      bill_type: null,
      bill_type_id: undefined,
      isForNew: 1,
      isForOld: 1,

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [schoolOptions, setschoolOptions] = useState([]);
  const [psbYear, setpsbYear] = useState(null);
  const [bill_typeOptions, setbill_typeOptions] = useState([])
  const [examTypeOptions, setexamTypeOptions] = useState([])

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'isForOld') {
      value = eventValue.target.checked ? 1 : 0;
      if(value===0){
        newState['isForNew'] = 1
      }
    }
    else if (fieldName === 'isForNew') {
      value = eventValue.target.checked ? 1 : 0;
      if(value===0){
        newState['isForOld'] = 1
      }
    }
    else if (fieldName === 'isBillable') {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 0) {
        newState['bill_type'] = null
        newState['bill_type_id'] = undefined
      }
    }
    else if (fieldName === 'isExam') {
      value = eventValue.target.checked ? 1 : 0;
      if (value === 0) {
        newState['exam_type'] = null
        newState['exam_type_value'] = undefined
      }
    }
    else if (fieldName === 'school') {
      newState['school_id'] = value.value
    }
    else if (fieldName === 'bill_type') {
      newState['bill_type_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'exam_type') {
      newState['exam_type_value'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'isAdminDecision') {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getPsbYear = async () => {
    const params = {}
    const response = await axios.get(endpoint.psb.year, { params: params });
    if (response && response.data) {
      setpsbYear(generalOptionMapper(response.data));
    }
  };

  const getexamTypeOptions = async () => {
    const params = {
      group: 'psb_exam_type'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setexamTypeOptions(parameterListOptionMapper(response.data));
    }
  };

  const getbill_typeOptions = async (school_id, tahun_id) => {
    const params = {
      school_id: school_id,
      tahun_id: tahun_id
    }
    const response = await axios.get(endpoint.billType.option, { params: params });
    if (response && response.data) {
      setbill_typeOptions(generalListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.psbFlow.root, payload)
    if (response && response.data) {
      showSuccess('save psbFlow')
      getData()
    } else {
      showError('save psbFlow')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.psbFlow.root, payload)
    if (response && response.data) {
      showSuccess('save psbFlow')
      getData()
    } else {
      showError('save psbFlow')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      school: generalOptionMapper(row.school),
      bill_type: generalOptionMapper(row.bill_type),
      exam_type: parameterOptionMapper(row.exam_type),
    })
  }

  useEffect(() => {
    getPsbYear()
  }, [])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getschoolOptions()
      getexamTypeOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  useEffect(() => {
    if (state.school_id && psbYear) {
      getbill_typeOptions(state.school_id, psbYear.id)
    }
  }, [state.school_id, psbYear])

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              {
                process.env.REACT_APP_SCHOOL_MODE==='PESANTREN' && 'Tahun PSB'
              }
              {
                process.env.REACT_APP_SCHOOL_MODE==='SEKOLAH' && 'Tahun PPDB'
              }
            </Grid>
            <Grid item sm={8} xs={12}>
              <ReadOnlyTextField value={psbYear && psbYear.name} />
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.school && state.school.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='school'
                  placeholder='Pilih sekolah'
                  options={schoolOptions}
                  value={state.school}
                  onChange={(e) => handleChange('school', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor tahapan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.order} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="order"
                  type="number"
                  fullWidth
                  value={state.order}
                  onChange={(e) => handleChange("order", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Instruksi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.instruction} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.instruction}
                  onChange={(e) => handleChange('instruction', e)}
                  multiline={true}
                  rows={2}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Path
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.path} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='path'
                  fullWidth
                  value={state.path}
                  onChange={(e) => handleChange('path', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              {`Jenis ${process.env.REACT_APP_STUDENT}`}
            </Grid>
            <Grid item sm={8} xs={12} container>
              <Conditional condition={pageAction === "READ"}>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox checked={state.isForOld === 1} />} label="Lama" />
                  <FormControlLabel control={<Checkbox checked={state.isForNew === 1} />} label="Baru" />
                </FormGroup>
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
              <FormGroup row>
                  <FormControlLabel control={<Checkbox checked={state.isForOld === 1} onChange={(e) => handleChange("isForOld", e)}/>} label="Lama" />
                  <FormControlLabel control={<Checkbox checked={state.isForNew === 1} onChange={(e) => handleChange("isForNew", e)}/>} label="Baru" />
                </FormGroup>
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Melakukan pembayaran
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isBillable === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isBillable === 1}
                  onChange={(e) => handleChange("isBillable", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.isBillable === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Pembayaran
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.bill_type && state.bill_type.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='bill_type'
                    placeholder='Pilih jenis pembayaran'
                    options={bill_typeOptions}
                    value={state.bill_type}
                    onChange={(e) => handleChange('bill_type', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Test / ujian
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isExam === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isExam === 1}
                  onChange={(e) => handleChange("isExam", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.isExam === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jenis test / ujian
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.exam_type && state.exam_type.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='exam_type'
                    placeholder='Pilih jenis ujian'
                    options={examTypeOptions}
                    value={state.exam_type}
                    onChange={(e) => handleChange('exam_type', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Diputuskan oleh admin
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isAdminDecision === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isAdminDecision === 1}
                  onChange={(e) => handleChange("isAdminDecision", e)}
                />
              </Conditional>
            </Grid>
          </Grid>





        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default PsbFlowDetail;

