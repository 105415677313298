import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Typography } from '@mui/material';


const BillInstallmentForm = (props) => {
  const { handleClose, action, actionName, title, handleChange, installmentAmount, bill } = props
  const onAction = () => {
    if(installmentAmount>0){
      action()
    }
    
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} justifyContent='center' alignItems='center' >
          <Grid item xs={12}>
            {
              bill && bill.bill_type && (bill.bill_type.minimumCicil > 0) && 
              `Miminum pembayaran ${new Intl.NumberFormat().format(bill.bill_type.minimumCicil)}`
            }
          </Grid >
          <Grid item>
            <Typography variant="h5" component="div">
              Jumlah cicilan
            </Typography>
          </Grid >
          
          <Grid item>
            <TextField
              type='number'
              fullWidth
              onChange={handleChange}
              value={installmentAmount.toString()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAction} color="secondary">
          {actionName}
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          Batal
        </Button>
      </DialogActions>
    </>

  );
}

export default BillInstallmentForm;
