import React,{useState} from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const YesNoCancelConfirmation =(props)=> {
  const {handleClose, yesAction, noAction, yesLabel, noLabel, message, title} = props

  const onYesAction = ()=>{
    yesAction()
    handleClose()
  }

  const onNoAction = ()=>{
    noAction()
    handleClose()
  }

  return (
  <>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  variant="contained" onClick={onYesAction} color="primary">
          {yesLabel}
        </Button>
        <Button  variant="contained" onClick={onNoAction} color="secondary">
          {noLabel}
        </Button>
        <Button onClick={handleClose}>
          Batal
        </Button>
      </DialogActions>
  </>

    );
}

export default YesNoCancelConfirmation;
