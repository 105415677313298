import React,{useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';


const DeleteConfirmation =(props)=> {
  const {handleClose, handleDelete, selectedIds, title} = props

  const onDelete = ()=>{
    handleDelete()
    handleClose()
  }

  return (
  <>
      <DialogTitle id="form-dialog-title">Konfirmasi Hapus</DialogTitle>
      <DialogContent>
        <DialogContentText> apakah anda yakin menghapus {selectedIds.length} {title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={onDelete} color="secondary">
          Hapus
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          Batal
        </Button>
      </DialogActions>
  </>

    );
}

export default DeleteConfirmation;
