import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Box, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper, studentListOptionMapper } from 'utils/mappers';
import BooleanChip from 'components/BooleanChip';
import { DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format, parse } from 'date-fns'
import AsyncSelect from 'react-select/async';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import useSetting from 'hooks/useSetting';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from 'hooks/useAuth';


const AgendaDetail = (props) => {
  const { row = {}, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError, isAllDataAccess } = useUI()
  const { tahunPelajaran } = useSetting()
  const reset = () => {
    return {
      id: undefined,
      image:undefined,
      tahun: null,
      tahun_id: undefined,
      agenda_type_value: undefined,
      name: undefined,
      description: undefined,
      building_id: undefined,
      room_id: undefined,
      location: undefined,
      startDateTime: new Date(),
      endDateTime: new Date(),
      isHoliday: 0,
      participant_level_value: undefined,
      school_participants: [],
      kelas_participants: [],
      jurusan_participants: [],
      parallel_participants: [],
      student_participants: [],
      employee_participants: [],
      parent_participants: [],
      isImportant: undefined,
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [agendaTypeOptions, setagendaTypeOptions] = useState([])
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [buildingOptions, setbuildingOptions] = useState([]);
  const [roomOptions, setroomOptions] = useState([]);
  const [participantLevelOptions, setparticipantLevelOptions] = useState([]);
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [nameParentKeyWord, setnameParentKeyWord] = useState('');

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue
    if (fieldName === 'head_agenda') {
      newState['head_agenda_id'] = eventValue ? eventValue.value : eventValue
    }
    else if (fieldName === 'agenda_type') {
      newState['agenda_type_value'] = value.value
    }
    else if (fieldName === 'participant_level') {
      newState['participant_level_value'] = value.value
      if (value.code === 'SCHOOL') {
        getschoolOptions()
      }
      else if (value.code === 'KELAS') {
        getkelasOptions()
      }
      else if (value.code === 'PARALLEL') {
        getparallelOptions()
      }
      else if (value.code === 'JURUSAN') {
        getjurusanOptions()
      }

    }
    else if (fieldName === 'building') {
      newState['building_id'] = value.value
      getroomOptions(value.value)
    }
    else if (fieldName === 'room') {
      newState['room_id'] = value.value
    }
    else if (fieldName === 'tahun') {
      newState['tahun_id'] = value.value
    }
    else if (["isImportant", "isHoliday"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getparallelOptions = async () => {
    let params = {}
    
    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getParentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.parent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const selectImage = async (event) => {
    let file = event.target.files[0]
    setstate({
      ...state,
      file: file,
      image: URL.createObjectURL(file)
    })
  };

  const handleInputChangeEmployee = (newValue) => {
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeParent = (newValue) => { 
    setnameParentKeyWord(newValue)
    return newValue;
  };

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const loadParentOptions = async (inputValue, callback) => {
    callback(await getParentOptions(inputValue))
  };

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const getagendaTypeOptions = async () => {
    const params = {
      group: 'agenda_type'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setagendaTypeOptions(parameterListOptionMapper(response.data));
    }
  };

  const getparticipantLevelOptions = async () => {
    const params = {
      group: 'participant_level'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setparticipantLevelOptions(parameterListOptionMapper(response.data));
    }
  };

  const getbuildingOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.building.option, { params: params });
    if (response && response.data) {
      setbuildingOptions(generalListOptionMapper(response.data));
    }
  };

  const getroomOptions = async (building_id) => {
    const params = {
      building_id: building_id
    }
    const response = await axios.get(endpoint.room.option, { params: params });
    if (response && response.data) {
      setroomOptions(generalListOptionMapper(response.data));
    }
  };
 

  const submit = async () => {
    const formData = new FormData();
  
    if (row) {
      formData.append('id', row.id)
    }
    if (state.file) {
      formData.append('file', state.file)
    }
    
    formData.append('name', state.name) 
    formData.append('tahun_id', state.tahun_id)
    formData.append('agenda_type_value', state.agenda_type_value)
    formData.append('description', state.description)
    formData.append('building_id', state.building_id)
    formData.append('room_id', state.room_id)
    formData.append('location', state.location)
    formData.append('isHoliday', state.isHoliday)
    formData.append('isImportant', state.isImportant)
    formData.append('startDateTime', format(state.startDateTime, 'yyyy-MM-dd HH:mm:ss'))
    formData.append('endDateTime', format(state.endDateTime, 'yyyy-MM-dd HH:mm:ss'))

    
    const response = await axios.post(endpoint.agenda.root, formData, {});
    if (response) {
      showSuccess('save agenda')
      getData()
    } else {
      showError('save agenda')
    }
 
  }

  const getRowDetail = async () => {
    setstate({
      ...row, 
      agenda_type: parameterOptionMapper(row.agenda_type),
      tahun: generalOptionMapper(row.tahun),
      startDateTime:parse(row.startDateTime, 'yyyy-MM-dd HH:mm:ss', new Date()),
      endDateTime:parse(row.endDateTime, 'yyyy-MM-dd HH:mm:ss', new Date()),
      image: `${api.url}/${row.image}`,
      building:generalOptionMapper(row.building),
      room:generalOptionMapper(row.room),
    })
  }


  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getparticipantLevelOptions()
      gettahunPelajaranOptions()
      getagendaTypeOptions()
      getbuildingOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])


  useEffect(() => {
    if (tahunPelajaran && pageAction !== 'READ') {
      handleChange('tahun', generalOptionMapper(tahunPelajaran))
    }
  }, [tahunPelajaran, pageAction])

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Gambar
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box sx={{ width: '100%', position: 'relative' }}>
                <img
                  src={state.image}
                  style={{ width: '100%', height: 'auto' }} />
                <Conditional condition={pageAction !== 'READ'}>
                  <label htmlFor='propic'>
                    <input onChange={selectImage} accept="image/*" id='propic' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                  </label>
                </Conditional>

              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Deskripsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>
 

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tahun Pelajaran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.tahun && state.tahun.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="tahun"
                  placeholder="Pilih Tahun Pelajaran"
                  options={tahunPelajaranOptions}
                  value={state.tahun}
                  onChange={(e) => handleChange("tahun", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kategori agenda
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.agenda_type && state.agenda_type.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="agenda_type"
                  placeholder="Agenda Type"
                  options={agendaTypeOptions}
                  value={state.agenda_type}
                  onChange={(e) => handleChange("agenda_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Building
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.building && state.building.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="building"
                  placeholder="Pilih building..."
                  options={buildingOptions}
                  value={state.building}
                  onChange={(e) => handleChange("building", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Room
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.room && state.room.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="room"
                  placeholder="Pilih room..."
                  options={roomOptions}
                  value={state.room}
                  onChange={(e) => handleChange("room", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Lokasi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.location} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='location'
                  fullWidth
                  value={state.location}
                  onChange={(e) => handleChange('location', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item  xs={12} container justifyContent='center' alignItems='center'>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Mulai
              </Grid>
              <Grid item xs={8}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={state.startDateTime && format(state.startDateTime, 'yyyy-MM-dd HH:mm:ss')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DateTimePicker
                    inputFormat='yyyy/MM/dd HH:mm'
                    ampm={false}
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.startDateTime}
                    onChange={(e) => handleChange("startDateTime", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>

            <Grid item  xs={12} container justifyContent='center' alignItems='center'>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Selesai
              </Grid>
              <Grid item xs={8}>
                <Conditional condition={pageAction === "READ"}>
                  <ReadOnlyTextField value={state.endDateTime && format(state.endDateTime, 'yyyy-MM-dd HH:mm:ss')} />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <DateTimePicker
                    inputFormat='yyyy/MM/dd HH:mm'
                    ampm={false}
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.endDateTime}
                    onChange={(e) => handleChange("endDateTime", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Libur
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isHoliday === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isHoliday === 1}
                  onChange={(e) => handleChange("isHoliday", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Penting
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isImportant === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isImportant === 1}
                  onChange={(e) => handleChange("isImportant", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </LocalizationProvider>;
}

export default AgendaDetail;

