import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import Header from './Header';
import CallToAction from './CallToAction';
import Schools from './Schools';
import Vision from './Vision';
import Activities from './Activities';
import Footer from './Footer';
import Featured from './Featured';
import { useHistory } from 'react-router-dom';
import MobileApp from './MobileApp';
import Facilities from './Facilities';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import useUI from 'hooks/useUI';


const Home = (props) => {
  const history = useHistory()
  const { showSuccess, showError } = useUI()
  const [state, setstate] = useState({
    callToActionTitle: '',
    callToActionContent: '',
    callToActionImage: '',
    mobileAppTitle: '',
    mobileAppContent: '',
    mobileAppImage: '',
    featuredTitle: '',
    featuredContent: ``,
    featuredImage: '',
    visionTitle: '',
    visionContent: '',
    visionImage: '',
    missionTitle: '',
    missionContent: ``,
    missionImage: '',
    activitiesTitle: '',
    activitiesContent: ``,
    activitiesImage1: '',
    activitiesImage2: '',
    activitiesImage3: '',
    activitiesImage4: '',
    activitiesImageTitle1: '',
    activitiesImageTitle2: '',
    activitiesImageTitle3: '',
    activitiesImageTitle4: '',
    facilitiesTitle: '',
    facilitiesContent: ``,
    facilitiesImage1: '',
    facilitiesImage2: '',
    facilitiesImage3: '',
    facilitiesImage4: '',
    facilitiesImageTitle1: '',
    facilitiesImageTitle2: '',
    facilitiesImageTitle3: '',
    facilitiesImageTitle4: '',
    address: '',
    phone: '',
    backgroundColor:'white'
  })
  const [schoolOptions, setschoolOptions] = useState([]);
  const [isPsbOpen, setisPsbOpen] = useState(0);


  const getPsbOpenStatus = async () => {
    const params = {}
    const response = await axios.get(endpoint.psb.open, { params: params });
    if (response && response.data) {
      setisPsbOpen(response.data)
    }
  };
  
  const getschoolOptions = async () => {
        const params = {}
        const response = await axios.get(endpoint.school.option, { params: params });
        if (response && response.data) {
          setschoolOptions(
              response.data.map(data=>({
                ...data,
                logo: `${api.url}/${data.logo}`
              })))
        }
      };

  const getData = async () => {
    const response = await axios.get(endpoint.setting.home)
    if (response) {
      let data = response.data
      let newState = { ...state }
      data.forEach(setting => {
        if (setting.code === 'HOME_CALL_ACTION_TITLE') {
          newState['callToActionTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_CALL_ACTION_CONTENT') {
          newState['callToActionContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_CALL_ACTION_IMAGE') {
          newState['callToActionImage'] = setting.stringValue
        }
        else if (setting.code === 'HOME_MOBILE_APP_TITLE') {
          newState['mobileAppTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_MOBILE_APP_CONTENT') {
          newState['mobileAppContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_MOBILE_APP_IMAGE') {
          newState['mobileAppImage'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FEATURED_TITLE') {
          newState['featuredTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FEATURED_CONTENT') {
          newState['featuredContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FEATURED_IMAGE') {
          newState['featuredImage'] = setting.stringValue
        }
        else if (setting.code === 'HOME_VISION_TITLE') {
          newState['visionTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_VISION_CONTENT') {
          newState['visionContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_VISION_IMAGE') {
          newState['visionImage'] = setting.stringValue
        }
        else if (setting.code === 'HOME_MISSION_TITLE') {
          newState['missionTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_MISSION_CONTENT') {
          newState['missionContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_MISSION_IMAGE') {
          newState['missionImage'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_TITLE') {
          newState['activitiesTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_CONTENT') {
          newState['activitiesContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_1') {
          newState['activitiesImage1'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_2') {
          newState['activitiesImage2'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_3') {
          newState['activitiesImage3'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_4') {
          newState['activitiesImage4'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_TITLE_1') {
          newState['activitiesImageTitle1'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_TITLE_2') {
          newState['activitiesImageTitle2'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_TITLE_3') {
          newState['activitiesImageTitle3'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ACTIVITIES_IMAGE_TITLE_4') {
          newState['activitiesImageTitle4'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_TITLE') {
          newState['facilitiesTitle'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_CONTENT') {
          newState['facilitiesContent'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_1') {
          newState['facilitiesImage1'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_2') {
          newState['facilitiesImage2'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_3') {
          newState['facilitiesImage3'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_4') {
          newState['facilitiesImage4'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_TITLE_1') {
          newState['facilitiesImageTitle1'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_TITLE_2') {
          newState['facilitiesImageTitle2'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_TITLE_3') {
          newState['facilitiesImageTitle3'] = setting.stringValue
        }
        else if (setting.code === 'HOME_FACILITIES_IMAGE_TITLE_4') {
          newState['facilitiesImageTitle4'] = setting.stringValue
        }
        else if (setting.code === 'HOME_ADDRESS') {
          newState['address'] = setting.stringValue
        }
        else if (setting.code === 'HOME_PHONE') {
          newState['phone'] = setting.stringValue
        }
        else if (setting.code === 'HOME_BACKGROUND_COLOR') {
          newState['backgroundColor'] = setting.stringValue
        }

      });
      setstate(newState)
    } else {
      showError('get extracurricular')
    }
  }


  const saveSetting = async (code, value, file) => {

    if (file) {

    } else {
      let params = {
        code: code,
        stringValue: value
      }
      const response = await axios.put(endpoint.setting.code, params)
      if (response && response.data) {
        showSuccess('save setting')
        getData()
      } else {
        showError('save setting')
      }
    }

  }


  useEffect(() => {
    getPsbOpenStatus()
    getData()
    getschoolOptions()
  }, [])


  return (
    <>
      <Header isPsbOpen={isPsbOpen} backgroundColor={state.backgroundColor} />
      <CallToAction isPsbOpen={isPsbOpen} backgroundColor={state.backgroundColor} title={state.callToActionTitle} content={state.callToActionContent} image={state.callToActionImage} saveSetting={saveSetting} getData={getData} />
      <MobileApp backgroundColor={state.backgroundColor} title={state.mobileAppTitle} content={state.mobileAppContent} image={state.mobileAppImage} saveSetting={saveSetting} getData={getData} />
      <Featured backgroundColor={state.backgroundColor} title={state.featuredTitle} content={state.featuredContent} image={state.featuredImage} saveSetting={saveSetting} />
      <Schools backgroundColor={state.backgroundColor} schools={schoolOptions}/>
      <Vision backgroundColor={state.backgroundColor} visionTitle={state.visionTitle} visionContent={state.visionContent} visionImage={state.visionImage} missionTitle={state.missionTitle} missionContent={state.missionContent} missionImage={state.missionImage} saveSetting={saveSetting} />
      <Activities
        backgroundColor={state.backgroundColor}
        title={state.activitiesTitle}
        content={state.activitiesContent}
        saveSetting={saveSetting}
        image1={state.activitiesImage1}
        image2={state.activitiesImage2}
        image3={state.activitiesImage3}
        image4={state.activitiesImage4}
        imageTitle1={state.activitiesImageTitle1}
        imageTitle2={state.activitiesImageTitle2}
        imageTitle3={state.activitiesImageTitle3}
        imageTitle4={state.activitiesImageTitle4}
        getData={getData}
      />
      <Facilities 
      backgroundColor={state.backgroundColor}
      title={state.facilitiesTitle} 
      content={state.facilitiesContent} 
      image={state.facilitiesImage} 
      saveSetting={saveSetting}
      image1={state.facilitiesImage1}
      image2={state.facilitiesImage2}
      image3={state.facilitiesImage3}
      image4={state.facilitiesImage4}
      imageTitle1={state.facilitiesImageTitle1}
      imageTitle2={state.facilitiesImageTitle2}
      imageTitle3={state.facilitiesImageTitle3}
      imageTitle4={state.facilitiesImageTitle4}
      getData={getData}
       />
      <Footer backgroundColor={state.backgroundColor} address={state.address} phone={state.phone} schools={schoolOptions} />

    </>
  );
}

export default Home;
