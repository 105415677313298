import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import ReadOnlyTextField from 'components/ReadOnlyTextField';


const PaymentDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      code: undefined,
      jenjang_id: undefined,
      jenjang: undefined,
      mobilePhone: undefined,
      email: undefined,
      address: undefined,
      head_employee_id: undefined,
      head_employee: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [jenjangOptions, setjenjangOptions] = useState([]);
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [headDepartmentOptions, setheadDepartmentOptions] = useState([])

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'jenjang') {
      newState['jenjang_id'] = value.value
    }
    else if (fieldName === 'head_employee') {
      newState['head_employee_id'] = value.value
    }
    else if (fieldName === 'head_department') {
      newState['head_department_id'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getheadDepartmentOptions = async () => {
    const params = {
      not_id: row ? row.id : undefined
    }
    const response = await axios.get(endpoint.department.option, { params: params });
    if (response && response.data) {
      setheadDepartmentOptions(generalListOptionMapper(response.data));
    }
  };

  const getjenjangOptions = async () => {
    const response = await axios.get(endpoint.jenjang.option);
    if (response && response.data) {
      setjenjangOptions(generalListOptionMapper(response.data));
    }
  };

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.payment.root, payload)
    if (response && response.data) {
      showSuccess('save payment')
      getData()
    } else {
      showError('save payment')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.payment.root, payload)
    if (response && response.data) {
      showSuccess('save payment')
      getData()
    } else {
      showError('save payment')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      jenjang: generalOptionMapper(row.jenjang),
      head_employee: generalOptionMapper(row.head_employee),
    })
  }

  useEffect(() => {
    if (pageAction !== 'READ') {
      getheadDepartmentOptions()
      getjenjangOptions()
    }
  }, [pageAction])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Induk bidang
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.head_department && state.head_department.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select 
                  isClearable={true}
                  name='head_department'
                  placeholder='Pilih bidang'
                  options={headDepartmentOptions}
                  value={state.head_department}
                  onChange={(e) => handleChange('head_department', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenjang
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.jenjang && state.jenjang.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="jenjang"
                  placeholder="Pilih Jenjang"
                  options={jenjangOptions}
                  value={state.jenjang}
                  onChange={(e) => handleChange("jenjang", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Email
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.email} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='email'
                  fullWidth
                  value={state.email}
                  onChange={(e) => handleChange('email', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.address} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='address'
                  fullWidth
                  value={state.address}
                  onChange={(e) => handleChange('address', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kepala sekolah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.head_employee && state.head_employee.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <AsyncSelect
                  cacheOptions
                  placeholder="Cari nama pegawai..."
                  value={state.head_employee}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  onChange={(e) => handleChange('head_employee', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default PaymentDetail;