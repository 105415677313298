import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { educationTypeOptions, geteducationTypeLabel, geteducationTypeObject, getjobTypeLabel, getjobTypeObject, getlifeStatusLabel, getlifeStatusObject, getparentTypeLabel, getparentTypeObject, getreligionLabel, getreligionObject, getsalaryTypeLabel, getsalaryTypeObject, getsexTypeLabel, getsexTypeObject, getwaliTypeLabel, getwaliTypeObject, jobTypeOptions, lifeStatusOptions, parentTypeOptions, religionOptions, salaryTypeOptions, sexTypeOptions, waliTypeOptions } from 'utils/options';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'


const ParentDetail = (props) => {
  const { row, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      user: null,
      name: undefined,
      ktp: undefined,
      nkk: undefined,
      email: undefined,
      mobilePhone: undefined,
      address: undefined,
      birthDate: new Date(),
      religion: null,
      religion_value: undefined,
      sex_type: null,
      sex_type_value: undefined,
      parent_type: null,
      parent_type_value: undefined,
      wali_type: null,
      wali_type_value: undefined,
      job_type: null,
      job_type_value: undefined,
      jobCorporateName: undefined,
      jobPositionName: undefined,
      jobWorkAddress: undefined,
      province: null,
      province_id: undefined,
      regency: null,
      regency_id: undefined,
      district: null,
      district_id: undefined,
      village: null,
      village_id: undefined,
      alive : null,
      isAlive: 1
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'sex_type') {
      newState['sex_type_value'] = value.value
    }
    else if (fieldName === 'religion') {
      newState['religion_value'] = value.value
    }
    else if (fieldName === 'job_type') {
      newState['job_type_value'] = value.value
    }
    else if (fieldName === 'parent_type') {
      newState['parent_type_value'] = value.value
      newState['wali_type_value'] = undefined
      newState['wali_type'] = null
    }
    else if (fieldName === 'wali_type') {
      newState['wali_type_value'] = value.value
    }
    else if (fieldName === 'education_type') {
      newState['education_type_value'] = value.value
    }
    else if (fieldName === 'salary_type') {
      newState['salary_type_value'] = value.value
    }
    else if (fieldName === 'alive') {
      newState['isAlive'] = value.value
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const insert = async (payload) => {
    const response = await axios.post(endpoint.parent.root, payload)
    if (response && response.data) {
      showSuccess('save parent')
      getData()
    } else {
      showError('save parent')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.parent.root, payload)
    if (response && response.data) {
      showSuccess('save parent')
      getData()
    } else {
      showError('save parent')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      birthDate: state.birthDate ? format(state.birthDate, 'yyyy-MM-dd') : undefined
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      sex_type: getsexTypeObject(row.sex_type_value),
      religion: getreligionObject(row.religion_value),
      parent_type: getparentTypeObject(row.parent_type_value),
      wali_type: getwaliTypeObject(row.wali_type_value),
      job_type: getjobTypeObject(row.job_type_value),
      birthDate: row.birthDate ? parse(row.birthDate, 'yyyy-MM-dd', new Date()) : null,
      education_type: geteducationTypeObject(row.education_type_value),
      salary_type: getsalaryTypeObject(row.salary_type_value),
      alive: getlifeStatusObject(row.isAlive),

    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])


  useEffect(() => {
    if (row) {
      if (pageAction !== 'CREATE') {
        getRowDetail()
      }
    } else {

      if (props.params) {
        setstate({
          ...state,
          student_id: props.params.student_id,
          sex_type: getsexTypeObject(props.params.sex_type_value),
          parent_type: getparentTypeObject(props.params.parent_type_value),
          parent_type_value: props.params.parent_type_value
        })
      } else {
        setstate(reset())
      }
    }

  }, [row, props.params])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Username
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.user && state.user.username} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Email
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.email} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="email"
                  fullWidth
                  value={state.email}
                  onChange={(e) => handleChange("email", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              KTP
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.ktp} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="ktp"
                  fullWidth
                  value={state.ktp}
                  onChange={(e) => handleChange("ktp", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor KK
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.nkk} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='nkk'
                  fullWidth
                  value={state.nkk}
                  onChange={(e) => handleChange('nkk', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Handphone
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.mobilePhone} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="mobilePhone"
                  fullWidth
                  value={state.mobilePhone}
                  onChange={(e) => handleChange("mobilePhone", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.address} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="address"
                  fullWidth
                  value={state.address}
                  onChange={(e) => handleChange("address", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsexTypeLabel(state.sex_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='sex_type'
                  placeholder='Pilih jenis kelamin'
                  options={sexTypeOptions}
                  value={state.sex_type}
                  onChange={(e) => handleChange('sex_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Agama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getreligionLabel(state.religion_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='religion'
                  placeholder='Pilih agama'
                  options={religionOptions}
                  value={state.religion}
                  onChange={(e) => handleChange('religion', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe orang tua
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getparentTypeLabel(state.parent_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='parent_type'
                  placeholder='Pilih tipe orangtua'
                  options={parentTypeOptions}
                  value={state.parent_type}
                  onChange={(e) => handleChange('parent_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.parent_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Tipe wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={getwaliTypeLabel(state.wali_type_value)} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    name='wali_type'
                    placeholder='Pilih tipe wali'
                    options={waliTypeOptions}
                    value={state.wali_type}
                    onChange={(e) => handleChange('wali_type', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthDate && format(state.birthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.birthDate}
                      onChange={(e) => handleChange('birthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota kelahiran
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.birthPlace} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="birthPlace"
                  fullWidth
                  value={state.birthPlace}
                  onChange={(e) => handleChange("birthPlace", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.education_type}
                  onChange={(e) => handleChange('education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis Pekerjaan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getjobTypeLabel(state.job_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='job_type'
                  placeholder='Pilih jenis pekerjaan'
                  options={jobTypeOptions}
                  value={state.job_type}
                  onChange={(e) => handleChange('job_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.jobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="jobCorporateName"
                  fullWidth
                  value={state.jobCorporateName}
                  onChange={(e) => handleChange("jobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.jobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="jobPositionName"
                  fullWidth
                  value={state.jobPositionName}
                  onChange={(e) => handleChange("jobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.jobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="jobWorkAddress"
                  fullWidth
                  value={state.jobWorkAddress}
                  onChange={(e) => handleChange("jobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.salary_type}
                  onChange={(e) => handleChange('salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='alive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.alive}
                  onChange={(e) => handleChange('alive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default ParentDetail;
