import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#5c8dea',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#ff5b5c',     // dark: will be calculated from palette.secondary.main,
    },    
    defaultBackground:{
      main:'#f2f4f3'
    }, 
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    merah:{
      main:'#e21e46',
      dark:'#e21e46'
    }
  },

  borderRadius:4,
  borderColor:'#c4c4c4'

})
export default theme

export const defaultSelectStyle = {
  control: (base, state) => ({
      ...base,
      minWidth: "150px"      
  }),
  container: (base, state) => {
      return {
          ...base,
          flex: 1,
          zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
      };
  }
};
