import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { Dialog, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import NumberFormatCustom from 'components/NumberFormatCustom';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { defaultSelectStyle } from 'utils/theme.js'
import { generalListOptionMapper, studentListOptionMapper } from 'utils/mappers';
import Confirmation from 'components/Confirmation';
import useAuth from 'hooks/useAuth';


const DonationAdmin = (props) => {
  const { row = {}, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()

  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [campaignOptions, setcampaignOptions] = useState([]);

  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [nameParentKeyWord, setnameParentKeyWord] = useState('');
  const [nameVisitorKeyWord, setnameVisitorKeyWord] = useState('');
  const [coa_hartaOptions, setcoa_hartaOptions] = useState([]);

  const [state, setstate] = useState(
    {
      employee: null,
      employeet_id: undefined,
      student: null,
      student_id: undefined,
      parent: null,
      parent_id: undefined,
      visitor: null,
      visitor_id: undefined,
      visitorName: undefined,
      visitorPhone: undefined,
      amount: 0,
      admin: null,
      admin_employee_id: undefined,
      campaign: null,
      donation_campaign_id: undefined,
      donatur: '4',
      coa_harta: null,
      coa_harta_id: undefined,
    }
  )

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'donatur') {
      value = eventValue.target.value

      newState['donatur'] = value
      newState['student'] = null
      newState['student_id'] = undefined
      newState['employee'] = null
      newState['employee_id'] = undefined
      newState['parent'] = null
      newState['parent_id'] = undefined
      newState['visitor'] = null
      newState['visitor_id'] = undefined
    }
    else if (fieldName === 'amount') {
      value = eventValue.target.value
    }
    else if (fieldName === 'employee') {
      newState['employee_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'visitor') {
      newState['visitor_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'parent') {
      newState['parent_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'campaign') {
      newState['donation_campaign_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'coa_harta') {
      newState['coa_harta_id'] = value.value
    }

    newState[fieldName] = value

    setstate(newState)
  }


  const getcoa_hartaOptions = async () => {
    const params = {
      group: 'HARTA',
      employee_id: detail.id
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_hartaOptions(generalListOptionMapper(response.data));
    }
  };

  const getcampaignOptions = async () => {
    const response = await axios.get(endpoint.donationCampaign.option);
    if (response && response.data) {
      setcampaignOptions(response.data.map(campaign=>({...campaign, label:campaign.title, value:campaign.id})));
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }


  const getParentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.parent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getVisitorOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.visitor.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const handleInputChangeEmployee = (newValue) => { 
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeStudent = (newValue) => { 
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeParent = (newValue) => { 
    setnameParentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeVisitor = (newValue) => { 
    setnameVisitorKeyWord(newValue)
    return newValue;
  };

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const loadParentOptions = async (inputValue, callback) => {
    callback(await getParentOptions(inputValue))
  };

  const loadVisitorOptions = async (inputValue, callback) => {
    callback(await getVisitorOptions(inputValue))
  };

  const payConfirmation = () => {
    setdialogContent(
      <Confirmation
        handleClose={() => setopenDialog(false)}
        action={submit}
        actionName="Terima Donasi"
        message='Apakah uang yang anda terima sudah sesuai'
        title='Konfirmasi Donasi'
      />
    );

    setopenDialog(true);
  }

  const submit = async () => {
    let payload = {
      donation_campaign_id: state.donation_campaign_id,
      amount: state.amount,
      user_type_value: parseInt(state.donatur),
      employee_id: parseInt(state.donatur) === 1 ? state.employee_id : undefined,
      student_id: parseInt(state.donatur) === 2 ? state.student_id : undefined,
      parent_id: parseInt(state.donatur) === 3 ? state.parent_id : undefined,
      visitor_id: parseInt(state.donatur) === 4 ? state.visitor_id : undefined,
      visitorName: state.visitorName,
      visitorPhone: state.visitorPhone,
      admin_employee_id: detail.id,
      coa_harta_id : state.coa_harta_id
    }

    const response = await axios.post(endpoint.donation.admin, payload)

    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('transaksi donasi')
        props.onClose()
        if (getData) {
          getData()
        }
      }

    } else {
      showError('save donasi')
    }
  }

  useEffect(() => {
    getcampaignOptions()
    getcoa_hartaOptions()
  }, [])

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kas penampung
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='coa_harta'
                placeholder='Pilih Kas. Penampung'
                options={coa_hartaOptions}
                value={state.coa_harta}
                onChange={(e) => handleChange('coa_harta', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis Donatur
            </Grid>
            <Grid item sm={8} xs={12}>
              <FormControl>

                <RadioGroup
                  row
                  aria-labelledby="donatur"
                  name="donatur"
                  defaultValue="4"
                  value={state.donatur}
                  onChange={(e) => handleChange('donatur', e)}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Pegawai" />
                  <FormControlLabel value="2" control={<Radio />} label={process.env.REACT_APP_STUDENT} />
                  <FormControlLabel value="3" control={<Radio />} label="Orang tua" />
                  <FormControlLabel value="4" control={<Radio />} label="Pengunjung" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Conditional condition={state.donatur === '1'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Pegawai
              </Grid>
              <Grid item sm={8} xs={12}>
                <AsyncSelect
                  cacheOptions
                  placeholder="Nama pegawai..."
                  value={state.employee}
                  loadOptions={loadEmployeeOptions}
                  onInputChange={handleInputChangeEmployee}
                  onChange={(e) => handleChange('employee', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.donatur === '2'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                { process.env.REACT_APP_STUDENT}
              </Grid>
              <Grid item sm={8} xs={12}>
                <AsyncSelect
                  cacheOptions
                  placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                  value={state.student}
                  loadOptions={loadStudentOptions}
                  onInputChange={handleInputChangeStudent}
                  onChange={(e) => handleChange('student', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.donatur === '3'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                {`Orang tua ${process.env.REACT_APP_STUDENT}`}
              </Grid>
              <Grid item sm={8} xs={12}>
                <AsyncSelect
                  cacheOptions
                  placeholder="Nama orang tua..."
                  value={state.parent}
                  loadOptions={loadParentOptions}
                  onInputChange={handleInputChangeParent}
                  onChange={(e) => handleChange('parent', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.donatur === '4'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Pengunjung
              </Grid>
              <Grid item sm={8} xs={12}>
                <AsyncSelect
                  cacheOptions
                  placeholder="Nama pengunjung..."
                  value={state.visitor}
                  loadOptions={loadVisitorOptions}
                  onInputChange={handleInputChangeVisitor}
                  onChange={(e) => handleChange('visitor', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>

            <Conditional condition={state.visitor === null}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Nama Pengunjung
                </Grid>
                <Grid item sm={8} xs={12}>
                  <DebouncedTextField
                    margin='dense'
                    id='visitorName'
                    fullWidth
                    value={state.visitorName}
                    onChange={(e) => handleChange('visitorName', e)}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                  Nomor Telepon
                </Grid>
                <Grid item sm={8} xs={12}>
                  <DebouncedTextField
                    margin='dense'
                    id='visitorPhone'
                    fullWidth
                    value={state.visitorPhone}
                    onChange={(e) => handleChange('visitorPhone', e)}
                  />
                </Grid>
              </Grid>
            </Conditional>

          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Program Donasi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name="campaign"
                placeholder="Pilih program donasi"
                options={campaignOptions}
                value={state.campaign}
                onChange={(e) => handleChange("campaign", e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                fullWidth
                value={parseInt(state.amount)}
                onChange={(e) => handleChange('amount', e)}
                name="amount"
                id="amount"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={payConfirmation} color="primary">
        Simpan
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={'sm'}
      onClose={() => setopenDialog(false)}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}

export default DonationAdmin;

