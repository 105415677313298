import React from 'react';
import { Grid, Button, Typography, CardContent, CardActions, Card, IconButton, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getuserTypeLabel } from 'utils/options';


const UserCard = (props) => {
  const { user, onClick, onClose } = props

  const handleClose = (e)=>{
    e.stopPropagation();
    onClose()
  }

  return (
    <Card variant="outlined" onClick={onClick} style={{ cursor: 'pointer' }}>
      <CardContent>
        <Grid container sx={{minWidth:150}}>
          <Grid item xs={12} container justifyContent="space-between" alignItems='center'>
            <Grid item>
              <Chip size="small" label={getuserTypeLabel(user.user_type_value)} color='primary'/> 
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
                aria-label='close'
                size="small">
                <CloseIcon color='primary' />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" alignItems='center'>
            <Grid item>
               <Typography variant="h6" component="h6" align="center">
                {user && user.name}
              </Typography>
            </Grid> 
          </Grid>
          <Grid item xs={12} container alignItems='center'>
            <Typography variant="body1" align="center">
              {user.username}
            </Typography>
          </Grid> 
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UserCard;
