import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const FilterList =(props)=> {
  const {fields, filters, onItemClick, onClose} = props

  const onClick=(field)=>{
  	onItemClick(field)
  	onClose()
  }
  return (
      <List component="nav">
        {
        	fields && filters &&
        	fields.filter(field=>(!filters[field.name])).map(field=>
        		(
					<ListItem button onClick={()=>onClick(field)}>
			          <ListItemText primary={field.display} />
			        </ListItem>
        		)
        	)
        }
      </List>
    );
}

export default FilterList;
