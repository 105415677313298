import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { Box, Checkbox, Switch, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import ActiveChip from 'components/ActiveChip';


const CampaignDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      title: undefined,
      description: undefined,
      target: undefined,
      accumulated: 0,
      image: undefined,
      isUnlimited: 1,
      deadline: new Date(),
      smart_reader: null,
      smart_reader_number: undefined,
      fixed_amount: undefined,
      withdrawal: 0,
      isPublic: 0,
      isActive: 1,
      isIncome: 0
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [smart_readerOptions, setsmart_readerOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (['isUnlimited','isPublic','isActive','isIncome'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'smart_reader') {
      newState['smart_reader_number'] = value ? value.number : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getsmart_readerOptions = async () => {
    const params = { }
    const response = await axios.get(endpoint.smartReader.option, { params: params });
    if (response && response.data) {
      setsmart_readerOptions(generalListOptionMapper(response.data));
    }
  };

  const selectImage = async (event) => {
    let file = event.target.files[0]
    setstate({
      ...state,
      file: file,
      image: URL.createObjectURL(file)
    })
  };


  const submit = async () => {
    const formData = new FormData();
    if (row) {
      formData.append('id', row.id)
    }
    formData.append('file', state.file)
    formData.append('title', state.title)
    formData.append('description', state.description)
    if(state.target){
      formData.append('target', state.target)
    }
    formData.append('isUnlimited', state.isUnlimited)
    formData.append('accumulated', state.accumulated)
    if (state.isUnlimited === 0) {
      formData.append('deadline', format(state.deadline, 'yyyy-MM-dd'))
    }
    if(state.smart_reader_number){
      formData.append('smart_reader_number', state.smart_reader_number)
    }
    formData.append('fixed_amount', state.fixed_amount)
    formData.append('isPublic', state.isPublic)
    formData.append('isActive', state.isActive)
    formData.append('isIncome', state.isIncome)

    const response = await axios.post(endpoint.donationCampaign.root, formData, {});
    if (response) {
      showSuccess('save campaign')
      getData()
    } else {
      showError('save campaign')
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      image: `${api.url}/${row.image}`,
      deadline: row.deadline ? parse(row.deadline, 'yyyy-MM-dd', new Date()) : null,
      smart_reader: generalOptionMapper(row.smart_reader),
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getsmart_readerOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Gambar
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box sx={{ width: '100%', position: 'relative' }}>
                <img
                  src={state.image}
                  style={{ width: '100%', height: 'auto' }} />
                <Conditional condition={pageAction !== 'READ'}>
                  <label htmlFor='propic'>
                    <input onChange={selectImage} accept="image/*" id='propic' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                  </label>
                </Conditional>

              </Box>
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Judul
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='title'
                  fullWidth
                  value={state.title}
                  onChange={(e) => handleChange('title', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Deskripsi
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Target
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.target)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='target'
                  fullWidth
                  value={state.target}
                  onChange={(e) => handleChange('target', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Terkumpul
            </Grid>
            <Grid item sm={8} xs={12}>
              <ReadOnlyTextField value={new Intl.NumberFormat().format(state.accumulated)} />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tidak terbatas waktu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isUnlimited === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isUnlimited === 1}
                  onChange={(e) => handleChange("isUnlimited", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.isUnlimited === 0}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Batas waktu
              </Grid>
              <Grid item sm={8} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.deadline}
                    onChange={(e) => handleChange('deadline', e)}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Smart reader
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.smart_reader && `(${state.smart_reader.number}) ${state.smart_reader.name}`} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isClearable={true}
                  name='smart_reader'
                  placeholder='Pilih smart reader'
                  options={smart_readerOptions}
                  value={state.smart_reader}
                  onChange={(e) => handleChange('smart_reader', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah pemotongan saldo
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.fixed_amount)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='fixed_amount'
                  fullWidth
                  value={state.fixed_amount}
                  onChange={(e) => handleChange('fixed_amount', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Dicairkan
            </Grid>
            <Grid item sm={8} xs={12}>
              <ReadOnlyTextField value={new Intl.NumberFormat().format(state.withdrawal)} />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Hitung sebagai pendapatan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isIncome === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isIncome === 1}
                  onChange={(e) => handleChange("isIncome", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Untuk umum
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isPublic === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isPublic === 1}
                  onChange={(e) => handleChange("isPublic", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={pageAction !== "CREATE"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
                Status
              </Grid>
              <Grid item xs={8}>
                <Conditional condition={pageAction === "READ"}>
                  <ActiveChip status={state.isActive} margin="0 0 0 8px" />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Switch
                    color='primary'
                    checked={state.isActive === 1}
                    onChange={(e) => handleChange("isActive", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default CampaignDetail;

