import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import EmployeeForm from './EmployeeForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Button, Chip, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Collapse from '@mui/material/Collapse';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EmployeeStatistic from './EmployeeStatistic.js';
import { getemployeeTypeLabel, getsexTypeLabel } from 'utils/options.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import useAuth from 'hooks/useAuth.js';




const Employee = (props) => {
  const { user, detail } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, isAllDataAccess } = useUI()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [openStatistic, setopenStatistic] = useState(false)
  const [selectedStatistic, setselectedStatistic] = useState('')
  const [positionOptions, setpositionOptions] = useState([])

  const defaultFilters = {
    keyword: '',
    isActive: null
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [isActiveOptions, setisActiveOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }

    if (fieldName === 'position') {
      filtersTemp['position_id'] = value ? value.value : undefined
    }


    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  }


  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      isActive: filters.isActive ? filters.isActive.value : undefined,
      position_id: filters.position_id,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getpositionOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.position.option, { params: params });
    if (response && response.data) {
      setpositionOptions(generalListOptionMapper(response.data));
    }
  };

  const printPdf = () => {
    getData()
  }

  const printExcel = () => {
    getData()
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter
    }

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }

    startLoading()
    const response = await axios.get(endpoint.employee.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get employee')
    }
    stopLoading()
  }



  const getDataByStatistic = async (statistic) => {
    setopenDialog(false)
    if (selectedStatistic === statistic.code) {
      reset()
    } else {
      setselectedStatistic(statistic.code)
      let params = {
        isActive: statistic.isActive,
        page: page,
        rowsPerPage: rowsPerPage
      }

      let isActiveIdx = isActiveOptions.findIndex(status => status.value === statistic.isActive)
      let isActive = null

      if (isActiveIdx > -1) {
        isActive = isActiveOptions[isActiveIdx]
      }



      setFilters(
        {
          ...filters,
          isActive: isActive
        }
      )
      setPage(1)

      getDataByParams(params)

    }

  }

  const showStatistic = () => {
    if (smallScreen) {
      setdialogContent(
        <Box minHeight={300}>
          <EmployeeStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Box>

      )
      setopenDialog(true)
    } else {
      setopenStatistic(!openStatistic)
    }
  }

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <EmployeeForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
        importUrl={endpoint.employee.import}
        templateUrl={api.url + '/files/EMPLOYEE_TEMPLATE.xlsx'}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Employee"
      />
    );

    setopenDialog(true);
  };

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.employee.root, { data: params });
    if (response) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setselectedIds([])
        setselectedRow(null)
        showSuccess('delete employee')
        getData();
      }
     
    } else {
      showError('delete employee')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      isActive: defaultFilters.isActive ? defaultFilters.isActive.value : undefined,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setselectedStatistic('')
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    getpositionOptions()
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
              <Button onClick={() => showDialog('IMPORT')} color='primary'>
                Import
              </Button>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }
          <Grid item>
            <IconButton
              onClick={showStatistic}
              aria-label='delete'
              size="large">
              <InsertChartIcon color='primary' />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Collapse in={openStatistic} timeout="auto" unmountOnExit sx={{ width: 'inherit' }}>
        <Grid container sx={{ minHeight: 64, mb: 1 }}>
          <EmployeeStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Grid>
      </Collapse>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='isActive'
              placeholder='Is active'
              options={isActiveOptions}
              onChange={(e) => filterChange('isActive', e)}
              styles={defaultSelectStyle}
              value={filters.isActive}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              isClearable={true}
              name='position'
              placeholder='Pilih posisi'
              options={positionOptions}
              value={filters.position}
              onChange={(e) => filterChange('position', e)}
              styles={defaultSelectStyle}
            />
          </Grid>


        </Grid>
        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={printPdf}>
            PDF
          </SmallButton>
          <SmallButton variant='text' color='secondary' onClick={printExcel}>
            Excel
          </SmallButton>
          <IconButton
            onClick={() => getData()}
            aria-label='delete'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>User</TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Kode</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Sex</TableCell>
                <TableCell>NIK</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Sisa cuti</TableCell>
                <TableCell>Tanggal Lahir</TableCell>
                <TableCell>Tanggal Bergabung</TableCell>
                <TableCell>Tanggal Tetap</TableCell>
                <TableCell>Tanggal Keluar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>{row.user && row.user.username}</TableCell>
                  <TableCell>
                    <ClickableText text={row.name} onClick={() => showDialog('READ', row)} />
                  </TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.mobilePhone}</TableCell>
                  <TableCell>
                    <Chip label={getsexTypeLabel(row.sex_type_value)} color="default" />
                  </TableCell>
                  <TableCell>{row.nik}</TableCell>
                  <TableCell>{getemployeeTypeLabel(row.employee_type_value)}</TableCell>
                  <TableCell><ActiveChip status={row.isActive} /></TableCell>
                  <TableCell>{row.cutiBalance}</TableCell>
                  <TableCell>{row.birthDate}</TableCell>
                  <TableCell>{row.joinDate}</TableCell>
                  <TableCell>{row.tetapDate}</TableCell>
                  <TableCell>{row.resignDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default Employee;

