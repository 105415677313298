import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './Sidebar.js';
import Conditional from 'components/Conditional.js';
import {
  Hidden,
  Grid,
  Typography,
  Drawer,
  LinearProgress,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI.js';
import ProfilePopUp from 'components/ProfilePopUp.js';
import SearchPopUp from 'components/SearchPopUp.js';
import { blue } from '@mui/material/colors';

import DashboardContent from './DashboardContent.js';
import Menu from 'views/menu/Menu.js';
import User from 'views/user/User.js';
import Student from 'views/student/Student.js';
import Parent from 'views/parent/Parent.js';
import Employee from 'views/employee/Employee.js';
import Parameter from 'views/parameter/Parameter.js';
import Visitor from 'views/visitor/Visitor.js';
import Role from 'views/role/Role.js';
import Jenjang from 'views/jenjang/Jenjang.js';
import School from 'views/school/School.js';
import Kelas from 'views/kelas/Kelas.js';
import Parallel from 'views/parallel/Parallel.js';
import Jurusan from 'views/jurusan/Jurusan.js';
import Action from 'views/action/Action.js';
import TahunPelajaran from 'views/tahun-pelajaran/TahunPelajaran.js';
import SettingGeneral from 'views/setting/SettingGeneral.js';
import SettingAdmin from 'views/setting-admin/SettingAdmin.js';
import Department from 'views/department/Department.js';
import Position from 'views/position/Position.js';
import SmartCard from 'views/smart-card/SmartCard.js';
import SmartReader from 'views/smart-reader/SmartReader.js';
import Balance from 'views/balance/Balance.js';
import Schedule from 'views/schedule/Schedule.js';
import Subject from 'views/subject/Subject.js';
import BankAccount from 'views/bank-account/BankAccount.js';
import Building from 'views/building/Building.js';
import Room from 'views/room/Room.js';
import Curriculum from 'views/curriculum/Curriculum.js';
import Store from 'views/store/Store.js';
import Product from 'views/product/Product.js';
import Extracurricular from 'views/extracurricular/Extracurricular.js';
import ExtracurricularStudent from 'views/extracurricular/ExtracurricularStudent.js';
import Agenda from 'views/agenda/Agenda.js';
import SmartReaderQueue from 'views/smart-reader/SmartReaderQueue.js';
import SimpleCashier from 'views/cashier/SimpleCashier.js';
import Transaction from 'views/transaction/Transaction.js';
import Attendance from 'views/attendance/Attendance.js';
import News from 'views/news/News.js';
import Achievement from 'views/achievement/Achievement.js';
import Inventory from 'views/inventory/Inventory.js';
import Category from 'views/category/Category.js';
import Mengaji from 'views/mengaji/Mengaji.js';
import ProgressTahfidz from 'views/tahfidz/ProgressTahfidz.js';
import Bill from 'views/bill/Bill.js';
import BillType from 'views/bill/BillType.js';
import Book from 'views/library/Book.js';
import Bookcase from 'views/library/Bookcase.js';
import BookBorrow from 'views/library/BookBorrow.js';
import DefinedIncome from 'views/transaction/DefinedIncome.js';
import MinimarketCashier from 'views/cashier/MinimarketCashier.js';
import JamPelajaran from 'views/schedule/JamPelajaran.js';
import TeachingNote from 'views/note/TeachingNote.js';
import Approval from 'views/approval/Approval.js';
import NotificationPopUp from 'components/NotificationPopUp.js';
import StoreWithdrawal from 'views/withdrawal/StoreWithdrawal.js';
import SchoolCase from 'views/school-case/SchoolCase.js';
import PermissionType from 'views/permission/PermissionType.js';
import Payment from 'views/payment/Payment.js';
import PsbFlow from 'views/psb-flow/PsbFlow.js';
import NewStudentAdmin from 'views/new-student/NewStudentAdmin.js';
import { Home } from '@mui/icons-material';
import PsbBill from 'views/psb-flow/PsbBill.js';
import PsbProfile from 'views/psb-flow/PsbProfile.js';
import PsbFile from 'views/psb-flow/PsbFile.js';
import PsbExam from 'views/psb-flow/PsbExam.js';
import PsbResult from 'views/psb-flow/PsbResult.js';
import { setMenuActions } from 'redux/slices/ui.js';
import PsbFileVerification from 'views/psb-flow/PsbFileVerification.js';
import PsbNewParent from 'views/psb-flow/PsbNewParent.js';
import PsbNewStudent from 'views/psb-flow/PsbNewStudent.js';
import Coa from 'views/finance/Coa.js';
import Journal from 'views/finance/Journal.js';
import Ledger from 'views/finance/Ledger.js';
import IncomeStatement from 'views/finance/IncomeStatement.js';
import BalanceSheet from 'views/finance/BalanceSheet.js';
import CashFlow from 'views/finance/CashFlow.js';
import ScoreParallel from 'views/score/ScoreParallel.js';
import Profile from 'views/profile/Profile.js';
import SubjectTeacher from 'views/subject/SubjectTeacher.js';
import Kd from 'views/score/Kd.js';
import StudentMutation from 'views/student/StudentMutation.js';
import EmployeeMutation from 'views/employee/EmployeeMutation.js';
import StudentClass from 'views/student/StudentClass.js';
import AttendanceByTeacher from 'views/attendance/AttendanceByTeacher.js';
import TestPage from 'views/test/TestPage.js';
import NewParent from 'views/new-parent/NewParent.js';
import PulseLoader from "react-spinners/PulseLoader";
import Documentation from 'views/documentation/Documentation.js';
import Image from 'views/gallery/Image.js';
import EmployeePerformance from 'views/employee/EmployeePerformance.js';
import StudentPerformance from 'views/student/StudentPerformance.js';
import Link from 'views/link/Link.js';
import Campaign from 'views/donation/Campaign.js';
import LibraryVisitor from 'views/library/LibraryVisitor.js';
import Patient from 'views/uks/Patient.js';
import EmployeePermission from 'views/permission/EmployeePermission.js';
import StudentPermission from 'views/permission/StudentPermission.js';
import Permission from 'views/permission/Permission.js';
import DefinedExpense from 'views/transaction/DefinedExpense.js';
import Autodebet from 'views/autodebet/Autodebet.js';
import AutodebetType from 'views/autodebet/AutodebetType.js';
import MonthlyTahfidz from 'views/tahfidz/MonthlyTahfidz.js';
import RewardType from 'views/reward/RewardType.js';
import StudentReward from 'views/reward/StudentReward.js';
import StudentGroup from 'views/student/StudentGroup.js';
import PsbExamParticipant from 'views/psb-exam/PsbExamParticipant.js';
import PsbFlowInstruction from 'views/psb-flow/PsbFlowInstruction.js';
import AttendanceStudent from 'views/attendance/AttendanceStudent.js';
import AttendanceEmployee from 'views/attendance/AttendanceEmployee.js';
import CoaHolder from 'views/finance/CoaHolder.js';
import ProductReport from 'views/product/ProductReport.js';
import UnpaidBuy from 'views/store-unpaid-buy/UnpaidBuy.js';


const Dashboard = (props) => {
  const { history } = props
  const { user, isAuthenticated, menus = [] } = useAuth()
  const { isLoading, isOpenSnackbar, hideSnackbar, snackbarMessage, snackbarVariant, gotoPage, setActiveMenuActions } = useUI()
  let { url } = useRouteMatch();
  const [openMenu, setopenMenu] = React.useState(false);
  const [expandedMenuId, setexpandedMenuId] = useState(null)


  const closeMenu = () => {
    setopenMenu(false);
  };


  const goHome = (path) => {
    props.history.push("/home")
  }

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated]);


  if (user) {
    return (
      <Grid container justifyContent="center"
        sx={{
          backgroundColor: '#ffff',
          height: '100vh',
          overflowY: 'hidden'
        }}
      >
        <Grid container item xs={12}
          sx={{
            position: 'fixed',
            display: 'flex',
            px: 2,
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: 'rgba(25, 42, 70, 0.13) 20px 8px 20px 0px',
            zIndex: 1100,
            width: '100%'
          }}
        >
          <Grid container justifyContent="flex-start" alignContent="center"
            xs={6}
            sx={{ height: '3.5rem' }}
          >
            <Hidden lgUp>
              <IconButton
                onClick={() => setopenMenu(true)}
                size="medium"
                aria-label="menu"
                style={{ margin: 0 }}>
                <MenuIcon color="primary" />
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <Typography alignContent='center' onClick={goHome} color="primary" variant="h6" 
              style={{ display: 'flex', alignItems: 'center', fontWeight: 900, cursor: 'pointer' }}>
                {process.env.REACT_APP_NAME}
              </Typography>
            </Hidden>

          </Grid>
          <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
            <PulseLoader color={'#1e65e9'} loading={isLoading} size={10} />
            <Grid item>
              <IconButton
                onClick={(e) => gotoPage('/home')}
                aria-label='search'
                size='small'
                style={{ margin: 0 }}>
                <Home />
              </IconButton>
            </Grid>
            <Grid item >
              <NotificationPopUp />
            </Grid>
            <Grid item sx={{ mr: 1 }}>
              <SearchPopUp />
            </Grid>
            <Grid item>
              <ProfilePopUp />
            </Grid>
          </Grid>
        </Grid>

        <Hidden lgDown>
          <Grid container alignItems="flex-start" alignContent="flex-start" item lg={2}
            sx={{
              height: '100vh',
            }}
          >
            <Grid container justifyContent="center" alignContent="center"
              sx={{ height: '3.5rem' }}
            >
              <Typography onClick={goHome} color="primary" variant="h6" style={{ fontWeight: 900, cursor: 'pointer' }}>
                {process.env.REACT_APP_NAME}
              </Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems='flex-start' alignContent="flex-start"
              sx={{
                height: '91vh',
                overflowY: 'scroll',
              }}
            >
              <Sidebar
                expandedMenuId={expandedMenuId}
                setexpandedMenuId={setexpandedMenuId}
                justify="center"
                onClose={() => { }}
              />

            </Grid>
          </Grid>
        </Hidden>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          sx={{
            height: '100vh',
            overflowY: 'scroll'
          }}
        >


          <Grid
            container
            sx={{
              minHeight: '91vh',
              marginTop: '3.5rem',
              bgcolor: blue[50],
              p: 2
            }}
          >


            <Switch>
              <Route exact path={url} component={DashboardContent} />
              <Route path={`${url}/achievement`} component={Achievement} />
              <Route path={`${url}/action`} component={Action} />
              <Route path={`${url}/agenda`} component={Agenda} />
              <Route path={`${url}/approval`} component={Approval} />
              <Route path={`${url}/attendance`} component={Attendance} />
              <Route path={`${url}/autodebet`} component={Autodebet} />
              <Route path={`${url}/autodebet-type`} component={AutodebetType} />
              <Route path={`${url}/balance`} component={Balance} />
              <Route path={`${url}/balance-sheet`} component={BalanceSheet} />
              <Route path={`${url}/bank-account`} component={BankAccount} />
              <Route path={`${url}/bill`} component={Bill} />
              <Route path={`${url}/bill-type`} component={BillType} />
              <Route exact path={`${url}/book`} component={Book} />
              <Route path={`${url}/book/borrow`} component={BookBorrow} />
              <Route path={`${url}/bookcase`} component={Bookcase} />
              <Route path={`${url}/building`} component={Building} />
              <Route path={`${url}/campaign`} component={Campaign} />
              <Route path={`${url}/cash-flow`} component={CashFlow} />
              <Route path={`${url}/cashier-simple`} component={SimpleCashier} />
              <Route path={`${url}/cashier-minimarket`} component={MinimarketCashier} />
              <Route path={`${url}/category`} component={Category} />
              <Route path={`${url}/coa`} component={Coa} />
              <Route path={`${url}/coa-holder`} component={CoaHolder} />
              <Route path={`${url}/curriculum`} component={Curriculum} />
              <Route path={`${url}/department`} component={Department} />
              <Route path={`${url}/documentation`} component={Documentation} />
              <Route path={`${url}/employee`} component={Employee} />
              <Route path={`${url}/employee-attendance`} component={AttendanceEmployee} />
              <Route path={`${url}/employee-mutation`} component={EmployeeMutation} />
              <Route path={`${url}/employee-performance`} component={EmployeePerformance} />
              <Route path={`${url}/employee-permission`} component={EmployeePermission} />
              <Route path={`${url}/extracurricular`} component={Extracurricular} />
              <Route path={`${url}/extracurricular-student`} component={ExtracurricularStudent} />
              <Route path={`${url}/image`} component={Image} />
              <Route path={`${url}/income-statement`} component={IncomeStatement} />
              <Route path={`${url}/inventory`} component={Inventory} />
              <Route path={`${url}/jenjang`} component={Jenjang} />
              <Route path={`${url}/journal`} component={Journal} />
              <Route path={`${url}/jurusan`} component={Jurusan} />
              <Route path={`${url}/kd`} component={Kd} />
              <Route path={`${url}/kelas`} component={Kelas} />
              <Route path={`${url}/ledger`} component={Ledger} />
              <Route path={`${url}/library-visitor`} component={LibraryVisitor} />
              <Route path={`${url}/link`} component={Link} />
              <Route path={`${url}/menu`} component={Menu} />
              <Route path={`${url}/mengaji`} component={Mengaji} />
              <Route path={`${url}/monthly-tahfidz`} component={MonthlyTahfidz} />
              <Route path={`${url}/news`} component={News} />
              <Route path={`${url}/new-parent`} component={NewParent} />
              <Route path={`${url}/new-student-adm`} component={NewStudentAdmin} />
              <Route path={`${url}/note/teaching`} component={TeachingNote} />
              <Route path={`${url}/parallel`} component={Parallel} />
              <Route path={`${url}/parameter`} component={Parameter} />
              <Route path={`${url}/parent`} component={Parent} />
              <Route path={`${url}/patient`} component={Patient} />
              <Route path={`${url}/payment`} component={Payment} />
              <Route path={`${url}/permission`} component={Permission} />
              <Route path={`${url}/permission-type`} component={PermissionType} />
              <Route path={`${url}/position`} component={Position} />
              <Route path={`${url}/product`} component={Product} />
              <Route path={`${url}/product-report`} component={ProductReport} />
              <Route path={`${url}/profile`} component={Profile} />
              <Route path={`${url}/progress-tahfidz`} component={ProgressTahfidz} />
              <Route path={`${url}/psb-flow`} component={PsbFlow} />
              <Route path={`${url}/psb-bill`} component={PsbBill} />
              <Route path={`${url}/psb-profile`} component={PsbProfile} />
              <Route path={`${url}/psb-file`} component={PsbFile} />
              <Route path={`${url}/psb-file-verification`} component={PsbFileVerification} />
              <Route path={`${url}/psb-flow-instruction`} component={PsbFlowInstruction} />
              <Route path={`${url}/psb-exam`} component={PsbExam} />
              <Route path={`${url}/psb-exam-participant`} component={PsbExamParticipant} />
              <Route path={`${url}/psb-result`} component={PsbResult} />
              <Route path={`${url}/psb-new-parent`} component={PsbNewParent} />
              <Route path={`${url}/psb-new-student`} component={PsbNewStudent} />
              <Route path={`${url}/defined-income`} component={DefinedIncome} />
              <Route path={`${url}/defined-expense`} component={DefinedExpense} />
              <Route path={`${url}/reward-type`} component={RewardType} />
              <Route path={`${url}/role`} component={Role} />
              <Route path={`${url}/room`} component={Room} />
              <Route path={`${url}/setting-general`} component={SettingGeneral} />
              <Route path={`${url}/setting-admin`} component={SettingAdmin} />
              <Route path={`${url}/school`} component={School} />
              <Route path={`${url}/school-case`} component={SchoolCase} />
              <Route exact path={`${url}/schedule`} component={Schedule} />
              <Route path={`${url}/schedule/jam-pelajaran`} component={JamPelajaran} />
              <Route path={`${url}/score-parallel`} component={ScoreParallel} />
              <Route path={`${url}/smart-card`} component={SmartCard} />
              <Route path={`${url}/simple-cashier`} component={SimpleCashier} />
              <Route path={`${url}/smart-reader`} component={SmartReader} />
              <Route path={`${url}/smart-reader-queue`} component={SmartReaderQueue} />
              <Route path={`${url}/store`} component={Store} />
              <Route path={`${url}/store-withdrawal`} component={StoreWithdrawal} />
              <Route path={`${url}/store-unpaid-buy`} component={UnpaidBuy} />
              <Route path={`${url}/student`} component={Student} />
              <Route path={`${url}/student-mutation`} component={StudentMutation} />
              <Route path={`${url}/student-class`} component={StudentClass} />
              <Route path={`${url}/student-attendance`} component={AttendanceStudent} />
              <Route path={`${url}/student-attendance-by-teacher`} component={AttendanceByTeacher} />
              <Route path={`${url}/student-group`} component={StudentGroup} />
              <Route path={`${url}/student-performance`} component={StudentPerformance} />
              <Route path={`${url}/subject`} component={Subject} />
              <Route path={`${url}/subject-teacher`} component={SubjectTeacher} />
              <Route path={`${url}/student-permission`} component={StudentPermission} />
              <Route path={`${url}/student-reward`} component={StudentReward} />
              <Route path={`${url}/tahun-pelajaran`} component={TahunPelajaran} />
              <Route path={`${url}/test-page`} component={TestPage} />
              <Route path={`${url}/transaction`} component={Transaction} />
              <Route path={`${url}/user`} component={User} />
              <Route path={`${url}/visitor`} component={Visitor} />

            </Switch>
          </Grid>
        </Grid>

        <Drawer anchor="left" open={openMenu} onClose={closeMenu}>
          <Sidebar expandedMenuId={expandedMenuId} setexpandedMenuId={setexpandedMenuId} onClose={closeMenu} justify="flex-start" />
        </Drawer>

        <Snackbar
          open={isOpenSnackbar}
          autoHideDuration={6000}
          onClose={hideSnackbar}
          message="Note archived"
          action={<IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={hideSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          }
        >
          <Alert onClose={hideSnackbar} severity={snackbarVariant} sx={{ width: '100%' }}>
            {` ${snackbarVariant === 'success' ? 'berhasil,' : snackbarVariant === 'error' ? 'gagal,' : ''} `}
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    );
  } else {
    return null
  }

}

export default Dashboard;

