import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';

const ShoppingCart = (props) => {

  const { cart = [], deleteFromCart } = props

  return (
    <Grid container item xs={12} justifyContent="center" alignItems='flex-start' alignContent='flex-start' >
      <Grid item xs={12} container justifyContent="center" alignItems='center' sx={{ py: 1, borderBottom: '1px solid #D5D7E6' }}>
        <ShoppingCartIcon color='secondary' />
        <Typography variant="h6" component="h6" align="center">
          Keranjang
        </Typography>
      </Grid>
      {
        cart.map((product, index) => (
          <Grid item xs={12} container justifyContent="center" alignItems='center' sx={{ p: 1 }}>
            <Grid item xs={1} container justifyContent="flex-start" alignContent='center' alignItems='center'>
              <IconButton
                onClick={() => deleteFromCart(index)}
                size='small'
                aria-label='edit'
                style={{ margin: 0 }}>
                <Close fontSize='inherit' />
              </IconButton>
            </Grid>
            <Grid item xs={5} container justifyContent="flex-start" alignContent='center' alignItems='center'>
              {product.name}
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignContent='center'>
              {product.count}
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end" alignContent='center'>
              {new Intl.NumberFormat().format(product.sellPrice * product.count)}
            </Grid>
          </Grid>
        ))
      }
    </Grid>
  )
}


export default ShoppingCart;

