import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { TextField } from '@mui/material';
import NumberFormatCustom from 'components/NumberFormatCustom';


const InputLimitAmount = (props) => {
  const { row = {} } = props 
 
  const [amount, setamount] = useState(0)

  const changeAmount = (event) => { 
    setamount(event.target.value) 
  }

  const submit = async () => { 
    props.onSubmit(amount) 
    props.onClose()
  }
  
  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Jumlah
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth
                value={parseInt(amount)}
                onChange={changeAmount}
                name="amount"
                id="amount"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
  </>;
}

export default InputLimitAmount;

