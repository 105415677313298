import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js'; 
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js'; 
import { FormControlLabel, Switch, Checkbox } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import AsyncSelect from 'react-select/async';


const ExtracurricularDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const classes = useStyles();
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      isActive: 1,
      description: undefined,
      instructor:undefined,
      instructor_employee_id:undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [nameKeyWord, setnameKeyWord] = useState('');

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isSelectable", "isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }else if (fieldName === 'instructor') {
      newState['instructor_employee_id'] = value.value
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };

   
  const insert = async (payload) => {
    const response = await axios.post(endpoint.extracurricular.root, payload)
    if (response && response.data) {
      showSuccess('save extracurricular')
      getData()
    } else {
      showError('save extracurricular')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.extracurricular.root, payload)
    if (response && response.data) {
      showSuccess('save extracurricular')
      getData()
    } else {
      showError('save extracurricular')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row
    })
  }
  
  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Deskripsi
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pembina
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={state.instructor && state.instructor.name} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <AsyncSelect
                  cacheOptions
                  placeholder="Cari nama pembina..."
                  value={state.instructor}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  onChange={(e) => handleChange('instructor', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid   xs={4} container justifyContent='flex-start' alignContent='center'>
              Aktif
            </Grid>
            <Grid   xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ActiveChip status={state.isActive} margin='0 0 0 8px' />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Switch
                  color='primary'
                  checked={state.isActive === 1}
                  onChange={(e) => handleChange('isActive', e)}
                />
              </Conditional>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default ExtracurricularDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
