import axios from 'axios';
// ----------------------------------------------------------------------

export const axiosInstance = axios.create();
export const api = {};

if (process.env.REACT_APP_BACKEND_MODE === 'PROD') {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_PROD;
  api.url = process.env.REACT_APP_API_URL_PROD
  api.host = process.env.REACT_APP_API_HOST_PROD
  api.wshost = process.env.REACT_APP_WS_HOST_PROD
} else if (process.env.REACT_APP_BACKEND_MODE === 'UAT') {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_UAT;
  api.url = process.env.REACT_APP_API_URL_UAT
  api.host = process.env.REACT_APP_API_HOST_UAT
  api.wshost = process.env.REACT_APP_API_HOST_UAT
} else {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_LOCAL;
  api.url = process.env.REACT_APP_API_URL_LOCAL
  api.host = process.env.REACT_APP_API_HOST_LOCAL
  api.wshost = process.env.REACT_APP_WS_HOST_LOCAL
}


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    (error.response && error.response.data)
);

export const endpoint = {
  achievement: {
    root: '/achievement',
    option: '/achievement/option',
    student: '/achievement/student'
  },
  action:{
    root:'/action',
    option:'/action/option',
    approver:'/action/approver',
    role:'/action/role'
  },
  agenda: {
    root: '/agenda',
    option: '/agenda/option'
  },
  alumni: {
    root: '/alumni',
    option: '/alumni/option'
  },  
  application: {
    root: '/application',
    option: '/application/option',
    role:'/application/role'
  },
  approval: {
    root: '/approval',
    detail: '/approval/id',
    option: '/approval/option'
  },
  attendance: {
    root: '/attendance',
    option: '/attendance/option', 
    statistic: '/attendance/statistic',
    daily: '/attendance/daily',
    hourly: '/attendance/hourly',
    hourlyDetail: '/attendance/hourly-detail',
    excel:'/attendance/excel',
  },
  autodebet: {
    root: '/autodebet',
    option: '/autodebet/option',
    type: '/autodebet/type',
    typeOption: '/autodebet/type/option',
    student: '/autodebet/student',
    cancelStudent: '/autodebet/cancel-student',
    run:'/autodebet/run',
    withdrawal:'autodebet/withdrawal'
  },
  balance: {
    root: '/balance',
    option: '/balance/option',
    excel: '/balance/excel',
    mutation: '/balance/mutation',
    limit: '/balance/limit',
    topUpManual:'/balance/topup-manual',
    withdrawal:'/balance/withdrawal',
    topUpPaymentRequest:'/balance/topup-payment-request',
    user: '/balance/user',
    student: '/balance/student',
  },
  bankAccount: {
    root: '/bank-account',
    option: '/bank-account/option'
  },
  bill: {
    root: '/bill',
    billing : '/bill/billing',
    statistic : '/bill/statistic',
    detail:'/bill/id',
    option: '/bill/option',
    unpaid: '/bill/unpaid',
    allUnpaidParent: '/bill/all-unpaid/parent',
    payOfflineCash: '/bill/pay-offline-cash',
    payOfflineInstallment: '/bill/pay-offline-installment',
    parentPayment : '/bill/parent-payment',
    export:'/bill/export/id',
    total:'/bill/total',
    excel:'/bill/excel',
  },
  billType: {
    root: '/bill-type',
    option: '/bill-type/option'
  },
  book: {
    root: '/book',
    option: '/book/option',
    borrow: '/book/borrow',
    return: '/book/return',
    returnMulti: '/book/return-multi',
    barcode: '/book/barcode',
  },
  bookcase: {
    root: '/bookcase',
    option: '/bookcase/option'
  },
  building:{
    root:'/building',
    option:'/building/option',
    room:'/building/room'
  },

  category: {
    root: '/category',
    option: '/category/option',
    group: '/category/group',
  },
  curriculum:{
    root:'/curriculum',
    option:'/curriculum/option',
    subject:'/curriculum/subject'
  },
  coa:{
    root:'/coa',
    option:'/coa/option',
    holder:'/coa/holder'
  },
  cuti: {
    root: '/cuti',
    option: '/cuti/option'
  },
  cutiBalance: {
    root: '/cuti-balance',
    option: '/cuti-balance/option'
  },
  data: {
    root: '/data',
    option: '/data/table/option',
    detail: '/data/table/id'
  },
  definedIncome: {
    root: '/defined-income',
    option: '/defined-income/option',
  },
  definedExpense: {
    root: '/defined-expense',
    option: '/defined-expense/option',
  },
  department: {
    root: '/department',
    option: '/department/option',
    position:'/department/position'
  },
  donation: {
    root: '/donation',
    option: '/donation/option',
    admin:'/donation/admin',
    user:'/donation/user',
  },
  donationCampaign: {
    root: '/donation-campaign',
    option: '/donation-campaign/option',
    withdrawal: '/donation-campaign/withdrawal',
  },
  extracurricular: {
    root: '/extracurricular',
    option: '/extracurricular/option',
    student: '/extracurricular/student'
  },
  employee: {
    root: '/employee',
    option: '/employee/option',
    detail: '/employee/id',
    statistic:'/employee/statistic',
    position:'/employee/position',
    smartReader:'/employee/smart-reader',
    store:'/employee/store',
    role:'/employee/role',
    photo:'/employee/photo',
    mutation:'/employee/mutation', 
    import:'employee/import',
    performance:'employee/performance',
    performanceId:'employee/performance/id'
  },
  employeePermission: {
    root: '/employee-permission',
    option: '/employee-permission/option'
  },

  inventory: {
    root: '/inventory',
    option: '/inventory/option'
  }, 

  image: {
    root: '/image',
    option: '/image/option',
    group: '/image/group',
    code: '/image/code'
  }, 

  report: {
    allPendapatan: '/report/all-pendapatan',
    allBiaya: '/report/all-biaya',
    hartaLancar: '/report/harta-lancar',
    hartaTidakLancar: '/report/harta-tidak-lancar',
    utang: '/report/utang',
    modal: '/report/modal',
    biaya: '/report/biaya',
    totalBiaya: '/report/total-biaya',
    totalPendapatan: '/report/total-pendapatan',
    laba: '/report/laba',
    operationalIncome: '/report/operational-income',
    operationalExpense: '/report/operational-expense',
    transactionalEquity:'/report/transactional-equity'
  }, 

  jamPelajaran: {
    root: '/jam-pelajaran',
    option: '/jam-pelajaran/option',
    duplicate:'/jam-pelajaran/duplicate',
    import:'/jam-pelajaran/import',
  },

  jenjang:{
    root:'/jenjang',
    option:'/jenjang/option'
  },
  journal: {
    root: '/journal',
    option: '/journal/option'
  },
  jurusan: {
    root: '/jurusan',
    option: '/jurusan/option'
  },
  kd: {
    root: '/kd',
    option: '/kd/option',
    import: '/kd/import'
  },
  kelas: {
    root: '/kelas',
    option: '/kelas/option',
    parallelOption: '/kelas/parallel/option',
  },
  ledger:{
    root:'/ledger',
    coa:'/ledger/coa', 
    coaDetail:'/ledger/coa/id',
  },
  library:{
    visitor:'/library/visitor',
    visitorExcel: '/library/visitor/excel',
    open:'/library/open',
    close:'/library/close',
  },
  link:{
    root:'/link',
    option:'/link/option', 
    group:'/link/group',
    code:'/link/code',
  },
  login: 'login',
  menu: {
    root: '/menu',
    favorite:'/menu/favorite',
    option: '/menu/option',
    role:'/menu/role',
    action:'/menu/action',
    statistic:'/menu/statistic'
  },
  mengaji: {
    root: '/mengaji',
    option: '/mengaji/option'
  },
  news: {
    root: '/news',
    option: '/news/option'
  },
  newStudent: {
    root: '/new-student',
    excel: '/new-student/excel',
    detail: '/new-student/id',
    exam: '/new-student/exam/id',
    file: '/new-student/file/id',
    option: '/new-student/option',
    simpleOption: '/new-student/simple-option',
    billType:'/new-student/bill-type', 
    statistic:'/new-student/statistic',
    register: '/new-student/register',
    psbFlow: '/new-student/psb-flow',
    psbDone: '/new-student/psb-done/new_student_id',
    overallPsbFlow: '/new-student/overall-psb-flow',
    currentPsbFlow : '/new-student/psb-flow/new_student_id',
    cancel : '/new-student/cancel',
    import : '/new-student/import'
  },
  newParent: {
    root: '/new-parent',
    detail: '/new-parent/id',
    option: '/new-parent/option',
    statistic:'/new-parent/statistic',
    register: '/new-parent/register',
  },
  parallel: {
    root: '/parallel',
    option: '/parallel/option',
    import: '/parallel/import',
  },
  patient: {
    root: '/patient',
    notify: '/patient/notify',
  },
  parameter: {
    root: '/parameter',
    option: '/parameter/option',
    group: '/parameter/group'
  },
  product: {
    root: '/product',
    option: '/product/option', 
    cartByBarcode: '/product/cart-by-barcode', 
    cartById: '/product/cart-by-id', 
    image: '/product/image',
    import: '/product/import', 
    report: '/product/report', 
    sales: '/product/sales', 
    profit: '/product/profit', 
    restock: '/product/restock', 
    batchRestock: '/product/batch-restock', 
    template: '/product/template', 
  },
  
  parent: {
    root: '/parent',
    option: '/parent/option',
    detail: '/parent/id',
    statistic:'/parent/statistic',
    student:'/parent/student',
    photo:'/parent/photo',
    excel:'/parent/excel',
    setUsernameToMobilePhone:'/parent/set-username-to-mobile',
  },
  paymentChannel:{
    root: '/payment-channel',
    option: '/payment-channel/option',
  },
  payment:{
    root:'/payment',
    request: '/payment/request',
  },
  permission: {
    root: '/permission',
    employee: '/permission/employee',
    student: '/permission/student',
    option: '/permission/option', 
    detail:'/permission/id',
  },
  permissionType: {
    root: '/permission-type',
    option: '/permission-type/option'
  },
  position: {
    root: '/position',
    option: '/position/option'
  },
  psb: {
    root: '/psb',
    option: '/psb/option', 
    year: '/psb/year',
    open: '/psb/open',
  },
  psbFlow: {
    root: '/psb-flow',
    option: '/psb-flow/option', 
    id: '/psb-flow/id', 
    profile: '/psb-flow/profile', 
    bill: '/psb-flow/bill/new_student_id', 
    file: '/psb-flow/file', 
    veriFyFile: '/psb-flow/verify-file',
    isCurrent: '/psb-flow/is-current-flow',
    adminDecision: '/psb-flow/admin-decision',
    newParent: '/psb-flow/new-parent',
    newStudent: '/psb-flow/new-student',
    migrate: '/psb-flow/migrate',
    bypass: '/psb-flow/bypass',
    stepBack: '/psb-flow/step-back',
    accept: '/psb-flow/accept',
    reject: '/psb-flow/reject',
    examParticipant: '/psb-flow/exam-participant',
    examDecision: '/psb-flow/exam-decision',
  },
  register: 'register',
  reward: {
    root: '/reward',
    type: '/reward/type',
    typeOption: '/reward/type/option',
    student: '/reward/student',
  },
  role:{
    root:'/role',
    detail:'/role/id',
    option:'/role/option',
    application:'/role/application',
    priviledge:'/role/priviledge',
    user:'/role/user',
    approval:'/role/approval'
  },
  room:{
    root:'/room',
    option:'/room/option'
  },
  schedule: {
    root: '/schedule',
    option: '/schedule/option',
    day: '/schedule/day'
  },
  school: {
    root: '/school',
    option: '/school/option'
  },
  schoolCase: {
    root: '/school-case',
    option: '/school-case/option'
  },
  schoolTime: {
    root: '/school-time',
    option: '/school-time/option'
  },
  score: {
    root: '/score',
    option: '/score/option',
    kelas: '/score/kelas',
    step: '/score/step',
    kelasExcel:'/score/kelas/excel'
  },
  setting: {
    root: '/setting',
    option: '/setting/option',
    general:'/setting/general',
    group: '/setting/group',
    admin:'/setting/admin',
    home:'/setting/home',
    code:'/setting/code', 
    codeFile:'/setting/code/file'
  },
  smartCard: {
    root: '/smart-card',
    option: '/smart-card/option',
    new: '/smart-card/new'
  },
  smartReader: {
    root: '/smart-reader',
    option: '/smart-reader/option',
    modeDetail: '/smart-reader/mode-detail',
    queue: '/smart-reader/queue',
    lastQueue: '/smart-reader/last-queue',
    clearQueue: '/smart-reader/clear-queue',
    studentQueue: '/smart-reader/student-queue',
    employeeQueue: '/smart-reader/employee-queue'
  },
  store: {
    root: '/store',
    option: '/store/option',
    withdrawal: '/store/withdrawal',
    withdrawalReceived: '/store/withdrawal-received',
    withdrawalLast: '/store/withdrawal-last',
    withdrawalRequest: '/store/withdrawal-request',
    withdrawalApproval : '/store/withdrawal-approval',
    withdrawalAutodebet: '/store/withdrawal-autodebet',
    detail: '/store/id',
    employee: '/store/employee',
    paySimpleCashless: '/store/pay-simple-cashless',
    payMinimarketCash: '/store/pay-minimarket-cash',
    payMinimarketCashless: '/store/pay-minimarket-cashless', 
    sales: '/store/sales/id',
    unpaidBuy: '/store/unpaid-buy',
    payDebtBalance: '/store/pay-debt-balance',
  }, 
  student: {
    root: '/student',
    detail: '/student/id',
    option: '/student/option',
    nullParallel: '/student/null-parallel', 
    statistic:'/student/statistic',
    syncParent:'/student/parent/sync',
    billType:'/student/bill-type', 
    photo:'/student/photo',
    mutation:'/student/mutation',
    class:'/student/class',
    moved:'/student/moved',
    allPresent:'student/all-present',
    attendance:'student/attendance',
    performance:'student/performance',
    import:'student/import',
    file: '/student/file/id',
    group: '/student/group',
    excel: '/student/excel',
  },
  studentPermission: {
    root: '/student-permission',
    option: '/student-permission/option',
  },
  subject: {
    root: '/subject',
    option: '/subject/option', 
    teacher: '/subject/teacher',
    import: '/subject/import'
  },
  tahfidz: {
    root: '/tahfidz',
    progress: '/tahfidz/progress',
    monthly: '/tahfidz/monthly'
  },
  tahunPelajaran: {
    root: '/tahun-pelajaran',
    option: '/tahun-pelajaran/option',
    active: '/tahun-pelajaran/active'
  },
  teachingTime: {
    root: '/teaching-time',
    option: '/teaching-time/option'
  },
  test: {
    exportPdf: 'test/export-pdf',
    exportExcel: 'test/export-excel',
    importExcel: 'test/import-excel',
  },
  note: {
    teaching: 'note/teaching',
  },
  notification: {
    root: '/notification',
    detail: '/notification/id'
  },
  transaction: {
    root: '/transaction',
    amount: '/transaction/amount',
    detail: '/transaction/id',
    option: '/transaction/option',
    statistic:'/transaction/statistic',
    income:'/transaction/income',
    expense:'/transaction/expense',
    transfer:'/transaction/transfer', 
    equity:'/transaction/equity', 
    export:'/transaction/export/id',
    excel:'/transaction/excel',
  },
  user: {
    root: 'user',
    option: '/user/option',
    employee: '/user/employee',
    student: '/user/student',
    parent: '/user/parent',
    visitor: '/user/visitor',
    by_token: 'user/by-token',
    byUsername: 'user/by-username',
    role:'user/role',
    statistic:'/user/statistic',
    changePassword:'/user/change-password',
    resetPassword:'/user/reset-password',
    resetPasswordRequest:'/reset-password-request'
  },
  waliKelas: {
    root: '/wali-kelas',
    option: '/wali-kelas/option'
  },
  vendor: {
    root: '/vendor',
    option: '/vendor/option'
  },
  news: {
    root: '/news',
    option: '/news/option',
    reader:'/news/reader', 
    user:'/news/user/id'
  
  },
  visit: {
    root: '/visit',
    option: '/visit/option',
    statistic:'/visit/statistic'
  },
  visitor: {
    root: '/visitor',
    option: '/visitor/option',
    statistic:'/visitor/statistic'
  },
  wilayah: {
    province: '/province',
    provinceRegency: '/province/regency/id',
    regencyDistrict: '/regency/district/id',
    districtVillage: '/district/village/id',
  },
  
  
};
