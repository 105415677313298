import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Send from '@mui/icons-material/Send';
import { gettransactionTypeLabel } from 'utils/options';
import { format, parse, parseISO } from 'date-fns';

const BalanceMutation = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return []

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const defaultFilters = {
    start_date: new DateObject,
    end_date: new DateObject,

  }
  const [filters, setFilters] = useState(defaultFilters)

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }

    filtersTemp[fieldName] = value

    setFilters(filtersTemp)
  }

  const getMutations = async () => {
    let params = {
      balance_id: row.id,
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
    }

    const response = await axios.get(endpoint.balance.mutation, { params: params })
    if (response) {
      setstate(response.data)
    }
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    getMutations()
  }, [])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container>
          <Grid item xs={6} md={4} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Mulai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.start_date}
                onChange={(e) => filterChange('start_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={4} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Sampai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.end_date}
                onChange={(e) => filterChange('end_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={6} md={4} item container justifyContent='flex-end' alignItems='flex-end'>
            <IconButton
              onClick={getMutations}
              aria-label='send'
              size="small">
              <Send color='primary' />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            width: 'inherit',
            minHeight: 400,
            mt: 1
          }}
        >
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell >Nomor</TableCell>
                  <TableCell >Tanggal</TableCell>
                  <TableCell >Jam</TableCell>
                  <TableCell >Jenis Transaksi</TableCell>
                  <TableCell >Nominal</TableCell>
                  <TableCell >Keterangan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      #{row.number}
                    </TableCell>
                    <TableCell >
                      {format(parse(row.transactionDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')}
                    </TableCell>
                    <TableCell >
                      {format(parseISO(row.created_at), 'HH:mm')}
                    </TableCell>
                    <TableCell >
                      {gettransactionTypeLabel(row.transaction_type_value)}
                    </TableCell>
                    <TableCell >
                      {row.user_cashflow === 'C' && '-'}
                      {row.user_cashflow === 'D' && '+'}
                      {new Intl.NumberFormat().format(row.amount)}
                    </TableCell>
                    <TableCell >
                      {row.description}
                    </TableCell>
                    <TableCell>
                      {row.admin && row.admin.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>
  </>;
}

export default BalanceMutation;

