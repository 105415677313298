import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUI from 'hooks/useUI.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth.js';
import { Add, Verified } from '@mui/icons-material';
import PaymentMethod from 'views/payment/PaymentMethod.js';
import PaymentCharge from 'views/payment/PaymentCharge.js';
import { useLocation } from 'react-router-dom';
import VerticalPillSelection from 'components/VerticalPillSelection';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import Conditional from 'components/Conditional';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const PsbBill = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showError } = useUI()
  const { user, detail } = useAuth()
  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const [selectedBill, setselectedBill] = useState(null)
  const [bills, setbills] = useState([])
  const [openChannelDialog, setopenChannelDialog] = useState(false);
  const [openInstallmentDialog, setopenInstallmentDialog] = useState(false);
  const [openVADialog, setopenVADialog] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const [paymentRequest, setpaymentRequest] = useState(null)
  const [installmentAmount, setinstallmentAmount] = useState(0);
  const [installmentType, setinstallmentType] = useState('1');
  const query = useQuery();

  const getnewStudentOptions = async () => {
    let params = {
      user_id: user.id
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      setnewStudentOptions(response.data)
      let new_student_id = query.get('new_student_id')


      if (response.data[0]) {
        if (new_student_id) {
          setselectedNewStudent(response.data.filter(newStudent => (newStudent.id === parseInt(new_student_id)))[0])
        } else {
          setselectedNewStudent(response.data[0])
        }

      }

    } else {
      showError('get new student')
    }
  }

  const closeInstallmentDialog = () => {
    setopenInstallmentDialog(false)
  }

  const installmentAmountChange = (e) => {
    setinstallmentAmount(parseInt(e.target.value))
  }

  const installmentTypeChange = (event) => {
    setinstallmentType(event.target.value)
    if (event.target.value === '1') {
      setinstallmentAmount(selectedBill.amount)
    }
  }

  const newStudentClick = (newStudent) => {
    setselectedNewStudent(newStudent)
  }

  const getBills = async (new_student_id) => {
    let params = {}

    const response = await axios.get(endpoint.psbFlow.bill.replace('new_student_id', new_student_id), { params: params })
    if (response && response.data) {
      setbills(response.data)
    }
  }

  const submitPaymentRequest = async (method, channel) => {
    setisLoading(true)

    let installmentTypeFinal = Number(installmentType)

    if (installmentAmount === selectedBill.amount) {
      installmentTypeFinal = 1
    }

    let payload = {
      installment_type_value: installmentTypeFinal,
      payment_channel_id: channel.id,
      user_id: user.id,
      installment: installmentAmount,
      bill_ids: [selectedBill.id],
      payment_method_value: 2
    }


    const response = await axios.post(endpoint.bill.parentPayment, payload)

    if (response && response.data) {
      setpaymentRequest(response.data)
      setopenChannelDialog(false)
      showVaAvailable()
    } else {
      showError('request virtual account')
    }

    setisLoading(false)
  }

  const showPaymentChannel = (bill) => {
    setselectedBill(bill)
    setopenChannelDialog(true);
  };

  const showVaAvailable = () => {
    setopenVADialog(true);
  };

  const handlePayClick = (bill) => {
    setselectedBill(bill)
    setinstallmentAmount(bill.amount)
    if (bill && bill.bill_type && bill.bill_type.isCicil === 1) {
      setopenInstallmentDialog(true)
    } else {
      setopenChannelDialog(true);
    }

  };

  const validation = () => {
    if (installmentAmount < 10000) {
      showError('minimal pembayaran 10.000'); 
      return;
    }

    if (installmentType === '2') {
      
      if (selectedBill.bill_type.minimumCicil && selectedBill.bill_type.minimumCicil > 0) {
        if (installmentAmount < selectedBill.bill_type.minimumCicil) {
          showError('minimal cicilan ' + new Intl.NumberFormat().format(selectedBill.bill_type.minimumCicil))
        }
        else if (installmentAmount > selectedBill.amount - selectedBill.paidAmount) {
          showError('tidak boleh melebihi Rp. ' + new Intl.NumberFormat().format(selectedBill.amount - selectedBill.paidAmount))
        }
        else {
          setopenChannelDialog(true);
        }

      } else {
        setopenChannelDialog(true);
      }
    } else {
      setopenChannelDialog(true);
    }


  };



  useEffect(() => {
    if (detail) {
      getnewStudentOptions()
    }
  }, [detail])

  useEffect(() => {
    if (selectedNewStudent) {
      getBills(selectedNewStudent.id)
    }
  }, [selectedNewStudent])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>


        </Grid>
      </Grid>

      <VerticalPillSelection
        selected={selectedNewStudent}
        options={newStudentOptions}
        onSelection={newStudentClick}
      />

      <Grid container alignItems='flex-start' alignContent='flex-start' sx={{ minHeight: 400 }} >
        Daftar pembayaran :
        <Grid container justifyContent='center' alignItems='flex-start' sx={{ mt: 2 }} >
          <Grid item xs={12} md={6} container justifyContent='center' alignItems='flex-start' >
            {
              bills.map(bill => (
                <Grid container justifyContent='center' alignItems='center'
                  sx={{
                    bgcolor: bill.pay_status_value === 2 ? '#5ac58c' : '#ffa0a0',
                    p: 2,
                    borderRadius: 2,
                    color: 'white',
                    my: 1
                  }} >
                  <Grid item xs={6} container >
                    <Typography variant="h6" component="h6" align="center">
                      {bill.bill_type && bill.bill_type.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container justifyContent='flex-end' alignItems='center'>
                    {
                      bill.pay_status_value === 2 &&
                      <Box bgcolor='white' width={36} height={36} display='flex' justifyContent='center' alignItems='center' borderRadius={50}>
                        <Verified color='success' fontSize='large' />
                      </Box>
                    }

                  </Grid>
                  <Grid item xs={12} container justifyContent='center' alignItems='center'>
                    <Typography variant="h3" component="h3" align="center">
                      Rp. {
                      bill.pay_status_value === 2 ? 
                      new Intl.NumberFormat().format(bill.amount) :
                      new Intl.NumberFormat().format(bill.amount-bill.paidAmount)
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-end' sx={{ mt: 1 }}>
                    {
                      bill.pay_status_value !== 2 &&
                      <Button onClick={() => handlePayClick(bill)} fullWidth variant='contained' color='success'>
                        Bayar
                      </Button>
                    }

                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Dialog
      open={openChannelDialog}
      maxWidth='md'
      onClose={() => setopenChannelDialog(false)}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <PaymentMethod
        viaBalance={false}
        onSubmit={submitPaymentRequest}
        onBack={() => setopenChannelDialog(false)}
        isLoading={isLoading}
      />
    </Dialog>


    <Dialog
      open={openVADialog}
      maxWidth='md'
      onClose={() => setopenVADialog(false)}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <PaymentCharge onClose={() => setopenVADialog(false)} paymentRequest={paymentRequest} />
    </Dialog>

    <Dialog
      open={openInstallmentDialog}
      maxWidth='md'
      onClose={closeInstallmentDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      <DialogTitle id="form-dialog-title">Konfirmasi pembayaran</DialogTitle>
      <DialogContent>

        <Grid container spacing={1} alignItems='center' >
          <RadioGroup
            aria-labelledby="payment_type"
            defaultValue='1'
            name="payment-type-group"
            value={installmentType}
            onChange={installmentTypeChange}
          >
            <FormControlLabel value='1' control={<Radio />} label="Tunai" />
            <FormControlLabel value='2' control={<Radio />} label="Mencicil" />
          </RadioGroup>
        </Grid>

        <Conditional condition={installmentType === '2'}>
          {
            selectedBill && selectedBill.bill_type && selectedBill.bill_type.minimumCicil &&
            <Grid container spacing={1} alignItems='center' >
              <Grid item>
                <Typography variant="body" component="p">
                  Minimum cicilan Rp. {new Intl.NumberFormat().format(selectedBill.bill_type.minimumCicil)}
                </Typography>
              </Grid >
            </Grid>
          }

          <Grid container spacing={1} alignItems='center' sx={{ mt: 2 }}>
            <Grid item>
              <Typography variant="h5" component="div">
                Jumlah cicilan
              </Typography>
            </Grid >
            <Grid item>
              <TextField
                type='number'
                fullWidth
                onChange={installmentAmountChange}
                value={installmentAmount.toString()}
              />
            </Grid>
          </Grid>
        </Conditional>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeInstallmentDialog} color="secondary">
          Batal
        </Button>
        <Button variant="contained" onClick={validation} color="primary">
          Bayar
        </Button>

      </DialogActions>
    </Dialog>

  </>;
}


export default PsbBill;

