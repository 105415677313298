import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormControlLabel, Switch, Checkbox } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Confirmation from 'components/Confirmation';
import StatusChip from 'components/StatusChip';
import { getwithdrawalStatusObject } from 'utils/options';
import useAuth from 'hooks/useAuth';


const StoreWithdrawalDetail = (props) => {
  const { row = {}, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError } = useUI()
  const classes = useStyles();
  const reset = () => {
    return {
      id: undefined,
      store: null,
      store_id: undefined,
      amount: undefined,
      withdrawal_status_value: undefined,
      store_employee: null,
      store_employee_id: undefined,
      admin_employee: null,
      admin_employee_id: undefined,
      coa_kas: null,
      coa_kas_id: undefined,

    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [coa_harta_options, setcoa_harta_options] = useState([]);


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'coa_kas') {
      newState['coa_kas_id'] = value.value
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getcoa_harta_options = async () => {
    const params = {
      group: 'HARTA',
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_harta_options(generalListOptionMapper(response.data));
    }
  };

  const closeDialog = () => {
    setopenDialog(false)
  }

  const approve = async () => {
    let payload = {
      withdrawal_id: row.id
    }

    const response = await axios.post(endpoint.store.withdrawalApproval, payload)
    if (response && response.data) {
      showSuccess('save approval')
      getData()
      props.closeDialog()
    } else {
      showError('save approval')
    }
  }

  const withdrawal = async () => {
    let payload = {
      withdrawal_id: row.id,
      coa_kas_id: state.coa_kas_id,
      admin_employee_id: detail.id
    }

    const response = await axios.post(endpoint.store.withdrawal, payload)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('pencairan toko')
        getData()
        props.closeDialog()
      }

    } else {
      showError('pencairan toko')
    }
  }

  const approveConfirmation = () => {
    setdialogContent(
      <Confirmation
        handleClose={closeDialog}
        action={approve}
        actionName="Setujui"
        message='Pastikan uangnya sudah tersedia'
        title='Konfirmasi'
      />
    )
    setopenDialog(true)
  }

  const withdrawalConfirmation = async () => {
    if (!state.coa_kas_id) {
      showError('Pilih sumber dana terlebih dahulu')
    } else {

      setdialogContent(
        <Confirmation
          handleClose={closeDialog}
          action={withdrawal}
          actionName="Cairkan"
          message='Berikan uangnya dan dikonfirmasi oleh penerima'
          title='Pencairan'
        />
      )
      setopenDialog(true)
    }

  }



  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      coa_kas: generalOptionMapper(row.coa_kas)
    })
  }

  useEffect(() => {
    getcoa_harta_options()
  }, [])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])


  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Toko
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.store && state.store.name} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Pegawai
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.store_employee && state.store_employee.name} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Jumlah
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={new Intl.NumberFormat().format(state.amount)} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Status
              </Grid>
              <Grid item sm={8} xs={12}>
                <StatusChip getStatus={getwithdrawalStatusObject} status={state.withdrawal_status_value} />
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.withdrawal_status_value > 0}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Rekening sumber dana
              </Grid>
              <Grid item sm={8} xs={12}>
                <Select
                  name='coa_kas'
                  placeholder='Pilih Rek. Sumber'
                  options={coa_harta_options}
                  value={state.coa_kas}
                  onChange={(e) => handleChange('coa_kas', e)}
                  styles={defaultSelectStyle}
                />
              </Grid>
            </Grid>
          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={state.withdrawal_status_value === 0}>
        <Button variant="contained" onClick={approveConfirmation} color="primary">
          Setujui
        </Button>
      </Conditional>

      <Conditional condition={state.withdrawal_status_value === 1}>
        <Button variant="contained" onClick={withdrawalConfirmation} color="success">
          Cairkan
        </Button>
      </Conditional>

    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth='sm'
      onClose={closeDialog}
      fullWidth
      fullScreen={false}
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  </>;
}

export default StoreWithdrawalDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
