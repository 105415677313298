import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { getkitabTypeLabel, getkitabTypeObject, getsuratObject, getsymptomTypeLabel, kitabTypeOptions, suratOptions, symptomTypeOptions } from 'utils/options';
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import UserCard from 'components/UserCard';
import { IconButton, Switch } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { EchoInstance } from 'App';


const PatientDetail = (props) => {
  const { row = {}, getData, getDataByParams } = props
  const { detail } = useAuth()
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      user_id: undefined,
      student: null,
      student_id: undefined,
      employee: null,
      employee_id: undefined,
      student: null,
      symptom_type: null,
      symptom_type_value: undefined,
      symptoms: undefined,
      treatment: undefined,
      medicine: undefined,
      description: undefined,
      isNotify: 0,
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [queue, setqueue] = useState(null)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isNotify"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'surat') {
      newState['surat_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'symptom_type') {
      newState['symptom_type_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'student') {
      newState['student_id'] = value ? value.value : undefined
      newState['user_id'] = value ? value.user_id : undefined
    }
    else if (fieldName === 'employee') {
      newState['employee_id'] = value ? value.value : undefined
      newState['user_id'] = value ? value.user_id : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        setqueue(response.data)
        setstate({ ...state, user_id: response.data.user_id })
      } else {
        setqueue(null)
      }
    }

  }

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
        getLastQueue()
      } else {
        showError('delete queue')
      }
    }
    setstate(reset())
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getHistory = async () => {
    getDataByParams(
      {
        student_id: queue.detail_id
      }
    )
    props.closeDialog()
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => {
    setnameKeyWord(newValue)
    return newValue;
  };

  const handleStudentChange = (value) => {
    handleChange("student", value)
    if (value) {
      insertStudentToQueue(value)
      getLastQueue()
    }
  };

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleEmployeeInputChange = (newValue) => {
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleEmployeeChange = (value) => {
    handleChange("employee", value)
    if (value) {
      insertEmployeeToQueue(value)
      getLastQueue()
    }
  };

  const insertStudentToQueue = async (student) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        student_id: student.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'UKS',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.studentQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }

    } else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const insertEmployeeToQueue = async (employee) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        employee_id: employee.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'UKS',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.employeeQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    }

  }

  const insert = async (payload) => {
    const response = await axios.post(endpoint.patient.root, payload)
    if (response && response.data) {
      showSuccess('save patient')
      getData()
      deleteLastQueue()
    } else {
      showError('save patient')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.patient.root, payload)
    if (response && response.data) {
      showSuccess('save patient')
      getData()
      deleteLastQueue()
    } else {
      showError('save patient')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      kitab_type: getkitabTypeObject(row.kitab_type_value),
      detail: row.user.detail
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  useEffect(() => {
    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME + 'card')
      .listen('NewCardQueue', (e) => {
        getLastQueue()
      });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME + 'card');
    }
  }, [])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Pasien
            </Grid>
            <Grid item xs={12} md={10}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.detail && state.detail.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                  {
                    queue && <UserCard user={queue} onClose={deleteLastQueue} onClick={getHistory} />
                  }
                  <Conditional condition={queue === null}>
                    <IconButton
                      onClick={getLastQueue}
                      aria-label='reset'
                      size="large">
                      <RefreshIcon color='primary' />
                    </IconButton>
                  </Conditional>
                </Grid>

                <Conditional condition={queue === null}>
                  <Grid container item xs={12} justifyContent="center" alignItems='center' sx={{ mb: 1 }}>
                    <AsyncSelect
                      cacheOptions
                      placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                      value={state.student}
                      loadOptions={loadOptions}
                      onInputChange={handleInputChange}
                      onChange={handleStudentChange}
                      styles={defaultSelectStyle}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="center" alignItems='center' >
                    <AsyncSelect
                      cacheOptions
                      placeholder="Nama pegawai..."
                      value={state.employee}
                      loadOptions={loadEmployeeOptions}
                      onInputChange={handleEmployeeInputChange}
                      onChange={handleEmployeeChange}
                      styles={defaultSelectStyle}
                    />
                  </Grid>
                </Conditional>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Keperluan
            </Grid>
            <Grid item xs={12} md={10}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getsymptomTypeLabel(state.symptom_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="User Type"
                  placeholder="Pilih keperluan"
                  options={symptomTypeOptions}
                  value={state.symptom_type}
                  onChange={(e) => handleChange("symptom_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Gejala
            </Grid>
            <Grid item xs={12} md={10}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.symptoms} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='symptoms'
                  fullWidth
                  value={state.symptoms}
                  onChange={(e) => handleChange('symptoms', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Tindakan
            </Grid>
            <Grid item xs={12} md={10}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.treatment} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='treatment'
                  fullWidth
                  value={state.treatment}
                  onChange={(e) => handleChange('treatment', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Obat
            </Grid>
            <Grid item xs={12} md={10}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.medicine} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='medicine'
                  fullWidth
                  value={state.medicine}
                  onChange={(e) => handleChange('medicine', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
              Keterangan
            </Grid>
            <Grid item xs={12} md={10}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.description} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='description'
                  fullWidth
                  value={state.description}
                  onChange={(e) => handleChange('description', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={(pageAction === "CREATE")}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item xs={12} md={2} container justifyContent='flex-start' alignContent='center'>
                Beritahu orang tua
              </Grid>
              <Grid item xs={12} md={10}>
                <Switch
                  color='primary'
                  checked={state.isNotify === 1}
                  onChange={(e) => handleChange("isNotify", e)}
                />
              </Grid>
            </Grid>
          </Conditional>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default PatientDetail;

