import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import { Container, Typography } from '@mui/material';
import { format } from 'date-fns'


const PaymentCharge = (props) => {
  const { row = {}, paymentRequest = {} } = props

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='center'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Grid item xs={12} sx={{ p: 2 }}>
            Segera lakukan pembayaran
          </Grid>

          <Grid container justifyContent='center'
            sx={{
              p: 2,
              background: 'linear-gradient(90deg, #48c6ef 0%, #6f86d6 100%)',
              borderRadius:4,
              width:'90%',
              color:'white'
            }}
          >
            <Grid container justifyContent='center' sx={{ p: 1 }}>
              {paymentRequest.payment_channel}
            </Grid>

            <Grid container justifyContent='center' sx={{ p: 1 }}>
              <Typography variant="h5" component="h5" align="center">
                {paymentRequest.payment_number}
              </Typography>
            </Grid>

            <Grid container justifyContent='center' sx={{ p: 1 }}>
              <Typography variant="h6" component="h6" align="center">
                Rp. {new Intl.NumberFormat().format(paymentRequest.gross_amount)}
              </Typography>
            </Grid>
          </Grid>



          <Grid container sx={{ p: 2 }}>
            <Grid item xs={6} container justifyContent='flex-start' alignContent='center'>
              Batas waktu
            </Grid>

            <Grid item xs={6} container justifyContent='flex-end'>
              <Typography variant="h6" component="h6" align="center" color='error'>
                {
                  format(new Date(paymentRequest.expired * 1000), 'dd MMMM yyyy HH:mm')
                }
              </Typography>

            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="secondary">
        Kembali
      </Button>
    </DialogActions>
  </>;
}

export default PaymentCharge;

