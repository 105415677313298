import React from 'react'
import { Box, Typography } from '@mui/material';
import Link from 'components/Link.js';

import useUI from 'hooks/useUI';
import BreadCrumbSeparator from './BreadCrumbSeparator';
import FavoriteMenuButton from './FavoriteMenuButton';


const MenuBreadCrumb = (props) => {
  const { startLoading, stopLoading, activeMenu = {} } = useUI()
  return (
    <>
      <FavoriteMenuButton />
      <Box display='flex' alignItems='center'>
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
      </Box>

      <BreadCrumbSeparator />

      <Typography color='primary' variant='subtitle1' sx={{fontWeight:'bold'}}>
        {activeMenu.name}
      </Typography>


    </>
  )

}

export default MenuBreadCrumb;

