import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import NewStudentAdminDetail from './NewStudentAdminDetail';
import useUI from 'hooks/useUI';
import NewStudentAdminFile from './NewStudentAdminFile';
import StudentBillType from 'views/student/StudentBillType';
import NewStudentAdminExam from './NewStudentAdminExam';
import GeneralImport from 'components/GeneralImport';

// ----------------------------------------------------------------------

export default function NewStudentAdminForm(props) {
  const { row, pageAction } = props
  const { startLoading, stopLoading, activeMenu = {} } = useUI()
  const [currentTab, setCurrentTab] = useState('biodata');
  const [tabs, settabs] = useState([{ 
    value: 'biodata',
    component: <NewStudentAdminDetail {...props} />
  }]);

 
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if(pageAction==='IMPORT'){
      settabs([
        {
          value: 'import',
          component: <GeneralImport {...props} />
        },
      ])
      setCurrentTab('import')
    }
    else if (["CREATE"].includes(pageAction)) {
      settabs([
        {
          value: 'biodata',
          component: <NewStudentAdminDetail {...props} />
        }
      ])
    }
    else if (["READ", 'EDIT'].includes(pageAction)) {
      settabs([
        {
          value: 'biodata',
          component: <NewStudentAdminDetail {...props} />
        },
        {
          value: 'berkas',
          component: <NewStudentAdminFile {...props} />
        },
        {
          value: 'ujian',
          component: <NewStudentAdminExam {...props} />
        },
        {
          value: 'pengaturan harga',
          component: <StudentBillType user_type_value={7} {...props} />
        }
      ])
    }

  }, [pageAction])

  return (
    <Container>
      <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} style={{ padding: 8 }}>
        <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {tabs.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
