import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Grid, Typography } from '@mui/material';

import MenuDetail from './MenuDetail';
import useUI from 'hooks/useUI';
import MenuAction from './MenuAction';
import BreadCrumbSeparator from 'components/BreadCrumbSeparator';

// ----------------------------------------------------------------------

export default function MenuForm(props) {
  const { row, pageAction } = props
  const { startLoading, stopLoading, activeMenu = {}, activeForms = [] } = useUI()
  const [currentTab, setCurrentTab] = useState('detail');

  let TABS = [
    {
      value: 'detail',
      component: <MenuDetail {...props} />
    }
  ];

  if (row && pageAction !== "CREATE") {
    TABS = [
      ...TABS,
      {
        value: 'action',
        component: <MenuAction {...props} />
      }
    ]
  }



  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container>
      <Grid container item alignItems='center' alignContent='center' xs={12} sm={12} md={6} lg={6} sx={{ pt: 2 }}>
        <Typography color='primary' component='h5' variant='h5'>
          {activeMenu.name}
        </Typography>
        {activeForms.map(form => (
          <>
            <BreadCrumbSeparator />
            <Typography color='primary' component='h5' variant='h5'>
              {form}
            </Typography>
          </>
        ))}
      </Grid>
      <Box
        sx={{
          paddingBottom: 1
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 1 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
