import React,{useState, useEffect, useRef} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Conditional from 'components/Conditional';
import { Check } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import { TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { format,parse } from 'date-fns'


const SettingTime =(props)=> {
  const{setting={}, saveSetting} = props
  const [isEditing, setisEditing] = useState(false)
  const [state, setstate] = useState({})
    
  const save=()=>{
    setisEditing(false)
    saveSetting(state)    
  }

  const cancel=()=>{
    setisEditing(false)
    setstate({
      ...setting,
      time:parse(setting.timeValue, 'HH:mm:ss', new Date())
    })
  }

  const handleChange=(e)=>{
    setstate({
      ...state,
      time:e,
      timeValue:format(e, 'HH:mm:00')
    })
  }
  
  useEffect(() => { 
    if(setting){
      setstate({
        ...setting,
        time:parse(setting.timeValue, 'HH:mm:ss', new Date())
      })      
    }      
  }, [setting])  

    return (
      <Paper sx={{
        p:1, 
        width:'90%', 
        }}>
          <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>
              {state.name}
          </Typography>
          <Conditional condition={isEditing===false}>
              <IconButton
              onClick={()=>setisEditing(true)}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
              <EditIcon fontSize='inherit' />
              </IconButton>
          </Conditional>
          
          <Conditional condition={isEditing===true}>
              <Grid item>
              <IconButton
              onClick={cancel}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
                  <Close fontSize='inherit' />
              </IconButton>
              <IconButton
              onClick={save}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
                  <Check fontSize='inherit' />
              </IconButton>
              </Grid>
          </Conditional>
          </Grid> 
          <Conditional condition={isEditing===false}>
              <Grid container>
                  {state.timeValue && state.timeValue.substring(0,5)}
              </Grid> 
          </Conditional>
          <Conditional condition={isEditing===true}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>          
            <Grid container alignItems='flex-start'>
              <TimePicker
                autoOk
                ampm={false}
                variant="inline"
                inputVariant="outlined"
                renderInput={(props) => <TextField {...props} />}
                value={state.time}
                onChange={handleChange}
              />
            </Grid>
          </LocalizationProvider>
          </Conditional>                
    </Paper>
    );
  }
  

export default SettingTime;