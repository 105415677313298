import { Star } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import useUI from 'hooks/useUI';
import React from 'react'


const FavoriteMenuButton = (props) => {
  const { isFavoriteMenu, toggleFavoriteMenu } = useUI()
  return (
    <IconButton
      onClick={toggleFavoriteMenu}
      aria-label='favorite'
      size="small">
      <Star fontSize='medium' sx={{
        color: isFavoriteMenu() ? '#fea500' : '#a0a1a4'
      }} />
    </IconButton>
  )

}

export default FavoriteMenuButton;

