import React from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import {
  Grid,
  Box,
  Typography,
  Stack,
  useMediaQuery
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import EditStringPopUp from 'components/EditStringPopUp';
import EditIcon from '@mui/icons-material/Edit';
import EditRichTextPopUp from 'components/EditRichTextPopUp';
import { useTheme } from '@mui/styles';

const Facilities = (props) => {
  const {
    title = '',
    content = '',
    image1 = '',
    image2 = '',
    image3 = '',
    image4 = '',
    imageTitle1 = '',
    imageTitle2 = '',
    imageTitle3 = '',
    imageTitle4 = '',
    backgroundColor
  } = props

  const { user, hasRole } = useAuth()
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const saveTitle = (value) => {
    props.saveSetting('HOME_FACILITIES_TITLE', value)
  }

  const saveContent = (value) => {
    props.saveSetting('HOME_FACILITIES_CONTENT', value)
  }

  const saveImageTitle1 = (value) => {
    props.saveSetting('HOME_FACILITIES_IMAGE_TITLE_1', value)
  }

  const saveImageTitle2 = (value) => {
    props.saveSetting('HOME_FACILITIES_IMAGE_TITLE_2', value)
  }

  const saveImageTitle3 = (value) => {
    props.saveSetting('HOME_FACILITIES_IMAGE_TITLE_3', value)
  }

  const saveImageTitle4 = (value) => {
    props.saveSetting('HOME_FACILITIES_IMAGE_TITLE_4', value)
  }

  const saveWithFile1 = async (event) => {
    let file = event.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('code', 'HOME_FACILITIES_IMAGE_1')

      const response = await axios.post(endpoint.setting.codeFile, formData, {});
      if (response && response.data) {
        props.getData()
      }
    }

  };

  const saveWithFile2 = async (event) => {
    let file = event.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('code', 'HOME_FACILITIES_IMAGE_2')

      const response = await axios.post(endpoint.setting.codeFile, formData, {});
      if (response && response.data) {
        props.getData()
      }
    }

  };

  const saveWithFile3 = async (event) => {
    let file = event.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('code', 'HOME_FACILITIES_IMAGE_3')

      const response = await axios.post(endpoint.setting.codeFile, formData, {});
      if (response && response.data) {
        props.getData()
      }
    }

  };

  const saveWithFile4 = async (event) => {
    let file = event.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('code', 'HOME_FACILITIES_IMAGE_4')

      const response = await axios.post(endpoint.setting.codeFile, formData, {});
      if (response && response.data) {
        props.getData()
      }
    }


  };

  return (
    <Box bgcolor='#ffffff'>
      <Grid container sx={{
        py: 8,
        px: 4,
        color: '#ffffff',
        backgroundColor: { backgroundColor },
        borderTopLeftRadius: 100,
        borderBottomTopRadius: 100
      }}>
        <Grid item xs={12} md={6} container justifyContent='center' alignContent='center'>
          <Grid item xs={12} container justifyContent='center' alignContent='center'
            sx={{
              px: 1,
            }}
          >
            <Typography variant={smallScreen ? 'h4' : 'h3'} component="div" >
              {title}
              {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={title} save={saveTitle} />}
              <span id='facilities'></span>
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent='center' alignContent='center'
            sx={{
              px: 1,
            }}
          >
            <Typography variant="body1" component="div" >
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              {hasRole('SCHOOL_ADMIN') && <EditRichTextPopUp value={content} save={saveContent} />}
            </Typography>
          </Grid>

        </Grid>

        <Grid item xs={12} md={6} container justifyContent="center" alignItems="flex-start" sx={{ pt: 4 }}>
          <Grid item xs={6} container justifyContent='center' alignContent='center'>
            <Stack justifyContent="center" alignItems="flex-start">
              <Grid item xs={12} container justifyContent='center'>
                <img
                  src={`${api.url}/${image1}?t=${Date.now()}`}
                  style={{ width: 100, height: 100, borderRadius: '25%' }}
                />
                {hasRole('SCHOOL_ADMIN') &&
                  <label htmlFor='HOME_FACILITIES_IMAGE_1'>
                    <input onChange={saveWithFile1} accept="image/*" id='HOME_FACILITIES_IMAGE_1' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer' }} />
                  </label>
                }
              </Grid>

              <Typography variant="h6" component="div" textAlign='center' sx={{ width: '100%' }}>
                {imageTitle1}
                {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={imageTitle1} save={saveImageTitle1} />}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} container justifyContent='center' alignContent='center'>
            <Stack justifyContent="center" alignItems="flex-start">
              <Grid item xs={12} container justifyContent='center'>
                <img
                  src={`${api.url}/${image2}?t=${Date.now()}`}
                  style={{ width: 100, height: 100, borderRadius: '25%' }}
                />
                {hasRole('SCHOOL_ADMIN') &&
                  <label htmlFor='HOME_FACILITIES_IMAGE_2'>
                    <input onChange={saveWithFile2} accept="image/*" id='HOME_FACILITIES_IMAGE_2' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer' }} />
                  </label>
                }
              </Grid>

              <Typography variant="h6" component="div" textAlign='center' sx={{ width: '100%' }}>
                {imageTitle2}
                {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={imageTitle2} save={saveImageTitle2} />}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} container justifyContent='center' alignContent='center'>
            <Stack justifyContent="center" alignItems="flex-start">
              <Grid item xs={12} container justifyContent='center'>
                <img
                  src={`${api.url}/${image3}?t=${Date.now()}`}
                  style={{ width: 100, height: 100, borderRadius: '25%' }}
                />
                {hasRole('SCHOOL_ADMIN') &&
                  <label htmlFor='HOME_FACILITIES_IMAGE_3'>
                    <input onChange={saveWithFile3} accept="image/*" id='HOME_FACILITIES_IMAGE_3' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer' }} />
                  </label>
                }
              </Grid>
              <Typography variant="h6" component="div" textAlign='center' sx={{ width: '100%' }}>
                {imageTitle3}
                {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={imageTitle3} save={saveImageTitle3} />}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} container justifyContent='center' alignContent='center'>
            <Stack justifyContent="center" alignItems="flex-start">
              <Grid item xs={12} container justifyContent='center'>
                <img
                  src={`${api.url}/${image4}?t=${Date.now()}`}
                  style={{ width: 100, height: 100, borderRadius: '25%' }}
                />
                {hasRole('SCHOOL_ADMIN') &&
                  <label htmlFor='HOME_FACILITIES_IMAGE_4'>
                    <input onChange={saveWithFile4} accept="image/*" id='HOME_FACILITIES_IMAGE_4' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer' }} />
                  </label>
                }
              </Grid>
              <Typography variant="h6" component="div" textAlign='center' sx={{ width: '100%' }}>
                {imageTitle4}
                {hasRole('SCHOOL_ADMIN') && <EditStringPopUp value={imageTitle4} save={saveImageTitle4} />}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>

  );
}

export default Facilities;
