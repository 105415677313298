import React,{useState, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { generalListOptionMapper } from 'utils/mappers';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme';


const SelectPositionForm =(props)=> {
  const {closeDialog, onSubmit} = props
  const classes = useStyles();
  const reset=()=>{    
    return{
      position:null
    }    
  }
  const [state, setstate] = useState(reset())
  const [positionOptions, setpositionOptions] = useState([]);

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    newState[fieldName] = value
    setstate(newState)
  }

  const getpositionOptions = async () => {
    const response = await axios.get(endpoint.position.option);
    if (response && response.data) {
      setpositionOptions(generalListOptionMapper(response.data));
    }
  };  
  
  const submit = async()=>{
    onSubmit(state.position)
    setstate(reset())
  }  
  
  useEffect(() => {
    getpositionOptions()
  }, [])

  return <>      
      <DialogContent sx={{height:300}}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >  
          <Grid container justifyContent='center' alignItems='flex-start' style={{margin:'16px 0'}}>                
          <Select
              name="Menu"
              placeholder = "Pilih Posisi..."
              options={positionOptions}
              value={state.position}
              onChange={(e)=>handleChange("position",e)}
              styles={defaultSelectStyle}
            />
          </Grid>
            
          </Grid>          
        </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
</>;
}

export default SelectPositionForm;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:'16px 0',
    minHeight:200
  },
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
