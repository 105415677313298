import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { Switch, TextField } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import { gettransactionModeLabel, gettransactionModeObject, transactionModeOptions, TRANSACTION_MODE_CASH } from 'utils/options';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import useAuth from 'hooks/useAuth';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'

const TransactionIncome = (props) => {
  const { definedIncome, getData } = props
  const { detail } = useAuth()
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      amount: undefined,
      description: undefined,
      transactionDate: new Date(),
      transaction_mode_value: TRANSACTION_MODE_CASH,
      admin_employee_id: undefined,
      coa_harta: null,
      coa_harta_id: undefined,
      coa_pendapatan: null,
      coa_pendapatan_id: undefined,
    }

  }
  const [state, setstate] = useState(reset())
  const [coa_hartaOptions, setcoa_hartaOptions] = useState([]);
  const [coa_pendapatanOptions, setcoa_pendapatanOptions] = useState([]);


  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'coa_harta') {
      newState['coa_harta_id'] = value.value
    }
    else if (fieldName === 'coa_pendapatan') {
      newState['coa_pendapatan_id'] = value.value
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getcoa_hartaOptions = async () => {
    const params = {
      group: 'HARTA',
      employee_id: detail.id
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_hartaOptions(generalListOptionMapper(response.data));
    }
  };

  const getcoa_pendapatanOptions = async () => {
    const params = {
      group: 'PENDAPATAN',
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoa_pendapatanOptions(generalListOptionMapper(response.data));
    }
  };

  const changeAmount = (event) => {
    setstate({
      ...state,
      amount: event.target.value
    })

  }


  const submit = async () => {
    if (detail) {
      const payload = {
        ...state,
        transactionDate: format(state.transactionDate, 'yyyy-MM-dd'),
        admin_employee_id: detail.id
      }

      const response = await axios.post(endpoint.transaction.income, payload)

      if (response && response.data) {
        showSuccess('save transaction')
        props.closeDialog()
        if (getData) {
          getData()
        }
        
      } else {
        showError('save transaction')
      }
    }

  }



  useEffect(() => {
    getcoa_hartaOptions()
    getcoa_pendapatanOptions()
  }, [])

  useEffect(() => {
    if (definedIncome) {
      setstate({
        ...state,
        coa_harta: generalOptionMapper(definedIncome.coa_harta),
        coa_harta_id: definedIncome.coa_harta_id,
        coa_pendapatan: generalOptionMapper(definedIncome.coa_pendapatan),
        coa_pendapatan_id: definedIncome.coa_pendapatan_id,
        amount: definedIncome.defaultAmount,
        description: `${definedIncome.name} : `
      })
    }

  }, [definedIncome])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal transaksi
            </Grid>
            <Grid item sm={8} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container alignItems='flex-start'>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    renderInput={(props) => <TextField {...props} />}
                    value={state.transactionDate}
                    onChange={(e) => handleChange('transactionDate', e)}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rekening Penampung
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='coa_harta'
                placeholder='Pilih Rek. Penampung'
                options={coa_hartaOptions}
                value={state.coa_harta}
                onChange={(e) => handleChange('coa_harta', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Rekening Pendapatan
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='coa_pendapatan'
                placeholder='Pilih Rek. Pendapatan'
                options={coa_pendapatanOptions}
                value={state.coa_pendapatan}
                onChange={(e) => handleChange('coa_pendapatan', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jumlah
            </Grid>
            <Grid item sm={8} xs={12}>

              <TextField
                fullWidth
                value={parseInt(state.amount)}
                onChange={changeAmount}
                name="amount"
                id="amount"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Keterangan
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin="dense"
                id="description"
                fullWidth
                value={state.description}
                onChange={(e) => handleChange("description", e)}
              />
            </Grid>
          </Grid>



        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Button variant="contained" onClick={submit} color="primary">
        Simpan
      </Button>
    </DialogActions>
  </>;
}

export default TransactionIncome;

