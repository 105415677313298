import React, { useState, useEffect } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Activation(props) { 
  const {  gotoPage } = useUI()
  let query = useQuery();
  const [selectedUser, setselectedUser] = useState(null)

  const getUser = async (username) => { 
    let params = {
      username: username
    }

    const response = await axios.get(endpoint.user.byUsername, { params: params })
    if (response) {
      setselectedUser(response.data)
    }
  }

  useEffect(() => {
    getUser(query.get('username')) 
  }, [query]);

  return (
    <>
      {
        selectedUser && selectedUser.isActive===1 && 
        <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400, mt: 4, px: 2 }}>
          <Grid item xs={12} container justifyContent='center'>
            <Typography color='primary' component='h6' variant='h6'>
              Selamat, akun anda sudah aktif !!!
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <img src={`${api.url}/assets/undraw_done_re_oak4.svg`} style={{ width: 200, height: 200 }} />
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Typography color='primary' component='h6' variant='h6'>
              anda sudah bisa login pakai email dan password yang telah didaftarkan
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent='center' sx={{mt:2}}>
            <Button variant='outlined' color='primary' fullWidth onClick={()=>gotoPage('/login')}>
              Login
            </Button>
          </Grid>
        </Grid> 
      }

      {
        !selectedUser || (selectedUser && selectedUser.isActive===0) && 
        <Grid container justifyContent='center' alignItems='flex-start' sx={{ minHeight: 400, mt: 4, px: 2 }}>
          <Grid item xs={12} container justifyContent='center'>
            <Typography color='primary' component='h6' variant='h6'>
              Aktivasi akun gagal
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <img src={`${api.url}/assets/undraw_warning_re_eoyh.svg`} style={{ width: 200, height: 200 }} />
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Typography color='primary' component='h6' variant='h6'>
              mohon gunakan link yang dikirimkan lewat email
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent='center' sx={{mt:2}}>
            <Button variant='outlined' color='primary' fullWidth onClick={()=>gotoPage('/login')}>
              Login
            </Button>
          </Grid>
          
        </Grid> 
      }
    </>
  );
}
