import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@mui/material/Typography';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormControlLabel, Switch } from '@mui/material';
import useUI from 'hooks/useUI';
import ActiveChip from 'components/ActiveChip';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper, studentListOptionMapper } from 'utils/mappers';
import AsyncSelect from 'react-select/async';
import { getuserTypeLabel, getuserTypeObject, userTypeOptions } from 'utils/options';
import ReadOnlyTextField from 'components/ReadOnlyTextField';


const SmartCardDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      number: undefined,
      user_id: undefined,
      student_id: undefined,
      employee_id: undefined,
      parent_id: undefined,
      visitor_id: undefined,
      user_type_value: undefined,
      isActive: undefined,
      card_status_value: undefined
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [nameUserKeyWord, setnameUserKeyWord] = useState('');
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [nameParentKeyWord, setnameParentKeyWord] = useState('');
  const [nameVisitorKeyWord, setnameVisitorKeyWord] = useState('');

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue
    if (fieldName === 'user') {
      newState['user_id'] = value ? value.value : value
    } else
      if (fieldName === 'employee') {
        newState['employee_id'] = value ? value.value : value
      }
      else if (fieldName === 'student') {
        newState['student_id'] = value ? value.value : value
      }
      else if (fieldName === 'parent') {
        newState['parent_id'] = value ? value.value : value
      }
      else if (fieldName === 'visitor') {
        newState['visitor_id'] = value ? value.value : value
      }
      else if (fieldName === 'user_type') {
        newState['user_type_value'] = value.value
      }
      else if (fieldName === 'isActive') {
        value = eventValue.target.checked ? 1 : 0;
      }
    newState[fieldName] = value
    setstate(newState)
  }

  const getUserOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.user.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getParentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.parent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getVisitorOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.visitor.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }



  const loadUserOptions = async (inputValue, callback) => {
    callback(await getUserOptions(inputValue))
  };

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const loadParentOptions = async (inputValue, callback) => {
    callback(await getParentOptions(inputValue))
  };

  const loadVisitorOptions = async (inputValue, callback) => {
    callback(await getVisitorOptions(inputValue))
  };

  const handleInputChangeUser = (newValue) => { 
    setnameUserKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeEmployee = (newValue) => { 
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeStudent = (newValue) => { 
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeParent = (newValue) => { 
    setnameParentKeyWord(newValue)
    return newValue;
  };

  const handleInputChangeVisitor = (newValue) => { 
    setnameParentKeyWord(newValue)
    return newValue;
  };


  const insert = async (payload) => {
    const response = await axios.post(endpoint.smartCard.root, payload)
    if (response && response.data) {
      showSuccess('save smart card')
      getData()
    } else {
      showError('save smart card')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.smartCard.root, payload)
    if (response && response.data) {
      showSuccess('save smart card')
      getData()
    } else {
      showError('save smart card')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    setstate({
      ...row,
      user: generalOptionMapper(row.user),
      employee: row.user_type_value === 1 ? generalOptionMapper(row.user.detail) : null,
      student: row.user_type_value === 2 ? generalOptionMapper(row.user.detail) : null,
      parent: row.user_type_value === 3 ? generalOptionMapper(row.user.detail) : null,
      visitor: row.user_type_value === 4 ? generalOptionMapper(row.user.detail) : null,
      user_type: getuserTypeObject(row.user_type_value)
    })
  }

  useEffect(() => {
    if (pageAction !== 'READ') {
    }
  }, [pageAction])

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])

  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.number} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='number'
                  fullWidth
                  value={state.number}
                  onChange={(e) => handleChange('number', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                username
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.user && state.user.username} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe user
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === "READ"}>
                <ReadOnlyTextField value={getuserTypeLabel(state.user_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Select
                  name="Type"
                  placeholder="Pilih type"
                  options={userTypeOptions}
                  value={state.user_type}
                  onChange={(e) => handleChange("user_type", e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.user_type_value === 1}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Employee
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.employee && state.employee.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih Employee..."
                    value={state.employee}
                    loadOptions={loadEmployeeOptions}
                    onInputChange={handleInputChangeEmployee}
                    onChange={(e) => handleChange('employee', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.user_type_value === 2}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Student
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.student && state.student.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih Student..."
                    value={state.student}
                    loadOptions={loadStudentOptions}
                    onInputChange={handleInputChangeStudent}
                    onChange={(e) => handleChange('student', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.user_type_value === 3}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Parent
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.parent && state.parent.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih Parent..."
                    value={state.parent}
                    loadOptions={loadParentOptions}
                    onInputChange={handleInputChangeParent}
                    onChange={(e) => handleChange('parent', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={state.user_type_value === 4}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Visitor
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  <ReadOnlyTextField value={state.visitor && state.visitor.name} />
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <AsyncSelect
                    isClearable={true}
                    cacheOptions
                    placeholder="Pilih Visitor..."
                    value={state.visitor}
                    loadOptions={loadVisitorOptions}
                    onInputChange={handleInputChangeVisitor}
                    onChange={(e) => handleChange('visitor', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Conditional condition={pageAction !== "CREATE"}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Status
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === "READ"}>
                  <ActiveChip status={state.isActive} margin="0 0 0 8px" />
                </Conditional>
                <Conditional condition={pageAction !== "READ"}>
                  <Switch
                    color='primary'
                    checked={state.isActive === 1}
                    onChange={(e) => handleChange("isActive", e)}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>


        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default SmartCardDetail;


