import React,{useState, useEffect, useRef} from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Conditional from 'components/Conditional';
import { Check } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import Select from 'react-select'
import { parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import {defaultSelectStyle} from 'utils/theme.js'


const SettingParameter =(props)=> {
  const{setting={}, saveSetting} = props
  const [isEditing, setisEditing] = useState(false)
  const [state, setstate] = useState({})
  const [parameterOptions, setparameterOptions] = useState([])
    
  const save=()=>{
    setisEditing(false)
    saveSetting(state)    
  }

  const cancel=()=>{
    setisEditing(false)
    setstate({
      ...setting,
      parameter:parameterOptionMapper(getParameter(setting))
    })
  }

  const handleChange=(e)=>{
    setstate({
      ...state,      
      parameter:e,
      parameter_value:e?e.value:null
    })
  }

  const getparameterOptions = async (group) => {
    const params={
      group:group
    }
    const response = await axios.get(endpoint.parameter.option,{params:params});
    if (response && response.data) {
      setparameterOptions(parameterListOptionMapper(response.data));
    }
  };

  const getParameter = (row)=>{
    if(row.parameter_group==='absen_input_mode'){
      return row.absen_input_mode
    }
  }
  
  useEffect(() => {
    if(setting){
      getparameterOptions(setting.parameter_group)
      setstate({
        ...setting,
        parameter:parameterOptionMapper(getParameter(setting))
      })
    }
    
  }, [setting])  

    return (
      <Paper sx={{
        p:1, 
        width:'90%', 
        }}>
          <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>
              {state.name}
          </Typography>
          <Conditional condition={isEditing===false}>
              <IconButton
              onClick={()=>setisEditing(true)}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
              <EditIcon fontSize='inherit' />
              </IconButton>
          </Conditional>
          
          <Conditional condition={isEditing===true}>
            <Grid item>
              <IconButton
              onClick={cancel}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
                  <Close fontSize='inherit' />
              </IconButton>
              <IconButton
              onClick={save}
              size='small'
              aria-label='edit'
              style={{margin:0}}>
                  <Check fontSize='inherit' />
              </IconButton>
            </Grid>
          </Conditional>
          </Grid> 
          <Conditional condition={isEditing===false}>
              <Grid container>
              {state.parameter && state.parameter.name}
              </Grid> 
          </Conditional>
          <Conditional condition={isEditing===true}>
            <Grid container alignItems='flex-start'>
              <Select
                name="parameter"
                placeholder = "Parameter Value"
                options={parameterOptions}
                value={state.parameter}
                onChange={handleChange}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Conditional>                
    </Paper>
    );
  }
  

export default SettingParameter;