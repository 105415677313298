import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import AttendanceForm from './AttendanceForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Chip, FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Collapse from '@mui/material/Collapse';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AttendanceStatistic from './AttendanceStatistic.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import { getattendanceTypeLabel, attendanceTypeOptions, getcomeLeaveStatusLabel, getscheduleTypeLabel, getuserTypeLabel, getattendanceTypeObject, userTypeOptions, scheduleTypeOptions } from 'utils/options.js';
import ReadOnlyTextField from 'components/ReadOnlyTextField.js';
import { addDays, differenceInDays, format, isAfter, isBefore, isEqual, lastDayOfMonth, startOfMonth, toDate } from 'date-fns'
import StatusChip from 'components/StatusChip.js';
import useAuth from 'hooks/useAuth.js';
import DatePicker, { DateObject } from "react-multi-date-picker"
import Conditional from 'components/Conditional.js';



const Attendance = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, gotoPage, showSuccess, showError, isAllDataAccess } = useUI()
  const { user, detail, students } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [openStatistic, setopenStatistic] = useState(false)
  const [selectedStatistic, setselectedStatistic] = useState('')
  const [rangeDates, setrangeDates] = useState([])
  const [dailyAttendances, setdailyAttendances] = useState([])
  const [hourlyAttendances, sethourlyAttendances] = useState([])
  

  const defaultFilters = {
    keyword: '',
    attendance_type_value: undefined,
    attendance_type: null,
    isActive: null,
    start_date: new DateObject,
    end_date: new DateObject,
    user_type: null,
    user_type_value: undefined,
    schedule_type: null,
    schedule_type_value: undefined,
  }
  const [filters, setFilters] = useState(defaultFilters)

  const [isActiveOptions, setisActiveOptions] = useState([{ label: 'aktif', value: 1 }, { label: 'nonaktif', value: 0 }])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'attendance_type') {
      filtersTemp['attendance_type'] = value
      filtersTemp['attendance_type_value'] = value ? value.value : undefined
      setFilters(filtersTemp)
    }
    else if (fieldName === 'user_type') {
      filtersTemp['user_type'] = value
      filtersTemp['user_type_value'] = value ? value.value : undefined
      setFilters(filtersTemp)
    }
    else if (fieldName === 'schedule_type') {
      filtersTemp['schedule_type'] = value
      filtersTemp['schedule_type_value'] = value ? value.value : undefined
      setFilters(filtersTemp)
    }
    else if (fieldName === 'start_date') {
      setValidDate(value, filters.end_date)
    }
    else if (fieldName === 'end_date') {
      setValidDate(filters.start_date, value)
    }
    else {
      filtersTemp[fieldName] = value
      setFilters(filtersTemp)
    }


  }

  const setValidDate = (start_date, end_date) => {
    let startDate = new Date(start_date.year, start_date.month.number - 1, start_date.day)
    let endDate = new Date(end_date.year, end_date.month.number - 1, end_date.day)
    let isInValidRangeDate = isBefore(endDate, startDate)

    if (isInValidRangeDate) {
      setFilters({
        ...filters,
        start_date: start_date,
        end_date: start_date
      })
    }
    else if (differenceInDays(endDate, startDate) > 31) {
      startDate = startOfMonth(startDate)
      endDate = lastDayOfMonth(startDate)

      setFilters({
        ...filters,
        start_date: new DateObject(startDate),
        end_date: new DateObject(endDate)
      })
    } else {
      setFilters({
        ...filters,
        start_date: start_date,
        end_date: end_date
      })
    }
  }



  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      user_type_value: filters.user_type_value,
      attendance_type_value: filters.attendance_type_value,
      schedule_type_value: filters.schedule_type_value,
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
      isActive: filters.isActive ? filters.isActive.value : undefined,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const printPdf = () => {
    getData()
  }

  const printExcel = () => {
    getData()
  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter,
    }

    if (filters.schedule_type_value === 1) {
      getDailyAttendances()
    }
    else if (filters.schedule_type_value === 2) {
      getHourlyAttendances()
    }
    else {
      if (!isAllDataAccess() && detail) {
        params = {
          ...params,
          employee_id: user.user_type_value === 1 ? detail.id : undefined,
          student_id: user.user_type_value === 2 ? detail.id : undefined,
          student_ids: user.user_type_value === 3 ? students.map(student => (student.id)) : undefined
        }
      }

      startLoading()
      const response = await axios.get(endpoint.attendance.root, { params: params })
      if (response && response.data && response.data.data) {
        const modifiedData = response.data.data.map(row => ({
          ...row
        }))
        setDataTable(modifiedData)
        setPage(response.data.current_page)
        setTotalRows(response.data.total)
      } else {
        showError('get attendance')
      }
      stopLoading()
    }


  }

  const getDailyAttendances = async () => {
    let params = {
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
    }

    if (!isAllDataAccess() && detail) {
      params = {
        ...params,
        employee_id: user.user_type_value === 1 ? detail.id : undefined
      }
    }

    startLoading()
    const response = await axios.get(endpoint.attendance.daily, { params: params })
    if (response && response.data) {
      setdailyAttendances(response.data)
    } else {
      showError('get attendance')
    }
    stopLoading()
  }

  const getHourlyAttendances = async () => {
    let params = {
      start_date: filters.start_date ? filters.start_date.format('YYYY-MM-DD') : undefined,
      end_date: filters.end_date ? filters.end_date.format('YYYY-MM-DD') : undefined,
    }

    if (!isAllDataAccess() && detail) {
      params = {
        ...params,
        employee_id: user.user_type_value === 1 ? detail.id : undefined
      }
    }

    startLoading()
    const response = await axios.get(endpoint.attendance.hourly, { params: params })
    if (response && response.data) {
      sethourlyAttendances(response.data)
    } else {
      showError('get attendance')
    }
    stopLoading()
  }



  const getDataByStatistic = async (statistic) => {
    setopenDialog(false)
    if (selectedStatistic === statistic.code) {
      reset()
    } else {
      setselectedStatistic(statistic.code)
      let params = {
        attendance_type_value: statistic.attendance_type_value,
        user_type_value: statistic.user_type_value,
        page: page,
        rowsPerPage: rowsPerPage
      }

      let attendance_typeIdx = attendanceTypeOptions.findIndex(type => type.value === statistic.attendance_type_value)
      let attendance_type = null

      if (attendance_typeIdx > -1) {
        attendance_type = attendanceTypeOptions[attendance_typeIdx]
      }

      let user_typeIdx = userTypeOptions.findIndex(type => type.value === statistic.user_type_value)
      let user_type = null

      if (user_typeIdx > -1) {
        user_type = userTypeOptions[user_typeIdx]
      }

      setFilters(
        {
          ...filters,
          attendance_type_value: statistic.attendance_type_value,
          attendance_type: attendance_type,
          user_type_value: statistic.user_type_value,
          user_type: user_type
        }
      )
      setPage(1)

      getDataByParams(params)

    }

  }

  const showStatistic = () => {
    if (smallScreen) {
      setdialogContent(
        <Box minHeight={300}>
          <AttendanceStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Box>

      )
      setopenDialog(true)
    } else {
      setopenStatistic(!openStatistic)
    }
  }

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDetail = (attendance) => {
    
    setMaxWidth('sm');
    setdialogContent(
      <AttendanceForm
        row={attendance}
        getData={getData}
        closeDialog={closeDialog}
      />
    );

    setopenDialog(true);
  };

  

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id:user.id
    };
    const response = await axios.delete(endpoint.attendance.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete attendance')
      getData();
    } else {
      showError('delete attendance')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const StatusCell = ({ status }) => {
    if (status === 0) {
      return <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#d6005f', width: 50 }} />
    }
    else if (status === 1) {
      return <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#0f6812', width: 50 }} />
    }
    else if (status === 2) {
      return <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#ffa246', width: 50 }} />
    }
    else return <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, width: 50 }} />
  }

  const MultiStatusCell = ({ status }) => {
    return (
      <td onClick={()=>showDetail(status)} style={{ cursor:'pointer',color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', width: 50 }}>
        <table>
          <tr>
            {
              status.present !== 0 &&
              <td style={{  textAlign: 'center',color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#0f6812', width: 50 }} >{status.present}</td>
            }
            {
              status.present === 0 &&
              <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, width: 50, height: 18 }} />
            }
          </tr>
          <tr>
            {
              status.permit !== 0 &&
              <td style={{ textAlign: 'center',color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#ffa246', width: 50 }} >{status.permit}</td>
            }
            {
              status.permit === 0 &&
              <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, width: 50, height: 18 }} />
            }
          </tr>
          <tr>
            {
              status.alpa !== 0 &&
              <td style={{ textAlign: 'center',color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#d6005f', width: 50 }}>{status.alpa}</td>
            }
            {
              status.alpa === 0 &&
              <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, width: 50, height: 18 }} />
            }
          </tr>
        </table>
      </td>


    )

  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const reset = () => {
    let params = {
      attendance_type_value: defaultFilters.attendance_type_value,
      start_date: defaultFilters.start_date,
      end_date: defaultFilters.end_date,
      keyword: defaultFilters.keyword,
      isActive: defaultFilters.isActive ? defaultFilters.isActive.value : undefined,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setselectedStatistic('')
    setFilters(defaultFilters)
    getDataByParams(params)
  }

  useEffect(() => {
    let startDate = new Date(filters.start_date.year, filters.start_date.month.number - 1, filters.start_date.day)
    let endDate = new Date(filters.end_date.year, filters.end_date.month.number - 1, filters.end_date.day)
    let isRangeDate = isBefore(startDate, endDate)
    let newRangeDates = []

    setrangeDates(newRangeDates)
    if (isRangeDate) {
      do {
        newRangeDates = [...newRangeDates, startDate]
        startDate = addDays(startDate, 1)
      } while (!isAfter(startDate, endDate));

    } else {
      newRangeDates = [...newRangeDates, startDate]
    }

    setrangeDates(newRangeDates)
    sethourlyAttendances([])
    setdailyAttendances([])
  }, [filters.start_date, filters.end_date])

  useEffect(() => {
    getData()
  }, [])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <IconButton
              onClick={showStatistic}
              aria-label='statistic'
              size="large">
              <InsertChartIcon color='primary' />
            </IconButton>
          </Grid>

          <Grid item>
            <SmallButton onClick={() => gotoPage('/dashboard/schedule')} variant='text' color='primary'>
              Jadwal
            </SmallButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Collapse in={openStatistic} timeout="auto" unmountOnExit sx={{ width: 'inherit' }}>
        <Grid container sx={{ minHeight: 64, mb: 1 }}>
          <AttendanceStatistic getDataByStatistic={getDataByStatistic} selectedStatistic={selectedStatistic} />
        </Grid>
      </Collapse>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }


          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Mulai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.start_date}
                onChange={(e) => filterChange('start_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3} container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Sampai tanggal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                value={filters.end_date}
                onChange={(e) => filterChange('end_date', e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
                containerStyle={{
                  width: "100%"
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='attendance_type'
              placeholder='tipe kehadiran'
              options={attendanceTypeOptions}
              onChange={(e) => filterChange('attendance_type', e)}
              styles={defaultSelectStyle}
              value={filters.attendance_type}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='user_type'
              placeholder='tipe pengguna'
              options={userTypeOptions}
              onChange={(e) => filterChange('user_type', e)}
              styles={defaultSelectStyle}
              value={filters.user_type}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="schedule_type"
              placeholder="Pilih tipe jadwal"
              options={scheduleTypeOptions}
              value={filters.schedule_type}
              onChange={(e) => filterChange("schedule_type", e)}
            />
          </Grid>

        </Grid>
        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={printPdf}>
            PDF
          </SmallButton>
          <SmallButton variant='text' color='secondary' onClick={printExcel}>
            Excel
          </SmallButton>
          <IconButton
            onClick={() => getData()}
            aria-label='delete'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container alignContent='flex-start' sx={{ minHeight: 400 }} >
        <Conditional condition={filters.schedule_type === null}>
          <TableContainer component={Paper} >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                      onChange={toggleSelectAllRow}
                      checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                    />
                  </TableCell>
                   
                  <TableCell >Nama</TableCell>
                  <TableCell >Tipe pengguna</TableCell>
                  <TableCell >Jadwal</TableCell>
                  <TableCell >Pelajaran</TableCell>
                  <TableCell >Tanggal</TableCell>
                  <TableCell >Jam Pelajaran</TableCell>
                  <TableCell >Kehadiran</TableCell>
                  <TableCell >Datang</TableCell>
                  <TableCell >Selesai</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTable.map((row) => (
                  <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                    <TableCell>
                      <Checkbox
                        checked={selectedIds.includes(row.id)}
                        color="primary"
                        inputProps={{ 'aria-label': 'select all' }}
                      />
                    </TableCell> 
                    <TableCell>
                      {
                        row.student && row.student.name ||
                        row.employee && row.employee.name
                      }
                    </TableCell>
                    <TableCell >
                      {getuserTypeLabel(row.user_type_value)}
                    </TableCell>
                    <TableCell >{getscheduleTypeLabel(row.schedule_type_value)}</TableCell>
                    <TableCell >{row.subject && row.subject.name}</TableCell>
                    <TableCell >{row.attendance_date}</TableCell>
                    <TableCell >{row.jam_ke}</TableCell>
                    <TableCell >
                      <StatusChip getStatus={getattendanceTypeObject} status={row.attendance_type_value} />
                    </TableCell>
                    <TableCell >{row.first_time && `${row.first_time.substring(0, 5)} `} {getcomeLeaveStatusLabel(row.first_status_value)}</TableCell>
                    <TableCell >{row.last_time && `${row.last_time.substring(0, 5)} `} {getcomeLeaveStatusLabel(row.last_status_value)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid container justifyContent="flex-end">
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
              />
            </Grid>
          </TableContainer>
        </Conditional>

        <Conditional condition={filters.schedule_type_value === 1}>
          {
            dailyAttendances.length > 0 &&
            <>
              <Grid container>
                <Chip
                  size="small"
                  label='Hadir'
                  sx={{
                    color: 'white',
                    margin: 0.25,
                    backgroundColor: '#0f6812'
                  }}
                />
                <Chip
                  size="small"
                  label='Izin'
                  sx={{
                    color: 'white',
                    margin: 0.25,
                    backgroundColor: '#ffa246'
                  }}
                />
                <Chip
                  size="small"
                  label='Alpa'
                  sx={{
                    color: 'white',
                    margin: 0.25,
                    backgroundColor: '#d6005f'
                  }}
                />
                <Chip
                  size="small"
                  label='Tidak ada jadwal'
                  variant='outlined'
                  sx={{ 
                    margin: 0.25, 
                  }}
                />
              </Grid>
              <TableContainer component={Paper}>
                <table style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                  <tr>
                    <th style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>Nama</th>
                    <th style={{ width: 150, border: '1px solid #dddddd', borderCollapse: 'collapse' }}>Ringkasan</th>
                    {
                      rangeDates.map(date => (
                        <th style={{ width: 110, border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 4, width: 50 }}>{format(date, 'dd MMM yyyy')}</th>
                      ))
                    }
                  </tr>
                  {
                    dailyAttendances.map(attendance => (
                      <tr>
                        <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8 }} >{attendance.name}</td>
                        <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8 }}>
                          <Grid container justifyContent='center' sx={{ width: 150 }}>
                            <Chip
                              size="small"
                              label={attendance.present}
                              sx={{
                                color: 'white',
                                margin: 0.25,
                                backgroundColor: '#0f6812'
                              }}
                            />
                            <Chip
                              size="small"
                              label={attendance.permit}
                              sx={{
                                color: 'white',
                                margin: 0.25,
                                backgroundColor: '#ffa246'
                              }}
                            />
                            <Chip
                              size="small"
                              label={attendance.alpa}
                              sx={{
                                color: 'white',
                                margin: 0.25,
                                backgroundColor: '#d6005f'
                              }}
                            />
                          </Grid>

                        </td>
                        {
                          attendance.attendances.map(status => (

                            <StatusCell status={status} />

                          ))
                        }
                      </tr>
                    ))
                  }

                </table>
              </TableContainer>
            </>

          }

        </Conditional>

        <Conditional condition={filters.schedule_type_value === 2}>
          {
            hourlyAttendances.length > 0 &&
            <>
              <Grid container>
                <Chip
                  size="small"
                  label='Hadir'
                  sx={{
                    color: 'white',
                    margin: 0.25,
                    backgroundColor: '#0f6812'
                  }}
                />
                <Chip
                  size="small"
                  label='Izin'
                  sx={{
                    color: 'white',
                    margin: 0.25,
                    backgroundColor: '#ffa246'
                  }}
                />
                <Chip
                  size="small"
                  label='Alpa'
                  sx={{
                    color: 'white',
                    margin: 0.25,
                    backgroundColor: '#d6005f'
                  }}
                />
                <Chip
                  size="small"
                  label='Tidak ada jadwal'
                  variant='outlined'
                  sx={{ 
                    margin: 0.25, 
                  }}
                />
              </Grid>
              <TableContainer component={Paper}>
                <table style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>
                  <tr>
                    <th style={{ border: '1px solid #dddddd', borderCollapse: 'collapse' }}>Nama</th>
                    <th style={{ width: 150, border: '1px solid #dddddd', borderCollapse: 'collapse' }}>Ringkasan</th>
                    {
                      rangeDates.map(date => (
                        <th style={{ width: 110, border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 4, width: 50 }}>{format(date, 'dd MMM yyyy')}</th>
                      ))
                    }
                  </tr>
                  {
                    hourlyAttendances.map(attendance => (
                      <tr>
                        <td style={{ border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8 }} >{attendance.name}</td>
                        <td style={{ textAlign: 'center', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8 }}>
                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{ color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#0f6812', width: 50 }} >{attendance.present}</td>
                            </tr>
                            <tr>
                              <td style={{ color: 'white',border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#ffa246', width: 50 }} >{attendance.permit}</td>
                            </tr>
                            <tr>
                              <td style={{ color: 'white', border: '1px solid #dddddd', borderCollapse: 'collapse', padding: 8, backgroundColor: '#d6005f', width: 50 }}>{attendance.alpa}</td>
                            </tr>
                          </table>
                        </td>
                        {
                          attendance.attendances.map(status => (

                            <MultiStatusCell status={status} />

                          ))
                        }
                      </tr>
                    ))
                  }

                </table>
              </TableContainer>
            </>

          }

        </Conditional>



      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default Attendance;

