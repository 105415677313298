import React, { useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Alert
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import Conditional from 'components/Conditional';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ziadsistem.com/">
        ziadsistem.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>

  );
}

export default function Login(props) {
  const { history } = props
  const { login, user, isAuthenticated } = useAuth()
  const { startLoading, stopLoading, gotoPage } = useUI()
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState(null);
  const [logoPath, setlogoPath] = useState('https://pesantren.ziadsistem.com/page2/images/pesantren.jpg');

  const submit = async () => {
    startLoading()
    const response = await login({ username: username, password: password })
    if (!response) {
      seterror('periksa username dan password atau klik link aktivasi di email anda jika anda mendaftar menggunakan email')
    }
    stopLoading()
  }

  const getlogoPath = async () => { 
    const response = await axios.get(endpoint.image.code + '/LOGO');
    if (response && response.data) {
      setlogoPath(api.url+'/'+response.data.path)
    }
  };

  const usernameChange = e => {
    setUserName(e.target.value);
  };

  const passwordChange = e => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard')
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getlogoPath()
  }, [])

  return (
    <Grid container justifyContent='space-between' alignContent='flex-start' sx={{
      p: 4,
      height: '100vh',

    }}>

      <Grid item xs={12} md={6} container justifyContent='center' alignContent='center'>
        <Grid item xs={6} md={12} container justifyContent='center' alignContent='center'>
          <img src={logoPath} style={{ width: '50%', height:'auto' }} />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} container justifyContent='center' alignContent='center'
        sx={{
          p: 4,
          mb: 10
        }}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <form
            sx={{
              width: '100%',
            }}
            noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username / Email / WA"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={usernameChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={passwordChange}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
              }}
              onClick={submit}
            >
              Log In
            </Button>
            <Grid container sx={{ my: 2 }}>
              <Grid item xs={6} container>
                <Button onClick={()=>gotoPage('/')} sx={{textTransform: 'none'}} >
                  Kembali ke beranda
                </Button>
              </Grid>
              <Grid item xs={6} container justifyContent='flex-end' >
              <Button onClick={()=>gotoPage('/reset')} sx={{textTransform: 'none'}} >
                  Reset password
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid container sx={{ my: 2 }}>
            <Conditional condition={error !== null}>
              <Grid item xs={12}>
                <Alert severity="error" onClose={() => seterror(null)} >{error}</Alert>
              </Grid>
            </Conditional>
          </Grid>
        </Box>
      </Grid>

      <Grid item container justifyContent='center'>
        <Copyright />
      </Grid>

    </Grid>

  );
}
