import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import useUI from 'hooks/useUI.js';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import useAuth from 'hooks/useAuth';
import { Button, Chip } from '@mui/material';
import { getuserTypeLabel } from 'utils/options.js';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import DebouncedTextField from 'components/DebouncedTextField';
import EditIcon from '@mui/icons-material/Edit';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

const Profile = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  const { user, detail } = useAuth()
  const defaultFilters = {
    keyword: ''
  }
  const [filters, setFilters] = useState(defaultFilters)
  const timestamp = Date.now();
  const [newPassword, setnewPassword] = useState('')
  const [isChangePassword, setisChangePassword] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [userDetail, setuserDetail] = useState(null);

  const getEmployeeDetail = async () => {
    
      let params = {}
      const response = await axios.get(endpoint.employee.detail.replace('id', detail.id), params)
      if (response) {
        setuserDetail(response.data)
      }
    
  }

  const getStudentDetail = async () => {
    let params = {}
    const response = await axios.get(endpoint.student.detail.replace('id', detail.id), params)
    if (response) {
      setuserDetail(response.data)
    }
  }

  const getParentDetail = async () => {
    let params = {}
    const response = await axios.get(endpoint.parent.detail.replace('id', detail.id), params)
    if (response) {
      setuserDetail(response.data)
    }
  }


  const submitNewPassword = async () => {
    let payload = {
      user_id: user.id,
      newPassword: newPassword
    }

    const response = await axios.post(endpoint.user.changePassword, payload)

    if (response && response.data) {
      showSuccess('Ubah password')
      setisChangePassword(false)
    } else {
      showError('Ubah password')
    }
  }

  const photo = async (event) => {
    let file = event.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('id', detail.id)
      let url = ''
      if (user.user_type_value === 1) {
        url = endpoint.employee.photo
      }
      else if (user.user_type_value === 2) {
        url = endpoint.student.photo
      }
      else if (user.user_type_value === 3) {
        url = endpoint.parent.photo
      }

      const response = await axios.post(url, formData, {});
      if (response) {
        if (user.user_type_value === 1) {
          getEmployeeDetail()
        }
        else if (user.user_type_value === 2) {
          getStudentDetail()
        }
        else if (user.user_type_value === 3) {
          getParentDetail()
        }
      }
    }

  };


  const closeDialog = () => {
    setopenDialog(false)
  }

  const passwordChange = (e) => {
    setnewPassword(e)
  }

  const cancelChangePassword = (e) => {
    setisChangePassword(false)
    setnewPassword(undefined)
  }




  useEffect(() => {
    if (user && detail) {
      if (user.user_type_value === 1) {
        getEmployeeDetail()
      }
      else if (user.user_type_value === 2) {
        getStudentDetail()
      }
      else if (user.user_type_value === 3) {
        getParentDetail()
      }
      else if (user.user_type_value === 4) {

      }
      else if (user.user_type_value === 5) {

      }
      else if (user.user_type_value === 6) {

      }
    }
  }, [user, detail])



  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
 
        </Grid>
      </Grid>

      <Grid container alignItems='flex-start' sx={{ minHeight: 400 }} >
        <Grid item xs={12} container justifyContent='center' sx={{ p: 1 }}>
          <Paper elevation={16} sx={{ p: 2, borderRadius: 4 }}>
            <Grid container >
              <Grid item xs={12} container justifyContent='center' >
                <Grid item sx={{ width: 200, position: 'relative' }}>
                  {
                    userDetail &&
                    <img
                      src={`${api.url}/${userDetail.photo}?t=${timestamp}`}
                      style={{ width: 200, height: 200, borderRadius: 16 }} />
                  }
                  <label htmlFor='propic'>
                    <input onChange={photo} accept="image/*" id='propic' type="file" style={{ display: 'none' }} />
                    <EditIcon color='secondary' fontSize='inherit' sx={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                  </label>
                </Grid>
              </Grid>

              <Grid item xs={6} container sx={{ mt: 1 }}>
                <Typography variant="h6" component="h6" align="center">
                  {detail && detail.name}
                </Typography>
              </Grid>
              <Grid item xs={6} container justifyContent='flex-end'>
                <Chip
                  size="medium"
                  label={getuserTypeLabel(user.user_type_value)}
                  color='secondary'
                />
              </Grid>

              <Grid item container sx={{ mt: 1, borderBottom:'solid 1px #c4c4c4' }}>
                <Grid item xs={6} sm={2}>
                  Username 
                </Grid>
                <Grid item xs={6} sm={10} container justifyContent='flex-end'>
                  {
                    user && user.username
                  }
                </Grid>
              </Grid>

              {
                user.user_type_value === 1 &&
                <>
                  <Grid item container sx={{ mt: 1, borderBottom:'solid 1px #c4c4c4' }}>
                    <Grid item xs={6} sm={2}>
                      Posisi 
                    </Grid>
                    <Grid item xs={6} sm={10} container justifyContent='flex-end'>
                      {
                        userDetail && userDetail.positions.map(position => (
                          <Grid container>
                            {position.name}
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>

                  <Grid item container sx={{ mt: 1, borderBottom:'solid 1px #c4c4c4' }}>
                    <Grid item xs={6} sm={2}>
                      Smart Reader 
                    </Grid>
                    <Grid item xs={6} sm={10} container justifyContent='flex-end'>
                      {userDetail && userDetail.smart_reader && `(${userDetail.smart_reader.number}) ${userDetail.smart_reader.name}`}
                    </Grid>
                  </Grid>
                </>
              }

              {
                user.user_type_value === 2 &&
                <>
                  <Grid item container sx={{ mt: 1, borderBottom:'solid 1px #c4c4c4' }}>
                    <Grid item xs={4} sm={2}>
                      Sekolah 
                    </Grid>
                    <Grid item xs={8} sm={10} container justifyContent='flex-end'>
                      {userDetail && userDetail.school && userDetail.school.name}
                    </Grid>
                  </Grid>
                  <Grid item container sx={{ mt: 1, borderBottom:'solid 1px #c4c4c4' }}>
                    <Grid item xs={4} sm={2}>
                      Kelas 
                    </Grid>
                    <Grid item xs={8} sm={10} container justifyContent='flex-end'>
                      {userDetail && userDetail.kelas && userDetail.kelas.name}
                    </Grid>
                  </Grid>
                  <Grid item container sx={{ mt: 1, borderBottom:'solid 1px #c4c4c4' }}>
                    <Grid item container>
                      <Grid item xs={4} sm={2}>
                        Paralel 
                      </Grid>
                      <Grid item xs={8} sm={10} container justifyContent='flex-end'>
                        {userDetail && userDetail.parallel && userDetail.parallel.name}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }

              {
                isChangePassword &&
                <>
                  <Grid item container sx={{ mt: 1 }}>
                    <Grid item container>
                      <Grid item xs={4} sm={2} container alignContent='center'>
                        Password baru :
                      </Grid>
                      <Grid item xs={8} sm={10}>
                        <DebouncedTextField
                          margin='dense'
                          id='newPassword'
                          fullWidth
                          value={newPassword}
                          type='password'
                          onChange={passwordChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-end' sx={{ mt: 2 }}>
                    <Grid item >
                      <Button onClick={cancelChangePassword}>Batal</Button>
                    </Grid>
                    <Grid item >
                      <Button variant='contained' color='success' onClick={submitNewPassword}>Simpan</Button>
                    </Grid>
                  </Grid>
                </>
              }

              {
                !isChangePassword &&
                <Grid item container justifyContent='center' sx={{ mt: 2 }}>
                  <Button variant='contained' color='warning' onClick={() => setisChangePassword(true)}>Ubah password</Button>
                </Grid>
              }




            </Grid>
          </Paper>
        </Grid>


      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

  </>;
}


export default Profile;

