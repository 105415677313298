import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import useUI from 'hooks/useUI.js';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import useAuth from 'hooks/useAuth';
import { Button, Chip } from '@mui/material';
import { getuserTypeLabel } from 'utils/options.js';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import DebouncedTextField from 'components/DebouncedTextField';
import EditIcon from '@mui/icons-material/Edit';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SelectEmployeeForm from 'views/employee/SelectEmployeeForm';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';


const EmployeeMutation = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const isInitialMount = useRef(true);
  const { user, detail } = useAuth()
  const defaultFilters = {
    keyword: ''
  }
  const [filters, setFilters] = useState(defaultFilters)
  const timestamp = Date.now();
  const [newPassword, setnewPassword] = useState('')
  const [isChangePassword, setisChangePassword] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedSubject, setselectedSubject] = useState(null);
  const [userDetail, setuserDetail] = useState(null);
  const [jenjangOptions, setjenjangOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [jurusanOptions, setjurusanOptions] = useState([]);
  const [jenjang, setjenjang] = useState(null);
  const [jurusan, setjurusan] = useState(null);
  const [kelas, setkelas] = useState(null);
  const [subjects, setsubjects] = useState([]);


  const getEmployeeDetail = async () => {
    let params = {}
    const response = await axios.get(endpoint.employee.detail.replace('id', detail.id), params)
    if (response) {
      setuserDetail(response.data)
    }
  }

 

  const getjenjangOptions = async () => {
    const response = await axios.get(endpoint.jenjang.option);
    if (response && response.data) {
      setjenjangOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };

  const getjurusanOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.jurusan.option, { params: params });
    if (response && response.data) {
      setjurusanOptions(generalListOptionMapper(response.data));
    }
  };


  const submitAddTeacher = async (employee) => {
    let payload = {
      subject_id: selectedSubject.id,
      employee_id: employee.id
    }

    const response = await axios.post(endpoint.subject.teacher, payload)

    if (response && response.data) {
      showSuccess('Menambah guru')
      closeAddTeacherDialog()
      getsubjects()
    } else {
      showError('Menambah guru')
    }
  }

  const removeTeacher = async (subject, employee) => {
    let payload = {
      subject_id: subject.id,
      employee_id: employee.id
    }

    const response = await axios.delete(endpoint.subject.teacher, { data: payload })

    if (response && response.data) {
      showSuccess('Menghapus guru')
      closeAddTeacherDialog()
      getsubjects()
    } else {
      showError('Menghapus guru')
    }
  }

  const photo = async (event) => {
    let file = event.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('id', detail.id)
      let url = ''
      if (user.user_type_value === 1) {
        url = endpoint.employee.photo
      }
      else if (user.user_type_value === 2) {
        url = endpoint.student.photo
      }

      const response = await axios.post(url, formData, {});
      if (response) {
        if (user.user_type_value === 1) {
          getEmployeeDetail()
        }
        else if (user.user_type_value === 2) {
          getEmployeeDetail()
        }
      }
    }

  };



  const jenjangChange = (e) => {
    setjenjang(e)
    getkelasOptions(e.id)
    getjurusanOptions(e.id)
  }

  const getsubjects = async (jenjang_id, kelas_id, jurusan_id) => {
    const params = {
      jenjang_id: jenjang_id,
      kelas_id: kelas_id,
      jurusan_id: jurusan_id
    }
    const response = await axios.get(endpoint.subject.teacher, { params: params });
    if (response && response.data) {
      setsubjects(generalListOptionMapper(response.data));
    }
  };

  const kelasChange = (e) => {
    setkelas(e)
  }

  const jurusanChange = (e) => {
    setjurusan(e)
  }

  const closeAddTeacherDialog = (e) => {
    setopenDialog(false)
    setselectedSubject(null)
  }

  const openAddTeacherDialog = (selectedSubject) => {
    setopenDialog(true)
    setselectedSubject(selectedSubject)
  }


  useEffect(() => {
    getjenjangOptions()
  }, [])

  useEffect(() => {
    let jenjang_id = jenjang ? jenjang.id : undefined
    let kelas_id = kelas ? kelas.id : undefined
    let jurusan_id = jurusan ? jurusan.id : undefined
    if (jenjang_id) {
      getsubjects(jenjang_id, kelas_id, jurusan_id)
    }
  }, [jenjang, kelas, jurusan])

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb/>
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>



        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' sx={{ minHeight: 400 }} >
        <Grid item md={3} xs={6}>
          <Select
            name="jenjang"
            placeholder="Pilih Jenjang"
            options={jenjangOptions}
            value={jenjang}
            onChange={jenjangChange}
            styles={defaultSelectStyle}
          />
        </Grid>

        <Grid item md={3} xs={6}>
          <Select
            name="kelas"
            placeholder="Pilih Kelas"
            options={kelasOptions}
            value={kelas}
            onChange={kelasChange}
            styles={defaultSelectStyle}
          />
        </Grid> 

        <Grid item md={3} xs={6}>
          <Select
            name="jurusan"
            placeholder="Pilih jurusan"
            options={jurusanOptions}
            value={jurusan}
            onChange={jurusanChange}
            styles={defaultSelectStyle}
          />
        </Grid>
 

        <Grid container sx={{ mt: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Mata Pelajaran</TableCell>
                  <TableCell>Pengajar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  subjects.map(subject => (
                    <TableRow
                      key={subject.id} c
                    >
                      <TableCell>{subject.name}</TableCell>
                      <TableCell>
                        {
                          subject.teachers.map(teacher => (
                            <Chip label={teacher.name} variant="outlined" onDelete={(e) => removeTeacher(subject, teacher)} />
                          ))
                        }
                        <SmallButton onClick={(e) => openAddTeacherDialog(subject)} variant='contained'>Tambah</SmallButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>


        </Grid>

      </Grid>

    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeAddTeacherDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <SelectEmployeeForm onSubmit={submitAddTeacher} />
    </Dialog>

  </>;
}


export default EmployeeMutation;

