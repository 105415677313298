import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { Checkbox, Chip } from '@mui/material';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { generalListOptionMapper } from 'utils/mappers';


const LinkDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()
  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      code: undefined,
      group: undefined,
      url: undefined,
      isPublic: 1,
      isNewTab: 1,
      roles: []
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [roleOptions, setroleOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isPublic", "isNewTab"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getroleOptions = async () => {
    const response = await axios.get(endpoint.role.option);
    if (response && response.data) {
      setroleOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.link.root, payload)
    if (response && response.data) {
      showSuccess('save link')
      getData()
    } else {
      showError('save link')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.link.root, payload)
    if (response && response.data) {
      showSuccess('save link')
      getData()
    } else {
      showError('save link')
    }
  }

  const submit = async () => {
    const params = {
      ...state,
      role_ids:state.roles.length>0 ? state.roles.map(role=>(role.id)):undefined
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      roles : generalListOptionMapper(row.roles)
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getroleOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Group
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.group} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='group'
                  fullWidth
                  value={state.group}
                  onChange={(e) => handleChange('group', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Url
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.url} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='url'
                  fullWidth
                  value={state.url}
                  onChange={(e) => handleChange('url', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Untuk semua
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isPublic === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isPublic === 1}
                  onChange={(e) => handleChange("isPublic", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Conditional condition={state.isPublic === 0}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Akses
              </Grid>
              <Grid item sm={8} xs={12}>
                <Conditional condition={pageAction === 'READ'}>
                  {
                    state.roles.map(role=>(<Chip 
                      size="small" 
                      label={role.name}  
                      sx={{ 
                        margin:1
                      }}
                      />))
                  }
                </Conditional>
                <Conditional condition={pageAction !== 'READ'}>
                  <Select
                    isClearable={true} 
                    isMulti
                    name='role'
                    placeholder='Pilih akses'
                    options={roleOptions}
                    value={state.roles}
                    onChange={(e) => handleChange('roles', e)}
                    styles={defaultSelectStyle}
                  />
                </Conditional>
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={10} container justifyContent='flex-start' alignContent='center'>
              Buka Tab baru
            </Grid>
            <Grid item sm={8} xs={2}>
              <Conditional condition={pageAction === "READ"}>
                <Checkbox checked={state.isNewTab === 1} />
              </Conditional>
              <Conditional condition={pageAction !== "READ"}>
                <Checkbox
                  checked={state.isNewTab === 1}
                  onChange={(e) => handleChange("isNewTab", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default LinkDetail;

