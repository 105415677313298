import { Grid, Box, Paper, Typography } from '@mui/material';
import { axiosInstance as axios, endpoint } from 'utils/axios'; 
import useUI from 'hooks/useUI';
import React, { useEffect, useState } from 'react' 

const StudentStatistic = (props) => {
 
  const { gotoPage, showError } = useUI()

  const [studentStatistics, setstudentStatistics] = useState([
    { name: 'Total', code: 'TOTAL', value: 0, isActive: null, parent_id: null },
    { name: 'Active', code: 'ACTIVE', value: 0, isActive: 1, parent_id: null },
    { name: 'Not Active', code: 'INACTIVE', value: 0, isActive: 0, parent_id: null }
])


  const getstudentStatistic = async () => {
    let params = {}
    const response = await axios.get(endpoint.student.statistic, { params: params })

    if (response && response.data) {
        let newStatistics = studentStatistics.map(statistic => {
            let idx = response.data.findIndex(data => data.code === statistic.code)
            if (idx > -1) {
                return { ...statistic, value: response.data[idx].value }
            }
            return statistic
        })
        setstudentStatistics(newStatistics)
    } else {
        showError('get student')
    }
}

  const gotoStudentPage = () => {
    let route = '/dashboard/student'
    gotoPage(route)
}

  useEffect(() => {
    getstudentStatistic() 
}, [])

  return (
    <Grid item xs={12}  container justifyContent='center'
                    sx={{
                        px: 2,
                    }}
                >
                    <Grid item container justifyContent='flex-start' spacing={1}
                        sx={{
                            color: '#393a3c',
                            pt: 1,
                            pb: 2,
                            px: 2,
                            mb: 2,
                            borderRadius: 4,
                            background: 'white'
                        }}
                    >
                        <Grid item xs={12} container justifyContent='flex-start'>
                            <Typography variant="subtitle2" component="div">
                                Student
                            </Typography>
                        </Grid>
                        {
                            studentStatistics.map(statistic => (
                                <Grid item onClick={gotoStudentPage} sx={{ cursor: 'pointer' }}>
                                    <Paper
                                        sx={{
                                            minWidth: 100,
                                            height: 48,
                                            p: 1,
                                            backgroundColor:'#1e65e9',
                                            color:'white'
                                        }}
                                        elevation={4}>
                                        <Typography variant="body2" gutterBottom component="div">
                                            {statistic.name}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom component="div">
                                            {statistic.value}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))
                        }

                    </Grid>

                </Grid>
  );
    
}

export default StudentStatistic;
