import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';
import { Button } from '@mui/material';

const TestPage = (props) => {


    const testExportPdf = () => {
        let params = {}
        axios.get(endpoint.test.exportPdf, { params: params, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.pdf");
                document.body.appendChild(link);
                link.click();
            })
    }

    const testExportExcel = () => {
        let params = {}
        axios.get(endpoint.test.exportExcel, { params: params, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.xls");
                document.body.appendChild(link);
                link.click();
            })
    }

    const testImportExcel = async (event) => {
        let file = event.target.files[0]
        if (file) {
            const formData = new FormData();
            formData.append('file', file) 
            const response = await axios.post(endpoint.test.importExcel, formData, {});
            if (response) {

            }
        } 
    };

    useEffect(() => {
    }, [])

    return <>
        <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
            sx={{
                p: 2,
                bgcolor: 'white',
                borderRadius: 4
            }}
        >
            <Grid container alignItems='center' alignContent='center'
                sx={{ my: 1 }}
            >
                <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
                    sx={{ pl: 1 }}
                >
                    <MenuBreadCrumb />
                </Grid>
                <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

                </Grid>
            </Grid>


            <Grid container sx={{ minHeight: 400 }} >
                <Grid item xs={4} container justifyContent='center' alignItems='flex-start'>
                    <Button variant='contained' onClick={testExportPdf}>Test PDF Export</Button>
                </Grid>
                <Grid item xs={4} container justifyContent='center' alignItems='flex-start'>
                    <Button variant='contained' onClick={testExportExcel}>Test excel Export</Button>
                </Grid>
                <Grid item xs={4} container justifyContent='center' alignItems='flex-start'>
                    <label htmlFor='excel'>
                        <input onChange={testImportExcel} id='excel' type="file" style={{ display: 'none' }} />
                        <Button variant="contained" component="span">
                            Test excel Import
                        </Button>
                    </label>
                </Grid>
            </Grid>
        </Grid>


    </>;
}


export default TestPage;

