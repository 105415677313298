import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import { educationTypeOptions, geteducationTypeLabel, geteducationTypeObject, getjobTypeObject, getlifeStatusLabel, getlifeStatusObject, getreligionObject, getsalaryTypeLabel, getsalaryTypeObject, getsexTypeObject, getstayWithObject, getwaliTypeObject, jobTypeOptions, lifeStatusOptions, religionOptions, salaryTypeOptions, sexTypeOptions, stayWithOptions, waliTypeOptions } from 'utils/options';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, parse } from 'date-fns'
import { TextField } from '@mui/material';


const NewParentDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError } = useUI()

  const reset = () => {
    return {
      id: undefined,
      name: undefined,
      user: null,
      user_id: undefined,
      FatherAlive: { value: 1, label: 'Hidup' },
      isFatherAlive: 1,
      MotherAlive: { value: 1, label: 'Hidup' },
      isMotherAlive: 1,
      fatherName: undefined,
      fatherKtp: undefined,
      fatherPhone: undefined,
      fatherEmail: undefined,
      father_education_type_value: undefined,
      father_job_type_value: undefined,
      father_salary_type_value: undefined,
      fatherJobCorporateName: undefined,
      fatherJobPositionName: undefined,
      fatherJobWorkAddress: undefined,
      fatherBirthDate: new Date(),
      fatherBirthPlace: undefined,
      motherName: undefined,
      motherKtp: undefined,
      motherPhone: undefined,
      motherEmail: undefined,
      mother_education_type_value: undefined,
      mother_job_type_value: undefined,
      mother_salary_type_value: undefined,
      motherJobCorporateName: undefined,
      motherJobPositionName: undefined,
      motherJobWorkAddress: undefined,
      motherBirthDate: new Date(),
      motherBirthPlace: undefined,
      isHasWali: undefined,
      waliName: undefined,
      wali_type_value: undefined,
      waliKtp: undefined,
      waliPhone: undefined,
      waliEmail: undefined,
      wali_education_type_value: undefined,
      wali_job_type_value: undefined,
      wali_salary_type_value: undefined,
      waliJobCorporateName: undefined,
      waliJobPositionName: undefined,
      waliJobWorkAddress: undefined,
      wali_sex_type_value: undefined,
      waliBirthDate: new Date(),
      waliBirthPlace: undefined,
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (["isActive"].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const insert = async (payload) => {
    const response = await axios.post(endpoint.newParent.root, payload)
    if (response && response.data) {
      showSuccess('save newParent')
      getData()
    } else {
      showError('save newParent')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.newParent.root, payload)
    if (response && response.data) {
      showSuccess('save newParent')
      getData()
    } else {
      showError('save newParent')
    }
  }

  const submit = async () => {
    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      wali_type: getwaliTypeObject(row.wali_type_value),
      father_job_type: getjobTypeObject(row.father_job_type_value),
      father_education_type: geteducationTypeObject(row.father_education_type_value),
      father_education_type_value: row.father_education_type_value,
      father_salary_type: getsalaryTypeObject(row.father_salary_type_value),
      father_salary_type_value: row.father_salary_type_value,
      fatherBirthDate: row.fatherBirthDate ? parse(row.fatherBirthDate, 'yyyy-MM-dd', new Date()) : null,
      mother_job_type: getjobTypeObject(row.mother_job_type_value),
      mother_education_type: geteducationTypeObject(row.mother_education_type_value),
      mother_education_type_value: row.mother_education_type_value,
      mother_salary_type: getsalaryTypeObject(row.mother_salary_type_value),
      mother_salary_type_value: row.mother_salary_type_value,
      motherBirthDate: row.motherBirthDate ? parse(row.motherBirthDate, 'yyyy-MM-dd', new Date()) : null,
      wali_job_type: getjobTypeObject(row.wali_job_type_value),
      wali_type: getwaliTypeObject(row.wali_type_value),
      wali_sex_type: getsexTypeObject(row.wali_sex_type_value),
      wali_education_type: geteducationTypeObject(row.wali_education_type_value),
      wali_education_type_value: row.wali_education_type_value,
      wali_salary_type: getsalaryTypeObject(row.wali_salary_type_value),
      wali_salary_type_value: row.wali_salary_type_value,
      waliBirthDate: row.waliBirthDate ? parse(row.waliBirthDate, 'yyyy-MM-dd', new Date()) : null,
      FatherAlive: getlifeStatusObject(row.isFatherAlive),
      MotherAlive: getlifeStatusObject(row.isMotherAlive),
      
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isFatherAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='FatherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.FatherAlive}
                  onChange={(e) => handleChange('FatherAlive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nama ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherName'
                  fullWidth
                  value={state.fatherName}
                  onChange={(e) => handleChange('fatherName', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Telepon ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherPhone'
                  fullWidth
                  value={state.fatherPhone}
                  onChange={(e) => handleChange('fatherPhone', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Ktp ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherKtp'
                  fullWidth
                  value={state.fatherKtp}
                  onChange={(e) => handleChange('fatherKtp', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                e-mail ayah
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='fatherEmail'
                  fullWidth
                  value={state.fatherEmail}
                  onChange={(e) => handleChange('fatherEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherBirthDate && format(state.fatherBirthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.fatherBirthDate}
                      onChange={(e) => handleChange('fatherBirthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota kelahiran ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherBirthPlace} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherBirthPlace"
                  fullWidth
                  value={state.fatherBirthPlace}
                  onChange={(e) => handleChange("fatherBirthPlace", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir ayah
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.father_education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='father_education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.father_education_type}
                  onChange={(e) => handleChange('father_education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='father_job_type'
                placeholder='Pilih jenis pekerjaan'
                options={jobTypeOptions}
                value={state.father_job_type}
                onChange={(e) => handleChange('father_job_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherJobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherJobCorporateName"
                  fullWidth
                  value={state.fatherJobCorporateName}
                  onChange={(e) => handleChange("fatherJobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherJobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherJobPositionName"
                  fullWidth
                  value={state.fatherJobPositionName}
                  onChange={(e) => handleChange("fatherJobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan ayah
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.fatherJobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="fatherJobWorkAddress"
                  fullWidth
                  value={state.fatherJobWorkAddress}
                  onChange={(e) => handleChange("fatherJobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan ayah
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.father_salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='father_salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.father_salary_type}
                  onChange={(e) => handleChange('father_salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Status ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getlifeStatusLabel(state.isMotherAlive)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='MotherAlive'
                  placeholder='Pilih status'
                  options={lifeStatusOptions}
                  value={state.MotherAlive}
                  onChange={(e) => handleChange('MotherAlive', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Nama ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='motherName'
                  fullWidth
                  value={state.motherName}
                  onChange={(e) => handleChange('motherName', e)}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Telepon ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='motherPhone'
                  fullWidth
                  value={state.motherPhone}
                  onChange={(e) => handleChange('motherPhone', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Ktp ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='motherKtp'
                  fullWidth
                  value={state.motherKtp}
                  onChange={(e) => handleChange('motherKtp', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                e-mail ibu
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='motherEmail'
                  fullWidth
                  value={state.motherEmail}
                  onChange={(e) => handleChange('motherEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherBirthDate && format(state.motherBirthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.motherBirthDate}
                      onChange={(e) => handleChange('motherBirthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota kelahiran ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherBirthPlace} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherBirthPlace"
                  fullWidth
                  value={state.motherBirthPlace}
                  onChange={(e) => handleChange("motherBirthPlace", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir ibu
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.mother_education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='mother_education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.mother_education_type}
                  onChange={(e) => handleChange('mother_education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='mother_job_type'
                placeholder='Pilih jenis pekerjaan'
                options={jobTypeOptions}
                value={state.mother_job_type}
                onChange={(e) => handleChange('mother_job_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherJobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherJobCorporateName"
                  fullWidth
                  value={state.motherJobCorporateName}
                  onChange={(e) => handleChange("motherJobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherJobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherJobPositionName"
                  fullWidth
                  value={state.motherJobPositionName}
                  onChange={(e) => handleChange("motherJobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan ibu
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.motherJobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="motherJobWorkAddress"
                  fullWidth
                  value={state.motherJobWorkAddress}
                  onChange={(e) => handleChange("motherJobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan ibu
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.mother_salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='mother_salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.mother_salary_type}
                  onChange={(e) => handleChange('mother_salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Ada wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Checkbox
                checked={state.isHasWali === 1}
                onChange={(e) => handleChange("isHasWali", e)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tipe wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='wali_type'
                placeholder='Pilih tipe wali'
                options={waliTypeOptions}
                value={state.wali_type}
                onChange={(e) => handleChange('wali_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='wali_sex_type'
                placeholder='Pilih jenis kelamin'
                options={sexTypeOptions}
                value={state.wali_sex_type}
                onChange={(e) => handleChange('wali_sex_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <DebouncedTextField
                margin='dense'
                id='waliName'
                fullWidth
                value={state.waliName}
                onChange={(e) => handleChange('waliName', e)}
              />
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Telepon wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='waliPhone'
                  fullWidth
                  value={state.waliPhone}
                  onChange={(e) => handleChange('waliPhone', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                Ktp wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='waliKtp'
                  fullWidth
                  value={state.waliKtp}
                  onChange={(e) => handleChange('waliKtp', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                e-mail wali
              </Grid>
              <Grid item sm={8} xs={12}>
                <DebouncedTextField
                  margin='dense'
                  id='waliEmail'
                  fullWidth
                  value={state.waliEmail}
                  onChange={(e) => handleChange('waliEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Tanggal lahir wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliBirthDate && format(state.waliBirthDate, 'dd  MMMM yyyy')} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container alignItems='flex-start'>
                    <DatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      renderInput={(props) => <TextField {...props} />}
                      value={state.waliBirthDate}
                      onChange={(e) => handleChange('waliBirthDate', e)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kota kelahiran wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliBirthPlace} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliBirthPlace"
                  fullWidth
                  value={state.waliBirthPlace}
                  onChange={(e) => handleChange("waliBirthPlace", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Pendidikan terakhir wali
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={geteducationTypeLabel(state.wali_education_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_education_type'
                  placeholder='Pilih pendidikan'
                  options={educationTypeOptions}
                  value={state.wali_education_type}
                  onChange={(e) => handleChange('wali_education_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pekerjaan Wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Select
                name='wali_job_type'
                placeholder='Pilih jenis pekerjaan'
                options={jobTypeOptions}
                value={state.wali_job_type}
                onChange={(e) => handleChange('wali_job_type', e)}
                styles={defaultSelectStyle}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama Usaha / Lembaga wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliJobCorporateName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliJobCorporateName"
                  fullWidth
                  value={state.waliJobCorporateName}
                  onChange={(e) => handleChange("waliJobCorporateName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Posisi / Jabatan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliJobPositionName} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliJobPositionName"
                  fullWidth
                  value={state.waliJobPositionName}
                  onChange={(e) => handleChange("waliJobPositionName", e)}
                />
              </Conditional>
            </Grid>
          </Grid>


          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Alamat pekerjaan wali
            </Grid>
            <Grid item sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.waliJobWorkAddress} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin="dense"
                  id="waliJobWorkAddress"
                  fullWidth
                  value={state.waliJobWorkAddress}
                  onChange={(e) => handleChange("waliJobWorkAddress", e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
              Penghasilan bulanan wali
            </Grid>
            <Grid item xs={8}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={getsalaryTypeLabel(state.wali_salary_type_value)} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  name='wali_salary_type'
                  placeholder='Pilih penghasilan'
                  options={salaryTypeOptions}
                  value={state.wali_salary_type}
                  onChange={(e) => handleChange('wali_salary_type', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>

      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default NewParentDetail;

