export const userTypeOptions = [
    { label: 'Pegawai', value: 1 },
    { label: process.env.REACT_APP_STUDENT, value: 2 },
    { label: 'Orang tua', value: 3 },
    { label: 'Pengunjung', value: 4 },
    { label: 'Vendor', value: 5 }, 
    { label: 'Calon orang tua', value: 6 }, 
    { label: `Calon ${process.env.REACT_APP_STUDENT}`, value: 7 }
]


export const getuserTypeObject = (value) => {
    const idx = userTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return userTypeOptions[idx]
    }
    return null
}

export const getuserTypeLabel = (value) => {
    const idx = userTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return userTypeOptions[idx].label
    }
    return null
}

export const studentGroupOptions = [
    { label: 'TAHFIDZ', value: 'TAHFIDZ' }
]

export const getstudentGroupObject = (value) => {
    const idx = studentGroupOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return studentGroupOptions[idx]
    }
    return null
}

export const getstudentGroupLabel = (value) => {
    const idx = studentGroupOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return studentGroupOptions[idx].label
    }
    return null
}

export const coaGroupOptions = [
    { label: 'HARTA', value: 'HARTA' },
    { label: 'PIUTANG', value: 'PIUTANG' },
    { label: 'INVENTARIS', value: 'INVENTARIS' },
    { label: 'UTANG', value: 'UTANG' },
    { label: 'MODAL', value: 'MODAL' }, 
    { label: 'PENDAPATAN', value: 'PENDAPATAN' }, 
    { label: 'BIAYA', value: 'BIAYA' }
]

export const getcoaGroupObject = (value) => {
    const idx = coaGroupOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return coaGroupOptions[idx]
    }
    return null
}

export const getcoaGroupLabel = (value) => {
    const idx = coaGroupOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return coaGroupOptions[idx].label
    }
    return null
}


export const caseTypeOptions = [
    { label: 'Perkelahian', value: 1 },
    { label: 'Pencurian', value: 2 },
    { label: 'Perusakan', value: 3 },
    { label: 'Bolos', value: 4 },
    { label: 'Tawuran', value: 5 },
    { label: 'Asusila', value: 6 },
    { label: 'Narkoba', value: 7 },
    { label: 'vandalisme', value: 8 },
    { label: 'Keluar tanpa izin', value: 9 },
    { label: 'Merokok', value: 10 },
    { label: 'Menggunakan HP', value: 11 },
    { label: 'Membawa barang terlarang', value: 12 },
    { label: 'Tidak solat berjamaah', value: 13 },
]

export const getcaseTypeObject = (value) => {
    const idx = caseTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return caseTypeOptions[idx]
    }
    return null
}

export const getcaseTypeLabel = (value) => {
    const idx = caseTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return caseTypeOptions[idx].label
    }
    return null
}

export const jamKeOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
]

export const getjamKeObject = (value) => {
    const idx = jamKeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return jamKeOptions[idx]
    }
    return null
}

export const getjamKeLabel = (value) => {
    const idx = jamKeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return jamKeOptions[idx].label
    }
    return null
}

export const kitabTypeOptions = [
    { label: 'Iqro', value: 1 },
    { label: 'AlQuran', value: 2 }
]

export const getkitabTypeObject = (value) => {
    const idx = kitabTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return kitabTypeOptions[idx]
    }
    return null
}

export const getkitabTypeLabel = (value) => {
    const idx = kitabTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return kitabTypeOptions[idx].label
    }
    return null
}

export const installmentTypeOptions = [
    { label: 'Tunai', value: 1 },
    { label: 'Cicil', value: 2 }
]

export const getinstallmentTypeObject = (value) => {
    const idx = installmentTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return installmentTypeOptions[idx]
    }
    return null
}

export const getinstallmentTypeLabel = (value) => {
    const idx = installmentTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return installmentTypeOptions[idx].label
    }
    return null
}

export const paymentMethodOptions = [
    { label: 'Saldo', value: 1 },
    { label: 'Virtual Account', value: 2 }
]

export const getpaymentMethodObject = (value) => {
    const idx = paymentMethodOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return paymentMethodOptions[idx]
    }
    return null
}

export const getpaymentMethodLabel = (value) => {
    const idx = paymentMethodOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return paymentMethodOptions[idx].label
    }
    return null
}

export const TRANSACTION_MODE_BALANCE = 1;
export const TRANSACTION_MODE_CASH = 2;
export const TRANSACTION_MODE_VA = 3;
export const TRANSACTION_MODE_MANUAL_TRANSFER = 4;

export const transactionModeOptions = [
    { label: 'Saldo', value: 1 },
    { label: 'Tunai', value: 2 },
    { label: 'VA', value: 3 },
    { label: 'Manual bank transfer', value: 4 },
]

export const gettransactionModeObject = (value) => {
    const idx = transactionModeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return transactionModeOptions[idx]
    }
    return null
}

export const gettransactionModeLabel = (value) => {
    const idx = transactionModeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return transactionModeOptions[idx].label
    }
    return null
}

export const TRANSACTION_TYPE_JAJAN = 1;
export const TRANSACTION_TYPE_BAYARAN = 2;
export const TRANSACTION_TYPE_MENABUNG = 3;
export const TRANSACTION_TYPE_DONASI = 4;
export const TRANSACTION_TYPE_PENGELUARAN = 5;
export const TRANSACTION_TYPE_PENERIMAAN = 6;
export const TRANSACTION_TYPE_CAIR_TABUNGAN = 7;
export const TRANSACTION_TYPE_CAIR_TOKO = 8; 
export const TRANSACTION_TYPE_TRANSFER_KAS = 9; 
export const TRANSACTION_TYPE_CAIR_DONASI = 10; 
export const TRANSACTION_TYPE_TARIK_AUTODEBET = 11; 
export const TRANSACTION_TYPE_CAIR_AUTODEBET = 12; 

export const transactionTypeOptions = [
    { label: 'Input Tagihan', value: 0 }, 
    { label: 'Jajan', value: 1 },
    { label: 'Bayaran', value: 2 },
    { label: 'Menabung', value: 3 },
    { label: 'Donasi', value: 4 },
    { label: 'Pengeluaran', value: 5 },
    { label: 'Penerimaan', value: 6 },
    { label: 'Pencairan tabungan', value: 7 },
    { label: 'Pencairan toko', value: 8 },
    { label: 'Transfer kas', value: 9 },
    { label: 'Pencairan donasi', value: 10 },
    { label: 'Tarik Autodebet', value: 11 },
    { label: 'Pencairan Autodebet', value: 12 },
    { label: 'Modal', value: 13 }
]

export const gettransactionTypeObject = (value) => {
    const idx = transactionTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return transactionTypeOptions[idx]
    }
    return null
}

export const gettransactionTypeLabel = (value) => {
    const idx = transactionTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return transactionTypeOptions[idx].label
    }
    return null
}

export const attendanceTypeOptions = [
    { label: 'Alpa', value: 0, backgroundColor: 'error', textColor: 'white' },
    { label: 'Hadir', value: 1 , backgroundColor: 'success', textColor: 'white' },
    { label: 'Izin', value: 2, backgroundColor: 'warning', textColor: 'white'},
    { label: 'Cuti', value: 3, backgroundColor: 'warning', textColor: 'white' }
]

export const getattendanceTypeObject = (value) => {
    const idx = attendanceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return attendanceTypeOptions[idx]
    }
    return null
}

export const getattendanceTypeLabel = (value) => {
    const idx = attendanceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return attendanceTypeOptions[idx].label
    }
    return null
}

export const comeLeaveStatusOptions = [
    { label: 'Awal', value: 1 }, 
    { label: 'Tepat', value: 2 },
    { label: 'Telat', value: 3 }
]

export const getcomeLeaveStatusObject = (value) => {
    const idx = comeLeaveStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return comeLeaveStatusOptions[idx]
    }
    return null
}

export const getcomeLeaveStatusLabel = (value) => {
    const idx = comeLeaveStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return comeLeaveStatusOptions[idx].label
    }
    return null
}


export const smart_reader_modeOptions = [
    { label: 'CHECK', value: 'CHECK' },
    { label: 'ABSEN', value: 'ABSEN' },
    { label: 'ABSEN HARIAN', value: 'ABSEN HARIAN' },
    { label: 'ABSEN PELAJARAN', value: 'ABSEN PELAJARAN' },
    { label: 'ABSEN KEGIATAN', value: 'ABSEN KEGIATAN' },
    { label: 'GURU', value: 'GURU' },
    { label: 'UKS', value: 'UKS' },
    { label: 'KONSELING', value: 'KONSELING' },
    { label: 'KASIR', value: 'KASIR' },
    { label: 'REGISTER', value: 'REGISTER' },
    { label: 'TATA USAHA', value: 'TATA USAHA' },
    { label: 'PERPUSTAKAAN', value: 'PERPUSTAKAAN' },
    { label: 'DONASI', value: 'DONASI' },
    { label: 'KASIR  AUTO DEBET', value: 'KASIR AUTO DEBET' },
    { label: 'SECURITY', value: 'SECURITY' }
]

export const getsmart_reader_modeObject = (value) => {
    const idx = smart_reader_modeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return smart_reader_modeOptions[idx]
    }
    return null
}

export const getsmart_reader_modeLabel = (value) => {
    const idx = smart_reader_modeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return smart_reader_modeOptions[idx].label
    }
    return null
}


export const symptomTypeOptions = [
    { label: 'Sakit', value: 1 },
    { label: 'Kelelahan', value: 2 },
    { label: 'Cek Up', value: 3  }
]

export const getsymptomTypeObject = (value) => {
    const idx = symptomTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return symptomTypeOptions[idx]
    }
    return null
}

export const getsymptomTypeLabel = (value) => {
    const idx = symptomTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return symptomTypeOptions[idx].label
    }
    return null
}

export const permissionTypeOptions = [
    { label: 'Sakit', value: 1 },
    { label: 'Tugas', value: 2  },
    { label: 'Musibah', value: 3 }
]

export const getpermissionTypeObject = (value) => {
    const idx = permissionTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return permissionTypeOptions[idx]
    }
    return null
}

export const getpermissionTypeLabel = (value) => {
    const idx = permissionTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return permissionTypeOptions[idx].label
    }
    return null
}

export const cutiTypeOptions = [
    { label: 'Sakit', value: 1 },
    { label: 'Tugas', value: 2  },
    { label: 'Musibah', value: 3 }
]

export const getcutiTypeObject = (value) => {
    const idx = cutiTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return cutiTypeOptions[idx]
    }
    return null
}

export const getcutiTypeLabel = (value) => {
    const idx = cutiTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return cutiTypeOptions[idx].label
    }
    return null
}


export const scheduleTypeOptions = [
    { label: 'Harian', value: 1 },
    { label: 'Pelajaran', value: 2 },
    { label: 'Kegiatan', value: 3 }
]

export const getscheduleTypeObject = (value) => {
    const idx = scheduleTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scheduleTypeOptions[idx]
    }
    return null
}

export const getscheduleTypeLabel = (value) => {
    const idx = scheduleTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scheduleTypeOptions[idx].label
    }
    return null
}

export const recurringTypeOptions = [
    { label: 'Satu kali', value: 1 },
    { label: 'Tiap bulan', value: 2 },
]

export const getrecurringTypeObject = (value) => {
    const idx = recurringTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return recurringTypeOptions[idx]
    }
    return null
}

export const getrecurringTypeLabel = (value) => {
    const idx = recurringTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return recurringTypeOptions[idx].label
    }
    return null
}

export const storeTypeOptions = [
    { label: 'Simple', value: 1 },
    { label: 'Minimarket', value: 2 },
    { label: 'Auto debet', value: 3 }
]

export const getstoreTypeObject = (value) => {
    const idx = storeTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return storeTypeOptions[idx]
    }
    return null
}

export const getstoreTypeLabel = (value) => {
    const idx = storeTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return storeTypeOptions[idx].label
    }
    return null
}


export const employeeTypeOptions = [
    { label: 'Tetap', value: 1 },
    { label: 'Kontrak', value: 2 }
]

export const getemployeeTypeObject = (value) => {
    const idx = employeeTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return employeeTypeOptions[idx]
    }
    return null
}

export const getemployeeTypeLabel = (value) => {
    const idx = employeeTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return employeeTypeOptions[idx].label
    }
    return null
}

export const parentTypeOptions = [
    { label: 'Kandung', value: 1 },
    { label: 'Wali', value: 2 }
]

export const getparentTypeObject = (value) => {
    const idx = parentTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return parentTypeOptions[idx]
    }
    return null
}

export const getparentTypeLabel = (value) => {
    const idx = parentTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return parentTypeOptions[idx].label
    }
    return null
}


export const waliTypeOptions = [ 
    { label: 'Kakek / nenek', value: 1 },
    { label: 'Paman / bibi', value: 2 },
    { label: 'Kakak', value: 3 },
]

export const getwaliTypeObject = (value) => {
    const idx = waliTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return waliTypeOptions[idx]
    }
    return null
}

export const getwaliTypeLabel = (value) => {
    const idx = waliTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return waliTypeOptions[idx].label
    }
    return null
}



export const jobTypeOptions = [
    { label: 'Belum bekerja', value: 0 },
    { label: 'ASN', value: 1 },
    { label: 'Pegawai swasta', value: 2 },
    { label: 'Wiraswasta', value: 3 }
]

export const getjobTypeObject = (value) => {
    const idx = jobTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return jobTypeOptions[idx]
    }
    return null
}

export const getjobTypeLabel = (value) => {
    const idx = jobTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return jobTypeOptions[idx].label
    }
    return null
}

export const lifeStatusOptions = [
    { label: 'Hidup', value: 1 },
    { label: 'Meninggal Dunia', value: 0 }
]

export const getlifeStatusObject = (value) => {
    const idx = lifeStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return lifeStatusOptions[idx]
    }
    return null
}

export const getlifeStatusLabel = (value) => {
    const idx = lifeStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return lifeStatusOptions[idx].label
    }
    return null
}

export const caseStatusOptions = [
    { label: 'Proses', value: 1 , backgroundColor: 'default', textColor: 'black' },
    { label: 'Selesai', value: 2, backgroundColor: 'success', textColor: 'white'  } 
]

export const getcaseStatusObject = (value) => {
    const idx = caseStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return caseStatusOptions[idx]
    }
    return null
}

export const getcaseStatusLabel = (value) => {
    const idx = caseStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return caseStatusOptions[idx].label
    }
    return null
}

export const billPriceTypeOptions = [
    { label: 'Normal', value: 1 , backgroundColor: 'success', textColor: 'white' },
    { label: 'Khusus', value: 2, backgroundColor: 'default', textColor: 'black'  } 
]

export const getbillPriceTypeObject = (value) => {
    const idx = billPriceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return billPriceTypeOptions[idx]
    }
    return null
}

export const getbillPriceTypeLabel = (value) => {
    const idx = billPriceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return billPriceTypeOptions[idx].label
    }
    return null
}

export const approvalStatusOptions = [
    { label: 'Menunggu', value: 0, backgroundColor: 'default', textColor: 'black' },
    { label: 'Disetujui', value: 1, backgroundColor: 'success', textColor: 'white' },
    { label: 'Ditolak', value: 2, backgroundColor: 'error', textColor: 'white' }
]

export const getapprovalStatusObject = (value) => {
    const idx = approvalStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return approvalStatusOptions[idx]
    }
    return null
}

export const getapprovalStatusLabel = (value) => {
    const idx = approvalStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return approvalStatusOptions[idx].label
    }
    return null
}

export const withdrawalStatusOptions = [
    { label: 'Menunggu', value: 0, backgroundColor: 'default', textColor: 'black' },
    { label: 'Disetujui', value: 1, backgroundColor: 'primary', textColor: 'white' },
    { label: 'Dicairkan', value: 2, backgroundColor: 'secondary', textColor: 'white' },
    { label: 'Diterima', value: 3, backgroundColor: 'success', textColor: 'white' }
]

export const getwithdrawalStatusObject = (value) => {
    const idx = withdrawalStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return withdrawalStatusOptions[idx]
    }
    return null
}

export const getwithdrawalStatusLabel = (value) => {
    const idx = withdrawalStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return withdrawalStatusOptions[idx].label
    }
    return null
}

export const borrowStatusOptions = [
    { label: 'Dipinjam', value: 1, backgroundColor: 'default', textColor: 'black' },
    { label: 'Kembali', value: 2, backgroundColor: 'success', textColor: 'white' }
]

export const getborrowStatusObject = (value) => {
    const idx = borrowStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return borrowStatusOptions[idx]
    }
    return null
}

export const getborrowStatusLabel = (value) => {
    const idx = borrowStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return borrowStatusOptions[idx].label
    }
    return null
}

export const newsStatusOptions = [
    { label: 'Disebar', value: 1 },
    { label: 'Draft', value: 0 }
]

export const getnewsStatusObject = (value) => {
    const idx = newsStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return newsStatusOptions[idx]
    }
    return null
}

export const getnewsStatusLabel = (value) => {
    const idx = newsStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return newsStatusOptions[idx].label
    }
    return null
}


export const cardStatusOptions = [
    { label: 'Baru', value: 1 },
    { label: 'Terhubung', value: 2 },
    { label: 'Rusak', value: 3 },
    { label: 'Hilang', value: 4 }
]

export const getCardStatusObject = (value) => {
    const idx = cardStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return cardStatusOptions[idx]
    }
    return null
}

export const getCardStatusLabel = (value) => {
    const idx = cardStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return cardStatusOptions[idx].label
    }
    return null
}


export const subjectTypeOptions = [
    { label: 'Kognitif', value: 1 },
    { label: 'Afektif', value: 2 },
    { label: 'Psikomotorik', value: 3 }
]

export const getsubjectTypeObject = (value) => {
    const idx = subjectTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return subjectTypeOptions[idx]
    }
    return null
}

export const getsubjectTypeLabel = (value) => {
    const idx = subjectTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return subjectTypeOptions[idx].label
    }
    return null
}

export const balanceTypeOptions = [
    { label: 'Jajan', value: 1 },
    { label: 'Tabungan wajib', value: 2 }
]

export const getbalanceTypeObject = (value) => {
    const idx = balanceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return balanceTypeOptions[idx]
    }
    return null
}

export const getbalanceTypeLabel = (value) => {
    const idx = balanceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return balanceTypeOptions[idx].label
    }
    return null
}

export const dayOptions = [
    { label: 'Senin', value: 1 },
    { label: 'Selasa', value: 2 },
    { label: 'Rabu', value: 3 },
    { label: 'Kamis', value: 4 },
    { label: 'Jumat', value: 5 },
    { label: 'Sabtu', value: 6 },
    { label: 'Minggu', value: 0 }
]

export const getdayObject = (value) => {
    const idx = dayOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return dayOptions[idx]
    }
    return null
}

export const getdayLabel = (value) => {
    const idx = dayOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return dayOptions[idx].label
    }
    return null
}

export const sexTypeOptions = [
    { label: 'Laki - laki', value: 1 },
    { label: 'Perempuan', value: 2 }
]

export const getsexTypeObject = (value) => {
    const idx = sexTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return sexTypeOptions[idx]
    }
    return null
}

export const getsexTypeLabel = (value) => {
    const idx = sexTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return sexTypeOptions[idx].label
    }
    return null
}

export const parentSexOptions = [
    { label: 'Ayah', value: 1 },
    { label: 'Ibu', value: 2 }
]

export const getparentSexObject = (value) => {
    const idx = parentSexOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return parentSexOptions[idx]
    }
    return null
}

export const getparentSexLabel = (value) => {
    const idx = parentSexOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return parentSexOptions[idx].label
    }
    return null
}

export const isAdoptedOptions = [
    { label: 'Kandung', value: 0 },
    { label: 'Adopsi', value: 1 }
]

export const getAdoptedObject = (value) => {
    const idx = isAdoptedOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return isAdoptedOptions[idx]
    }
    return null
}

export const getAdoptedLabel = (value) => {
    const idx = isAdoptedOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return isAdoptedOptions[idx].label
    }
    return null
}

export const stayWithOptions = [
    { label: 'Ayah / Ibu', value: 1 },
    { label: 'Kakak / Adik', value: 2 },
    { label: 'Kakek / Nenek', value: 3 },
    { label: 'Paman / Bibi', value: 4 },
    { label: 'Kerabat lainnya', value: 5 }
]

export const getstayWithObject = (value) => {
    const idx = stayWithOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return stayWithOptions[idx]
    }
    return null
}

export const getstayWithLabel = (value) => {
    const idx = stayWithOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return stayWithOptions[idx].label
    }
    return null
}

export const religionOptions = [
    { label: 'Islam', value: 1 },
    { label: 'Kristen', value: 2 },
    { label: 'Katolik', value: 3 },
    { label: 'hindu', value: 4 },
    { label: 'Budha', value: 5 },
    { label: 'Konghucu', value: 6 }
]

export const getreligionObject = (value) => {
    const idx = religionOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return religionOptions[idx]
    }
    return null
}

export const getreligionLabel = (value) => {
    const idx = religionOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return religionOptions[idx].label
    }
    return null
}

export const studentStatusOptions = [
    { label: `${process.env.REACT_APP_STUDENT} aktif`, value: 1 },
    { label: 'Lulus', value: 2 },
    { label: 'Cuti', value: 3 },
    { label: 'Putus sekolah', value: 4 },
    { label: 'Pindah sekolah', value: 5 },
    { label: 'Dikeluarkan', value: 6 }
]

export const getstudentStatusObject = (value) => {
    const idx = studentStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return studentStatusOptions[idx]
    }
    return null
}

export const getstudentStatusLabel = (value) => {
    const idx = studentStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return studentStatusOptions[idx].label
    }
    return null
}


export const readerLevelOptions = [
    { label: 'Semua', value: 0 },
    { label: 'Yayasan', value: 1 },
    { label: 'Sekolah', value: 2 },
    { label: 'Kelas', value: 3 },
    { label: 'Jurusan', value: 4 },
    { label: 'Paralel', value: 5 },
    { label: 'Posisi', value: 6 },
    { label: 'Individu', value: 7 }
]

export const getreaderLevelObject = (value) => {
    const idx = readerLevelOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return readerLevelOptions[idx]
    }
    return null
}

export const getreaderLevelLabel = (value) => {
    const idx = readerLevelOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return readerLevelOptions[idx].label
    }
    return null
}

export const serviceTypeOptions = [
    { label: 'Konsultasi', value: 1 },
    { label: 'Kasus', value: 2 }
]

export const getserviceTypeObject = (value) => {
    const idx = serviceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return serviceTypeOptions[idx]
    }
    return null
}

export const getserviceTypeLabel = (value) => {
    const idx = serviceTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return serviceTypeOptions[idx].label
    }
    return null
}

export const consultationTypeOptions = [
    { label: 'Perkembangan pribadi', value: 1 },
    { label: 'Perkembangan belajar', value: 2 },
    { label: 'Perkembangan karir', value: 3 },
    { label: 'Perkembangan sosial', value: 4 }
]

export const getconsultationTypeObject = (value) => {
    const idx = consultationTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return consultationTypeOptions[idx]
    }
    return null
}

export const getconsultationTypeLabel = (value) => {
    const idx = consultationTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return consultationTypeOptions[idx].label
    }
    return null
}

export const caseLevelOptions = [
    { label: 'Ringan', value: 1 },
    { label: 'Sedang', value: 2 },
    { label: 'Berat', value: 3 }
]

export const getcaseLevelObject = (value) => {
    const idx = caseLevelOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return caseLevelOptions[idx]
    }
    return null
}

export const getcaseLevelLabel = (value) => {
    const idx = caseLevelOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return caseLevelOptions[idx].label
    }
    return null
}

export const participantTypeOptions = [
    { label: 'Individu', value: 1 },
    { label: 'Kelompok', value: 2 },
]

export const getparticipantTypeObject = (value) => {
    const idx = participantTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return participantTypeOptions[idx]
    }
    return null
}

export const getparticipantTypeLabel = (value) => {
    const idx = participantTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return participantTypeOptions[idx].label
    }
    return null
}

export const achievementTypeOptions = [
    { label: 'Lomba internal sekolah', value: 1 },
    { label: 'Juara kelas', value: 2 },
    { label: 'Lomba antar sekolah', value: 3 },
    { label: 'Lomba Nasional', value: 4 },
    { label: 'Diterima PTN', value: 5 },
    { label: 'Tahfidz', value: 6 },
]

export const getachievementTypeObject = (value) => {
    const idx = achievementTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return achievementTypeOptions[idx]
    }
    return null
}

export const getachievementTypeLabel = (value) => {
    const idx = achievementTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return achievementTypeOptions[idx].label
    }
    return null
}

export const examTypeOptions = [
    { label: 'Penilaian Harian', code:'PH', value: 1 }, 
    { label: 'Penilaian Tengah Semester', code:'PTS', value: 2 },
    { label: 'Penilaian Akhir Semester', code:'PAS', value: 3 },
    { label: 'Nilai Akhir', code:'NA', value: 4 },
]

export const getexamTypeObject = (value) => {
    const idx = examTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return examTypeOptions[idx]
    }
    return null
}

export const getexamTypeLabel = (value) => {
    const idx = examTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return examTypeOptions[idx].label
    }
    return null
}
 

export const semesterOptions = [
    { label: 'Ganjil', value: 1 }, 
    { label: 'Genap', value: 2 }, 
]

export const getsemesterObject = (value) => {
    const idx = semesterOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return semesterOptions[idx]
    }
    return null
}

export const getsemesterLabel = (value) => {
    const idx = semesterOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return semesterOptions[idx].label
    }
    return null
}

export const payStatusOptions = [
    { label: 'Belum', value: 0, backgroundColor: 'error', textColor: 'white' },
    { label: 'Dicicil', value: 1, backgroundColor: 'warning', textColor: 'white' },
    { label: 'Lunas', value: 2, backgroundColor: 'success', textColor: 'white' }
]

export const getpayStatusObject = (value) => {
    const idx = payStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return payStatusOptions[idx]
    }
    return null
}

export const getpayStatusLabel = (value) => {
    const idx = payStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return payStatusOptions[idx].label
    }
    return null
}

export const discountTypeOptions = [
    { label: 'Persentase', value: 1}, 
    { label: 'Nominal', value: 2}
]

export const getdiscountTypeObject = (value) => {
    const idx = discountTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return discountTypeOptions[idx]
    }
    return null
}

export const getdiscountTypeLabel = (value) => {
    const idx = discountTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return discountTypeOptions[idx].label
    }
    return null
}

export const specialPriceOptions = [
    { label: 'Diskon', value: 1}, 
    { label: 'Harga khusus', value: 2}
]

export const getspecialPriceObject = (value) => {
    const idx = specialPriceOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return specialPriceOptions[idx]
    }
    return null
}

export const getspecialPriceLabel = (value) => {
    const idx = specialPriceOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return specialPriceOptions[idx].label
    }
    return null
}

export const billedStatusOptions = [
    { label: 'Belum', value: 0, backgroundColor: 'default', textColor: 'black' }, 
    { label: 'Sudah', value: 1, backgroundColor: 'success', textColor: 'white' }
]

export const getbilledStatusObject = (value) => {
    const idx = billedStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return billedStatusOptions[idx]
    }
    return null
}

export const getbilledStatusLabel = (value) => {
    const idx = billedStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return billedStatusOptions[idx].label
    }
    return null
}

export const paymentStatusOptions = [
    { label: 'Belum', value: 0, backgroundColor: 'default', textColor: 'black' },
    { label: 'Berhasil', value: 1, backgroundColor: 'success', textColor: 'white' },
    { label: 'Gagal', value: 2, backgroundColor: 'error', textColor: 'white' }
]

export const getpaymentStatusObject = (value) => {
    const idx = paymentStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return paymentStatusOptions[idx]
    }
    return null
}

export const getpaymentStatusLabel = (value) => {
    const idx = paymentStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return paymentStatusOptions[idx].label
    }
    return null
}


export const monthOptions = [
    { label: 'Januari', value: 1 },
    { label: 'Februari', value: 2 },
    { label: 'Maret', value: 3 },
    { label: 'April', value: 4 },
    { label: 'Mei', value: 5 },
    { label: 'Juni', value: 6 },
    { label: 'Juli', value: 7 },
    { label: 'Agustus', value: 8 },
    { label: 'September', value: 9 },
    { label: 'Oktober', value: 10 },
    { label: 'November', value: 11 },
    { label: 'Desember', value: 12 }
]

export const getmonthObject = (value) => {
    const idx = monthOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return monthOptions[idx]
    }
    return null
}

export const getmonthLabel = (value) => {
    const idx = monthOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return monthOptions[idx].label
    }
    return null
}

export const suratOptions = [
    { value: 1, label: "Al-Fatihah" },
    { value: 2, label: "Al-Baqarah" },
    { value: 3, label: "Ali-Imran" },
    { value: 4, label: "An-Nisa" },
    { value: 5, label: "Al-Maidah" },
    { value: 6, label: "Al-Anam" },
    { value: 7, label: "Al-Araf" },
    { value: 8, label: "Al-Anfal" },
    { value: 9, label: "At-Taubah" },
    { value: 10, label: "Yunus" },
    { value: 11, label: "Hud" },
    { value: 12, label: "Yusuf" },
    { value: 13, label: "Ar-Rad" },
    { value: 14, label: "Ibrahim" },
    { value: 15, label: "Al-Hijr" },
    { value: 16, label: "An-Nahl" },
    { value: 17, label: "Al-Isra" },
    { value: 18, label: "Al-Kahfi" },
    { value: 19, label: "Maryam" },
    { value: 20, label: "Ta Ha" },
    { value: 21, label: "Al-Anbiya" },
    { value: 22, label: "Al-Hajj" },
    { value: 23, label: "Al-Muminun" },
    { value: 24, label: "An-Nur" },
    { value: 25, label: "Al-Furqan" },
    { value: 26, label: "Asy-Syuara" },
    { value: 27, label: "An-Naml" },
    { value: 28, label: "Al-Qasas" },
    { value: 29, label: "Al-Ankabut" },
    { value: 30, label: "Ar-Ruu" },
    { value: 31, label: "Luqman" },
    { value: 32, label: "As-Sajdah" },
    { value: 33, label: "Al-Ahzab" },
    { value: 34, label: "Saba" },
    { value: 35, label: "Fatir" },
    { value: 36, label: "Ya sin" },
    { value: 37, label: "Ash-Shaaffat" },
    { value: 38, label: "Shad" },
    { value: 39, label: "Az-Zumar" },
    { value: 40, label: "Al-Mumin" },
    { value: 41, label: "Fushshilat" },
    { value: 42, label: "Asy-Syura" },
    { value: 43, label: "Az-Zukhruf" },
    { value: 44, label: "Ad-Dukhan" },
    { value: 45, label: "Al-Jaatsiyah" },
    { value: 46, label: "Al-Ahqaf" },
    { value: 47, label: "Muhammad" },
    { value: 48, label: "Al-Fath" },
    { value: 49, label: "Al-Hujurat" },
    { value: 50, label: "Qaaf" },
    { value: 51, label: "Adz-dzariyat" },
    { value: 52, label: "Ath-Thuur" },
    { value: 53, label: "An-Najm" },
    { value: 54, label: "Al-Qamar" },
    { value: 55, label: "Ar-Rahman" },
    { value: 56, label: "Al-Waqiah" },
    { value: 57, label: "Al-Hadid" },
    { value: 58, label: "Al-Mujadilah" },
    { value: 59, label: "Al-Hasyr" },
    { value: 60, label: "Al-Mumtahanah" },
    { value: 61, label: "Ash-shaf" },
    { value: 62, label: "Al-Jumuah" },
    { value: 63, label: "Al-Munafiqun" },
    { value: 64, label: "At-taghabun" },
    { value: 65, label: "Ath-Thalaq" },
    { value: 66, label: "At-Tahrim" },
    { value: 67, label: "Al-Mulk" },
    { value: 68, label: "Al-Qalam" },
    { value: 69, label: "Al-Haqqah" },
    { value: 70, label: "Al-Maarij" },
    { value: 71, label: "Nuh" },
    { value: 72, label: "Al-Jin" },
    { value: 73, label: "Al-Muzammil" },
    { value: 74, label: "Al-Muddatstsir" },
    { value: 75, label: "Al-Qiyamah" },
    { value: 76, label: "Al-Insan" },
    { value: 77, label: "Al-Mursalat" },
    { value: 78, label: "An-Naba" },
    { value: 79, label: "An-Naziat" },
    { value: 80, label: "Abasa" },
    { value: 81, label: "At-Takwir" },
    { value: 82, label: "Al-Infithar" },
    { value: 83, label: "Al-Muthaffifin" },
    { value: 84, label: "Al-Insyiqaq" },
    { value: 85, label: "Al-Buruj" },
    { value: 86, label: "Ath-Thariq" },
    { value: 87, label: "Al-Alaa" },
    { value: 88, label: "Al-Ghasyiyah" },
    { value: 89, label: "Al-Fajr" },
    { value: 90, label: "Al-Balad" },
    { value: 91, label: "Asy-Syams" },
    { value: 92, label: "Al-Lail" },
    { value: 93, label: "Adh-Dhuha" },
    { value: 94, label: "Al-Insyirah" },
    { value: 95, label: "At-Tin" },
    { value: 96, label: "Al-Alaq" },
    { value: 97, label: "Al-Qadr" },
    { value: 98, label: "Al-Bayyinah" },
    { value: 99, label: "Al-Zalzalah" },
    { value: 100, label: "Al-Adiyat" },
    { value: 101, label: "Al-Qariah" },
    { value: 102, label: "At-Takatsur" },
    { value: 103, label: "Al-Ashr" },
    { value: 104, label: "Al-Humazah" },
    { value: 105, label: "Al-Fil" },
    { value: 106, label: "Quraysh" },
    { value: 107, label: "Al-Maun" },
    { value: 108, label: "Al-Kautsar" },
    { value: 109, label: "Al-Kafirun" },
    { value: 110, label: "An-Nashr" },
    { value: 111, label: "Al-Lahab" },
    { value: 112, label: "Al-Ikhlas" },
    { value: 113, label: "Al-Falaq" },
    { value: 114, label: "An-Nas" }
]

export const getsuratObject = (value) => {
    const idx = suratOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return suratOptions[idx]
    }
    return null
}

export const getsuratLabel = (value) => {
    const idx = suratOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return suratOptions[idx].label
    }
    return null
}

export const doneStatusOptions = [ 
    { label: 'Belum', value: 0, backgroundColor: 'default', textColor: 'black' },
    { label: 'Sudah', value: 1, backgroundColor: 'success', textColor: 'white' },
]

export const getdoneStatusObject = (value) => {
    const idx = doneStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return doneStatusOptions[idx]
    }
    return null
}

export const getdoneStatusLabel = (value) => {
    const idx = doneStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return doneStatusOptions[idx].label
    }
    return null
}

export const yesNoStatusOptions = [ 
    { label: 'Tidak', value: 0, backgroundColor: 'default', textColor: 'black' },
    { label: 'Ya', value: 1, backgroundColor: 'success', textColor: 'white' },
]

export const getyesNoStatusObject = (value) => {
    const idx = yesNoStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return yesNoStatusOptions[idx]
    }
    return null
}

export const getyesNoStatusLabel = (value) => {
    const idx = yesNoStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return yesNoStatusOptions[idx].label
    }
    return null
}

export const scoreTypeOptions = [
    { label: 'Pengetahuan', value: 1 }, 
    { label: 'Keterampilan', value: 2 },
    { label: 'Sikap spiritual', value: 3 },
    { label: 'Sikap sosial', value: 4 },
]

export const getscoreTypeObject = (value) => {
    const idx = scoreTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scoreTypeOptions[idx]
    }
    return null
}

export const getscoreTypeLabel = (value) => {
    const idx = scoreTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scoreTypeOptions[idx].label
    }
    return null
}

export const numberOptions = [
    { label: '1', value: 1 }, 
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
]

export const getnumberObject = (value) => {
    const idx = numberOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return numberOptions[idx]
    }
    return null
}

export const getnumberLabel = (value) => {
    const idx = numberOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return numberOptions[idx].label
    }
    return null
}

export const kiOptions = [
    { label: '1', value: 1 }, 
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 }
]

export const getkiObject = (value) => {
    const idx = kiOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return kiOptions[idx]
    }
    return null
}

export const getkiLabel = (value) => {
    const idx = kiOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return kiOptions[idx].label
    }
    return null
}

export const scoringMethodOptions = [
    { label: 'Tulis', value: 'TLS' }, 
    { label: 'Tugas', value: 'TGL' },
    { label: 'Lisan', value: 'LSN' },
    { label: 'Praktik', value: 'PRTK' },
    { label: 'Proyek', value: 'PRJK' },
]

export const getscoringMethodObject = (value) => {
    const idx = scoringMethodOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scoringMethodOptions[idx]
    }
    return null
}

export const getscoringMethodLabel = (value) => {
    const idx = scoringMethodOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return numberOptions[idx].label
    }
    return null
}

export const vehicleTypeOptions = [
    { label: 'Jalan kaki', value: 1 }, 
    { label: 'Mobil pribadi', value: 2 },
    { label: 'Motor pribadi', value: 3 },
    { label: 'Angkutan umum', value: 4 },
    { label: 'Jasa antar jemput', value: 5 }
]

export const getvehicleTypeObject = (value) => {
    const idx = vehicleTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return vehicleTypeOptions[idx]
    }
    return null
}

export const getvehicleTypeLabel = (value) => {
    const idx = vehicleTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return vehicleTypeOptions[idx].label
    }
    return null
}

export const educationTypeOptions = [
    { label: 'Tidak sekolah', value: 0 }, 
    { label: 'Putus SD', value: 1 },
    { label: 'SD sederajat', value: 2 },
    { label: 'SMP sederajat', value: 3 },
    { label: 'SMA sederajat', value: 4 },
    { label: 'D1', value: 5 },
    { label: 'D2', value: 6 },
    { label: 'D3', value: 7 },
    { label: 'S1', value: 8 },
    { label: 'S2', value: 9 },
    { label: 'S3', value: 10 },
]

export const geteducationTypeObject = (value) => {
    const idx = educationTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return educationTypeOptions[idx]
    }
    return null
}

export const geteducationTypeLabel = (value) => {
    const idx = educationTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return educationTypeOptions[idx].label
    }
    return null
}


export const salaryTypeOptions = [
    { label: 'Tidak berpenghasilan', value: 0 }, 
    { label: '< 500.000', value: 1 },
    { label: '500.000 - 999.000', value: 2 },
    { label: '1.000.000 - 1.999.999', value: 3 },
    { label: '2.000.000 - 4.999.999', value: 4 },
    { label: '5.000.000 - 20.000.000', value: 5 },
    { label: '> 20.000.000', value: 6 },
]

export const getsalaryTypeObject = (value) => {
    const idx = salaryTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return salaryTypeOptions[idx]
    }
    return null
}

export const getsalaryTypeLabel = (value) => {
    const idx = salaryTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return salaryTypeOptions[idx].label
    }
    return null
}

export const autodebetStatusOptions = [ 
    { label: 'Berhasil', value: 1, backgroundColor: 'success', textColor: 'white' },
    { label: 'Gagal', value: 0, backgroundColor: 'error', textColor: 'white' }
]

export const getautodebetStatusObject = (value) => {
    const idx = autodebetStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return autodebetStatusOptions[idx]
    }
    return null
}

export const getautodebetStatusLabel = (value) => {
    const idx = autodebetStatusOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return autodebetStatusOptions[idx].label
    }
    return null
}

export const tahfidzScoreTypeOptions = [
    { label: 'Mumtaz', value: 5 }, 
    { label: 'Jayyid Jiddan', value: 4 },
    { label: 'Jayyid', value: 3 },
    { label: 'Maqbul', value: 2 },
    { label: 'Rosyib', value: 1 }
]

export const gettahfidzScoreTypeObject = (value) => {
    const idx = tahfidzScoreTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return tahfidzScoreTypeOptions[idx]
    }
    return null
}

export const gettahfidzScoreTypeLabel = (value) => {
    const idx = tahfidzScoreTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return tahfidzScoreTypeOptions[idx].label
    }
    return null
}

export const scopeTypeOptions = [
    { label: 'Semua', value: 1 },
    { label: 'Sekolah', value: 2 },
    { label: 'Kelas', value: 3 },
    { label: 'Paralel', value: 4 }
]

export const getscopeTypeObject = (value) => {
    const idx = scopeTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scopeTypeOptions[idx]
    }
    return null
}

export const getscopeTypeLabel = (value) => {
    const idx = scopeTypeOptions.findIndex(item => (item.value === value))
    if (idx > -1) {
        return scopeTypeOptions[idx].label
    }
    return null
}