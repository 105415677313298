import React, { useState, useEffect, createContext } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import useAuth from 'hooks/useAuth';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, generalOptionMapper, studentListOptionMapper } from 'utils/mappers';
import MoneyCard from 'components/MoneyCard';
import { Card, CardContent, Dialog, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format, parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import Confirmation from 'components/Confirmation';
import StatusChip from 'components/StatusChip';
import { EchoInstance } from 'App'; 
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const InstallmentContext = createContext();

const BalanceTopup = (props) => {

  const { row = {}, getData, getDataByParams } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI() 
  const reset = () => {
    return {

    }

  }
  const [pageAction, setpageAction] = useState('READ')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [queue, setqueue] = useState(null)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [nameEmployeeKeyWord, setnameEmployeeKeyWord] = useState('');
  const [amount, setamount] = useState(0)
  const [student, sestudent] = useState(null)
  const [employee, setemployee] = useState(null)
  const [transactionMode, settransactionMode] = useState('2'); 
  const [manualCoaBank, setmanualCoaBank] = useState(null);
  const [coaBankOptions, setcoaBankOptions] = useState([]);
  const [manualCoaKas, setmanualCoaKas] = useState(null);
  const [coaKasOptions, setcoaKasOptions] = useState([]);
  

  const transactionModeChange = event => {
    settransactionMode(event.target.value)
    if(event.target.value==='2'){
      setmanualCoaBank(null)
    }
    else if(event.target.value==='4'){
      setmanualCoaKas(null)
    }
  };

  const closeDialog = () => {
    setopenDialog(false)
  }

  const getLastQueue = async () => {
    if (detail && detail.smart_reader_number) {
      const params = {
        smart_reader_number: detail.smart_reader_number
      }
      const response = await axios.get(endpoint.smartReader.lastQueue, { params: params })
      if (response && response.data) {
        setqueue(response.data)
        props.setqueue(response.data)
      } else {
        setqueue(null)
      }
    }

  }

  const deleteLastQueue = async () => {
    if (queue) {
      const params = {
        ids: [queue.id]
      };
      const response = await axios.delete(endpoint.smartReader.queue, { data: params });
      if (response) {
        getLastQueue()
      } else {
        showError('delete queue')
      }
    }
  };

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getcoaBankOptions = async () => {
    const params = {
      group: 'HARTA',
      code_prefix:'12'
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoaBankOptions(generalListOptionMapper(response.data));
    }
  };

  const getcoaKasOptions = async () => {
    const params = {
      group: 'HARTA',
      code_prefix:'11',
      employee_id: detail.id
    }
    const response = await axios.get(endpoint.coa.option, { params: params });
    if (response && response.data) {
      setcoaKasOptions(generalListOptionMapper(response.data));
    }
  };


  const loadOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChange = (newValue) => { 
    setnameKeyWord(newValue)
    return newValue;
  };

  const handleStudentChange = (value) => {
    sestudent(value)
    if (value) {
      insertStudentToQueue(value)
      getLastQueue()
    }
  };

  const insertStudentToQueue = async (student) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        student_id: student.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'GURU',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.studentQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    } else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const getEmployeeOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.employee.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadEmployeeOptions = async (inputValue, callback) => {
    callback(await getEmployeeOptions(inputValue))
  };

  const handleEmployeeInputChange = (newValue) => { 
    setnameEmployeeKeyWord(newValue)
    return newValue;
  };

  const handleEmployeeChange = (value) => {
    setemployee(value)
    if (value) {
      insertEmployeeToQueue(value)
      getLastQueue()
    }
  };

  const insertEmployeeToQueue = async (employee) => {
    if (detail && detail.smart_reader_number) {
      const params = {
        employee_id: employee.id,
        smart_reader_number: detail.smart_reader_number,
        smart_reader_mode_value: 'TATA USAHA',
        admin_employee_id: detail.id
      }
      const response = await axios.post(endpoint.smartReader.employeeQueue, params)
      if (response && response.data) {
        if (response.data.error) {
          showError(response.data.error)
        }
      }
    }
    else {
      showError('Pegawai belum memiliki akses smart reader')
    }

  }

  const submitConfirmation = () => {
    
    if(student && employee){
      showInfo('Hanya bisa isi satu pengguna');
      return;
    }else{
      if(queue || student || employee){
        
        if(transactionMode==='2' && manualCoaKas===null){
          showInfo('Mohon pilih kas terlebih dahulu')
          return
        }
    
        if(transactionMode==='4' && manualCoaBank===null){
          showInfo('Mohon pilih bank terlebih dahulu')
          return
        }
        
        setMaxWidth("sm");
        setdialogContent(
          <Confirmation
            handleClose={closeDialog}
            action={submit}
            actionName="Simpan"
            message='Apakah nominal yang anda terima sudah sesuai'
            title='Konfirmasi simpan'
          />
        );
        setopenDialog(true);
      }else{
        showInfo('Belum pilih pengguna')
      }
      
    } 
  };


  const submit = async () => {
    let user_id = undefined
    let queue_id = undefined
    if (queue) {
      user_id = queue.user_id
      queue_id = queue.id
    }
    else if (student) {
      user_id = student.user_id
    }
    else if (employee) {
      user_id = employee.user_id
    }

    
    const params = {
      queue_id: queue_id,
      user_id: user_id,
      amount: amount,
      admin_employee_id: detail.id,
      balance_type_value: 1,
      transaction_mode_value: Number(transactionMode),
      coa_kas_id:manualCoaKas ? manualCoaKas.id:undefined,
      coa_bank_id:manualCoaBank ? manualCoaBank.id:undefined
    }
    const response = await axios.post(endpoint.balance.topUpManual, params)
    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      }else{
        showSuccess('Menabung')
        deleteLastQueue()
        getData()
        props.closeDialog()
      }
    } else {
      showError('Menabung')
    }

  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    EchoInstance.channel(process.env.REACT_APP_BACKEND_NAME + 'card')
      .listen('NewCardQueue', (e) => {
        getLastQueue()
      });

    return function cleanup() {
      EchoInstance.leaveChannel(process.env.REACT_APP_BACKEND_NAME +'card');
    }
  }, [])


  useEffect(() => {
    getcoaBankOptions()
    getcoaKasOptions()
  }, [])


  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            pb: 2,
            width: 'inherit',
          }}
        >

          <Grid container justifyContent='flex-start' alignItems='center' sx={{ my: 0.5 }}>
            <Grid item xs={12}>
              <Conditional condition={pageAction !== 'READ'}>
                <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ mb: 1 }}>
                  {
                    queue && <MoneyCard user={queue} onClose={deleteLastQueue} />
                  }
                  <Conditional condition={queue === null}>
                    <IconButton
                      onClick={getLastQueue}
                      aria-label='reset'
                      size="large">
                      <RefreshIcon color='primary' />
                    </IconButton>
                  </Conditional>
                </Grid>

                <Conditional condition={queue === null}>
                  <Grid container item xs={12} justifyContent="center" alignItems='center' sx={{ mb: 1 }}>
                    <AsyncSelect
                      isClearable={true}
                      cacheOptions
                      placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
                      value={student}
                      loadOptions={loadOptions}
                      onInputChange={handleInputChange}
                      onChange={handleStudentChange}
                      styles={defaultSelectStyle}
                    />
                  </Grid>

                  <Grid container item xs={12} justifyContent="center" alignItems='center' >
                    <AsyncSelect
                      isClearable={true}
                      cacheOptions
                      placeholder="Nama pegawai..."
                      value={employee}
                      loadOptions={loadEmployeeOptions}
                      onInputChange={handleEmployeeInputChange}
                      onChange={handleEmployeeChange}
                      styles={defaultSelectStyle}
                    />
                  </Grid>
                </Conditional>
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <FormControl>

              <RadioGroup
                row
                aria-labelledby="transactionMode"
                name="transactionMode"
                defaultValue="2"
                value={transactionMode}
                onChange={(e) => transactionModeChange(e)}
              >
                <FormControlLabel value="2" control={<Radio />} label="Terima uang tunai" />
                <FormControlLabel value="4" control={<Radio />} label="Manual bank transfer" />
              </RadioGroup>
            </FormControl>
            <Conditional condition={transactionMode === '2'}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={12}>
                  <Select
                    name='coa_kas'
                    placeholder='Pilih  Rek. Kas'
                    options={coaKasOptions}
                    value={manualCoaKas}
                    onChange={setmanualCoaKas}
                    styles={defaultSelectStyle}
                  />
                </Grid>
              </Grid>
            </Conditional>
            <Conditional condition={transactionMode === '4'}>
              <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
                <Grid item xs={12}>
                  <Select
                    name='coa_bank'
                    placeholder='Pilih  Rek. Bank'
                    options={coaBankOptions}
                    value={manualCoaBank}
                    onChange={setmanualCoaBank}
                    styles={defaultSelectStyle}
                  />
                </Grid>
              </Grid>
            </Conditional>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid item xs={12} container alignContent='center' alignItems='center' >
              <DebouncedTextField
                margin='dense'
                id='amount'
                fullWidth
                label='Nominal'
                type="number"
                value={amount}
                onChange={(e) => setamount(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        batal
      </Button>
      <Button variant="contained" onClick={submitConfirmation} color="primary">
        Terima pembayaran
      </Button>
    </DialogActions>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  </>;
}

export default BalanceTopup;

