import React, { useState, useEffect, useRef } from 'react';
import { api, axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useUI from 'hooks/useUI.js';
import Protected from 'components/Protected.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { DialogActions, CardActionArea, CardActions } from '@mui/material';
import { Box } from '@mui/system';
import { Verified } from '@mui/icons-material';
import { parameterListOptionMapper } from 'utils/mappers';


const NewStudentAdminFile = (props) => {
  const { row = {} } = props
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError } = useUI()
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [newStudentFiles, setnewStudentFiles] = useState([])
  const [newStudentFileTypes, setnewStudentFileTypes] = useState([])
  const [documentTypes, setdocumentTypes] = useState([])
  const [selectedFile, setselectedFile] = useState(null)
  const [isVerified, setisVerified] = useState(false)
  const [isUploadDone, setisUploadDone] = useState(false)
  const [documentTypeOptions, setdocumentTypeOptions] = useState([])



  const closeDialog = () => {
    setopenDialog(false)
    setselectedFile(null)
  }

  const getNewStudentFiles = async () => {
    let params = {}
    const response = await axios.get(endpoint.newStudent.file.replace('id', row.id), params)
    if (response) {
      setnewStudentFiles(response.data)
      setnewStudentFileTypes(response.data.map(item=>(item.document_type_value)))
    }
  }

  const getStudentPsbFlows = async (new_student_id) => {
    let params = {
      new_student_id: new_student_id
    }
    const response = await axios.get(endpoint.newStudent.psbFlow, { params: params })

    if (response && response.data) {
      const uploadFileFlow = response.data.filter(flow => flow.code === 'FILES')[0]
      if (uploadFileFlow) {
        setisUploadDone(true)
      }

      const verificationFlow = response.data.filter(flow => flow.code === 'FILE_VERIFICATION')[0]
      if (verificationFlow) {
        setisVerified(true)
      }
    } else {
      showError('get student psb flow')
    }
  }

  const verify = async () => {
    let payload = {
      id: row.id,
    }
    const response = await axios.post(endpoint.psbFlow.veriFyFile, payload)
    if (response && response.data) {
      if (response.data.message === 'success') {
        getStudentPsbFlows(row.id)
      }
    }
  }


  const getdocumentTypeOptions = async () => {
    const params = {
      group: 'psb_document_type'
    }
    const response = await axios.get(endpoint.parameter.option, { params: params });
    if (response && response.data) {
      setdocumentTypeOptions(parameterListOptionMapper(response.data));
      setdocumentTypes(response.data.map(item=>(item.value)))
    }
  };

  const fileChange = async (document_type_value, event) => {
    let file = event.target.files[0]
    const formData = new FormData();
    formData.append('file', file)
    formData.append('id', row.id)
    formData.append('document_type_value', document_type_value)

    const response = await axios.post(endpoint.psbFlow.file, formData, {});
    if (response && response.data) {
      getNewStudentFiles()
      getStudentPsbFlows(row.id)
    }
  };

  const showFile = (file) => {
    setselectedFile(file)

  }

  useEffect(() => {
    if (row) {
      getNewStudentFiles()
      getdocumentTypeOptions()
      getStudentPsbFlows(row.id)
    }
  }, [row])


  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start' >
      <Grid container justifyContent="center" alignItems="flex-start"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: 1,
          padding: 2,
          width: 'inherit',
        }}
      >

        {
          newStudentFiles.map(file => (
            <Grid item xs={12} md={4} sx={{ p: 2 }} container justifyContent="center" alignItems="flex-start">
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => showFile(file)}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${api.url}/${file.path}`}
                    alt="file psb"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {file.document_type && file.document_type.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <label htmlFor={`${file.document_type_value}-upload`}>
                    <input onChange={(e) => fileChange(file.document_type_value, e)} accept="image/*" id={`${file.document_type_value}-upload`} type="file" style={{ display: 'none' }} />
                    <Button component="span" color='error'>
                      Ubah
                    </Button>
                  </label>
                </CardActions>
              </Card>
            </Grid>
          ))

        }
        
          <Grid item xs={12} container justifyContent='space-around' sx={{ mt: 2 }}>
            {
              documentTypeOptions.filter(documentType => !newStudentFiles.some((stdFile) => stdFile.document_type_value === documentType.value)).map(documentType => (
                <Grid item xs={12} sm={3} container sx={{
                  bgcolor: '#dff6ff',
                  borderRadius: 4,
                  p: 2,
                  my: 1
                }}>
                  <Grid item xs={12} >
                    <Grid item xs={12} >
                      <Typography component='h6' variant='h6'>
                        {documentType.name}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} >
                        <label htmlFor={`${documentType.value}-upload`}>
                          <input onChange={(e) => fileChange(documentType.value, e)} accept="image/*" id={`${documentType.value}-upload`} type="file" style={{ display: 'none' }} />
                          <Button variant="contained" component="span">
                            Upload
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              ))
            }
          </Grid>
      </Grid>

    </Grid>

    <DialogActions>
      <Grid container>
        <Grid item xs={6} container>
          {
            isVerified &&
            <Box bgcolor='white' width={36} height={36} display='flex' justifyContent='center' alignItems='center' borderRadius={50}>
              <Verified color='success' fontSize='large' />
            </Box>
          }
          {
            !isVerified && isUploadDone &&
            <Button variant='contained' fullWidth onClick={verify} color="success">
              Semua dokumen sudah sesuai
            </Button>
          }
        </Grid>
        <Grid item xs={6} container justifyContent='flex-end'>
          <Button onClick={props.closeDialog} color="secondary">
            Kembali
          </Button>
        </Grid>
      </Grid>





    </DialogActions>

    <Dialog
      open={selectedFile !== null}
      maxWidth='xl'
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <Grid container justifyContent='center'>
        {
          selectedFile &&
          <img src={`${api.url}/${selectedFile.path}`} />
        }
      </Grid>

    </Dialog>

  </>;
}


export default NewStudentAdminFile;
