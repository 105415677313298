import { Box } from '@mui/material';
import React from 'react'
 

const StatusBadge = ({status}) => {

  const getBackgroundColor=(status)=>{
    if(status===0){
      return 'red';
    }
    else if(status===1){
      return 'green';
    }
    else if(status===2){
      return 'orange';
    }
  }
  
  if(status>=0){
    return (
      <Box width={15} height={15} bgcolor={getBackgroundColor(status)} borderRadius='50%'/>
    )
  }
  else{ 
    return null 
  }
  
    
}

export default StatusBadge;
