import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper, studentListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import BillForm from './BillForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { Avatar, Button, Chip, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Radio, RadioGroup } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import useAuth from 'hooks/useAuth.js';
import { useHistory } from 'react-router-dom';
import { payStatusOptions, getpayStatusLabel, getpayStatusObject, getmonthLabel, getdoneStatusObject, doneStatusOptions, monthOptions } from 'utils/options.js';
import StatusChip from 'components/StatusChip.js';
import Conditional from 'components/Conditional.js';
import { integerPropType } from '@mui/utils';
import PaymentMethod from 'views/payment/PaymentMethod.js';
import PaymentCharge from 'views/payment/PaymentCharge.js';
import AsyncSelect from 'react-select/async';



const Bill = (props) => {
  const { user, detail, students } = useAuth()
  const { startLoading, stopLoading, activeMenu = {}, setPreviousMenuActions, gotoPage, showSuccess, showError, showInfo, isAllDataAccess } = useUI()
  const history = useHistory()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const defaultFilters = {
    keyword: '',
    student: null,
    student_id: undefined,
    new_student: null,
    new_student_id: undefined,
    pay_status: null,
    pay_status_value: undefined,
    billed_status: null,
    billed_status_value: undefined,
    school: null,
    school_id: undefined,
    kelas: null,
    kelas_id: undefined,
    parallel: null,
    parallel_id: undefined,
    bill_type: null,
    bill_type_id: undefined,
    tahun: null,
    tahun_id: undefined,
    month: null,
    month_value: undefined,


  }
  const [filters, setFilters] = useState(defaultFilters)

  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [openInstallmentTypeDialog, setopenInstallmentTypeDialog] = useState(false);
  const [openPayMethodDialog, setopenPayMethodDialog] = useState(false);
  const [openVADialog, setopenVADialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedBills, setselectedBills] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [installmentType, setinstallmentType] = useState('1')
  const [installmentAmount, setinstallmentAmount] = useState(0)
  const [paymentRequest, setpaymentRequest] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const [schoolOptions, setschoolOptions] = useState([]);
  const [kelasOptions, setkelasOptions] = useState([]);
  const [parallelOptions, setparallelOptions] = useState([]);
  const [bill_typeOptions, setbill_typeOptions] = useState([])
  const [tahunPelajaranOptions, settahunPelajaranOptions] = useState([]);
  const [isCicilAvailable, setisCicilAvailable] = useState(true);
  const [totalAmount, settotalAmount] = useState(true);
  const [totalPaid, settotalPaid] = useState(true);
  const [totalOutstanding, settotalOutstanding] = useState(true);
  const [nameStudentKeyWord, setnameStudentKeyWord] = useState('');
  const [nameNewStudentKeyWord, setnameNewStudentKeyWord] = useState('');


  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    if (fieldName === 'pay_status') {
      filtersTemp['pay_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'billed_status') {
      filtersTemp['billed_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'school') {
      filtersTemp['school_id'] = value ? value.value : undefined
      getkelasOptions(value ? value.jenjang_id : undefined)
      if (value) {
        getbill_typeOptions(value.value, filters.tahun_id)
      }
    }
    else if (fieldName === 'student') {
      filtersTemp['student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'new_student') {
      filtersTemp['new_student_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'kelas') {
      filtersTemp['kelas_id'] = value ? value.value : undefined
      getparallelOptions(value ? value.value : undefined)
    }
    else if (fieldName === 'parallel') {
      filtersTemp['parallel_id'] = value ? value.value : undefined
    }
    else if (fieldName === 'month') {
      filtersTemp['month_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'tahun') {
      filtersTemp['tahun_id'] = value ? value.value : undefined
      if (value) {
        getbill_typeOptions(filters.school_id, value.value)
      }
    }
    else if (fieldName === 'bill_type') {
      filtersTemp['bill_type_id'] = value ? value.value : undefined
    }
    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  }

  const getTotalAmount = async (parameter) => {
    let params = {
      ...parameter
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        params = {
          ...params,
          employee_id: detail.id,
        }
      }
      else if (user.user_type_value === 2 && detail) {
        params = {
          ...params,
          student_id: detail.id,
        }
      }
      else if (user.user_type_value === 3 && students.length > 0) {
        params = {
          ...params,
          student_ids: students.map(student => (student.id))
        }
      }
      else {
        return
      }
    }

    startLoading()
    const response = await axios.get(endpoint.bill.total, { params: params })
    if (response && response.data) {
      if (response.data[0]) {
        let amount = Number(response.data[0].amount)
        let paid = Number(response.data[0].paid)
        let outStanding = amount - paid
        settotalAmount(amount)
        settotalPaid(paid)
        settotalOutstanding(outStanding)
      }

    } else {
      showError('get total bill')
    }
    stopLoading()
  }

  const installmentTypeChange = (event) => {
    setinstallmentType(event.target.value)
  }

  const installmentAmountChange = (e) => {
    setinstallmentAmount(parseInt(e.target.value))
  }

  const gettahunPelajaranOptions = async () => {
    const response = await axios.get(endpoint.tahunPelajaran.option);
    if (response && response.data) {
      settahunPelajaranOptions(generalListOptionMapper(response.data));
    }
  };

  const getschoolOptions = async () => {
    let params = {}

    if (!isAllDataAccess()) {
      params = {
        ...params,
        employee_id: detail.id,
      }
    }
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const getkelasOptions = async (jenjang_id) => {
    const params = {
      jenjang_id: jenjang_id
    }
    const response = await axios.get(endpoint.kelas.option, { params: params });
    if (response && response.data) {
      setkelasOptions(generalListOptionMapper(response.data));
    }
  };


  const getparallelOptions = async (kelas_id) => {
    const params = {
      kelas_id: kelas_id,
    }
    const response = await axios.get(endpoint.parallel.option, { params: params });
    if (response && response.data) {
      setparallelOptions(generalListOptionMapper(response.data));
    }
  };

  const getbill_typeOptions = async (school_id, tahun_id) => {
    const params = {
      school_id: school_id,
      tahun_id: tahun_id
    }
    const response = await axios.get(endpoint.billType.option, { params: params });
    if (response && response.data) {
      setbill_typeOptions(generalListOptionMapper(response.data));
    }
  };

  const getData = async (newPage, newRowsPerPage, format) => {
    let params = {
      keyword: filters.keyword,
      student_id: filters.student_id,
      new_student_id: filters.new_student_id,
      pay_status_value: filters.pay_status_value,
      billed_status_value: filters.billed_status_value,
      school_id: filters.school_id,
      kelas_id: filters.kelas_id,
      parallel_id: filters.parallel_id,
      bill_type_id: filters.bill_type_id,
      tahun_id: filters.tahun_id,
      month_value: filters.month_value,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    if (format) {
      if (format === 'excel') {
        printExcel(params)
      }
    } else {
      getDataByParams(params)
      getTotalAmount(params)
    }

  }

  const getDataByParams = async (parameter = {}) => {
    let params = {
      ...parameter
    }

    if (!isAllDataAccess()) {
      if (user.user_type_value === 1 && detail) {
        params = {
          ...params,
          employee_id: detail.id,
        }
      }
      else if (user.user_type_value === 2 && detail) {
        params = {
          ...params,
          student_id: detail.id,
        }
      }
      else if (user.user_type_value === 3 && students.length > 0) {
        params = {
          ...params,
          student_ids: students.map(student => (student.id))
        }
      }
      else {
        return
      }
    }

    startLoading()
    const response = await axios.get(endpoint.bill.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get bill')
    }
    stopLoading()
  }

  const printExcel = (params) => {
    axios.get(endpoint.bill.excel, { params: params, responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "bills.xlsx");
        document.body.appendChild(link);
        link.click();
      })
  }

  const getStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.student.option, { params: params })

    if (response) {
      return studentListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const getNewStudentOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await axios.get(endpoint.newStudent.option, { params: params })

    if (response) {
      return generalListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadStudentOptions = async (inputValue, callback) => {
    callback(await getStudentOptions(inputValue))
  };

  const handleInputChangeStudent = (newValue) => {
    setnameStudentKeyWord(newValue)
    return newValue;
  };

  const loadNewStudentOptions = async (inputValue, callback) => {
    callback(await getNewStudentOptions(inputValue))
  };

  const handleInputChangeNewStudent = (newValue) => {
    setnameNewStudentKeyWord(newValue)
    return newValue;
  };

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }


  const payClick = (event) => {
    const paidBills = selectedBills.filter(bill => bill.pay_status_value === 2)
    let tunaiBills = selectedBills.filter(bill => (bill.bill_type.isCicil === 0))
    setisCicilAvailable(tunaiBills.length < 1)

    if (paidBills.length > 0) {
      showInfo('Mohon pilih yang belum lunas')
    } else {
      setopenDialog(false)
      setopenInstallmentTypeDialog(true)
    }
  }

  const doBilling = async () => {
    setisLoading(true)
    let payload = {
      ids: selectedIds
    }

    const response = await axios.post(endpoint.bill.billing, payload)

    if (response && response.data) {

      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('penagihan')
        setopenPayMethodDialog(false)
        getData()
      }

    } else {
      showError('save pembayaran')
    }

    setisLoading(false)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedBills([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <BillForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
        payClick={payClick}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Bill"
      />
    );

    setopenDialog(true);
  };

  const gotoTransactionPage = () => {
    const route = '/dashboard/transaction'
    setPreviousMenuActions(activeMenu)
    gotoPage(route)
    history.push(route)
  }

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedBills([])
      setselectedIds([])
    }
  }

  const backToInstallment = () => {
    setopenInstallmentTypeDialog(true)
    setopenPayMethodDialog(false)
  }

  const openPayMethod = () => {

    if (installmentType === '2' && installmentAmount === 0) {
      showInfo('cicilan tidak boleh 0')
      return
    }

    setopenInstallmentTypeDialog(false)
    setopenPayMethodDialog(true)
  }

  const closeInstallmentTypeDialog = () => {
    setopenInstallmentTypeDialog(false)
  }

  const closePayMethodDialog = () => {
    setopenPayMethodDialog(false)
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.bill.root, { data: params });
    if (response) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setselectedIds([])
        setselectedBills([])
        setselectedRow(null)
        showSuccess('delete bill')
        getData();
      }

    } else {
      showError('delete bill')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      const bills = selectedBills.filter(item => item.id !== row.id)
      setselectedIds(ids)
      setselectedBills(bills)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedBills([...selectedBills, row])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
      setselectedBills([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
      setselectedBills([...dataTable])
    }
  }

  const submitPayment = (method, channelOrBalance) => {
    if (method === 1) {
      payWithBalance(channelOrBalance)
    } else {
      requestPaymentCharge(channelOrBalance)
    }
  }

  const payWithBalance = async (balance) => {

    setisLoading(true)
    let payload = {
      installment_type_value: parseInt(installmentType),
      balance_id: balance.id,
      payment_method_value: 1,
      bill_ids: selectedIds,
      installment: installmentAmount
    }

    const response = await axios.post(endpoint.bill.parentPayment, payload)

    if (response && response.data) {

      if (response.data.error) {
        showError(response.data.error)
      } else {
        showSuccess('transaksi pembayaran')
        reset()
        setopenPayMethodDialog(false)
      }

    } else {
      showError('save pembayaran')
    }

    setisLoading(false)
  }



  const requestPaymentCharge = async (channel) => {
    if (installmentType === '1') {
      const total = selectedBills.map(b => (b.amount - b.paidAmount)).reduce((partialSum, a) => partialSum + a, 0)
      if (total < 10000) {
        showInfo('pembayaran lewat Bank dan Indomaret minimum Rp. 10.000')
        return
      }
    }

    if (installmentType === '2' && installmentAmount < 10000) {
      showInfo('pembayaran lewat Bank dan Indomaret minimum Rp. 10.000')
      return
    }

    setisLoading(true)
    let payload = {
      installment_type_value: parseInt(installmentType),
      payment_channel_id: channel.id,
      payment_method_value: 2,
      bill_ids: selectedIds,
      installment: installmentAmount,
      user_id: user.id
    }

    const response = await axios.post(endpoint.bill.parentPayment, payload)

    if (response && response.data) {
      if (response.data.error) {
        showError(response.data.error)
      } else {
        setpaymentRequest(response.data)
        closePayMethodDialog()
        setopenVADialog(true)
      }

    } else {
      showError('save pembayaran')
    }

    setisLoading(false)
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setselectedBills([])
    setFilters(defaultFilters)
    getDataByParams(params)
    setinstallmentAmount(0)
    setinstallmentType('1')
  }

  const resetPayment = () => {
    setopenInstallmentTypeDialog(false)
    setinstallmentType(1)
    setinstallmentAmount(0)
  }

  useEffect(() => {
    getschoolOptions()
    getData()
    gettahunPelajaranOptions()
  }, [])



  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>

          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
            </Protected>
          </Grid>
          <Grid item>
            <Protected allowedCodes={['PAYMENT']} >
              <SmallButton onClick={() => showDialog('PAYMENT')} variant='contained' color='success'>
                <ActionDisplay code='PAYMENT' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length > 0 &&
            <>
              <Grid item>
                <Protected allowedCodes={['DELETE']} >
                  <SmallButton onClick={showDeleteConfirmation} variant='contained' color='error' >
                    <ActionDisplay code='DELETE' />
                  </SmallButton>
                </Protected>
              </Grid>
              <Grid item>
                <Protected allowedCodes={['PAY']} >
                  <SmallButton onClick={payClick} variant='contained' color='success'>
                    <ActionDisplay code='PAY' />
                  </SmallButton>
                </Protected>
              </Grid>
              <Grid item>
                <Protected allowedCodes={['BILLING']} >
                  <SmallButton onClick={doBilling} variant='contained' color='warning'>
                    <ActionDisplay code='BILLING' />
                  </SmallButton>
                </Protected>
              </Grid>
            </>
          }

          <Grid item>
            <SmallButton onClick={gotoTransactionPage} variant='text' color='primary'>
              Transaksi
            </SmallButton>
          </Grid>

          <Grid item>
            <Protected allowedCodes={['BILL_TYPE']} >
              <SmallButton onClick={() => gotoPage('/dashboard/bill-type')} color='primary'>
                <ActionDisplay code='BILL_TYPE' />
              </SmallButton>
            </Protected>
          </Grid>

          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent='flex-end'>
        <Grid item >
          <Paper
            sx={{
              minWidth: 100,
              height: 'auto',
              p: 1,
              backgroundColor: 'white'
            }}
            elevation={4}>
            <Typography variant="body2" component="div">
              Total
            </Typography>
            <Typography variant="h6" component="div">
              {new Intl.NumberFormat().format(totalAmount)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item >
          <Paper
            sx={{
              minWidth: 100,
              height: 'auto',
              p: 1,
              backgroundColor: 'white'
            }}
            elevation={4}>
            <Typography variant="body2" component="div">
              Terbayar
            </Typography>
            <Typography variant="h6" component="div">
              {new Intl.NumberFormat().format(totalPaid)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item >
          <Paper
            sx={{
              minWidth: 100,
              height: 'auto',
              p: 1,
              backgroundColor: 'white'
            }}
            elevation={4}>
            <Typography variant="body2" component="div">
              Tunggakan
            </Typography>
            <Typography variant="h6" component="div">
              {new Intl.NumberFormat().format(totalOutstanding)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder={`Nama ${process.env.REACT_APP_STUDENT}...`}
              value={filters.student}
              loadOptions={loadStudentOptions}
              onInputChange={handleInputChangeStudent}
              onChange={(e) => filterChange('student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <AsyncSelect
              isClearable={true}
              cacheOptions
              placeholder={`Nama calon ${process.env.REACT_APP_STUDENT}...`}
              value={filters.new_student}
              loadOptions={loadNewStudentOptions}
              onInputChange={handleInputChangeNewStudent}
              onChange={(e) => filterChange('new_student', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='billed_status'
              placeholder='status penagihan'
              options={doneStatusOptions}
              onChange={(e) => filterChange('billed_status', e)}
              styles={defaultSelectStyle}
              value={filters.billed_status}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='pay_status'
              placeholder='status pembayaran'
              options={payStatusOptions}
              onChange={(e) => filterChange('pay_status', e)}
              styles={defaultSelectStyle}
              value={filters.pay_status}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="month"
              placeholder="Pilih Bulan"
              options={monthOptions}
              value={filters.month}
              onChange={(e) => filterChange("month", e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name="tahun"
              placeholder="Pilih Tahun Pelajaran"
              options={tahunPelajaranOptions}
              value={filters.tahun}
              onChange={(e) => filterChange("tahun", e)}
              styles={defaultSelectStyle}
            />
          </Grid>


          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='school'
              placeholder='Sekolah'
              options={schoolOptions}
              onChange={(e) => filterChange('school', e)}
              styles={defaultSelectStyle}
              value={filters.school}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='kelas'
              placeholder='Kelas'
              options={kelasOptions}
              onChange={(e) => filterChange('kelas', e)}
              styles={defaultSelectStyle}
              value={filters.kelas}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='parallel'
              placeholder='Paralel'
              options={parallelOptions}
              value={filters.parallel}
              onChange={(e) => filterChange('parallel', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Select
              isClearable={true}
              name='bill_type'
              placeholder='Pilih jenis tagihan'
              options={bill_typeOptions}
              value={filters.bill_type}
              onChange={(e) => filterChange('bill_type', e)}
              styles={defaultSelectStyle}
            />
          </Grid>

        </Grid>

        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <SmallButton variant='text' color='secondary' onClick={() => getData(undefined, undefined, 'excel')}>
            Excel
          </SmallButton>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>

      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Paralel</TableCell>
                <TableCell>Nomor</TableCell>
                <TableCell>Jenis</TableCell>
                <TableCell>Bulan</TableCell>
                <TableCell>Tahun</TableCell>
                <TableCell>Jumlah</TableCell>
                <TableCell>Dibayar</TableCell>
                <TableCell>Pembayaran</TableCell>
                <TableCell>Penagihan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  <TableCell>
                    <ClickableText text={
                      row.student && row.student.name
                      ||
                      row.new_student && row.new_student.name
                    }
                      onClick={() => showDialog('READ', row)}
                    />
                  </TableCell>
                  <TableCell>{row.parallel && row.parallel.name}</TableCell>
                  <TableCell>{row.billNumber}</TableCell>
                  <TableCell>{row.bill_type && row.bill_type.name}</TableCell>
                  <TableCell>{getmonthLabel(row.month_value)}</TableCell>
                  <TableCell>{row.year_value}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.amount)}</TableCell>
                  <TableCell>{new Intl.NumberFormat().format(row.paidAmount)}</TableCell>
                  <TableCell>
                    <StatusChip getStatus={getpayStatusObject} status={row.pay_status_value} />
                  </TableCell>
                  <TableCell>
                    {
                      row.billed_status_value === 0 ? <StatusChip getStatus={getdoneStatusObject} status={row.billed_status_value} /> : <Chip label={row.billed_status_value} color="success" />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={closeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      {dialogContent}
    </Dialog>

    <Dialog
      open={openInstallmentTypeDialog}
      maxWidth={maxWidth}
      onClose={closeInstallmentTypeDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <DialogTitle>
        Pilih jumlah pembayaran
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id="payment_type">Anda akan membayar secara</FormLabel>
          <RadioGroup
            aria-labelledby="payment_type"
            defaultValue='1'
            name="payment-type-group"
            value={installmentType}
            onChange={installmentTypeChange}
          >
            <FormControlLabel value='1' control={<Radio />} label="Tunai" />
            <Conditional condition={selectedIds.length === 1 && isCicilAvailable}>
              <FormControlLabel value='2' control={<Radio />} label="Mencicil" />
            </Conditional>
          </RadioGroup>
        </FormControl>
        <Conditional condition={installmentType === "2"}>
          <Grid container spacing={1} alignItems='center' >
            <Grid item>
              <Typography variant="h5" component="div">
                Jumlah cicilan
              </Typography>
            </Grid >
            <Grid item>
              <TextField
                type='number'
                fullWidth
                onChange={installmentAmountChange}
                value={installmentAmount.toString()}
              />
            </Grid>
          </Grid>
        </Conditional>

        <Conditional condition={installmentType === '1'}>
          <Grid container alignItems='center' sx={{ my: 2 }}>
            <Grid item>
              <Typography variant="h5" component="div">
                Sebesar Rp. {
                  new Intl.NumberFormat().format(
                    selectedBills.map(b => (b.amount - b.paidAmount)).reduce((partialSum, a) => partialSum + a, 0)
                  )}
              </Typography>
            </Grid >
          </Grid>
        </Conditional>

        <Conditional condition={installmentType === '2'}>
          <Grid container alignItems='center' sx={{ my: 2 }}>
            <Grid item>
              <Typography variant="h5" component="div">
                Sebesar Rp. {new Intl.NumberFormat().format(installmentAmount)}
              </Typography>
            </Grid >
          </Grid>
        </Conditional>

      </DialogContent>
      <DialogActions>
        <Button onClick={resetPayment} color="secondary">
          Batal
        </Button>
        <Button variant="contained" onClick={openPayMethod} color="primary">
          Pilih metode pembayaran
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openPayMethodDialog}
      maxWidth={maxWidth}
      onClose={closePayMethodDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <PaymentMethod
        viaBalance={true}
        onSubmit={submitPayment}
        onBack={backToInstallment}
        isLoading={isLoading}
      />
    </Dialog>

    <Dialog
      open={openVADialog}
      maxWidth={maxWidth}
      onClose={() => setopenVADialog(false)}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <PaymentCharge onClose={() => setopenVADialog(false)} paymentRequest={paymentRequest} />
    </Dialog>

  </>;
}


export default Bill;

