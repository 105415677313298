import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint, api } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Select from 'react-select'
import { defaultSelectStyle } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import { List, ListItem, ListItemText, Switch, TextField, Typography } from '@mui/material';
import useUI from 'hooks/useUI';
import { generalListOptionMapper, generalOptionMapper, parameterListOptionMapper, parameterOptionMapper } from 'utils/mappers';
import ActiveChip from 'components/ActiveChip';
import { gettransactionModeLabel, gettransactionModeObject, transactionModeOptions, TRANSACTION_MODE_CASH } from 'utils/options';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import useAuth from 'hooks/useAuth';
import NumberFormatCustom from 'components/NumberFormatCustom';


const PsbExamParticipantInput = (props) => {
  const { examTypeOptions } = props
  const { detail } = useAuth()
  const { showSuccess, showError, showInfo } = useUI()
  const reset = () => {
    return {
      id: undefined,
      exam_type: null,
      exam_type_value: undefined,
      keyword:''
    }

  }
  const [state, setstate] = useState(reset())
  const [newStudentOptions, setnewStudentOptions] = useState([])
  const [selectedNewStudent, setselectedNewStudent] = useState(null)
  const [psbYear, setpsbYear] = useState(null);
  

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (fieldName === 'exam_type') {
      newState['exam_type_value'] = value ? value.value : undefined
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const selectNewStudent = (newStudent) => {
    setnewStudentOptions([])
    setstate({...state,keyword : ''})
    setselectedNewStudent(newStudent)
  }

  const getPsbYear = async () => {
    const params = {}
    const response = await axios.get(endpoint.psb.year, { params: params });
    if (response && response.data) {
      setpsbYear(generalOptionMapper(response.data));
    }
  };



  const submit = async () => {
    if(!state.exam_type_value){
      showInfo('Jenis ujian belum dipilih') 
      return;
    }
    if (detail) {
      const payload = {
        ...state,
        registration_number:selectedNewStudent.registration_number,
        new_student_id:selectedNewStudent.id,
        employee_id: detail.id,
        tahun_id:psbYear.id
      }

      const response = await axios.post(endpoint.psbFlow.examParticipant, payload)

      if (response && response.data) {
        showSuccess('save attendance')
        setselectedNewStudent(null)
      } else {
        showError('save attendance')
      }
    }

  }

  const getnewStudentOptions = async () => {
    let params = {
      keyword: state.keyword
    }
    const response = await axios.get(endpoint.newStudent.simpleOption, { params: params })

    if (response) {
      setnewStudentOptions(response.data)

    } else {
      showError('get new student')
    }
  }

  useEffect(() => {
    getPsbYear()
  }, [])

  useEffect(() => {
    if (state.keyword && state.keyword.trim() !== '') {
      getnewStudentOptions()
    }
  }, [state.keyword])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='flex-start'>
        <Grid container justifyContent='center' alignItems='flex-start' alignContent='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
            minHeight: 400
          }}
        >
          <Grid container spacing={1} justifyContent='center' alignItems='center'>
            <Grid item xs={12} md={6}>
              <Select
                name="exam_type"
                placeholder="Jenis ujian"
                options={examTypeOptions}
                value={state.exam_type}
                onChange={(e) => handleChange("exam_type", e)}
                styles={defaultSelectStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DebouncedTextField
                label='Nomor registrasi / Nama peserta'
                margin='dense'
                id='keyword'
                fullWidth
                value={state.keyword}
                onChange={(e) => handleChange('keyword', e)}
              />
            </Grid>

          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <List component="nav" style={{ width: '100%' }}>
              {
                newStudentOptions.map(item => (
                  <ListItem button onClick={() => { selectNewStudent(item) }}>
                    <ListItemText primary={`${item.name} - ${item.registration_number}`} />
                  </ListItem>
                ))
              }
            </List>
          </Grid>
          {
            selectedNewStudent &&
            <Grid container justifyContent='center' alignItems='flex-start'>
              <Grid item xs={12} container justifyContent='center'>
                <Typography variant="h5" component="h5" align="center">
                  {selectedNewStudent.name}
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent='center'>
                <Typography variant="h6" component="h6" align="center">
                  {selectedNewStudent.registration_number}
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent='center'>
                <img src={`${api.url}/${selectedNewStudent.photo}`} style={{ width: 300, height: 300 }} />
              </Grid>
              <Grid item xs={12} container justifyContent='center' sx={{mt:1}}>
                <Button onClick={submit} size='large' variant='contained'>Hadir</Button>
              </Grid>
            </Grid>
          }


        </Grid>

      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
    </DialogActions>
  </>;
}

export default PsbExamParticipantInput;

