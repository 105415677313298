import React, { useState, useEffect } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Protected from 'components/Protected.js';
import Conditional from 'components/Conditional.js';
import useUI from 'hooks/useUI';
import ReadOnlyTextField from 'components/ReadOnlyTextField';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers';
import { defaultSelectStyle } from 'utils/theme.js'
import { Checkbox, FormControlLabel } from '@mui/material';



const BankAccountDetail = (props) => {
  const { row = {}, getData } = props
  const { showSuccess, showError, showInfo } = useUI()
  const reset = () => {
    return {
      id: undefined,
      number: undefined,
      name: undefined,
      bank: undefined,
      code: undefined,
      schools: [],
      school_ids: [],
      isForPaymentGateway: 0,
      male: undefined,
      female: undefined,
      bill: undefined,
      saving: undefined,
      donation: undefined,
      
    }

  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('READ')
  const [schoolOptions, setschoolOptions] = useState([]);

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    if (['isForPaymentGateway','male','female','bill','saving','donation'].includes(fieldName)) {
      value = eventValue.target.checked ? 1 : 0;
    }
    else if (fieldName === 'schools') {
      newState['school_ids'] = value.map(school => (school.id))
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const getschoolOptions = async () => {
    const params = {}
    const response = await axios.get(endpoint.school.option, { params: params });
    if (response && response.data) {
      setschoolOptions(generalListOptionMapper(response.data));
    }
  };

  const insert = async (payload) => {
    const response = await axios.post(endpoint.bankAccount.root, payload)
    if (response && response.data) {
      showSuccess('save bank Account')
      getData()
    } else {
      showError('save bank Account')
    }
  }

  const update = async (payload) => {
    const response = await axios.put(endpoint.bankAccount.root, payload)
    if (response && response.data) {
      showSuccess('save bank Account')
      getData()
    } else {
      showError('save bank Account')
    }
  }

  const submit = async () => {

    if (state.school_ids.length === 0) {
      showInfo('tambahkan data sekolah')
      return
    }

    const params = {
      ...state
    }

    if (pageAction === "CREATE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      schools: generalListOptionMapper(row.schools),
      school_ids: row.schools.map(school => (school.id)),
    })
  }

  useEffect(() => {
    setpageAction(props.pageAction)
  }, [props.pageAction])

  useEffect(() => {
    if (pageAction !== 'READ') {
      getschoolOptions()
    }
  }, [pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CREATE') {
      getRowDetail()
    } else {
      setstate(reset())
    }
  }, [row])



  return <>
    <DialogContent>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid container justifyContent='center' alignItems='flex-start'
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 1,
            padding: 2,
            width: 'inherit',
          }}
        >
          <Conditional condition={pageAction === 'READ'}>
            <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
              <Grid item sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
                ID
              </Grid>
              <Grid item sm={8} xs={12}>
                <ReadOnlyTextField value={state.id} />
              </Grid>
            </Grid>
          </Conditional>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nomor rekening
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.number} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='number'
                  fullWidth
                  value={state.number}
                  onChange={(e) => handleChange('number', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama pemilik
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.name} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='name'
                  fullWidth
                  value={state.name}
                  onChange={(e) => handleChange('name', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Nama bank
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.bank} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='bank'
                  fullWidth
                  value={state.bank}
                  onChange={(e) => handleChange('bank', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Sekolah
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <Select
                  isMulti
                  name='schools'
                  placeholder='Pilih sekolah'
                  options={schoolOptions}
                  value={state.schools}
                  styles={defaultSelectStyle}
                />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <Select
                  isMulti
                  name='schools'
                  placeholder='Pilih sekolah'
                  options={schoolOptions}
                  value={state.schools}
                  onChange={(e) => handleChange('schools', e)}
                  styles={defaultSelectStyle}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Kode
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <ReadOnlyTextField value={state.code} />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <DebouncedTextField
                  margin='dense'
                  id='code'
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange('code', e)}
                />
              </Conditional>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Pembayaran
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.isForPaymentGateway === 1} />
                  }
                  label="Online" />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>

                <FormControlLabel
                  control={
                    <Checkbox checked={state.isForPaymentGateway === 1} onChange={(e) => handleChange("isForPaymentGateway", e)} />
                  }
                  label="Online" />
              </Conditional>

            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis kelamin
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.male === 1} />
                  }
                  label="Laki - laki" />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.female === 1} />
                  }
                  label="Perempuan" />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.male === 1} onChange={(e) => handleChange('male', e)} />
                  }
                  label="Laki - laki" />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.female === 1} onChange={(e) => handleChange('female', e)} />
                  }
                  label="Perempuan" />
              </Conditional>

            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ my: 1 }}>
            <Grid sm={4} xs={12} container justifyContent='flex-start' alignContent='center'>
              Jenis transaksi
            </Grid>
            <Grid sm={8} xs={12}>
              <Conditional condition={pageAction === 'READ'}>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.bill === 1} />
                  }
                  label="Tagihan" />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.saving === 1} />
                  }
                  label="Tabungan" />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.donation === 1} />
                  }
                  label="Donasi" />
              </Conditional>
              <Conditional condition={pageAction !== 'READ'}>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.bill === 1} onChange={(e) => handleChange('bill', e)} />
                  }
                  label="Tagihan" />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.saving === 1} onChange={(e) => handleChange('saving', e)} />
                  }
                  label="Tabungan" />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.donation === 1} onChange={(e) => handleChange('donation', e)} />
                  }
                  label="Donasi" />
              </Conditional>

            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Conditional condition={pageAction === "READ"}>
        <Protected allowedCodes={['EDIT']}>
          <Button onClick={() => setpageAction("EDIT")} color="primary">
            Edit
          </Button>
        </Protected>
      </Conditional>
      <Button onClick={props.closeDialog} color="secondary">
        Kembali
      </Button>
      <Conditional condition={pageAction !== "READ"}>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </Conditional>
    </DialogActions>
  </>;
}

export default BankAccountDetail;

