import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { useDebounce } from 'react-use';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Send from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import { generalListOptionMapper, parameterListOptionMapper } from 'utils/mappers.js'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import ActiveChip from 'components/ActiveChip';
import ActionDisplay from 'components/ActionDisplay.js';
import useUI from 'hooks/useUI.js';
import PaymentForm from './PaymentForm.js';
import Protected from 'components/Protected.js';
import { defaultSelectStyle } from 'utils/theme';
import { FormControlLabel } from '@mui/material';
import SmallButton from 'components/SmallButton.js';
import Collapse from '@mui/material/Collapse';
import Link from 'components/Link.js';

import BreadCrumbSeparator from 'components/BreadCrumbSeparator.js';
import ClickableText from 'components/ClickableText.js';
import MenuBreadCrumb from 'components/MenuBreadCrumb.js';

import StatusChip from 'components/StatusChip.js';
import { getpaymentStatusObject, paymentStatusOptions } from 'utils/options.js';
import { format } from 'date-fns'
import useAuth from 'hooks/useAuth.js';


const Payment = (props) => {
  const { startLoading, stopLoading, activeMenu = {}, showSuccess, showError, isAllDataAccess } = useUI()
  const { user, detail } = useAuth()
  const isInitialMount = useRef(true);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const defaultFilters = {
    keyword: '',
    payment_status: null,
    payment_status_value: undefined,
    bank_account: null,
    bank_account_id: undefined,
  }
  const [filters, setFilters] = useState(defaultFilters)
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [openSettlementDialog, setopenSettlementDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [isAllData, setisAllData] = useState(false)
  const [bankAccountOptions, setbankAccountOptions] = useState([]);

  const filterChange = (fieldName, value) => {
    let filtersTemp = { ...filters }
    filtersTemp[fieldName] = value


    if (fieldName === 'payment_status') {
      filtersTemp['payment_status_value'] = value ? value.value : undefined
    }
    else if (fieldName === 'bank_account') {
      filtersTemp['bank_account_id'] = value ? value.value : undefined
    }

    setFilters(filtersTemp)
  }

  const getData = async (newPage, newRowsPerPage) => {
    let params = {
      keyword: filters.keyword,
      payment_status_value: filters.payment_status_value,
      bank_account_id: filters.bank_account_id,
      page: newPage ? newPage : page,
      rowsPerPage: newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    getDataByParams(params)
  }

  const getDataByParams = async (parameter = {}) => {

    let params = {
      ...parameter
    }

    if (!isAllData && detail) {
      params = {
        ...params,
        user_id: user.id,
      }
    }

    startLoading()
    const response = await axios.get(endpoint.payment.root, { params: params })
    if (response && response.data && response.data.data) {
      const modifiedData = response.data.data.map(row => ({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    } else {
      showError('get payment')
    }
    stopLoading()
  }

  const getbankAccountOptions = async () => {
    let params = {
      isForPaymentGateway: 1
    }

    const response = await axios.get(endpoint.bankAccount.option, { params: params });
    if (response && response.data) {

      setbankAccountOptions(response.data.map(bankAccount => ({
        ...bankAccount,
        label: `${bankAccount.bank} ${bankAccount.number}`,
        value: bankAccount.id
      })));
    }
  };

  const changeKeyword = (event) => {
    setFilters({ ...filters, keyword: event.target.value })
  }

  const changePage = (event, newPage) => {
    setPage(newPage + 1)
    getData(newPage + 1, null)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    getData(1, +event.target.value)
  }

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    if (actionCode === 'CREATE') {
      setselectedIds([])
      setselectedRow(null)
    }
    let row = undefined
    if (rowParam) {
      row = rowParam
    } else {
      row = actionCode === 'CREATE' ? null : selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <PaymentForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeDialog={closeDialog}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Payment"
      />
    );

    setopenDialog(true);
  };

  const closeSettlementDialog = () => {
    setopenSettlementDialog(false)
    setselectedRow(null)
    setselectedIds([])
  }

  const closeDialog = () => {
    setopenDialog(false)
    if (pageAction === 'READ') {
      setselectedRow(null)
      setselectedIds([])
    }
  }

  const handleDelete = async () => {
    const params = {
      ids: selectedIds,
      user_id: user.id
    };
    const response = await axios.delete(endpoint.payment.root, { data: params });
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      showSuccess('delete payment')
      getData();
    } else {
      showError('delete payment')
    }
  };

  const toggleSelectRow = (row) => {
    if (selectedIds.includes(row.id)) {
      const ids = selectedIds.filter(item => item !== row.id)
      setselectedIds(ids)

      if (ids.length === 1) {
        const existingRow = dataTable.filter(data => (data.id === ids[0]))
        setselectedRow(existingRow[0])
      }
      else {
        setselectedRow(null)
      }

    } else {
      setselectedIds([...selectedIds, row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow = () => {
    if (selectedIds.length === dataTable.length) {
      setselectedIds([])
    } else {
      setselectedIds(dataTable.map(row => row.id))
    }
  }

  const showSettlementDialog = (row) => {
    setselectedRow(row)
    setopenSettlementDialog(true)
  }

  const reset = () => {
    let params = {
      keyword: defaultFilters.keyword,
      payment_status: null,
      payment_status_value: undefined,
      page: 1,
      rowsPerPage: 10
    }
    setselectedRow(null)
    setselectedIds([])
    setFilters(defaultFilters)
    getDataByParams(params)
  }


  useEffect(() => {
    getbankAccountOptions()
    getData()
  }, [])

  useEffect(() => {
    setisAllData(isAllDataAccess())
  }, [isAllDataAccess])


  useDebounce(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        getData()
      }
    },
    700,
    [filters.keyword]
  );

  return <>
    <Grid container justifyContent='flex-start' alignItems='flex-start' alignContent='flex-start'
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: 4
      }}
    >
      <Grid container alignItems='center' alignContent='center'
        sx={{ my: 1 }}
      >
        <Grid container alignItems='center' spacing={1} item xs={12} sm={12} md={6} lg={6}
          sx={{ pl: 1 }}
        >
          <MenuBreadCrumb />
        </Grid>
        <Grid container alignItems='center' item spacing={1} xs={12} sm={12} md={6} lg={6} direction='row-reverse' justifyContent='flex-start'>
          <Grid item>
            <Protected allowedCodes={['CREATE']} >
              <SmallButton onClick={() => showDialog('CREATE')} variant='contained' color='primary'>
                <ActionDisplay code='CREATE' />
              </SmallButton>
            </Protected>
          </Grid>

          {
            selectedIds.length === 1 &&
            <Grid item>
              <Protected allowedCodes={['EDIT']} >
                <SmallButton onClick={() => showDialog('EDIT')} variant='contained'>
                  <ActionDisplay code='EDIT' />
                </SmallButton>
              </Protected>
            </Grid>

          }
          {
            selectedIds.length > 0 &&
            <Grid item>
              <Protected allowedCodes={['DELETE']} >
                <SmallButton onClick={showDeleteConfirmation} variant='contained' color='secondary' >
                  <ActionDisplay code='DELETE' />
                </SmallButton>
              </Protected>
            </Grid>
          }
          <Grid item>
            <IconButton
              onClick={reset}
              aria-label='reset'
              size="large">
              <RefreshIcon color='primary' />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>


      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid container alignItems='center' spacing={1} item xs={12} lg={9} >
          {
            filters && filters.keyword !== undefined &&
            <Grid item xs={12} sm={3} >
              <TextField
                margin='dense'
                fullWidth
                value={filters.keyword}
                onChange={changeKeyword}
                id='search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          }

          <Grid item md={3} xs={6}>
            <Select
              isClearable={true}
              name='payment_status'
              placeholder='status pembayaran'
              options={paymentStatusOptions}
              onChange={(e) => filterChange('payment_status', e)}
              styles={defaultSelectStyle}
              value={filters.payment_status}
            />
          </Grid>

          {
            isAllData && <Grid item md={3} xs={6}>
              <Select
                isClearable={true}
                name='bank_account'
                placeholder={`Rekening bank`}
                options={bankAccountOptions}
                onChange={(e) => filterChange('bank_account', e)}
                styles={defaultSelectStyle}
                value={filters.bank_account}
              />
            </Grid>
          }




        </Grid>
        <Grid xs={12} lg={3} item container justifyContent='flex-end' alignItems='center'>
          <IconButton
            onClick={() => getData()}
            aria-label='send'
            size="large">
            <Send color='primary' />
          </IconButton>
        </Grid>

      </Grid>


      <Grid container sx={{ minHeight: 400 }} >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                    onChange={toggleSelectAllRow}
                    checked={selectedIds.length === dataTable.length && dataTable.length > 0}
                  />
                </TableCell>
                {
                  isAllData && <TableCell>Tipe</TableCell>
                }
                {
                  isAllData && <TableCell>
                    Nomor Order
                  </TableCell>
                }
                <TableCell>Pengguna</TableCell>
                {
                  isAllData && <TableCell>Jumlah</TableCell>
                }
                <TableCell>Jumlah Gross</TableCell>
                <TableCell>Channel</TableCell>
                <TableCell>Nomor pembayaran</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Batas waktu</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.id} onClick={() => toggleSelectRow(row)}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  {
                    isAllData && <TableCell>{row.payment_type}</TableCell>
                  }
                  {
                    isAllData && <TableCell>
                      <ClickableText text={row.order_id} onClick={() => showSettlementDialog(row)} />
                    </TableCell>
                  }
                  <TableCell>{row.user && row.user.detail && row.user.detail.name}</TableCell>
                  {
                    isAllData && <TableCell>{new Intl.NumberFormat().format(row.amount)}</TableCell>
                  }
                  <TableCell>{new Intl.NumberFormat().format(row.gross_amount)}</TableCell>
                  <TableCell>{row.channel_name}</TableCell>
                  <TableCell>{row.payment_number}</TableCell>
                  <TableCell>
                    <StatusChip getStatus={getpaymentStatusObject} status={row.payment_status_value} />
                  </TableCell>
                  <TableCell>{format(new Date(row.expired * 1000), 'yyyy-MM-dd HH:mm')}</TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
          <Grid container justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>

    <Dialog
      open={openSettlementDialog}
      maxWidth={maxWidth}
      onClose={closeSettlementDialog}
      fullWidth
      fullScreen={isDialogFull}
      scroll="body"
    >
      <Grid container justifyContent='center' sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Bank</TableCell>
                <TableCell>Nomor</TableCell>
                <TableCell>Jumlah</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                selectedRow && selectedRow.bank_accounts.map(bankAccount => (
                  <TableRow>
                    <TableCell>{bankAccount.name}</TableCell>
                    <TableCell>{bankAccount.number}</TableCell>
                    <TableCell>{bankAccount.pivot && new Intl.NumberFormat().format(bankAccount.pivot.amount)}</TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Dialog>


  </>;
}


export default Payment;

